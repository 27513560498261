import React, { useEffect, useState } from 'react';

import NumberInput from '../NumberInput';
import DatePicker from '../DatePicker';
import DynamicSelect from '../DynamicSelect';
import { getStyle } from '../../../styles/styles';
import { dynamicGetWithParameters } from '../../../networking/NetworkingDynamic';
import AlertBox from '../AlertBox';

const DynamicScreenForm =(props)=> {
    
    const style = getStyle();
        const [objJson, setObjJson] = useState({});
        const [reload, setReload] =useState(false);
        const [reloadList, setReloadList] =useState(false);
        const [showAlert, setShowAlert] =useState(false);
        const [alertTitle, setAlertTitle] = useState ('');
        const [alertMessage, setAlertMessage] = useState('');
    

    useEffect(() => {
        if(props.objJson)
        {   
            let json = props.objJson;
            if(json.length > 0)
            {
                json.map(async(item) =>{
                    if(item.type === 'list' && item.method && (item.filterID === undefined || item.filterID === '') && item.value)
                    {
                        let itemID = item.id;
                
                        let indexSubItem = json.findIndex((s) => s.filterID === itemID);
                        
                        if(indexSubItem > -1)
                        {
                            json[indexSubItem].data = await loadSubList(json, itemID, item.value, indexSubItem);      
                            setObjJson(json);
                            setReload(true);
                        }
                    }
                });  
            }
            setObjJson(json);            
        }
    },[props.objJson])

    useEffect(() => {
        if(reload)
        {
            setReload(false);
        }
    },[reload])

    const loadSubList = async(json, itemID,value,indexSubItem) => {
        
                let subItem = json[indexSubItem];
                if(subItem && subItem.method && subItem.params){
                    let params = JSON.stringify(subItem.params);
                    params = params.replace(`#${itemID}`, value);
                    params = JSON.parse(params);                    
                    return await dynamicGetWithParameters(subItem.method, params); 
                }
                else
                {
                    return [];
                }
            
    }

    const OnChangeListItem = async(value, id) =>{
        if (value != null && value.value !== undefined && value.value !== "") 
        {
            let json = objJson;
            json[id].value = value.value;
            setObjJson(json);   

            let itemID = json[id].id;
            
            let indexSubItem = json.findIndex((s) => s.filterID === itemID);
            
            if(indexSubItem > -1)
            {
                json[indexSubItem].data = await loadSubList(json,itemID, value.value, indexSubItem);      
                json[indexSubItem].value = '';
                setObjJson(json);
                setReload(true);
            }
        }
    }

    const OnChangeText =(e, id) =>{
        let json = objJson;
        json[id].value = e.target.value;
        setObjJson(json);   
        setReload(true);
    }

    const OnChangeNumber =(e, id) =>{
        let json = objJson;
        json[id].value = e.target.value;
        setObjJson(json); 
    }

    const OnChangeDate =(date, id) =>{
        let json = objJson;
        json[id].value = date;
        setObjJson(json);   
    }

    const OnCancel = () =>{
        if(props.onCancel)
        {
            props.onCancel(false);
        }
    }

    const OnSave = async() =>{

        if(props.submitMethod)
        {
            let json = {};
            objJson.map(o => {
                json[o.id] = o.value;
            })

            let result = await dynamicGetWithParameters(props.submitMethod, json); 
            if(result && result.httpErrorMessage)
            {
                setAlertTitle('Error');
                setAlertMessage(result.httpErrorMessage);
                setShowAlert(true);
            }
            else
            {
                if(result && result.length > 0 && result[0].Result && result[0].Message)
                {
                    setAlertTitle(result[0].Result);
                    setAlertMessage(result[0].Message);
                    if(result[0].Result.toLowerCase() === 'success')
                    {
                        setReloadList(true);                    
                    }
                    setShowAlert(true);
                }
            }
        }

    }

    const closeAlert = () => {
        if(props.onCancel)
        {
            props.onCancel(reloadList);
        }
    }

    const getControl = (id,item) =>{
        switch(item.type)
        {
            case 'list': return <DynamicSelect
            className="uk-select"
            objValue={{ value: item.value}}
            getValue={(value) => OnChangeListItem(value, id)}
            data={item.data}
            id={"select-"+id} 
            key={"select-"+id} 
            placeholder={`Select ${item.label}`} 
        />
            case 'integer': return <NumberInput className="uk-input" id={"int-"+id} key={"int-"+id} type="Integer" onChange={(e)=>OnChangeNumber(e, id)}  placeholder="" value={item.value} />
            case 'number': return <NumberInput className="uk-input" type="Currency"  id={"number-"+id} key={"number-"+id} onChange={(e)=>OnChangeNumber(e, id)}  placeholder="" value={item.value} />
            case 'date': return <DatePicker id={"date-"+id} key={"date-"+id} className={"uk-input" } disabledDays={item.disabledDays}  value={item.value} onDayChange={(date)=>OnChangeDate(date, id)} />
            case 'text': return <input className="uk-input" id={"text-"+id} key={"text-"+id} type="text" onChange={(e)=>OnChangeText(e, id)}  placeholder="" value={item.value} />
            default: return "";
        }
    }
        let information = [];
        
        if(objJson && objJson.length > 0)
        {
            let id = 0;
            objJson.map(item => 
                { if(!item.isKEY)
                {
                    let columns = 2;
                    if(item.columns)
                    {
                        columns = item.columns;
                    }
                information.push(
                    <div className={`uk-width-1-${columns} uk-form-stacked`}>
                        <div className="uk-margin-right">
                            <label className="uk-form-label" htmlFor="form-bank-status">{item.label}:</label>
                            <div className="uk-margin-right">
                                {getControl(id, item)}
                            </div>
                        </div>
                    </div> )
                    
                }
                id++;
                return true;
                }
        )
        }
    

        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>{props.title}</h3>
                <div className="uk-grid">
                    {information}
                    <div className="uk-width-4-5" style={{marginTop:'20px'}}>
                    <button className="uk-button uk-button-green" onClick={OnCancel} >Cancel</button>
                    <button className="uk-button uk-button-green" onClick={OnSave} >Save</button>  
                    </div>
                </div>
                <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert}/>
            </div>
            
        );
    
}


export default DynamicScreenForm;