import { PlusOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Form, Input, InputNumber, Popconfirm, Select, Space, Table, Tag, Typography, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../../../AppContext';
import Text from 'antd/lib/typography/Text';
import { getUserDefinedProperties, getUserDefinedPropertiesById, saveProperty } from '../../../networking/NetworkingUserProperties';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import useLookUpTables from '../complaints/useLookUpTables';
import { MultiSelect } from "react-multi-select-component";
import AdditionalPropertiesDialog from './AdditionalPropertiesDialog';
import { getAllLookUpTableMin } from '../../../networking/NetworkingLookUpTables';
import DynamicSelect from '../../shared/DynamicSelect';
import Highlighter from 'react-highlight-words';

const style = getStyle();
const { Option } = Select;

const NewPropertiesMaintenanceScreen = () => {
    const context = useRef(useContext(AppContext));
    const [form] = Form.useForm();
    const lookUpTables = useLookUpTables('Data Type,Sub Entity Types');
    const [listOfLookUpTables, setListOfLookUpTables] = useState([]);
    const [allProperties, setAllProperties] = useState([]);
    const [subEntityType, setSubEntityType] = useState('');

    const [reloadProperties, setReloadProperties] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const [selectedSubtype, setSelectedSubtype] = useState([]);

    const [editingKey, setEditingKey] = useState(0);
    const [openPropertiesDialog, setOpenPropertiesDialog] = useState(false);

    const [description, setDescription] = useState('');
    const [ extAmountLimit, setExtAmountLimit] = useState(0)
    const [ extAttachmentsRequired, setExtAttachmentsRequired] = useState(false)
    const [ extMandatory, setExtMandatory] = useState(false)
    const [ extUserPropertyID, setExtUserPropertyID] = useState(null)
    const [ extUpdateToken, setExtUpdateToken] = useState('')
    const [ extPrintable, setExtPrintable] = useState(false)
    const [currentDataType, setCurrentDataType] = useState(null);

    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const searchInput = useRef();

    const isEditing = (record) => record.ID === editingKey;

    useEffect(() => {
        const loadProperties = async () => {
            const json = await getUserDefinedProperties(subEntityType, 0, 0);
            if (json != null && json.properties != null) {
                const _properties = json.properties.map((p, index) => {
                    let _subTypes = [];
                    if (p.SubTypes != null) {
                        _subTypes = JSON.parse(p.SubTypes).map(s => s.SubEntityType).filter(s => !s.startsWith('Select'));
                    }
                    return ({ ...p, key: `property-${index}`, SubTypes: _subTypes, GroupBy: p.GroupBy == null ? '' : p.GroupBy})
                });
                setAllProperties(_properties);
            }
        }
        loadProperties();
    }, [reloadProperties, subEntityType]);

    useEffect(() => {
        const loadLookUpTables = async () => {
            const json = await getAllLookUpTableMin();
            if (json != null && json.LookUpTable != null) {
                const _lookUpTables = json.LookUpTable.map(l => ({ ...l, value: l.LookUpTableId, name: l.Description }))
                setListOfLookUpTables(_lookUpTables);
            }
        }
        loadLookUpTables();
    }, []);

    const save = async (record) => {
        try {
            const row = await form.validateFields();
            console.log(row);
            const newData = [...allProperties];
            const index = newData.findIndex((item) => record.EmailId === item.EmailId);
            if (index > -1) {
                const userID = localStorage.getItem('UserID');
                const model = {
                    UserPropertyId: record.ID,
                    DisplayOrder: row['Display Order'],
                    Description: row['Description'],
                    ShortDescription: row['Short Description'],
                    DataType: row['Data Type'],
                    ReadOnly: row['ReadOnly'] === 'Yes',
                    LookUpTableId: row['LookUpTable'] != null ? row['LookUpTable'] : null,
                    GroupBy: row['Group By'],
                    Mandatory: row['Mandatory'] === 'Yes',
                    Comments: form.getFieldValue('Comments'),
                    SubTypes: row['SubTypes'].map(s => s.value),
                    UserId: userID,
                    UpdateToken: form.getFieldValue('UpdateToken')
                }
                console.log(model);
                context.current.startLoading();
                const json = await saveProperty(model);
                context.current.finishLoading();
                if (json != null){
                    if (json.httpStatusCode !== 200){
                        setShowAlert(true);
                        setAlertTitle('Error');
                        setAlertMessage(json.httpErrorMessage);
                        // setReloadProperties(!reloadProperties);
                    } else {
                        setShowAlert(true);
                        setAlertTitle('Success');
                        setAlertMessage('Property successfully saved.');
                        setReloadProperties(!reloadProperties);
                        setEditingKey(0);
                        setCurrentDataType(null)
                    }
                }
            }

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }

    const edit = async (record, event) => {
        event.stopPropagation();
        if (allProperties[0].ID === 0) {
            let auxAllProperties = [...allProperties];
            auxAllProperties.shift();
            setAllProperties(auxAllProperties);
        }

        const json = await getUserDefinedPropertiesById(record['ID']);
        if (json != null) {
            form.setFieldsValue({
                ...record,
                'Mandatory': record.Mandatory ? 'Yes' : 'No',
                'ReadOnly': record.ReadOnly ? 'Yes' : 'No',
                'SubTypes': record['SubTypes'].map(st => ({ label: st, value: st })),
                'LookUpTable': record['LookUpTableId'],
                'Comments': json.Comments,
                'UpdateToken': json.UpdateToken
            });
            setEditingKey(record.ID);
            setCurrentDataType(record['Data Type']);
        }
    };

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        // const onChangeSelect = (value) => {
        //     setCurrentStatus(value);
        //     form.setFieldsValue({ 'Status': value });
        // }

        let inputNode = null;
        switch (inputType) {
            case 'select': {
                switch (dataIndex) {
                    case 'LookUpTable': {
                        inputNode = <Select
                            key="Select-LookUpTable"
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select an Option"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {listOfLookUpTables.map((l, index)  => (
                                <Option key={`Property-LookUpTable-option${index}`} value={l.value}>{l.name}</Option>
                            ))}
                        </Select>
                        break;
                    }
                    case 'Data Type': {
                        inputNode = <Select
                            key="Select-DataType"
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select an Option"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            <Option key='Property-DataType-option-Boolean' value={'Boolean'}>Boolean</Option>
                            <Option key='Property-DataType-option-DateTime' value={'DateTime'}>DateTime</Option>
                            <Option key='Property-DataType-option-Numeric' value={'Numeric'}>Numeric</Option>
                            <Option key='Property-DataType-option-Text' value={'Text'}>Text</Option>
                        </Select>
                        break;
                    }
                    default: break;
                }
                break;
            }
            case 'number': {
                inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center' }} />;
                break;
            }
            case 'check': {
                inputNode = <Select
                    key="Select-Mandatory"
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select an Option"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    <Option key='Property-Mandatory-option-Yes' value={'True'}>Yes</Option>
                    <Option key='Property-Mandatory-option-No' value={'False'}>No</Option>
                </Select>
                break;
            }
            case 'subtype': {
                inputNode = <MultiSelect
                        // key={`multiselect-subtypes-${index}`}
                        options={[...lookUpTables.SubEntityTypes
                            .map(st => ({ label: st.name, value: st.value }))
                            .filter(s => !s.value.startsWith('Select a '))
                            .sort((a, b) => {
                                if (record['SubTypes'].includes(a.value)) {
                                    return -1;
                                } else if (record['SubTypes'].includes(b.value)) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            })]}
                        // selected={selectedSubtype}
                        // value={[]}
                        // onChange={(item) => {setSelectedSubtype(item); console.log(item)}}
                        overrideStrings={{ "selectSomeItems": "Select..." }}
                    />;
                    break;
            }
            default: {
                inputNode = <Input style={{ textAlign: 'center' }} />;
                break;
            }
        }

        let styleEdit = { margin: 0 };
        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        const getCustomRules = (columnDataIndex) => {
            switch (columnDataIndex) {
                case 'Description':
                case 'ShortDescription':
                case 'DataType': {
                    return [
                        {
                            required: true,
                            type: 'string'
                        }
                    ];
                }
                case 'LookUpTable':
                case 'SubTypes': {
                    return [
                        {
                            required: false,
                        }
                    ]
                }
                case 'DisplayOrer': {
                    return [
                        {
                            required: true,
                            type: 'number'
                        }
                    ]
                }
                default: {
                        return [
                            {
                                required: true,
                                message: `Please Input ${title}!`
                            },
                        ];
                    }
            }
        };
        const rules = getCustomRules(dataIndex);

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item name={dataIndex} style={styleEdit} rules={rules}>
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        if (selectedKeys[0] != null) {
            setFilteringSomething(true);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };

    const settingSearchStateValues = (value, col) => {
        if (value != null) {
            setSearchText(value);
            setSearchedColumn(col);
            setSearchArray(...(searchArray[col] = value));
            setFilteringSomething(true);
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput.current = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(clearFilters);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            settingSearchStateValues(selectedKeys[0], dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    });

    const modifyColumns = (col) => {
        if (!col.editable) {
            return col;
        }
        let type = 'text';
        switch (col.dataIndex) {
            case 'ReadOnly':
            case 'Mandatory': {
                type = 'check';
                break;
            }
            case 'LookUpTable':
            case 'Data Type': {
                type = 'select';
                break;
            }
            case 'Display Order': {
                type = 'number';
                break;
            }
            case 'SubTypes': {
                type = 'subtype';
                break;
            }
            default: {
                type = 'text';
                break;
            }
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    }

    const columns = [
        {
            title: 'Group By',
            dataIndex: 'Group By',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                if (a['Group By'] == null) {
                    return -1;
                }
                if (b['Group By'] == null) {
                    return 1;
                }
                return a['Group By'].localeCompare(b['Group By']);
            },
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Group By')
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            width: '15%',
            editable: true,
            ellipsis: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Description']?.localeCompare(b['Description']) ?? -100;
            },
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Description')
        },
        {
            title: 'Short Description',
            dataIndex: 'Short Description',
            width: '15%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Short Description']?.localeCompare(b['Short Description']) ?? -100;
            },
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Short Description')
        },
        {
            title: 'Data Type',
            dataIndex: 'Data Type',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Data Type'].localeCompare(b['Data Type'])
            },
            render: (value, _, index) => (
                <Tag key={value + index}>
                  {value}
                </Tag>
            ),
            filters: [
                {
                    text: 'Text',
                    value: 'Text'
                },
                {
                    text: 'Numeric',
                    value: 'Numeric'
                },
                {
                    text: 'DateTime',
                    value: 'DateTime'
                },
                {
                    text: 'Boolean',
                    value: 'Boolean'
                }
            ],
            onFilter: (value, record) => record['Data Type'].startsWith(value),
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title: 'Display Order',
            dataIndex: 'Display Order',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Display Order'] - b['Display Order'];
            },
            sortDirections  : ['descend', 'ascend'],
        },
        {
            title: 'Mandatory',
            dataIndex: 'Mandatory',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Mandatory']?.toString().localeCompare(b['Mandatory']) ?? -100;
            },
            render: (value, _, index) => (
                <Tag color={value ? 'blue' : 'red'} key={value + index}>
                  {value ? 'Yes' : 'No'}
                </Tag>
            ),
            sortDirections  : ['descend', 'ascend'],
            filters: [
                {
                    text: 'Yes',
                    value: 'Yes'
                },
                {
                    text: 'No',
                    value: 'No'
                }
            ],
            onFilter: (value, record) => (record.Mandatory ? 'Yes' : 'No') === value
        },
        {
            title: 'Read Only',
            dataIndex: 'ReadOnly',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['ReadOnly']?.toString().localeCompare(b['ReadOnly']) ?? -100;
            },
            render: (value, _, index) => (
                <Tag color={value ? 'blue' : 'red'} key={value + index}>
                  {value ? 'Yes' : 'No'}
                </Tag>
            ),
            sortDirections  : ['descend', 'ascend'],
            filters: [
                {
                    text: 'Yes',
                    value: 'Yes'
                },
                {
                    text: 'No',
                    value: 'No'
                }
            ],
            onFilter: (value, record) => (record.ReadOnly ? 'Yes' : 'No') === value
        },
        {
            title: 'Sub Types',
            dataIndex: 'SubTypes',
            width: '20%',
            align: 'center',
            editable: true,
            render: (value, _, index) => {
                const _lookup = [...lookUpTables.SubEntityTypes
                    .map(st => ({ label: st.name, value: st.value }))
                    .filter(s => !s.value.startsWith('Select'))
                    .sort((a, b) => {
                        if (value.includes(a.value)) {
                            return -1;
                        } else if (value.includes(b.value)) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })];
                return (
                    <div title={value.join('\n')}>
                        <MultiSelect
                            title="test"
                            key={`multiselect-subtypes-${index}`}
                            options={_lookup}
                            // selected={selectedSubtype}
                            value={value.map(st => ({ label: st, value: st }))}
                            hasSelectAll={false}
                            // disabled={true}
                            onChange={() => {}}
                            ClearIcon={null}
                            ClearSelectedIcon={null}
                            // onChange={(item) => {setSelectedSubtype(item); console.log(item)}}
                            overrideStrings={{ "selectSomeItems": "Select..." }}
                        />
                    </div>
            )},
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={() => save(record)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Tooltip title="View">
                                <Typography.Link disabled={editingKey !== 0 || allProperties[0].ID === 0} onClick={async (event) => openDialog(record, event)}>
                                    <SearchOutlined />
                                </Typography.Link>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <Typography.Link disabled={editingKey !== 0 || allProperties[0].ID === 0} onClick={async (event) => await edit(record, event)}>
                                    <EditOutlined />
                                </Typography.Link>
                            </Tooltip>
                        </Space>
                    </div>
                );
            }
        }
    ].map(modifyColumns);

    const expandedRowRender = (record) => {
        const _columns = [
            {
                title: 'Look up Table',
                dataIndex: 'LookUpTable',
                editable: true,
                width: '15%',
                // render: () => (
                //     <DynamicSelect id="lookuptable-list" className="uk-select" getValue={this.handleUpdateLookUpTable.bind(this)} listHasPlaceholder={false} placeholder='Select an item...' data={listOfLookUpTables} objValue={{ value: this.state.lookUpTableId, name: this.state.lookUpTableName }} />
                // )
                render: (_, record) => (
                    <Text>{record['LookUpTableDescription']}</Text>
                )
            }
        ].map(modifyColumns)
        if (editingKey === 0 && !record.isNew) {
            return <Text>Look up Table: {record.LookUpTableDescription}</Text>
        }
        return (
            <div className='uk-row'>
                <div className='uk-width-1-4'>
                    <Table
                        size="small"
                        columns={_columns}
                        dataSource={allProperties.filter(p => p.ID === record.ID)}
                        components={{ body: { cell: EditableCell } }}
                        pagination={false}
                    />
                </div>
            </div>
        )
    };

    const cancel = (page) => {
        setEditingKey(0);
        setCurrentDataType(null);
        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        if (allProperties[0].ID === 0) {
            let auxAllProperties = [...allProperties];
            auxAllProperties.shift();
            setAllProperties(auxAllProperties);
        }

        form.resetFields();
    };

    const closeAlert = () => {
        setShowAlert(false);
        setAlertTitle('');
        setAlertMessage('');
    }

    const openDialog = (record, event) => {
        event.stopPropagation();
        setDescription(record.Description);
        setExtAmountLimit(record['ExtAmountLimit$']);
        setExtAttachmentsRequired(record['ExtAttachmentsRequired$']);
        setExtMandatory(record['ExtMandatory$']);
        setExtUserPropertyID(record['ID']); //obj['ExtUserPropertyID$'],);
        setExtUpdateToken(record['ExtUpdateToken$']);
        setExtPrintable(record['ExtPrintable$']);
        setOpenPropertiesDialog(true);
    }

    const onClosePropertiesDialog = (updateToken) => {
        setOpenPropertiesDialog(false);
        if (updateToken !== extUpdateToken) { // A save was done
            setReloadProperties(!reloadProperties);
        }
    }

    const addNew = () => {
        let actual = [...allProperties];
        if (actual[0] != null && actual[0].ID === 0) {
            return;
        } else {
            actual.unshift({
                'key': 'UserProperty-new',
                'ID': 0,
                'Description': '',
                'Display Order': 0,
                'Group By': '',
                'Data Type': 'Text',
                'Short Description': '',
                'LookUpTable': null,
                'Mandatory': 'No',
                'ReadOnly': 'No',
                'UpdateToken': '',
                'SubTypes': [],
                isNew: true
            });
            setAllProperties(actual);
            setCurrentPage(1);
            form.setFieldsValue({
                'key': 'UserProperty-new',
                'ID': 0,
                'Description': '',
                'Display Order': 0,
                'Group By': '',
                'Data Type': 'Text',
                'Short Description': '',
                'LookUpTable': null,
                'Mandatory': 'No',
                'ReadOnly': 'No',
                'UpdateToken': '',
                'SubTypes': [],
                ...actual
            });
            setEditingKey(0);
            setCurrentDataType('Text');
        }
    };

    const onValuesChange = (changedValues) => {
        if (changedValues['Data Type'] != null) {
            setCurrentDataType(changedValues['Data Type']);
        }
    }

    return (
        <div>
            <div className="breadcrumb-div">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className="breadcrumb-item-bold">Additional Properties</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* <MultiSelect
                className='extended-multiselect'
                // key={`multiselect-subtypes-${index}`}
                options={lookUpTables.SubEntityTypes.map(st => ({ label: st.name, value: st.value }))}
                selected={selectedSubtype}
                value={selectedSubtype}
                onChange={(item) => {setSelectedSubtype(item); console.log(item)}}
                overrideStrings={{ "selectSomeItems": "Select..." }}
            /> */}
            <Form form={form} onValuesChange={onValuesChange}>
                <Table dataSource={allProperties} columns={columns} size="small" components={{ body: { cell: EditableCell } }} pagination={{
                        onChange: cancel,
                        showQuickJumper: true,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                                return <a>Previous</a>;
                            }
                            if (type === 'next') {
                                return <a>Next</a>;
                            }
                            return originalElement;
                        },
                        current: currentPage,
                        defaultCurrent: 1,
                        size: 'default'
                    }}
                    expandable={{
                        expandedRowRender: record => (expandedRowRender(record)),
                        rowExpandable: (record) => record['Data Type'] === 'Text' && (isEditing(record) ? (currentDataType != null && currentDataType === 'Text') : record['LookUpTableId'] != null),
                    }}
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={addNew}
                            block
                            icon={<PlusOutlined />}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            disabled={editingKey !== 0 || filteringSomething || (allProperties[0] !== undefined && allProperties[0].ID === 0)}
                        >
                            Add an Item
                        </Button>
                    )}
                    ></Table>
            </Form>
            <AdditionalPropertiesDialog
                open={openPropertiesDialog}
                onClose={onClosePropertiesDialog}
                propertiesID={extUserPropertyID}
                propertyName={description}
                properties={{ _amountLimit: extAmountLimit, _mandatory: extMandatory, _attachmentsRequired: extAttachmentsRequired, _printable: extPrintable, _updateToken: extUpdateToken }}
            />
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
};

export default NewPropertiesMaintenanceScreen;
