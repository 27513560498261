import React, { useState } from 'react';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import AlertBox from '../../shared/AlertBox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { deleteAttachment, getAttachment, getAttachmentsList } from '../../../networking/NetworkingTMx';
import { useEffect } from 'react';
import NewEditableGrid from '../../shared/NewEditableGrid';
import { useRef } from 'react';
import { useContext } from 'react';
import AppContext from '../../../AppContext';
import NewAttachment from './NewAttachment';
import * as mime from 'mime-types';
import { CheckOutlined, DeleteOutlined, EditOutlined, SearchOutlined }  from '@ant-design/icons';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(styles)((props) => 
{
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const CaseActivityAttachment = (props) =>
{
    const context = useRef(useContext(AppContext));
    const [loading, setLoading] = useState(false);
    const [listAttachments, setListAttachments] = useState([]);
    const [columnDetail, setColumnDetail] = useState([]);
    const [openAttachment, setOpenAttachment] = useState(false);
    const [currentID, setCurrentID] = useState('');
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentFileURLList, setCurrentFileURLList] = useState('');
    const [currentBlob, setCurrentBlob] = useState(null);
    const [currentExtension, setCurrentExtension] =useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showOpenAlert, setShowOpenAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [reloadAttachments, setReloadAttachments] = useState(false);
    const [openNewAttachment, setOpenNewAttachment] = useState(false);

    useEffect(() => {
        const loadAttachments= async () => {
            if(props.open || reloadAttachments)
        {
            if(props.activityID === '')
            {
                setListAttachments([])
            }
            else
            {
                context.current.startLoading();
                setLoading(true);
                const json = await getAttachmentsList(props.activityID);
                setLoading(false);
                if (json != null && json.result != null) {
                    const _formattedCases = json.result.map((m, i) => ({ ...m, key: `case-${i}` }));
                    setListAttachments(_formattedCases);
                    if (json.columnDetail != null) {
                        const _columnDetail = JSON.parse(json.columnDetail);
                        setColumnDetail(_columnDetail);
                    }
                }
                else
                {setListAttachments([]);}
                
                context.current.finishLoading();
            }
        }
        else
        {
            setListAttachments([]);
        }
        };
        
        loadAttachments();
        
    }, [reloadAttachments, props.activityID, props.open]);

    const yesDowloadFile =() =>{
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(currentBlob);
        link.download = currentFileName;
        link.click();
    }

    const closeAlert = () =>{
        setShowAlert(false);
    }

    const onCloseAttachment = () =>{
        setOpenAttachment(false);
    }

    const onView = (record) =>{

        getAttachment(record.ID).then((jsonResponse)=>
        {
            let byteCharacters = atob(jsonResponse.data);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) 
            {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let bytes = new Uint8Array(byteNumbers);
            let fileName = record.Filename ? record.Filename: '';

            let blob = new Blob([bytes],{type: mime.lookup(fileName)});

            let arrayName = fileName.split(".")

            let fileList = [{
                uri     : window.URL.createObjectURL(blob),
                fileType: arrayName[arrayName.length-1].toLowerCase(),
                fileName: fileName
            }]

            if(arrayName.length !== 0)
            {
                if( arrayName[arrayName.length-1] === 'xlsx' || arrayName[arrayName.length-1] === 'xls' ||
                    arrayName[arrayName.length-1] === 'docx' || arrayName[arrayName.length-1] === 'doc' ||
                    arrayName[arrayName.length-1] === 'docm')
                {
                        setShowOpenAlert(true);
                        setCurrentBlob(blob);
                        setCurrentFileName(fileName);
                        setCurrentExtension(arrayName[arrayName.length-1]);
                
                }else
                {
                        setCurrentFileName(fileName);
                        setCurrentFileURLList(fileList);
                        setOpenAttachment(true);
                    
                }
            }
        });
    }
 
    const onDelete = (record) =>{
        setCurrentID(record.ID);
        setShowConfirmDelete(true);
    }

    const handleNewClick = () =>{
        setCurrentID('');
        setOpenNewAttachment(true);
    }

    const yesConfirmDeleteAlert = async() =>{
        context.current.startLoading();
        setShowConfirmDelete(false);
        const model = {
                json : {
                        ID: currentID
                    }
                }

        const json = await deleteAttachment(model);
        context.current.finishLoading();
        setCurrentID('');
        if(json){
            if (json.httpStatusCode !== 200){
                setShowAlert(true);                
                setAlertTitle('Error');
                if (json.Message != null && json.Message !== '') {
                    setAlertMessage(json.Message);
                } else {
                    setAlertMessage(json.httpErrorMessage);
                }
                setReloadAttachments(!reloadAttachments);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('File has successfully removed.');                
                setReloadAttachments(!reloadAttachments);
            }
        }
    }
 
    const noConfirmDeleteAlert = () =>{
        setCurrentID('');
        setShowConfirmDelete(false);
    }
 
    return (
        <div>
            <Dialog 
                open={props.open} 
                onClose={props.onClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="attachments-dialog-title" onClose={props.onClose}>
                    Attachments
                </DialogTitle>
                <DialogContent dividers>
                    <div className="uk-margin" style={{backgroundColor: '#ffffff'}}>
                        {loading === true && 
                            <h4 style={{
                                textAlign: 'center'
                            }}>Loading...</h4>
                        }
                        {listAttachments.length === 0 && 
                         loading === false && <div>
                            <h4 style={{
                                textAlign: 'center'
                            }}>No attachments found</h4>
                        </div>}
                        {loading === false && 
                            <NewEditableGrid
                                    data={listAttachments}
                                    columnDetails={columnDetail}
                                    isEditable={false}
                                    useIcons={true}
                                    dynamicActions={[{name: 'View', function: onView, isIcon: true, icon: <SearchOutlined />},
                                        {name: 'Delete', function: onDelete, isIcon: true, icon: <DeleteOutlined /> }]} 
                            />
                        }
                        <button className="uk-button uk-button-green" type="button" onClick={handleNewClick}>Add attachment</button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog 
                open        = {openAttachment} 
                onClose     = {onCloseAttachment}
                fullWidth   = {true}
                maxWidth    = {'md'}
            >
                <DialogTitle id="current-attachment-dialog-title" onClose={onCloseAttachment}>
                    {currentFileName}
                </DialogTitle>
                <DialogContent dividers>      
                    <div>
                        <DocViewer
                            pluginRenderers = {DocViewerRenderers} 
                            documents       = {currentFileURLList}
                            config          = {{
                                header: {
                                    disableHeader   : true,
                                    disableFileName : true,
                                    retainURLParams : false
                                }
                            }} 
                        />
                    </div>
                </DialogContent>
            </Dialog>

            <NewAttachment open={openNewAttachment} onClose={() => {setOpenNewAttachment(false);setReloadAttachments(!reloadAttachments);}} linkID ={props.activityID} />
            <AlertBox id="confirmation-delete"
                    open={showConfirmDelete}
                    type="Yes/No"
                    title={'Warning'}
                    message={'Do you want to delete this file?'}
                    yesClick={yesConfirmDeleteAlert}
                    noClick={noConfirmDeleteAlert} />
            <AlertBox id="alert-pricing" 
                open={showAlert} 
                onClose={closeAlert} 
                title={alertTitle}
                message={alertMessage} 
                type="Ok" 
                okClick={()=>setShowAlert(false)} />
            <AlertBox 
                id="open-attachment" 
                open={showOpenAlert} 
                onClose={closeAlert} 
                title="Warning" 
                message="The file has no preview. Do you want to download the file?" 
                type="Yes/No" 
                yesClick={yesDowloadFile} 
                noClick={closeAlert}
            />
        </div>
    )
} 
export default CaseActivityAttachment;