import React, { useState, useEffect } from 'react';
import NewDynamicTable from '../../shared/NewDynamicTable';
import { getStyle } from '../../../styles/styles';
import { formatValidInput, formatValidSelect, formatToCurrency, getScaleByCurrency, formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import NumberInput from '../../shared/NumberInput';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import DynamicSelect from '../../shared/DynamicSelect';
import AlertBox from '../../shared/AlertBox';
import { getCurrencyList, searchCurrencies } from '../../../networking/NetworkingCurrencies';
import useBlotters from './hooks/useBlotters';
import { getTraders, saveBlotter, deleteBlotter, getBlotters } from '../../../networking/NetworkingBlotters';
import useTraders from './hooks/useTraders';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { validate } from '../../../helpers/ValidateHelper';
import useCurrenciesList from './hooks/useCurrenciesList';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
const style = getStyle();

const BlotterForm = (props) => {
    const [blotterID, setBlotterID] = useState(0);
    const [description, setDescription] = useState('');
    const [baseCurrency, setBaseCurrency] = useState('');
    const [currencies] = useCurrenciesList();
    const [baseCurrencyName, setBaseCurrencyName] = useState('');
    const [positionLimit, setPositionLimit] = useState(0);
    const [parentBlotter, setParentBlotter] = useState('');
    const [status, setStatus] = useState('');
    const [allowProfit, setAllowProfit] = useState(false);
    const [trader, setTrader] = useState('');
    const [validations, setValidations] = useState({description: true, status: true})

    const [disableParentBlotter, setDisableParentBlotter] = useState(false);
    const [baseCurrencies, setBaseCurrencies] = useState([]);
    const [pages, setPages] = useState(1);

    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [showSuccessDeleteAlert, setShowSuccessDeleteAlert] = useState(false)
    const [readyToDeleteBlotter, setReadyToDeleteBlotter] = useState({});
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [errors, setErrors] = useState([]);

    const parentBlotterDropdown = props.parentBlotters;

    const [statusDropdown, setStatusDropdown] = useState([]);

    useEffect(() => {
        const list = [{name: 'Active', value: 'Active'}, {name: 'Inactive', value:'Inactive'}];
        setStatusDropdown(list);
    }, []);

    const [traderDropdown, tradersNeedsReloading] = useTraders();

    const [blotters, totalPages, blottersNeedReloading] = useBlotters();

    const handleButtonSaveClick = async () => {
        if (validateFields()) {
            const model = {
                'BlotterID': blotterID === '' ? 0 : blotterID,
                'Description': description,
                'BaseCCY': baseCurrency,
                'PositionLimit': positionLimit,
                'ParentBlotterID': parentBlotter === '' ? 0 : parentBlotter,
                'Status': status,
                'AllowProfit': allowProfit,
                'TraderId': trader === '' ? 0 : trader
            }
            console.log(model);
            const json = await saveBlotter(model);
            if (json.httpStatusCode !== 200) {
                setErrors([]);
                setAlertTitle('Error');
                setAlertMessage('An error occurred while trying to save the blotter. Please try again.');
                setShowAlertError(true);
            } else {
                setShowAlert(true);
                setAlertMessage('Blotter successfully saved')
                setAlertTitle('Success');
            }
            clearFields();
            blottersNeedReloading(true);
            tradersNeedsReloading(true);
            props.parentBlottersNeedReloading();
        }
    }

    const handleRowClick = (obj) => {
        setValidations({description: true, status: true});
        setBlotterID(obj.BlotterID);
        setDescription(obj.Description);
        setBaseCurrency(obj['Base Currency']);
        setBaseCurrencyName(obj['Base Currency']);
        setPositionLimit(obj['Position Limit']);
        setParentBlotter(obj['ParentBlotterID'] === null ? '' : obj['ParentBlotterID']);
        setStatus(obj.Status);
        setAllowProfit(obj['Allow Profit']);
        setDisableParentBlotter(true);
        const search = traderDropdown.find(t => t.value === obj['TraderID']);
        if (search === undefined && obj['TraderID'] !== null) {
            traderDropdown.push({name: obj['TraderDescription'], value: obj['TraderID']});
        }
        setTrader(obj['TraderID'] === null ? '' : obj['TraderID']);
    }

    const handleDeleteClick = (obj) => {
        setReadyToDeleteBlotter(obj);
        setShowDeleteAlert(true)
    }

    const handleClearFieldsClick = () => {
        clearFields();
        tradersNeedsReloading(true);
    }

    const viewBlotterLimits = (obj) => {
        props.getSelectedBlotterID(obj.BlotterID);
    }

    const validateFields = () => {
        const fields = [
            {displayName: 'Description', validateName: 'description', value: description, type: 'string'},
            {displayName: 'Status', validateName: 'status', value: status, type: 'string'}
        ];

        const [valid, _validations, _errors] = validate(fields);
        setValidations(_validations);
        if (!valid) {
            setAlertTitle('Error');
            setAlertMessage('Please fill all mandatory fields:')
            setErrors(_errors);
            setShowAlertError(true);
        }

        return valid;
    }

    const changePage = (value) => {
        setPages(value);
    }

    const searchBaseCurrency = async (query) => {
        const json = await searchCurrencies(query, 'active');
        var helper = [];
        if(query !== '') {
            if(json !== undefined) {
                for(var i = 0; i < json.length; i++) {
                    helper.push({value: json[i].CurrencyID, name: json[i].CurrencyIDName})
                }
                if(json.length > 1) {
                    setBaseCurrencies(helper);
                } else {
                    setBaseCurrencies(helper);
                    setBaseCurrency(helper[0].name);
                }
            } else {
                setBaseCurrencies(helper);
            }
        }
    }
    
    const clearFields = () => {
        setValidations({description: true, status: true});
        setDescription('');
        setBaseCurrency('');
        setBaseCurrencyName('');
        setPositionLimit(0);
        setParentBlotter('');
        setStatus('');
        setAllowProfit(false);
        setTrader('');
        setDisableParentBlotter(false);
    }

    const handleGetValueBaseCurrency = (value) => {
        if (value.value !== undefined && value.value !== 'select') {
            setBaseCurrency(value.value);
            setBaseCurrencyName(value.name);
        }
    }

    const handleGetValueParentBlotter = (value) => {
        if (value !== undefined && value !== 'select') {
            value.value = value.value === 'select' ? '' : value.value;
            setParentBlotter(value.value);
        }
    }

    const handleGetValueStatus = (value) => {
        if (value !== undefined && value !== 'select') {
            setValidations({...validations, status: true});
            value.value = value.value === 'select' ? '' : value.value;
            setStatus(value.value);
        }
    }

    const handleGetValueTrader = (value) => {
        if (value !== undefined && value !== 'select') {
            value.value = value.value === 'select' ? '' : value.value;
            setTrader(value.value);
        }
    }

    const handleUpdateDescription = (event) => {
        setValidations({...validations, description: true});
        setDescription(event.target.value);
    }

    const handleUpdatePositionLimit = (event) => {
        setPositionLimit(event.target.value);
    }

    const handleUpdateAllowProfit = (event) => {
        setAllowProfit(event.target.checked);
    }

    const yesDeleteAlert = async () => {
        const json = await deleteBlotter(readyToDeleteBlotter.BlotterID);
        if (json.httpStatusCode !== 200) {
            setAlertTitle('Error');
            setErrors([]);
            let message = 'An error occurred while trying to delete the blotter. Please try again.';
            if(json.Message !== undefined && json.Message !== '')
            {
              message = json.Message;
            }
            setAlertMessage(message);
            setShowDeleteAlert(false);
            setShowAlertError(true);
        } else {
            setShowDeleteAlert(false);
            setShowSuccessDeleteAlert(true);
            clearFields();
            blottersNeedReloading(true);
            tradersNeedsReloading(true);
            props.parentBlottersNeedReloading();
        }
    }

    const okSuccessAlert = () => {
        setShowSuccessDeleteAlert(false);
    }

    const closeDeleteAlert = () => {
        setShowDeleteAlert(false);
        setReadyToDeleteBlotter({});
    }

    const closeAlert = () => {
        setShowAlert(false);
        setAlertTitle('');
        setAlertMessage('');
    }

    const closeAlertError = () => {
        setShowAlertError(false)
        setAlertTitle('');
        setAlertMessage('');
    }

    return (
        <div>
            <form className="uk-form-horizontal uk-margin">
                <div className="uk-grid uk-margin">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Description (*)</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <input className={formatValidInput(validations.description)} type="text" placeholder="" value={description} onChange={handleUpdateDescription}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Base Currency</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <DynamicSelect className="uk-select" id="b-base-currency" getValue={handleGetValueBaseCurrency} placeholder="Select a Currency..." data={currencies} objValue={{value: baseCurrency, name: baseCurrencyName}}/>
                            {/*<DynamicSearchSelect id="b-base-currency" className="uk-select" data={baseCurrencies} objValue={{value: baseCurrency, name: baseCurrencyName}} getValue={handleGetValueBaseCurrency} searchFunction={searchBaseCurrency} placeholder="Select a Currency..." />*/}
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Position Limit</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <NumberInput id="b-position-limit" className="uk-input" type="Currency" 
                            scale={getScaleByCurrency(baseCurrency)}
                            placeholder="" value={positionLimit} onChange={handleUpdatePositionLimit} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >Parent Blotter</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <DynamicSelect id="b-parent-blotter" disabled={disableParentBlotter} className="uk-select" data={parentBlotterDropdown} objValue={{value: parentBlotter}} getValue={handleGetValueParentBlotter} listHasPlaceholder={false} placeholder={"Select a Blotter..."} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Status (*)</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <DynamicSelect id="b-status" className={formatValidSelect(validations.status)} data={statusDropdown} objValue={{value: status}} getValue={handleGetValueStatus} listHasPlaceholder={false} placeholder={"Select a Status..."} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <div className="uk-margin" style={style.inputStyle}></div>
                        <label className="uk-form-label"><input className="uk-checkbox uk-margin-right" value={allowProfit} checked={allowProfit} onChange={handleUpdateAllowProfit} type="checkbox" />Allow Profit</label>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Traders</label>
                        <div className="uk-margin" style={style.inputStyle}>
                            <DynamicSelect id="b-traders" className="uk-select" data={traderDropdown} objValue={{value: trader}} getValue={handleGetValueTrader} listHasPlaceholder={false} placeholder={traderDropdown.length > 0 ? "Select a Trader...": "There are no traders available"} />
                        </div>
                    </div>
                </div>
            </form>
            <div>
                <button className="uk-button uk-button-green uk-margin-right" onClick={handleClearFieldsClick} type="button">Clear Fields</button>
                <button className="uk-button uk-button-green uk-margin-right" onClick={handleButtonSaveClick} type="button">Save</button>
                <hr/>
            </div>
            <NewDynamicTable
                id="blotter-form"
                data={blotters}
                hiddenHeaders={["BlotterID", 'Allow Profit', "ParentBlotterID", "TraderID"]}
                formats={[{header: 'Position Limit', formatFunction: formatToCurrencyWithScale,formatCurrency:'Base Currency'}]}
                dynamicActions={[{name: 'Info', function: viewBlotterLimits, isIcon: true, icon: <InfoCircleOutlined /> }]}
                enableClick={true}
                clickFunction={handleRowClick}
                newSearch={true}
                useDeleteButton={true}
                deleteFunction={handleDeleteClick}
                usePaginator={true}
                changePage={changePage}
                numberOfPages={totalPages}
                pageNumber={pages}
                numberPerPage={getDefaultPagingNumberOfItems()}
                enableSortByHeader={true}
                useIcons={true}
              />
            <AlertBox id="alert-blotter" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert}/>
            <AlertBox id="alert-error-blotter" open={showAlertError} onClose={closeAlertError} title={alertTitle} message={alertMessage} messageBoldList={errors} type="Ok" okClick={closeAlertError}/>
            <AlertBox id="alert-delete-blotter" open={showDeleteAlert} onClose={closeDeleteAlert} title="Warning" message="Are you sure you want to delete these blotter? This action can not be undone." type="Yes/No" yesClick={yesDeleteAlert} noClick={closeDeleteAlert}/>
            <AlertBox id="alert-delete-success-blotter" open={showSuccessDeleteAlert} onClose={closeDeleteAlert} title="Success" message="Blotter successfully removed." type="Ok" okClick={okSuccessAlert}/>
        </div>
    );
}

export default BlotterForm;