import React from 'react';
import { notFilledInputClass, regularInputClass, getStyle, regularSelectClass, notFilledSelectClass } from '../../../styles/styles';
import {getLookUpTable} from '../../../networking/Networking';
import {searchCountries, getCountryById, getCountries} from '../../../networking/NetworkingCountries';
import {getStatesByCountryMin } from '../../../networking/NetworkingStates';
import {searchBanks} from '../../../networking/NetworkingBanks';
import {getBranchesByBankID, getBranchByID, deleteBranch, saveBranch} from '../../../networking/NetworkingBankBranches';
import {getDefaultPagingNumberOfItems} from '../../../helpers/PackageJsonHelper';

import DynamicSelect from '../../shared/DynamicSelect';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
//import NewDynamicTable from '../../shared/NewDynamicTable';
import GridDynamicTable from '../../shared/GridDynamicTable';
import NewEditableGrid from '../../shared/NewEditableGrid';
import Paginator from '../../shared/Paginator';
import AlertBox from '../../shared/AlertBox';
import DatePicker from '../../shared/DatePicker';
import NumberInput from '../../shared/NumberInput';
import EntityProperties from '../../shared/EntityProperties';
import { formatDate } from '../../../helpers/FormatHelper';
import {BankBranchType} from '../../../constants/EntityTypeNames';
import LoadingBox from '../../shared/LoadingBox';
import { validate } from '../../../helpers/ValidateHelper';

import PlusIcon from '../../../css/custom-icons/plus.svg';
import MinusIcon from '../../../css/custom-icons/minus.svg';
import AppContext from '../../../AppContext';
import {Colors} from '../../../constants/Colors'

let globalFunctions = {};
var style = getStyle();

export default class BankBranchForm extends React.Component {
    static contextType = AppContext;
    state = {
        notFirstLoad: false,
        showAlert: false,
        listAddressStatus: [],
        listCountries: [],
        listBanks: [],
        listStates: [],
        listBranches: [],
        statusPlaceHolder: '',
        branchID : 0,
        bankID: 0, //required
        bankName: '', 
        branchName: '', 
        transitNo: '',
        addressStatus: '', 
        address: '', //required
        address2: '',
        bankCountryID: '',  //required
        bankCountryName:'', //required
        stateID: 0,
        stateName:'',
        otherState:'',
        city : '',
        district: '',
        postalCode: '',
        pobox: '',
        riskScore: 0, //required
        sinceWhen:'',
        branchUpdateToken: 0,
        alertTitle: '',
        alertMessage: '',
        newSearch: false,
        totalPages: 0,
        currentPage: 1,
        selectedBranch: {},
        entityPropertiesSaveProcess: false,
        loading: false,
        validations:{
            bank: true,
            address: true,
            country: true,
            riskScore: true
        },
        errors: []        
    }
    
    componentDidMount() {
        globalFunctions = this.context;
        console.log(regularInputClass);
        globalFunctions.startLoading();
        getLookUpTable('Customer Address Status').then(
            (json) => {
                var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
                var helper = [];
                for(var i=0; i<lookUpTableDetails.length; i++){
                    lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                    lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                    helper.push(lookUpTableDetails[i]);
                    //helper.push({value: lookUpTableDetails[i].LookUpTableDetail, name: lookUpTableDetails[i].LookUpTableDetail})
                }
                this.setState({listAddressStatus: helper});
                globalFunctions.finishLoading();
            }
        );
        getCountries(1, 250).then(
            (json) => {
                if (json != null && json.countries != null) {
                    const countries = json.countries.map(c => ({ ...c, name: c["Country Name"], value: c.Country }));
                    this.setState({ listCountries: countries }, () => this.loadBank());
                }
            }
        );
    }
    componentDidUpdate(prevProps) {
        if (this.props.selectedBank !== prevProps.selectedBank) {
            console.log(this.props.selectedBank);
            this.loadBank();
        }
    }

    loadBank = () => {
        this.setState({
            bankID: this.props.selectedBank['BankID'], bankName: this.props.selectedBank['BankName'],
            branchID: 0,
            branchName: '', //required
            addressStatus: '', //required
            address: '', //required
            address2: '',
            bankCountryID: '',  //required
            bankCountryName: '', //required
            bankRiskScore: 0, //required
            stateID: 0,
            stateName: '',
            otherState: '',
            city: '',
            district: '',
            postalCode: '',
            pobox: '',
            sinceWhen: '',
            transitNo: '',
            branchUpdateToken: 0
        }, () => {

            getBranchesByBankID(this.props.selectedBank['BankID'], 1, getDefaultPagingNumberOfItems()).then(
                (value) => {
                    if (value !== undefined && value.branches !== undefined) {
                        var branches = value.branches//this.transformBankBranchList(value.branches);
                        this.setState({ listBranches: branches });
                        var totalCount = value.totalCount;
                        var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                        this.setState({ totalPages: pages, newSearch: true });
                    }
                    else {
                        this.setState({ listBranches: [] });
                        this.setState({ totalPages: 0, newSearch: false });
                    }
                }
            );
        })
    }
    validateFields = () => 
    {
        const fields = [
            {displayName: 'Bank', validateName: 'bank', value: this.state.bankID, type: 'number'},
            {displayName: 'Address', validateName: 'address', value: this.state.address, type: 'text'},
            {displayName: 'Country', validateName: 'country', value: this.state.bankCountryID, type: 'number'}
        ];

        const [valid, validations, errors] = validate(fields);

        this.setState({
            validations: validations
        });

        if (!valid) {
            this.setState({
                showAlert: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors: errors
            });
        }

        return valid;
    }

    searchCountry(query){ 
        searchCountries(query).then(
            (json) => {
                var helper = [];
                if(query !== ''){
                    console.group('Search Countries');
                    console.log('Query');
                    console.table(query);
                    console.log('Response');
                    console.table(json);
                    console.groupEnd();
                for(var i=0; i<json.length; i++){
                    json[i].value = json[i].CountryId;
                    json[i].name = json[i].CountryIdName;
                    helper.push(json[i]);
                    //helper.push({value: json[i].CountryId, name: json[i].CountryIdName})
                }
                console.log(helper);
                this.setState({listCountries: helper});
                if(helper.length === 1){
                    var value = helper[0];
                    this.setState({bankCountryID: value.CountryId, bankCountryName: value.CountryIdName});
                }
            }}
        );
    }

    searchBank(query){ 
        searchBanks(query).then(
            (json) => {
                var helper = [];
                if(query !== ''){
                    if(json!== '' && json.banks !== undefined) {
                        var banks = json.banks;
                    for(var i = 0 ; i < banks.length; i++) {
                        banks[i].value = banks[i]["BankID"];
                        banks[i].name = banks[i]["BankName"];
                        helper.push(banks[i]);
                    }
                }
                this.setState({listBanks: helper});
                if(helper.length === 1){
                    var value = helper[0];
                    this.setState({bankID: value.BankID, bankName: value.BankName});
                }
            }}
        );
    }

    getAddressStatusSelect(value){
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({addressStatus: value.value !== "select" ? value.value : ""});
        }
    }

    getBranchNameValue(event){
        this.setState({branchName: event.target.value});
    }

    getTransitNoValue(event){
        this.setState({transitNo: event.target.value});
    }

    getAddressValue(event){
        this.setState({address: event.target.value});
    }

    getAddress2Value(event){
        this.setState({address2: event.target.value});
    }

    getCityValue(event){
        this.setState({city: event.target.value});
    }

    getDistrictValue(event){
        this.setState({district: event.target.value});
    }

    getOtherStateValue(event){
        this.setState({otherState: event.target.value});
    }

    getPostalCodeValue(event){
        this.setState({postalCode: event.target.value});
    }

    getPOBoxValue(event){
        this.setState({pobox: event.target.value});
    }

    getRiskScoreValue(event){
        this.setState({riskScore: event.target.value});
    }

    handleUpdateSinceWhen(date) {
        this.setState({sinceWhen: formatDate(date)});
    }

    handleGetValueCountry(value) {    
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ bankCountryID: value.value !== "select" ? value.value : "", bankCountryName: value.name != null ? value.name : "" });
            this.getStates(value.Country);
        }
    }

    getStates(CountryId)
    {
        getStatesByCountryMin(CountryId).then(
            (json) => {
                var helper = [];
                if(json.value !== '' && json.states !== undefined) {
                    var states = json.states;
                for(var i = 0 ; i < states.length; i++) {
                    states[i].value = states[i]["State ID"];
                    states[i].name = states[i]["State Name"];
                    helper.push(states[i]);
                }
                this.setState({listStates: helper});
                }
                else
                {
                    this.setState({listStates: []}); 
                }   
        });
    }

    handleGetValueState(value) {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ stateID: value.value, stateName: value.name });
        }
    }

    handleGetValueBank(value) {        
        if (value.BankID !== undefined) {
            console.log(this.state.bankID);
            this.setState({ bankID: value.BankID});
            getBranchesByBankID(value.BankID,1,getDefaultPagingNumberOfItems()).then(
                (value) => {
                    if(value !== undefined && value.branches !== undefined)
                    {
                    var branches = value.branches//this.transformBankBranchList(value.branches);
                    this.setState({listBranches: branches});
                    var totalCount = value.totalCount;
                    var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                    this.setState({totalPages: pages, newSearch:true});
                    }
                    else
                    {
                        this.setState({listBranches: []});
                        this.setState({totalPages: 0, newSearch:false});
                    }
                }
            );
        }
    }

    transformBankBranchList(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'Branch ID': list[i]['Branch ID'],
            'Branch': list[i]['Branch'], 
            'Bank': list[i]['Bank'], 
            'Address': list[i]['Address'], 
            'Transit No': list[i]['Transit Number'], 
            'Risk Score': list[i]['Risk Score']});
        }
        return helper;
    }


    handleClearClick(){
        this.setState({
            branchID: 0,
            branchName: '', //required
            addressStatus: '', //required
            address: '', //required
            address2: '',
            bankCountryID: '',  //required
            bankCountryName:'', //required
            bankRiskScore: 0, //required
            bankID: 0,
            bankName: '',
            stateID: 0,
            stateName: '',
            otherState:'',
            city:'',
            district: '',
            postalCode: '',
            pobox:'',
            sinceWhen:'',
            transitNo:'',
            branchUpdateToken: 0,
            //listBranches:[]
        });
    }

    handleSaveClick(){
        let userID = localStorage.getItem('UserID');
        
        var model = {
            branchRequest:{
                BranchID: this.state.branchID,
                BankID: this.state.bankID,            
                BranchName: this.state.branchName,
                TransitNumber: this.state.transitNo,
                RiskScore: this.state.riskScore,
                UpdateToken: this.state.branchUpdateToken,
                UpdatedBy: userID},
            addressRequest:{
                AddressValue: this.state.address,
                Address2: this.state.address2,
                Status: this.state.addressStatus,
                CountryID: this.state.bankCountryID,
                StateID: this.state.stateID,
                OtherState: this.state.otherState,
                City: this.state.city,
                District: this.state.district,
                ZipCode: this.state.postalCode,
                POBox: this.state.pobox,
                SinceWhen: this.state.sinceWhen}
            
        }
        
        console.table(model);
        if(this.validateFields()){
            saveBranch(model).then((json)=>
            {
                if (json.httpStatusCode !== 200){
                    var alertMsg = 'An error occurred while trying to save the bank branch. Please try again.'
                    if(json.httpErrorMessage !== undefined && json.httpErrorMessage !== ''){
                        alertMsg = json.httpErrorMessage;
                    }
                    this.setState({showAlert: true, alertMessage: alertMsg, alertTitle: 'Error'});
                } else {
                    this.setState({branchUpdateToken: json.updateToken, branchID: json.branchId, showAlert: true, alertTitle: 'Bank branch successfully saved.', entityPropertiesSaveProcess: true});
                    this.updatePage();
                }
            })
        } else {
            this.setState({notFirstLoad: true});
        }
    }

    closeAlert =()=>{
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    validateRequiredInputs(){
        
        if(this.state.bankID === undefined){
            return false;
        }
        if(this.state.address === ''){
            return false;
        }
        if(this.state.riskScore === ''){
            return false;
        }
        if(this.state.bankCountryID === undefined){
            return false;
        }
        return true;
    }

    getCountry(id){
        getCountryById(id).then(
            (json) => {
                console.log(json);
                var country = json.country[0];
                var name = country['Country Id'] + ' - ' + country['Country Name'];
                this.setState({bankCountryID: country['Country Id'], bankCountryName: name});
            }
        );
    }

    handleRowClick(obj){
        debugger
        getBranchByID(obj['Key §H']).then(
            (value)=>{ 
                console.table(value);
                this.getCountry(value['Country ID']);
                //this.setState({ stateID: value["Province"]});
                
                this.setState({
                    branchID : value['Branch ID'],
              //      bankID: bankID,
                    branchName: value["Branch Name"],
                    transitNo: value['Transit Number'],
                    addressStatus: value["Address Status"] != null ? value["Address Status"] : '',
                    address: value["Address Value"],
                    address2: value["Address2"],
               //     bankCountryID: value["CountryID"],  
                    stateID: value["StateID"],
                    stateName: value["StateName"],
                    otherState: value["OtherState"],
                    city : value["City"],
                    district: value["District"],
                    postalCode: value["ZipCode"],
                    pobox: value["POBox"],
                    riskScore: value["Risk Score"], //required
                    sinceWhen:value["Since When"],
                    branchUpdateToken: value["UpdateToken"]
                });

                this.getStates(value['Country ID']);
                this.setState({stateID: value["StateID"],
                stateName: value["StateName"]});
            }
        );
           
    }

    closeDeleteAlert(){
        this.setState({showDeleteAlert: false, readyToDeleteBank: {}});
    }

    openDeleteAlert(){
        this.setState({showDeleteAlert: true});
    }

    yesDeleteAlert(){
        deleteBranch(this.state.readyToDeleteBank['Key §H']).then(
            (value)=>{
                this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true});                
                this.updatePage();
        });
    }

    okSuccessAlert(){
        this.setState({showSuccessDeleteAlert: false});
    }

    handleDeleteClick(obj){
        console.group("BankBranch handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteBank: obj});        
    }

    changePage(value){
        this.setState({currentPage: value});
        getBranchesByBankID(this.state.bankID,value,getDefaultPagingNumberOfItems()).then(
            (json) => {
                if(json !== undefined && json.branches !== undefined)
                {
                    var branches = value.branches//this.transformBankBranchList(json.branches);
                    this.setState({listBranches: branches});
                    var totalCount = json.totalCount;
                    var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                    this.setState({totalPages: pages, newSearch:false});
                    globalFunctions.finishLoading();
                }
                else
                {
                    this.setState({listBranches: []});
                    this.setState({totalPages: 0, newSearch:false});
                    globalFunctions.finishLoading();
                }
            }
            
        );
    }

    updatePage(){
        //this.props.updatePage();
        getBranchesByBankID(this.state.bankID,this.state.currentPage,getDefaultPagingNumberOfItems()).then(
            (json) => {
                if(json !== undefined && json.branches !== undefined)
                {
                    var branches = json.branches//this.transformBankBranchList(json.branches);
                    this.setState({listBranches: branches});
                    var totalCount = json.totalCount;
                    var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                    this.setState({totalPages: pages, newSearch:false});
                    globalFunctions.finishLoading();
                }
                else
                {
                    this.setState({listBranches: []});
                    this.setState({totalPages: 0, newSearch:false});
                    globalFunctions.finishLoading();
                }
            }
        );
    }

    onFinishEntityPropertiesSaveProcess(){
        this.setState({entityPropertiesSaveProcess: false});
    }

    render(){

        var controlState = <input className="uk-input" id="form-branch-other-state" type="text" placeholder="Other State" value={this.state.otherState} onChange={this.getOtherStateValue.bind(this)}/>;

        if(this.state.listStates !== undefined && this.state.listStates.length > 0)
        {
            controlState = <DynamicSelect getValue={this.handleGetValueState.bind(this)} listHasPlaceholder={false} placeholder={"Select a State..."} data={this.state.listStates} objValue={{value: this.state.stateID, name: this.state.stateName, "State ID": this.state.stateID, "State Name": this.state.stateName}} id="form-provinces-states" />;
        }

        //for required inputs
        var classNameInputAddress = regularInputClass;
        var classNameInputBank = regularInputClass; 
        var classNameInputCountry = regularSelectClass;
        var classNameInputRiskScore = regularInputClass;

        if(this.state.address === '' && this.state.notFirstLoad){
            classNameInputAddress=notFilledInputClass;
        }
        if((this.state.bankID === undefined || this.state.bankID === 0) && this.state.notFirstLoad){
            classNameInputBank = notFilledInputClass;
        }
        if(this.state.riskScore === '' && this.state.notFirstLoad){
            classNameInputRiskScore = notFilledInputClass;
        }
        if((this.state.bankCountryID === undefined || this.state.bankCountryID === '') && this.state.notFirstLoad){
            classNameInputCountry = notFilledSelectClass;
        }

        var bankBranchPropertyId = 'bank-branch-property'
      
        return(
            <div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-name">Bank (*)</label>
                            <div className="uk-form-controls">
                            <DynamicSearchSelect className={classNameInputBank} id="form-bank-branch-name" placeholder="Select a Bank..." searchFunction={this.searchBank.bind(this)} getValue={this.handleGetValueBank.bind(this)} data={this.state.listBanks} objValue={{value: this.state.bankID, name: this.state.bankName, BankID: this.state.bankID, BankName: this.state.bankName}}/>
                              </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-name">Branch Name</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-branch-name" type="text" placeholder="Branch Name" value={this.state.branchName} onChange={this.getBranchNameValue.bind(this)}/>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-transitno">Transit No</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-branch-swift" type="text" placeholder="Transit No" value={this.state.transitNo} onChange={this.getTransitNoValue.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-address-status">Address Status</label>
                            <div className="uk-form-controls">
                            <DynamicSelect getValue={this.getAddressStatusSelect.bind(this)} listHasPlaceholder={true} data={this.state.listAddressStatus} id="form-branch-address-status" objValue={{value: this.state.addressStatus, LookUpTableDetail: this.state.addressStatus}}/>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-address">Address (*)</label>
                            <div className="uk-form-controls">
                            <input className={classNameInputAddress} id="form-branch-address" type="text" placeholder="Address" value={this.state.address} onChange={this.getAddressValue.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-address2">Address 2</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-branch-address2" type="text" placeholder="Address 2" value={this.state.address2} onChange={this.getAddress2Value.bind(this)}/>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-country">Country (*)</label>
                            <div className="uk-form-controls">
                            <DynamicSelect className={classNameInputCountry} id="form-branch-country" placeholder="Select a Country..." getValue={this.handleGetValueCountry.bind(this)} data={this.state.listCountries} objValue={{value: this.state.bankCountryID, name: this.state.bankCountryName, CountryId: this.state.bankCountryID, CountryIdName: this.state.bankCountryName}}/> 
                            {/* <DynamicSearchSelect className={classNameInputCountry} id="form-branch-country" placeholder="Select a Country..." searchFunction={this.searchCountry.bind(this)} getValue={this.handleGetValueCountry.bind(this)} data={this.state.listCountries} objValue={{value: this.state.bankCountryID, name: this.state.bankCountryName, CountryId: this.state.bankCountryID, CountryIdName: this.state.bankCountryName}}/>  */}
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                                <label className="uk-form-label" htmlFor="form-provinces-states" >Province / State</label>
                                <div className="uk-form-controls" >
                                {controlState}
                                </div>
                    </div>

                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-city">City</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-branch-city" type="text" placeholder="City" value={this.state.city} onChange={this.getCityValue.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-district">District</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-branch-district" type="text" placeholder="District" value={this.state.district} onChange={this.getDistrictValue.bind(this)}/>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-postal-code">Postal / Zip Code</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-branch-postal-code" type="text" placeholder="Postal / Zip Code" value={this.state.postalCode} onChange={this.getPostalCodeValue.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-pobox">PO Box</label>
                            <div className="uk-form-controls">
                            <input className="uk-input" id="form-branch-pobox" type="text" placeholder="PO Box" value={this.state.pobox} onChange={this.getPOBoxValue.bind(this)}/>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-risk-score">Risk Score (*)</label>
                            <div className="uk-form-controls">
                                <NumberInput className={classNameInputRiskScore} placeholder="Risk Score" id="form-branch-risk-score" type="Integer"  value={this.state.riskScore} onChange={this.getRiskScoreValue.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-branch-risk-score">Since When</label>
                            <div className="uk-form-controls">
                            <DatePicker className="uk-input" value={this.state.sinceWhen} onDayChange={this.handleUpdateSinceWhen.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <h5 
                            className="uk-heading-divider properties-tasks-category-title-new1" 
                            uk-toggle="target : #propertiesSection;"
                            style={{...getStyle().titleStyle, cursor: 'pointer', backgroundColor: Colors.primaryColor, backgroundImage: 'none'}}
                        >Additional Properties
                            <img 
                                id="propertiesSection"
                                src={PlusIcon}
                                //className="fa fa-fw fa-plus" 
                                style={{position: 'absolute',
                                paddingTop: '16px',
                                right: '30px', color: 'black'}}
                            />
                            <img 
                                id="propertiesSection" 
                                src={MinusIcon}
                                //className="fa fa-fw fa-minus" 
                                style={{position: 'absolute',
                                paddingTop: '16px',
                                right: '30px', color: 'black'}} 
                                hidden
                            />
                        </h5>
                        <div id="propertiesSection" hidden>
                            <div className="uk-width-1-1 uk-margin">
                                <EntityProperties 
                                    id={bankBranchPropertyId} 
                                    entityType={BankBranchType} 
                                    entityID={this.state.branchID} 
                                    runSaveProcess={this.state.entityPropertiesSaveProcess} 
                                    finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)}
                                    fullScreen={true}
                                />
                            </div>
                        </div>
                    </div>       
                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleSaveClick.bind(this)}>Save</button>
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleClearClick.bind(this)}>Clear Fields</button>
                    </div>
                </form>
                {/*<NewDynamicTable data={this.state.listBranches} enableClick={true} clickFunction={this.handleRowClick.bind(this)} newSearch={this.state.newSearch} useDeleteButton={true} deleteFunction={this.handleDeleteClick.bind(this)}/>*/}
                {/*<GridDynamicTable 
                    data={this.state.listBranches} 
                    enableClick={true} 
                    clickFunction={this.handleRowClick.bind(this)} 
                    newSearch={this.state.newSearch} 
                    useDeleteButton={true} 
                    deleteFunction={this.handleDeleteClick.bind(this)}
        />*/}
                <NewEditableGrid
                    data={this.state.listBranches}
                    enableClick={true} 
                    clickFunction={this.handleRowClick.bind(this)}
                    useDeleteButton={true} 
                    deleteFunction={this.handleDeleteClick.bind(this)}
                    useExportButton = {true}
                    useIcons={true}
                    tableName = "bankBranches" />
                <Paginator changePage={this.changePage.bind(this)} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch}/>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert}/>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this bank branch?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Bank Branch successfully removed." message="" type="Ok" okClick={this.okSuccessAlert.bind(this)}/>
                {/*<LoadingBox loading={this.state.loading}/>*/}
            </div>
        );
    }
}