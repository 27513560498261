import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DealVersionInformationScreen from './DealVersionInformationScreen';

import NewDynamicTable from '../shared/NewDynamicTable';
import AlertBox from '../shared/AlertBox';
import { formatDatetime } from '../../helpers/FormatHelper';

import {getDefaultPagingNumberOfItems} from '../../helpers/PackageJsonHelper';

import {getStyle} from '../../styles/styles'
import { getDealVersionComparer, getDealVersions, getMonitorDeals } from '../../networking/NetworkingDeals';

var style = getStyle();

const initialDate = new Date();
export default class DealVersionsScreen extends React.Component {
    state = {        
        dealVersions: [],
        dealDifferences: [],
        selectedVersions: [],
        selectedVersion1: 0,
        selectedVersion2: 0,
        viewMode: false,

        voidDeals: false,
        dealOriginType: '',
        dealOriginList: [],
        pages: 1,
        totalPages: 0,
        currentPage: 1,
        newSearch: false,
        showAlertOK: false,
        alertTitle: '',
        alertMessage: '',
        openDetail: false,
        objVersion:{},
        differencesDisabled:true,
        hiddenVersions: true,
        hiddenDetails: true
    }

    componentDidMount(){
        getDealVersions(this.props.dealID).then(
            (value) => {
                console.log(value);
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                console.log(pages);
                if(totalCount === 0){
                    this.setState({dealVersions: [], totalPages: pages, newSearch: true});
                }else{
                    this.setState({dealVersions: value.result, totalPages: pages, newSearch: true});
                }
            }
        );
    }

    changePage = (value) => {
        this.setState({pages: value});
    }


    handleDifferencesClick(){
        if(this.state.viewMode)
        {
            //debugger;
            if(this.state.selectedVersions.length == 1)
            {
                var objVersion = this.state.dealVersions.find(v => v["Version Number"] === this.state.selectedVersions[0]);
                if(objVersion)
                {
                    this.setState({objVersion: objVersion, openDetail: true});
                }
            }
        }
        else
        {
            getDealVersionComparer(this.props.dealID, this.state.selectedVersion1, this.state.selectedVersion2).then(
                (value) => {
                    console.log(value);
                    var pages = 1;
                    console.log(pages);
                    debugger
                    if(value === undefined){
                        this.setState({dealDifferences: [], totalPages: pages, newSearch: true, hiddenVersions: false, hiddenDetails: true});
                    }else{
                        this.setState({dealDifferences: value.data, totalPages: pages, newSearch: true, hiddenVersions: false, hiddenDetails: true});
                    }
                }, ()=>{this.setState({selectedVersion1: 0, selectedVersion2: 0})}
            );
        }
    }

    getDealOriginSelect(value) {        
        if (value != null && value.value !== undefined) {
            if(value.value === 'select'){
                this.setState({dealOriginType: ''},()=>{this.getListOfMonitoredDeals()});
            }else{
                this.setState({dealOriginType: value.value},()=>{this.getListOfMonitoredDeals()});
            }            
        }
    }

    handleUpdateDealOriginSelect(event) {
        this.setState({
            //validations: { ...this.state.validations, percentOrPoints: true },
            dealOriginType: event.target.value
        },()=>{this.getListOfMonitoredDeals()});
    }

    handleOnCheck(event) {
        console.log(event.target.checked);
        this.setState({ voidDeals: event.target.checked },()=>{this.getListOfMonitoredDeals()});
    }

    closeAlertOK(){
        this.setState({showAlertOK: false, alertMessage: '', alertTitle: '', updateContact: true});
    }

    getAllDealMonitor = async () =>{
        let date = new Date();
        var json = await getMonitorDeals(formatDatetime(initialDate),formatDatetime(date),this.state.voidDeals, this.state.dealOriginType,0,0);
        var rates = this.transformRateList(json.result);
        return rates;
    }

    handleExitClick(event){
        //event.preventDefault();
        this.props.backFromDealVersions();
    }

    /*handleSelectClick = (obj) =>
    {
        if(obj["Version Number"] !== undefined)
        {
            if(this.state.selectedVersion1 === 0){
                this.setState({selectedVersion1: obj["Version Number"]});
            }else{
                this.setState({selectedVersion2: obj["Version Number"]});
            }
        }
    }*/

    orderSelectedVersions(){

    }

    handleSelectClick = (rowKeys) => {
       /* var exists = selectedVersions.findIndex(v => v["Version Number"] === rowKey);
        
        if (exists >= 0){
            selectedVersions.splice(selectedVersions.findIndex(v => v["Version Number"] === rowKey), 1);
        }
        else{
            if(selectedVersions.length < 2)
            {
                selectedVersions.push(rowKey);
            }
        }*/
        var viewMode = false;

        
        if(rowKeys.length == 1)
        {
            viewMode = true;
        }
        var differencesDisabled = true;

        if(rowKeys.length < 3 && rowKeys.length > 0)
        {
            differencesDisabled = false;
        }

        this.setState({
            viewMode: viewMode,
            selectedVersions: rowKeys,
            selectedVersion1: rowKeys[0] != null ? rowKeys[0] : 0,
            selectedVersion2: rowKeys[1] != null ? rowKeys[1] : 0,
            differencesDisabled: differencesDisabled
        });
    }

    handleExitClick = () =>
    {
        this.setState({openDetail: false});
    }

    onHiddenDetails = () =>
    {
        this.setState({hiddenDetails:true});
    }

    onShowDetails = () =>
    {
        let hidden = !this.state.hiddenDetails;
        this.setState({hiddenDetails:hidden});
    }

    onHiddenVersions = () =>
    {
        debugger
        this.setState({hiddenVersions: true});
    }

    onShowVersions = () =>
    {
        let hidden = !this.state.hiddenVersions;
        this.setState({hiddenVersions:hidden});
    }

    render(){

        return(
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}><button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleExitClick.bind(this)}>
                            <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                            </button>Deal Versions</h3>
                <div className="uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >To view details of a single version of a deal, select the desired version line in the table below and click on "View".</label>
                        <label className="uk-form-label" >To view the differences between 2 versions, select the desired two versions and click on "Differences". The differences will be displayed in the 'Deal Version Differences' section below.</label>
                    </div>
                    <div className="uk-margin-right">
                        <div className="" style={style.inputStyle}>
                            <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "35px"}} disabled={this.state.differencesDisabled} onClick={this.handleDifferencesClick.bind(this)} type="button">{(this.state.viewMode?"View":"Differences")}</button>
                        </div>
                    </div>
                    {/*<div className="uk-margin-right">
                        <div className="" style={style.inputStyle}>
                            <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "35px"}} onClick={this.handleAddCommentClick.bind(this)} type="button">Refresh</button>
                        </div>
                    </div>
                    <div className="uk-margin-right">
                        <div className="" style={{paddingTop: 40}}>
                            <label className="uk-form-label" htmlFor="form-void-deals"><input className="uk-checkbox" id="voidDeals" type="checkbox" checked={this.state.voidDeals} onChange={this.handleOnCheck.bind(this)} />Differences</label>
                        </div>
                    </div>*/}
                    <div className="uk-width-1"> 
                        <div className="uk-margin" style={style.inputStyle}>
                            <br></br>
                        </div>
                    </div>
                </div>
                <h5 className="uk-heading-divider" 
                                uk-toggle="target : #dealsVersionSection;" onClick={this.onShowVersions}
                                style={{...getStyle().titleStyle, cursor: 'pointer'}}>List of Deal Versions<i id="dealsVersionSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}} hidden={!this.state.hiddenVersions}  onClick={this.onShowVersions}/><i id="dealsVersionSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} hidden={this.state.hiddenVersions} onClick={this.onShowVersions}/>
                            </h5>
                <div id="dealsVersionSection" hidden={!this.state.hiddenVersions}>
                <NewDynamicTable
                    key="deal-version-list"                    
                    hiddenHeaders={['Trader','Branch', 'Blotter', "DealType", "DealOrigin", "Name", "MaturityDate", "SettlementDeliveryMethods", "BuyCurrency",
                     "BuyAmount", "SellCurrency", "SellAmount", "OriginalMarketSpotRate", "OriginalCustomerRate", "MarketRate", "CustomerRate", "ForwardPoints",
                      "ProfitCurrency", "ProfitAmount", "TotalDisbursed", "InputBy", "VoidBy", "VoidTS", "ExternalComments", "InternalComments", "Status", "ApprovedBy",
                       "ApprovedTS", "BatchId", "BatchDate", "CustomerRefNo", "LinkQuoteNo", "BankAccount", "NotificationTS", "NotificationMethod", "ForwardType", "RequireDeposits",
                        "DepositPercent", "ConfirmedByClient", "ConfirmedTS", "DealPaidStatus", "DealDisbursementStatus", "OriginalForwardPoints", "OnlineSettlement", "CashIndicator",
                         "BuyBaseCCYSpotMarketRate", "BuyBaseCCYFwdMarketRate", "BuyBaseCCYFwdPoints", "TotalFeesAmount", "DepositComments", "FullSettledDate", "IsRateDirect", "BankName"]} 
                    data={this.state.dealVersions}
                    useSelection={true}  
                    onChangeSelection={this.handleSelectClick} 
                    selectionID={"Version Number"}
                    selectedRows={this.state.selectedVersions}
                    enableClick={false} 
                    newSearch={this.state.newSearch} 
                    useDeleteButton={false}
                    enableSortByHeader={true}
                    usePaginator={false}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={getDefaultPagingNumberOfItems()}/>
                </div>
                <h5 className="uk-heading-divider" 
                                uk-toggle="target : #differencesSection;" onClick={this.onShowDetails}
                                style={{...getStyle().titleStyle, cursor: 'pointer'}}>List of Differences<i id="differencesSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}} hidden={!this.state.hiddenDetails} onClick={this.onShowDetails}/><i id="differencesSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} onClick={this.onShowDetails} hidden={this.state.hiddenDetails}/>
                            </h5>
                <div id="differencesSection" hidden={!this.state.hiddenDetails}>
                    <NewDynamicTable
                        key="deal-version-differences"                    
                        hiddenHeaders={['DealHeaderID']} 
                        data={this.state.dealDifferences}
                        enableClick={false} 
                        newSearch={this.state.newSearch} 
                        useDeleteButton={false}
                        enableSortByHeader={true}
                        usePaginator={false}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.pages}
                        numberPerPage={getDefaultPagingNumberOfItems()}/>
                </div>
                <AlertBox open={this.state.showAlertOK} onClose={this.closeAlertOK.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertOK.bind(this)}/>
                
                <Dialog open={this.state.openDetail} onClose={this.handleExitClick} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>Deal Versions</DialogTitle>
                <DialogContent dividers>
                    <DealVersionInformationScreen objVersion = {this.state.objVersion}/>
                </DialogContent>
                <DialogActions>
                    <button className="uk-button uk-button-green" key={"exit"} onClick={this.handleExitClick}>Exit</button>
                </DialogActions>
                </Dialog>
            </div>
        );
    }
}