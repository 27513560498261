import React, { useEffect, useState } from 'react';
import { dynamicGetWithParameters } from '../../../networking/NetworkingDynamic';
import DynamicScreenForm from './DynamicScreenForm';
import NewDynamicTable from '../NewDynamicTable';
import AlertBox from '../AlertBox';

const DynamicScreen = (props) =>{
    const [objJson, setObjJson] = useState({});
    const [objJsonNew, setObjJsonNew] = useState({});
    const [title, setTitle] = useState('');
    const [methodList, setMethodList] = useState('');
    const [methodDetail, setMethodDetail] = useState('');
    const [methodSubmit, setMethodSubmit] = useState('');
    const [methodDelete, setMethodDelete] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [pages, setPages] = useState(0);
    const [newSearch, setNewSearch] = useState(false);
    const [list, setList] = useState([]);
    const [showList, setShowList] = useState(true);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [deleteID, setDeleteID] = useState('');
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [keyColumn, setKeyColumn] = useState('');
    const [showAlert, setShowAlert] =useState(false);
    const [alertTitle, setAlertTitle] = useState ('');
    const [alertMessage, setAlertMessage] = useState('');


    useEffect(()=>{
        if(props.screenName)
        {
            loadScreenData();
        }
    },[props.screenName])

    useEffect(()=>{
        if(methodList)
        {
            loadList();
        }
    },[methodList])

    const loadScreenData = async(json) =>{
       
        let params = `{ "DynamicScreenName" : "${props.screenName}"}`

        let response  = await dynamicGetWithParameters('usp_react_DynamicScreens_GetByName', params);

        if(response && response.length >0)
        {
            let data = response[0];
            setTitle(data.Description);
            setMethodSubmit(data.SubmitMethod);
            setMethodList(data.GetMethodList);
            setMethodDetail(data.GetMethodDetail);
     
            if(data.DeleteMethod)
            {
                setMethodDelete(data.DeleteMethod);
                setShowDeleteButton(true);
            }
            if(data.JSONStructure)
            {
                let json = JSON.parse(data.JSONStructure);
                loadListData(json);

                let keyID = json.filter(o => o.isKEY);            
                if(keyID && keyID.length > 0)
                {
                    setKeyColumn(keyID[0].id);
                }
            }
        }
                
   }

    const loadListData = async(json) =>{

         await json.map(async(item) =>{
            if(item.type === 'date' && item.disabledDays){
                let stringArray = item.disabledDays.split(",");
                let numberArray = stringArray.map(Number);
                item.disabledDays  = numberArray;
            }
            if(item.type === 'list' && item.data && (item.method === undefined || item.method === '')){
                item.data  = JSON.parse(item.data);
            }
            if(item.type === 'list' && item.method && (item.filterID === undefined || item.filterID === ''))
            {
                item.data  = await dynamicGetWithParameters(item.method, item.params);
                
                setObjJson(json);
                setObjJsonNew(json);
            }
        });        
    }

    const loadList = async() =>{
        const value = await dynamicGetWithParameters(methodList, "{}");
        if (value !== undefined && value.length > 0) {
            const totalCount = value.length;
            const totalPages = Math.ceil(totalCount / 15);
            setTotalPages(totalPages);
            setPages(1);
            setNewSearch(true);
            setList(value);            
        } else {
            setTotalPages(0);
            setPages(0);
            setNewSearch(true);
            setList([]);
            setKeyColumn('');
        }
    }

    const handleRowClick = async(obj) =>{    
        if(keyColumn)
        {
            let params = {};
            params[keyColumn] = obj[keyColumn];

            let result  = await dynamicGetWithParameters(methodDetail, params);
            if(result && result.length > 0)
            {
                let json = JSON.parse(JSON.stringify(objJsonNew));
                let detail = result[0];
                
                json.map(i => i.value = detail[i.id]);

                setObjJson(json);
                setShowList(false);
            }
        }

    }

    const handleDelete = (obj) =>{
        if(methodDelete)
        {
            if(keyColumn)
            {
                setDeleteID(obj[keyColumn]);
                setShowDeleteAlert(true);
            }
        }
    }

    const closeDeleteAlert = () =>{
        setShowDeleteAlert(false);
        setDeleteID('');
    }

    const yesDeleteAlert = async() =>{
        setShowDeleteAlert(false);
        if(methodDelete && deleteID)
        {
            let params = {};
            params[keyColumn] = deleteID;

            let result = await dynamicGetWithParameters(methodDelete, params); 
            if(result && result.httpErrorMessage)
            {
                setAlertTitle('Error');
                setAlertMessage(result.httpErrorMessage);
                setShowAlert(true);
            }
            else
            {
                if(result && result.length > 0 && result[0].Result && result[0].Message)
                {
                    setAlertTitle(result[0].Result);
                    setAlertMessage(result[0].Message);
                    if(result[0].Result.toLowerCase() === 'success')
                    {
                        loadList();                    
                    }
                    setShowAlert(true);
                }
            }
        }
    }

    const openNewForm = () => {
        let json = [...objJsonNew];
        setObjJson(json);
        setShowList(false);
    }

    const changePage = (value) => {
        setPages(value);      
    }

    const onCancel = (reload) =>{
        setShowList(true);
        if(reload)
        {
            loadList();
        }
    }

    const closeAlert = () => {
        setShowAlert(false);
    }

    let screen = showList ? <> 
            <div className="uk-margin-right" style={{marginBottom:'20px'}}>
                    <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "40px"}} onClick={openNewForm} type="button">Add</button>
            </div>
            <NewDynamicTable
                data={list}
                enableClick={true}
                clickFunction={handleRowClick}
                newSearch={newSearch}
                useDeleteButton={showDeleteButton}
                deleteFunction={handleDelete}
                usePaginator={true}
                changePage={changePage}
                numberOfPages={totalPages}
                pageNumber={pages}
                numberPerPage={15}
                enableSortByHeader={true}
                useIcons={true}
            />
            <AlertBox
                    id="delete-alert"
                    open={showDeleteAlert}
                    onClose={closeDeleteAlert}
                    title="Warning"
                    message="Are you sure you want to delete this record? This action can not be undone."
                    type="Yes/No"
                    yesClick={yesDeleteAlert}
                    noClick={closeDeleteAlert}
                />
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert}/>
            </>
            : <DynamicScreenForm title={title} objJson={objJson} onCancel={onCancel} submitMethod={methodSubmit} />
        return(
            <>{screen}</>
        );
    
}


export default DynamicScreen;