import React from 'react';
import { getAddressByPersonID, getAddressByID, saveAddress, deleteAddress } from '../../../../networking/NetworkingAddresses';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import AlertBox from '../../../shared/AlertBox';
import AddressForm from '../../addresses/AddressForm';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
import AppContext from '../../../../AppContext';
let globalFunctions = {};

export default class AddressesScreen extends React.Component {
    static contextType = AppContext;
    state = {
        address: null,
        addresses: [],
        newSearch: false,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteAddress: {},
        pages: 1,
        totalPages: 0,
        isValidatedAddress: false,
        validateAddressFields: true,
        clearFields: false,
        errors: [],
        openCollapsible: false
    }

    componentDidMount() {
        globalFunctions = this.context;
        globalFunctions.startLoading();
        this.handleUpdateGrid();
    }

    handleGetAddressInfo = (address) => {
        this.setState({ address: address });
    }

    handleIsValidated = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedAddress: true,
                errors: []
            });
        } else {
            this.setState({
                isValidatedAddress: false,
                errors: validator.errors
            });
        }
    }

    handleRowClick = (row) => {
        globalFunctions.startLoading();
        getAddressByID(row["Address ID"]).then(
            (address) => {
                address.PersonID = this.props.data;
                this.setState({
                    address: address
                });
                globalFunctions.finishLoading();
            }
        );
        if (!this.state.isOpen) {
            this.setState({ openCollapsible: true }, () => this.setState({ openCollapsible: false }));
        }
    }

    handleUpdateGrid = async () => {    
        const addresses = await this.getAllAddresses();
        if (addresses !== undefined) {
            const totalCount = addresses.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({ addresses, totalPages, newSearch: true });
        }else
        {
            this.setState({ addresses: [], totalPages: 0, newSearch: true });
        }
        globalFunctions.finishLoading();
    }

    handleClearFields = () => {
        this.setState({
            address: null,
            clearFields: true
        }, () => {
            this.setState({ clearFields: false });
        });
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }

    getAllAddresses = async () => {
        const json = await getAddressByPersonID(this.props.data);
        this.setState({
            address: { ...this.state.address, DefaultAddress: json.DefaultAddress }
        });
        return json.addresses;
    }

    handleSaveAddressClick = async (event) => {
        event.preventDefault();
        globalFunctions.startLoading();
        if (this.state.isValidatedAddress) {
            const model = {
                PersonID: this.props.data,
                AddressID: this.state.address.AddressId,
                AddressType: this.state.address.AddressType,
                AddressValue: this.state.address.AddressValue,
                CountryID: this.state.address.CountryId,
                StateID: this.state.address.StateId,
                City: this.state.address.City,
                Address2: this.state.address.Address2,
                ZipCode: this.state.address.ZipCode,
                OtherState: this.state.address.OtherState,
                DefaultAddress: this.state.address.DefaultAddress,
                SinceWhen: this.state.address.SinceWhen,
                Status: this.state.address.Status,
                District: this.state.address.District,
                POBox: this.state.address.POBox,
                UpdatedBy: localStorage.getItem('UserID'),
                UpdateToken: this.state.address.UpdateToken
            }
            const json = await saveAddress(model);
            if (json.httpStatusCode !== 200 && json.httpStatusCode !== 409) {
                this.setState({
                    showAlertError: true, alertMessage: json.httpErrorMessage, alertTitle: 'Error'
                })
            } else if (json.httpStatusCode !== undefined && json.httpStatusCode === 409) {
                this.setState({ showAlertError: true, alertMessage: json.httpErrorMessage, alertTitle: 'Error' });
            } else {
                console.log(json);
                if (json.addressMessage !== '') { // Another address changed its Address Type to Main Address
                    this.setState({
                        showAlert: true, alertTitle: 'Success', alertMessage: `Address successfully saved.\n${json.addressMessage}`,
                        address: { ...this.state.address, UpdateToken: json.updateToken, AddressId: json.addressID, DefaultAddress: json.newDefaultAddress }
                    });
                } else {
                    this.setState({
                        showAlert: true, alertTitle: 'Success', alertMessage: 'Address successfully saved.',
                        address: { ...this.state.address, UpdateToken: json.updateToken, AddressId: json.addressID, DefaultAddress: json.newDefaultAddress }
                    });
                }
                globalFunctions.finishLoading();
                this.props.refreshOnlineCurrencies();
            }
            this.handleUpdateGrid();
        } else {
            globalFunctions.finishLoading();
            this.setState({
                showAlertError: true,
                alertMessage: 'Please fill all mandatory fields',
                alertTitle: 'Error',
                validateAddressFields: false
            });
        }
    }

    handleClearClick = (event) => {
        event.preventDefault();
        this.handleClearFields();
    }

    handleDeleteClick = (obj) => {
        let addresses = this.state.addresses || []
        if (addresses.length === 1) {
            this.setState({
                showAlertError: true,
                alertTitle: 'Error',
                alertMessage: 'The customer needs at least one address.',
            })
            return;
        }                                             
        this.setState({ showDeleteAlert: true, readyToDeleteAddress: obj });
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    closeAlertError = () => {
        this.setState({ showAlertError: false, validateAddressFields: true });
    }

    closeDeleteAlert = () => {
        this.setState({ showDeleteAlert: false, readyToDeleteAddress: {} });
    }

    openDeleteAlert = () => {
        this.setState({ showDeleteAlert: true });
    } 

    yesDeleteAlert = async () => {

        globalFunctions.startLoading();
        this.setState({ showDeleteAlert: false });
        const deleteResponse = await deleteAddress(this.state.readyToDeleteAddress['Address ID']);
        console.log(deleteResponse);

        let auxTitle = 'Error';
        let auxMessage = 'Something went wrong. Please try again later.';
        let deleted = false;

        if(deleteResponse !== undefined)
        {
            switch (deleteResponse.Message) 
            {
                case 'The address has been successfully deleted.':
                {
                    deleted = true;
                    break;
                }
                default:
                {
                    if(deleteResponse.Message !== undefined && deleteResponse.Message !== null && deleteResponse.Message !== '')
                    {
                        auxMessage = deleteResponse.Message;
                    }
                    break;
                }
            }

            if(deleted)
            {
                this.setState({ showSuccessDeleteAlert: true }, () => {this.handleUpdateGrid();});
                globalFunctions.finishLoading();
            }else{
                this.setState({
                    showAlertError      : true, 
                    alertTitle          : auxTitle,
                    alertMessage        : auxMessage,
                }, () => {
                    globalFunctions.finishLoading();
                });
            }
        }else
        {
            this.setState({
                showAlertError      : true, 
                alertTitle          : auxTitle,
                alertMessage        : auxMessage,
            }, () =>{
                globalFunctions.finishLoading();
            });
        }
    }

    okSuccessAlert = () => {
        this.handleClearFields();
        this.setState({ showSuccessDeleteAlert: false });
    }

    render() {
        return (
            <div>
                <div className="">
                    <AddressForm selectedAddress={this.state.address} getAddressInfo={this.handleGetAddressInfo} isValid={this.handleIsValidated} validations={this.state.validateAddressFields} clearFields={this.state.clearFields} openCollapsible={this.state.openCollapsible} isCustomer={true} />
                    <div style= {{paddingTop: 10}}>
                        <button className="uk-button uk-button-green" onClick={this.handleSaveAddressClick} >Save</button>
                        <button className="uk-button uk-button-green" onClick={this.handleClearClick} >Clear Fields</button>
                        <hr />
                    </div>
                    <NewDynamicTable
                        id="ndt-addresses-screen"
                        data={this.state.addresses}
                        hiddenHeaders={["Address ID"]}
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        useDeleteButton={true}
                        deleteFunction={this.handleDeleteClick}
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.pages}
                        numberPerPage={getDefaultPagingNumberOfItems()}
                        enableSortByHeader={true}
                        useFilter={true}
                        filterFunction={this.getAllAddresses}
                        useExportButton={true}
                        tableName="addressess" 
                        useIcons={true}/>
                    <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                    <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlertError} />
                    <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Warning" message="Are you sure you want to delete this address? This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                    <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Success" message="Address successfully removed." type="Ok" okClick={this.okSuccessAlert} />
                </div>
            </div>
        );
    }
}