import React from 'react';
import NewDynamicTable from '../../shared/NewDynamicTable';
import AlertBox from '../../shared/AlertBox';
import { getStyle, regularInputClass, regularSelectClass, regularTextAreaClass, notFilledSelectClass, notFilledInputClass, notFilledTextAreaClass } from '../../../styles/styles';
import {getDefaultPagingNumberOfItems} from '../../../helpers/PackageJsonHelper'
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { getTradingHolidays, saveTradingHolidays, deleteTradingHolidays } from '../../../networking/NetworkingHolidays';
import { formatDateString, formatStringToDate } from '../../../helpers/FormatHelper';
import { Calendar } from '../../shared/Calendar';
var style = getStyle();
const itemsPerPage = getDefaultPagingNumberOfItems();

export default class TradingHolidaysScreen extends React.Component {
    state = {
        holiday: '',
        selecteddate: '',
        description: '',
        updateToken: 0,
        
        allHolidays: [],  
        
        hideButton: false,        
        
        available:'',
        listMissing: [],

        validations: {
            holiday: true,
            description: true,
        },

        alertMessage: '',
        showAlert: false,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteHoliday: {},
        alertTitle: '',
        totalPages: 0,
        currentPage: 1,
        newSearch: false
    }

    closeDeleteAlert = () =>{
        this.setState({showDeleteAlert: false, readyToDeleteHoliday: {}});
    }

    openDeleteAlert = () => {
        this.setState({showDeleteAlert: true});
    }

    yesDeleteAlert = () => {
        deleteTradingHolidays(this.state.readyToDeleteHoliday['Date']).then(
            (value)=>{
                console.log(value);
                if(value.httpStatusCode !== 200){
                    this.setState({showDeleteAlert: false, showErrorAlert: true, errorMessage: value.Message});
                } else {
                    this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true});
                    this.updatePage();
                }
        });
    }

    okSuccessAlert = () => {
        this.setState({showSuccessDeleteAlert: false});
    }
    
    componentDidMount(){
        getTradingHolidays(0,0).then(
            (value)=>{               
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                console.log(pages);
                this.setState({allHolidays: value.holidays, totalPages: pages, newSearch: false});
            }
        )
    }   

    transformHolidaysList (list) {
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'Holiday Id': list[i]['Holiday Id'],
            'Description': list[i]['Description'],
            'Date': list[i]['Date']});
        }
        return helper;
    }

    handleRowClick = (obj) =>{
        console.group("Holiday handleRowClick")
        console.log(obj);
        console.groupEnd();
        this.setState({
            holiday: obj["Date"],
            description: obj["Description"],
            selecteddate: formatStringToDate(obj['Date'])
        })
    }

    handleDeleteClick = (obj) => {
        console.group("Holiday handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteHoliday: obj});        
    }

    changePage = (value) => {
        this.setState({currentPage: value});        
    }

    updatePage(){
        getTradingHolidays(0,0).then(
            (value)=>{               
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                console.log(pages);
                this.setState({allHolidays: value.holidays, totalPages: pages, newSearch: false});
            }
        )
    }

    handleSaveClick(){
        let userID = localStorage.getItem('UserID');
        var model = {
            date: this.state.holiday,
            description: this.state.description
        }
        console.log(model);
        if(this.validateRequiredFields()){
            saveTradingHolidays(model).then(()=>
            {
                this.setState({
                    holiday: '',
                    description: '',
                    showAlert: true, 
                    alertTitle: 'Holiday successfully saved.'});
                this.updatePage(); 
            })
        }
    }

    validateRequiredFields() 
    {
        var valid = true
        var description = true
        var holiday = true
        var listItems =[]        

        if(this.state.holiday === undefined || 
            this.state.holiday === "")
         {
             valid = false;
             holiday = false;
             listItems.push("Trading Holiday")
         }

        if(this.state.description === undefined || 
           this.state.description === "")
        {
            valid = false;
            description = false;
            listItems.push("Description")
        }
        
        this.setState({listMissing: listItems,validations: {
                ...this.state.validations,
                description: description,
                holiday: holiday
            }});

        if(!valid)
        {
            this.setState({
                showAlert: true, 
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    handleClearClick(){
        this.setState({
            description: '',
            });
    }
    
    handleAddClick(event){
        event.preventDefault();
        
    }

    handleRemoveClick(event){
        event.preventDefault();
        
    }

    handleHoliday (value){
        this.setState({holiday: formatDateString(value), selecteddate: value, validations: {...this.state.validations, holiday: true}});
    }

    handleUpdateDescription(value){
        this.setState({description: value.target.value,validations: {...this.state.validations, description: true}});
    }

    formatValidTextArea(valid) {
        return valid ? regularTextAreaClass : notFilledTextAreaClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    getAllTradingHolidays = async () => {
        var json = await getTradingHolidays(0,0);
        var holidays = this.transformHolidaysList(json.holidays);
        return holidays;
    }

    render() {
        var classNameInput = regularInputClass; 
         
        return (
            <div>
            <form className="uk-grid">
                <div className="uk-width-1-2">
                    <label className="uk-form-label" htmlFor="form-holiday-description">Description</label>
                    <div className="uk-form-controls">
                         <input className={this.formatValidInput(this.state.validations.description)} id="form-holiday-description" value={this.state.description} type="text" placeholder=" " onChange={this.handleUpdateDescription.bind(this)}/>
                    </div>
                </div>
                <div className="uk-margin-right">
                    <div className="">                                
                        {/* <DayPicker value={this.state.holiday} onDayClick={this.handleHoliday.bind(this)} selectedDays={this.state.selecteddate} formatDay={this.state.holiday} month={this.state.selecteddate}/> */}
                        <Calendar value={this.state.holiday} onDayClick={this.handleHoliday.bind(this)} selectedDays={this.state.selecteddate} formatDay={this.state.holiday} month={this.state.selecteddate} />
                    </div>
                </div>
                <div className="uk-margin-right"> 
                    <div className="uk-margin" style={style.inputStyle}>
                        <div className="uk-margin-right"> 
                            <div className="uk-margin" style={style.inputStyle}>
                                <br></br>
                            </div>
                        </div>
                        <div className="uk-margin-right"> 
                            <div className="uk-margin" style={style.inputStyle}>
                                <br></br>
                            </div>
                        </div>
                        <div className="uk-margin-right">
                            <div className="" style={style.inputStyle}>
                                <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "40px"}} onClick={this.handleSaveClick.bind(this)} type="button">Add</button>
                            </div>
                        </div>
                        {/*<div className="uk-margin-right">
                            <div className="" style={style.inputStyle}>
                                <button className="uk-button uk-button-green uk-margin-right" style={{marginTop: "40px"}} onClick={this.handleDeleteClick.bind(this)} type="button">Delete</button>
                            </div>
                        </div>*/}
                    </div>
                </div>
                               
                <div className="uk-width-1"> 
                    <div className="uk-margin" style={style.inputStyle}>
                        <br></br>
                    </div>
                </div>
            </form>
            <div className="uk-width-1">
            <h3 className="uk-heading-divider"> </h3>
            <NewDynamicTable
                useIcons={true}
                id="tasks-table"
                data={this.state.allHolidays}
                hiddenHeaders={['Holiday Id']}
                enableClick={true} 
                clickFunction={this.handleRowClick} 
                newSearch={this.state.newSearch} 
                useDeleteButton={true}
                deleteFunction={this.handleDeleteClick}
                useFilter={true} 
                filterFunction={this.getAllTradingHolidays}
                usePaginator={true}
                changePage={this.changePage}
                numberOfPages={this.state.totalPages}
                pageNumber = {this.state.currentPage}
                numberPerPage = {getDefaultPagingNumberOfItems()}
                enableSortByHeader={true}/>
            </div>
            <AlertBox id="delete-holiday" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete this holiday?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert}/>
            <AlertBox id="confirmation-delete-holiday" open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Holiday successfully removed." message="" type="Ok" okClick={this.okSuccessAlert}/>
            <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} messageBoldList={this.state.listMissing}/>
            </div>
        );
    }

}