import { genericGetWithBody} from "./Networking"

export async function dynamicGetWithParameters(method, params) {
    try {
        let jsonParam = params;
        if(typeof(params) === String)
        {
            jsonParam = JSON.parse(params.toString());
        }
        let model = {
            sp: method,
            json: jsonParam
        } 

       let response = await genericGetWithBody('DynamicScreen/DynamicExecByJson', model)

       if(response.data !== undefined){
        return response.data;
        }    
        return response;

    } catch (error) {
        console.error(error);
    }
  }
