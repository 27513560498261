import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Checkbox, Form, Input, InputNumber, Popconfirm, Select, Space, Table, Tag, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useMemo, useState, useContext, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { getLookUpTablesDescription } from '../../networking/NetworkingLookUpTables';
import { deleteRiskScoreRange, getRiskScoreRanges, saveUpdateRiskScoreRange } from '../../networking/NetworkingRiskScoreRange';
import { regularSelectClass } from '../../styles/styles';
import DynamicSelect from '../shared/DynamicSelect';
import AlertBox from '../shared/AlertBox';
import TheCSVButton from '../shared/TheCSVButton';
import AppContext from '../../AppContext';
import { CheckOutlined, DeleteOutlined, EditOutlined, RollbackOutlined }  from '@ant-design/icons';

const NewRiskScoreRangeScreen = () => {

    const { Option } = Select;
    const context = useRef(useContext(AppContext));
    const [form] = Form.useForm();
    const [ranges, setRanges] = useState([]);
    const [allRanges, setAllRanges] = useState([]);
    const [types, setTypes] = useState([]);
    const [status, setStatus] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const [updating, setUpdating] = useState(false);

    //Search
    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const searchInput = useRef();

    const [editingKey, setEditingKey] = useState(0);
    const [editData, setEditData] = useState({});
    const [currentRangeID, setCurrentRangeID] = useState(0);
    const [currentColor, setCurrentColor] = useState('#000000');

    const isEditing = (record) => record['Score Range ID'] === editingKey;

    const defaultRowsSize = window.DEFAULT_ROWS_SIZE??20;

    const edit = (record) => {
        if (ranges[0]['Score Range ID'] === '') {
          let auxRange = [...ranges];
          auxRange.shift();
          setRanges(auxRange);
      }

      setCurrentColor(record['Color Hex']);

      form.setFieldsValue({
          ...record,
          'Score Range ID':record['Score Range ID'],
          Color: record['Color Hex'] ,
          'Color Hex': record['Color Hex'] 
      });
      
      setEditingKey(record['Score Range ID']);
      setCurrentRangeID(record['Score Range ID']);
    };

    useEffect(() => {
        getLookUpTablesDescription('RiskRangeEntityTypes').then(
            (jsonResponse) => {
                console.log("getLookUpTablesDescription: "+ JSON.stringify(jsonResponse.lookUpTables))
                if(jsonResponse.lookUpTables !== undefined)
                {
                    if(Array.isArray(jsonResponse.lookUpTables))
                    {
                        var objLookUpTable = jsonResponse.lookUpTables[0].lookUpTableDetails
                        if(Array.isArray(objLookUpTable))
                        {
                            var riskRangeEntityTypesList = []
                            objLookUpTable.forEach(element => {
                                var elementList = {
                                    value   :element.LookUpTableDetail,
                                    name    : element.LookUpTableDetail
                                }
                                riskRangeEntityTypesList.push(elementList)
                            });
                            setTypes(riskRangeEntityTypesList);
                        }
                    }
                }
            }
        );
        
    }, []);

useEffect(() => {
    getRiskScoreRanges().then(
      (jsonResponse) => {
          if (jsonResponse != null && jsonResponse.riskScores != null) {
            const listRSRAll = jsonResponse.riskScores.map((r) => ({ ...r, key: `Range-${r['Score Range ID']}` }));                
            setAllRanges(listRSRAll);

            filterAllListByEntityType(listRSRAll, selectedType);
        }
      }
  );
}, [updating]);

    const handleGetValueEntityType = (entityObj) => 
    {
        if (entityObj.value !== undefined)
        {
            if(entityObj.value.indexOf("Select", 0) == 0)
            {
                setSelectedType('');
                setRanges([]);
            }else
            {
                setSelectedType(entityObj.value);

                var descriptionStatus = ''

                switch (entityObj.value)
                {
                    case 'Customer':
                        descriptionStatus = 'Customer Status'
                        break;
                    case 'Beneficiary':
                        descriptionStatus = 'Beneficiary Status'
                        break;
                    case 'Trade':
                        descriptionStatus = 'Deal Status'
                        break;
                    case 'Owner':
                          descriptionStatus = 'Owner Status'
                          break;
                }

                if(descriptionStatus != '')
                {
                  filterAllListByEntityType( allRanges ,entityObj.value);
                    getLookUpTablesDescription(descriptionStatus).then(
                        (jsonResponseRiskScoreStatus) =>{

                            if(jsonResponseRiskScoreStatus.lookUpTables !== undefined)
                            {
                                if(Array.isArray(jsonResponseRiskScoreStatus.lookUpTables))
                                {
                                    var objLookUpTable = jsonResponseRiskScoreStatus.lookUpTables[0].lookUpTableDetails
                                    if(Array.isArray(objLookUpTable))
                                    {
                                        var riskRangeEntityStatusList = []
                                        objLookUpTable.forEach(element => {
                                            var elementList = {
                                                value   :element.LookUpTableDetail,
                                                name    : element.LookUpTableDetail
                                            }
                                            riskRangeEntityStatusList.push(elementList)
                                        });
                                        setStatus(riskRangeEntityStatusList);                                    
                                    }
                                }
                            }
                        }
                    );
                }
            }
        }
    }

    const filterAllListByEntityType = (listRanges, entityType) =>
    {
        var allList = listRanges;
        if(allList != null && entityType != '')
        {
            var entityTypeListFilter = []
            allList.forEach((risk, index) => {
                if(risk["Entity Type"] == entityType)
                {
                    entityTypeListFilter.push(risk)
                }
            });

            entityTypeListFilter.sort(dynamicSort("Range From"));

            setRanges(entityTypeListFilter);
        }
    }

    const dynamicSort = (property) => {
      var sortOrder = 1;
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a,b) {
          /* next line works with strings and numbers, 
           * and you may want to customize it to your needs
           */
          var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result * sortOrder;
      }
  }

  const save = async (record) => {
    try {
        const row = await form.validateFields();
        const newData = [...ranges];
        const index = newData.findIndex((item) => record['Score Range ID'] === item['Score Range ID']);
        if (index > -1) {
            const model = {
                "ScoreRangeID"  : currentRangeID,
                "RangeFrom"     : row['Range From'],
                "RangeTo"       : row['Range To'],
                "Description"   : row['Description'],
                "ColorHEX"      : currentColor,
                "Status"        : row['Status'],
                "EntityType"    : selectedType
            }
            context.current.startLoading();
            const json = await saveUpdateRiskScoreRange(model);
            context.current.finishLoading();
            if (json.riskScore != null){
              console.log(json);
              setShowAlert(true);
              setAlertTitle('Success');
              setAlertMessage('Risk Score Range successfully saved.');
              setUpdating(!updating);
              setEditingKey(0);
              setCurrentRangeID(0);
              setCurrentColor('#000000');
                    
                } else {
                  console.log(json);
                  setShowAlert(true);
                  setAlertTitle('Error');
                  if (json.Message != null && json.Message !== '') {
                      setAlertMessage(json.Message);
                  } else {
                      setAlertMessage(json.httpErrorMessage);
                  }
                  setUpdating(!updating);
                  setEditingKey(0);
            }
        }

    } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
    } 
  }

    const deleteRecord = async (record) => {
      context.current.startLoading();
        var json = await deleteRiskScoreRange(record['Score Range ID']);
        context.current.finishLoading();
        if(json !== null){
            if (json.httpStatusCode !== 200){
                setShowAlert(true);
                setAlertTitle('Error');
                if (json.Message != null && json.Message !== '') {
                    setAlertMessage(json.Message);
                } else {
                    setAlertMessage(json.httpErrorMessage);
                }
                setUpdating(!updating);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Risk Score Range successfully removed.');
                setUpdating(!updating);
            }
        }
    };

    const cancel = (page) => {
        setEditingKey(0);
        
        setCurrentRangeID(0);
        setCurrentColor('#000000');

        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        if (ranges[0]['Score Range ID'] === 0) {
            let auxRanges = [...ranges];
            auxRanges.shift();
            setRanges(auxRanges);
        }
        form.resetFields();        
    };

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {

        const onChangeStatus = (value) => {
   
            let actualValue = null;

            form.setFieldsValue({
              'Status'       : value
            });
          }
        
        const onChangeValueOfColor=(event)=>
          {
              form.setFieldsValue({
                'Color Hex'       : event.target.value
              });
              setCurrentColor(event.target.value);
          }

        let inputNode = null;

        switch (inputType) 
      {
          case 'number':
          {
            inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center'}}/>;
            break;
          }
          case 'select-status':
          {
            let allOptions = [];

            status.forEach((s, index) => {
              allOptions.push(
                <Option 
                  key   = {index + s.value} 
                  value = {s.value}
                >{s.label}
                </Option>
              );
            });

            inputNode = (
              <Select
                  showSearch
                  style       = {{ width: '100%' }}
                  placeholder = "Select a Status..."
                  onChange    = {onChangeStatus}
              >
                {allOptions}
              </Select>
            );
            break;
          }
          case 'select-color':
            {  
              inputNode = (

                <div 
                                style={{
                                    flexDirection: 'row',
                                    display: 'flex'
                                }}
                            >
                                <div 
                                    style={{
                                        width: '100%',
                                        height: '25px',
                                        flex: 1
                                    }} >
                                <input type='color' value={currentColor}
                                        onChange={onChangeValueOfColor}
                                    />
                                    </div>
                                  </div>
                
              );
              break;
            }
          default:
            inputNode = <Input style={{ textAlign: 'center' }} />;
        }


        let styleEdit = { margin: 0 };
        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        const getCustomRules = (columnDataIndex) => {
            switch (columnDataIndex) {
                case 'Markup Factor': {
                    return [
                        {
                            type: 'boolean',
                            mesage: 'Please Input a valid value!'
                        }
                    ];
                }
                default:
                    return [
                        {
                            required: true,
                            message: `Please Input a ${title}!`
                        }
                    ];
            }
        };
        const rules = getCustomRules(dataIndex);

        return (
          <td {...restProps}>
          {editing ? (
              <Form.Item
                  name={dataIndex}
                  style={styleEdit}
                  rules={rules}
              >
                  {inputNode}
              </Form.Item>
          ) : (
              children
          )}
      </td>
        );
    };

     //Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setFilteringSomething(true);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setFilteringSomething(false);
  };

  const settingSearchStateValues = (value, col) => {
    setSearchText(value);
    setSearchedColumn(col);
    setSearchArray(...(searchArray[col] = value));
    setFilteringSomething(true);
  };
  //


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref         = {(node) => {
                searchInput.current = node;
              }}
              placeholder = {`Search ${dataIndex}`}
              value       = {selectedKeys[0]}
              onChange    = {(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                width       : 188,
                marginBottom: 8,
                display     : 'block'
              }}
            />
            <Space>
              <Button 
                type    = "primary" 
                onClick = {() => handleSearch(selectedKeys, confirm, dataIndex)} 
                icon    = {<SearchOutlined />} 
                size    = "small" 
                style   = {{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                    handleReset(clearFilters);
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type    = "link"
                size    = "small"
                onClick = {() => {
                  confirm({ closeDropdown: false });
                  settingSearchStateValues(selectedKeys[0], dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current.select(), 100);
          }
        },
        render: (text) => (
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : ( text )
        )
      });


    const columns = [
        {
            title: 'Range From',
            dataIndex: 'Range From',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => a['Range From'] - b['Range From'],
            sortDirections: ['descend', 'ascend']            
        },
        {
            title: 'Range To',
            dataIndex: 'Range To',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => a['Range To'] - b['Range To'],
            sortDirections: ['descend', 'ascend']            
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            width: '10%',
            editable: true,
            align: 'left',
            sorter: (a, b) => {
                return a['Description'].localeCompare(b['Description']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Description')
        },
        {
            title     : 'Color',
            dataIndex : 'Color Hex',
            width     : '6%',
            editable  : true,
            align     : 'center',
            render        : (value, obj, index) => (              
              <div  style={{
                backgroundColor : value,
                width:'100%',
                height:'25px'
            }}>
              </div>
            )
        },
        {
            title     : 'Status',
            dataIndex : 'Status',
            width     : '6%',
            editable  : true,
            align     : 'center',
            sorter    : (a, b) => {
              return a['Status'].localeCompare(b['Status']);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {                
                const editable = isEditing(record);                
                return editable ? (
                    <span>                        
                        <Popconfirm title="Sure to save?" onConfirm={() => save(record)}>
                            <a style={{ marginRight: 8 }}>Save</a>
                        </Popconfirm>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <a onClick = {() => edit(record)} disabled={editingKey !== 0 || ranges[0]['Score Range ID'] === 0}><Tooltip title="Edit"><EditOutlined /></Tooltip></a>
                            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record)}>
                                <a disabled={editingKey !== 0 || ranges[0]['Score Range ID'] === 0}><Tooltip title="Delete"><DeleteOutlined /></Tooltip></a>
                            </Popconfirm>
                        </Space>
                    </div>
                );
            }
        }
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }

          let type = '';
          switch (col.dataIndex) 
          {
            case 'Range From':
            case 'Range To':
            {
              type = 'number';
              break;
            }
            case 'Status':
            {
              type = 'select-status';
              break;
            }
            case 'Color Hex':
            {
              type = 'select-color';
              break;
            }
            default:
            {
                type = 'text'
                break;
            }
          }
    
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: type,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

    const addNewRange = () => {
        let actual = [...ranges];
        if (actual[0] != null && actual[0]['Score Range ID'] === 0) {
            return;
        } else {
            actual.unshift({
                'key': 'Ranges-new',
                'Score Range ID':0,
                'Range From': 0,
                'Range To': 0,
                'Description': '',
                'Status': '',
                'Color Hex': '#000000',
                UpdateToken: '',
                isNew: true
            });
            setRanges(actual);
            setCurrentPage(1);
            setCurrentRangeID(0);
            form.setFieldsValue({
              'Range From': 0,
              'Range To': 0,
              'Description': '',
              'Status': '',
              'Color Hex': '#000000',
                ...actual
            });
            // setCurrentUpdateToken('');
        }
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a>Previous</a>;
        }
        if (type === 'next') {
            return <a>Next</a>;
        }
        return originalElement;
    }
        
    return (
        
        <div>
            <div className="breadcrumb-div">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className="breadcrumb-item-bold">Risk Score Range</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Form form={form}>
                <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label"  >Type (*)</label>
                            <div className="" >
                                <DynamicSelect 
                                    className={regularSelectClass} 
                                    objValue={{value: 'value'}} 
                                    getValue={handleGetValueEntityType} 
                                    listHasPlaceholder={false} 
                                    data={types} 
                                    id="form-entity-type"
                                />
                                
                            </div>
                </div>
        
                    <div className="uk-overflow-auto">
                        <TheCSVButton board={'Ranges'} data={ranges} useIcon={true}/>
                    </div>
                <Table dataSource={ranges} columns={mergedColumns} size="small" components={{ body: { cell: EditableCell } } }
                pagination={{
                    onChange: cancel,
                    showQuickJumper: true,
                    itemRender: itemRender,
                    current: currentPage,
                    defaultCurrent: 1,
                    size: 'default',
                    defaultPageSize: defaultRowsSize
                }}
                footer={() => (
                    <Button
                        type="dashed"
                        onClick={addNewRange}
                        block
                        icon={<PlusOutlined />}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        disabled={editingKey !== 0 || (ranges[0] !== undefined && ranges[0]['Score Range ID'] === 0)}
                    >
                        Add a Risk Score Range
                    </Button>
                )}/>
            </Form>
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
};

export default NewRiskScoreRangeScreen;
