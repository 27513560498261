import React from 'react';

const CoverDealsInfo = (props) => {

    const { coverDeals, coverDealsInfoDetail, clickDeal, isCoverDeal } = props

    let coverDealsDetails = []
    if (Array.isArray(coverDealsInfoDetail) && coverDealsInfoDetail.length > 0) {
        coverDealsDetails = coverDealsInfoDetail.map(x =>
            <div key={x.DealHeaderID}>
                <a onClick={() => clickDeal(x.DealHeaderID)}>{x.DealHeaderID}</a>
                <label> ({isCoverDeal ? 'Partner' : 'Customer'}: {x.CustomerName})</label>
            </div>
        )
    }

    return <>
        {coverDealsDetails.length > 0 ? coverDealsDetails : coverDeals}
    </>

}

export default CoverDealsInfo