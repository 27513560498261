import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Lottie from 'lottie-react';
import * as mime from 'mime-types';
import React from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import uploadAnimation from '../../../animations/upload-files.json';
import AppContext from '../../../AppContext';
import { formatDate, getScaleByCurrency } from '../../../helpers/FormatHelper';
import { deleteE2EAttachment, getAttachment, uploadAttachmentWithoutLink } from '../../../networking/NetworkingAttachments';
import { saveOutgoingFundsEntityProperties } from '../../../networking/NetworkingFunds';
import { getUserDefinedPropertiesBySubEntityType } from '../../../networking/NetworkingUserDefinedProperties';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../AlertBox';
import { DialogTitle as DialogTitleAttachmentPreview } from '../AttachmentsTable';
import DatePicker from '../DatePicker';
import NumberInput from '../NumberInput';
import {GlobalSystemParametersNames} from '../../../constants/GlobalSystemParametersNames'

const styles = getStyle();

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default class DisbursementPropertiesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
    }

    static contextType = AppContext;
    state = {
        properties: [],
        entityProperties: [],
        showAttachments: false,
        fileName: '',
        fileDescription: '',
        updatedProperties: false,
        index: 0,
        selectedEntityPropertyID: 0,
        selectedEntityPropertyIndex: 0,
        allowEntityLoadAgain: true,
        showAlert: false,
        alertMessage: '',
        alertTitle: '',
        outgoingFundsID: 0, // Only used for directly saving UserProperties with this.props.saveMode = true. Used by OutgoingFunds tab
        showOpenAttachmentWarning: false,
        currentFileURLList: [],
        currentFileName: '',
        currentBlob: null,
        currentExtension: '',
        openAttachmentPreview: false,
        baseCurrency : localStorage.getItem(GlobalSystemParametersNames.BaseCurrency),
        amount:0
    };

    componentDidMount() {
        getUserDefinedPropertiesBySubEntityType('OutgoingFund', 0, 0).then((response) => {
            let properties = [];
            let entityProperties = [];
            if (response.properties !== undefined) {
                response.properties.forEach((element) => {
                    if (element['ExtUserPropertyID$'] !== null) {
                        properties.push(element);
                    }
                });
                if(this.props.entityProperties == null || this.props.entityProperties.length == 0)
                {
                    entityProperties = this.generateCleanEntityProperties(properties);
                }
                else
                {
                    entityProperties = this.props.entityProperties;
                }

                if(this.props.beneficiaryID != null && this.props.beneficiaryAccountList != null){
                    let beneficiaryID = this.props.beneficiaryID;
                    let beneficiaries = this.props.beneficiaryAccountList;
                    entityProperties=this.ValidateRequiredInvoiceDoc(entityProperties, beneficiaryID, beneficiaries);  
                    properties=this.ValidateRequiredInvoiceDoc(properties, beneficiaryID, beneficiaries)          
                }

                this.setState({ properties: properties, entityProperties });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.setState({ open: true });  
        }

        if (prevProps.index != null && this.props.index != null && prevProps.index !== this.props.index) {
            this.setState({ index: this.props.index });
        }
        if (prevProps.outgoingFundsID != null && this.props.outgoingFundsID != null && prevProps.outgoingFundsID !== this.props.outgoingFundsID) {
            this.setState({ outgoingFundsID: this.props.outgoingFundsID });
        }
        if (this.props.entityProperties != null && this.props.open && this.state.allowEntityLoadAgain) {
            this.setState({ entityProperties: this.props.entityProperties, allowEntityLoadAgain: false });
        }

        if (prevProps.amount !== this.props.amount ) {
            this.setState({ amount: this.props.amount  });
        }
        
        if(prevProps.beneficiaryID !== this.props.beneficiaryID && this.props.beneficiaryAccountList != null){
            let beneficiaryID = this.props.beneficiaryID;
            let beneficiaries = this.props.beneficiaryAccountList;
            if(this.state.entityProperties != null){
                this.setState({entityProperties: this.ValidateRequiredInvoiceDoc(this.state.entityProperties, beneficiaryID, beneficiaries)})
            }
            if(this.state.properties != null){
                this.setState({properties: this.ValidateRequiredInvoiceDoc(this.state.properties, beneficiaryID, beneficiaries)})
            }
        }

    }

    ValidateRequiredInvoiceDoc = (propertiesAux, beneficiaryID, beneficiaries) => {
        let bene = beneficiaries.find(b => b.BeneficiaryID == beneficiaryID);    
        if (bene) {
            let CountryRequiresDocs = false;
            if(bene.CountryRequiresDocs != null && bene.CountryRequiresDocs.toLowerCase() == 'yes'){
                CountryRequiresDocs = true;
            }
            let BeneRequiresDocs = false;
            if(bene.BeneRequiresDocs != null && bene.BeneRequiresDocs.toLowerCase() == 'yes'){
                BeneRequiresDocs = true;
            }
            if(CountryRequiresDocs && BeneRequiresDocs){
                propertiesAux.map(p => {
                    if (p.Description.toLowerCase().includes('copy of invoice and number')) {
                      p.AttachmentsRequired = true;
                      p.Mandatory = true;
                    }
                    return true;
                  }); 
            }
        }
        return propertiesAux;
      }

    generateCleanEntityProperties = (properties) => {
        const entityProperties = properties.map((element) => {
            const property = {
                AmountLimit: element['ExtAmountLimit$'],
                Attachments: [],
                AttachmentsRequired: element['ExtAttachmentsRequired$'],
                DataType: element['Data Type'],
                DateValue: null,
                Description: element['Description'],
                EntityPropertyID: null,
                Mandatory: element['ExtMandatory$'],
                NumValue: 0,
                TextValue: '',
                UserDefinedPropertyID: element['ExtUserPropertyID$']
            };
            return property;
        });
        return entityProperties;
    };

    clearDialogStateAndClose = () => {
        const cleanProperties = this.generateCleanEntityProperties(this.state.properties);
        this.setState({
            open: false,
            entityProperties: cleanProperties,
            showAttachments: false,
            fileName: '',
            fileDescription: '',
            updatedProperties: false,
            selectedEntityPropertyID: 0,
            allowEntityLoadAgain: true
        });
    };

    handleUploadFile = async (event) => {
        if (this.props.disabled) {
            return;
        }
        if (this.state.fileName !== '' && this.state.fileDescription !== '') {
            event.preventDefault();
            const file = this.fileInput.current.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            this.context.startLoading();
            const response = await uploadAttachmentWithoutLink(file.name, this.state.fileDescription, fileContent, localStorage.getItem('UserID'), new Date());
            this.context.finishLoading();
            if (response !== undefined) {
                switch (response.httpStatusCode) {
                    case 200: {
                        let propertyIndex = this.state.entityProperties.findIndex((p) => p.UserDefinedPropertyID === this.state.selectedEntityPropertyID);
                        if (propertyIndex !== -1) {
                            let entityProperties = [...this.state.entityProperties];
                            if(entityProperties[propertyIndex].Attachments == null)
                            {
                                entityProperties[propertyIndex].Attachments = [];
                            }
                            entityProperties[propertyIndex].Attachments.push({ ID: response.id});//, FileName: file.name, Description: this.state.fileDescription });
                            this.setState({ entityProperties, fileDescription: '', fileName: '', updatedProperties: true });
                        }
                        break;
                    }
                    default: {
                        this.setState({ alertMessage: 'Upload failed. Please try again.', showAlert: true, alertTitle: 'Error' });
                        break;
                    }
                }
            } else {
                this.setState({ alertMessage: 'Upload failed. Please try again.', showAlert: true, alertTitle: 'Error' });
            }
        }
    };

    readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    };

    deleteAttachment = async (attachmentID) => {
        if (this.props.disabled) {
            return;
        }
        this.context.startLoading();
        const response = await deleteE2EAttachment(attachmentID);
        this.context.finishLoading();
        switch (response.httpStatusCode) {
            case 200: {
                let propertyIndex = this.state.entityProperties.findIndex((p) => p.UserDefinedPropertyID === this.state.selectedEntityPropertyID);
                if (propertyIndex !== -1) {
                    let entityProperties = [...this.state.entityProperties];
                    entityProperties[propertyIndex].Attachments = entityProperties[propertyIndex].Attachments.filter((a) => a.ID !== attachmentID);
                    this.setState({ entityProperties, updatedProperties: true });
                }
                break;
            }
            default: {
                this.setState({ alertMessage: 'Attachment not deleted. Please try again.', showAlert: true, alertTitle: 'Error' });
                break;
            }
        }
    };

    showAttachmentsContent = async (attachment) => {
        // src\components\shared\AttachmentsTable.js:handleAttachmentClick
        const json = await getAttachment(attachment.ID);
        const byteCharacters = atob(json.data);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const bytes = new Uint8Array(byteNumbers);
        const blob = new Blob([bytes], { type: mime.lookup(attachment.FileName) });
        const arrayName = attachment.FileName.split('.');

        let fileList = [
            {
                uri: window.URL.createObjectURL(blob),
                fileType: arrayName[arrayName.length - 1].toLowerCase(),
                fileName: attachment.FileName
            }
        ];

        if (arrayName.length !== 0) {
            if (
                arrayName[arrayName.length - 1] === 'xlsx' ||
                arrayName[arrayName.length - 1] === 'xls' ||
                arrayName[arrayName.length - 1] === 'docx' ||
                arrayName[arrayName.length - 1] === 'doc' ||
                arrayName[arrayName.length - 1] === 'docm'
            ) {
                this.setState({
                    showOpenAttachmentWarning: true,
                    currentBlob: blob,
                    currentFileName: attachment.FileName,
                    currentExtension: arrayName[arrayName.length - 1]
                });
            } else {
                this.setState({
                    currentFileName: attachment.FileName,
                    currentFileURLList: fileList,
                    openAttachmentPreview: true
                });
            }
        }
    };

    onCloseAttachmentPreview = () => {
        this.setState({
            currentFileName: '',
            currentFileURLList: [],
            openAttachmentPreview: false
        });
    };

    yesDowloadFile = () => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(this.state.currentBlob);
        link.download = this.state.currentFileName;
        link.click();
    };

    closeAttachmentWarning = () => {
        this.setState({
            showOpenAttachmentWarning: false,
            currentBlob: null,
            currentExtension: '',
            currentFileName: ''
        });
    };

    showAttachmentsForm = (entityPropertyID, index) => {
        this.setState({ showAttachments: true, selectedEntityPropertyID: entityPropertyID, selectedEntityPropertyIndex: index });
    };

    onBack = () => {
        this.setState({ showAttachments: false, selectedEntityPropertyID: 0, selectedEntityPropertyIndex: 0 });
    };

    getPropertyControl = (property, index) => {
        var amount = (this.state.amount ? this.state.amount: 0);
        var entityProperty = undefined;

        if(this.state.entityProperties != undefined && this.state.entityProperties.length > 0)
        {
            entityProperty = this.state.entityProperties.find( ep => ep.UserDefinedPropertyID == this.state.properties[index]['ExtUserPropertyID$']);
        }

        switch (property['Data Type']) {
            default:
            case 'Text': {
                var textValue = (entityProperty != undefined) ? entityProperty.TextValue: '';
                if (property['PossibleValues$'] === null) {
                    let className = regularInputClass;

                    if(((this.state.properties[index].AmountLimit > 0 && amount > this.state.properties[index].AmountLimit )
                    || this.state.properties[index].Mandatory )
                    && (entityProperty == undefined || entityProperty.TextValue === ''))
                    {
                        className = notFilledInputClass;
                    }
                    
                    return (
                        <input
                            id={`form-attachment-description-${property.ID}`}
                            type="text"
                            placeholder="Description"
                            className={className}
                            style={{ paddingRight: '35px' }}
                            value={textValue}
                            maxLength={35}
                            onChange={(event) => this.handleTextPropertyUpdate(event.target.value, property.ID, 'TextValue')}
                            disabled={this.props.disabled}
                        />
                    );
                } else {
                    let className = regularSelectClass;
                    if(((this.state.properties[index].AmountLimit > 0 && amount > this.state.properties[index].AmountLimit )
                    || this.state.properties[index].Mandatory )
                        && (entityProperty == undefined || entityProperty.TextValue === ''))
                    {
                        className = notFilledInputClass;
                    }
                    return (
                        <select
                            id={`form-attachment-description-${property.ID}`}
                            type="text"
                            placeholder="Description"
                            className={className}
                            value={textValue}
                            maxLength={35}
                            onChange={(event) => this.handleTextPropertyUpdate(event.target.value, property.ID, 'TextValue')}
                            disabled={this.props.disabled}
                        >
                            {property['PossibleValues$'].map((value) => (
                                <option value={value.Value}>{value.Description}</option>
                            ))}
                        </select>
                    );
                }
            }
            case 'Numeric': {
                let className = regularInputClass;
                var numValue = (entityProperty != undefined) ? entityProperty.NumValue: 0;

                if(((this.state.properties[index].AmountLimit > 0 && amount > this.state.properties[index].AmountLimit )
                    || this.state.properties[index].Mandatory )
                    && (entityProperty == undefined || entityProperty.NumValue === 0))
                {
                    className = notFilledInputClass;
                }

                return (
                    <NumberInput
                        id={`form-attachment-description-${property.ID}`}
                        value={numValue}
                        className={className}
                        style={{ paddingRight: '35px' }}
                        scale={getScaleByCurrency(this.state.baseCurrency)}  
                        type="Currency"
                        onChange={(event) => this.handleTextPropertyUpdate(event.target.value, property.ID, 'NumValue')}
                        disabled={this.props.disabled}
                    />
                );
            }
            case 'DateTime': {
                let dateValue = '';
                if (entityProperty != undefined && entityProperty.DateValue !== null) {
                    dateValue = new Date(entityProperty.DateValue);
                }
                let className = regularInputClass;
                if (((this.state.properties[index].AmountLimit > 0 && amount > this.state.properties[index].AmountLimit )
                || this.state.properties[index].Mandatory )
                    && (entityProperty != undefined || entityProperty.DateValue !== null)) {
                    className = notFilledInputClass;
                }
                return (
                    <DatePicker
                        id={`form-attachment-description-${property.ID}`}
                        className={className}
                        value={dateValue}
                        onDayChange={(value) => this.handleTextPropertyUpdate(value, property.ID, 'DateValue')}
                        disabled={!this.state.enableScreen}
                    />
                );
            }
        }
    };

    handleTextPropertyUpdate = (value, id, propertyValueToUpdate) => {
        let propertyIndex = this.state.entityProperties.findIndex((p) => p.UserDefinedPropertyID === id);
        if (propertyIndex !== -1) {
            let entityProperties = [...this.state.entityProperties];
            entityProperties[propertyIndex][propertyValueToUpdate] = value;
            if (propertyValueToUpdate === 'DateValue') {
                if (value instanceof Date && !isNaN(value)) {
                    entityProperties[propertyIndex][propertyValueToUpdate] = formatDate(value);
                } else {
                    entityProperties[propertyIndex][propertyValueToUpdate] = null;
                }
            }
            this.setState({ entityProperties, updatedProperties: true });
        }
    };

    handleFileUpdate = (event) => {
        let fileName = '';
        if (event.target.files[0] !== undefined) {
            fileName = event.target.files[0].name;
        }
        this.setState({ fileName: fileName });
    };

    handleFileDescriptionUpdate = (event) => {
        this.setState({ fileDescription: event.target.value });
    };

    onClose = () => {
        if (this.state.updatedProperties) {
            const updatedProperties = true;
            const entityProperties = [...this.state.entityProperties];
            const indexToUpdate = this.state.index;
            this.props.onClose(updatedProperties, entityProperties, indexToUpdate);
        } else {
            this.props.onClose();
        }
        this.clearDialogStateAndClose();
    };

    saveUserProperties = async () => {
        const model = {
            OutgoingFundsID: this.state.outgoingFundsID,
            EntityPropertiesJson: JSON.stringify(this.state.entityProperties),
            UpdatedBy: localStorage.getItem('UserID')
        };
        this.context.startLoading();
        const json = await saveOutgoingFundsEntityProperties(model);
        this.context.finishLoading();
        if (json != null) {
            switch (Number(json.httpStatusCode)) {
                case 200: {
                    this.setState({ showAlert: true, alertMessage: 'Required Information successfully saved', alertTitle: 'Success' });
                    break;
                }
                default: {
                    this.setState({ showAlert: true, alertMessage: 'json.httpErrorMessage', alertTitle: 'Error' });
                    break;
                }
            }
        }
    };

    dismissAlert = () => {
        this.setState({ showAlert: false });
    };

    render() {
        return !this.state.showAttachments ? (
            <React.Fragment>
                <Dialog open={this.state.open} onClose={this.onClose} fullWidth={true} maxWidth={'xs'}>
                    <DialogTitle>Required Information</DialogTitle>
                    <DialogContent dividers>
                        {this.state.properties.map((property, index) => {
                            let propertyIndex = this.state.entityProperties.findIndex((p) => p.UserDefinedPropertyID === property.ID);
                            if (propertyIndex !== -1) {

                            let attachmentsText = '';
                            let attachmentsColor = 'inherit';
                            if (this.state.entityProperties[propertyIndex] != null && this.state.entityProperties[propertyIndex].Attachments != null && this.state.entityProperties[propertyIndex].Attachments.length === 1) {
                                attachmentsText = `(${this.state.entityProperties[propertyIndex].Attachments.length} attachment)`;
                            } else if (this.state.entityProperties[propertyIndex] != null && this.state.entityProperties[propertyIndex].Attachments != null && this.state.entityProperties[propertyIndex].Attachments.length > 1) {
                                attachmentsText = `(${this.state.entityProperties[propertyIndex].Attachments.length} attachments)`;
                            }
                            if (this.state.entityProperties[propertyIndex] != null && (this.state.entityProperties[propertyIndex].Attachments == null || this.state.entityProperties[propertyIndex].Attachments.length === 0) 
                                && ((this.state.properties[index]['ExtAmountLimit$'] > 0 && this.state.amount > this.state.properties[index]['ExtAmountLimit$'])  || this.state.properties[index].Mandatory) 
                                && this.state.properties[index]['ExtAttachmentsRequired$'] ) {
                                attachmentsColor = 'red';
                            }

                            return (
                                <fieldset id={`div-prop-${property.ID}-${index}`} key={`div-prop-${property.ID}-${index}`}>
                                    <div className="uk-grid">
                                        <div className="uk-width-expand">
                                            <div className="uk-form-stacked">
                                                <div className="uk-form-controls">
                                                    <label className="uk-form-label">{property.Description}</label>
                                                    <div className="uk-grid" style={styles.inputStyle}>
                                                        <div className="uk-width-expand">{this.getPropertyControl(property, index)}</div>
                                                        <i
                                                            className="fa fa-paperclip"
                                                            style={{ paddingLeft: '20px', paddingTop: '6px', color: attachmentsColor, cursor: 'pointer' }}
                                                            onClick={() => this.showAttachmentsForm(property.ID, index)}
                                                        />
                                                        <label className="uk-form-label" style={{ paddingLeft: '20px', paddingTop: '5px' }}>
                                                            {attachmentsText}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            );
                            }
                        })}
                    </DialogContent>
                    <DialogActions>
                        {this.props.saveMode != null && (
                            <button className="uk-button uk-button-green" key={'save'} type="button" onClick={this.saveUserProperties}>
                                Save
                            </button>
                        )}
                        <button className="uk-button uk-button-green" key={'close'} type="button" onClick={this.onClose}>
                            Close
                        </button>
                    </DialogActions>
                </Dialog>
                <AlertBox
                    id="disbursement-dialog-alert"
                    open={this.state.showAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.dismissAlert}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Dialog open={this.state.open} onClose={this.onClose}>
                    <DialogTitle>{!this.props.disabled ? 'Upload Attachment' : 'Attachments'}</DialogTitle>
                    <DialogContent dividers>
                        {!this.props.disabled && (
                            <div className="uk-grid">
                                <div className="uk-form-stacked">
                                    <div uk-form-custom="target: true" className="uk-margin file-box">
                                        <Lottie animationData={uploadAnimation} style={{ height: '200px' }} />
                                        <h5>Click or Drag&Drop the file you want to attach</h5>
                                        <input
                                            type="file"
                                            ref={this.fileInput}
                                            className="file_upload"
                                            // style={{ top: 'auto', bottom: 'auto', height: 'auto' }}
                                            onChange={this.handleFileUpdate}
                                            disabled={this.props.disabled}
                                        />
                                        <input type="text" value={this.state.fileName} placeholder="Select a file..." className="uk-input uk-form-width-large" disabled />
                                    </div>
                                    <div className="uk-margin">
                                        <div className="uk-form-controls">
                                            <label className="uk-form-label" htmlFor="form-attachment-description">
                                                Description
                                            </label>
                                            <input
                                                className="uk-input"
                                                id="form-attachment-description"
                                                type="text"
                                                value={this.state.fileDescription}
                                                onChange={this.handleFileDescriptionUpdate}
                                                disabled={this.props.disabled}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.entityProperties != null &&
                            this.state.entityProperties[this.state.selectedEntityPropertyIndex].Attachments != null &&
                            this.state.entityProperties[this.state.selectedEntityPropertyIndex].Attachments.length > 0 && (
                                <React.Fragment>
                                    <div className="uk-grid">
                                        <label className={!this.props.disabled ? 'uk-width-1-3' : 'uk-width-1-2'} style={styles.semiboldLabel}>
                                            File name
                                        </label>
                                        <label className={!this.props.disabled ? 'uk-width-1-3' : 'uk-width-1-2'} style={styles.semiboldLabel}>
                                            Description
                                        </label>
                                    </div>
                                    {this.state.entityProperties[this.state.selectedEntityPropertyIndex].Attachments.map((attachment) => {
                                        return (
                                            <div className="uk-grid" style={{ marginTop: '0px' }}>
                                                <label
                                                    className={!this.props.disabled ? 'uk-width-1-3 attachment-link' : 'uk-width-1-2 attachment-link'}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => this.showAttachmentsContent(attachment)}
                                                >
                                                    {attachment.FileName}
                                                </label>
                                                <label className={!this.props.disabled ? 'uk-width-1-3' : 'uk-width-1-2'}>{attachment.Description}</label>
                                                {!this.props.disabled && (
                                                    <i className="fa fa-trash" style={{ cursor: 'pointer' }} onClick={() => this.deleteAttachment(attachment.ID)} />
                                                )}
                                            </div>
                                        );
                                    })}
                                    <Dialog open={this.state.openAttachmentPreview} onClose={this.onCloseAttachmentPreview} fullWidth={true} maxWidth={'md'}>
                                        <DialogTitleAttachmentPreview id="current-attachment-dialog-title" onClose={this.onCloseAttachmentPreview}>
                                            {this.state.currentFileName}
                                        </DialogTitleAttachmentPreview>
                                        <DialogContent dividers style={{ padding: 0 }}>
                                            <DocViewer
                                                pluginRenderers={DocViewerRenderers}
                                                documents={this.state.currentFileURLList}
                                                config={{
                                                    header: {
                                                        disableHeader: true,
                                                        disableFileName: true,
                                                        retainURLParams: false
                                                    }
                                                }}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </React.Fragment>
                            )}
                    </DialogContent>
                    <DialogActions>
                        {!this.props.disabled && (
                            <button className="uk-button uk-button-green" key={'upload'} type="button" onClick={this.handleUploadFile}>
                                Upload
                            </button>
                        )}
                        <button className="uk-button uk-button-green" key={'back'} type="button" onClick={this.onBack}>
                            Close
                        </button>
                    </DialogActions>
                </Dialog>
                <AlertBox
                    id="disbursement-dialog-alert"
                    open={this.state.showAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.dismissAlert}
                />
                <AlertBox
                    id="open-attachment"
                    open={this.state.showOpenAttachmentWarning}
                    onClose={this.closeAttachmentWarning}
                    title="Warning"
                    message="The file has no preview. Do you want to download the file?"
                    type="Yes/No"
                    yesClick={this.yesDowloadFile}
                    noClick={this.closeAttachmentWarning}
                />
            </React.Fragment>
        );
    }
}
