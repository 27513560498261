import {genericCallWithBody} from './Networking';

export async function runEODProcess(objParameters){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'EOD/Run';
    let response = await genericCallWithBody(method, apiName, objParameters);
    return response;
}

export async function ValidateEOD(objParameters){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'EOD/ValidateEOD';
    let response = await genericCallWithBody(method, apiName, objParameters);
    return response;
}

export async function ValidationEOD_Date(){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'EOD/ValidationEOD_Date';
    let datePC = new Date();
    const objParameters = {
        datePC: datePC
    };
    let response = await genericCallWithBody(method, apiName, objParameters);
    return response;
}
