import React from 'react';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { validate } from '../../../helpers/ValidateHelper';
import { searchCountries } from '../../../networking/NetworkingCountries';
import { getAllRoles, getRolesByUsername } from '../../../networking/NetworkingRoles';
import { getBusinessUnits, getListOfBranch, getReportTo, saveUser } from '../../../networking/NetworkingUsers';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import DynamicSelect from '../../shared/DynamicSelect';
import LoadingBox from '../../shared/LoadingBox';
import NumberInput from '../../shared/NumberInput';
import { getPlaceHolder, getScaleByCurrency } from '../../../helpers/FormatHelper'
import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames';
import {Colors} from '../../../constants/Colors'
import { v4 as uuidv4 } from 'uuid';

var style = getStyle();

const itemsPerPage = getDefaultPagingNumberOfItems();
export default class CreateUserForm extends React.Component {

    state = {
        applicationName: 'ForeignExchange',
        //--
        firstName: '',
        lastName: '',
        userName: '',
        emailAddress: '',
        phoneNumber: '',
        password: '',
        confirmPassw: '',
        userCommi: '',
        corpClientCommi: '',
        privClientCommi: '',
        dealLimit: '',
        accountLock: '',
        reqApproval: '',
        allPrevDays: '',
        allTradeUpdate: '',
        allSameDay: '',
        allSingleDeal: '',
        restCustomerAcc: '',
        restBranchAcc: '',
        commissionOver: '',
        numDaysPrevi: 0,
        expirationNumber: 90,
        country: '',
        businessUnits: [],
        businessUnitsID: '',
        businessUnitsName: '',
        reportsList: [],
        reportsToID: '',
        reportsToName: '',
        branchList: [],
        //branchID: '', --ya no se usa este
        branchName: '',
        additionalBranchID:0,
        additionalBranchName : '',
        usID: '',
        updateToken: '',
        //For ddwnCountry
        countryId: '',
        countryIdName: '',
        countryList: [],
        //For tables
        rolesAvailableForOnlineUsers: [],
        listTestProfile: [],
        selectIDLeft: '',
        selectIDRight: '',
        listHave: [],//originLists
        listWant: [],//originLists
        //--
        isCreate    : false,
        isEqual     : false,
        loading     : false,
        isTrader    : false,
        isPassValid : false,
        //Box
        showAlert: '',
        showAlertError: '',
        alertTitle: '',
        alertMessage: '',
        errors: [],
        validations: {
            firstName: true,
            lastName: true,
            userName: true,
            password: true,
            confirmPassw: true,
            emailAddress: true,
            //branchID: true,
            phoneNumber: true,
            dealLimit: true,
            mobileNumber: true
        },
        baseCurrency: '',
        mobile:'',
        additionalValidation:false,
        allowedIPs:'',
        otpExpirationTime:0,
        isReferral: false
    }

    componentDidMount() {
        let baseCurrency = localStorage.getItem(GlobalSystemParametersNames.BaseCurrency);
        this.setState({ baseCurrency: baseCurrency })
        getAllRoles(this.state.applicationName, 1, 200).then(
            (json) => {
                this.setState({ listHave: json.Roles }, () => {
                    getListOfBranch().then(
                        (json) => {
                            console.log(json)
                            this.setState({ branchList: this.transformListBranches(json.branches) }, () => {
                                if (!this.props.isCreate) {
                                    if (this.props.selectedUser.ApplicationName !== undefined) {
                                        var selectedUser = this.props.selectedUser
                                        ////debugger
                                        let isLock = false;
                                        if (selectedUser.IsLock != null && selectedUser.Islock == null) {
                                            if (typeof selectedUser.IsLock === 'string') {
                                                isLock = selectedUser.IsLock === 'Yes';
                                            } else {
                                                isLock = selectedUser.IsLock
                                            }
                                        } else {
                                            if (typeof selectedUser.Islock === 'string') {
                                                isLock = selectedUser.Islock === 'Yes';
                                            } else {
                                                isLock = selectedUser.Islock
                                            }
                                        }
                                        this.setState({
                                            //branchID: selectedUser['Teller Branch'] != null ? selectedUser['Teller Branch'] : '',
                                            dealLimit: selectedUser.DealsLimit != null ? selectedUser.DealsLimit : 0,
                                            reqApproval: selectedUser.RequireApproval,
                                            allPrevDays: selectedUser.AllowPreviousDayVoid,
                                            allTradeUpdate: selectedUser.AllowTradeUpdates,
                                            allSameDay: selectedUser.AllowSameDayVoid,
                                            allSingleDeal: selectedUser.AllowSingleDealRoll,
                                            restCustomerAcc: selectedUser.RestrictCustomerAccess,
                                            restBranchAcc: selectedUser.RestrictBranchAccess,
                                            isEmailValid: selectedUser.Email !== '' ? true : false,
                                            firstName: selectedUser.FirstName,
                                            lastName: selectedUser.LastName,
                                            userName: selectedUser.UserName,
                                            emailAddress: selectedUser.Email,
                                            phoneNumber: selectedUser.PhoneNumber,
                                            userCommi: selectedUser.UserCommissionPercentage,
                                            accountLock: isLock,
                                            usID: selectedUser.UserID,
                                            commissionOver: selectedUser.CommissionOverride,
                                            numDaysPrevi: selectedUser.noPreviousPwds,
                                            expirationNumber: selectedUser.noDaysPasswordExpires,
                                            countryId: selectedUser.CountryID,
                                            reportsToID: selectedUser.ReportsToUserID,
                                            businessUnitsID: selectedUser.BusinessUnitID,
                                            updateToken: selectedUser.UpdateToken !== null ? selectedUser.UpdateToken : '',
                                            password: '*********',
                                            confirmPassw: '*********',
                                            isEqual: true,
                                            isPassValid: true,
                                            additionalBranchID: selectedUser['TellerBranchID'],
                                            mobileNumber: selectedUser.MobileNumber,
                                            additionalValidation: selectedUser.AdditionalValidation,
                                            allowedIPs: selectedUser.AllowedIPs,
                                            otpExpirationTime: selectedUser.OTPExpirationTime,
                                            isReferral: selectedUser.IsReferral
                                        }, () => {
                                            this.searchCountry(selectedUser.CountryID)
                                            this.getRolesByUserName(selectedUser.UserName)
                                        })
                                    }
                                } else {
                                    this.setState({ reqApproval: false, allTradeUpdate: false, allSingleDeal: false, allPrevDays: false, allSameDay: false, restCustomerAcc: false, restBranchAcc: false, isReferral: false })
                                }
                            })
                        }
                    );
                })
            }
        );
        getReportTo(this.state.applicationName).then(
            (json) => {
                console.log(json)
                this.setState({ reportsList: this.transformListReport(json) })
            }
        );
    }

    transformListReport(list) {
        var helper = [];
        for (var i = 0; i < list.length; i++) {
            if (list[i] != null) {
                helper.push({
                    'name': list[i].FullName,
                    'value': list[i].UserID
                });
            }
        }
        return helper;
    }
    transformListBranches(list) {
        var helper = [];
        for (var i = 0; i < list.length; i++) {
            helper.push({
                'name': list[i].Description,
                'value': list[i].BranchId
            });
        }
        return helper;
    }
    getBusiness = (countryID) => {
        getBusinessUnits(countryID).then(
            (json) => {
                if (json.businessUnities !== undefined) {
                    var helper = [];
                    for (var i = 0; i < json.businessUnities.length; i++) {
                        helper.push({
                            'name': json.businessUnities[i].Description,
                            'value': json.businessUnities[i].BusinessUnitId
                        });
                    }
                    this.setState({ businessUnits: helper });
                } else { }
            }
        );
    }

    getRolesByUserName = (username) => {
        getRolesByUsername(this.state.applicationName, username).then(
            (json) => {
                const hasRole = (role) => json.some((selectedRole) => role === selectedRole);
                const availableRoles = this.state.listHave.filter((role) => !hasRole(role['Role Name']));
                const selectedRoles = json.map((item) => ({ 'Role Name': item }));
                let isTrader = false;
                if (hasRole('Traders')) {
                    isTrader = true
                }
                this.setState({
                    listWant: selectedRoles,
                    listHave: availableRoles,
                    selectIDLeft: '',
                    isTrader
                });
            }
        );
    }

    //--COUNTRIES
    searchCountry(query) {
        searchCountries(query).then(
            (json) => {
                var helper = [];
                if (query !== '') {
                    console.group('Search Countries');
                    console.log('Query');
                    console.table(query);
                    console.log('Response');
                    console.table(json);
                    console.groupEnd();
                    for (var i = 0; i < json.length; i++) {
                        json[i].value = json[i].CountryId;
                        json[i].name = json[i].CountryIdName;
                        helper.push(json[i]);
                    }
                    console.log(helper);
                    this.setState({
                        countryList: helper
                    });
                    if (helper.length === 1) {
                        var value = helper[0];
                        this.setState({
                            countryId: value.CountryId,
                            countryIdName: value.CountryIdName
                        });
                        this.getBusiness(value.CountryId)
                    }else if(helper.length > 1){
                        helper.forEach(c => {
                            if(c.CountryId==this.state.countryId){
                                this.setState({
                                    countryId: c.CountryId,
                                    countryIdName: c.CountryIdName
                                });
                                this.getBusiness(c.CountryId)
                            }
                        });
                    }
                }
            }
        );
        console.table(this.state.countries)
    }
    getCountryValue(value) {
        if (value.value !== undefined) {
            this.setState({
                countryId: value.value, countryIdName: value.CountryIdName
            });
        }
    }

    handleOnCheck = (event) => {
    }

    handleSaveUserClick = (event) => {
        let userID = localStorage.getItem('UserID');
        var isLock, commOver = ''
        if (this.state.accountLock !== '') { isLock = this.state.accountLock } else { isLock = false }
        if (this.state.commissionOver !== '') { commOver = this.state.commissionOver } else { commOver = false }
        var objUser = {
            User: {
                'ApplicationName': this.state.applicationName,
                'UserID': this.state.usID,
                'UserName': this.state.userName,
                'Email': this.state.emailAddress,
                'noDaysPasswordExpires': this.state.expirationNumber,
                'noPreviousPwds': this.state.numDaysPrevi,
                'IsLock': isLock,
                'UserCommissionPercentage': '',
                'CommissionOverride': commOver,
                'CountryID': this.state.countryId != null ? this.state.countryId : '',
                'BusinessUnitID': this.state.businessUnitsID != null ? this.state.businessUnitsID : '',
                'ReportsToUserID': this.state.reportsToID != null ? this.state.reportsToID : '',
                'FirstName': this.state.firstName,
                'LastName': this.state.lastName,
                'PhoneNumber': this.state.phoneNumber,
                'RequireApproval': this.state.reqApproval,
                'AllowTradeUpdates': this.state.allTradeUpdate,
                'AllowSingleDealRoll': this.state.allSingleDeal,
                'AllowPreviousDayVoid': this.state.allPrevDays,
                'AllowSameDayVoid': this.state.allSameDay,
                'RestrictCustomerAccess': this.state.restCustomerAcc,
                'RestrictBranchAccess': this.state.restBranchAcc,
                'branchId': this.state.additionalBranchID,
                'dealLimit': this.state.dealLimit != null ? this.state.dealLimit : 0,
                'UpdatedBy': userID,
                'UpdateToken': this.state.updateToken,
                'TellerBranchID' : this.state.additionalBranchID,
                'MobileNumber': this.state.mobileNumber,
                'AdditionalValidation': this.state.additionalValidation,
                'AllowedIPs': this.state.allowedIPs,
                'OTPExpirationTime': this.state.otpExpirationTime,
                'IsReferral': this.state.isReferral
            },
            Claim: {
                'Questions': {},
                'Password': this.state.password,
                'NewPassword': this.state.confirmPassw,
                'SecurityToken': 'ae83565b-9ffb-4075-9477-4da20dab2fae'
            },
            Roles: this.transformListWant(this.state.listWant)
        }
        console.log(objUser)
        var errors = [];
        if (this.validateRequiredInputs()) 
        {
            if (this.state.listWant.length > 0) 
            {
                if (this.state.isEmailValid) 
                {
                    if(this.state.isPassValid)
                    {
                        if (this.state.isEqual) 
                        {
                            this.setState({ loading: true })
                            saveUser(objUser).then(
                                (json) => {
                                    console.log(json)
                                    this.setState({ loading: false })
                                    if (json.httpStatusCode !== 200) {
                                        errors = [json.Message]
                                        this.setState({ errors: errors, showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fix the following errors:', notFirstLoad: true });
                                    } else {
                                        this.handleClearClick(event)
                                        this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: 'User successfully saved.' })
                                    }
                                    this.props.isCreateClick(false)
                                }
                            );
                        } else {
                            errors = ['Your password do not match. Please try again']
                            this.setState({ errors: errors, showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fix the following errors:', notFirstLoad: true });
                        }
                    }else
                    {
                        errors = ['Your password must contain at least one number, one special character, one lower case letter, and one upper case letter. The minimum password length is 6 characters. (NOTE - special characters accepted only include @ # $ % &)']
                        this.setState({ 
                            errors: errors, 
                            showAlertError: true, 
                            alertTitle: 'Error', 
                            alertMessage: 'Please fix the following errors:', 
                            notFirstLoad: true,
                            validations : { ...this.state.validations, password: false }, 
                        });
                    }

                } else {
                    errors = ['Your email is incorrect. Please try again']
                    this.setState({ errors: errors, showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fix the following errors:', notFirstLoad: true });
                }
            } else {
                errors = ['Add at least one Role. Please try again']
                this.setState({ errors: errors, showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fix the following errors:', notFirstLoad: true });
            }
        } else {
            this.setState({ showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fix the following errors:', notFirstLoad: true });
        }
    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' }, () => this.props.getStep(1));
    }
    closeAlert2() {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '' });
    }
    transformListWant(list) {
        var helper = [];
        for (var i = 0; i < list.length; i++) {
            helper.push(list[i]['Role Name']);
        }
        return helper;
    }
    //--ROLES
    //For color left & right
    roleChangeColorLeft = selectIDLeft => e => {
        if (selectIDLeft !== undefined) {
            this.setState({ selectIDLeft });
        }
    };
    roleChangeColorRight = selectIDRight => e => {
        if (selectIDRight !== undefined) {
            this.setState({ selectIDRight });
        }
    };

    handleAddClick = (event) => {
        event.preventDefault();
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;
        var idSelected = this.state.selectIDLeft;
        var add = true;
        var isTrader = this.state.isTrader;

        if (idSelected !== '') 
        {
            if (listRight.length === 0) 
            {
                listLeft.forEach((role, index) => 
                {
                    if (role['Role Name'] === idSelected) 
                    {
                        listRight.push(role)
                        listLeft.splice(index, 1)
                    }
                });

                if (idSelected === 'Traders') {
                    isTrader = true
                }

                this.setState({
                    listWant: listRight,
                    listHave: listLeft,
                    selectIDLeft: '',
                    isTrader
                });

            } else {
                listRight.forEach(rightRole => {
                    if (rightRole['Role Name'] === idSelected) 
                    {
                        add = false
                    }
                });

                if (add) 
                {
                    listLeft.forEach((leftRole, index) => 
                    {
                        if (leftRole['Role Name'] === idSelected) 
                        {
                            listRight.push(leftRole)
                            listLeft.splice(index, 1)
                        }
                    });


                    if (idSelected === 'Traders') 
                    {
                        isTrader = true
                    }

                    this.setState({
                        listWant: listRight,
                        listHave: listLeft,
                        selectIDLeft: '',
                        isTrader
                    });
                }
            }
        }
    }

    handleAddAllClick = (event) => {
        event.preventDefault();
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;

        Array.prototype.push.apply(listRight, listLeft)
        this.setState({
            listWant: listRight,
            listHave: [],
            selectIDLeft: '',
            selectIDRight: '',
            isTrader: true
        });
    }

    handleRemoveClick = (event) => {
        event.preventDefault();
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;
        var idSelected = this.state.selectIDRight;
        var isTrader = this.state.isTrader;

        if (idSelected !== '') 
        {
            listRight.forEach((rightRole, index) => 
            {
                if (rightRole['Role Name'] === idSelected) 
                {
                    listRight.splice(index, 1)
                    listLeft.push(rightRole)
                }
            });

            if (idSelected === 'Traders') 
            {
                isTrader = false
            }

            this.setState({
                listWant: listRight,
                listHave: listLeft,
                selectIDRight: '',
                isTrader
            });
        }
    }
    handleRemoveAllClick = (event) => {
        event.preventDefault();
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;

        Array.prototype.push.apply(listLeft, listRight)

        this.setState({
            listWant: [],
            listHave: listLeft,
            selectIDLeft: '',
            selectIDRight: '',
            isTrader: false
        });
    }

    //--ROLES
    handleUpdateFirstName = (event) => {
        this.setState({ validations: { ...this.state.validations, firstName: true }, firstName: event.target.value });
    }
    handleUpdateLastName = (event) => {
        this.setState({ validations: { ...this.state.validations, lastName: true }, lastName: event.target.value });
    }
    handleUpdateUserName = (event) => {
        this.setState({ validations: { ...this.state.validations, userName: true }, userName: event.target.value });
    }

    handleUpdateAllowedIPs = (event) =>{
        this.setState({allowedIPs: event.target.value});
    }

    handleUpdateOTPExpirationTime = (event) =>{
        this.setState({otpExpirationTime: event.target.value});
    }

    handleUpdateEmailAddress = (event) => {
        this.setState({ emailAddress: event.target.value },
            () => {
                if (this.validateEmail()) {
                    this.isEmailValid(true);
                } else {
                    this.isEmailValid(false);
                }
            });
    }
    isEmailValid(validator) {
        if (validator) {
            this.setState({ isEmailValid: true });
        } else {
            this.setState({ isEmailValid: false });
        }
    }
    validateEmail() {
        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.emailAddress.match(regex)) {
            return false;
        }
        return true;
    }
    handleUpdatePhoneNumber = (event) => {
        this.setState({ validations: { ...this.state.validations, phoneNumber: true }, phoneNumber: event.target.value });
    }

    handleUpdateMobileNumber = (event) => {
        this.setState({ validations: { ...this.state.validations, mobileNumber: true }, mobileNumber: event.target.value });
    }
    handleUpdatePassword = (event) => {
        if(event != undefined)
        {   
            var regStrength = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&])[0-9a-zA-Z@#$%&]{6,50}$/;
            var isValid = regStrength.test(event.target.value);

            this.setState({ 
                validations : { ...this.state.validations, password: true }, 
                password    : event.target.value,
                isPassValid : isValid
            });
        }
        
    }
    handleUpdateConfirmPassw = (event) => {
        if (event.target.value !== this.state.password) {
            this.setState({ validations: { ...this.state.validations, confirmPassw: true }, confirmPassw: event.target.value, isEqual: false });
        } else {
            this.setState({ validations: { ...this.state.validations, confirmPassw: true }, confirmPassw: event.target.value, isEqual: true });
        }
    }
    handleUpdateUserCommi = (event) => {
        this.setState({ userCommi: event.target.value });
    }
    handleUpdateCorpClientCommi = (event) => {
        this.setState({ corpClientCommi: event.target.value });
    }
    handleUpdatePrivClientCommi = (event) => {
        this.setState({ privClientCommi: event.target.value });
    }
    handleUpdateDealLimit = (event) => {
        this.setState({ validations: { ...this.state.validations, dealLimit: true }, dealLimit: event.target.value });
    }
    handleUpdateAccountLock = (event) => {
        this.setState({ accountLock: event.target.checked });
    }
    handleUpdateCommissionOver = (event) => {
        this.setState({ commissionOver: event.target.checked });
    }
    handleUpdateReqApproval = (event) => {
        this.setState({ reqApproval: event.target.checked });
    }
    handleUpdateAllPrevDays = (event) => {
        this.setState({ allPrevDays: event.target.checked });
    }
    handleUpdateAllTradeUpdate = (event) => {
        this.setState({ allTradeUpdate: event.target.checked });
    }
    handleUpdateAllSameDay = (event) => {
        this.setState({ allSameDay: event.target.checked });
    }
    handleUpdateAllSingleDeal = (event) => {
        this.setState({ allSingleDeal: event.target.checked });
    }
    handleUpdateRestCustomerAcc = (event) => {
        this.setState({ restCustomerAcc: event.target.checked });
    }
    handleUpdateRestBranchAcc = (event) => {
        this.setState({ restBranchAcc: event.target.checked });
    }
    handleUpdateNumDaysPrevi = (event) => {
        this.setState({ numDaysPrevi: event.target.value });
    }
    handleUpdateExpirationNumber = (event) => {
        this.setState({ expirationNumber: event.target.value });
    }
    handleUpdateCountry = (event) => {
        this.setState({ country: event.target.value });
    }
    handleUpdateBusinessUnits = (value) => {
        if (value.value !== undefined) {
            this.setState({ businessUnitsID: value.value, businessUnitsName: value.name });
        } else { }
    }
    handleUpdateReportsTo = (value) => {
        if (value !== null) {
            if (value.value !== undefined) {
                this.setState({ reportsToID: value.value, reportsToName: value.name });
            } else { }
        } else { }
    }
    handleUpdateAdditionalValidation = (event) => {
        let additional = event.target.checked;
        let otp = (!additional? 0: this.state.otpExpirationTime);

        this.setState({ additionalValidation: additional, otpExpirationTime: otp });
    }

    handleUpdateIsReferral = (event) =>{
        this.setState({ isReferral: event.target.checked });
    }
    /*handleUpdateBranches = (value) => {
        if (value !== null) {
            if (value.value != null) {
                this.setState({ 
                    validations: { ...this.state.validations, branchID: true }, 
                    branchID: value.value, branchName: value.name 
                });
            } else { }
        } else { }
    }*/

    handleUpdateAdditionalBranches = (value) => {
        if (value !== null) {
            if (value.value != null) {
                this.setState({ additionalBranchID: value.value, additionalBranchName: value.name });
            } else { }
        } else { }
    }

    handleCancelClick = (event) => {
        var selectUser = {}
        this.props.blankUser(selectUser);
        this.handleClearClick(event);
        this.props.getStep(1);
    }

    handleClearClick = (event) => {
        var selectUser = {}
        this.props.blankUser(selectUser)
        this.handleRemoveAllClick(event)
        this.setState({
            firstName: '',
            lastName: '',
            userName: '',
            emailAddress: '',
            phoneNumber: '',
            password: '',
            confirmPassw: '',
            userCommi: '',
            accountLock: '',
            commissionOver: '',
            numDaysPrevi: 0,
            expirationNumber: 90,
            country: '',
            businessUnitsID: '',
            businessUnitsName: '',
            reportsToID: '',
            reportsToName: '',
            usID: '',
            updateToken: '',
            countryId: '',
            countryIdName: '',
        })
    }
    //Inputs Validations
    validateRequiredInputs() {
        const fields = [
            { displayName: 'First Name', validateName: 'firstName', 'value': this.state.firstName, type: 'string' },
            { displayName: 'Last Name', validateName: 'lastName', 'value': this.state.lastName, type: 'string' },
            { displayName: 'User Name', validateName: 'userName', 'value': this.state.userName, type: 'string' },
            { displayName: 'Password', validateName: 'password', 'value': this.state.password, type: 'string' },
            { displayName: 'Confirm Password', validateName: 'confirmPassw', 'value': this.state.confirmPassw, type: 'string' },
            { displayName: 'Email Address', validateName: 'emailAddress', 'value': this.state.emailAddress, type: 'string' }
        ]
        let listRight = this.state.listWant;
        listRight.forEach((obj) => {
            if (obj['Role Name'] === 'Traders') {
                fields.push({ displayName: 'Teller Branch', validateName: 'additionalBranchID', 'value': this.state.additionalBranchID, type: 'string' },
                    { displayName: 'Phone Number', validateName: 'phoneNumber', 'value': this.state.phoneNumber, type: 'number' },
                    { displayName: 'Deal Limit', validateName: 'dealLimit', 'value': this.state.dealLimit, type: 'number' })
            }
        });

        const [valid, validations, errors] = validate(fields);

        this.setState({ validations: { ...this.state.validations, ...validations }, errors: errors });

        return valid;
    }
    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }
    render() {
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;
        var idVariable = 'form-role-country' + this.props.selectedUser.UserID;
        var isTrader = false;
        let accordionID = 'accordion-customer-information-' + uuidv4();

        if (listRight.length !== 0) {
            listRight.forEach(rightRole => {
                if (rightRole['Role Name'] === 'Traders') {
                    isTrader = true
                }
            });
        }
        return (
            <div>
                <ul id={accordionID} uk-accordion="multiple: true" >
                            {/*General Information*/}
                            <li className="uk-open">
                                <h5 className="uk-accordion-title">General Information</h5>
                                <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                <div className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">First Name(*)</label>
                            <div className="uk-form-controls">
                                <input className={this.formatValidInput(this.state.validations.firstName)} id="form-role-name" value={this.state.firstName} type="text" onChange={this.handleUpdateFirstName} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">Last Name(*)</label>
                            <div className="uk-form-controls">
                                <input className={this.formatValidInput(this.state.validations.lastName)} id="form-role-name" value={this.state.lastName} type="text" onChange={this.handleUpdateLastName} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">User Name(*)</label>
                            <div className="uk-form-controls">
                                <input className={this.formatValidInput(this.state.validations.userName)} id="form-role-name" value={this.state.userName} type="text" onChange={this.handleUpdateUserName} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">Email Address(*)</label>
                            <div className="uk-form-controls">
                                <input className={this.formatValidInput(this.state.validations.emailAddress)} id="form-role-name" value={this.state.emailAddress} type="text" onChange={this.handleUpdateEmailAddress} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">Phone Number</label>
                            <div className="uk-form-controls">
                                <input className={this.formatValidInput(this.state.validations.phoneNumber)} id="form-role-name" value={this.state.phoneNumber} type="text" onChange={this.handleUpdatePhoneNumber} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">Password(*)</label>
                            <div className="uk-form-controls">
                                <input className={this.formatValidInput(this.state.validations.password)} id="form-role-name" value={this.state.password} type="password" onChange={this.handleUpdatePassword} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">Confirm Password(*)</label>
                            <div className="uk-form-controls">
                                <input className={this.formatValidInput(this.state.validations.confirmPassw)} id="form-role-name" value={this.state.confirmPassw} type="password" onChange={this.handleUpdateConfirmPassw} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked uk-margin" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name"><input className="uk-checkbox" type="checkbox" checked={this.state.accountLock} onChange={this.handleUpdateAccountLock} /> Account Locked</label>
                        </div>
                    </div>
                    
                    <div className="uk-width-1-4 uk-form-stacked" >
                    <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-mobile">Mobile Number</label>
                            <div className="uk-form-controls">
                                <input className={this.formatValidInput(this.state.validations.mobileNumber)} id="form-role-name" value={this.state.mobileNumber} type="text" onChange={this.handleUpdateMobileNumber} />
                            </div>
                        </div>
                        </div>
                    {/* CONDITIONAL TRADERS */}
                    {!isTrader &&
                        <div className="uk-width-1-4 uk-form-stacked" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name">User Commission</label>
                                <div className="uk-form-controls">
                                <NumberInput id="form-role-name" type="Rate" scale={2} className='uk-input' placeholder="___0,00" value={this.state.userCommi} onChange={this.handleUpdateUserCommi} />
                                </div>
                            </div>
                        </div>
                    }
                    {/* CONDITIONAL TRADERS */}
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name"># of previous password to store</label>
                            <div className="uk-form-controls">
                                <NumberInput id="form-role-name" type="Integer" className='uk-input' placeholder="0" value={this.state.numDaysPrevi} onChange={this.handleUpdateNumDaysPrevi} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">Expiration number days</label>
                            <div className="uk-form-controls">
                                <NumberInput id="form-role-name" type="Integer" className='uk-input' placeholder="0" value={this.state.expirationNumber} onChange={this.handleUpdateExpirationNumber} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">Country</label>
                            <div className="uk-form-controls">
                                <DynamicSearchSelect
                                    className="uk-select"
                                    id={idVariable}
                                    placeholder="Select a country"
                                    searchFunction={this.searchCountry.bind(this)}
                                    getValue={this.getCountryValue.bind(this)}
                                    data={this.state.countryList}
                                    objValue={{ value: this.state.countryId, name: this.state.countryIdName }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">Business Units</label>
                            <div className="uk-form-controls">
                                <DynamicSelect className="uk-select" id="form-role-business" getValue={this.handleUpdateBusinessUnits} placeholder="Select your item" data={this.state.businessUnits} objValue={{ value: this.state.businessUnitsID, name: this.state.businessUnitsName }} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">Reports To</label>
                            <div className="uk-form-controls">
                                <DynamicSelect className="uk-select" id="form-role-reports" getValue={this.handleUpdateReportsTo} placeholder="Select your item" data={this.state.reportsList} objValue={{ value: this.state.reportsToID, name: this.state.reportsToName }} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name">Teller Branch</label>
                                <div className="uk-form-controls">
                                    <DynamicSelect className="uk-select" id="form-role-reports" getValue={this.handleUpdateAdditionalBranches} placeholder="Select your item" data={this.state.branchList} objValue={{ value: this.state.additionalBranchID, name: this.state.additionalBranchName }} />
                                </div>
                            </div>
                        </div>
                    <div className="uk-width-1-4 uk-form-stacked" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">Allowed IPs</label>
                            <div className="uk-form-controls">
                                <input className={regularInputClass} id="form-role-name" value={this.state.allowedIPs} type="text" onChange={this.handleUpdateAllowedIPs} />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </li>

                {/*Roles*/}
                <li>
                    <h5 className="uk-accordion-title">Roles</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1 uk-margin">
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                border: '1px solid #9DA99E'
                            }}
                        >
                            <div
                                className="uk-width-1-3"
                                style={{
                                    overflowY: 'scroll',
                                    height: 320,
                                }}
                            >
                                <table
                                    className="uk-table uk-table-hover uk-table-divider"
                                    style={{
                                        marginBottom: 0
                                    }}
                                >
                                    <tbody>
                                        {listLeft.map((item, i) => {
                                            return (
                                                <tr
                                                    key={item['Role Name']} onClick={this.roleChangeColorLeft(item['Role Name'])}
                                                    style={
                                                        this.state.selectIDLeft === item['Role Name'] ?
                                                            { backgroundColor: Colors.primaryColor, cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                    }
                                                >
                                                    <td
                                                        style={
                                                            this.state.selectIDLeft === item['Role Name'] ?
                                                                { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                        }
                                                    >{item['Role Name']}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div
                                className="uk-card-body uk-width-1-3"
                                style={{
                                    textAlign: 'center',
                                    borderRight: '1px solid #9DA99E',
                                    borderLeft: '1px solid #9DA99E'
                                }}
                            >
                                <button
                                    className="uk-button uk-button-green uk-width-1-2"
                                    style={{
                                        marginBottom: 20,
                                    }}
                                    onClick={this.handleAddClick.bind(this)}
                                >
                                    Add
                                </button>
                                <button
                                    className="uk-button uk-button-green uk-width-1-2"
                                    style={{
                                        marginBottom: 20,
                                    }}
                                    onClick={this.handleAddAllClick.bind(this)}
                                >
                                    Add all
                                </button>
                                <button
                                    className="uk-button uk-button-green uk-width-1-2"
                                    style={{
                                        marginBottom: 20,
                                    }}
                                    onClick={this.handleRemoveClick.bind(this)}
                                >
                                    Remove
                                </button>
                                <button
                                    className="uk-button uk-button-green uk-width-1-2"
                                    style={{
                                        marginBottom: 20,
                                    }}
                                    onClick={this.handleRemoveAllClick.bind(this)}
                                >
                                    Remove all
                                </button>
                            </div>
                            <div
                                className="uk-width-1-3"
                                style={{
                                    overflowY: 'scroll',
                                    height: 320,
                                    paddingLeft: 0,
                                }}
                            >
                                <table
                                    className="uk-table uk-table-hover uk-table-divider"
                                    style={{
                                        marginBottom: 0
                                    }}
                                >
                                    <tbody>
                                        {listRight.map((item, i) => {
                                            return (
                                                <tr
                                                    key={item['Role Name']} onClick={this.roleChangeColorRight(item['Role Name'])}
                                                    style={
                                                        this.state.selectIDRight === item['Role Name'] ?
                                                            { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                    }
                                                >
                                                    <td
                                                        style={
                                                            this.state.selectIDRight === item['Role Name'] ?
                                                                { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                        }
                                                    >{item['Role Name']}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
                </div>
                </li>

                {/*Options*/}
                <li>
                                <h5 className="uk-accordion-title">Options</h5>
                                <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                <div className="uk-form-stacked uk-grid">

                    {/*---------TRADERS2-----------*/}
                    {isTrader &&
                        <div className="uk-width-1-4 uk-form-stacked" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name">Corporate Client Commission</label>
                                <div className="uk-form-controls">
                                <NumberInput id="form-role-name" type="Rate" scale={2} className='uk-input' placeholder="___0,00" value={this.state.corpClientCommi} onChange={this.handleUpdateCorpClientCommi} />
                                </div>
                            </div>
                        </div>
                    }
                    {isTrader &&
                        <div className="uk-width-1-4 uk-form-stacked" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name">Private Client Commission</label>
                                <div className="uk-form-controls">
                                <NumberInput id="form-role-name" type="Rate" scale={2} className='uk-input' placeholder="___0,00" value={this.state.privClientCommi} onChange={this.handleUpdatePrivClientCommi} />
                                </div>
                            </div>
                        </div>
                    }
                    {isTrader &&
                        <div className="uk-width-1-4 uk-form-stacked" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name">Deal Limit</label>
                                <div className="uk-form-controls">
                                    <NumberInput id="form-role-name" type="Currency" scale={getScaleByCurrency(this.state.baseCurrency)} className={this.formatValidInput(this.state.validations.dealLimit)} /*placeholder={getPlaceHolder(getScaleByCurrency(this.state.baseCurrency))}*/ value={this.state.dealLimit} onChange={this.handleUpdateDealLimit} />
                                </div>
                            </div>
                        </div>
                    }
                    {isTrader &&
                        <div className="uk-width-1-4 uk-form-stacked uk-margin" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name"><input className="uk-checkbox" type="checkbox" checked={this.state.reqApproval} onChange={this.handleUpdateReqApproval} />
                                Require Approval</label>
                            </div>
                        </div>
                    }
                    {isTrader &&
                        <div className="uk-width-1-4 uk-form-stacked uk-margin" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name"><input className="uk-checkbox" type="checkbox" checked={this.state.allPrevDays} onChange={this.handleUpdateAllPrevDays} />
                                Allow Previous Day Void</label>
                            </div>
                        </div>
                    }
                    {isTrader &&
                        <div className="uk-width-1-4 uk-form-stacked uk-margin" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name"><input className="uk-checkbox" type="checkbox" checked={this.state.allTradeUpdate} onChange={this.handleUpdateAllTradeUpdate} />
                                Allow Trade Updates</label>
                            </div>
                        </div>
                    }
                    {isTrader &&
                        <div className="uk-width-1-4 uk-form-stacked uk-margin" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name"><input className="uk-checkbox" type="checkbox" checked={this.state.allSameDay} onChange={this.handleUpdateAllSameDay} />
                                Allow Same Day Void</label>
                            </div>
                        </div>
                    }
                    {isTrader &&
                        <div className="uk-width-1-4 uk-form-stacked uk-margin" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name"><input className="uk-checkbox" type="checkbox" checked={this.state.allSingleDeal} onChange={this.handleUpdateAllSingleDeal} />
                                Allow Single Deal Roll</label>
                            </div>
                        </div>
                    }
                    {isTrader &&
                        <div className="uk-width-1-4 uk-form-stacked uk-margin" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name"><input className="uk-checkbox" type="checkbox" checked={this.state.restCustomerAcc} onChange={this.handleUpdateRestCustomerAcc} />
                                Restrict Customer Access</label>
                            </div>
                        </div>
                    }                    
                    {/*{isTrader &&*/}
                        <div className="uk-width-1-4 uk-form-stacked uk-margin" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name"><input className="uk-checkbox" type="checkbox" checked={this.state.restBranchAcc} onChange={this.handleUpdateRestBranchAcc} />
                                Restrict Branch Access</label>
                            </div>
                        </div>
                    {/*}*/}
                                        
                    <div className="uk-width-1-4 uk-form-stacked uk-margin" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name"><input className="uk-checkbox" type="checkbox" checked={this.state.commissionOver} onChange={this.handleUpdateCommissionOver} /> Commission Override</label>
                        </div>
                    </div>

                    <div className="uk-width-1-4 uk-form-stacked uk-margin" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name"><input className="uk-checkbox" type="checkbox" checked={this.state.isReferral} onChange={this.handleUpdateIsReferral} />
                                Allow Referrals</label>
                            </div>
                        </div>

                    <div className="uk-width-1-4 uk-form-stacked uk-margin" >
                            <div className="uk-width-1-2 uk-margin">
                                <label className="uk-form-label" htmlFor="form-additional-validation"><input className="uk-checkbox" type="checkbox" checked={this.state.additionalValidation} onChange={this.handleUpdateAdditionalValidation} />
                                SMS login validation</label>
                            </div>
                            <div className="uk-width-1-2 uk-margin">
                            <label className="uk-form-label" htmlFor="form-role-name">OTP Expiration (hours)</label>
                            <div className="uk-form-controls">
                                <NumberInput id="form-role-name" type="Integer" disabled={!this.state.additionalValidation}  className='uk-input' placeholder="0" value={this.state.otpExpirationTime} onChange={this.handleUpdateOTPExpirationTime} />
                            </div>
                        </div>
                    </div>

                    
                    
                    {/*isTrader &&
                        <div className="uk-width-1-4 uk-form-stacked" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-role-name">Branch</label>
                                <div className="uk-form-controls">
                                    <DynamicSelect className={this.formatValidSelect(this.state.validations.branchID)} id="form-role-reports" getValue={this.handleUpdateBranches} placeholder="Select your item" data={this.state.branchList} objValue={{ value: this.state.branchID, name: this.state.branchName }} />
                                </div>
                            </div>
                        </div>
                    */}

                </div>
                </div>
                </li>
                </ul>

                    {/*---------TRADERS2-----------*/}
                    <div className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleCancelClick}>Cancel</button>
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleClearClick}>Clear Fields</button>
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleSaveUserClick}>Save</button>
                    </div>
                    </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlert2.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert2.bind(this)} />
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }

}