import { Dialog, DialogContent, Tooltip, Typography, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import React, { useEffect, useState } from 'react';
import DynamicSelect from '../../shared/DynamicSelect';
import NewEventScreen from './NewEventScreen';
import { dismissEvent, snoozeEvent } from '../../../networking/NetworkingScheduler';
import AlertBox from '../../shared/AlertBox';
import CompleteEventScreen from './CompleteEventScreen';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { Colors } from '../../../constants/Colors';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(styles)((props) => 
    {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
                </IconButton>
            ) : null}
            </MuiDialogTitle>
        );
    });

const ReminderScreen =(props)=> {

  const [openNewEvent, setOpenNewEvent] = useState(false);
  const [openCompleteEvent, setOpenCompleteEvent] = useState(false);
  const [eventID, setEventID] = useState(0);
  const [reminder, setReminder] = useState ('');
  const [showAlert, setShowAlert] =useState(false);
  const [alertTitle, setAlertTitle] = useState ('');
  const [alertMessage, setAlertMessage] = useState('');

  const getDetail = (eventID)=>{
    setEventID(eventID);
    setOpenNewEvent(true);
  }

  const updateReminder = (value, eventID) =>{
    if (eventID > 0 && value != null && value !== 'select' && value.value !== undefined) {
      snoozeEvent(eventID, value.value).then(
        (response) => {
          if (Number(response.httpStatusCode) === 200) {
            setAlertTitle('Success');
            setAlertMessage('Event saved');
            setShowAlert(true);
            if(props.onReload)
            {
              props.onReload();
            }
          }
          else
          {
            setAlertTitle('Error');
            if (response.httpErrorMessage !== undefined) {
              setAlertMessage(response.httpErrorMessage);
            }
            else
            {
              setAlertMessage('Process failed. Please try again or contact the administrator.');
            }

            setShowAlert(true);
          }
        }
      )
    }
}

const onComplete = (eventID)=>{
  if(eventID > 0)
    {
      setEventID(eventID);
      setOpenCompleteEvent(true);
    }

}

  const onDismiss = (eventID)=>{

    if(eventID > 0)
    {
      dismissEvent(eventID).then(
        (response) => {
          if (Number(response.httpStatusCode) === 200) {
            setAlertTitle('Success');
            setAlertMessage('Event dismissed');
            setShowAlert(true);
            if(props.onReload)
            {
              props.onReload();
            }
          }
          else
          {
            setAlertTitle('Error');
            if (response.httpErrorMessage !== undefined) {
              setAlertMessage(response.httpErrorMessage);
            }
            else
            {
              setAlertMessage('Process failed. Please try again or contact the administrator.');
            }

            setShowAlert(true);
          }
        }
      )
    }

  }

  const getEventList = ()=>{
    let events =  props.events;
    let list = [];
    if(events && events.length > 0)
    {
      events.map(event => {
        let date = new Date(event.EventDateStart);
        let time = date.toLocaleTimeString();
        date = `${date.toDateString()} ${time}`

        let item = <div className="uk-grid" id={`event-${event.EventID}`} style={{borderStyle:'solid',borderColor:'#D3D3D3', borderWidth:'1px',marginLeft:'10px', marginTop:'0px',padding:'5px', fontSize:'12px'}}>
                  <div style={{padding:'5px'}}>
                  <EventIcon/>
                  </div>
                  <div className="uk-width-2-3 uk-form-stacked" style={{paddingLeft:'0px'}}>
                    <div>
                    <a href="#" id={`subject-${event.EventID}`} onClick={()=>getDetail(event.EventID)} >{event.Subject}</a>
                    </div>
                    <div>
                      <label className="" >{date}</label>
                    </div>
                  </div>
                  <div  className="uk-width-1-4 uk-form-stacked" style={{paddingLeft:'10px'}}>
                    <label className="uk-form-label" >Snooze for</label>
                        <div className="" >
                            <DynamicSelect getValue={(value)=>updateReminder(value, event.EventID)} listHasPlaceholder={true} data={props.reminderList} id={`reminder-${event.EventID}`}
                              objValue={{value: reminder, LookUpTableDetail: reminder}}/>
                        </div>
                        <div  className="uk-grid" style={{marginLeft:'0px'}}>
                          <div className="uk-width-1-2" style={{textAlign:'center', paddingLeft:'0px', marginTop:'5px'}}>
                            <Tooltip title="Complete">
                                <AssignmentTurnedInIcon id={`complete-${event.EventID}`} onClick={()=>onComplete(event.EventID)} style={{color:Colors.primaryColor}} />
                  
                            </Tooltip>
                          </div>
                          <div className="uk-width-1-2" style={{textAlign:'center', paddingLeft:'0px', marginTop:'5px'}}>
                            <Tooltip title="Dismiss">
                                <CancelPresentationIcon id={`dismiss-${event.EventID}`} onClick={()=>onDismiss(event.EventID)} style={{color:Colors.primaryColor}} />
                            </Tooltip>
                          </div>
                    </div>
                  </div>
                </div>
        list.push(item);
      });
      
      return list;      
    }

  }

  const cancelEditing = (reload) =>{
    setOpenNewEvent(false);
    if(reload && props.onReload)
    {
      props.onReload();
    }
  }


    return (
    <div>
        <Dialog
          fullWidth={true}
          maxWidth='sm'
          disableBackdropClick
          disableEscapeKeyDown
          open={props.open} 
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
            <DialogTitle id="attachments-dialog-title" onClose={props.onClose}>
              <NotificationsActiveIcon/>
                {` ${props.events.length} Reminder${props.events.length > 1 ? 's': ''}`}
            </DialogTitle>
          <DialogContent>
            <div className="" style={{marginBottom:'20px'}}>
              {getEventList()}
            </div>
          </DialogContent>
      </Dialog>

      <NewEventScreen openNewEvent={openNewEvent} 
              onCancel={cancelEditing} 
              eventID={eventID}
              eventTypeList={props.eventTypeList} 
              customers={props.customerList}
              eventStatusList={props.eventStatusList} eventReminderList={props.reminderList}/>

      <CompleteEventScreen openCompleteEvent={openCompleteEvent} 
              onCancel={()=> setOpenCompleteEvent(false)} 
              eventID={eventID} eventReminderList={props.reminderList}
              onReload={props.onReload}/>   

           <AlertBox open={showAlert} onClose={()=> setShowAlert(false)} title={alertTitle} message={alertMessage} type="Ok" okClick={()=> setShowAlert(false)}/>
    </div>)
}

export default ReminderScreen;