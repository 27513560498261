import { DialogActions, DialogContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import { notFilledInputClass, regularInputClass } from '../../../styles/styles';
import MaterialDialogTitle from "../../shared/Dialogs/MaterialDialogTitle";

const InputFielDialog = (props) => {

    const { open, closeDialog, title, saveValue, noAllowedValues } = props

    const [newValue, setNewValue] = useState('')
    const [disableSave, setDisableSave] = useState(true)
    const [inputClassName, setInputClassName] = useState(regularInputClass)

    const onChangeNewValue = (event) => {
        setNewValue(event.target.value)        
        let invalidValue = event.target.value === '' || (Array.isArray(noAllowedValues) && noAllowedValues.includes(event.target.value))        
        if (invalidValue) {
            setDisableSave(true)
            setInputClassName(notFilledInputClass)
        }
        else {
            setDisableSave(false)
            setInputClassName(regularInputClass)
        }
    }

    const onSaveNewValue = () => {
        saveValue(newValue)
        setNewValue('')
        setInputClassName(regularInputClass)
    }

    const onCancel = () => {
        setNewValue('')
        setInputClassName(regularInputClass)
        closeDialog()
    }

    return <Dialog
        open={open}
        onClose={closeDialog}
        fullWidth={true}
        maxWidth={"xs"}
    >
        <MaterialDialogTitle>{title}</MaterialDialogTitle>
        <DialogContent dividers>
            <div className="uk-grid">
                <div className="uk-width-1-3">New value: </div>
                <div className="uk-width-2-3">
                    <input className={inputClassName} value={newValue} onChange={onChangeNewValue} />
                </div>
            </div>
        </DialogContent>
        <DialogActions >
            <button className="uk-button uk-button-green" onClick={onSaveNewValue} disabled={disableSave}>
                Save
            </button>
            <button className="uk-button uk-button-green" onClick={onCancel}>
                Cancel
            </button>
        </DialogActions>
    </Dialog>

}

export default InputFielDialog;