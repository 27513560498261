import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // needed for dayCl
import timeGridPlugin from '@fullcalendar/timegrid'

import React, { useEffect, useState } from 'react'
import NewEventScreen from './NewEventScreen'
import { getListOfEvents } from '../../../networking/NetworkingScheduler'
import { formatDate as formatDateHelper } from '../../../helpers/FormatHelper'
import { formatDate } from '@fullcalendar/core/index.js'
import { Divider } from 'antd'
import moment from 'moment';

export function SchedulerScreen (props) {
  const weekendsVisible = useState(true)
  const [openNewEvent, setOpenNewEvent] = useState(false)
  const [selectedDateStart, setSelectedDateStart] = useState([]);
  const [selectedDateEnd, setSelectedDateEnd] = useState([]);
  const [allDayEvent, setAllDayEvent] = useState(false);
  const [eventID, setEventID] = useState(0);
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [eventList, setEventList] = useState([])
  const [todayEventList, setTodayEventList] = useState([])
  const [reloadEvents, setReloadEvents] = useState(false)

  useEffect(()=>{
    if(startDate !== '' && endDate !== '')
    {
      getListOfEvents(formatDateHelper(startDate), formatDateHelper(endDate)).then(
        (response) => {
            if (response.events !== undefined) {
              setEventList(response.events);
            }
        });
      
      let today = new Date();
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      getListOfEvents(formatDateHelper(today), formatDateHelper(tomorrow)).then(
        (response) => {
            if (response.events !== undefined) {
              setTodayEventList(response.events);
            }
        });
      
    }
  },[startDate, endDate, reloadEvents])

  function handleDateSelect(selectInfo) {
    let startDateEvent = selectInfo.startStr;
    let endDateEvent = selectInfo.endStr;

    if(selectInfo.view.type === "dayGridMonth")
{
    let FORMAT_NEW_DAYPICKER = 'YYYY/MM/DD'
    let startDate = moment(selectInfo.startStr).format(FORMAT_NEW_DAYPICKER);

    let todayDate =  new Date();
    let startHour = todayDate.getHours();
    let startMinutes = todayDate.getMinutes();

    if(startMinutes > 0 && startMinutes <=15)
    {
        startMinutes = 15;
    }
    else
    {
      if(startMinutes > 15 && startMinutes <=30)
        {
            startMinutes = 30;
        }
        else
        {
          if(startMinutes > 30 && startMinutes <=45)
            {
                startMinutes = 45;
            }
            else
            {
              startMinutes = 0;
              startHour++;
            }
        }
    }

    startDateEvent = new Date(`${startDate},${startHour}:${startMinutes}:00`);
    endDateEvent = new Date(`${startDate},${startHour}:${startMinutes}:00`);
    endDateEvent.setTime(endDateEvent.getTime()+ 60 * 60 * 1000);
  }
  
    setEventID(0);
    setSelectedDateStart(startDateEvent);
    setSelectedDateEnd(endDateEvent);
    //setAllDayEvent(selectInfo.allDay);
    setOpenNewEvent(true);    
  }

  function renderEventContent(eventInfo) {
    return (
      <>
        <b style={{ marginRight: 2 }}>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }

  function handleEventClick(clickInfo) {
    setEventID(clickInfo.event.id);
    setOpenNewEvent(true);
  }

  const onCancelNewEvent = (reload) => {
    setOpenNewEvent(false)
    if(reload)
    {
      setReloadEvents(!reloadEvents);
    }
  }

  return (
    <div className="demo-app">
      <Sidebar
        currentEvents={todayEventList}
      />
      <Divider/>
      <div className="demo-app-main">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          initialView="timeGridDay"
          editable={true}
          selectable={true}
          selectMirror={true}
          buttonIcons={false}
          dayMaxEvents={true}
          weekends={weekendsVisible}
          events={eventList} // alternatively, use the `events` setting to fetch from a feed
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          datesSet={(arg) => {
            setStartDate(arg.start)
            setEndDate(arg.end) 
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: false,
            hour12: false
          }}
          //eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
            
        />
      </div>

      
              <NewEventScreen openNewEvent={openNewEvent} 
              onCancel={onCancelNewEvent} eventTypeList={props.eventTypeList} 
              eventID={eventID}
              customers={props.customers}
              eventStatusList={props.eventStatusList} eventReminderList={props.reminderList}
              dateStartEvent={selectedDateStart} dateEndEvent={selectedDateEnd} allDay={allDayEvent}/>
          

    </div>
  )
}

function Sidebar({ currentEvents }) {
  return (
    <div className="demo-app-sidebar">
      
      <div className="demo-app-sidebar-section">
        <h2>Today Events ({currentEvents.length})</h2>
        <ul>
          {currentEvents.map((event) => (
            <SidebarEvent key={event.id} event={event} />
          ))}
        </ul>
      </div>
    </div>
  )
}

function SidebarEvent({ event }) {
  return (
    <li key={event.id} style={{ marginBottom: 5 }}>
      <b style={{ marginRight: 4 }}>
        {formatDate(event.start, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </b>
      <i>
        {event.title}: {event.description}
      </i>
    </li>
  )
}
export default SchedulerScreen
