import React from 'react';
import { addBeneficiary, getBeneficiariesByID, validateBeneficiaryName } from '../../../../networking/NetworkingBeneficiaries';
import { searchInGlobalWatchLists } from '../../../../networking/NetworkingGlobalWatchListSearch';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import AddressForm from '../../addresses/AddressForm';
import AddBeneficiaryInformationForm from './AddBeneficiaryInformationForm';
import { getParametersByName } from '../../../../networking/NetworkingGlobalSystemParameters';
import { GlobalSystemParametersNames } from '../../../../constants/GlobalSystemParametersNames';
import { getCustomerByID, IsRestrictedCountry, searchCustomerByIdNameMin } from '../../../../networking/NetworkingCustomers';
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
import { getMainAddressByPersonID } from '../../../../networking/NetworkingAddresses';

var style = getStyle();

export default class AddBeneficiaryScreen extends React.Component {
    state = {
        beneficiaryID       : 0,
        updateToken         : 0,
        isValidated         : true,
        isValidatedAddress  : true,
        beneInfoErrors      : [],
        addressErrors       : [],
        notFirstLoad        : true,
        notFirstLoadBene    : true,
        showAlert           : false,
        showAlert2          : false,
        showAlertError      : false,
        showAlertSaveSuspicious: false,
        alertTitle          : '',
        alertMessage        : '',
        loading             : false,
        updateBeneficiary   : false,
        pressedButton       : false,
        saveProperties      : false,
        saveTasks           : false,
        selectBene          : {},
        viewHistory         : false,
        isClearAdd          : false,
        isClearBene         : false,        
        activeTab: "beneficiary-information",
        //OBJ FILTERS
        objBeneInfo         : {},
        objAddress          : {},
        objBankAccount      : {},

        objJson             : [],
        //Attachments
        showAttachmentsDialog : false,
        //Compliance
        beneficiaryHandlingMethodBackend: '',

        isSuspicious: false, 
        showConfirmDuplicateName:false,

        allowCreateBeneficiaryFromCustomer: false,
        customerList:[],
        CustomerID:'',
        CustomerIDName:'',
        SelectedCustomerPersonID:'',
        customerInfoUsed : false,
        showAlertErrorAddBene: false
    }

    componentDidMount() {
        getParametersByName(GlobalSystemParametersNames.BeneficiaryHandlingMethodBackend).then(
            (response) => {
                if (response != null && response.parameters != null && response.parameters.length > 0) {                    
                    this.setState({ beneficiaryHandlingMethodBackend: response.parameters[0].Value });

                }
            }
        ) 
        let allowCreateBeneficiaryFromCustomer = localStorage.getItem(GlobalSystemParametersNames.CreateBeneficiaryFromCustomer);
        this.setState({allowCreateBeneficiaryFromCustomer: allowCreateBeneficiaryFromCustomer});
    }

    changePage(value) {
    }

    updatePage(beneID, updateToken, updateTokenAddress, newBene) {
        if(newBene){
            this.setState({showAlert: newBene});
        }
        var objBeneInfo = this.state.objBeneInfo
        var objAdd = this.state.objAddress
        getBeneficiariesByID(beneID, 1).then(
            (json) => {
                console.log(json.beneficiary);
                ////debugger
                objBeneInfo.beneficiaryID = beneID
                objBeneInfo.updateToken = updateToken
                objAdd.AddressID = json.beneficiary[0].Address[0].AddressID
                objAdd.UpdateToken = updateTokenAddress
                this.setState({ objBeneInfo: objBeneInfo, objAddress: objAdd, objJson:json.beneficiary })
            }
        );
    }

    handleBackLink() {
        this.props.updateFunctionOrigin();
        this.props.backLink();
    }

    handleClearFieldsClick() {
        this.setState({
            notFirstLoad: true,
            notFirstLoadBene: true,
            showAlert: false,
            showAlert2: false,
            showAlert2view: false,
            showAlertError: false,
            alertTitle: '',
            alertMessage: '',
            updateBeneficiary: false,
            pressedButton: false,
            saveProperties: false,
            saveTasks: false,
            selectBene: null,
            viewHistory: false,
            objBeneInfo: {},
            objAddress: null,
            objBankAccount: {}
        }, () => this.props.isNewClearBene())
    }

    returnButtonState() {
        this.setState({ pressedButton: false });
    }

    handleButtonSaveClick = async () => {
        if (this.state.isValidated && this.state.isValidatedAddress) {
            if (this.state.objBeneInfo.bypassValidation) {
                this.validateBeneficiaryNameBeforeSave(false);
            } else {
                this.setState({ loading: true });
                if(this.state.objBeneInfo.status=='Authorized'){
                    const textToSearch = this.state.objBeneInfo.customerType === "Personal" ? `${this.state.objBeneInfo.firstName} ${this.state.objBeneInfo.lastName}` : this.state.objBeneInfo.companyName;
                    const globalListsResponse = await searchInGlobalWatchLists(textToSearch, 'Beneficiary', 0);
                    this.setState({ loading: false });
                    if (globalListsResponse != null && globalListsResponse.MatchedWords.length === 0 && globalListsResponse.SourceNames.length === 0) {
                        this.validateBeneficiaryNameBeforeSave(false);
                    } else {
                        this.setState({ showAlertSaveSuspicious: true, alertTitle: 'Warning', alertMessage: 'Found Beneficiary in Watch Lists. The Beneficiary will be saved with status "Suspicious"' });
                    }
                }else{
                    this.validateBeneficiaryNameBeforeSave(false);
                }
            }
        } else {
            if (this.state.isValidated && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoad: false
                });
            } else if (!this.state.isValidated && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadBene: false,
                    notFirstLoad: false
                });
            } else {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadBene: false
                });
            }
        }
    }

    showAlertAfterPropertiesValidation = () => {
        this.setState({ showAlert: true});
    }

    
    validateBeneficiaryNameBeforeSave = async(isSuspicious) =>{

        var model = {
            BeneficiaryId: 0,
            CustomerId: this.props.customerID,
            BeneficiaryType: this.state.objBeneInfo.customerType,
            Name: this.state.objBeneInfo.companyName,
            FirstName: this.state.objBeneInfo.firstName,
            LastName: this.state.objBeneInfo.lastName
        }
        let validNameResponse = await validateBeneficiaryName(model);

        if(validNameResponse.Valid)
        {
            this.saveAll(isSuspicious);
        }
        else{
            this.setState({isSuspicious: isSuspicious, showConfirmDuplicateName: true});
        }

    }

    yesConfirmDuplicate = () =>{
        this.setState({showConfirmDuplicateName: false});
        this.saveAll(this.state.isSuspicious, this.state.saveAsPending);
    }

    noConfirmDuplicate = () =>{
        this.setState({showConfirmDuplicateName: false});
    }

    saveAll(isSuspicious) {
        let Status = (this.state.beneficiaryHandlingMethodBackend === 'Save as Pending' ? "Pending activation" : this.state.objBeneInfo.status);
        let UserID = localStorage.getItem('UserID');
        var objFilter = {
            BeneficiaryId: 0,
            CustomerId: this.props.customerID,
            StatusBeneficiary: isSuspicious ? 'Suspicious' : Status,
            BeneficiaryType: this.state.objBeneInfo.customerType,
            BypassValidation: this.state.objBeneInfo.bypassValidation,
            ContactEmail: this.state.objBeneInfo.contactEmail,
            ContactPhone: this.state.objBeneInfo.contactPhone,
            EmailIndicator: this.state.objBeneInfo.emailIndicator,
            DetailsOfPayment: this.state.objBeneInfo.detailsPayments,
            ExpiryByPassDate: this.state.objBeneInfo.expiryByPassDate,
            IndustrySector: this.state.objBeneInfo.industrySector,
            ReferencesOfPayment: this.state.objBeneInfo.paymentReference,
            ReceiveLimit: this.state.objBeneInfo.limit,
            PreferredMethodOfPayment: this.state.objBeneInfo.preferredPaymentIns,
            PreferredDeliveryMethod: this.state.objBeneInfo.preferredDeliveryMet,
            PaymentFlow: this.state.objBeneInfo.paymentFlow,
            Salutation: this.state.objBeneInfo.salutation!==undefined?this.state.objBeneInfo.salutation:'',
            Name: this.state.objBeneInfo.companyName,
            ContactName: this.state.objBeneInfo.contactName,
            FirstName: this.state.objBeneInfo.firstName,
            LastName: this.state.objBeneInfo.lastName,
            ShortName: this.state.objBeneInfo.shortName,
            Email: '',
            Phone: '',
            PhoneExt: '',
            OfficeFax: '',
            MobilePhone: '',
            UpdateToken: this.state.objBeneInfo.updateToken,
            UserId: UserID,
            PersonID: this.state.customerInfoUsed? this.state.objBeneInfo.PersonID:null,
            Address: this.state.objAddress!==null?this.state.objAddress:{},
            BankAccounts: null
        }
        if (this.state.selectBene != null) {
            if (this.state.selectBene.Address != null) {
                objFilter.Address.AddressID = this.state.selectBene.Address[0] != null ? this.state.selectBene.Address[0].AddressID : (this.state.selectBene.Address?.AddressID ?? 0)
            } else {
                objFilter.Address.AddressId = 0
            }
        } else {
            objFilter.Address.AddressId = 0
        }
        objFilter.Address.UpdateToken = this.state.objAddress != null ? this.state.objAddress.UpdateToken : undefined;
        objFilter.Address.UpdatedBy = UserID;
        objFilter.Address.PersonID = this.props.customerPersonID;
        if(this.state.customerInfoUsed)
        {
            objFilter.Address = null;
        }
        this.setState({ selectBene: objFilter });
        if (this.state.objBeneInfo.isEmailValid && this.state.objBeneInfo.contactEmail !== '' || !this.state.objBeneInfo.isEmailValid && this.state.objBeneInfo.contactEmail === '') {
            this.setState({ loading: true });

            let isRestrictedCountry=false;
            let obj1={
                customerID: objFilter.CustomerId,
                countryID: this.state.customerInfoUsed ? this.state.objAddress.CountryId: objFilter.Address.CountryId
            }
            IsRestrictedCountry(obj1).then((json) => {
                isRestrictedCountry=json.response;                                
            },
            addBeneficiary(objFilter).then(
                (json) => {
                    if (json.httpStatusCode !== 200 && json.httpStatusCode !== 409) {
                        let message = 'An error occurred while trying to save the beneficiary. Please try again and complete the required fields(*).';
                            if(json.Message && json.Message !== '')
                            {
                                message = json.Message;
                            }
                        this.setState({ showAlertError: true, alertTitle: 'Error', alertMessage: message, loading: false });
                    } else if (json.httpStatusCode !== undefined && json.httpStatusCode === 409){
                        this.setState({ showAlertError: true, alertTitle: 'Error' , alertMessage: json.httpErrorMessage, loading: false });
                    } else {
                        this.state.selectBene.BeneficiaryId = json.beneficiaryId;
                        this.state.selectBene.UpdateToken = json.updateToken;
                        
                        let beneTitle = "Beneficiary - " + objFilter.Name + " - " + json.beneficiaryId;
                        this.props.beneTitle(beneTitle)
                        if(isRestrictedCountry){
                            this.setState({ saveProperties: true, saveTasks: true, showAlert: true, alertTitle: 'Success', 
                            alertMessage: 'Beneficiary successfully saved. No payments can be sent to this beneficiary while country is blocked.', loading: false }, () => this.updatePage(json.beneficiaryId, json.updateToken, json.updateTokenAddress, true))    
                        }
                        else{
                            this.setState({ saveProperties: true, saveTasks: true, showAlert: true, alertTitle: 'Success', alertMessage: 'Beneficiary successfully saved.', loading: false }, () => this.updatePage(json.beneficiaryId, json.updateToken, json.updateTokenAddress, true))
                        }                        
                    }
                }
            )        
        )
        this.setState({ pressedButton: true });
            
        } else {
            this.setState({ showAlertError: true, alertMessage: 'Your email is incorrect. Please try again.', alertTitle: 'Error', notFirstLoad: false, notFirstLoadBene: false, loading: false });
        }
    }
    isValidated = (validator) =>{
        if (validator.valid) {
            this.setState({
                isValidated: true,
                notFirstLoadBene: true,
                beneInfoErrors: []
            });
        } else {
            this.setState({
                isValidated: false,
                beneInfoErrors: { tab: "Beneficiary Information", errors: validator.errors }
            });
        }
    }
    isValidatedAddress = (validator) =>{
        if (validator.valid) {
            this.setState({
                isValidatedAddress: true,
                notFirstLoad: true,
                addressErrors: []
            });
        } else {
            this.setState({
                isValidatedAddress: false,
                addressErrors: { tab: "Address", errors: validator.errors }
            });
        }
    }

    handleViewHistoryClick() {
        this.setState({ viewHistory: true });
    }

    handleReturnToBeneficiary() {
        this.setState({ viewHistory: false });
    }

    handleAttachmentsClick() {
        this.setState({
            showAttachmentsDialog: true
        });  
    }

    handleResetAnswersClick() {

    }

    handleSendOAEmailClick() {

    }

    closeAlert() {        
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' }, () => this.props.selectedBene(this.state.objJson[0]));
    }

    closeAlertError() {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '', notFirstLoadBene: true, notFirstLoad: true });
    }
    formatVisibilityNew(beneID) {
        if (beneID === '' || beneID === null) { return "visible"; }
        else { return "hidden"; }
    }
    formatVisibilityUpdate(beneID) {
        if (beneID === '' || beneID === null) { return "hidden"; }
        else { return "visible"; }
    }

    //OBJ FILTERS
    objFilterBeneInfo(objFilter) {
        this.setState({ objBeneInfo: objFilter });
    }

    objFilterBeneAddress(objFilterA) {
        this.setState({ objAddress: objFilterA });
    }

    objFilterBeneAddProperties(value) {
        this.setState({ saveProperties: value });
    }

    objFilterBeneAddTasks(value) {
        this.setState({ saveTasks: value });
    }

    onClickTab = (tabName) => {
        this.setState({ activeTab: tabName });
    }

    formatVisibilityButton() {
        if (this.state.activeTab==="account-information") { return "hidden"; }
        else { return "visible"; }
    }
    
    onCloseAttachmentsDialog = ()=>
    {
        this.setState({
            showAttachmentsDialog: false,
        })
    }

    closeAlertSaveSuspicious = async () => {
        this.setState({ showAlertSaveSuspicious: false });
        await this.validateBeneficiaryNameBeforeSave(true);
    }

    searchCustomer = (query)=> {
        searchCustomerByIdNameMin(query).then(
            (response) => {
                if (response.customers !== undefined) {
                    for (var i = 0; i < response.customers.length; i++) {
                        response.customers[i].value = response.customers[i].CustomerID;
                        response.customers[i].name = response.customers[i].CustomerIDName;
                    }
                    this.setState({customerList: response.customers });
                }
            }
        )
    }

    handleUpdateCustomerIDName = (value)=> {
        if (value.value !== undefined) {
            let customerIDName = value.name !== 'select' ? value.name : '';
            let customerID = value.value !== 'select' ? value.value : '';
            this.setState({CustomerIDName: customerIDName , CustomerID: customerID, SelectedCustomerPersonID: value.PersonID });
        }
    }

    handleUseInformationClick = async() =>{
        if(this.state.CustomerID !== '0' && this.state.CustomerID !== '' && this.state.SelectedCustomerPersonID !== '')
        {
            if(this.props.customerID === this.state.CustomerID)
            {
                this.setState({alertTitle:'Error', alertMessage:"This customer can't be used.", showAlertErrorAddBene: true});
                return true;
            }

            if (this.props.beneficiaries) {
                let exists = this.props.beneficiaries.find(b => b.BeneficiaryPersonID === this.state.SelectedCustomerPersonID);
                if (exists) {
                    this.setState({alertTitle:'Error', alertMessage:"This customer has already been used.", showAlertErrorAddBene: true});
                    return true;
                }
            }

            let customerInfo = await getCustomerByID(this.state.CustomerID);
            if(customerInfo && customerInfo.CustomerID > 0)
            {
                let objFilter = {
                    beneficiaryId: 0,
                    status:'Pending activation',
                    customerType: customerInfo.CustomerType === 'Individual'?'Personal':customerInfo.CustomerType,
                    bypassValidation: customerInfo.BypassValidation,
                    contactEmail: customerInfo.Email,
                    contactPhone: customerInfo.Phone,
                    emailIndicator: '',//this.state.objBeneInfo.emailIndicator,
                    detailsPayments: '',//this.state.objBeneInfo.detailsPayments,
                    expiryByPassDate: customerInfo.ExpiryByPassDate,
                    industrySector: customerInfo.IndustrySector,
                    paymentReference: '',//this.state.objBeneInfo.paymentReference,
                    limit: 0,//this.state.objBeneInfo.limit,
                    preferredPaymentIns:  '',//this.state.objBeneInfo.preferredPaymentIns,
                    preferredDeliveryMet:  '',//this.state.objBeneInfo.preferredDeliveryMet,
                    paymentFlow:  '',//this.state.objBeneInfo.paymentFlow,
                    salutation: customerInfo.Salutation,
                    companyName: customerInfo.Name,
                    contactName: customerInfo.ContactName,
                    firstName: customerInfo.FirstName,
                    lastName: customerInfo.LastName,
                    shortName: customerInfo.ShortName,
                    PersonID: customerInfo.PersonID,
                    UpdateToken: '',        
                    Address: null,
                    BankAccounts: null,
                    isEmailValid: true
                }

                let address = await getMainAddressByPersonID(customerInfo.PersonID);
                let selectedAddress = {
                    AddressId: address.AddressId,
                    AddressType: address.AddressType,
                    AddressValue: address.AddressValue,
                    Address2: address.Address2,
                    City: address.City,
                    ZipCode: address.ZipCode,
                    StateId: address.StateId,
                    StateName: address.StateName,
                    CountryId: address.CountryId ? address.CountryId.trim():'',
                    CountryName: address.CountryName,
                    OtherState: address.OtherState,
                    DefaultAddress: true,
                    SinceWhen: address.SinceWhen,
                    Status: address.Status,
                    District: address.District,
                    POBox: address.POBox,
                    UpdateToken: address.UpdateToken
                }

                this.setState({customerInfoUsed: true,objBeneInfo: objFilter, objAddress: selectedAddress});
            }
        }
    }

    handleClearInformationClick = () =>{
        if(this.state.CustomerID !== '0' && this.state.CustomerID !== '')
        {
            if(this.state.CustomerID !== '0' && this.state.CustomerID !== '')
                {

                    let address= {
                        Address2: '',
                        AddressId: '',
                        AddressType: '',
                        AddressValue: '',
                        City: '',
                        CountryId: '',
                        CountryName: '',
                        CreatedBy: '',
                        CreatedOn: '',
                        DefaultAddress: false,
                        District: '',
                        OtherState: '',
                        PersonID: '',
                        POBox: '',
                        SinceWhen: '',
                        StateId: 0,
                        StateName: '',
                        Status: '',
                        UpdateToken: '',
                        UpdatedOn: '',
                        ZipCode: ''
                    }
                        let objFilter = {
                            beneficiaryId: 0,
                            status:'',
                            customerType: '',
                            bypassValidation: false,
                            contactEmail: '',
                            contactPhone: '',
                            emailIndicator: '',//this.state.objBeneInfo.emailIndicator,
                            detailsPayments: '',//this.state.objBeneInfo.detailsPayments,
                            expiryByPassDate: '',
                            industrySector: '',
                            paymentReference: '',//this.state.objBeneInfo.paymentReference,
                            limit: 0,//this.state.objBeneInfo.limit,
                            preferredPaymentIns:  '',//this.state.objBeneInfo.preferredPaymentIns,
                            preferredDeliveryMet:  '',//this.state.objBeneInfo.preferredDeliveryMet,
                            paymentFlow:  '',//this.state.objBeneInfo.paymentFlow,
                            salutation: '',
                            companyName:'',
                            contactName: '',
                            firstName: '',
                            lastName: '',
                            shortName: '',
                            PersonID: null,
                            UpdateToken: '',        
                            Address: null,
                            BankAccounts: null
                        }    
        
                        this.setState({customerInfoUsed: false,objBeneInfo: objFilter, objAddress: address, SelectedCustomerPersonID: '', CustomerID:'', CustomerIDName:''});
                    
                }
        }
    }

    closeAlertErrorAddBene = ()=>{
        this.setState({showAlertErrorAddBene: false, alertTitle:'', alertMessage:''});
    }

    //beneID={this.props.beneID} selectedBene={this.state.beneList} type={this.state.type}
    render() {
        return (
            <div>
                <div>
                    {/*<h3 className="uk-heading-divider component-title" >
                        <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                            <i className="fa fa-arrow-left back-arrow"></i>
                        </button>
                        New Beneficiary
                    </h3>*/}
                    <ul uk-tab="">
                        <li>
                            <a href="#" id="beneficiary-information" onClick={() => this.onClickTab('beneficiary-information')}>Beneficiary Information</a>
                        </li>
                        <li>
                            <a href="#" id="address-information" onClick={() => this.onClickTab('address-information')}>Address</a>
                        </li>
                    </ul>
                    <ul className="uk-switcher uk-margin">
                        <li>
                            <>
                            <div className="uk-grid" style={{marginBottom:'10px'}}>
                            <div className="uk-width-1-4 uk-form-stacked">
                                <label className="uk-form-label" htmlFor="form-deal-CustomerIDName">Customer</label>
                                <div className="" style={style.inputStyle}>
                                    <DynamicSearchSelect id="CustomerIDName" placeholder="Customer" searchFunction={this.searchCustomer} getValue={this.handleUpdateCustomerIDName} data={this.state.customerList} objValue={{ value: this.state.CustomerID, name: this.state.CustomerIDName }} disabled={this.state.customerInfoUsed} />
                                </div>
                            </div>
                            <div className="uk-width-1-5 uk-form-stacked" style={{marginTop:'15px'}}>
                                <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleUseInformationClick} type="button" disabled={this.state.customerInfoUsed}>Use Information</button>
                            </div>
                            <div className="uk-width-1-5 uk-form-stacked" style={{marginTop:'15px'}}>
                                <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearInformationClick} type="button">Clear</button>
                            </div>
                            </div>
                            
                            <AddBeneficiaryInformationForm objBene={this.state.objBeneInfo} isValid={this.isValidated.bind(this)} updateFunction={this.updatePage.bind(this)} customerName={this.props.customerName} objFilterBeneInfo={this.objFilterBeneInfo.bind(this)} notFirstLoad={this.state.notFirstLoadBene} customerInfoUsed={this.state.customerInfoUsed} />
                            </>
                        </li>
                        <li>
                            <AddressForm clearFields = {this.props.isClearAdd} selectedAddress={this.state.objAddress} getAddressInfo={this.objFilterBeneAddress.bind(this)} isValid={this.isValidatedAddress.bind(this)} validations={this.state.notFirstLoad} isCustomer = {false} customerInfoUsed={this.state.customerInfoUsed} />
                            {/*<BeneficiaryAddressForm updateFunction={this.updatePage.bind(this)} selectedBeneAddress={this.props.selectedBene.Address}  beneID={this.props.beneID} objFilterBeneAddress={this.objFilterBeneAddress.bind(this)}/>*/}
                        </li>
                    </ul>
                </div>
                
                <div style={{visibility:this.formatVisibilityButton()}}>
                    {/* <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearFieldsClick.bind(this)} type="button">Clear Fields</button> */}
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick.bind(this)} type="button">Save</button>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleBackLink.bind(this)} type="button">Exit</button>
                </div>
                <AlertBox 
                    open={this.state.showAlert} 
                    onClose={this.closeAlert.bind(this)} 
                    title={this.state.alertTitle} 
                    message={this.state.alertMessage} 
                    type="Ok" 
                    okClick={this.closeAlert.bind(this)}
                />
                <AlertBox
                    open={this.state.showAlertSaveSuspicious}
                    onClose={this.closeAlertSaveSuspicious}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage} type="Ok" okClick={this.closeAlertSaveSuspicious} />
                <AlertBox 
                    open={this.state.showAlertError} 
                    onClose={this.closeAlertError.bind(this)} 
                    title={this.state.alertTitle} 
                    message={this.state.alertMessage} 
                    messageBoldList={[this.state.beneInfoErrors, this.state.addressErrors]} 
                    type="Ok" 
                    okClick={this.closeAlertError.bind(this)}
                />

                <AlertBox
                        open={this.state.showConfirmDuplicateName}
                        onClose={this.noConfirmDuplicate}
                        title={'Warning'}
                        message={'Beneficiary Name already exists. Do you want to save?'}
                        type="Yes/No"
                        yesClick={this.yesConfirmDuplicate}
                        noClick={this.noConfirmDuplicate}
                    />
                <AlertBox 
                    open={this.state.showAlertErrorAddBene} 
                    onClose={this.closeAlertErrorAddBene} 
                    title={this.state.alertTitle} 
                    message={this.state.alertMessage} 
                    type="Ok" 
                    okClick={this.closeAlertErrorAddBene}
                />
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}