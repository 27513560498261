import React from 'react';
import AppContext from '../../../AppContext';
import { getCountryName } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { deleteBank, getBank, getBanks } from '../../../networking/NetworkingBanks';
import AlertBox from '../../shared/AlertBox';
import GridDynamicTable from '../../shared/GridDynamicTable';
import NewEditableGrid from '../../shared/NewEditableGrid';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import BanksForm from './BanksForm';

let globalFunctions = {};

export default class BanksScreen extends React.Component {
    static contextType = AppContext;
    
    state = {
        banks: [],
        allBanks: [],
        originalBanks: [],
        newSearch: false,
        totalPages: 0,
        currentPage: 1,
        selectedBank: {},
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteBank: {},
        loading: false,
        errorMessage: '',
        showErrorAlert: false,
    }

    closeDeleteAlert = () =>{
        this.setState({showDeleteAlert: false, readyToDeleteBank: {}});
    }

    openDeleteAlert = () => {
        this.setState({showDeleteAlert: true});
    }

    closeErrorAlert = () => {
        this.setState({showErrorAlert: false});
    }

    yesDeleteAlert = () => {
        deleteBank(this.state.readyToDeleteBank['Key §H']).then(
            (value)=>{
                console.log(value);
                if(value.httpStatusCode !== 200){
                    this.setState({showDeleteAlert: false, showErrorAlert: true, errorMessage: value.Message});
                } else {
                    this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true});
                    this.updatePage();
                }
        });
    }

    okSuccessAlert = () => {
        this.setState({showSuccessDeleteAlert: false});
    }

    componentDidMount(){
        globalFunctions = this.context;
        //userHasActions(['AddBeneficiaryInfo']);
        //this.setState({loading: true});
        globalFunctions.startLoading();
        /*getBanks(1,getDefaultPagingNumberOfItems()).then(
            (value) => {
                console.table(value.banks);
                var banks = this.transformBankList(value.banks);
                this.setState({banks: banks, originalBanks: value.banks});
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                this.setState({totalPages: pages, newSearch:true, loading:false});
            }
        );*/

        var listAllBanks = []
        
        getBanks(0,0).then(
            (value) => {
                console.log("-------------------------------------------------------------------")
                console.log(JSON.stringify(value))
                console.log(value)
                listAllBanks = value.banks//this.transformBankList(value.banks);

                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());

                this.setState({
                    allBanks: listAllBanks,
                    totalPages: pages,
                });
                globalFunctions.finishLoading();
            }
        );
    }

    handleRowClick = (obj) =>{
        console.group("Bank handleRowClick")
        console.log(obj);
        console.groupEnd();
        getBank(obj['Key §H']).then(
            (value)=>{
                console.table(value);
                this.setState({selectedBank: value});
                this.props.getSelectedBank(value);
            }
        );
        /*this.setState({
            updateCustomer: true,
            customerID: obj["Customer ID"]
        });*/
    }

    handleBranchesClick = (obj) => {
        getBank(obj['Key §H']).then(
            (value)=>{
                console.table(value);
                this.setState({selectedBank: value});
                this.props.changeToBranch(value);
            }
        );
    }

    handleDeleteClick = (obj) => {
        console.group("Bank handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteBank: obj});
        /*deleteBank(obj['Bank ID']).then(
            (value)=>{
                this.updatePage();
        });*/
    }

    changePage = (value) => {
        this.setState({currentPage: value});
        /*getBanks(value,getDefaultPagingNumberOfItems()).then(
            (json) => {
                var banks = this.transformBankList(json.banks);
                this.setState({banks: banks, originalBanks: json.banks});
                var totalCount = json.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                this.setState({totalPages: pages, newSearch:false});
            }
        );*/
    }

    transformBankList (list) {
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'Bank ID': list[i]['Bank ID'],
            'CustomerID': list[i]['Customer ID'],
            'Bank': list[i]['Bank Name'], 
            'Country': getCountryName(list[i]['Country ID']), 
            'Risk Score': list[i]['RiskScore'],
            'Status': list[i]['Status']});
        }
        return helper;
    }

    updatePage = () => {
        var listAllBanks = []
        
        getBanks(0,0).then(
            (value) => {
                console.log("-------------------------------------------------------------------")
                console.log(JSON.stringify(value))
                listAllBanks = value.banks//this.transformBankList(value.banks);

                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());

                this.setState({
                    allBanks: listAllBanks,
                    totalPages: pages,
                });
                globalFunctions.finishLoading();
            }
        );
    }

    getAllBanks = async () => {
        var json = await getBanks(0,0);
        var banks = json.banks//this.transformBankList(json.banks);
        return banks;
    }
    
    render(){
        return(
            <div>                
                <BanksForm updateFunction={this.updatePage} selectedBank={this.state.selectedBank}/>
                <NewEditableGrid
                    data={this.state.allBanks}
                    dynamicActions={[{name: 'Branches', function: this.handleBranchesClick, isIcon: true, icon: <SearchOutlined />}]}
                    enableClick={true} 
                    clickFunction={this.handleRowClick}
                    useDeleteButton={true} 
                    deleteFunction={this.handleDeleteClick}
                    useExportButton = {true}
                    useIcons={true}
                    tableName = "banks" />
                {/* <GridDynamicTable
                    id="banks-table"
                    hiddenHeaders={['Bank ID','CustomerID']} 
                    data={this.state.allBanks} 
                    enableClick={true} 
                    clickFunction={this.handleRowClick} 
                    newSearch={this.state.newSearch} 
                    useDeleteButton={true} 
                    deleteFunction={this.handleDeleteClick} 
                    useFilter={true} 
                    filterFunction={this.getAllBanks}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber = {this.state.currentPage}
                    numberPerPage = {getDefaultPagingNumberOfItems()}
                    enableSortByHeader = {true}
                    useExportButton = {true}
                    tableName = "banks"
                    dynamicActions = {[{name: 'Branches', function: this.handleBranchesClick, isIcon: true}]}
                /> */}
                {/*<Paginator changePage={this.changePage} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch}/>*/}
                <AlertBox id="delete-bank" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete this bank?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert}/>
                <AlertBox id="confirmation-delete-bank" open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Bank successfully removed." message="" type="Ok" okClick={this.okSuccessAlert}/>
                <AlertBox id="error-message-bank" open={this.state.showErrorAlert} onClose={this.closeErrorAlert} title="Error" message={this.state.errorMessage} type="Ok" okClick={this.closeErrorAlert}/>
            </div>
        );
    }
}