import React from 'react';
import { regularInputClass } from '../../styles/styles';
import '../../css/dropDownSearch.css';

//USAGE
//data must be: {value: "x", name: "X"}
/*
<DynamicSelectWithSearch key={'ds' + i}
                    id={'ds' + i}
                    data={this.state.nostroAccounts}
                    placeholder='Select an Account...'
                    className={this.formatValidSelect(x.nostroValid !== undefined ? x.nostroValid : true)}
                    getValue={(obj) => {
                        if (obj && obj.value !== undefined) {
                            x.NostroAccount = obj.value
                        }
                    }}
                    objValue={{ value: x.NostroAccount }}
                />
*/


export default class DynamicSelectWithSearch extends React.Component {
    state = {
        value: 'select',
        data: [],
        filteredData:[],
        searchText:'',
        selectedText:'',
        open: false,
        controlWidth:0,
        placeholder:'',
        hasFocus: false
    }
    ref = React.createRef();

    componentDidMount(){
        
     const { current } = this.ref;
        this.setState({controlWidth: current.offsetWidth});
        
        document.addEventListener("mousedown", this.handleClickOutside.bind(this));

        let propData = this.props.data;
        this.setState({data: propData, filteredData:propData});
        let propValue = 'select';
        let propText = '';

        if(this.props.objValue !== undefined && this.props.objValue.value !== ''){
            let item = propData.find(d => d.value === this.props.objValue.value);
            if(item && item.name)
            {
                propText = item.name;
            }
            propValue = this.props.objValue.value;
        }
        this.setState({value: propValue, selectedText: propText}, ()=>{
            this.props.getValue(this.state.value);
        });

        let placeholder = '';
        if (this.props.listHasPlaceholder){
            if(this.props.data.length>0){
                placeholder = this.props.data[0].name;
            } else {
                placeholder = 'Loading...';
            }
        } else {
            placeholder = this.props.placeholder;
        }

        if(placeholder !== '' && placeholder !== undefined)
        {
           this.setState({placeholder: placeholder});
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.objValue !== undefined && this.props.objValue !== undefined) {
            if (prevProps.objValue.value !== this.props.objValue.value) {                                

                let propText = ''
                if (Array.isArray(this.props.data)) {

                    let item = this.props.data.find(d => d.value === this.props.objValue.value);
                    if (item && item.name) {
                        propText = item.name;
                    }
                }                
                this.setState({ value: this.props.objValue.value, selectedText: propText }, ()=>{
                    this.props.getValue(this.state.value);
                });
            }
        }
        if (prevProps.data !== undefined && this.props.data !== undefined && this.props.data.length != prevProps.data.length) {
            this.setState({ data: this.props.data, filteredData: this.props.data });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside.bind(this));
      }

    handleClickOutside(event) {
        if (this.ref && this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({open: false});
        }
      }

    onSelect= (selectedItem) => {
        this.props.getValue(selectedItem);
        this.setState({
            value : selectedItem.value,
            selectedText: selectedItem.name,
            open:false
        });
    }
    
    handleUpdateSearch = (event) => {
        let searchText = event.target.value;
        let filteredData = this.state.data.filter(d => d.name.toString().toLowerCase().includes(searchText));
        this.setState({
            searchText: searchText,
            filteredData: filteredData
        });
    }

    showItems = (event) => {
        let aux = !this.state.open;
        this.setState({
            open: aux, hasFocus: true
        });
    }

    onBlurSearchControl = (event) => {        
        this.setState({ hasFocus: false });
    }

    onClickSearchControl = (event) => {        
        this.setState({
            open: !this.state.open, hasFocus: true
        });
    }

    onKeyPress = (event) => {
        if (event.keyCode === 9 && event.target.id.includes('dropdownInput')) {
            this.setState({
                open: true, hasFocus: true
            });
        }
    }

    render() {
        var disabled = false;
        if(this.props.disabled !== undefined){
          disabled = this.props.disabled;
        }
        var options = [];
        var initCount = 0;

        if (this.props.listHasPlaceholder){
            if(this.props.data.length>0){
                initCount = 1;
            }
        }

        if(disabled)
        {
            for (var i=initCount; i<this.state.filteredData.length; i++) {
                if(this.state.value === this.state.filteredData[i].value)
                {
                    options.push(<option key={this.state.filteredData[i].value} className='optionDropdownSearch'value={this.state.filteredData[i].value} onClick={this.onSelect.bind(this,this.state.filteredData[i])} >{this.state.filteredData[i].name}</option>);
                }
            }
        }else{
            for (var i=initCount; i<this.state.filteredData.length; i++) {
                if(this.state.value === this.state.filteredData[i].value)
                {
                    options.push(<option key={this.state.filteredData[i].value} className='optionDropdownSearchSelected' value={this.state.filteredData[i].value} onClick={this.onSelect.bind(this,this.state.filteredData[i])}>{this.state.filteredData[i].name}</option>);
                }
                else
                {
                    options.push(<option key={this.state.filteredData[i].value} className='optionDropdownSearch' value={this.state.filteredData[i].value} onClick={this.onSelect.bind(this,this.state.filteredData[i])}>{this.state.filteredData[i].name}</option>);
                }
            }
        }
        
        return(
            <div id={this.props.id} className={'dropdownSearch'} ref={this.ref} disabled={this.props.disabled} onKeyUp={this.onKeyPress}>
                <input id={'dropdownInput-' + this.props.id} type="text" className={regularInputClass + ' ' + this.props.className} readOnly={true} placeholder={this.state.placeholder} onBlur={this.onBlurSearchControl}
                    value={this.state.selectedText} onClick={this.onClickSearchControl} />
                {this.state.open &&
                    <div className='optionsContainer' style={{ width: this.state.controlWidth }}>
                        <input type="text" className={regularInputClass} autoFocus={true} placeholder="Enter search text" value={this.state.searchText} onChange={this.handleUpdateSearch} />
                        {options}
                    </div>
                }
            </div>
        );
    }
}