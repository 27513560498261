import React, { useContext, useEffect, useRef, useState } from 'react';

import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox'
import { formatDate } from '../../../helpers/FormatHelper';
import { getEventByID, saveEvent } from '../../../networking/NetworkingScheduler';
import AppContext from '../../../AppContext';
import { Dialog, DialogContent, Tooltip } from '@material-ui/core';
import FollowUpScreen from './FollowUpScreen';
import { Colors } from '../../../constants/Colors';
import InfoIcon from '@material-ui/icons/Info';
import ShowDynamicInfo from '../../shared/ShowDynamicInfo';
import { getCustomerInfoMin } from '../../../networking/NetworkingCustomers';
import { getContactInfoMin } from '../../../networking/NetworkingContacts';

const CompleteEventScreen =(props)=> {
    
    const FORMAT_NEW_DAYPICKER = 'YYYY/MM/DD';
    const style = getStyle();
    const context = useRef(useContext(AppContext));
        const [customerID, setCustomerID] = useState (0);
        const [customerIDName, setCustomerIDName] = useState ('');
        const [contactID, setContactID] = useState (0);
        const [contactIDName, setContactIDName] = useState ('');
        const [eventType, setEventType] = useState ('');
        const [subject, setSubject] = useState ('');
        const [description, setDescription] = useState ('');
        const [dateStart, setDateStart] = useState (formatDate(new Date()));
        const [timeStart, setTimeStart] = useState ('00:00');
        const [dateEnd, setDateEnd] = useState (formatDate(new Date()));
        const [timeEnd, setTimeEnd] = useState ('00:00');
        const [allDay, setAllDay] = useState (false);
        const [reminder, setReminder] = useState ('');
        const [eventID, setEventID] = useState (0);
        const [parentEventID, setParentEventID] = useState (0);
        const [isParentEvent, setIsParentEvent] = useState (true);
        const [updateToken, setUpdateToken] = useState (0);
        const [dateTimeStart, setDateTimeStart] = useState('');
        const [dateTimeEnd, setDateTimeEnd] = useState('');

        const [showAlert, setShowAlert] =useState(false);
        const [showAlertSuccess, setShowAlertSuccess] =useState(false);
        const [showAlertFollowUp, setShowAlertFollowUp] =useState(false);
        const [alertTitle, setAlertTitle] = useState ('');
        const [alertMessage, setAlertMessage] = useState('');
        const [event, setEvent]= useState({});
        const [openFollowUpEvent, setOpenFollowUpEvent] =useState(false);
        const [openInfoScreen, setOpenInfoScreen] =useState(false);
        const [customer, setCustomer] = useState({});
        const [contact, setContact] = useState({});
        const [jsonDetail, setJsonDetail] = useState({});
        const [screenTitle, setScreenTitle] = useState ('');



    useEffect(()=>{
        if(props.openCompleteEvent && props.eventID && props.eventID >0)
        {
            context.current.startLoading();
          
            getEventByID(props.eventID).then(
                (response) => {
                    if(response && response.event && response.event.length > 0)
                    {
                        let event = response.event[0];
                        setEvent(event);
                        setEventID(props.eventID);
                        setCustomerID(event.CustomerID);
                        setCustomerIDName(event.CustomerName);
                        setContactID(event.ContactID);
                        setContactIDName(event.ContactName);
                        setEventType(event.EventType);
                        setSubject(event.Subject);
                        setDescription(event.Description);
                        setAllDay(event.AllDay);
                        setDateStart(event.EventDateStart);
                        setTimeStart(event.EventTimeStart);
                        setDateEnd(event.EventDateEnd);
                        setTimeEnd(event.EventTimeEnd);
                        setReminder(event.Reminder);
                        setUpdateToken(event.UpdateToken);
                        setParentEventID(event.ParentEventID);
                        setIsParentEvent(event.IsParentEvent);
                        let dateStart = new Date(event.EventDateStartLong);        
                        setDateTimeStart(`${dateStart.toDateString()} ${dateStart.toLocaleTimeString()}`);
                        let dateEnd = new Date(event.EventDateEndLong);        
                        setDateTimeEnd(`${dateEnd.toDateString()} ${dateEnd.toLocaleTimeString()}`);

                        getCustomerInfoMin(event.CustomerID).then((jsonCustomer)=>{
                            setCustomer(jsonCustomer);
                        });

                        getContactInfoMin(event.ContactID).then((jsonContact)=>{
                            setContact(jsonContact);
                        });

                        context.current.finishLoading();
                    }
                    else
                    {
                        context.current.finishLoading();
                    }
                });
        }
        
    },[props.eventID])

    const OnCancel = () =>{
        if(props.onCancel)
        {
            props.onCancel(false);
        }
    }


    const OnSave = async() =>{


            context.current.startLoading();

            let model = {
                EventID:eventID,
                EventPersonID: localStorage.getItem('UserID'),
                CustomerID: customerID,
                ContactID: contactID,
                EventType: eventType,
                Subject: subject,
                Description: description,
                EventDateStart: dateStart,
                EventTimeStart: `${timeStart}:00`,
                EventDateEnd: allDay?'':dateEnd,
                EventTimeEnd: allDay?'':`${timeEnd}:00`,
                Reminder: reminder,
                Status: 'Completed',
                UpdatedBy: localStorage.getItem('UserID'),
                UpdateToken: updateToken,
                ParentEventID: parentEventID
            }
                        
            saveEvent(model).then((json) => {

                if (json.httpStatusCode !== 200){
                    setAlertTitle('Error');
                    setAlertMessage('An error occurred while trying to save the Event. Please try again.');
                    setShowAlert(true);                 
                } else {
                    setShowAlertSuccess(true);                 
                               
                }
            })
                context.current.finishLoading();
   
    }

    const closeAlertSuccess = () => {
        setShowAlertSuccess(false);
        if(!isParentEvent)
        {
            setShowAlertFollowUp(true);
        }
        else
        {
            if(props.onReload)
            {
                props.onReload();
            }  
            if(props.onCancel)
            {
                props.onCancel();
            }
        }
    }

    const closeAlert = () => {
        setShowAlert(false);
    }

     const handleChangeDescription = (event) =>{
        setDescription(event.target.value);              
     }

     const yesFollowUp = () =>{
        setShowAlertFollowUp(false);
        setOpenFollowUpEvent(true);
     }

     const noFollowUp = () =>{
        setShowAlertFollowUp(false);
        setOpenFollowUpEvent(false);
     }

     const cancelFollowUp = () =>{
        setShowAlertFollowUp(false);
        setOpenFollowUpEvent(false);
        if(props.onCancel)
        {
            props.onCancel();
        }
     }

     const showCustomerInfo = ()=>{
        setJsonDetail(customer);
        setScreenTitle("Customer Information");
        setOpenInfoScreen(true);
     }

     const showContactInfo = ()=>{
        setJsonDetail(contact);
        setScreenTitle("Contact Information");
        setOpenInfoScreen(true);
     }

        return(
            <>
            <Dialog
          fullWidth={true}
          maxWidth='md'
          disableBackdropClick
          disableEscapeKeyDown
          open={props.openCompleteEvent} 
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent>
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>{`Complete Event`}</h3>
                <div className="uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >{`Customer `} 
                            <Tooltip title="Customer Information">
                                <InfoIcon id={`customerInfo`} onClick={showCustomerInfo} style={{color:Colors.primaryColor, cursor:'pointer'}} />
                  
                            </Tooltip>
                        </label>
                        <div className="" >
                            <input className="uk-input" id="customer" value={customerIDName} type="text" placeholder="Customer" readOnly={true}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >{`Contact `} 
                            <Tooltip title="Contact Information">
                                <InfoIcon id={`contactInfo`} onClick={showContactInfo} style={{color:Colors.primaryColor, cursor:'pointer'}} />
                  
                            </Tooltip> </label>
                        <div className="">
                            <input className="uk-input" id="contact" value={contactIDName} type="text" placeholder="Contact" readOnly={true}/>
                        </div>
                    </div>
                    
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label" >Subject (*)</label>
                        <div className="" style={style.inputStyle}>
                            <input className="uk-input" id="subject" value={subject} type="text" placeholder="Subject" readOnly={true}/>
                        </div>
                    </div>                    
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label" >Notes</label>
                        <div className="" style={style.inputStyle}>
                        <textarea className="uk-textarea" style={{marginTop: "5px"}} id="Comments" type="text" placeholder="" value={description} onChange={handleChangeDescription}></textarea>
                        </div>
                    </div>
                    <div className="uk-width-1-4" style={style.inputStyle}>
                        <label className="uk-form-label" >Date Start</label>
                        <input className="uk-input" id="dateStart" value={dateTimeStart} type="text" placeholder="Date Start" readOnly={true}/>
                    </div>
                    <div className="uk-width-1-4" style={style.inputStyle}>
                        <label className="uk-form-label" >Date End</label>
                        <input className="uk-input" id="dateEnd" value={dateTimeEnd} type="text" placeholder="Date End" readOnly={true}/>
                    </div>
                    
                    <div className="uk-width-1-1" style={{marginTop:'20px', textAlign:'end'}}>
                        <button className="uk-button uk-button-green" onClick={OnCancel} >Cancel</button>
                        <button className="uk-button uk-button-green" onClick={OnSave} >Save</button>  
                    </div>
                </div>
                <AlertBox open={showAlertSuccess} onClose={closeAlertSuccess} title={'Success'} message={'Event successfully saved.'} type="Ok" okClick={closeAlertSuccess}/>
                <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert}/>
                <AlertBox id="followup-alert"
                    type="Yes/No"
                    open={showAlertFollowUp}
                    title="Confirmation"
                    message="Do you want to do a follow up for this event?"
                    yesClick={yesFollowUp}
                    noClick={noFollowUp} />
            </div>
            </DialogContent>
      </Dialog>
      <FollowUpScreen event={event} openFollowUpEvent={openFollowUpEvent}  
        onCancel={cancelFollowUp} 
              eventReminderList={props.eventReminderList}
              onReload={props.onReload} />
        <ShowDynamicInfo open={openInfoScreen} jsonDetail={jsonDetail} title={screenTitle} onClose={() => setOpenInfoScreen(false)}/>
                
      </>
        );
    
}


export default CompleteEventScreen;