import React from 'react';
import { getStyle } from '../../../../styles/styles';
import LoadingBox from '../../../shared/LoadingBox';
import AlertBox from '../../../shared/AlertBox';
import DynamicSelect from '../../../shared/DynamicSelect';
import NumberInput from '../../../shared/NumberInput';
import { formatValidSelect, formatToCurrency, getScaleByCurrency, formatToCurrencyWithScale } from '../../../../helpers/FormatHelper';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import { getAllDynamicPricingByCustomer, saveDynamicPricingByCustomer, deleteDynamicPricingByCustomer, getAllDynamicPricingDefault, saveDynamicPricingDefault, deleteDynamicPricingDefault } from '../../../../networking/NetworkingCustomers';
import { getLookUpTable } from '../../../../networking/Networking';
import { searchCurrencies, getCurrencyList } from '../../../../networking/NetworkingCurrencies';
import { validate } from '../../../../helpers/ValidateHelper';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
const style = getStyle();

export default class DynamicCoverScreen extends React.Component {

    state = {
        loading: true,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        errors: [],
        newSearch: false,
        pages: 1,
        totalPages: 0,
        readyToDeleteDynamicCover: null,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        dynamicPricing: [],
        bankInterfaces: [],
        timeTable: [
            { name: '00:00', value: '00:00' },
            { name: '01:00', value: '01:00' },
            { name: '02:00', value: '02:00' },
            { name: '03:00', value: '03:00' },
            { name: '04:00', value: '04:00' },
            { name: '05:00', value: '05:00' },
            { name: '06:00', value: '06:00' },
            { name: '07:00', value: '07:00' },
            { name: '08:00', value: '08:00' },
            { name: '09:00', value: '09:00' },
            { name: '10:00', value: '10:00' },
            { name: '11:00', value: '11:00' },
            { name: '12:00', value: '12:00' },
            { name: '13:00', value: '13:00' },
            { name: '14:00', value: '14:00' },
            { name: '15:00', value: '15:00' },
            { name: '16:00', value: '16:00' },
            { name: '17:00', value: '17:00' },
            { name: '18:00', value: '18:00' },
            { name: '19:00', value: '19:00' },
            { name: '20:00', value: '20:00' },
            { name: '21:00', value: '21:00' },
            { name: '22:00', value: '22:00' },
            { name: '23:00', value: '23:00' },
            { name: '23:59', value: '23:59' }
        ],
        currencies: [],
        currency: '',
        currencyName: '',
        liquidityProvider: '',
        fundsTransferProvider: '',
        fromTime: '',
        toTime: '',
        fromAmount: 0,
        toAmount: 0,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        updateToken: '',
        validations: {
            currency: true,
            liquidityProvider: true,
            fundsTransferProvider: true
        }
    };

    componentDidMount() {
        this.loadDynamicPricingDefault();
        getLookUpTable('BankInterfaces').then(
            (json) => {
                if (json != null) {
                    const bankInterfaces = json.lookUpTables[0].lookUpTableDetails.map(d => ({ ...d, name: d.LookUpTableDetail, value: d.LookUpTableDetail }));
                    this.setState({ bankInterfaces });
                }
            }
        );
        getCurrencyList().then(
            (json) => {
                if (json != null && json.currencies != null) {
                    const currencies = json.currencies.map(c => ({ ...c, name: c.CurrencyIDName, value: c.CurrencyID }));
                    this.setState({ currencies });
                }
            }
        );
    }

    loadDynamicPricingDefault = async () => {
        let json = {}
        if (this.props.customerID != null) {
            json = await getAllDynamicPricingByCustomer(this.props.customerID);
        } else {
            //Call Dynamic Default GLOBAL
            json = await getAllDynamicPricingDefault();
        }
        if (json != null && json.dynamicPricing != null) {
            const dynamicPricing = this.transformDynamicPricing(json.dynamicPricing);
            const totalCount = dynamicPricing.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({ dynamicPricing, totalPages, loading: false, newSearch: true });
        } else {
            this.setState({ dynamicPricing: [], totalPages: 0, loading: false, newSearch: true });
        }
    }

    handleBackLink = () => {
        this.props.backLink();
    }

    handleSaveClick = async () => {
        if (this.validateRequiredFields()) {
            let model = {
                SellCurrency: this.state.currency,
                FromAmount: this.state.fromAmount,
                ToAmount: this.state.toAmount,
                FromTime: this.state.fromTime,
                ToTime: this.state.toTime,
                Monday: this.state.monday,
                Tuesday: this.state.tuesday,
                Wednesday: this.state.wednesday,
                Thursday: this.state.thursday,
                Friday: this.state.friday,
                Saturday: this.state.saturday,
                Sunday: this.state.sunday,
                LiquidityProvider: this.state.liquidityProvider,
                FundsTransferProvider: this.state.fundsTransferProvider,
                UpdatedBy: localStorage.getItem('UserID'),
                UpdateToken: this.state.updateToken
            }
            let json = {}
            this.setState({ loading: true });
            if (this.props.customerID != null) {
                model.CustomerId = this.props.customerID
                json = await saveDynamicPricingByCustomer(model);
            } else {
                //Call Dynamic Default GLOBAL
                json = await saveDynamicPricingDefault(model);
            }
            if (json != null) {
                switch (Number(json.httpStatusCode)) {
                    case 200: {
                        this.setState({
                            showAlert: true, alertTitle: 'Success', alertMessage: 'Dynamic cover successfully saved.', loading: false
                        });
                        this.handleClearClick();
                        await this.loadDynamicPricingDefault();
                        break;
                    }
                    default: {
                        this.setState({
                            showAlert: true, alertMessage: json.httpErrorMessage, alertTitle: 'Error', loading: false
                        });
                        break;
                    }
                }
            }
        }
    }

    validateRequiredFields = () => {
        const fields = [
            { displayName: 'Currency', validateName: 'currency', value: this.state.currency, type: 'string' },
            { displayName: 'Liquidity Provider', validateName: 'liquidityProvider', value: this.state.liquidityProvider, type: 'string' },
            { displayName: 'FundsTransfer Provider', validateName: 'fundsTransferProvider', value: this.state.fundsTransferProvider, type: 'string' },
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({ validations: { ...this.state.validations, ...validations } });

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }

    handleClearClick = () => {
        this.setState({
            currency: '',
            currencyName: '',
            liquidityProvider: '',
            fundsTransferProvider: '',
            fromTime: '',
            toTime: '',
            fromAmount: 0,
            toAmount: 0,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            updateToken: '',
            validations: {
                currency: true,
                liquidityProvider: true,
                fundsTransferProvider: true
            }
        })
    }

    handleRowClick = (obj) => {
        this.setState({
            currency: obj.Currency,
            currencyName: obj.Currency,
            liquidityProvider: obj['Liquidity Provider'],
            fundsTransferProvider: obj['Funds Transfer Provider'],
            fromTime: obj['From Time'],
            toTime: obj['To Time'],
            fromAmount: obj['From Amount'],
            toAmount: obj['To Amount'],
            monday: obj.Mon === "✓" ? true : false,
            tuesday: obj.Tue === "✓" ? true : false,
            wednesday: obj.Wed === "✓" ? true : false,
            thursday: obj.Thu === "✓" ? true : false,
            friday: obj.Fri === "✓" ? true : false,
            saturday: obj.Sat === "✓" ? true : false,
            sunday: obj.Sun === "✓" ? true : false,
            updateToken: obj.UpdateToken
        })
    }

    handleDeleteClick = (obj) => {
        this.setState({
            showDeleteAlert: true,
            readyToDeleteDynamicCover: obj
        });
    }

    yesDeleteAlert = async () => {
        let model = {
            SellCurrency: this.state.readyToDeleteDynamicCover.Currency,
            FromAmount: this.state.readyToDeleteDynamicCover['From Amount'],
            ToAmount: this.state.readyToDeleteDynamicCover['To Amount'],
            FromTime: this.state.readyToDeleteDynamicCover['From Time'],
            ToTime: this.state.readyToDeleteDynamicCover['To Time'],
            LiquidityProvider: this.state.readyToDeleteDynamicCover['Liquidity Provider'],
            FundsTransferProvider: this.state.readyToDeleteDynamicCover['Funds Transfer Provider']
        };
        let json = {}
        this.setState({ showDeleteAlert: false, loading: true });
        if (this.props.customerID != null) {
            model.CustomerId = this.props.customerID
            json = await deleteDynamicPricingByCustomer(model);
        } else {
            //Call Dynamic Default GLOBAL
            json = await deleteDynamicPricingDefault(model);
        }
        if (json !== undefined) {
            console.log(json);
            if (json.status !== 200) {
                this.setState({
                    showSuccessDeleteAlert: true,
                    loading: false
                });
                this.handleClearClick();
                await this.loadDynamicPricingDefault();
            } else {
                this.setState({
                    showAlert: true,
                    alertMessage: "An error occurred while trying to delete the dynamic cover. Please try again.",
                    loading: false
                });
            }
        }
    }

    closeDeleteAlert = () =>
        this.setState({
            showDeleteAlert: false,
            readyToDeleteDynamicCover: null
        });

    okSuccessAlert = () =>
        this.setState({
            showSuccessDeleteAlert: false
        });

    handleExitClick = () => {
        this.props.backLink();
    }

    transformDynamicPricing = (dynamicPricing) => {
        const list = dynamicPricing.map((d) => ({
            ...d,
            Mon: d.Mon ? '✓' : '',
            Tue: d.Tue ? '✓' : '',
            Wed: d.Wed ? '✓' : '',
            Thu: d.Thu ? '✓' : '',
            Fri: d.Fri ? '✓' : '',
            Sat: d.Sat ? '✓' : '',
            Sun: d.Sun ? '✓' : ''
        }));
        return list;
    }

    searchCurrency = (query) => {
        searchCurrencies(query, 'active').then(
            (json) => {
                let helper = [];
                if (query !== '') {
                    if (json != null) {
                        for (let i = 0; i < json.length; i++) {
                            helper.push({ value: json[i].CurrencyID, name: json[i].CurrencyIDName })
                        }
                        if (json.length > 1) {
                            this.setState({ currencies: helper });
                        } else {
                            this.setState({ currencies: helper, currency: helper[0].name });
                        }
                    } else {
                        this.setState({ currencies: helper });
                    }
                }
            }
        );
    }

    getAllDynamicPricing = async () => {
        let json = {}
        if (this.props.customerID != null) {
            json = await getAllDynamicPricingByCustomer(this.props.customerID);
        } else {
            //Call Dynamic Default GLOBAL
            json = await getAllDynamicPricingDefault();
        }
        if (json != null && json.dynamicPricing != null) {
            return this.transformDynamicPricing(json.dynamicPricing);
        } else {
            return [];
        }
    }

    changePage = (value) => {
        this.setState({
            pages: value,
            newSearch: false
        });
    }

    handleGetValueCurrency = (value) => {
        if (value != null && value !== 'select' && value !== '') {
            this.setState({
                currency: value.value !== 'select' ? value.value : '',
                currencyName: value.name != null ? value.name : '',
                validations: { ...this.state.validations, currency: true }
            });
        }
    }

    handleGetValueLiquidityProvider = (value) => {
        if (value != null && value !== 'select') {
            this.setState({
                liquidityProvider: value.value,
                validations: { ...this.state.validations, liquidityProvider: true }
            });
        }
    }

    handleGetValueFundsTransferProvider = (value) => {
        if (value != null && value !== 'select') {
            this.setState({
                fundsTransferProvider: value.value,
                validations: { ...this.state.validations, fundsTransferProvider: true }
            });
        }
    }

    handleGetValueFromTime = (value) => {
        if (value != null && value !== 'select') {
            this.setState({
                fromTime: value.value,
            });
        }
    }

    handleGetValueToTime = (value) => {
        if (value != null && value !== 'select') {
            this.setState({
                toTime: value.value,
            });
        }
    }

    handleUpdateFromAmount = (event) => {
        this.setState({ fromAmount: event.target.value });
    }

    handleUpdateToAmount = (event) => {
        this.setState({ toAmount: event.target.value });
    }

    handleUpdateMonday = (event) => {
        this.setState({ monday: event.target.checked });
    }

    handleUpdateTuesday = (event) => {
        this.setState({ tuesday: event.target.checked });
    }

    handleUpdateWednesday = (event) => {
        this.setState({ wednesday: event.target.checked });
    }

    handleUpdateThursday = (event) => {
        this.setState({ thursday: event.target.checked });
    }

    handleUpdateFriday = (event) => {
        this.setState({ friday: event.target.checked });
    }

    handleUpdateSaturday = (event) => {
        this.setState({ saturday: event.target.checked });
    }

    handleUpdateSunday = (event) => {
        this.setState({ sunday: event.target.checked });
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    closeAlertError = () => {
        this.setState({ showAlertError: false });
    }

    render() {
        var section = (<h3 className="uk-heading-divider" style={style.titleStyle} >Dynamic Cover</h3>)
        if (this.props.backLink != null) {
            section = (
                <h3 className="uk-heading-divider component-title" >
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink}>
                        <i className="fa fa-arrow-left back-arrow" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>
                    </button>
                    Dynamic Cover
                </h3>
            )
        }
        if (this.props.isTitle != null) {
            section = ''
        }
        return (
            <div>
                {section}
                <form className="uk-form-horizontal">
                    <div className="uk-grid">
                        <div className="uk-width-1-1 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Currency (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect id="dynamic-cover-currency" className={formatValidSelect(this.state.validations.currency)} objValue={{ value: this.state.currency, name: this.state.currencyName }} getValue={this.handleGetValueCurrency} placeholder={"Select an Item..."} data={this.state.currencies} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Liquidity Provider (*)</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect className={formatValidSelect(this.state.validations.liquidityProvider)} objValue={{ value: this.state.liquidityProvider }} getValue={this.handleGetValueLiquidityProvider} listHasPlaceholder={true} data={this.state.bankInterfaces} id="form-liquidity-provider" />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>FundsTransfer Provider (*)</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect className={formatValidSelect(this.state.validations.fundsTransferProvider)} objValue={{ value: this.state.fundsTransferProvider }} getValue={this.handleGetValueFundsTransferProvider} listHasPlaceholder={true} data={this.state.bankInterfaces} id="form-funds-transfer-provider" />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >From Time</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect className="uk-select" objValue={{ value: this.state.fromTime }} getValue={this.handleGetValueFromTime} placeholder={"Select an Item..."} data={this.state.timeTable} id="form-from-time" />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" >To Time</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect className="uk-select" objValue={{ value: this.state.toTime }} getValue={this.handleGetValueToTime} placeholder={"Select an Item..."} data={this.state.timeTable} id="form-to-time" />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">From Amount</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <NumberInput id="dynamic-cover-from-amount" className="uk-input" type="Currency" 
                                scale={getScaleByCurrency(this.state.currency)}
                                placeholder="" value={this.state.fromAmount} onChange={this.handleUpdateFromAmount} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">To Amount</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <NumberInput id="dynamic-cover-to-amount" className="uk-input" type="Currency" 
                                scale={getScaleByCurrency(this.state.currency)}
                                placeholder="" value={this.state.toAmount} onChange={this.handleUpdateToAmount} />
                            </div>
                        </div>
                        <div className="uk-width-1-7 uk-form-stacked uk-margin">
                            <label className="uk-form-label">
                                <input id="dynamic-cover-monday" className="uk-checkbox" value={this.state.monday} checked={this.state.monday} onChange={this.handleUpdateMonday} type="checkbox" />
                                Monday
                            </label>
                        </div>
                        <div className="uk-width-1-7 uk-form-stacked uk-margin">
                            <label className="uk-form-label">
                                <input id="dynamic-cover-tuesday" className="uk-checkbox" value={this.state.tuesday} checked={this.state.tuesday} onChange={this.handleUpdateTuesday} type="checkbox" />
                                Tuesday
                            </label>
                        </div>
                        <div className="uk-width-1-7 uk-form-stacked uk-margin">
                            <label className="uk-form-label">
                                <input id="dynamic-cover-wednesday" className="uk-checkbox" value={this.state.wednesday} checked={this.state.wednesday} onChange={this.handleUpdateWednesday} type="checkbox" />
                                Wednesday
                            </label>
                        </div>
                        <div className="uk-width-1-7 uk-form-stacked uk-margin">
                            <label className="uk-form-label">
                                <input id="dynamic-cover-thursday" className="uk-checkbox" value={this.state.thursday} checked={this.state.thursday} onChange={this.handleUpdateThursday} type="checkbox" />
                                Thursday
                            </label>
                        </div>
                        <div className="uk-width-1-7 uk-form-stacked uk-margin">
                            <label className="uk-form-label">
                                <input id="dynamic-cover-friday" className="uk-checkbox" value={this.state.friday} checked={this.state.friday} onChange={this.handleUpdateFriday} type="checkbox" />
                                Friday
                            </label>
                        </div>
                        <div className="uk-width-1-7 uk-form-stacked uk-margin">
                            <label className="uk-form-label">
                                <input id="dynamic-cover-saturday" className="uk-checkbox" value={this.state.saturday} checked={this.state.saturday} onChange={this.handleUpdateSaturday} type="checkbox" />
                                Saturday
                            </label>
                        </div>
                        <div className="uk-width-1-7 uk-form-stacked uk-margin">
                            <label className="uk-form-label">
                                <input id="dynamic-cover-sunday" className="uk-checkbox" value={this.state.sunday} checked={this.state.sunday} onChange={this.handleUpdateSunday} type="checkbox" />
                                Sunday
                            </label>
                        </div>
                    </div>
                </form>
                <div>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearClick}>Clear Fields</button>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSaveClick}>Save</button>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleExitClick}>Exit</button>
                    <hr />
                </div>
                <NewDynamicTable
                    id="ndt-dynamic-cover"
                    data={this.state.dynamicPricing}
                    formats={[
                        { header: 'From Amount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Currency' },
                        { header: 'To Amount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Currency' }
                    ]}
                    newSearch={this.state.newSearch}
                    hiddenHeaders={['UpdateToken']}
                    enableClick={true}
                    clickFunction={this.handleRowClick}
                    useDeleteButton={true}
                    deleteFunction={this.handleDeleteClick}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={getDefaultPagingNumberOfItems()}
                    enableSortByHeader={true}
                    useFilter={true}
                    filterFunction={this.getAllDynamicPricing}
                    useExportButton={true}
                    tableName="dynamic_pricing"
                    useIcons={true}
                />
                <LoadingBox loading={this.state.loading} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlertError} />
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete this dynamic cover?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Success" message="Dynamic cover successfully removed." type="Ok" okClick={this.okSuccessAlert} />
            </div>
        );
    }
}