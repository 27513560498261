import React from 'react';
import { formatToCommaSeparate } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getLookUpTable } from '../../../networking/Networking';
import { getAllLookUpTableMin } from '../../../networking/NetworkingLookUpTables';
import { deleteUserProperty, getSubEntityTypes, getUserDefinedProperties, getUserDefinedPropertiesById, saveProperty } from '../../../networking/NetworkingUserProperties';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSelect from '../../shared/DynamicSelect';
import NewDynamicTable from '../../shared/NewDynamicTable';
import AdditionalPropertiesDialog from './AdditionalPropertiesDialog';
import {Colors} from '../../../constants/Colors'

const style = getStyle();
const itemsPerPage = getDefaultPagingNumberOfItems();
export default class PropertiesMaintenanceScreen extends React.Component {
    state = {
        userPropertyId: 0,
        properties: [],
        allProperties: [],
        description: '',
        shortDescription: '',
        dataType: '',
        displayOrder: '',
        readOnly: false,
        visible: true,
        mandatory: false,
        groupBy: '',
        lookUpTableId: 0,
        lookUpTableName: '',
        listLookUpTables: [],
        "Data Type": [],
        "Sub Entity Types": [],
        lookUpTables: [
            "Data Type",
            "Sub Entity Types"
        ],
        comments: '',
        updateToken: 0,
        //
        hideButton: false,

        //For tables
        selectIDLeft: '',
        selectIDRight: '',
        listInitialHave: [],
        listHave: [],//originLists
        listWant: [],//originLists
        available: '',
        listMissing: [],

        validations: {
            description: true,
            shortDescription: true,
            dataType: true,
            displayOrder: true,
            readOnly: true,
            mandatory: true
        },

        alertMessage: '',
        showAlert: false,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteProperty: {},
        alertTitle: '',
        totalPages: 0,
        currentPage: 1,
        newSearch: false,
        openPropertiesDialog: false,
        extAmountLimit: 0,
        extAttachmentsRequired: false,
        extPrintable: false,
        extMandatory: false,
        extUserPropertyID: null,
        extUpdateToken: '',
        subEntityTypeFilter:[],
        subtEntityType:''
    }

    closeDeleteAlert = () => {
        this.setState({ showDeleteAlert: false, readyToDeleteProperty: {} });
    }

    openDeleteAlert = () => {
        this.setState({ showDeleteAlert: true });
    }

    yesDeleteAlert = () => {
        deleteUserProperty(this.state.readyToDeleteProperty['ID']).then(
            (value) => {
                if (value.httpStatusCode === 200) {
                    this.setState({ showDeleteAlert: false, showSuccessDeleteAlert: true });
                } else {
                    let message = 'An error occurred while trying to delete the property. Please try again.';
                    if (value.Message != null && value.Message !== '') {
                        message = value.Message;
                    }
                    this.setState({ showDeleteAlert: false, showAlert: true, alertTitle: 'Error', alertMessage: message });
                }
                this.updatePage();
            });
    }

    okSuccessAlert = () => {
        this.setState({ showSuccessDeleteAlert: false });
    }

    componentDidMount() {
        var list = []
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1) {
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        console.group(this.state.lookUpTables[i]);
                        console.table(table);
                        console.groupEnd();
                        this.setState({ [this.state.lookUpTables[i]]: table });
                    }
                }
                var helper = this.state["Sub Entity Types"]
                if (this.state["Sub Entity Types"] === null || this.state["Sub Entity Types"] === undefined) { list = [] }
                this.setState({
                    listInitialHave: this.transformBoolean(helper),
                    listHave: this.transformBoolean(helper),
                    listWant: list
                })
            })
        getAllLookUpTableMin().then(
            (value) => {
                var table = value.LookUpTable;
                for (var j = 0; j < table.length; j++) {
                    table[j].value = table[j].LookUpTableId;
                    table[j].name = table[j].Description;
                }
                this.setState({ listLookUpTables: table });
            }
        )

        /*getUserDefinedProperties(1,itemsPerPage).then(
            (value)=>{
                this.setState({properties: value.properties});                
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                console.log(pages);
                this.setState({totalPages: pages, newSearch: false});
            }
        )*/
        this.getSubEntityTypes();
        this.getUserDefinedProperties();
    }

    getSubEntityTypes()
    {
        getSubEntityTypes().then(
            (value) => {
                var table = value.SubEntityTypes;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].SubEntityType;
                            table[j].name = table[j].SubEntityType;
                        }
                this.setState({subEntityTypeFilter: table, subtEntityType: ''});
            }
        )
    }

    getUserDefinedProperties()
    {
        getUserDefinedProperties(this.state.subtEntityType, 0, 0).then(
            (value) => {
                //this.setState({allProperties: value.properties});                
                console.log(value)
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount / itemsPerPage);
                console.log(pages);
                this.setState({ allProperties: this.transformList(value.properties), totalPages: pages, newSearch: false });
            }
        )
    }

    transformWantList = list => {
        var helper = [];
        var i = 0;
        for (i; i < list.length; i++) {
            helper.push(list[i]['SubEntityType']);
        }
        return helper;
    }

    transformBoolean = list => {
        var helper = [];
        var i = 0, trueFalse = '';
        if (list.length !== 0 && list[0].name.includes('Select')) { i = 1 } else { i = 0 }
        for (i; i < list.length; i++) {
            if (list[i]['Editable']) {
                trueFalse = 'True';
            } else {
                trueFalse = 'False';
            }
            helper.push({
                'LookUpTableDetail': list[i]['LookUpTableDetail'],
                'DisplayOrder': list[i]['DisplayOrder'],
                'Field1': list[i]['Field1'],
                'Field2': list[i]['Field2'],
                'Field3': list[i]['Field3'],
                'Field4': list[i]['Field4'],
                'Field5': list[i]['Field5'],
                'Field6': list[i]['Field6'],
                'MappingId': list[i]['MappingId'],
                'name': list[i]['name'],
                'value': list[i]['value'],
                'Editable': trueFalse
            });
        }
        return helper;
    }

    transformPropertiesList(list) {
        var helper = [];
        for (var i = 0; i < list.length; i++) {
            helper.push({
                'ID': list[i]['ID'],
                'Group By': list[i]['Group By'],
                'Description': list[i]['Description'],
                'Short Description': list[i]['Short Description'],
                'Data Type': list[i]['Data Type'],
                'Display Order': list[i]['Display Order']
            });
        }
        return helper;
    }

    handleRowClick = (obj) => {
        getUserDefinedPropertiesById(obj['ID']).then((value) => {
            const subTypes1 = value.subTypes;
            let havelist = [...this.state.listInitialHave] || [];
     
            if (subTypes1 !== null) {
                for (var i = havelist.length - 1; i >= 0; i--) {
                    for (var j = 0; j < subTypes1.length; j++) {
                        if (i > 0 && havelist.length > 0 && subTypes1[j] && subTypes1[j].SubEntityType === havelist[i].LookUpTableDetail) {
                            havelist.splice(i, 1);
                            i = --i;
                        }
                    }
                }
            }
            this.setState({
                userPropertyId: obj['ID'],
                description: value.Description,
                shortDescription: value.ShortDescription,
                dataType: value.DataType,
                displayOrder: value.DisplayOrder,
                readOnly: value.ReadOnly,
                visible: value.Visible,
                mandatory: value.Mandatory,
                groupBy: value.GroupBy === null ? "" : value.GroupBy,
                lookUpTableId: value.LookUpTableId,
                listWant: value.subTypes,
                comments: value.Comments,
                updateToken: value.UpdateToken,
                listHave: havelist,
                extAmountLimit: obj['ExtAmountLimit$'],
                extAttachmentsRequired: obj['ExtAttachmentsRequired$'],
                extMandatory: obj['ExtMandatory$'],
                extUserPropertyID: obj['ID'],//obj['ExtUserPropertyID$'],
                extUpdateToken: obj['ExtUpdateToken$'],
                extPrintable: obj['ExtPrintable$']
            });
        });
    }

    handleDeleteClick = (obj) => {
        console.group("Property handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({ showDeleteAlert: true, readyToDeleteProperty: obj });
    }

    changePage = (value) => {
        this.setState({ currentPage: value });
        /*getUserDefinedProperties(this.state.currentPage, itemsPerPage).then(
            (value) => {
                this.setState({properties: value.properties});
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                // console.log(pages);
                this.setState({totalPages: pages, newSearch: false});
            }
        );*/
    }

    updatePage = () => {
        /*getUserDefinedProperties(this.state.currentPage,itemsPerPage).then(
            (value) => {
                this.setState({properties: value.properties});
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                // console.log(pages);
                this.setState({totalPages: pages, newSearch: false});
            }
        );*/
        var listAllProperties = []

        getUserDefinedProperties(this.state.subtEntityType, 0, 0).then(
            (value) => {
                console.log("-------------------------------------------------------------------")
                console.log(JSON.stringify(value))
                //listAllProperties = this.transformPropertiesList(value.properties);
                listAllProperties = this.transformList(value.properties);

                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());

                this.setState({
                    allProperties: listAllProperties,
                    totalPages: pages
                });
            }
        );
    }

    transformList = (list) =>
    {
        var i= 0;
        var helper = [];
        var mandatoryExt = '';
        var attachment = '';
        var amountLimit = '';
        
        for (i; i < list.length; i++) {
            mandatoryExt = '';
         attachment = '';
         amountLimit = '';
            if(list[i]['ExtAmountLimit$'] != null && list[i]['ExtAmountLimit$'] > 0)
            {
                amountLimit = formatToCommaSeparate(list[i]['ExtAmountLimit$']) ;
            if (list[i]['ExtMandatory$']) {
                mandatoryExt = 'True';
            } else {
                if(list[i]['ExtMandatory$']!= null)
                {
                mandatoryExt = 'False';
                }
            }
            if (list[i]['ExtAttachmentsRequired$']) {
                attachment = 'True';
            } else {
                if(list[i]['ExtAttachmentsRequired$'] != null)
                {
                    attachment = 'False';
                }
            }
        }
            helper.push({
                'ID': list[i]['ID'],
                'Group By': list[i]['Group By'],
                'Description': list[i]['Description'],
                'Short Description': list[i]['Short Description'],
                'Data Type': list[i]['Data Type'],
                'Display Order': list[i]['Display Order'],
                'PossibleValues$': list[i]['PossibleValues$'],
                'ExtUserPropertyID$': list[i]['ExtUserPropertyID$'],
                'ExtAmountLimit$': list[i]['ExtAmountLimit$'],
                'ExtMandatory$': list[i]['ExtMandatory$'],
                'ExtPrintable$': list[i]['ExtPrintable$'],
                'ExtAttachmentsRequired$': list[i]['ExtAttachmentsRequired$'],
                'ExtUpdateToken$': list[i]['ExtUpdateToken$'],
                'Amount Limit': amountLimit,
                'Mandatory (Limit)': mandatoryExt,
                'Attachment': attachment
            });
        }
        return helper;
    }

    handleSaveClick = (event) => {
        let userID = localStorage.getItem('UserID');
        var model = {
            UserPropertyId: this.state.userPropertyId,
            DisplayOrder: this.state.displayOrder,
            Description: this.state.description,
            ShortDescription: this.state.shortDescription,
            DataType: this.state.dataType,
            ReadOnly: this.state.readOnly,
            Visible: this.state.visible,
            LookUpTableId: this.state.lookUpTableId,
            GroupBy: this.state.groupBy,
            Mandatory: this.state.mandatory,
            Comments: this.state.comments,
            SubTypes: this.transformWantList(this.state.listWant),
            UserId: userID,
            UpdateToken: this.state.updateToken
        }
        if (this.validateRequiredFields()) {
            this.getSubEntityTypes();

            saveProperty(model).then(
                (json) => {
                    console.log(json)
                    if (json.httpStatusCode !== 200) {
                        let message = json.httpErrorMessage;
                        if (json.Message != null && json.Message.includes('duplicate key')) {
                            message = <span> {message} <br/> {'This property is duplicate.'}</span>;
                        }
                        this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: message });
                    } else {
                        this.setState({
                            userPropertyId: 0,
                            description: '',
                            shortDescription: '',
                            dataType: '',
                            displayOrder: '',
                            readOnly: false,
                            visible: true,
                            mandatory: false,
                            groupBy: '',
                            lookUpTableId: 0,
                            lookUpTableName: '',
                            comments: '',
                            updateToken: 0,
                            selectIDLeft: '',
                            selectIDRight: '',
                            listHave: this.state.listInitialHave,
                            listWant: [],
                            showAlert: true,
                            alertTitle: 'Success',
                            alertMessage: 'Property successfully saved.',
                            subEntityType :''
                        }, () => this.updatePage());
                    }
            })
        }
    }

    validateRequiredFields() {
        var valid = true
        var description = true
        var shortDescription = true
        var dataType = true
        var displayOrder = true
        var readOnly = true
        //var mandatory = true
        var listItems = []

        if (this.state.description === undefined ||
            this.state.description === "") {
            valid = false;
            description = false;
            listItems.push("Description")
        }

        if (this.state.shortDescription === undefined ||
            this.state.shortDescription === "") {
            valid = false;
            shortDescription = false;
            listItems.push("Short Description")
        }

        if (this.state.dataType === "select" ||
            this.state.dataType === '' ||
            this.state.dataType === undefined) {
            valid = false;
            dataType = false;
            listItems.push("Data Type")
        }

        if (this.state.displayOrder === undefined ||
            this.state.displayOrder === "") {
            valid = false;
            displayOrder = false;
            listItems.push("Display Order")
        }

        if (this.state.readOnly === undefined ||
            this.state.readOnly === "" ||
            this.state.readOnly === null) {
            valid = false;
            readOnly = false;
            listItems.push("Read Only")
        }
/* 
        if (this.state.mandatory === undefined ||
            this.state.mandatory === "" ||
            this.state.mandatory === null) {
            valid = false;
            mandatory = false;
            listItems.push("Mandatory")
        } */

        this.setState({
            validations: {
                ...this.state.validations,
                description: description,
                shortDescription: shortDescription,
                dataType: dataType,
                displayOrder: displayOrder,
                readOnly: readOnly,
                //mandatory: mandatory
            },
            listMissing: listItems
        });

        if (!valid) {
            this.setState({
                showAlert: true,
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    validateRequiredInputs() {
        if (this.state.description === '') {
            return false;
        }
        if (this.state.shortDescription === '') {
            return false;
        }
        if (this.state.dataType === undefined) {
            return false;
        }
        if (this.state.displayOrder === '') {
            return false;
        }
        if (this.state.readOnly === undefined) {
            return false;
        }
        /* if (this.state.mandatory === undefined) {
            return false;
        } */
        return true;
    }

    handleClearClick = () => {
        this.setState({
            userPropertyId: 0,
            description: '',
            shortDescription: '',
            dataType: '',
            displayOrder: '',
            readOnly: false,
            visible: true,
            mandatory: false,
            groupBy: '',
            lookUpTableId: 0,
            lookUpTableName: '',
            comments: '',
            updateToken: 0,
            selectIDLeft: '',
            selectIDRight: '',
            listHave: this.state.listInitialHave,
            listWant: [],
            extAmountLimit: 0,
            extAttachmentsRequired: false,
            extMandatory: false,
            extUserPropertyID: null,
            extUpdateToken: ''
        }
        );
    }

    //For color left & right
    roleChangeColorLeft = selectIDLeft => e => {
        if (selectIDLeft !== undefined) {
            this.setState({ selectIDLeft });
        }
    };
    roleChangeColorRight = selectIDRight => e => {
        if (selectIDRight !== undefined) {
            this.setState({ selectIDRight });
        }
    };

    handleAddClick(event) {
        event.preventDefault();
        var listLeft = [...this.state.listHave];
        var listRight = [...this.state.listWant];
        var idSelected = this.state.selectIDLeft;
        var add = true
        if (idSelected !== '') {
            if (listRight.length === 0) {
                const newListLeft = listLeft.filter(subType =>subType.LookUpTableDetail !== idSelected);
                const newListRight = [
                        ...listRight,
                        ...listLeft
                            .filter(subType => subType.LookUpTableDetail === idSelected)
                            .map(subType => ({ SubEntityType: subType.LookUpTableDetail }))
                    ].sort((a, b) => a.SubEntityType.localeCompare(b.SubEntityType));
                this.setState({
                    listWant: newListRight,
                    listHave: newListLeft,
                    selectIDLeft: ''
                });

            } else {
                listRight.forEach(rightRole => {
                    if (rightRole.LookUpTableDetail === idSelected) {
                        add = false
                    }
                });
                if (add) {
                    const newListLeft = listLeft.filter(subType =>subType.LookUpTableDetail !== idSelected);
                    const newListRight = [
                        ...listRight,
                        ...listLeft
                            .filter(subType => subType.LookUpTableDetail === idSelected)
                            .map(subType => ({ SubEntityType: subType.LookUpTableDetail }))
                    ].sort((a, b) => a.SubEntityType.localeCompare(b.SubEntityType));
                    this.setState({
                        listWant: newListRight,
                        listHave: newListLeft,
                        selectIDLeft: ''
                    });
                }
            }
        }
    }

    handleRemoveClick(event) {
        event.preventDefault();
        var listLeft = [...this.state.listHave];
        var listRight = [...this.state.listWant];
        var idSelected = this.state.selectIDRight;
        // var add = true

        if (idSelected !== '') {
            const newListRight = listRight.filter(subType => subType.SubEntityType !== idSelected);
            const newListLeft = [
                    ...listLeft,
                    ...listRight
                        .filter(subType => subType.SubEntityType === idSelected)
                        .map(c => ({ LookUpTableDetail: c.SubEntityType }))
                ].sort((a, b) => a.LookUpTableDetail.localeCompare(b.LookUpTableDetail));
            this.setState({
                listWant: newListRight,
                listHave: newListLeft,
                selectIDLeft: ''
            });
        }
    }

    handleUpdateDescription(value) {
        this.setState({ description: value.target.value, validations: { ...this.state.validations, description: true } });
    }

    handleUpdateShortDescription(value) {
        this.setState({ shortDescription: value.target.value, validations: { ...this.state.validations, shortDescription: true } });
    }

    handleUpdateDataType(value) {
        this.setState({ dataType: value.value, validations: { ...this.state.validations, dataType: true } });
    }

    handleUpdateDisplayOrder(value) {
        this.setState({ displayOrder: value.target.value, validations: { ...this.state.validations, displayOrder: true } });
    }

    handleOnCheckReadOnly(event) {
        this.setState({ readOnly: event.target.checked, validations: { ...this.state.validations, readOnly: true } });
    }

    handleOnCheckVisible(event) {
        this.setState({ visible: event.target.checked});
    }

    handleOnCheckMandatory(event) {
        this.setState({ mandatory: event.target.checked, validations: { ...this.state.validations, mandatory: true } });
    }

    handleUpdateGroupBy(value) {
        this.setState({ groupBy: value.target.value });
    }

    handleUpdateLookUpTable(value) {
        this.setState({ lookUpTableName: value.Description, lookUpTableId: value.LookUpTableId });
    }

    handleUpdateSubEntityType(value)
    {
        if(value == 'select' || value.value == '')
        {
            value='';
        }
        else
        {
            value = value.SubEntityType;
        }
        this.setState({subtEntityType: value, currentPage: 1}, ()=> this.getUserDefinedProperties())
    }

    handleHideButtonClick = () => {
        if (this.state.hideButton) {
            this.setState({ hideButton: false })
        } else {
            this.setState({ hideButton: true })
        }
    }

    showAdditionalFields = () => {
        this.setState({ openPropertiesDialog: true });
    }

    onClosePropertiesDialog = (updateToken) => {
        this.setState({ openPropertiesDialog: false });
        if (updateToken !== this.state.extUpdateToken) { // A save was done
            this.updatePage();
            this.handleClearClick();
        }
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }

    getAllProperties = async () => {
        var json = await getUserDefinedProperties(this.state.subtEntityType, 0, 0);
        //var properties = this.transformPropertiesList(json.properties);
        var properties = this.transformList(json.properties);
        return properties;
    }

    render() {
        var classNameInput = regularInputClass;
        var classNameSelect = regularSelectClass;
        var listLeft = this.state.listHave.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));;
        var listRight = [];
        // var attachmentsText = "No Attachments";
        if (this.state.listWant === null || this.state.listWant === undefined) {
            this.setState({ listWant: [] }, () => listRight = this.state.listWant)
        } else {
            listRight = this.state.listWant.sort((a, b) => (a.SubEntityType > b.SubEntityType) ? 1 : ((b.SubEntityType > a.SubEntityType) ? -1 : 0))
        }
        var available = this.state.available;

        return (
            <div>
                <div className="uk-width-1">
                    <div>
                        <h3 className="uk-heading-divider" style={style.titleStyle}>Additional Properties</h3>
                    </div>
                </div>
                <div className="uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Description (*)</label>
                        <div className=""style={style.inputStyle}>
                            <input className={this.formatValidInput(this.state.validations.description)} id="description" type="text" placeholder="" value={this.state.description} onChange={this.handleUpdateDescription.bind(this)} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Short Description (*)</label>
                        <div className=""style={style.inputStyle}>
                            <input className={this.formatValidInput(this.state.validations.shortDescription)} id="shortDescription" type="text" placeholder="" value={this.state.shortDescription} onChange={this.handleUpdateShortDescription.bind(this)} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Data Type (*)</label>
                        <div className=""style={style.inputStyle}>
                            <DynamicSelect id="data-type" className={this.formatValidSelect(this.state.validations.dataType)} getValue={this.handleUpdateDataType.bind(this)} listHasPlaceholder={true} data={this.state["Data Type"]} objValue={{ value: this.state.dataType }} disabled={available} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Display Order (*)</label>
                        <div className=""style={style.inputStyle}>
                            <input className={this.formatValidInput(this.state.validations.displayOrder)} id="displayOrder" type="text" placeholder="" value={this.state.displayOrder} onChange={this.handleUpdateDisplayOrder.bind(this)} />
                        </div>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked uk-margin">
                        <label className="uk-form-label"><input className="uk-checkbox" type="checkbox" checked={this.state.readOnly} value={this.state.readOnly} onChange={this.handleOnCheckReadOnly.bind(this)} /> Read Only</label>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked uk-margin">
                        <label className="uk-form-label"><input className="uk-checkbox" type="checkbox" checked={this.state.visible} value={this.state.visible} onChange={this.handleOnCheckVisible.bind(this)} /> Visible</label>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked uk-margin">
                        <label className="uk-form-label"><input className="uk-checkbox" type="checkbox" checked={this.state.mandatory} value={this.state.mandatory} onChange={this.handleOnCheckMandatory.bind(this)} /> Mandatory</label>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Group By</label>
                        <div className="" style={style.inputStyle}>
                            <input className={classNameInput} id="groupBy" type="text" placeholder="" value={this.state.groupBy} onChange={this.handleUpdateGroupBy.bind(this)} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Look up Table</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect id="lookuptable-list" className={classNameSelect} getValue={this.handleUpdateLookUpTable.bind(this)} listHasPlaceholder={false} placeholder='Select an item...' data={this.state.listLookUpTables} objValue={{ value: this.state.lookUpTableId, name: this.state.lookUpTableName }} disabled={available} />
                        </div>
                    </div>
                </div>
                {/* //------Available Subtypes----------- */}
                <ul id="accordion-online-access" uk-accordion="multiple: true">
                    <li className="">
                        <h5 className="uk-accordion-title">Available Subtypes</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="general-information">
                                <div className="uk-grid" style={{ marginTop: "0px" }}>
                                    <div className="uk-width-1-1" id="subtype-component">
                                        <div
                                            className="uk-margin"
                                            style={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                border: '1px solid #9DA99E'
                                            }}
                                        >
                                            <div
                                                className="uk-width-1-3"
                                                style={{
                                                    overflowY: 'scroll',
                                                    height: 500,
                                                }}
                                            >
                                                <table
                                                    className="uk-table uk-table-hover uk-table-divider"
                                                    style={{
                                                        marginBottom: 0
                                                    }}
                                                >
                                                    <tbody>
                                                        {listLeft.map((item, i) => {
                                                            return (
                                                                <tr
                                                                    key={item.LookUpTableDetail} onClick={this.roleChangeColorLeft(item.LookUpTableDetail)}
                                                                    style={
                                                                        this.state.selectIDLeft === item.LookUpTableDetail ?
                                                                            { backgroundColor: Colors.primaryColor, cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                                    }
                                                                >
                                                                    <td
                                                                        style={
                                                                            this.state.selectIDLeft === item.LookUpTableDetail ?
                                                                                { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                                        }
                                                                    >{item.LookUpTableDetail}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div
                                                className="uk-card-body uk-width-1-3"
                                                style={{
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    borderRight: '1px solid #9DA99E',
                                                    borderLeft: '1px solid #9DA99E',
                                                    height: 500
                                                }}
                                            >
                                                
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        justifyContent: 'center',
                                                        top: '40%'
                                                    }}>
                                                    <button
                                                        className="uk-button uk-button-green uk-margin-right" disabled={available}
                                                        onClick={this.handleAddClick.bind(this)}>
                                                        Add
                                                    </button>
                                                    <br></br>
                                                    <button
                                                    className="uk-button uk-button-green uk-margin-right uk-margin" disabled={available}
                                                    onClick={this.handleRemoveClick.bind(this)}>
                                                    Remove
                                                </button>
                                              
                                               </div>
                                            </div>
                                            <div
                                                className="uk-width-1-3"
                                                style={{
                                                    overflowY: 'scroll',
                                                    height: 500,
                                                    paddingLeft: 0,
                                                }}
                                            >
                                                <table
                                                    className="uk-table uk-table-hover uk-table-divider"
                                                    style={{
                                                        marginBottom: 0
                                                    }}
                                                >
                                                    <tbody>
                                                        {listRight.map((item, i) => {
                                                            return (
                                                                <tr
                                                                    key={item.SubEntityType} onClick={this.roleChangeColorRight(item.SubEntityType)}
                                                                    style={
                                                                        this.state.selectIDRight === item.SubEntityType ?
                                                                            { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                                    }
                                                                >
                                                                    <td
                                                                        style={
                                                                            this.state.selectIDRight === item.SubEntityType ?
                                                                                { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                                        }
                                                                    >{item.SubEntityType}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                {/* //------Available Subtypes----------- */}
                <div>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSaveClick} type="button">Save</button>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearClick} type="button">Clear Fields</button>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.showAdditionalFields} type="button" disabled={this.state.userPropertyId == 0}>View Additional fields</button>
                    <hr/>
                </div>
                <div className="uk-width-1 uk-margin">
                    <div className="uk-width-1-4 uk-form-stacked">
                        <label className="uk-form-label">SubEntityType</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect id="subEntityType-list" className={classNameSelect} getValue={this.handleUpdateSubEntityType.bind(this)} listHasPlaceholder={false} placeholder='Select an item...' data={this.state.subEntityTypeFilter} objValue={{ value: this.state.subtEntityType, name: this.state.subtEntityType }} />
                        </div>
                    </div>
                    {/* <h3 className="uk-heading-divider" style={style.titleStyle}>Existing Properties</h3> */}
                    <NewDynamicTable
                        id="properties-table"
                        data={this.state.allProperties}
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        useDeleteButton={true}
                        deleteFunction={this.handleDeleteClick}
                        useFilter={true}
                        filterFunction={this.getAllProperties}
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.currentPage}
                        numberPerPage={getDefaultPagingNumberOfItems()}
                        enableSortByHeader={true} 
                        useIcons={true}
                        hiddenHeaders={['Short Description']}/>
                    {/*<Paginator changePage={this.changePage} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch}/>*/}
                </div>
                <AdditionalPropertiesDialog
                        open={this.state.openPropertiesDialog}
                        onClose={this.onClosePropertiesDialog}
                        propertiesID={this.state.extUserPropertyID}
                        propertyName={this.state.description}
                        properties={{ _amountLimit: this.state.extAmountLimit, _mandatory: this.state.extMandatory, _attachmentsRequired: this.state.extAttachmentsRequired, _printable: this.state.extPrintable, _updateToken: this.state.extUpdateToken }}
                    />
                <AlertBox id="delete-property" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete this property?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                <AlertBox id="confirmation-delete-property" open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Property successfully removed." message="" type="Ok" okClick={this.okSuccessAlert} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} messageBoldList={this.state.listMissing} />
            </div>
        );
    }

}

