import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Space, Checkbox, Form, Input, InputNumber, Popconfirm, Select, Tag, Table, Typography, Switch, Tooltip } from 'antd';
import { PlusOutlined, SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import AlertBox from '../../shared/AlertBox';
import { getStyle } from '../../../styles/styles';
import AppContext from '../../../AppContext';
import { GetGlobalSystemParameters } from '../../../networking/NetworkingGlobalSystemParameters';
import { deleteRateTreasuryPadding, getRateTreasuryPadding, saveTreasuryPadding } from '../../../networking/NetworkingRates';
import { getCurrencyList } from '../../../networking/NetworkingCurrencies';

const { Option } = Select;
const { TextArea } = Input;

const style = getStyle();

const TreasuryPaddingTable = () => {
    const context = useRef(useContext(AppContext));
  
    const [treasuryPaddings, setTreasuryPaddings] = useState([]);
    const [treasuryPaddingID, setTreasuryPaddingID] = useState(0);
    const [form] = Form.useForm();
    const [updating, setUpdating] = useState(false); 
 
    //Add
    const [isEditableName, setIsEditableName] = useState(false);
    const [currentPage, setCurrentPage] = useState();

    //Edit
    const [editingKey, setEditingKey] = useState(0);
    const [currentType, setcurrentType] = useState('');
    const isEditing = (record) => record['ID'] === editingKey;
    const [currentTreasuryPaddinID, setCurrentTreasuryPaddinID] = useState(0);
    const [checkedMandatory, setCheckedMandatory] = useState(false);
    const [checkedCaseValueBoolean, setCheckedCaseValueBoolean] = useState(null);
    
    //Search
    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const [searchText, setSearchText] = useState('');
    const searchInput = useRef();

    const [currencyList, setCurrencyList] = useState([]);  

    //Alert
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(()=>{        
        const loadTreasuryPaddingList = async() =>{            
            context.current.startLoading();          
            const json = await getRateTreasuryPadding(0, 0);
            if (json != null && json.result != null) 
            {
            let auxGP = json.result;
            setTreasuryPaddings(auxGP);
            }
            context.current.finishLoading();
        }
        
        const loadCurrencyList = async() =>{
          const json = await getCurrencyList();
            if (json != null && json.currencies != null) 
            {
              let auxGP = json.currencies;
              setCurrencyList(auxGP);
            }
        }
        
        loadTreasuryPaddingList();
        loadCurrencyList();
     },[updating]);

    //Search
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setFilteringSomething(true);
    };
    
    const closeAlert = () => {
        setShowAlert(false);
    };

    const edit = (record) => {
        if (treasuryPaddings[0]['ID'] === 0) {
            let auxTreasuryPaddings = [...treasuryPaddings];
            auxTreasuryPaddings.shift();
            setTreasuryPaddings(auxTreasuryPaddings);
        }
        form.setFieldsValue({
          ...record
        });
        setEditingKey(record['ID']);
        setCurrentTreasuryPaddinID(record['ID']);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };

     const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref         = {(node) => {
                searchInput.current = node;
              }}
              placeholder = {`Search ${dataIndex}`}
              value       = {selectedKeys[0]}
              onChange    = {(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                width       : 188,
                marginBottom: 8,
                display     : 'block'
              }}
            />
            <Space>
              <Button 
                type    = "primary" 
                onClick = {() => handleSearch(selectedKeys, confirm, dataIndex)} 
                icon    = {<SearchOutlined />} 
                size    = "small" 
                style   = {{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                    handleReset(clearFilters);
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type    = "link"
                size    = "small"
                onClick = {() => {
                  confirm({ closeDropdown: false });
                  settingSearchStateValues(selectedKeys[0], dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current.select(), 100);
          }
        },
        render: (text) => (
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : ( text )
        )
      });

    const settingSearchStateValues = (value, col) => {
        setSearchText(value);
        setSearchedColumn(col);
        if(searchArray!==undefined && searchArray[col]!==undefined && value!==undefined){
          setSearchArray(...(searchArray[col] = value));
        }
        setFilteringSomething(true);
      };


    const save = async (key) => {
        try {
          const row = await form.validateFields();          
          let UserID = localStorage.getItem('UserID');
          
          const newData = [...treasuryPaddings];
          const index = newData.findIndex((item) => key === item.ID);
          
          if (index > -1) 
          {
            const item = newData[index];
            const token = item.UpdateToken;
            let model = {
              PaddingID:  editingKey === '' ? row['ID'] : editingKey,
              HomeCurrencyCode: row['Home Currency'],
              ForeignCurrencyCode: row['Foreign Currency'],
              MarkupFactor: row['Markup Factor'],
              PercentOrPoints: row['Percent or Points'],
              UserId: UserID,
              updateToken: token
            }  
            context.current.startLoading();
            const json = await saveTreasuryPadding(model);
            context.current.finishLoading();
            if (json != null) {
                if (json.httpStatusCode !== 200) {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.httpErrorMessage);
                    setUpdating(!updating);
                } else {
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Successfully saved.');
                    setUpdating(!updating);
                }
            }
            setEditingKey(0);
          }
          else
          {
            newData.push(row);
            setTreasuryPaddings(newData);
            setEditingKey(0);
          }

        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
        }
    };
  
    const deleteRecord = async (record) => {
      context.current.startLoading();
      let UserID = localStorage.getItem('UserID');        
      let model = {
        "PaddingID"           : record['ID'],
        "UserId"              : UserID,
        "UpdateToken"         : record['UpdateToken']
      }      
      const deleteResponse = await deleteRateTreasuryPadding(model);
      context.current.finishLoading();
      if (deleteResponse != null) {
          if (deleteResponse.httpStatusCode !== 200) {
              setShowAlert(true);
              setAlertTitle('Error');
              setAlertMessage(deleteResponse.httpErrorMessage);
              setUpdating(!updating);
          } else {
              setShowAlert(true);
              setAlertTitle('Success');
              setAlertMessage('Successfully removed.');
              setUpdating(!updating);
          }
      }
    }
    
  const cancelEdit = () => {
    setEditingKey(0);
    if(treasuryPaddings[0]['ID'] === 0)
    {
      let auxTreasuryPaddings = [...treasuryPaddings];
      auxTreasuryPaddings.shift();
      setTreasuryPaddings(auxTreasuryPaddings);
    }
    setTreasuryPaddingID(0);
  };

    const columns = [
        {
          title     : 'Home Currency',
          dataIndex : 'Home Currency',
          width     : '20%',
          editable  : true,
          sorter    : (a, b) => {
            return a['Home Currency'].localeCompare(b['Home Currency']);
          },
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('Home Currency')
        },
        {
          title     : 'Foreign Currency',
          dataIndex : 'Foreign Currency',
          width     : '20%',
          editable  : true,
          sorter    : (a, b) => {
            return a['Foreign Currency'].localeCompare(b['Foreign Currency']);
          },
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('Foreign Currency')
        },        
        {
          title     : 'Markup Factor',
          dataIndex : 'Markup Factor',
          width     : '20%',
          editable  : true,
          sorter    : (a, b) => {
            return a['Markup Factor'].localeCompare(b['Markup Factor']);
          },
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('Markup Factor')
        },
        {
          title     : 'Percent or Points',
          dataIndex : 'Percent or Points',
          width     : '30%',
          editable  : true,
          sorter    : (a, b) => {
            return a['Percent or Points'].localeCompare(b['Percent or Points']);
          },
          sortDirections: ['descend', 'ascend'],
          ...getColumnSearchProps('Percent or Points')
        },
        {
          title     : 'Actions',
          dataIndex : 'Actions',
          align     : 'center',
          width     : '10%',
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
              <span>
                <a
                  onClick = {() => {save(record['ID'])}}
                  style   = {{
                    marginRight: 8,
                  }}
                >Save
                </a>
                <Popconfirm title="Sure to cancel?" onConfirm={cancelEdit}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <div>
                <Space size="middle">
                <Tooltip title="Edit">
                  <Typography.Link disabled = {editingKey !== 0 ? true: (treasuryPaddings[0]['ID'] === 0) ? true: false}
                    onClick  = {() => edit(record)} >
                    <EditOutlined />
                  </Typography.Link>
                </Tooltip>
                  <Popconfirm title = "Sure to delete?" onConfirm = {() => {deleteRecord(record)}}>
                  <Tooltip title="Delete">
                    <a disabled = {editingKey !== 0 ? true: (treasuryPaddings[0]['Id'] === 0) ? true: false} ><DeleteOutlined /></a>
                    </Tooltip>
                  </Popconfirm>
                </Space>
              </div>            
            );
          },
        },
      ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        const getType = (record) => {
          let type = '';
          switch (col.dataIndex) 
          {
            case 'Markup Factor':
            {
              type = 'number';
              break;
            }
            case '2':
            {
              type = 'check';
              break;
            }
            case 'Home Currency':
            case 'Foreign Currency':
            {
              type = 'select-currencies';
              break;
            }
            case 'Percent or Points':
            {
              type = 'select-percent-points';
              break;
            }
            case '5':
            {
              type = 'adaptable'
              break;
            }
            default:
            {
                type = 'text'
                break;
            }
          }
          return type;
        }
    
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: getType(record),
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });
          
    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {  
        let inputNode = null;  
        if(editing)
        {
        switch (inputType) 
        {
            case 'number':
            {
                inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center'}}/>;
                break;
            }
            case 'select':
            {
                let allOptions = [];
        
                if(Array.isArray(record.lookupdetails))
                {
                record.lookupdetails.forEach((lookupdetail, index) => {
                    allOptions.push(
                    <Option 
                        key   = {index + lookupdetail['Description']} 
                        value = {lookupdetail['Description']}
                    >{lookupdetail['Description']}
                    </Option>
                    );
                });
                }
        
                inputNode = (
                <Select
                    showSearch
                    style               = {{ width: '100%' }}
                    placeholder         = "Select..."
                >
                    {allOptions}
                </Select>
                );
                break;
            }            
            case 'select-currencies':
            {
                let allOptions = [];
        
                currencyList.forEach((valueList,index)=>{
                  allOptions.push(
                    <Option key = {valueList['CurrencyID']} value = {valueList['CurrencyID']}>{valueList['CurrencyID']}</Option>
                  );
                });

                inputNode = (
                <Select
                    showSearch
                    style               = {{ width: '100%' }}
                    placeholder         = "Select..."
                >
                    {allOptions}
                </Select>
                );
                break;
            }
            case 'select-percent-points':
            {
                let allOptions = [];
                allOptions.push(
                  <Option 
                        key   = 'PERCENT'
                        value = 'PERCENT'
                    >PERCENT</Option>
                )
                allOptions.push(
                  <Option 
                        key   = 'POINTS'
                        value = 'POINTS'
                    >POINTS</Option>
                )        
                inputNode = (
                <Select
                    showSearch
                    style               = {{ width: '100%' }}
                    placeholder         = "Select..."
                >
                    {allOptions}
                </Select>
                );
                break;
            }
            case 'textarea':
            {
                inputNode = <TextArea />;
                break;
            }
            case 'adaptable':
            {
                if(record.LookUpTableID !== undefined && record.LookUpTableID !== null && record.LookUpTableID !== '') 
                {
                let allOptions = [];
        
                if(Array.isArray(record.lookupdetails))
                {
                    record.lookupdetails.forEach((lookupdetail, index) => {
                    allOptions.push(
                        <Option 
                        key   = {index + lookupdetail['Description']} 
                        value = {lookupdetail['Description']}
                        >{lookupdetail['Description']}
                        </Option>
                    );
                    });
                }
        
                inputNode = (
                    <Select
                        showSearch
                        style               = {{ width: '100%' }}
                        placeholder         = "Select..."
                    >
                    {allOptions}
                    </Select>
                );
                }else
                {
                let whatSwitch = '';
                whatSwitch = currentType === '' ? record['Data Type'] : currentType
                switch (whatSwitch) 
                {
                    case 'D':
                    {
                    inputNode = <Input style={{textAlign: 'center'}}/>;
                    break;
                    }
                    case 'I':
                    case 'G':
                    case 'C':
                    case 'T':
                    {
                    inputNode = <Input style={{textAlign: 'center'}}/>;
                    break;
                    }
                    case 'N':
                    {
                    inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center'}}/>;
                    break;
                    }
                    case 'B':
                    {
                    if(checkedCaseValueBoolean === null)
                    {
                        if(record['Parameter Value'] === 'true')
                        {
                        form.setFieldsValue({
                            'Parameter Value' : true,
                        });
                        setCheckedCaseValueBoolean(true);
                        }else
                        {
                        setCheckedCaseValueBoolean(false);
                        }
                    }

                    inputNode = <Checkbox
                        checked   = {checkedCaseValueBoolean}
                        onChange  = {(e)=>{
                        form.setFieldsValue({
                            'Parameter Value' : e.target.checked,
                        });
                        setCheckedCaseValueBoolean(e.target.checked);
                        }}
                        ></Checkbox>;
                    break;
                    }
                }
                }
                break;
            }
            default:
            {
                inputNode = <Input style={{textAlign: 'center'}}/>;
                break;
            }
        }
        }

        let auxRules = [];

        if(dataIndex !== 'LookUpTableID')
        {
        auxRules = [
            {
            required: true,
            message : `Please Input ${title}!`,
            },
        ]
        }
  
        return (
        <td 
            key = {inputType + dataIndex} 
            id  = {inputType + dataIndex}
            {...restProps}
        >
            {editing ? (
            <Form.Item
                name  = {dataIndex}
                rules = {auxRules}
                style = {{
                margin: 0,
                }}
            >{inputNode}
            </Form.Item>
            ) : children
            }
        </td>
        );
    };
    
    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
        return <a>Previous</a>;
        }
        if (type === 'next') {
        return <a>Next</a>;
        }
        return originalElement;
    }
    
    const onChangePagination = (page, pageSize) => {
        setEditingKey(0);
        if(typeof page === 'number')
        {
        setCurrentPage(page);
        }
    };

    
    const addNewRow = () => {
        let actual = [...treasuryPaddings];
        if (actual[0] != null && actual[0]['ID'] === 0) {
            return;
        } else {
            actual.unshift({
                'ID': 0,
                'Home Currency': '',
                'Foreign Currency': '',
                'Markup Factor': '',
                'Percent or Points': '',
                isNew: true
            });
            setTreasuryPaddings(actual);
            setCurrentPage(1);
            form.setFieldsValue({
              'Home Currency': '',
              'Foreign Currency': '',
              'Markup Factor': '',
              'Percent or Points': '',
                ...actual
            });
            setCurrentTreasuryPaddinID(0);
        }
    }

    return(
     <div>         
        <Form form={form} component={false}>
            <Table
                components   = {{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                bordered
                rowClassName = "editable-row"
                size         = 'small'
                dataSource   = {treasuryPaddings}
                columns      = {mergedColumns}
                pagination   = {{
                  onChange        : onChangePagination,
                  showQuickJumper : true,
                  itemRender      : itemRender,
                  current         : currentPage,
                  defaultCurrent  : 1,
                  defaultPageSize : 15,
                  pageSizeOptions : [10, 15, 20, 30, 40, 50],
                  size            : 'default'
                }}
                footer          = {() => {
                  return(
                    <Button 
                      type        = "dashed" 
                      onClick     = {addNewRow}
                      block
                      icon        = {<PlusOutlined />}
                      style       = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                      disabled    = {
                        editingKey !== 0 || filteringSomething || (treasuryPaddings[0] !== undefined && treasuryPaddings[0]['ID'] === 0)                              
                      }
                    >Add a Treasury Padding
                    </Button>
                  );
                }}  
            />
        </Form>        
        <AlertBox id="alert-new-bankingStandard" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
     </div>
    );
}

export default TreasuryPaddingTable;