import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MaterialDialogTitle from "../../shared/Dialogs/MaterialDialogTitle";
import { DialogActions, DialogContent } from '@material-ui/core';
import NewEditableGrid from '../../shared/NewEditableGrid';
import { formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import { BorderTop } from '@material-ui/icons';

const styles = ({
    amounts: {
        textAlign: 'right',
        paddingRight: '2rem'
    },
    hrResult: {
        borderTop: '1px solid'
    }
});

const DealBalanceInfo = (props) => {
    
    const { open, closeDialog, balances, dealHeaderInfoProp } = props

    const [data, setData] = useState([])
    const [totalPaid, setTotalPaid] = useState(0)

    useEffect(() => {
        if (balances.DetailsJson !== undefined) {
            setData(balances.DetailsJson)
            let tPaid = 0
            if (Array.isArray(balances.DetailsJson)) {                                
                tPaid = balances.DetailsJson.map(x => Number(x['Paid Amount'])).reduce((acc, x) => acc + x, 0)                
            }
            setTotalPaid(-1 * tPaid)
        } else {
            setData([])
        }
    }, [balances.DetailsJson])

    return <Dialog
        open={open}
        onClose={closeDialog}
        fullWidth={true}
        maxWidth={"md"}
    >
        <MaterialDialogTitle>Deal Balance Details</MaterialDialogTitle>
        <DialogContent dividers>
            <NewEditableGrid
                key="dealbalanceinfo-data"
                data={data}
                isEditable={false}
                useIcons={false}
            />
            <div className="uk-grid">
                <div className="uk-width-1-2"></div>
                <div className="uk-width-1-4">
                    <h4 className="h4-deal">Cover Sell Amount</h4>
                </div>
                <div className="uk-width-1-4">
                    <h4 style={styles.amounts}>{formatToCurrencyWithScale(dealHeaderInfoProp.SellAmount, dealHeaderInfoProp.SellCurrency)}  {dealHeaderInfoProp.SellCurrency}</h4>
                </div>
                <div className="uk-width-1-2"></div>
                <div className="uk-width-1-4">
                    <h4 className="h4-deal">Total Paid</h4>
                </div>
                <div className="uk-width-1-4">
                    <h4 style={styles.amounts}>{formatToCurrencyWithScale(totalPaid, dealHeaderInfoProp.SellCurrency)}  {dealHeaderInfoProp.SellCurrency}</h4>
                </div>
                <div className="uk-width-1-2"></div>
                <div className="uk-width-1-2"><hr style={styles.hrResult} /></div>
                <div className="uk-width-1-2"></div>
                <div className="uk-width-1-4">
                    <h4 className="h4-deal">Cover Net Balance</h4>
                </div>
                <div className="uk-width-1-4">
                    <h4 style={styles.amounts}>{formatToCurrencyWithScale(balances.BalanceToDisbursed, dealHeaderInfoProp.SellCurrency)}  {dealHeaderInfoProp.SellCurrency}</h4>
                </div>
            </div>
        </DialogContent>
        <DialogActions >
            <button className="uk-button uk-button-green" onClick={closeDialog}>
                Close
            </button>
        </DialogActions>
    </Dialog>

}

export default DealBalanceInfo;