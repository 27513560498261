import { genericCallWithBody, genericGetWithParameters, genericGetWithParametersNoEncrypted } from "./Networking";

export async function saveEvent(model){ 
    let response = await genericCallWithBody('POST','Scheduler/Post',model);
    return response;
}

export const getListOfEvents = async (startDate, endDate) => {
    try {
        const params = {
            'PersonID': localStorage.getItem('UserID'),
            'StartDate':startDate,
            'EndDate': endDate
        };
        const response = await genericGetWithParameters('Scheduler/GetListOfEvents', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getEventByID = async (eventID) => {
    try {
        const params = {
            'eventID': eventID
        };
        const response = await genericGetWithParameters('Scheduler/GetEventByID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function snoozeEvent(eventID, snoozeFor){
    try {
        let params = {
            'eventID': eventID,
            'snoozeFor': snoozeFor
        }

        let response = await genericGetWithParametersNoEncrypted('Scheduler/SnoozeEvent',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function dismissEvent(eventID){
    try {
        let params = {
            'eventID': eventID
        }

        let response = await genericGetWithParametersNoEncrypted('Scheduler/DismissEvent',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}