import React, { useContext, useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { getAllFees, deleteFee, addFee } from "../../../networking/NetworkingFees"
import { getLookUpTablesDescription} from "../../../networking/NetworkingLookUpTables"
import { Breadcrumb, Button, Form, Input, InputNumber, Popconfirm, Select, Space, Table, Typography, Checkbox } from 'antd';
import { getStyle } from "../../../styles/styles";
import AppContext from '../../../AppContext';
import AlertBox from '../../shared/AlertBox';
import { SearchOutlined } from '@material-ui/icons';
import Highlighter from 'react-highlight-words';
import Text from 'antd/lib/typography/Text';
import { roundAmountByCurrency } from '../../../helpers/FormatHelper';
import NewEditableGrid from '../../shared/NewEditableGrid'

const style = getStyle();
const { Option } = Select;
const NewFeeMaintenanceScreen = () => {
    const context = useRef(useContext(AppContext));
    const [form] = Form.useForm();
    const [fees, setFees] = useState([]);
    const [chargeToItems, setChargeToItems] = useState([]);
    const [displayInItems, setDisplayInItems] = useState([]);
    const [reloadFees, setReloadFees] = useState(false);
    
    const [editingKey, setEditingKey] = useState(0);
    const [currentPage, setCurrentPage] = useState();
    
    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const searchInput = useRef();

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    
    const [currentFeeID, setCurrentFeeID] = useState(0);
    const [currentUpdateToken, setCurrentUpdateToken] = useState('');
    const [columnDetail, setColumnDetail] = useState({});

    useEffect(() =>{
        const loadFees = async () => {
            context.current.startLoading();
            const json = await getAllFees('', 0, 0);
            context.current.finishLoading();
            if(json !== null && json !== undefined && json.totalCount > 0){
                setFees(json.fees);
                setColumnDetail(JSON.parse(json.columnDetail))
            } else {
                setFees([])
            }
        };
        loadFees();
    },[reloadFees]);

    useEffect(() =>{
        const loadChargeToItems = async () => {
            context.current.startLoading();
            const json = await getLookUpTablesDescription('ChargeTo');
            context.current.finishLoading();
            if (json != null && json.lookUpTables !== undefined && json.lookUpTables != null) {
                if(Array.isArray(json.lookUpTables))
                {
                    var objLookUpTable = json.lookUpTables[0].lookUpTableDetails
                    if(Array.isArray(objLookUpTable))
                    {
                        var itemList = []
                        objLookUpTable.forEach(element => {
                            var elementList = {
                                value   : element.LookUpTableDetail,
                                name    : element.LookUpTableDetail
                            }
                            itemList.push(elementList)
                        });
                        setChargeToItems(itemList);
                    }
                }
            }
        }
        loadChargeToItems();
    },[]);

    useEffect(() =>{
        const loadDisplayInItems = async () => {
            context.current.startLoading();
            const json = await getLookUpTablesDescription('Display In');
            context.current.finishLoading();
            if (json != null && json.lookUpTables != null) {
                var objLookUpTable = json.lookUpTables[0].lookUpTableDetails
                if(Array.isArray(objLookUpTable))
                {
                    var itemList = []
                    objLookUpTable.forEach(element => {
                        var elementList = {
                            value   : element.LookUpTableDetail,
                            name    : element.LookUpTableDetail
                        }
                        itemList.push(elementList)
                    });
                    setDisplayInItems(itemList);
                }
            }
        }
        loadDisplayInItems();
    },[]);

    const isEditing = (record) => record.ID === editingKey;

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setFilteringSomething(true);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };

    const settingSearchStateValues = (value, col) => {
        if (value != null) {
            setSearchText(value);
            setSearchedColumn(col);
            setSearchArray(...(searchArray[col] = value));
            setFilteringSomething(true);
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput.current = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(clearFilters);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            settingSearchStateValues(selectedKeys[0], dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const save = async (record) => {
        try {
            const row = await form.validateFields();
            const newData = [...fees];
            const index = newData.findIndex((item) => record['currentFeeID'] === item['currentFeeID']);
            if (index > -1) {
                const userID = localStorage.getItem('UserID');
                const allowNegative = row['Allow Negative'] === 'True';
                const readOnly = row['Read Only'] === 'True';
                const loss = row['Loss'] === 'True';
                const commission = row['Commission'] === 'True';
                const model = {
                    FeeStructureId: currentFeeID,
                    Description: row['Description'],
                    CostPerUnit: 0,
                    Unit: 0,
                    ChargeTo: row['Charge To'],
                    DefaultAmount: row['Default Amount'],
                    LossIndicator: loss,
                    CommissionIndicator: commission,
                    AllowNegative: allowNegative,
                    DisplayIn: row['Display In'],
                    ReadOnly: readOnly,
                    UserId: userID,
                    UpdateToken: currentUpdateToken,
                    AgentCustomerID: 0
                }
                context.current.startLoading();
                const json = await addFee(model);
                context.current.finishLoading();
                if (json != null){
                    if (json.httpStatusCode !== 200){
                        console.log(json)
                        setShowAlert(true);
                        setAlertTitle('Error');
                        if (json.Message != null && json.Message !== '') {
                            setAlertMessage(json.Message);
                        } else {
                            setAlertMessage(json.httpErrorMessage);
                        }
                        setReloadFees(!reloadFees);
                        setEditingKey(0);
                    } else {
                        setShowAlert(true);
                        setAlertTitle('Success');
                        setAlertMessage('Fee successfully saved.');
                        setReloadFees(!reloadFees);
                        setEditingKey(0);
                        setCurrentFeeID(json.feeStructureId);
                        setCurrentUpdateToken(json.updateToken);
                        //onAddOrDeleteSpotPricing();
                    }
                }
            }

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }

    const deleteRecord = async (record) => {
        context.current.startLoading();
        var json = await deleteFee(record['ID']);
        context.current.finishLoading();
        if(json !== null){
            if (json.httpStatusCode !== 200){
                setShowAlert(true);
                setAlertTitle('Error');
                if (json.Message != null && json.Message !== '') {
                    setAlertMessage(json.Message);
                } else {
                    setAlertMessage(json.httpErrorMessage);
                }
                setReloadFees(!reloadFees);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Fee successfully removed.');
                setReloadFees(!reloadFees);
            }
        }
    }

    const columns = [
        {
            title: 'Description',
            dataIndex: 'Description',
            width: '20%',
            editable: true,
            align: 'left',
            sorter: (a, b) => {
                return a['Description'].localeCompare(b['Description']);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Default Amount',
            dataIndex: 'Default Amount',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => a['Default Amount'] - b['Default Amount'],
            sortDirections: ['descend', 'ascend'],
            render: (_, record) => <Text>{roundAmountByCurrency(record['Default Amount'])}</Text>
        },
        {
            title: 'Charge To',
            dataIndex: 'Charge To',
            width: '10%',
            editable: true,
            align: 'left',
            sorter: (a, b) => {
                return a['Charge To'].localeCompare(b['Charge To']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Charge To')
        },
        {
            title: 'Loss',
            dataIndex: 'Loss',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Loss']?.toString().localeCompare(b['Loss']) ?? -100;
            },
            sortDirections: ['descend', 'ascend'],
            render: (_, record) => <Text>{record['Loss'] ? 'Yes' : 'No'}</Text>
        },
        {
            title: 'Commission',
            dataIndex: 'Commission',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Commission']?.toString().localeCompare(b['Commission']) ?? -100;
            },
            sortDirections: ['descend', 'ascend'],
            render: (_, record) => <Text>{record['Commission'] ? 'Yes' : 'No'}</Text>
        },
        {
            title: 'Allow Negative',
            dataIndex: 'Allow Negative',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Allow Negative']?.toString().localeCompare(b['Allow Negative']) ?? -100;
            },
            sortDirections: ['descend', 'ascend'],
            render: (_, record) => <Text>{record['Allow Negative'] ? 'Yes' : 'No'}</Text>
        },
        {
            title: 'Display In',
            dataIndex: 'Display In',
            width: '10%',
            editable: true,
            align: 'left',
            sorter: (a, b) => {
                return a['Display In'].localeCompare(b['Display In']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Display In')
        },
        {
            title: 'Read Only',
            dataIndex: 'Read Only',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                if (a === null) {
                    return -100;
                }
                return a['Read Only']?.toString().localeCompare(b['Read Only']) ?? -100;
            },
            sortDirections: ['descend', 'ascend'],
            render: (_, record) => <Text>{record['Read Only'] ? 'Yes' : 'No'}</Text>
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a href onClick={() => save(record.ID)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a href>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Typography.Link disabled={editingKey !== 0 || fees[0]['ID'] === 0} onClick={() => edit(record)}>
                                Edit
                            </Typography.Link>
                            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record)}>
                                <a href disabled={editingKey !== 0 || fees[0]['ID'] === 0}>Delete</a>
                            </Popconfirm>
                        </Space>
                    </div>
                );
            }
        }
    ]

    const edit = (record) => {
        if (fees[0].ID === '') {
            let auxFee = [...fees];
            auxFee.shift();
            setFees(auxFee);
        }

        form.setFieldsValue({
            ...record,
            UpdateToken: record['$UpdateToken'],
            Loss: record.Loss ? 'True': 'False',
            Commission: record.Commission ? 'True': 'False',
            'Allow Negative': record['Allow Negative'] ? 'True': 'False',
            'Read Only': record['Read Only'] ? 'True': 'False',

        });
        setEditingKey(record.ID);
        setCurrentFeeID(record.ID);
        setCurrentUpdateToken(record['$UpdateToken']);
    }

    const cancel = (page) => {
        setEditingKey(0);
        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        if (fees[0].ID === 0) {
            let auxFees = [...fees];
            auxFees.shift();
            setFees(auxFees);
        }
    }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        let type = '';

        switch (col.dataIndex) {
            case 'Default Amount':{
                type = 'number';
                break;
            }
            case 'Allow Negative':
            case 'Read Only': 
            case 'Loss':
            case 'Commission':{
                type = 'check';
                break;
            }
            case 'ChargeTo':
            case 'DisplayIn':
            case 'Charge To':
            case 'Display In': {
                type = 'select';
                break;
            }
            default: {
                type = 'text';
                break;
            }
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let inputNode = null;
        switch (inputType) {
            case 'number': {
                inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center' }} precision={2} />;
                break;
            }
            case 'check': {
                inputNode = <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select an Option"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    <Option key='Fee-option-Yes' value={'True'}>Yes</Option>,
                    <Option key='Fee-option-No' value={'False'}>No</Option>
                </Select>
                break;
            }
            case 'select': {
                switch (title){
                    default:
                    case 'Charge To':
                    case 'ChargeTo': {
                        inputNode = (
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select an Item"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {chargeToItems.map((item) => (
                                    <Option 
                                        key={`ChargeTo-options-${item.value}`}
                                        value={item.value}>
                                    </Option>
                                ))}
                            </Select>
                        );
                        break;
                    }
                    case 'Display In':
                    case 'DisplayIn': {
                        inputNode = (
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select an Item"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {displayInItems.map((item) => (
                                    <Option 
                                        key={`DisplayIn-options-${item.value}`}
                                        value={item.value}>
                                    </Option>
                                ))}
                            </Select>
                        );
                        break;
                    }
                }
                break;
            }
            default: {
                inputNode = <Input style={{ textAlign: 'center' }}/>;
                break;
            }
        }
        
        let styleEdit = { margin: 0 };

        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        const getCustomRules = (columnDataIndex) => {
            switch (columnDataIndex) {
                case 'Default Amount': {
                    return [
                        {
                            min: 0,
                            type: 'number',
                            mesage: 'Please Input a valid number!'
                        }
                    ];
                }
                case 'Charge To':
                case 'Display In': {
                    return [
                        {
                            required: true,
                            message: `Please select an option!`,
                            transform: (value) => {
                                return value != null && value.toLowerCase().includes('select a') ? '' : value;
                            }
                        },
                    ];
                }
                default:
                    return [
                        {
                            required: true,
                            message: `Please Input ${title}!`
                        },
                    ];
            }
        }
        const rules = getCustomRules(dataIndex);
        
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={styleEdit}
                        rules={rules}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    }

    const addNewFeeMaintenance = () => {
        let actual = [...fees];
        if (actual[0] != null && actual[0]['ID'] === 0) {
            return;
        } else {
            actual.unshift({
                'key': 'Fees-new',
                'ID': 0,
                'Description': '',
                'Default Amount': 0,
                'Charge To': '',
                'Loss': 'No',
                'Commission': 'No',
                'Allow Negative': 'No',
                'Display In': '',
                'Read Only': 'No',
                UpdateToken: '',
                isNew: true
            });
            setFees(actual);
            setCurrentPage(1);
            form.setFieldsValue({
                'Description': '',
                'Default Amount': 0,
                'Charge To': '',
                'Loss': 'No',
                'Commission': 'No',
                'Allow Negative': 'No',
                'Display In': '',
                'Read Only': 'No',
                ...actual
            });
            setCurrentUpdateToken('');
            setCurrentFeeID(0);
        }
    }

    const closeAlert = () => {
        setShowAlert(false);
    };

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a href>Previous</a>;
        }
        if (type === 'next') {
            return <a href>Next</a>;
        }
        return originalElement;
    }
    
    const EditSaveFunctionNewFeeMaintenance = async (record, row) => {
        const userID = localStorage.getItem('UserID');

        //FeeStructureId: currentFeeID,
        const model = {            
            FeeStructureId: record.ID,
            Description: row['Description'],
            DefaultAmount: row['Default Amount'],
            ChargeTo: row['Charge To'],
            LossIndicator: row['Loss'],
            CommissionIndicator: row['Commission'],
            AllowNegative: row['Allow Negative'],
            DisplayIn: row['Display In'],
            ReadOnly: row['Read Only'],
            UpdateToken: record.UpdateToken,

            CostPerUnit: 0,
            Unit: 0,
            UserId: userID,
            AgentCustomerID: 0
        }
        
        let json;
        context.current.startLoading();
        json = await addFee(model);
        context.current.finishLoading();
        setReloadFees(!reloadFees);
    }

    const AddSaveFunctionNewFeeMaintenance = async (record, row) => {
        const userID = localStorage.getItem('UserID');

        //FeeStructureId: currentFeeID,
        const model = {
            Description: row['Description'],
            DefaultAmount: row['Default Amount'],
            ChargeTo: row['Charge To'],
            LossIndicator: row['Loss'],
            CommissionIndicator: row['Commission'],
            AllowNegative: row['Allow Negative'],
            DisplayIn: row['Display In'],
            ReadOnly: row['Read Only'],
            UpdateToken: row['UpdateToken'],

            CostPerUnit: 0,
            Unit: 0,
            UserId: userID,
            AgentCustomerID: 0
        }
        
        let json;
        context.current.startLoading();
        json = await addFee(model);
        context.current.finishLoading();
        setReloadFees(!reloadFees);
    }
    
    const deleteFunctionNewFeeMaintenance = async (record, row) => {
        context.current.startLoading();
        var json = await deleteFee(record.ID);
        context.current.finishLoading();
        
        if(json !== null){
            if (json.httpStatusCode !== 200){
                setShowAlert(true);
                setAlertTitle('Error');
                if (json.Message != null && json.Message !== '') {
                    setAlertMessage(json.Message);
                } else {
                    setAlertMessage(json.httpErrorMessage);
                }
                setReloadFees(!reloadFees);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Fee successfully removed.');
                setReloadFees(!reloadFees);
            }
        }
    }

    return (
        <div>
            <div className="breadcrumb-div">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className="breadcrumb-item-bold">Fee Maintenance</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div>                
                <NewEditableGrid
                        data={fees}
                        columnDetails={columnDetail}
                        isEditable={true}
                        editSaveFunction={EditSaveFunctionNewFeeMaintenance}
                        canAdd={true}
                        addSaveFunction={AddSaveFunctionNewFeeMaintenance}
                        useDeleteButton={true}
                        deleteFunction={deleteFunctionNewFeeMaintenance}
                        useExportButton={true}
                        tableName="FeeMaintenance"
                        useIcons={true} />
            </div>
            {/*<Form form={form} component={false}>
            <Table
                components={{ body: { cell: EditableCell } }} 
                dataSource={fees}
                columns={mergedColumns}
                size='small'
                pagination={{
                    onChange: cancel,
                    showQuickJumper: true,
                    itemRender: itemRender,
                    current: currentPage,
                    defaultCurrent: 1,
                    size: 'default'
                }}
                footer={() => (
                    <Button
                        type="dashed"
                        onClick={addNewFeeMaintenance}
                        block
                        icon={<PlusOutlined />}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        disabled={editingKey !== 0 || (fees[0] !== undefined && fees[0]['ID'] === 0)}
                    > Add a Fee
                    </Button>
                )}
            />
                </Form>*/}
            <AlertBox id="alert-pricing" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    )
}

export default NewFeeMaintenanceScreen