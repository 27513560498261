import React from 'react';
import AlertBox from '../../shared/AlertBox';
import DynamicSelect from '../../shared/DynamicSelect';
import DatePicker from '../../shared/DatePicker';
import LoadingBox from '../../shared/LoadingBox';
import NewDynamicTable from '../../shared/NewDynamicTable';
import Paginator from '../../shared/Paginator';
import NewEditableGrid from '../../shared/NewEditableGrid'

import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames'
import { getLookUpTable } from '../../../networking/Networking';
import { validateRecordInFilter } from '../../../networking/NetworkingLookUpTables';
import { getSTPDeals, acceptCreditApproval, declineCreditApproval } from '../../../networking/NetworkingDeals';
import { getParametersByName } from '../../../networking/NetworkingGlobalSystemParameters';

import { formatDate } from '../../../helpers/FormatHelper';
import { getStyle, regularInputClass, notFilledInputClass } from '../../../styles/styles';
import { formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { validate } from '../../../helpers/ValidateHelper';
var style = getStyle();
const customerName = window.REACT_APP_CUSTOMER_NAME;

export default class CreditApprovalScreen extends React.Component {
    state = {
        creditApproval: {
            DealHeaderID: 0,
            StpComments: '',
            FromDate: '',
            ToDate: '',
            SearchBy: 'IssueDate',
            CrediStatus: 'Requires Approval'
        },
        validations: {
            comments: true,
        },
        dropdowns: {
            'Deal Credit Status': []},
        lookUpTables: [
            'Deal Credit Status'],
        alertTitle: '',
        alertMessage: '',
        buyAmount: 0,
        controlsVisibility: "hidden",
        customerName: '',
        isTradeDate: true,
        isValueDate: false,
        loading: false,
        newSearch: false,
        sellAmount: '',
        showAlert: false,
        stpDeals: [],
        totalPages: 0,
        currentDealCreditStatus: '',
        columnDetail: {},
        baseCurrency: localStorage.getItem(GlobalSystemParametersNames.BaseCurrency)
    }
    
    closeAlert() {
        this.setState({ showAlert: false });
    }
    
    validateRequiredFields() {
        const fields = [
            { displayName: 'Comments', validateName: 'comments', value: this.state.creditApproval.StpComments, type: 'string' }
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({ validations: { ...this.state.validations, ...validations } });

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }

    componentDidMount(){        
        let systemDate = localStorage.getItem(GlobalSystemParametersNames.SystemDate);
        this.setState({creditApproval: {...this.state.creditApproval, FromDate: systemDate, ToDate: systemDate}});

        var defaultPaymentInstrument = '';
        var defaultDeliveryMethod = '';
        getParametersByName('DefaultImportPaymentInstrument').then(
            (value) => {
                defaultPaymentInstrument = value.parameters[0].Value;
                getParametersByName('DefaultImportSettlementDeliveryMethod').then(
                    (value) => {
                        defaultDeliveryMethod = value.parameters[0].Value;
                        validateRecordInFilter('Incoming Instruments', defaultPaymentInstrument, defaultDeliveryMethod).then(
                            (value) => {
                                if(!value){
                                    this.setState({showAlert: true, alertTitle: 'Error', alertMessage: 'Default Import Payment Delivery Method does not belong to the Default Import Payment Instrument'});
                                } else {
                                    getLookUpTable(this.state.lookUpTables.toString()).then((json) => 
                                    {
                                        for (var i = 0; i < this.state.lookUpTables.length; i++)
                                        {
                                            let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                                            if (index > -1)
                                            {
                                                var table = json.lookUpTables[index].lookUpTableDetails;
                                                for (var j = 0; j < table.length; j++)
                                                {
                                                    table[j].value = table[j].LookUpTableDetail;
                                                    table[j].name = table[j].LookUpTableDetail;
                                                }
                                                this.setState({[this.state.lookUpTables[i]]: table});
                                                this.setState({ dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                                            }
                                        }
                                    });
                            
                                    this.loadSTPDeals(systemDate, systemDate, 'IssueDate', 'Requires Approval');
                                }
                            }
                        );
                    }
                );
            }
        );
    }

    loadSTPDeals(fromDate, toDate, searchBy, dealCreditStatus){
        getSTPDeals(fromDate, toDate, searchBy, dealCreditStatus, 1, getDefaultPagingNumberOfItems()).then(
            (value) =>{
                //alert(JSON.stringify(value));
                var stpDeals = [];
                if(value.deals !== undefined){
                    stpDeals = value.deals;
                }
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                
                this.setState({ 
                    loading: false ,
                    newSearch: false,
                    stpDeals: stpDeals, 
                    totalPages: pages,
                    columnDetail: JSON.parse(value.columnDetail)
                });
            }
        );
    }
    
    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    handleUpdateFromDate(date){
        if(date!==undefined){
            this.setState({creditApproval:{...this.state.creditApproval, FromDate: formatDate(date)}});
        }else{
            this.setState({creditApproval:{...this.state.creditApproval, FromDate: ''}});
        }
    }
    
    handleUpdateToDate(date){
        if(date!==undefined){
            this.setState({creditApproval:{...this.state.creditApproval, ToDate: formatDate(date)}});
        }else{
            this.setState({creditApproval:{...this.state.creditApproval, ToDate: ''}});
        }
    }

    handleCheckDate(event) {
        this.setState({
            creditApproval: { ...this.state.creditApproval, SearchBy: event.target.value }
        });
    }

    handleDealCreditStatus(value){
        if (value !== undefined && value.value !== undefined && value.value !== "select") {
            this.setState({
                creditApproval: { ...this.state.creditApproval, CrediStatus: value.value }
            });
        }
    }

    handleComments = (event) => {
        this.setState({
            creditApproval: { ...this.state.creditApproval, StpComments: event.target.value },
            validations: { ...this.state.validations, comments: true }
        });
    }

    handleSearchClick = async(event)=>{
        this.loadSTPDeals(this.state.creditApproval.FromDate, this.state.creditApproval.ToDate, this.state.creditApproval.SearchBy, this.state.creditApproval.CrediStatus);
    }
    
    changePage(value) {
        this.setState({ currentPage: value });
    }
    /*
    handleRowClick(obj) {
        console.group("CreditApproval handleRowClick")
        console.log(obj);
        console.groupEnd();
        if(obj["Deal Number"] !== undefined)
        {            
            this.setState({
                creditApproval: { ...this.state.creditApproval, DealHeaderID: obj["Deal Number"] },
                customerName: obj["Customer Name"], 
                buyAmount: obj["Buy Amount"], 
                sellAmount: obj["Sell Amount"], 
                controlsVisibility: 'visible',
                currentDealCreditStatus: obj['Deal Credit Status$'],
            });
        }
    }
    */
    handleRowClick2(obj){
        console.log(obj)
        if(obj["Deal Number"] !== undefined)
            {            
                this.setState({
                    creditApproval: { ...this.state.creditApproval, DealHeaderID: obj["Deal Number"] },
                    customerName: obj["Customer Name"], 
                    buyAmount: obj["Buy Amount"], 
                    sellAmount: obj["Sell Amount"], 
                    controlsVisibility: 'visible',
                    currentDealCreditStatus: obj['Deal Credit Status$'],
                });
            }
    }
    
    handleAcceptClick = () => {
        if (this.validateRequiredFields()) {
            this.acceptCreditApproval_intern()
        }
    }

    acceptCreditApproval_intern(){
        var installationID = 'Default';
        if (customerName !== undefined) { installationID = customerName }
        if(this.state.creditApproval.DealHeaderID > 0){
            acceptCreditApproval(this.state.creditApproval.DealHeaderID, this.state.creditApproval.StpComments, installationID).then(
                (json) => {
                    if(json.Message !== undefined && json.Message !== ''){
                        this.setState({
                            showAlert: true, alertTitle: "Error", alertMessage: json.Message,
                            creditApproval: { ...this.state.creditApproval }
                        });

                    } else {
                        this.setState({
                            showAlert: true, alertTitle: "Warning", alertMessage: json.CreditApprovalMessage,
                            creditApproval: { ...this.state.creditApproval, DealHeaderID: 0 },
                            customerName: '', buyAmount: 0, sellAmount: 0, controlsVisibility: 'hidden'
                        });
                        this.loadSTPDeals(this.state.FromDate, this.state.ToDate, this.state.SearchBy, this.state.CrediStatus);
                    }
                }
            )
        } else{
            this.setState({showAlert: true, alertTitle: "Warning", alertMessage: "You must select a deal."});
        }
        
    }
    
    handleDeclineClick = () => {
        if (this.validateRequiredFields()) {
            this.declineCreditApproval_intern()
        }
    }
    
    declineCreditApproval_intern(){
        var installationID = 'Default';
        if (customerName !== undefined) { installationID = customerName }
        if(this.state.creditApproval.DealHeaderID > 0){
            declineCreditApproval(this.state.creditApproval.DealHeaderID, this.state.creditApproval.StpComments, installationID).then(
                (json) => {
                    if(json.Message !== undefined && json.Message !== ''){
                        this.setState({
                            showAlert: true, alertTitle: "Error", alertMessage: json.Message,
                            creditApproval: { ...this.state.creditApproval }
                        });

                    } else {
                        this.setState({
                            showAlert: true, alertTitle: "Warning", alertMessage: json.CreditApprovalMessage,
                            creditApproval: { ...this.state.creditApproval, DealHeaderID: 0 },
                            customerName: '', buyAmount: 0, sellAmount: 0, controlsVisibility: 'hidden'
                        });
                        this.loadSTPDeals(this.state.FromDate, this.state.ToDate, this.state.SearchBy, this.state.CrediStatus)
                    }
                }
            )
        } else{
            this.setState({showAlert: true, alertTitle: "Warning", alertMessage: "You must select a deal."});
        }
    }

    handleCheckboxClick = (obj) =>
    {
        if(obj["Deal Number"] !== undefined)
        {            
            this.setState({
                creditApproval: { ...this.state.creditApproval, DealHeaderID: obj["Deal Number"] }
            });
        }
    }

    render(){
        var classNameInputName = regularInputClass;

        var approveButton, rejectButton;

        if(this.state.currentDealCreditStatus === 'Requires Approval'){
            approveButton = (<button className="uk-button uk-button-green" type="button" onClick={this.handleAcceptClick}>Accept</button>);
            rejectButton = (<button className="uk-button uk-button-green" type="button" onClick={this.handleDeclineClick}>Decline</button>);
        }

        return(
            <div>
                <div className="uk-form-stacked">
                    <div className="uk-margin">
                        <div className="">
                            <h3 className="uk-heading-divider" style={style.titleStyle} >
                                    Credit Approval
                            </h3>
                        </div>
                    </div>
                    <div className="uk-margin uk-grid">
                        <div className="uk-width-1-4">
                            <label className="uk-form-label" htmlFor="form-deal-FromDate">From</label>
                            <div className="uk-form-controls">
                                <DatePicker className={classNameInputName} useNew value={this.state.creditApproval.FromDate} onDayChange={this.handleUpdateFromDate.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-4">
                            <label className="uk-form-label" htmlFor="form-deal-ToDate">To</label>
                            <div className="uk-form-controls">
                                <DatePicker className={classNameInputName} useNew value={this.state.creditApproval.ToDate} onDayChange={this.handleUpdateToDate.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-grid">
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Trade Date</label>
                                <div className="uk-form-controls">
                                    <input value="IssueDate" className="uk-radio" type="radio" name="chkTradeDate" onChange={this.handleCheckDate.bind(this)} checked={this.state.creditApproval.SearchBy === null || "IssueDate"} />
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <label className="uk-form-label">Value Date</label>
                                <div className="uk-form-control">
                                    <input value="ValueDate" className="uk-radio" type="radio" name="chkTradeDate" onChange={this.handleCheckDate.bind(this)} checked={this.state.creditApproval.SearchBy === "ValueDate"} />
                                </div>
                            </div>
                        </div>

                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Deal Credit Status</label>
                            <div className="uk-form-controls">
                                <DynamicSelect id="form-deal-credit-status"
                                    className="uk-select"  
                                    objValue={{value: this.state.creditApproval.CrediStatus}} 
                                    getValue={this.handleDealCreditStatus.bind(this)} 
                                    listHasPlaceholder={true} 
                                    data={this.state.dropdowns['Deal Credit Status']}/>
                            </div>
                        </div>    
                        <div className="uk-width-1-2">
                            <div className="" style={{marginTop:'0.750rem'}}>
                                <button className="uk-button uk-button-green" type="button" onClick={this.handleSearchClick.bind(this)}>Search</button>
                            </div>
                        </div>
                        
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Note: The Customer's Available Credit includes the amount used by the deal.</label>
                        </div>    
                        <div className="uk-width-1-2" style={{ visibility: "hidden" }}>
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" />
                            </div>
                        </div>           
    
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel}>Comments(*)</label>
                            <div className="" style={style.inputStyle}>
                                <input className={this.formatValidInput(this.state.validations.comments)} type="text" placeholder="" onChange={this.handleComments} />
                            </div>
                        </div>  
                        <div className="uk-width-1-2" style={{ visibility: "hidden" }}>
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" />
                            </div>
                        </div>   

                        <div className="uk-width-1-3">
                            {approveButton}
                        </div>    
                        <div className="uk-width-1-3">
                            {rejectButton}
                        </div>  
                        <div className="uk-width-1-3" style={{ visibility: "hidden" }}>
                            <div className="uk-form-controls">
                                <input className="uk-input" type="text" />
                            </div>
                        </div>   

                        <div className="uk-width-1-4" style={{ visibility: this.state.controlsVisibility }}>                            
                            <label className="uk-form-label" style={style.boldLabel}>Deal ID</label>
                            <div className="" style={style.inputStyle}>
                                <input className="uk-input" readOnly={true} type="text" value={this.state.creditApproval.DealHeaderID}/>
                            </div>
                        </div>
                        <div className="uk-width-1-4" style={{ visibility: this.state.controlsVisibility }}>                            
                            <label className="uk-form-label" style={style.boldLabel}>Customer Name</label>
                            <div className="" style={style.inputStyle}>
                                <input className="uk-input" readOnly={true} type="text" value={this.state.customerName}/>
                            </div>
                        </div>
                        <div className="uk-width-1-4" style={{ visibility: this.state.controlsVisibility }}>                            
                            <label className="uk-form-label" style={style.boldLabel}>Buy Amount</label>
                            <div className="" style={style.inputStyle}>
                                <input className="uk-input" readOnly={true} type="text" value={this.state.buyAmount}/>
                            </div>
                        </div>
                        <div className="uk-width-1-4" style={{ visibility: this.state.controlsVisibility }}>                            
                            <label className="uk-form-label" style={style.boldLabel}>Sell Amount</label>
                            <div className="" style={style.inputStyle}>
                                <input className="uk-input" readOnly={true} type="text" value={this.state.sellAmount}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/*<NewDynamicTable
                            id="table-approval-tiers"
                            formats={[{header: 'Buy Amount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Buy Currency'}, 
                                {header: 'Sell Amount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Sell Currency'},
                                {header: 'Customer\'s Available Credit', formatFunction: formatToCurrencyWithScale, formatCurrency:this.state.baseCurrency}]}
                            hiddenHeaders={['Comments']}
                            data={this.state.stpDeals}
                            enableClick={true}
                            newSearch={this.state.newSearch}
                            checkboxFunction={this.handleCheckboxClick}
                            useCheckBox={false} 
                            clickFunction={this.handleRowClick.bind(this)}           
                        />*/}
                        <NewEditableGrid
                            data={this.state.stpDeals}
                            columnDetails={this.state.columnDetail}
                            isEditable={false}
                            enableClick={true} 
                            clickFunction={this.handleRowClick2.bind(this)}
                            useExportButton={true}
                            tableName="CreditApproval"
                            useIcons={true} />

                        {/*<Paginator changePage={this.changePage.bind(this)} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch} />*/}
                        <LoadingBox loading={this.state.loading} />
                    </div>
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
            </div>
        );
    }
}