import React from 'react';

import AlertBox from '../../shared/AlertBox'
import DatePicker from '../../shared/DatePicker'
import DynamicSelect from '../../shared/DynamicSelect'
import LoadingBox from '../../shared/LoadingBox'
import NumberInput from '../../shared/NumberInput'

import { BankReconciliationTypeNames } from '../../../constants/BankReconciliationTypeNames'
import { formatDate, formatToCurrencyWithScale, getScaleByCurrency, roundAmountByCurrency } from '../../../helpers/FormatHelper'

import { getNostroBankAccounts, getNostroBankAccountsForFunds, getBankAccountBalanceReconciliation, saveBankReconciliation } from '../../../networking/NetworkingBankAccounts'
import { getStyle } from '../../../styles/styles';
import {Colors} from '../../../constants/Colors'

const clearedTypes = [{value:'Both Cleared and Uncleared', name: 'Both Cleared and Uncleared'}, {value:'Cleared', name: 'Cleared'}, {value:'Uncleared', name: 'Uncleared'}]

const getTotals = (reconciliatedRecords = [], debit = false) => {

    if (debit) {
        let debit = 0
        let debits = reconciliatedRecords.map(x => Number(x.Debits) || 0)
        debit = debits.reduce((acc, currentValue) => { return acc + currentValue }, 0)
        return debit
    } else { 
        let credit = 0
        let credits = reconciliatedRecords.map(x => Number(x.Credits) || 0)
        credit = credits.reduce((acc, currentValue) => { return acc + currentValue }, 0)
        return credit
    }
}

const getRowStyle = (x) => {
    let backgroundColor = x.Checked ? 'rgb(174 211 140 / 74%)' : ''
    /*if (x.IsCleared) {
        backgroundColor = 'lightyellow'
    }*/
    return { backgroundColor: backgroundColor, }
}

const ReconciliationTable = ({ results = [], updateData = f => f, showCredit, showDebit,currencyID }) => {
    
    let headers = []
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h1'}></th>)    
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h2'}>Customer Name</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h3'}>Payment Date</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h5'}>Debits</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h4'}>Credits</th>)    
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h6'}>Deal/Transfer No.</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h7'}>Instrument</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h8'}>Ref. No / Cheque Number</th>)    
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h10'}>Type</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h11'}>Comments</th>)
    headers.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em', textTransform: 'uppercase' }} className="uk-table-shrink" key={'rt-h12'}>Last Updated By</th>)
    
    let filteredResults = results.filter(x => (Number(x.Credits) > 0 && showCredit) || (Number(x.Debits) > 0 && showDebit));

    let rows = filteredResults.map((x, i) => {
        
        let checkbox = <input 
            className   = 'uk-checkbox'
            style       = {{marginRight: 0}}
            type        = "checkbox" 
            checked     = {x.Checked} 
            //disabled    = {x.IsCleared}
        />
                                return <tr
                                            key={i}
                                            style={getRowStyle(x)}
                                        >
                                            <td
                                                style={{ textAlign: 'center', verticalAlign: 'middle', cursor: 'pointer' }}
                                                onClick={() => {
                                                    x.Checked = !x.Checked;
                                                    x.UnReconciliated = x.IsCleared && !x.Checked
                                                    updateData(results);
                                                }}
                                            >
                                                {checkbox}
                                            </td>
                                            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.CustomerName}</td>
                                            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.Date}</td>
                                            <td className="uk-preserve-width" style={{ textAlign: 'right', verticalAlign: 'middle' }}>{formatToCurrencyWithScale(x.Debits, currencyID)}</td>
                                            <td className="uk-preserve-width" style={{ textAlign: 'right', verticalAlign: 'middle' }}>{formatToCurrencyWithScale(x.Credits, currencyID)}</td>                                            
                                            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.DealNo}</td>
                                            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.Instrument}</td>
                                            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.PaymentReferenceNo} / {x.ChequeNumber}</td>
                                            {/*<td className="uk-preserve-width" style={{textAlign: 'center', verticalAlign: 'middle'}}>{x.ChequeNumber}</td>*/}
                                            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.Type}</td>
                                            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                <input key={'comments' + x.ID} className="uk-input" value={x.ClearedComments} //disabled={x.IsCleared}
                                                    onChange={(event) => {
                                                        x.ClearedComments = event.target.value
                                                        updateData(results)
                                                    }} /></td>
                                            <td className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{x.BankRecLastUpdatedBy}</td>
                                        </tr>
    })

    rows.push(<tr style={{backgroundColor: Colors.primaryColor}}>
        <td className="uk-preserve-width" colspan="3" style={{textAlign: 'right', fontWeight:'bolder'}}><label>Totals:</label></td>
        <td className="uk-preserve-width" style={{textAlign: 'right', verticalAlign: 'middle', fontWeight:'bolder'}}>{formatToCurrencyWithScale(getTotals(results, true), currencyID)}</td>
        <td className="uk-preserve-width" style={{textAlign: 'right', verticalAlign: 'middle', fontWeight:'bolder'}}>{formatToCurrencyWithScale(getTotals(results, false), currencyID)}</td>
        <td className="uk-preserve-width" colspan='6'></td></tr>
    )

    let table =
        <div className="">
            <table key={'reconciliationTable'} className="uk-table uk-table-small uk-table-responsive uk-table-hover uk-table-divider border-table uk-table-striped"
                >
                <thead>
                    <tr>{headers}</tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        </div>
    return table
}

export default class BankReconciliationScreen extends React.Component {

    state = {
        clearedDate: '',
        fromDate: '',
        toDate: '',
        expectedBalance: 0,
        beginingBalance: 0,
        listNostroAccounts: [],
        reconciliationData: [],
        selectedNostroId: '',
        selectedClearedType: '',
        showCredit: true,
        showDebit: true,
        includeCleared: false,
        loading: false,
        activeSaveButton: false,
        currencyID:''
    }

    componentDidMount() {
        let systemDate = localStorage.getItem('SystemDate')
        this.setState({ clearedDate: systemDate }, () => {
            //this.loadNostroAccounts()
            this.loadNostroBankAccounts()
            this.loadBankAccountBalanceReconciliation()
        })
    }

    //networking
    loadBankAccountBalanceReconciliation = () => {

        let fromDate = null
        let toDate = null
        if (this.state.includeCleared && this.state.toDate !== '') {
            fromDate = this.state.fromDate
            toDate = this.state.toDate
        } else {
            fromDate = this.state.clearedDate
            toDate = null
        }

        this.setState({ loading: true }, () => {
            getBankAccountBalanceReconciliation(this.state.selectedNostroId, fromDate, toDate, ).then(response => {
                console.log('getBankAccountBalanceReconciliation ', response)
                if (response !== undefined && response.results !== undefined && response.results !== null && response.results.length > 0) 
                {
                    let data = response.results || [];
                    data.forEach(x => {
                        x.IsCleared = x.ClearedDate !== null                        
                    });

                    if (this.state.clearedTypeSelected === 'Cleared') 
                    {
                        data = data.filter(x => x.IsCleared === true);
                    }
                    if (this.state.clearedTypeSelected === 'Uncleared') 
                    {
                        data = data.filter(x => x.IsCleared === false);
                    }

                    console.log('response.actualBeginingBalance ', response.actualBeginingBalance)
                    console.log('response.expectedBalance ', response.expectedBalance)

                    this.setState({
                        reconciliationData: data,
                        beginingBalance: Number(response.actualBeginingBalance),
                        expectedBalance: Number(response.expectedBalance),
                        loading: false
                    });
                }
                else if (response !== undefined && response !== null) {
                    console.log('response.actualBeginingBalance ', response.actualBeginingBalance)
                    console.log('response.expectedBalance ', response.expectedBalance)
                    this.setState({
                        reconciliationData: [],
                        beginingBalance: Number(response.actualBeginingBalance),
                        expectedBalance: 0,//TODO: response.expectedBalance,
                        loading: false
                    });
                }
                else {
                    this.setState({
                        reconciliationData: [],
                        beginingBalance: 0,
                        expectedBalance: 0,
                        loading: false
                    });
                }
            }).finally(() => this.setState({ loading: false }));
        })
    }

    loadNostroBankAccounts() {
        getNostroBankAccountsForFunds('', 0, false).then(
            (response) => {
                console.log(response)
                if (response !== undefined && response.nostroBankAccounts !== undefined && response.nostroBankAccounts.length > 0) {
                    let nostroAcounts = response.nostroBankAccounts;
                    nostroAcounts.forEach(element => {
                        element.value = element.BankAccountID;
                        element.name = element.BankName;
                    });
                    this.setState({ listNostroAccounts: nostroAcounts })
                } else {
                    this.setState({ listNostroAccounts: [] });
                }
            }
        )
    }

    loadNostroAccounts = () => {
        getNostroBankAccounts(0).then(response => {
            console.log(response)
            if (response !== undefined && response.banks !== undefined && response.banks.length > 0) {
                let accounts = response.banks
                accounts.forEach(x => {
                    x.value = x.BankID
                    x.name = x.BankName
                });
                this.setState({listNostroAccounts: accounts})
            }
        })
    }

    //events
    nostroAccountSelected = (obj) => {
        if (obj.value !== undefined) {
            var accounts= obj.name.split('-');
            console.log(accounts)
            var ccy='';
            if(accounts.length > 3)
            {
                ccy = accounts[3];
            }
            this.setState({ selectedNostroId: obj.value, currencyID : ccy }, () => { this.loadBankAccountBalanceReconciliation() })
        }
    }

    clearedTypeSelected = (obj) => { 
        if (obj.value !== undefined) {
            this.setState({ clearedTypeSelected: obj.value }, () => { 
                this.loadBankAccountBalanceReconciliation()
            })
        }
    }

    handleClearedDateChange = (date) => {
        this.setState({ clearedDate: formatDate(date), fromDate: this.state.includeCleared ? formatDate(date) : this.state.fromDate },
            () => { this.loadBankAccountBalanceReconciliation() })
    }

    handleFromDateChange = (date) => {
        this.setState({ 
            fromDate: formatDate(date) 
        });
        this.loadBankAccountBalanceReconciliation();
    }

    handleToDateChange = (date) => {
        this.setState({ 
            toDate: formatDate(date) 
        });
        this.loadBankAccountBalanceReconciliation();
    }

    handleIncludeCleared = (event) => {
        this.setState({ 
            includeCleared  : !this.state.includeCleared, 
            //fromDate        : this.state.includeCleared ? this.state.fromDate : this.state.clearedDate,
            activeSaveButton: event.target.checked ? true : false
        }, () => { 
            this.loadBankAccountBalanceReconciliation();
        });
    }

    updateData = (reconciliationData) => {
        //console.log(reconciliationData)
        this.setState({ reconciliationData: reconciliationData })
    }

    onRefresh = () => {
        this.setState({ loading: true }, () => this.loadBankAccountBalanceReconciliation())
    }

    onClearDateRange = () =>{
        this.setState({
            //includeCleared  : false,
            //activeSaveButton: false,
            fromDate        : '',
            toDate          : ''
        });
    }

    onSave = () => {
        let applicationDate = localStorage.getItem('SystemDate');
        let userId = localStorage.getItem('UserID')
        //send the checked records and the unreconciliated (this ones to revert its status)
        let processedRecords = this.state.reconciliationData.filter(x => x.Checked === true || x.UnReconciliated)

        if (processedRecords.length === 0) {
            this.setState({ showAlert: true, alertTitle: 'Warning', alertMessage: 'There are no records to update.' })
            return
        }
        
        let totalCredits = this.getClearedCredit(this.state.reconciliationData.filter(x => x.Checked === true))
        let totalDebits = this.getClearedDebit(this.state.reconciliationData.filter(x => x.Checked === true))

        this.setState({ loading: true }, () => {
            saveBankReconciliation(this.state.clearedDate, this.state.selectedNostroId, totalCredits, totalDebits, this.state.beginingBalance, this.state.expectedBalance,
                userId, applicationDate, this.state.reconciliationData)
                //userId, applicationDate, reconciliatedRecords)
                .then((response) => {
                    console.log(response)
                    switch (Number(response.httpStatusCode)) {
                        case 200:
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Success',
                                alertMessage: 'Records saved successfully',
                                loading: false,
                            }, this.onRefresh())
                            break
                        case 409:
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Error',
                                alertMessage: 'Current record has been updated by other user. Please refresh the information and try again.',
                                loading: false
                            })
                            break
                        default:
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Error',
                                alertMessage: 'Error saving records',
                                loading: false
                            })
                            break
                    }
                })
        })

    }

    closeAlert = () => {
        this.setState({ showAlert: false })
    }

    //helper
    getClearedDebit = (reconciliatedRecords = []) => {
        let debit = 0

        let debits = reconciliatedRecords.map(x => {
            if (x.Type === BankReconciliationTypeNames.TransactionLossDebit
                || (x.Type === BankReconciliationTypeNames.OutgoingFunds && !x.IsRejected)
                || x.Type === BankReconciliationTypeNames.WithdrawalRequests
                || (x.Type === BankReconciliationTypeNames.TransferOut && !x.IsRejected)
                || (x.Type === BankReconciliationTypeNames.Payments && !x.IsRejected)) {
                return Number(x.Debits) || 0
            }
            else {
                return 0
            }
        })

        debit = debits.reduce((acc, currentValue) => { return acc + currentValue }, 0)
        return Number(roundAmountByCurrency(debit, this.state.currencyID))
    }

    getClearedCredit = (reconciliatedRecords = []) => {

        let credit = 0

        let credits = reconciliatedRecords.map(x => {
            if (x.Type === BankReconciliationTypeNames.TransactionLossCredit
                || (x.Type === BankReconciliationTypeNames.Payments && !x.IsRejected)
                || x.Type === BankReconciliationTypeNames.TransferIn
                || (x.Type === BankReconciliationTypeNames.OutgoingFunds && !x.IsRejected)
                || (x.Type === BankReconciliationTypeNames.WithdrawalRequests && !x.IsRejected)) {
                return Number(x.Credits) || 0
            }
            else {
                return 0
            }
        })

        credit = credits.reduce((acc, currentValue) => { return acc + currentValue }, 0)
        return Number(roundAmountByCurrency(credit, this.state.currencyID))
    }

    updateBeginingBalance = (event) => {
        this.setState({ beginingBalance: Number(event.target.value) });
    }

    updateExpectedBalance = (event) => {
        this.setState({ expectedBalance: Number(event.target.value) });
    }

    onShowCredit = () => {
        this.setState({ showCredit: !this.state.showCredit })
    }

    onShowDebit = () => {
        this.setState({ showDebit: !this.state.showDebit })
    }

    //render
    render() {

        let reconciliatedRecords = this.state.reconciliationData.filter(x => x.Checked === true)
        let reconciliatedCount = reconciliatedRecords.length
        //let clearedRecordCount = this.state.reconciliationData.length// this.state.reconciliationData.filter(x => x.IsCleared === true).length
        let unReconciliatedCount = this.state.reconciliationData.length - reconciliatedCount// - clearedRecordCount

        console.log("currencyID ", this.state.currencyID)

        let beginingBalance = Number(roundAmountByCurrency(this.state.beginingBalance, this.state.currencyID))

        console.log("beginingBalance ", beginingBalance)

        let totalClearedDebits = this.getClearedDebit(reconciliatedRecords)
        let totalClearedCredit = this.getClearedCredit(reconciliatedRecords)

        console.log("totalClearedDebits ", totalClearedDebits)
        console.log("totalClearedCredit ", totalClearedCredit)

        let endingBalance = Number(roundAmountByCurrency(Number(this.state.beginingBalance) + totalClearedCredit - totalClearedDebits, this.state.currencyID))

        console.log("endingBalance ", endingBalance)

        let difference = Number(roundAmountByCurrency((beginingBalance + totalClearedCredit - totalClearedDebits) - Number(this.state.expectedBalance), this.state.currencyID))

        console.log("difference ", difference)

        return <div>
                    <h3  className="uk-heading-divider" style={{ ...getStyle().titleStyle, width: '100%'}}>
                        Bank Reconciliation
                    </h3>
                    
                    <div className="uk-grid uk-margin">
                                
                        {/* row 1 */}
                        <div className="uk-width-1-6">Nostro Account:</div>
                        <div className="uk-width-1-2">
                            <DynamicSelect id="select-nostro-accounts"
                                placeholder='Select an Account...'
                                data={this.state.listNostroAccounts}
                                objValue={{ value: this.state.selectedNostroId }}
                                getValue={this.nostroAccountSelected}/>
                        </div>
                        <div className="uk-width-1-3" style={{ marginTop: '25px' }}></div>

                        {/* row 2 */}
                        <div className="uk-width-1-6" style={{ marginTop: '25px' }}>Clearing date:</div>
                        <div className="uk-width-1-6" style={{ marginTop: '25px' }}>
                            <DatePicker 
                                useNew
                                className="uk-input" 
                                value={this.state.clearedDate} 
                                onDayChange={(e)=>{this.handleClearedDateChange(e);}}
                                disabled={this.state.activeSaveButton}
                                endDate = { new Date()}
                                modeCustomArturo = {true}
                            />
                        </div>
                        <div className="uk-width-1-6" style={{ marginTop: '25px' }}>Show:</div>
                        <div className="uk-width-1-6" style={{ marginTop: '25px' }}>
                            <DynamicSelect id="select-cleared-types" data={clearedTypes}
                                objValue={{ value: this.state.selectedClearedType }}
                                getValue={this.clearedTypeSelected} />
                        </div>

                        <div className="uk-width-1-3" style={{ marginTop: '25px' }}>
                            <div className="uk-grid uk-margin">
                            <div className="uk-width-1-6"><input className="uk-checkbox  uk-margin-left" type="checkbox" checked={this.state.showCredit}
                                onChange={this.onShowCredit} /></div>
                            <div className="uk-width-1-6"> <label className="uk-form-label" >Show credits</label></div>
                            <div className="uk-width-1-6"></div>
                            <div className="uk-width-1-6"><input className="uk-checkbox  uk-margin-left" type="checkbox" checked={this.state.showDebit}
                                onChange={this.onShowDebit} /></div>
                            <div className="uk-width-1-6"><label className="uk-form-label" >Show debits</label></div>
                            <div className="uk-width-1-6"></div>
                            </div>
                        </div>         

                        {/* row 3 */} 
                        
                        <div className="uk-width-1-6" style={{ marginTop: '20px' }}>Expected Ending Balance:</div>
                        <div className="uk-width-1-6" style={{ marginTop: '20px' }}>
                            <NumberInput negative={true} type="Currency" className="uk-input" value={this.state.expectedBalance}
                            scale={getScaleByCurrency(this.state.currencyID)}
                            onChange={this.updateExpectedBalance} /></div>
                        
                        <div className="uk-width-1-6" style={{ marginTop: '20px' }}>Begin Reconciled Balance:</div>
                        <div className="uk-width-1-6" style={{ marginTop: '20px' }}>
                            <NumberInput negative={true} type="Currency" className="uk-input" value={this.state.beginingBalance}
                            scale={getScaleByCurrency(this.state.currencyID)}
                            onChange={this.updateBeginingBalance} /*disabled={true}*//></div>
                        
                        <div className="uk-width-1-3" style={{ marginTop: '20px' }}></div>

                        {/* row 4 */} 

                        <div className="uk-width-1-6" style={{ marginTop: '20px' }}>From:</div>
                        <div className="uk-width-1-6" style={{ marginTop: '20px' }}>
                            <DatePicker 
                                useNew
                                className           = "uk-input" 
                                value               = {this.state.fromDate} 
                                onDayChange         = {this.handleFromDateChange} 
                                //disabled            = {!this.state.includeCleared}
                                modeCustomArturo    = {true}
                            />
                        </div>
                        <div className="uk-width-1-6" style={{ marginTop: '20px' }}>To:</div>
                        <div className="uk-width-1-6" style={{ marginTop: '20px' }}>
                            <DatePicker 
                                useNew
                                className           = "uk-input" 
                                value               = {this.state.toDate} 
                                onDayChange         = {this.handleToDateChange} 
                                //disabled            = {!this.state.includeCleared}
                                modeCustomArturo    = {true}
                            />
                        </div>
                        <div className="uk-width-1-6" style={{ marginTop: '20px' }}>
                            <input className="uk-checkbox  uk-margin-left" type="checkbox" checked={this.state.includeCleared} onChange={this.handleIncludeCleared}/>
                            <label className="uk-form-label" >Include Cleared</label>
                        </div>
                        <div className="uk-width-1-6" style={{ marginTop: '20px' }}></div>


                        <hr style={{ marginTop: '20px', width: "100%" }}/>

                    <div className="uk-width-1-1" style={{ marginTop: '20px' }}>
                        {/* <button Name="btnRecalculateTotals" className="uk-button uk-button-default uk-button-green">Re-calculate Totals</button> */}
                        <button Name="btnImport" className="uk-button uk-button-default" disabled={true}>Import</button>
                        <button Name="btnRefresh" className="uk-button uk-button-default uk-button-green" onClick={this.onRefresh}>Refresh</button>
                        <button 
                            Name        = "btnRefresh" 
                            className   = "uk-button uk-button-default uk-button-green" 
                            onClick     = {this.onClearDateRange}  
                            disabled    = {
                                ((this.state.fromDate !== undefined && this.state.fromDate !== '' && this.state.fromDate !== null) ||
                                (this.state.toDate !== undefined && this.state.toDate !== '' && this.state.toDate !== null)) ? false : true
                            }
                        >Clear Date Range</button>
                        <button Name="btnSave" className="uk-button uk-button-default uk-button-green" onClick={this.onSave} disabled={this.state.activeSaveButton}>Save</button>
                    </div>
                    </div>

                    <h5  className="uk-heading-divider" style={{ ...getStyle().subTitleStyle, width: '100%'}}>
                        Results
                    </h5>   

                    <ReconciliationTable results={this.state.reconciliationData}
                        updateData={this.updateData}
                        showCredit={this.state.showCredit}
                        showDebit={this.state.showDebit} 
                        currencyID={this.state.currencyID}/> 

                    <h5  className="uk-heading-divider" style={{ ...getStyle().titleStyle, width: '100%'}}>
                        Totals
                    </h5>
                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1-6"></div>
                        <div className="uk-width-2-3">
                            <div className="uk-grid uk-margin">
                                
                                <div className="uk-width-1-6">Total cleared debits:</div>
                                <div className="uk-width-1-6">
                                    <NumberInput negative={true} className="uk-input" type="Currency" 
                                    scale={getScaleByCurrency(this.state.currencyID)}
                                    value={totalClearedDebits} disabled={true} /></div>
                                
                                <div className="uk-width-1-6">Total cleared credits:</div>
                                <div className="uk-width-1-6">
                                    <NumberInput negative={true} className="uk-input" type="Currency" 
                                    scale={getScaleByCurrency(this.state.currencyID)}
                                    value={totalClearedCredit} disabled={true} /></div>
                                
                                <div className="uk-width-1-6">Reconcilied Count:</div>
                                <div className="uk-width-1-6">
                                    <NumberInput className="uk-input" type="Integer" value={reconciliatedCount} disabled={true} /></div>
                                
                                <div className="uk-width-1-6">Ending balance</div>
                                <div className="uk-width-1-6">
                                    <NumberInput negative={true} className="uk-input" type="Currency" 
                                    scale={getScaleByCurrency(this.state.currencyID)}
                                    value={endingBalance} disabled={true} /></div>
                                
                                <div className="uk-width-1-6">Difference</div>
                                <div className="uk-width-1-6">
                                    <NumberInput negative={true} className="uk-input" type="Currency" 
                                    scale={getScaleByCurrency(this.state.currencyID)}
                                    value={difference} disabled={true}/></div>
                                
                                <div className="uk-width-1-6">Unreconcilied Count:</div>
                                <div className="uk-width-1-6">
                                    <NumberInput className="uk-input" type="Integer" value={unReconciliatedCount} disabled={true} /></div>
                            </div>
                        </div>
                        <div className="uk-width-1-6"></div>
                    </div>
                    <LoadingBox loading={this.state.loading}/>
                    <AlertBox id="bankReconciliationAlert" type="Ok" open={this.state.showAlert} title={this.state.alertTitle} message={this.state.alertMessage}
                        okClick={this.closeAlert} onClose={this.closeAlert} />
                </div>
    }
}