import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import NewEditableGrid from '../../shared/NewEditableGrid';
import { getStyle } from '../../../styles/styles';
import AppContext from '../../../AppContext';
import AlertBox from '../../shared/AlertBox';
import { addRole, editRole, getAllRoles } from '../../../networking/NetworkingRoles';
import { getEODParameters } from '../../../helpers/PackageJsonHelper';

const style = getStyle();


const NewDynamicRolesScreen = () => {
    const context = useRef(useContext(AppContext));
    const ApplicationName = useMemo(() => getEODParameters().ApplicationName, []);
    const [data, setData] = useState([]);
    const [columnDetail, setColumnDetail] = useState(null);

    const [reload, setReload] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
      async function loadGrid() {
        const json = await getAllRoles(ApplicationName,0, 0);
        if (json != null && json.Roles != null) {
            setData(json.Roles);
            if (json.columnDetail != null) {
                const _columnDetail = JSON.parse(json.columnDetail);
                setColumnDetail(_columnDetail);
            }
        }
      }
      loadGrid();
    }, [reload]);

    const save = async (record, row) => {        
        try {
            const roleName = row['Role Name'];
            const available = row['Available For Online Users'];

            let json;
            context.current.startLoading();

            json = await addRole(ApplicationName, roleName, available);   

            context.current.finishLoading();

            if (json != null) {
                if (json.status !== 200) {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.Message);
                } else {
                    
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Role successfully saved.');
                    setReload(!reload);
                }
            }

          } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
          }
    }

    const edit = async (record, row) => {        
        try {
            const roleName = record['Role Name'];
            const available = row['Available For Online Users'];

            let json;
            context.current.startLoading();

            json = await editRole(ApplicationName, roleName, available);   

            context.current.finishLoading();

            if (json != null) {
                if (json.status !== 200) {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.Message);
                } else {
                    
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Role successfully updated.');
                    setReload(!reload);
                }
            }

          } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
          }
    }

    const closeAlert = () => {
        setShowAlert(false);
        setAlertTitle('');
        setAlertMessage('');
    };

    return (
        <div>
            <h3 className="uk-heading-divider" style={style.titleStyle} >Admin Roles</h3>
            <NewEditableGrid
                data={data}
                columnDetails={columnDetail}
                isEditable={true}
                useIcons={true}
                editSaveFunction={edit}
                canAdd={true}
                addSaveFunction={save}
            />
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
}

export default NewDynamicRolesScreen;
