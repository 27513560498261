import React from 'react';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getLookUpTable } from '../../../networking/Networking';
import { addLookUpTableDetails, deleteLookUpTableDetail } from '../../../networking/NetworkingLookUpTables';
import { getStyle, notFilledInputClass, regularInputClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import LoadingBox from '../../shared/LoadingBox';
import NewDynamicTable from '../../shared/NewDynamicTable';
import NumberInput from '../../shared/NumberInput';

var style = getStyle();
const items = 8;
export default class LookUpTableDetailsForm extends React.Component {
    
    state = {
        lookupdetails:{
            isDisable: true,
            notFirstLoad: false,
            showAlert: false,
            showDeleteAlert: false,
            showSuccessDeleteAlert: false,
            alertTitle: '',
            alertMessage: '',
            readyToDeleteLookUpDetail:{},
            keyDescription:'',
            field4Temporal:'',
            totalPages: 0,
            pages: 1,
            newSearch: false,
            clickPencil: false,
            //---
            dataTable:[],
            lookUpTableId:'',
            name:'',
            field1:'', 
            field2:'', 
            field3:'', 
            field4:'', 
            field5:'', 
            field6:'', 
            field7:'', 
            field8:'', 
            displayOrder:0,
            isEditable:'',
            mappingId: '',
            riskScore: 0,
            updateToken:0,
            nameDetail:'',
            loading: false
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.getDropdowns && prevProps.getDropdowns !== this.props.getDropdowns && this.props.getDropdowns.length>0){
            var data =  this.transformBoolean(this.props.getDropdowns)
            var pages = Math.ceil((data.length)/items);
            this.setState({lookupdetails:{...this.state.lookupdetails, isEditable:false,dataTable: data,lookUpTableId:0, 
                field4Temporal: this.state.lookupdetails.field4, nameDetail: this.props.nameDetail.Description, 
                isDisable:this.props.isDisable, notFirstLoad: false, totalPages: pages}},()=>this.props.objDetails(this.state.lookupdetails))
        }
        if(this.props.field4!==undefined&&this.props.field4!==''&&prevProps.field4!==this.props.field4){
            this.setState({lookupdetails:{...this.state.lookupdetails,field4:this.props.field4[0], notFirstLoad: false}})
        }
    }

    componentDidMount = () => {
        if(this.props.getDropdowns && this.props.getDropdowns.length>0){
            var data =  this.transformBoolean(this.props.getDropdowns)
            var pages = Math.ceil((data.length)/items);
            this.setState({lookupdetails:{...this.state.lookupdetails, isEditable:false,dataTable: data,lookUpTableId:0, 
                field4Temporal: this.state.lookupdetails.field4, nameDetail: this.props.nameDetail.Description, 
                isDisable:this.props.isDisable, notFirstLoad: false, totalPages: pages}},()=>{this.props.objDetails(this.state.lookupdetails);
                    if(this.props.field4!==undefined&&this.props.field4!==''){
                        this.setState({lookupdetails:{...this.state.lookupdetails,field4:this.props.field4[0], notFirstLoad: false}})
                    }
                })
        }
        
    }

    transformBoolean = list =>{
        var helper = [];
        var i, trueFalse = '';
        if(list[0].name.includes('Select')){i=1}else{i=0}
        for(i; i<list.length;i++){
            if(list[i]['Editable']){
                trueFalse = 'True';
            }else{
                trueFalse = 'False';
            }
            helper.push({
                'LookUpTableDetail': list[i]['LookUpTableDetail'],
                'DisplayOrder': list[i]['DisplayOrder'],
                'Field1': list[i]['Field1'],
                'Field2': list[i]['Field2'],
                'Field3': list[i]['Field3'],
                'Field4': list[i]['Field4'],
                'Field5': list[i]['Field5'],
                'Field6': list[i]['Field6'],
                'Field7': list[i]['Field7'],
                'Field8': list[i]['Field8'],
                'MappingId': list[i]['MappingId'],
                'name': list[i]['name'],
                'value': list[i]['value'],
                'Editable': trueFalse,
                [`${this.props.showRiskScore ? '' : '$'}RiskScore`]: list[i]['RiskScore']
            });
        }
        return helper;
    }

    transformListInput = listMethod =>{
        var helper = listMethod;
        if(helper.length>0){
            var list = [helper[0].name]
            for(var i=1; i<helper.length;i++){
                list[0] += '§'+ helper[i].name;
            }
            return list;
        }return '';
    }
    updateLookUpTable = () => {
        getLookUpTable(this.props.nameDetail.Description).then(
            (json) => {
                var helper = [];
                var formatHelper = [];
                if(json && json.lookUpTables !== undefined)
                {
                    var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
                    for(var i = 0; i<lookUpTableDetails.length; i++)
                    {
                        lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                        lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail

                        if( (!lookUpTableDetails[i].LookUpTableDetail.includes('select') ||
                            !lookUpTableDetails[i].LookUpTableDetail.includes('Select')) &&
                            lookUpTableDetails[i].DisplayOrder >= 0 )
                        { 
                            helper.push(lookUpTableDetails[i]);
                        }
                    }

                    
                    formatHelper =  this.transformBoolean(helper);
                 

                    var totalCount = lookUpTableDetails.length;
                    var pages = Math.ceil(totalCount/items);
                    this.setState({lookupdetails:{...this.state.lookupdetails,dataTable: formatHelper,totalPages: pages, newSearch: false}},()=>this.props.objDetails(this.state.lookupdetails));
                }
                this.setState({lookupdetails:{...this.state.lookupdetails,dataTable: formatHelper, newSearch: false}},()=>this.props.objDetails(this.state.lookupdetails));
        });
    }
    getAllLookUp = async () =>{
        var json = await getLookUpTable(this.props.nameDetail.Description);
        var helper = [];
        let formatHelper = [];
        if(json && json.lookUpTables !== undefined)
        {
            var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
            for(var i = 0; i<lookUpTableDetails.length; i++)
            {
                lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail

                if( (!lookUpTableDetails[i].LookUpTableDetail.includes('select') ||
                    !lookUpTableDetails[i].LookUpTableDetail.includes('Select')) &&
                    lookUpTableDetails[i].DisplayOrder >= 0 )
                { 
                    helper.push(lookUpTableDetails[i]);
                }
            }
            formatHelper =  this.transformBoolean(helper);
        }
        //var lookuptables = json.lookUpTables[0].lookUpTableDetails;
        //return lookuptables;
        return formatHelper;
    }
    handleClearClick(event){
        event.preventDefault();
        var list = []
        this.setState({lookupdetails:{...this.state.lookupdetails,
            lookUpTableId:0,
            name:'',
            keyDescription:'',
            field1:'', 
            field2:'', 
            field3:'', 
            field4Temporal:'',
            field4: '',
            field5:'', 
            field6:'', 
            field7:'', 
            field8:'', 
            displayOrder:0,
            isEditable:'',
            mappingId: '',
            riskScore: 0
        }},()=>this.props.objDetails(list));
    }
    handleSaveClick(event){
        event.preventDefault();
        var editable = 0
        if(this.state.lookupdetails.isEditable){editable=1}else{editable=0}
        var objFilter = {
            'LookUpTableId': this.props.nameDetail.ID,
            'Description': this.state.lookupdetails.name,
            'KeyDescription': this.state.lookupdetails.keyDescription,
            'DisplayOrder': this.state.lookupdetails.displayOrder,
            'Editable': editable,
            'Field1': this.state.lookupdetails.field1,
            'Field2': this.state.lookupdetails.field2,
            'Field3': this.state.lookupdetails.field3,
            'Field4': this.state.lookupdetails.field4,
            'Field5': this.state.lookupdetails.field5,
            'Field6': this.state.lookupdetails.field6,
            'Field7': this.state.lookupdetails.field7,
            'Field8': this.state.lookupdetails.field8,
            'MappingId': this.state.lookupdetails.mappingId,
            'RiskScore': this.state.lookupdetails.riskScore,
        }
        if(this.validateRequiredInputs()){
            addLookUpTableDetails(objFilter).then(
                (json)=>{
                    ////debugger
                    if(json.status!==200){
                        this.setState({lookupdetails:{...this.state.lookupdetails,showAlert: true, alertMessage: 'An error occurred while trying to save the Look Up Table Detail. Please try again.', alertTitle: 'Error'}},()=>this.props.objDetails(this.state.lookupdetails));
                    }else{
                        this.setState({lookupdetails:{...this.state.lookupdetails,showAlert: true, alertTitle: 'Success', alertMessage: 'Look Up Table Detail successfully saved.', 
                        field4Temporal:'',
                        lookUpTableId:0,
                        name:'',
                        field1:'', 
                        field2:'', 
                        field3:'', 
                        field4:'', 
                        field5:'', 
                        field6:'', 
                        field7:'', 
                        field8:'', 
                        displayOrder:0,
                        isEditable:'',
                        mappingId: '',
                        riskScore: 0
                        }},()=>this.props.objDetails(this.state.lookupdetails))
                        this.updateLookUpTable()
                    }
                }
            );
        }else{
            this.setState({lookupdetails:{...this.state.lookupdetails,showAlert: true, alertMessage: 'The name is incomplete in Lookup Detail. Please try again', alertTitle: 'Error'}},()=>this.props.objDetails(this.state.lookupdetails));
        }
    }
    handleUpdateLookUpTable(value){
        this.setState({lookupdetails:{...this.state.lookupdetails,lookUpTab: value.name}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    handleOnCheck(event){
        this.setState({lookupdetails:{...this.state.lookupdetails,isEditable: event.target.checked}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    //----------
    handleUpdateName(event){
        this.setState({lookupdetails:{...this.state.lookupdetails,name: event.target.value}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    handleUpdateField1(event){
        this.setState({lookupdetails:{...this.state.lookupdetails,field1: event.target.value}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    handleUpdateField2(event){
        this.setState({lookupdetails:{...this.state.lookupdetails,field2: event.target.value}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    handleUpdateField3(event){
        this.setState({lookupdetails:{...this.state.lookupdetails,field3: event.target.value}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    handleUpdateField4(event){
        this.setState({lookupdetails:{...this.state.lookupdetails,field4: event.target.value}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    handleUpdateField5(event){
        this.setState({lookupdetails:{...this.state.lookupdetails,field5: event.target.value}},()=>this.props.objDetails(this.state.lookupdetails));
    }

    handleUpdateField6(event) {
        this.setState({ lookupdetails: { ...this.state.lookupdetails, field6: event.target.value } }
            , () => this.props.objDetails(this.state.lookupdetails));
    }

    handleUpdateField7(event) {
        this.setState({ lookupdetails: { ...this.state.lookupdetails, field7: event.target.value } }
            , () => this.props.objDetails(this.state.lookupdetails));
    }

    handleUpdateField8(event) {
        this.setState({ lookupdetails: { ...this.state.lookupdetails, field8: event.target.value } }
            , () => this.props.objDetails(this.state.lookupdetails));
    }

    handleUpdateDisplayOrder(event){
        this.setState({lookupdetails:{...this.state.lookupdetails,displayOrder: event.target.value}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    handleUpdateMappingID(event){
        this.setState({lookupdetails:{...this.state.lookupdetails,mappingId: event.target.value}},()=>this.props.objDetails(this.state.lookupdetails));
    }

    handleUpdateRiskScore(event) {
        this.setState({ lookupdetails: { ...this.state.lookupdetails, riskScore: event.target.value } }
            , () => this.props.objDetails(this.state.lookupdetails));
    }

    clickPencil = (event) =>{
        this.setState({lookupdetails:{...this.state.lookupdetails,clickPencil: true}},
            ()=>this.props.objDetails(this.state.lookupdetails));
    }

    
    closeAlert(){
        this.setState({lookupdetails:{...this.state.lookupdetails,showAlert: false}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    
    handleRowClick = obj =>{
        console.group("Look Up Table Details handleClick")
        console.log(obj);
        console.groupEnd();
        var isBool, displayOrder =''
        if(obj.Editable==='True'){
            isBool = true
        }else{isBool = false}
        this.setState({lookupdetails:{...this.state.lookupdetails,
            lookUpTableId:this.props.nameDetail.ID,
            keyDescription: obj.name,
            name:obj.name,
            field1:obj.Field1, 
            field2:obj.Field2, 
            field3:obj.Field3, 
            field4Temporal: obj.Field4,
            field4:obj.Field4, 
            field5:obj.Field5, 
            field6:obj.Field6, 
            field7:obj.Field7, 
            field8:obj.Field8, 
            displayOrder:obj.DisplayOrder,
            isEditable:isBool,
            mappingId: obj.MappingId,
            riskScore: obj.RiskScore
        }},()=>this.props.objDetails(this.state.lookupdetails));
    }
    changePage = value =>{
        this.setState({lookupdetails:{...this.state.lookupdetails,pages: value}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    handleDeleteClick = obj =>{
        console.group("Look Up Table Details handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        obj.lookUpTableId = this.props.nameDetail.ID
        this.setState({lookupdetails:{...this.state.lookupdetails,showDeleteAlert: true, readyToDeleteLookUpDetail: obj}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    closeDeleteAlert(){
        this.setState({lookupdetails:{...this.state.lookupdetails,showDeleteAlert: false, readyToDeleteLookUpDetail: {}}},()=>this.props.objDetails(this.state.lookupdetails));
    }
    yesDeleteAlert(){
        deleteLookUpTableDetail(this.state.lookupdetails.readyToDeleteLookUpDetail.lookUpTableId,
                                this.state.lookupdetails.readyToDeleteLookUpDetail.name).then(
            (deleteResponse)=>{

                ////debugger

                /* 
                    ErrorMessage: null
                    Message: "The Detail has been successfully delete."
                    httpStatusCode: 200
                */

                this.setState({
                    lookupdetails:{
                        ...this.state.lookupdetails,
                        loading     : false
                    }
                }, () =>{
                    let auxTitle = 'Error';
                    let auxMessage = 'Something went wrong. Please try again later.';
                    let deleted = false;

                    if(deleteResponse !== undefined)
                    {
                        switch (deleteResponse.httpStatusCode) 
                        {
                            case 200:
                            {
                                deleted = true;
                                break;
                            }
                            case 412:
                            case 503:
                            {
                                if(deleteResponse.Message !== undefined && deleteResponse.Message !== null && deleteResponse.Message !== '')
                                {
                                    auxMessage = deleteResponse.Message;
                                }
                                break;
                            }
                            default:
                            {
                                break;
                            }
                        }

                        if(deleted){
                            this.setState({
                                lookupdetails:{...this.state.lookupdetails,showDeleteAlert: false, showSuccessDeleteAlert: true}
                            }, ()=>this.props.objDetails(this.state.lookupdetails));
                            this.updateLookUpTable();
                        }else{
                            this.setState({
                                lookupdetails:{
                                    ...this.state.lookupdetails, 
                                    showAlert    : true,
                                    alertTitle   : auxTitle,
                                    alertMessage : auxMessage,
                                    loading      : false
                                }
                            });
                        }
                    }else
                    {
                        this.setState({
                            lookupdetails:{
                                ...this.state.lookupdetails, 
                                showAlert   : true,
                                alertTitle  : auxTitle,
                                alertMessage: auxMessage,
                                loading     : false
                            }
                        });
                    }
                });
            }
        );
    }
    okSuccessAlert(){
        this.setState({lookupdetails:{...this.state.lookupdetails,showSuccessDeleteAlert: false}},()=>this.props.objDetails(this.state.lookupdetails));
    }

    //Input Validation
    validateRequiredInputs(){
        if(this.state.lookupdetails.name === ''){
            this.state.lookupdetails.notFirstLoad = true;
            return false;
        }
        return true;
    }
    render() {
        var classNameInputName = regularInputClass;
        var classNameInputLookName = regularInputClass;

        if(this.state.lookupdetails.name === '' && this.state.lookupdetails.notFirstLoad){
            classNameInputLookName=notFilledInputClass;
        }
        
        switch (this.props.nameDetail.Description) {
            case 'Service Level':
                var inputField2 = (
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-lookuptable-detail">Color</label>
                        <div className="uk-grid" style={style.inputStyle}>
                            <div className="uk-width-4-5 uk-form-stacked">
                                <input className={classNameInputName} id="field2" type="text" value={this.state.lookupdetails.field2} onChange={this.handleUpdateField2.bind(this)}/>  
                            </div>
                            <div className="uk-width-1-5 uk-form-stacked" style={{paddingLeft:'5px'}}>
                                <input className={classNameInputName} id="field2" type="color" value={this.state.lookupdetails.field2} onChange={this.handleUpdateField2.bind(this)}/>
                            </div>
                        </div>
                    </div>
                );
                var inputField4 = (
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-lookuptable-detail">Field 4</label>
                        <div className="" style={style.inputStyle}>
                            <input className={classNameInputName} id="field4" type="text" value={this.state.lookupdetails.field4} onChange={this.handleUpdateField4.bind(this)}/>
                        </div>  
                    </div>
                );
                break;
            case 'Incoming Instruments':
                var inputField2 = (
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-lookuptable-detail">Field 2</label>
                        <div className="" style={style.inputStyle}>
                            <input className={classNameInputName} id="field2" type="text" value={this.state.lookupdetails.field2} onChange={this.handleUpdateField2.bind(this)}/>
                        </div>
                    </div>
                );
                var inputField4 = (
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-lookuptable-detail">Delivery Method</label>
                        <div className="uk-grid" style={style.inputStyle}>
                            <div className="uk-width-2-3">
                                <input className={classNameInputName} id="field4" type="text" value={this.state.lookupdetails.field4} onChange={this.handleUpdateField4.bind(this)} disabled={true}/>   
                            </div>
                            <div className="uk-width-1-3 uk-form-stacked">
                                <a className="fa fa-pencil" style={{ fontSize: '1.00em',color: '#666', verticalAlign: 'middle', cursor: 'pointer'  }} onClick={this.clickPencil}></a>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 'Outgoing Instruments':
                var inputField2 = (
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-lookuptable-detail">Field 2</label>
                        <div className="" style={style.inputStyle}>
                            <input className={classNameInputName} id="field2" type="text" value={this.state.lookupdetails.field2} onChange={this.handleUpdateField2.bind(this)}/>
                        </div>
                    </div>
                );
                var inputField4 = (
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-lookuptable-detail">Delivery Method</label>
                        <div className="uk-grid" style={style.inputStyle}>
                            <div className="uk-width-2-3 uk-form-stacked">
                                <input className={classNameInputName} id="field4" type="text" value={this.state.lookupdetails.field4} onChange={this.handleUpdateField4.bind(this)} disabled={true}/>   
                            </div>
                            <div className="uk-width-1-3 uk-form-stacked">
                                <a className="fa fa-pencil" style={{ fontSize: '1.00em',color: '#666', verticalAlign: 'middle', cursor: 'pointer'  }} onClick={this.clickPencil}></a>
                            </div>
                        </div>
                    </div>
                );
                    break;
            default:
                var inputField2 = (
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-lookuptable-detail">Field 2</label>
                        <div className="" style={style.inputStyle}>
                            <input className={classNameInputName} id="field2" type="text" value={this.state.lookupdetails.field2} onChange={this.handleUpdateField2.bind(this)}/>
                        </div>
                    </div>
                );
                var inputField4 = (
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-lookuptable-detail">Field 4</label>
                        <div className="" style={style.inputStyle}>
                            <input className={classNameInputName} id="field4" type="text" value={this.state.lookupdetails.field4} onChange={this.handleUpdateField4.bind(this)}/>
                        </div>  
                    </div>
                );
                break;
        }
        
        return (
            <div>
                {/*<h3 className="uk-heading-divider" style={style.titleStyle} >
                    Lookup Details - {this.state.lookupdetails.nameDetail}
                </h3>*/}
                <form >
                    <div className="uk-form-stacked uk-grid">
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" htmlFor="form-lookuptable-id">Name(*)</label>
                            <div className="" style={style.inputStyle}>
                                <input className={classNameInputLookName} id="name" type="text" value={this.state.lookupdetails.name} onChange={this.handleUpdateName.bind(this)}/>
                            </div> 
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked uk-margin" style = {{marginBottom:'0px'}}>
                            <label className="uk-form-label" htmlFor="form-lookuptable-isEditable"><input className="uk-checkbox" id="isEditable" type="checkbox" checked={this.state.lookupdetails.isEditable} onChange={this.handleOnCheck.bind(this)}/> Editable</label>                            
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" htmlFor="form-lookuptable-detail">Field 1</label>
                            <div className="" style={style.inputStyle}>
                                <input className={classNameInputName} id="field1" type="text" value={this.state.lookupdetails.field1} onChange={this.handleUpdateField1.bind(this)}/>
                            </div>
                        </div>
                        {/*FIELD 2*/}
                        {inputField2}
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" htmlFor="form-lookuptable-detail">Field 3</label>
                            <div className="" style={style.inputStyle}>
                                <input className={classNameInputName} id="field3" type="text" value={this.state.lookupdetails.field3} onChange={this.handleUpdateField3.bind(this)}/>
                            </div>
                        </div>
                        {/*FIELD 4*/}
                        {inputField4}
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" htmlFor="form-lookuptable-detail">Field 5</label>
                            <div className="" style={style.inputStyle}>
                                <input className={classNameInputName} id="field5" type="text" value={this.state.lookupdetails.field5} onChange={this.handleUpdateField5.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" htmlFor="form-lookuptable-detail">Field 6</label>
                            <div className="" style={style.inputStyle}>
                                <input className={classNameInputName} id="field 6" type="text" value={this.state.lookupdetails.field6} onChange={this.handleUpdateField6.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" htmlFor="form-lookuptable-detail">Field 7</label>
                            <div className="" style={style.inputStyle}>
                                <input className={classNameInputName} id="field 6" type="text" value={this.state.lookupdetails.field7} onChange={this.handleUpdateField7.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" htmlFor="form-lookuptable-detail">Field 8</label>
                            <div className="" style={style.inputStyle}>
                                <input className={classNameInputName} id="field 6" type="text" value={this.state.lookupdetails.field8} onChange={this.handleUpdateField8.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" htmlFor="form-lookuptable-displayOrder">Display Order</label>
                            <div className="" style={style.inputStyle}>
                                <NumberInput id="displayOrder" type="Integer" className={classNameInputName} placeholder="___" value={this.state.lookupdetails.displayOrder} onChange={this.handleUpdateDisplayOrder.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" htmlFor="form-lookuptable-mappingId">Mapping ID</label>
                            <div className="" style={style.inputStyle}>
                                <NumberInput id="mappingId" type="Integer" className={classNameInputName} placeholder="___" value={this.state.lookupdetails.mappingId} onChange={this.handleUpdateMappingID.bind(this)}/>
                            </div>
                        </div>
                        {this.props.showRiskScore && <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" htmlFor="form-lookuptable-mappingId">Risk Score</label>
                            <div className="" style={style.inputStyle}>
                                <NumberInput id="mappingId" type="Integer" className={classNameInputName} min={0} max={100} placeholder="___" value={this.state.lookupdetails.riskScore} onChange={this.handleUpdateRiskScore.bind(this)}/>
                            </div>
                        </div>}
                    </div>
                    <div className="uk-width-1-1 uk-form-stacked">
                        <button className="uk-button uk-button-green" onClick={this.handleClearClick.bind(this)} disabled={this.state.lookupdetails.isDisable}>Clear Fields</button>
                        <button className="uk-button uk-button-green" onClick={this.handleSaveClick.bind(this)} disabled={this.state.lookupdetails.isDisable}>Save</button>
                        <hr />
                    </div>
                    <NewDynamicTable 
                        hiddenHeaders = {['name','value', 'Editable']} 
                        data={this.state.lookupdetails.dataTable} 
                        enableClick={true} 
                        clickFunction={this.handleRowClick} 
                        useDeleteButton={true} 
                        deleteFunction={this.handleDeleteClick}
                        useFilter={true}
                        filterFunction={this.getAllLookUp}
                        newSearch={this.state.lookupdetails.newSearch}
                        usePaginator={true} 
                        changePage={this.changePage} 
                        numberOfPages={this.state.lookupdetails.totalPages}
                        pageNumber = {this.state.lookupdetails.pages}
                        numberPerPage = {getDefaultPagingNumberOfItems()}
                        enableSortByHeader={true}
                    />
                    <AlertBox 
                        open     = {this.state.lookupdetails.showDeleteAlert} 
                        onClose  = {this.closeDeleteAlert.bind(this)} 
                        title    = "Warning" 
                        message  = "Are you sure you want to delete this Look Up Detail? This action can not be undone." 
                        type     = "Yes/No" 
                        yesClick = {this.yesDeleteAlert.bind(this)} 
                        noClick  = {this.closeDeleteAlert.bind(this)}
                    />
                    <AlertBox 
                        open    = {this.state.lookupdetails.showSuccessDeleteAlert} 
                        onClose = {this.closeDeleteAlert.bind(this)} 
                        title   = "Success" 
                        message = "Look Up Detail successfully removed." 
                        type    = "Ok" 
                        okClick = {this.okSuccessAlert.bind(this)}
                    />
                    <AlertBox 
                        open={this.state.lookupdetails.showAlert} 
                        onClose={this.closeAlert.bind(this)} 
                        title={this.state.lookupdetails.alertTitle} 
                        message={this.state.lookupdetails.alertMessage} 
                        type="Ok" 
                        okClick={this.closeAlert.bind(this)}
                    />
                    <LoadingBox loading={this.state.lookupdetails.loading} />
                </form>
            </div>
        );
    }
}