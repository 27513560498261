import Badge from '@material-ui/core/Badge';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import {formatToCurrencyWithScale} from '../../../helpers/FormatHelper';
import { getRiskScoreDetailsForDeals, getRiskScoreRanges } from '../../../networking/NetworkingRiskScoreRange';
import RiskScoreDetailDialog from '../../shared/Dialogs/RiskScoreDetailDialog';
import { getIncomingFundsByDeal, getOutgoingFundsByDeal } from '../../../networking/NetworkingFunds';
import ShowDynamicInfo from '../../shared/ShowDynamicInfo';
import { getDealInformationMin } from '../../../networking/NetworkingDeals';
export default class WizardDealSummary extends React.Component {
    state = {
        //RiskScore
        showModalViewRiskScoreCalculation   : false,
        listRiskScoreDetails                : [],
        riskValue                           : 0,
        dealRiskScoreText                   : 'N/A',
        colorRiskText                       : 'white',
        fontWeight                          : 'normal',
        backgroundColorRiskScore            : '#1d2b1d',
        farLegDetail:{},
        openInfoScreen:false,
        screenTitle:''
    }

    componentDidMount(){      
        
        var backgroundColorRiskScore =  localStorage.getItem('BackgroundColorRiskScore');
        if(backgroundColorRiskScore != null && backgroundColorRiskScore != '')
        {
            this.setState({backgroundColorRiskScore: backgroundColorRiskScore});
        }
        this.clickButtonViewRiskScoreCalculation();
    }

    clickButtonViewRiskScoreCalculation()
    {
        ////debugger

        var objDeal     = this.props.objDealHeader;
        let listInco    = []
        let listOutg    = []

        getIncomingFundsByDeal(objDeal.DealHeaderID,0,0).then(
            (jsonResponse)=>{
                if(jsonResponse !== undefined)
                {
                    switch (jsonResponse.httpStatusCode) 
                    {
                        case 200:
                        {
                            if(jsonResponse.totalCount !== 0)
                            {
                                listInco = jsonResponse.incomingFunds
                            }
                            break;
                        }
                        default:
                        {
                            break;
                        }
                    }
                }

                getOutgoingFundsByDeal(objDeal.DealHeaderID,0,0).then(
                    (jsonResponse)=>{

                        if(jsonResponse !== undefined)
                        {
                           switch (jsonResponse.httpStatusCode) 
                           {
                               case 200:
                               {
                                   if(jsonResponse.totalCount !== 0)
                                   {
                                        listOutg = jsonResponse.outgoingFunds
                                   }
                                   break;
                               }
                               default:
                               {
                                   break;
                               }
                           }
                        }

                        var modelRquest = 
                        {
                            "dealType"      : objDeal.DealType,
                            "customerID"    : objDeal.CustomerID,
                            "buyCurrency"   : objDeal.BuyCurrency,
                            "sellCurrency"  : objDeal.SellCurrency,
                            "oOutgoingFunds": listOutg,
                            "oIncomingFunds": listInco,
                            dealID          : objDeal.DealHeaderID
                        }

                        let subtotalA = 0;
                        let subtotalB = 0;
                        let listRiskScoreDetails = [];

                        getRiskScoreDetailsForDeals(modelRquest).then(
                            (jsonResponse)=>{

                                if(jsonResponse.riskScoreDetails !== undefined)
                                {
                                    listRiskScoreDetails = jsonResponse.riskScoreDetails;
                                    listRiskScoreDetails.forEach(risk => {
                                        subtotalA += risk['Risk Importance'];
                                        subtotalB += risk['Total'];
                                    });

                                    let color   = this.state.colorRiskText;
                                    let text    = this.state.dealRiskScoreText;

                                    getRiskScoreRanges().then(
                                        (jsonResponse) => {
                                            if(jsonResponse.riskScores !== undefined)
                                            {
                            
                                                var listRSRAll = []
                                                
                                                listRSRAll = jsonResponse.riskScores;

                                                listRSRAll.forEach(riskScoreRange => {
                                                    if( riskScoreRange['Entity Type'] === 'Trade' && 
                                                        riskScoreRange['Range From'] <= Math.round(subtotalB/subtotalA) && 
                                                        Math.round(subtotalB/subtotalA) <= riskScoreRange['Range To'])
                                                    {
                                                        ////debugger
                                                        color = riskScoreRange['Color Hex'];
                                                        text  = riskScoreRange.Description;
                                                    }
                                                });
                                            }

                                            this.setState({
                                                listRiskScoreDetails    : listRiskScoreDetails,
                                                riskValue               : Math.round(subtotalB/subtotalA),
                                                dealRiskScoreText       : text === this.state.dealRiskScoreText ? text + Math.round(subtotalB/subtotalA) : text,
                                                colorRiskText           : color,
                                                fontWeight              : 'bolder'
                                            })
                                        }
                                    );
                                }
                            }
                        );
                    }
                );
            }
        );
    }

    openForwardInformation = () =>{
        if(this.props.loadDeal)
        {
            let dealObj = {DealNo: this.props.objDealHeader.ParentDealHeaderID};
            this.props.loadDeal(dealObj, true);
        }
    }

    openFarLegInformation = async() =>{

        if(this.props.objDealHeader && this.props.objDealHeader.ParentDealHeaderID > 0)
            {
                let dealNo = this.props.objDealHeader.ParentDealHeaderID;
                let title = `Deal ${dealNo}`
                let jsonDetails = await getDealInformationMin(dealNo);
                this.setState({farLegDetail: jsonDetails, openInfoScreen: true, screenTitle: title});
            }
            else
            {
                this.setState({farLegDetail: {}, openInfoScreen: false, screenTitle:''});
            }

    }

    render() {
        let objDealHeader = this.props.objDealHeader;

        var totalDueHeader, settlementStatusHeader, balanceHeader,
            adjustmentDealIDHeader, adjustedByHeader, coverDealIDHeader,
            coveredDealsHeader;
        var totalDueValue, settlementStatusValue, 
            adjustmentDealIDValue, adjustedByValue, coverDealIDValue,
            coveredDealsValue;
        
        switch(objDealHeader.DealType){
            case 1:
            case 2:
            default:
                totalDueHeader = (<th align="center">Total Due</th>);
                totalDueValue = (<td align="center">{formatToCurrencyWithScale(Number(objDealHeader.BuyAmount) + Number(objDealHeader.TotalFees) - Number(objDealHeader.TotalPaid != null ? objDealHeader.TotalPaid : 0), objDealHeader.BuyCurrency )}</td>);
                settlementStatusHeader = (<th align="center">Settlement Status</th>);
                settlementStatusValue = (<td align="center">{objDealHeader.DealPaidStatus}</td>);
                balanceHeader = (<th align="center">Pending Disbursements</th>);
                break;
            case 4:
                balanceHeader = (<th align="center">Outstanding Balance</th>);
                break;
        }

        //Adjustment Deal ID
        if(Number(objDealHeader.AdjustmentDealID) > 0){
            adjustmentDealIDHeader = (<th align="center">Deal Adjusted</th>);
            adjustmentDealIDValue = (<td align="center">{objDealHeader.AdjustmentDealID}</td>);
        }

        //Adjusted By
        if(Number(objDealHeader.AdjustedBy) > 0){
            adjustedByHeader = (<th align="center">Adjusted By</th>);
            adjustedByValue = (<td align="center">{objDealHeader.AdjustedBy}</td>);
        }

        //Cover deal ID
        if(Number(objDealHeader.CoverDealID) > 0){
            coverDealIDHeader = (<th align="center">Cover Deal ID</th>);
            coverDealIDValue = (<td align="center">{objDealHeader.CoverDealID}</td>);
        }

        if(objDealHeader.CoveredDeals !== ''){
            coveredDealsHeader = (<th align="center">Covered Deal(s)</th>);
            coveredDealsValue = (<td align="center">{objDealHeader.CoveredDeals}</td>);
        }

        var outstandingBalance = Number(objDealHeader.TotalDisbursed != null ? objDealHeader.TotalDisbursed : 0);
        var sellAmount = Number(objDealHeader.SellAmount);
        if(objDealHeader != undefined && objDealHeader.ParentDealHeaderID >0 && objDealHeader.DealType == 2)
        {
            sellAmount = Number(objDealHeader.ForwardSellAmount);
        }
        if(objDealHeader != undefined && objDealHeader.DealType == 4 && objDealHeader.ForwardType == 'Closed')
        {
            outstandingBalance = 0;
            if(objDealHeader.OutgoingFunds != undefined)
            {
                objDealHeader.OutgoingFunds.forEach(element => {
                    if(element.Status != 'Void')
                    {
                        outstandingBalance += element.PaymentAmount;            
                    }        
                });
            }
            else
            {
                if(this.props.disbursements != undefined)
                {
                    this.props.disbursements.forEach(element => {
                        if(element.Status != 'Void')
                        {
                            outstandingBalance += element.PaymentAmount;            
                        }        
                    });
                }
                else
                {

                }
            }
        }
       
        return(
            <div className="uk-width-4-5" style={{ display: 'inline-flex', justifyContent: 'flex-end'  }}>
                <div>
                    <div style={{ textAlign: "right", marginRight: '10px' }}><h3 className="" style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>Deal ID: {objDealHeader.DealHeaderID}</h3>
                    </div>
                    {objDealHeader.DealType === 2 &&
                    <div style={{ textAlign: "right", marginRight: '10px' }}><h3 className="" style={{ fontSize: '1.0em', verticalAlign: 'middle' }}>Forward ID: 
                       <a onClick={this.openForwardInformation}> {objDealHeader.ParentDealHeaderID}</a></h3>
                    </div>
                    }
                    {objDealHeader.DealType === 6 &&
                    <div style={{ textAlign: "right", marginRight: '10px' }}><h3 className="" style={{ fontSize: '1.0em', verticalAlign: 'middle' }}>Far Leg Deal: 
                       <a onClick={this.openFarLegInformation}> {objDealHeader.ParentDealHeaderID}</a></h3>
                    </div>
                    }
                </div>
                <div style={{marginRight: '20px' }}>
                <Badge
                                anchorOrigin={{
                                    vertical    : 'top',
                                    horizontal  : 'right',
                                }}
                                badgeContent={
                                    <InfoIcon 
                                        uk-tooltip  = {'Risk calculation'}
                                        style       = {{ color: '#6E9C3A' }}
                                    />
                                }
                                disabled={true}
                            >
                                <div 
                                    className   = "" 
                                    type        = "button" 
                                    style       = {{
                                        borderRadius    : 10,
                                        border          : '1px solid #e5e5e5',
                                        height          : 30,
                                        justifyContent  : 'center',
                                        display         : 'flex',
                                        alignItems      : 'center',
                                        paddingLeft      : 10,
                                        paddingRight     : 10,
                                        backgroundColor : this.state.backgroundColorRiskScore
                                    }}
                                >
                                    <label 
                                        onClick     = { () =>{
                                            this.setState({showModalViewRiskScoreCalculation: true});
                                        }}
                                        className   =""
                                        style       = {{ color: this.state.colorRiskText, fontWeight: this.state.fontWeight, cursor:'pointer'}}
                                        id          = "DealRiskScore"
                                    >{this.state.dealRiskScoreText}
                                    </label>
                                </div>
                            </Badge>
                </div>
                
                <div className="input-number">
                    <table className="uk-align-right" border="1" cellSpacing="0" cellPadding="5">
                        <thead>
                            <tr>
                                <th align="center">Status</th>
                                {totalDueHeader}
                                {settlementStatusHeader}
                                {balanceHeader}
                                {adjustmentDealIDHeader}
                                {adjustedByHeader}
                                {coverDealIDHeader}
                                {coveredDealsHeader}
                            </tr>                    
                        </thead>
                        <tbody>
                            <tr>
                                <td align="center">{objDealHeader.Status}</td>
                                {totalDueValue}
                                {settlementStatusValue}
                                <td align="center">{formatToCurrencyWithScale(sellAmount - outstandingBalance, objDealHeader.SellCurrency)}</td>
                                {adjustmentDealIDValue}
                                {adjustedByValue}
                                {coverDealIDValue}
                                {coveredDealsValue}
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/*View Risk Score Calculation*/}
                <RiskScoreDetailDialog
                    entityType                   = {'Trader'}
                    dealId                       = {this.props.objDealHeader.DealHeaderID}
                    riskScoreDetails             = {this.state.listRiskScoreDetails}
                    open                         = {this.state.showModalViewRiskScoreCalculation}
                    onCloseRiskScoreDetailDialog = {() =>{this.setState({showModalViewRiskScoreCalculation: false})}}
                />
                <ShowDynamicInfo open={this.state.openInfoScreen} jsonDetail={this.state.farLegDetail} title={this.state.screenTitle} onClose={() => this.setState({openInfoScreen:false})}/>
            </div>
        );
    }
}