import { Breadcrumb } from 'antd';
import React, { useState } from 'react';
import AuditEntityPropertyScreen from '../auditEntity/AuditEntityPropertyScreen';
import AdditionalPropertiesScreen from './AdditionalPropertiesScreen';
import ExtendedOnlinePropertiesScreen from './ExtendedOnlinePropertiesScreen';

const PropertiesScreen = ({ customerID, personID, customerType, refreshCustomer, forceLoad = false }) => {
    const [showBreadCrumb, setShowBreadCrumb] = useState(false);

    const showHistoryScreen = () => {
        setShowBreadCrumb(true);
    };

    const handleBackLink = () => {
        setShowBreadCrumb(false);
    }

    if (showBreadCrumb) {
        return (
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={handleBackLink}>Additional Properties</Breadcrumb.Item>
                        <Breadcrumb.Item className="breadcrumb-item-bold">Audit History</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <AuditEntityPropertyScreen entityID={customerID} entityType="Customer" isTitle />;
            </div>
        );
    }

    return (
        <div>
            <ul uk-tab="">
                <li><a></a></li>
               {/* <li><a>Additional Properties</a></li>*/}
              {/*  <li><a>Extended Properties</a></li> */}
            </ul>
            <ul className="uk-switcher uk-margin">
                <li>
                    <AdditionalPropertiesScreen
                        customerID={customerID}
                        refreshCustomer={refreshCustomer}
                        forceLoad={forceLoad}
                        showHistoryScreen={showHistoryScreen}
                    />
                </li>
                {/*<li>
                    <ExtendedOnlinePropertiesScreen entityID={customerID} entityType="Customer" personID={personID} customerType={customerType} noTitle={true} />
    </li>*/}
            </ul>
        </div>
    );
};

export default PropertiesScreen;
