import React from 'react';

import { searchCountries, getCountries } from '../../../../networking/NetworkingCountries';
import { getCurrencyList, searchCurrencies } from '../../../../networking/NetworkingCurrencies';
import { getAllLimitProfilesByCustomerID, getLimitProfile, saveLimitProfile, deleteCustomerLimitProfile } from '../../../../networking/NetworkingCustomers';

import DynamicSelect from '../../../shared/DynamicSelect';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';

import AlertBox from '../../../shared/AlertBox';
import { getStyle } from '../../../../styles/styles';

import NumberInput from '../../../shared/NumberInput';
import { formatValidSelect, formatValidInput, formatToCurrency, formatToCurrencyWithScale, getScaleByCurrency } from '../../../../helpers/FormatHelper';
import { validate } from '../../../../helpers/ValidateHelper';
import LoadingBox from '../../../shared/LoadingBox';

var style = getStyle();

export default class LimitProfilesScreen extends React.Component {

    state = {
        customerLimitProfile: {
            CustomerLimitProfileID: 0,
            CustomerID: 0,
            CurrencyID: "",
            BuySell: "",
            CountryId: "",
            LowerLimit: 0,
            UpperLimit: 0,
            Frequency: 0,
            LowerFrequency: 0,
            CreatedBy: "",
            CreatedOn: "",
            UpdatedBy: "",
            UpdatedOn: "",
            UpdateToken: ""
        },

        buysell: [{
            value: "Buy",
            name: "Buy"
        }, {
            value: "Sell",
            name: "Sell"
        }],
        currencies: [],
        countries: [],
        side: "",
        currencyId: "",
        currencyName: '',
        countryId: "",
        countryIdName: "",
        lowerLimit: 0,
        frequency: 0,
        lowerFrequency: 0,
        upperLimit: 0,

        pages: 1,
        totalPages: 1,
        newSearch: true,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        limitProfiles: [],
        customerId: 0,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteObj: {},
        validations: {
            buySell: true,
            country: true,
            currency: true,
            frequency: true,
            upperLimit: true,
        },
        errors: [],
        loading: false,
        requiresEdd: 0
    }

    componentDidMount() {
        this.setState({
            customerId: this.props.id
        });

        //load limit profiles
        this.updateGrid();
        getCurrencyList().then(
            (json) => {
                if (json != null && json.currencies != null) {
                    const currencies = json.currencies.map(c => ({ ...c, name: c.CurrencyIDName, value: c.CurrencyID }));
                    this.setState({ currencies });
                }
            }
        );
        getCountries(1, 250).then(
            (json) => {
                if (json != null && json.countries != null) {
                    const countries = json.countries.map(c => ({ ...c, name: c["Country Name"], value: c.Country }));
                    this.setState({ countries });
                }
            }
        )
    }

    changePage = (value) => {
        this.setState({
            pages: value
        });
    }

    handleRowClick = (row) => {
        console.group("Limit Profile handleRowClick")
        console.log(row);
        console.groupEnd();
        getLimitProfile(row['CustomerLimitProfileId']).then(
            (value) => {
                console.table(value);
                this.setState({
                    customerLimitProfile: value,
                    side: value.BuySell,
                    frequency: value.Frequency,
                    lowerFrequency: value.LowerFrequency,
                    upperLimit: value.UpperLimit,
                    lowerLimit: value.LowerLimit,
                    currencyId: value.CurrencyID,
                    countryId: value.CountryID,
                    validations: {
                        buySell: true,
                        country: true,
                        currency: true,
                        frequency: true,
                        upperLimit: true,
                    }
                }, () => {
                    
                });

            }
        );
    }

    handleDeleteClick = (obj) => {
        console.group("Customer Limit Profile handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({ showDeleteAlert: true, readyToDeleteObj: obj });
        this.handleClearClick();
    }

    closeAlert = () => {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }
    closeAlertError = () => {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '' });
    }

    handleGetValueBuySell = (value) => {
        console.log(value);
        if (value !== undefined) {
            this.setState({
                validations: { ...this.state.validations, buySell: true },
                side: value.value
            });
        }
    }

    handleGetValueCurrency = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            console.log(value);
            this.setState({
                validations: { ...this.state.validations, currency: true },
                currencyId: value.value,
                currencyName: value.name != null ? value.name : ''
            });
        }
    }

    search = async (query) => {
        const json = await searchCurrencies(query, 'active');
        var helper = [];
        if (query !== '') {
            if (json !== undefined) {
                for (var i = 0; i < json.length; i++) {
                    helper.push({ value: json[i].CurrencyID, name: json[i].CurrencyIDName })
                }
                if (json.length > 1) {
                    this.setState({ currencies: helper });
                } else {
                    this.setState({ currencies: helper, currencyName: helper[0].name });
                }
            } else {
                this.setState({ currencies: helper });
            }
        }
    }

    searchCountry = async (query) => {
        const json = await searchCountries(query);
        var helper = [];
        if (query !== '') {
            console.group('Search Countries');
            console.log('Query');
            console.table(query);
            console.log('Response');
            console.table(json);
            console.groupEnd();
            for (var i = 0; i < json.length; i++) {
                json[i].value = json[i].CountryId;
                json[i].name = json[i].CountryIdName;
                helper.push(json[i]);
            }
            console.log(helper);
            this.setState({
                countries: helper
            });
            if (helper.length === 1) {
                var value = helper[0];
                this.setState({
                    countryId: value.CountryId,
                    countryIdName: value.CountryIdName
                });
            }
        }
        console.table(this.state.countries)
    }

    getCountryValue = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({
                validations: { ...this.state.validations, country: true },
                countryId: value.value,
                countryIdName: value.name != null ? value.name : ''
            });
        }
    }

    getFrequencyValue = (event) => {
        this.setState({
            validations: { ...this.state.validations, frequency: true },
            frequency: event.target.value
        });
    }

    getLowerFrequencyValue = (event) => {
        this.setState({
            lowerFrequency: event.target.value
        });
    }

    getUpperLimitValue = (event) => {
        this.setState({
            validations: { ...this.state.validations, upperLimit: true },
            upperLimit: event.target.value
        });
    }

    getLowerLimitValue = (event) => {
        this.setState({
            lowerLimit: event.target.value
        });
    }

    handleClearClick = () => {
        this.setState({
            frequency: 0,
            lowerFrequency: 0,
            upperLimit: 0,
            lowerLimit: 0,
            side: '',
            currencyId: '',
            currencyName: '',
            countryId: '',
            countryIdName: '',
            customerLimitProfile: {
                CustomerLimitProfileID: 0,
                CustomerID: 0,
                CurrencyID: "",
                BuySell: "",
                CountryId: "",
                LowerLimit: 0,
                UpperLimit: 0,
                Frequency: 0,
                LowerFrequency: 0,
                CreatedBy: "",
                CreatedOn: "",
                UpdatedBy: "",
                UpdatedOn: "",
                UpdateToken: ""
            },
            validations: {
                buySell: true,
                country: true,
                currency: true,
                frequency: true,
                upperLimit: true,
            }
        });
    }

    handleSaveClick = () => {
        let userID = localStorage.getItem('UserID');
        var model = {
            CustomerLimitProfileID: this.state.customerLimitProfile.CustomerLimitProfileID,
            CustomerID: this.state.customerId,
            CurrencyID: this.state.currencyId,
            BuySell: this.state.side,
            CountryID: this.state.countryId,
            Frequency: this.state.frequency,
            LowerFrequency: this.state.lowerFrequency,
            UpperLimit: this.state.upperLimit,
            LowerLimit: this.state.lowerLimit,
            UpdatedBy: userID,
            UpdateToken: this.state.customerLimitProfile.UpdateToken
        }

        console.table(model);
        if (this.validateRequiredInputs()) {

            if (this.state.upperLimit > this.state.lowerLimit) {
                this.setState({ loading: true });
                saveLimitProfile(model).then((json) => {
                    console.log(json);
                    if (json.httpStatusCode !== 200 && json.httpStatusCode !== 409) {
                        this.setState({
                            showAlert: true,
                            alertMessage: json.httpErrorMessage,
                            alertTitle: 'Error',
                            loading: false
                        });
                    } else if (json.httpStatusCode === 409){
                        this.setState({ 
                            showAlertError: true, 
                            alertMessage: json.httpErrorMessage, 
                            alertTitle: 'Error',
                            loading: false
                        });
                    } else {
                        this.setState({
                            customerLimitProfile: {
                                ...this.state.customerLimitProfile,
                                UpdateToken: json.updateToken
                            },
                            showAlert: true,
                            alertTitle: 'Success',
                            alertMessage: 'Limit profile successfully saved.',
                            loading: false
                        });
                        this.updateGrid();
                    }
                })
            } else {
                this.setState({
                    showAlert: true, alertTitle: 'Error', alertMessage: 'Upper Limit is lower than Lower Limit. Please, try again.'
                });
            }
        }
    }

    validateRequiredInputs = () => {
        const frequencyValidation = () => {
            if ((this.state.frequency !== 0 || this.state.lowerFrequency !== 0) && this.state.frequency <= this.state.lowerFrequency) {
                return false;
            }
            return true;
        }

        const upperLimitValidation = () => {
            if (this.state.upperLimit === 0 || (this.state.upperLimit !== 0 && this.state.upperLimit <= this.state.lowerLimit)) {
                return false;
            }
            return true;
        }
        
        const fields = [
            {displayName: 'Buy/Sell', validateName: 'buySell', value: this.state.side, type: 'string'},
            {displayName: 'Country', validateName: 'country', value: this.state.countryId, type: 'string'},
            {displayName: 'Currency', validateName: 'currency', value: this.state.currencyId, type: 'string'},
            {displayName: 'Frequency', validateName: 'frequency', value: this.state.frequency, validation: frequencyValidation},
            {displayName: 'Upper Limit', validateName: 'upperLimit', value: this.state.upperLimit, validation: upperLimitValidation}
        ]

        const [valid, validations, errors] = validate(fields);

        this.setState({ validations });

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors: errors
            });
        }

        return valid;
    }

    updateGrid = async () => {
        this.setState({ loading: true })
        const limitProfiles = await this.getAllLimitProfiles();
        const totalCount = limitProfiles.length;
        const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
        this.setState({ limitProfiles, totalPages, loading: false });
    }

    getAllLimitProfiles = async () => {
        const json = await getAllLimitProfilesByCustomerID(this.props.id, 0, 0);
        if (json !== undefined && json !== null) {
            this.setState({ requiresEdd: json.RequiresEdd })
        }
        if (json.limitProfiles !== undefined) {            
            return json.limitProfiles;
        } else {
            return [];
        }
    }

    closeDeleteAlert = () => {
        this.setState({ showDeleteAlert: false });
    }

    okSuccessDeleteAlert = () => {
        this.setState({ showSuccessDeleteAlert: false });
    }

    yesDeleteAlert = async () => {
        this.setState({ showDeleteAlert: false, loading: true });
        const value = await deleteCustomerLimitProfile(this.state.readyToDeleteObj['CustomerLimitProfileId']);
        console.log(value);
        this.setState({
            showSuccessDeleteAlert: true,
            loading: false
        });
        this.updateGrid();
    }

    render() {
        return (<div>
            <div className="">
                <ul id="accordion-limit-profiles" uk-accordion="multiple: true">
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">Limit Profiles</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="limit-profiles">
                                <form className="uk-form-horizontal uk-margin">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel} >Buy/Sell (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect
                                                    className={formatValidSelect(this.state.validations.buySell)}
                                                    objValue={{ value: this.state.side }}
                                                    getValue={this.handleGetValueBuySell}
                                                    placeholder='Select a value'
                                                    data={this.state.buysell}
                                                    id="form-limitprofile-side" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Currency (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect className={formatValidSelect(this.state.validations.currency)} objValue={{value: this.state.currencyId, name: this.state.currencyName}} getValue={this.handleGetValueCurrency} placeholder={"Select an Item..."} data={this.state.currencies} id="limit-profiles-currencies" />
                                                {/* <DynamicSearchSelect
                                                    className={formatValidSelect(this.state.validations.country)}
                                                    id="currency-list"
                                                    objValue={{ value: this.state.currencyId, name: this.state.currencyName }}
                                                    placeholder="Enter a Currency..."
                                                    searchFunction={this.search}
                                                    data={this.state.currencies}
                                                    getValue={this.handleGetValueCurrency} /> */}
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Country (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <DynamicSelect id="form-limitprofile-country" className={formatValidSelect(this.state.validations.country)} placeholder={"Select an Item..."}  getValue={this.getCountryValue}  data={this.state.countries} objValue={{ value: this.state.countryId, name: this.state.countryIdName }} />
                                                {/* <DynamicSearchSelect
                                                    className={formatValidSelect(this.state.validations.country)}
                                                    id="form-limitprofile-country"
                                                    placeholder="Select a country"
                                                    searchFunction={this.searchCountry}
                                                    getValue={this.getCountryValue}
                                                    data={this.state.countries}
                                                    objValue={{ value: this.state.countryId, name: this.state.countryIdName }}
                                                /> */}
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">                                            
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel} >Lower Limit</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <NumberInput className="uk-input" type="Currency" placeholder="" value={this.state.lowerLimit}
                                                    scale={getScaleByCurrency(this.state.currencyId)} onChange={this.getLowerLimitValue} disabled={this.props.requiresEdd === 0}/>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel} >Upper Limit (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <NumberInput className={formatValidInput(this.state.validations.upperLimit)} type="Currency" placeholder="" value={this.state.upperLimit}
                                                    scale={getScaleByCurrency(this.state.currencyId)} onChange={this.getUpperLimitValue} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Lower Frequency</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <NumberInput className="uk-input" type="Integer" placeholder="" value={this.state.lowerFrequency}
                                                    onChange={this.getLowerFrequencyValue} disabled={this.props.requiresEdd === 0}/>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Frequency (*)</label>
                                            <div className="uk-margin" style={style.inputStyle}>
                                                <NumberInput className={formatValidInput(this.state.validations.frequency)} type="Integer" placeholder="" value={this.state.frequency}
                                                    onChange={this.getFrequencyValue} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="uk-width-1-3">
                    <hr />
                    <button className="uk-button uk-button-green" type="button" onClick={this.handleClearClick} >Clear Fields</button>
                    <button className="uk-button uk-button-green" type="button" onClick={this.handleSaveClick} >Save</button>
                    <hr />
                </div>
                <NewDynamicTable
                    hiddenHeaders={['CustomerLimitProfileId', 'Country ID']}
                    data={this.state.limitProfiles}
                    formats={[
                        { header: 'Upper Limit', formatFunction: formatToCurrencyWithScale, formatCurrency:'Currency' }
                    ]}
                    enableClick={true}
                    clickFunction={this.handleRowClick}
                    newSearch={this.state.newSearch}
                    useDeleteButton={true}
                    deleteFunction={this.handleDeleteClick}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={getDefaultPagingNumberOfItems()}
                    enableSortByHeader={true}
                    useFilter={true}
                    filterFunction={this.getAllLimitProfiles} 
                    useExportButton={true}
                    tableName="limit_profiles" 
                    useIcons={true}
                    />

                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlertError} />

                <AlertBox id="delete-limit-profile" title="Are you sure you want to delete this customer profile?" message="This action can not be undone." type="Yes/No"
                    open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />

                <AlertBox id="confirmation-delete-limit-profile" title="Success" open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} message="Customer profile successfully removed." type="Ok" okClick={this.okSuccessDeleteAlert} />
                <LoadingBox loading={this.state.loading} />
            </div>
        </div>)
    }
}