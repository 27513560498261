import jwt from 'jsonwebtoken';
import { genericGetWithParameters, genericCallWithBody, genericDeleteWithParameters, genericCallWithBodyAndResponseDecoded, genericGetWithParametersNoEncrypted } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getContactsByCustomer(customerID,page,items){
    try {
        let params = {
            'CustomerID': customerID,
            'Page': page,
            'Items': items,
        }

        let response = await genericGetWithParameters('Contact/GetContactsByCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function GetActiveContactsByCustomer(customerID,page,items){
    try {
        let params = {
            'CustomerID': customerID,
            'Page': page,
            'Items': items,
        }

        let response = await genericGetWithParameters('Contact/GetActiveContactsByCustomer',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getContactByID(contactID){
    try {
        let params = {
            'ContactID': contactID
        }
        let response = await genericGetWithParameters('Contact/GetContactInformationByContactID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveContact(model){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Contact/SaveContact'
    let response = await genericCallWithBody(method, apiName, model)
    return response;
}

export async function getContactOnlineInfo(customerID, personID,emailAddress){
    try {
        let params = {
            "CustomerId":customerID,
            "PersonId":personID,
            "EmailAddress":emailAddress
        };

        let response = await genericGetWithParameters('Contact/GetContactOnlineInfo',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCustomerContactsMin(customerID){
    try {
        let params = {
            "CustomerId":customerID,
        };

        let response = await genericGetWithParameters('Contact/GetCustomerContacts_min',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveRelatedCustomerRoles(relatedCustomerId, email, contactId, roles)
{
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Role/SaveRelatedCustomerRoles'
    //try{
        var request = {
            relatedCustomerId: relatedCustomerId,
            email: email,
            applicationName: 'ForeignExchange',
            contactId: contactId,
            roles: roles,
        }
        let response = await genericCallWithBody(method, apiName, request)
        return response;
    /*} catch (error) {
        console.error(error);
    }*/
}
/*Contact/DeleteContact?contactID={contactID}&personID={personID} */
export async function deleteContact(contactID) {
    try{
        let params = {
            'contactID': contactID,
        };
        let response = await genericDeleteWithParameters('Contact/DeleteContact',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function ValidateSendOnlineAccessEmail(validateOnlineAccessEmailRequest){
    let method = 'POST';
    let apiName = 'Contact/ValidateSendOnlineAccessEmail';
    let response = await genericCallWithBody(method, apiName, validateOnlineAccessEmailRequest);
    return response;
}

export async function ValidatePasswordForOnlineAccessEmail(validatePasswordForOnlineAccessEmail){
    let method = 'POST';
    let apiName = 'Contact/ValidatePasswordForOnlineAccessEmail';
    let response = await genericCallWithBody(method, apiName, validatePasswordForOnlineAccessEmail);
    return response;
}

export async function SendOnlineEmailAccess(sendOnlineEmailAccessRequest){ 
    try{
        let method = 'POST';
        let apiName = 'Contact/SendOnlineAccessEmail';
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, sendOnlineEmailAccessRequest);
        return response;
    } catch (error){
        console.error(error);
    }
}

export const ChangeContactStatus = async (model) => {
    try
    {
        const response = await genericCallWithBody('PUT',  'Contact/ChangeContactStatus', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const verifyContact = async (model) => {
    try
    {
        const response = await genericCallWithBody('POST',  'DataVerification/VerifyContact', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getContactInfoMin(contactID){
    try {
        let params = {
            'ContactID': contactID
        }
        let response = await genericGetWithParameters('Contact/GetContactInformationMin', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}