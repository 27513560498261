import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import NewEditableGrid from '../../shared/NewEditableGrid';
import { getStyle } from '../../../styles/styles';
import AppContext from '../../../AppContext';
import AlertBox from '../../shared/AlertBox';
import { getEODParameters } from '../../../helpers/PackageJsonHelper';
import { getAdminActions, saveAdminActions } from '../../../networking/NetworkingActions';

const style = getStyle();


const NewDynamicActionsScreen = () => {
    const context = useRef(useContext(AppContext));
    const ApplicationName = useMemo(() => getEODParameters().ApplicationName, []);
    const [data, setData] = useState([]);
    const [columnDetail, setColumnDetail] = useState(null);

    const [reload, setReload] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
      async function loadGrid() {
        const json = await getAdminActions(ApplicationName,0, 0);
        
        if (json != null && json.result != null) {
            setData(json.result);
            if (json.columnDetail != null) {
                const _columnDetail = JSON.parse(json.columnDetail);
                setColumnDetail(_columnDetail);
            }
        }
      }
      loadGrid();
    }, [reload]);

    const save = async (record, row) => {        
        try {
            const userID = localStorage.getItem('UserID');
            const model = {
                ApplicationName: ApplicationName,
                ActionName: row.ActionName,
                Rule: row.Rule,
                Description: row.Description,
                ShortName: row.ShortName,
                Keywords: row.Keywords,
                IconName: row.IconName,
                UserID: userID,
                UpdateToken: ''
            };

            let json;
            context.current.startLoading();

            json = await saveAdminActions(model);   

            context.current.finishLoading();

            if (json != null) {
                if (json.httpStatusCode === 200) {
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Action successfully saved.');
                    setReload(!reload);
                } else {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.Message);
                    
                }
            }

          } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
          }
    }

    const edit = async (record, row) => {        
        try {

            const userID = localStorage.getItem('UserID');
            const model = {
                ApplicationName: ApplicationName,
                ActionName: record.ActionName,
                Rule: row.Rule,
                Description: row.Description,
                ShortName: row.ShortName,
                Keywords: row.Keywords,
                IconName: row.IconName,
                UserID: userID,
                UpdateToken: record.UpdateToken
            };

            let json;
            context.current.startLoading();

            json = await saveAdminActions(model);   

            context.current.finishLoading();

            if (json != null) {
                if (json.httpStatusCode === 200) {
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Action successfully updated.');
                    setReload(!reload);
                } else {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.Message);
                    
                }
            }

          } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
          }
    }

    const closeAlert = () => {
        setShowAlert(false);
        setAlertTitle('');
        setAlertMessage('');
    };

    return (
        <div>
            <h3 className="uk-heading-divider" style={style.titleStyle} >Admin Actions</h3>
            <NewEditableGrid
                data={data}
                columnDetails={columnDetail}
                isEditable={true}
                editSaveFunction={edit}
                canAdd={true}
                useIcons={true}
                addSaveFunction={save}
            />
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
}

export default NewDynamicActionsScreen;
