import zIndex from '@material-ui/core/styles/zIndex';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import React from 'react';
import moment from 'moment';
import { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import '../../css/react-day-picker.css';
import { currentYear, fromMonth, toMonth, YearMonthForm } from './Calendar';
import { DatePicker as NewDayPicker } from 'antd';

const FORMAT = 'yyyy/MM/dd';
const FORMAT_NEW_DAYPICKER = 'YYYY/MM/DD';

function OverlayComponent({ children, ...props }) {
    return (
      <div {...props} className='date-picker-custom'>
        { children }
      </div>
    );
  }


export default class DatePicker extends React.Component {
    state = {
        startDate: new Date(),
        endDate: new Date(),
        disabledDays: [],
        month: new Date(currentYear, new Date().getMonth())
    }

    componentDidMount(){
        var startDate = new Date(1901,0,1);
        var endDate = new Date(2050,11,31);

        if(this.props.startDate !== undefined){
            startDate = this.props.startDate;
        }

        if(this.props.endDate !== undefined){
            endDate = this.props.endDate;
        }

        this.setState({startDate: startDate, 
            endDate:endDate,
        });

        /*if(this.props.after !== undefined && this.props.before !== undefined){
            var dateAfter = this.parseDate(this.props.before, FORMAT, null);
            var dateBefore = this.parseDate(this.props.after, FORMAT, null);
            this.setState({after: dateAfter, before: dateBefore});
        } else {
            this.setState({after: new Date(2050,11,31) , before: new Date(1901,0,1)});
        }*/

        if(this.props.disabledDays !== undefined){
            this.setState({disabledDays: this.props.disabledDays});
        }
    }

    componentDidUpdate(prevProps) {
        if(/*prevProps.startDate !== undefined &&*/ this.props.startDate !== undefined){
            if (prevProps.startDate !== this.props.startDate){
                this.setState({startDate: this.props.startDate});
            }
        }

        if(/*prevProps.endDate !== undefined &&*/ this.props.endDate !== undefined){
            if (prevProps.endDate !== this.props.endDate){
                this.setState({endDate: this.props.endDate});
            }
        }
    }

    parseDate(str, format, locale) {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
          return parsed;
        }
        return undefined;
      }
      
    formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
    } 

    handleYearMonthChange = (month) => {
        this.setState({ month });
    }

    checkInvalidValue = (value) => {
        return (value != null && value !== '');
    };

    OverlayComponentTop = ({ children}) => {
        return (
          <div className='DayPickerInput-OverlayWrapperTop' style={{position: 'absolute',top: this.props.calendarTop }} >
            { children }
          </div>
        );
      }
    
    render() {
        let disabled = false;
        if (this.props.disabled !== undefined){
            disabled = this.props.disabled;
        }

        let readOnly = false;
        if (this.props.readOnly !== undefined){
            readOnly = this.props.readOnly;
        }

        let inputProps = {
            id: this.props.id,
            className: this.props.className,
            readOnly: readOnly,
            disabled: disabled,
            autoFocus: this.props.autoFocus
        };

        if (this.props.useNew) {
            const _dateValue = this.checkInvalidValue(this.props.value) ? (moment(this.props.value, FORMAT_NEW_DAYPICKER)) : null;
            return (
                <div>
                    <NewDayPicker
                        id={this.props.id}
                        className={this.props.className}
                        disabled={this.props.disabled}
                        autoFocus={this.props.autoFocus}
                        inputReadOnly={this.props.readOnly}
                        format={FORMAT_NEW_DAYPICKER}
                        value={_dateValue}
                        onChange={this.props.onDayChange} />
                </div>
            )
        }

        if(this.props.modeCustomArturo)
        {
            return (
                <div>
                    <DayPickerInput
                        inputProps          = {inputProps}
                        dayPickerProps      = {{
                            disabledDays: [{ daysOfWeek: this.state.disabledDays }, { after: this.state.endDate, before: this.state.startDate }, this.props.isHoliday],
                            ...(!this.props.disableDropdown && {
                                fromMonth: fromMonth,
                                toMonth: toMonth,
                                month: this.state.month,
                                captionElement: ({ date, localeUtils }) => <YearMonthForm date={date} localeUtils={localeUtils} onChange={this.handleYearMonthChange} />
                            }),
                        }}
                        value               ={this.props.value}
                        onDayChange         ={this.props.onDayChange}
                        formatDate          ={this.formatDate.bind(this)}
                        format              ={FORMAT}
                        parseDate           ={this.parseDate.bind(this)}
                        placeholder         ={'Select a date...'}
                        onDayPickerHide     = {this.props.onDayPickerHide}
                        overlayComponent    = {OverlayComponent}
                        keepFocus           = {false}
                    />
                </div>
            );
        }else
        {
            if(this.props.calendarTop && this.props.calendarTop > 0)
            {
            return (
                <div >
                    <DayPickerInput
                        inputProps={inputProps}
                        dayPickerProps={{
                            disabledDays: [{ daysOfWeek: this.state.disabledDays }, { after: this.state.endDate, before: this.state.startDate }, this.props.isHoliday],
                            ...(!this.props.disableDropdown && {
                                fromMonth: fromMonth,
                                toMonth: toMonth,
                                month: this.state.month,
                                captionElement: ({ date, localeUtils }) => <YearMonthForm date={date} localeUtils={localeUtils} onChange={this.handleYearMonthChange} />
                            }),
                        }}
                        value={this.props.value}
                        onDayChange={this.props.onDayChange}
                        formatDate={this.formatDate.bind(this)}
                        format={FORMAT}
                        parseDate={this.parseDate.bind(this)}
                        placeholder={'Select a date...'}
                        overlayComponent    = {this.OverlayComponentTop}
                    />
                </div>
            );
            }
            else
            {
                return (
                    <div >
                        <DayPickerInput
                            inputProps={inputProps}
                            dayPickerProps={{
                                disabledDays: [{ daysOfWeek: this.state.disabledDays }, { after: this.state.endDate, before: this.state.startDate }, this.props.isHoliday],
                                ...(!this.props.disableDropdown && {
                                    fromMonth: fromMonth,
                                    toMonth: toMonth,
                                    month: this.state.month,
                                    captionElement: ({ date, localeUtils }) => <YearMonthForm date={date} localeUtils={localeUtils} onChange={this.handleYearMonthChange} />
                                }),
                            }}
                            value={this.props.value}
                            onDayChange={this.props.onDayChange}
                            formatDate={this.formatDate.bind(this)}
                            format={FORMAT}
                            parseDate={this.parseDate.bind(this)}
                            placeholder={'Select a date...'}
                        />
                    </div>
                );
            }
        }
    }
}