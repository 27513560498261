import { Breadcrumb } from 'antd';
import React from 'react';
import { getLookUpTable } from '../../../networking/Networking';
import { addLookUpTableDetails, getAllLookUpTable, getLookUpTableByID, addLookUpTable } from '../../../networking/NetworkingLookUpTables';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import NewDynamicTable from '../../shared/NewDynamicTable';
import LookUpDeliveryMethod from './LookUpDeliveryMethod';
import LookUpTableDetailsForm from './LookUpTableDetailsForm';
import LookUpTableForm from './LookUpTableForm';
import NewEditableGrid from '../../shared/NewEditableGrid'
import { SearchOutlined, EditOutlined } from '@ant-design/icons';

var style = getStyle();

const items = 16;
export default class LookUpTableScreen extends React.Component {
    
    state = {
        showAlert: '',
        alertTitle: '',
        alertMessage: '',
        notFirstLoad: false,
        newSearch: false,
        showDetails: false,
        isDisable: true,
        isDisableMethod: true,
        obDdrwns: [],
        activeTab1: 'uk-active',
        activeTab2: 'uk-disabled',
        activeTab3: 'uk-disabled',
        isValidate:'',
        clickPencil: false,
        //---
        name:'',
        field4:'',
        field4Details:'',
        objLookUp: {},
        objMethod:{},
        objDetails:{},
        lookUpTables: [],
        totalPages: 0,
        pages:1,
        showRiskScore: false,
        columnDetail: {}
    }

    componentDidMount(){
        this.updateLookUpTables();
    }

    updateLookUpTables(){
        getAllLookUpTable(0,0).then(
            (json)=>{
                console.log(json);
                var helper = this.transformBoolean(json.countries);
                var columnDetail = JSON.parse(json.columnDetail);
                var totalCount = json.totalCount;
                var pages = Math.ceil(totalCount/items);
                this.setState({ lookUpTables: helper, totalPages: pages, newSearch: false, columnDetail: columnDetail});
            }
        );
    }

    getAllLookUp = async () =>{
        var json = await getAllLookUpTable(0,0);
        var lookuptables = this.transformBoolean(json.countries);
        return lookuptables;
    }
    changePage = value =>{
        this.setState({pages: value})
    }

    transformBoolean = list =>{
        var helper = list;
        for(var i=0; i<helper.length;i++){
            if(helper[i]['Is Risk Factor']){
                helper[i]['Is Risk Factor'] = 'True';
            }else{
                helper[i]['Is Risk Factor'] = 'False';
            }
            helper[i]['Dynamic Details'] = helper[i]['Dynamic Details'] ? 'True' : 'False'
        }
        return helper;
    }

    handleRowClick = obj =>{
        console.group("LookUpTables handleRowClick")
        console.log(obj);
        console.groupEnd();
        getLookUpTableByID(obj.ID).then(
            (json)=>{
                this.setState({objLookUp: json, isDisable: false, isDisableMethod: false});
            }
        );
    }

    handleSelectClick = obj =>{
        console.group("LookUpTables handleSelectClick")
        console.log(obj);
        console.groupEnd();
        getLookUpTable(obj.Description).then(
            (json) => {
                if(json != null && json.lookUpTables!= null) {
                    const lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails
                        .filter((lookUpTable) => lookUpTable.LookUpTableDetail !== 'select')
                        .map((lookUpTable) => ({...lookUpTable, value: lookUpTable.LookUpTableDetail, name: lookUpTable.LookUpTableDetail}));
                    const showRiskScore = obj['Is Risk Factor'].toLowerCase() === 'true';
                    this.setState({ showDetails: true, obDdrwns: lookUpTableDetails, name: obj, newSearch: false, isDisableMethod: false, isDisable: false, showRiskScore });
                }else{
                    this.setState({ showDetails: true, obDdrwns: [], name: obj, newSearch: false, isDisableMethod: false, isDisable: false, showRiskScore: false });
                }
        });
    }

    getLookUpTableDetails=(value)=>{
        getLookUpTable(value).then(
            (json) => {
                var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
                var helper = [];
                for(var i=0; i<lookUpTableDetails.length; i++){
                    lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                    lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                    helper.push(lookUpTableDetails[i]);
                }
                console.log(helper);
                this.setState({dropdowns: helper});
        });
    }
    
    objDetailsTab = obj =>{
        this.setState({objDetails:obj,field4: obj.field4})
        if(obj.clickPencil&&this.state.clickPencil){
            this.setState({isDisable: false, isDisableMethod: false, 
                activeTab1: '', activeTab2:'uk-disabled', 
                activeTab3:'uk-active'})
        }else{
            this.setState({isDisable: false, isDisableMethod: false, 
                activeTab1: '', activeTab2:'uk-active', 
                activeTab3:'uk-disabled'})
            }
    }
    objMethod = obj =>{
        var objFilter = this.transformListInput(obj)
        this.setState({objMethod:objFilter,field4Details:objFilter})
    }
    transformListInput = listMethod =>{
        var helper = listMethod;
        if(helper.length>0){
            var list = [helper[0].name]
            for(var i=1; i<helper.length;i++){
                list[0] += '§'+ helper[i].name;
            }
            return list;
        }
    }
    //--Save RelatedItem (Method)
    
    handleSaveClick = () =>{
        var editable = 0
        var obj = ''
        if (this.state.objDetails.isEditable) { editable = 1 } else { editable = 0 }
        if (this.state.objMethod != null && this.state.objMethod[0] != null) { obj = this.state.objMethod[0] } else { obj = '' }
        var objFilter = {
            'LookUpTableId': this.state.name.ID,
            'Description': this.state.objDetails.name,
            'KeyDescription': this.state.objDetails.keyDescription,
            'DisplayOrder': this.state.objDetails.displayOrder,
            'Editable': editable,
            'Field1': this.state.objDetails.field1,
            'Field2': this.state.objDetails.field2,
            'Field3': this.state.objDetails.field3,
            'Field4': obj,
            'Field5': this.state.objDetails.field5,
            'Field6': this.state.objDetails.field6,
            'MappingId': this.state.objDetails.mappingId,
            'RiskScore': this.state.objDetails.riskScore
        }
        if(this.state.objDetails.name!==''){
            addLookUpTableDetails(objFilter).then(
                (json)=>{
                    if(json.status!==200){
                        this.setState({showAlert: true, alertMessage: 'An error occurred while trying to save the Look Up Table Detail. Please try again.', alertTitle: 'Error'});
                    }else{
                        this.updateLookUpTable()
                        this.setState({showAlert: true, alertTitle: 'Related Items successfully saved.'})
                    }
                }
            );
        }else{   
            this.setState({showAlert: true, alertMessage: 'The name is incomplete in Lookup Detail. Please try again', alertTitle: 'Error'});
        }
    }
    
    viewDetails= async (obj) => {
        //await updateMessageAsRead(record.MessageId);
        this.handleSelectClick(obj);
        switch (obj.Description) {
            case 'Incoming Instruments':
                this.setState({isDisable: false, isDisableMethod: false, 
                    activeTab1: 'uk-inactive', activeTab2:'uk-active', 
                    activeTab3:'', clickPencil: true})
                break;
            case 'Outgoing Instruments':
                this.setState({isDisable: false, isDisableMethod: false, 
                    activeTab1: 'uk-inactive', activeTab2:'uk-active', 
                    activeTab3:'', clickPencil: true})
                break;
        
            default:
                this.setState({isDisable: false, isDisableMethod: false, 
                    activeTab1: 'uk-inactive', activeTab2:'uk-active', 
                    activeTab3:'uk-disabled', clickPencil: false})
                break;
        }
    }

    updateLookUpTableNewGrid = async (record, row) => {

        let userID = localStorage.getItem('UserID');

        var objFilter = {
            "LookUpTableId": record.ID,
            "Description": row['Description'],
            "IsRiskFactor": row['Is Risk Factor'],
            "DynamicDetails": row['Dynamic Details'],
            "UserId": userID,
            "UpdateToken": record['UpdateToken']
        }

        addLookUpTable(objFilter).then(
            (json) => {
                if (json.status !== 200) {
                    this.setState({ showAlert: true, alertMessage: 'An error occurred while trying to save the Look Up Table. Please try again.', alertTitle: 'Error' });
                } else {
                    this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: 'Look Up Table successfully saved.', updateToken: json.updateToken });
                    this.updateLookUpTables();
                    //this.props.clearLookUp();
                    //this.props.updateLookUpTables();
                }
            }
        );
    }

    updateLookUpTable(){
        getLookUpTable(this.state.name.Description).then(
            (json) => {
                var helper = [];
                if(json!={} && json.lookUpTables!==undefined){
                    var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
                    var i=0
                    if(lookUpTableDetails[0].LookUpTableDetail.includes('select')){i=0}else{i=1}
                    for(i; i<lookUpTableDetails.length; i++){
                        lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                        lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                        helper.push(lookUpTableDetails[i]);
                    }
                    console.log(helper);
                    this.setState({obDdrwns: helper});
                }
                this.setState({obDdrwns: helper});
        });
    }
    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }
    action1 = (obj) =>{
        this.handleSelectClick(obj);
        switch (obj.Description) {
            case 'Incoming Instruments':
                this.setState({isDisable: false, isDisableMethod: false, 
                    activeTab1: 'uk-inactive', activeTab2:'uk-active', 
                    activeTab3:'', clickPencil: true})
                break;
            case 'Outgoing Instruments':
                this.setState({isDisable: false, isDisableMethod: false, 
                    activeTab1: 'uk-inactive', activeTab2:'uk-active', 
                    activeTab3:'', clickPencil: true})
                break;
        
            default:
                this.setState({isDisable: false, isDisableMethod: false, 
                    activeTab1: 'uk-inactive', activeTab2:'uk-active', 
                    activeTab3:'uk-disabled', clickPencil: false})
                break;
        }
    }
    onClicTab = (obj) =>{
        this.setState({isDisable: false, isDisableMethod: false, 
            activeTab1: 'uk-active', activeTab2:'uk-disabled', 
            activeTab3:'uk-disabled'})
    }
    isValidate = (value) =>{
        if(value){
            this.setState({isValidate: true})
        }else{
            this.setState({isValidate: false})
        }
    }
    
    isBack = (value) =>{
        if(value){
            this.setState({isDisable: false, isDisableMethod: false, 
                activeTab1: '', activeTab2:'uk-active', 
                activeTab3:'uk-disabled'})
        }else{
            this.setState({isDisable: false, isDisableMethod: false, 
                activeTab1: '', activeTab2:'uk-disabled', 
                activeTab3:'uk-active'})
        }
    }

    clearLookUp = () => {
        this.setState({
            objLookUp: {}
        })
    }

    render() {
        var active1 = this.state.activeTab1;
        var active2 = this.state.activeTab2;
        var active3 = this.state.activeTab3;

        let breadcrumb = [];
        let screen = [];

        if (active1 === 'uk-active') {
            /*screen.push(
                <LookUpTableForm getDropdowns={this.state.objLookUp} updateLookUpTables={this.updateLookUpTables.bind(this)} clearLookUp={this.clearLookUp}/>
            );*/
            /*screen.push(
                <NewDynamicTable 
                    hiddenHeaders={['ID']} 
                    data={this.state.lookUpTables} 
                    enableClick={true} 
                    clickFunction={this.handleRowClick} 
                    dynamicActions={[{name: 'Edit', function: this.action1, isIcon: true}]} 
                    useFilter={true} 
                    filterFunction={this.getAllLookUp} 
                    newSearch={this.state.newSearch} 
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber = {this.state.pages}
                    numberPerPage = {items}
                    enableSortByHeader={true}/> 
            );*/
            screen.push(
                <NewEditableGrid
                    data={this.state.lookUpTables}
                    columnDetails={this.state.columnDetail}
                    isEditable={true}                    
                    editSaveFunction={this.updateLookUpTableNewGrid}
                    useExportButton={true}
                    tableName="LookUp Tables"
                    useIcons={true} 
                    canAdd={true}
                    addSaveFunction={this.updateLookUpTableNewGrid}
                    dynamicActions={[{name: 'Details', function: this.viewDetails, isIcon: true, icon: <SearchOutlined />}]}/>
            );
            
            breadcrumb.push(
                <Breadcrumb.Item className="breadcrumb-item-bold">LookUp Tables</Breadcrumb.Item>
            );
        } else if (active2 === 'uk-active') {
            screen.push(
                <LookUpTableDetailsForm getDropdowns={this.state.obDdrwns} nameDetail = {this.state.name} field4={this.state.field4Details} isDisable={this.state.isDisable} objDetails = {this.objDetailsTab} showRiskScore={this.state.showRiskScore}/>
            );
            breadcrumb.push(
                <Breadcrumb.Item className="breadcrumb-item" onClick={this.onClicTab}>LookUp Tables</Breadcrumb.Item>
            );
            breadcrumb.push(
                <Breadcrumb.Item className="breadcrumb-item-bold">LookUp Details - {this.state.name.Description}</Breadcrumb.Item>
            );
        } else if (active3 === 'uk-active') {
            screen.push(
                <LookUpDeliveryMethod name = {this.state.name} field4={this.state.field4} isDisable={this.state.isDisableMethod} isBack = {this.isBack} isValidate = {this.isValidate} objMethod = {this.objMethod} save = {this.handleSaveClick}/>
            );
            breadcrumb.push(
                <Breadcrumb.Item className="breadcrumb-item" onClick={this.onClicTab}>LookUp Tables</Breadcrumb.Item>
            );
            breadcrumb.push(
                <Breadcrumb.Item className="breadcrumb-item" onClick={() => {this.isBack(true)}}>LookUp Details - {this.state.name.Description}</Breadcrumb.Item>
            );
            breadcrumb.push(
                <Breadcrumb.Item className="breadcrumb-item-bold">Delivery Method - {this.state.objDetails.name}</Breadcrumb.Item>
            );
        }

        return (
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        {breadcrumb}
                    </Breadcrumb>
                </div>
                <div className="uk-margin">
                    {screen}
                </div>                
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
            </div>
        );
    }
}