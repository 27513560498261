import React, { useEffect, useReducer, useRef, useState } from 'react';
import { formatDate, getScaleByCurrency, getScaleForCurrencyPair, roundAmountByCurrency, roundAmountByCurrencyPair } from '../../../helpers/FormatHelper';
import { getDealBuybackAndValidation, saveDealHeader } from '../../../networking/NetworkingDeals';
import { regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSelect from '../../shared/DynamicSelect';
import NumberInput from '../../shared/NumberInput';
import LoadingBox from '../../shared/LoadingBox';
import { getRate } from '../../../networking/NetworkingRates';
import DatePicker from '../../shared/DatePicker';


const BuybackForm = (props) => {

    let { cancelBuyback, customerDeal, valueDate, onBuybackSuccess, bankCustomers } = props

    const [sellLocked, setSellLocked] = useState('');
    const [buyLocked, setBuyLocked] = useState('');
    const [marketRate, setMarketRate] = useState(0);
    const [marketRateLabel, setMarketRateLabel] = useState('');
    const [customerRate, setCustomerRate] = useState(0);
    const [sellAmount, setSellAmount] = useState(0);
    const [buyAmount, setBuyAmount] = useState(0);
    const [rateTerms, setRateTerms] = useState(0);
    const [balance, setBalance] = useState(-1);
    const [sellBalance, setSellBalance] = useState(-1);
    const [validationError, setValidationError] = useState('')
    const [rateQuoteId, setRateQuoteId] = useState('')
    const [disableBookNow, setDisableBookNow] = useState(false)
    const [netAmount, setNetAmount] = useState(0)    
    const [customerPaysUs, setCustomerPaysUs] = useState(0)
    const [customerPaysUsBuySide, setCustomerPaysUsBuySide] = useState(0)
    const [netAmountStyle, setNetAmountStyle] = useState({})
    const [bankInterface, setBankInterface] = useState({})
    const [useInterface, setUseInterface] = useState(false);

    const updatingSellAmount = useRef(false);
    const updatingBuyAmount = useRef(false);
    const updatingCustomerRate = useRef(false);
    const validateBalances = useRef(false)

    const [loadingDeal, setLoadingDeal] = useState(false);

    const [alertMessage, setAlertMessage] = useState({});
    const [loading, setLoading] = useState(false)
    const [newDeal, setNewDeal] = useState({})

    const loadBuybackInfo = async () => {
        let validationData = await getDealBuybackAndValidation(customerDeal.DealHeaderID)
        console.log('validationData', validationData)
        if (validationData !== undefined) {
            let sellBalance
            let balance = validationData['Disbursement balance']
            setBalance(balance)
            setBuyAmount(validationData['Disbursement balance'])
            sellBalance = balance * customerDeal.BuyAmount / customerDeal.SellAmount            
            setSellBalance(sellBalance)
            setSellAmount(sellBalance)
            setNetAmount(0)
            setValidationError(validationData['Error'])
            getRateForBuybackPair(balance)
        }
        return true;
    }

    const getRateForBuybackPair = async (buyAmountPar) => {
        let externalInterface = ''
        let externalInterfaceType = ''
        if (bankInterface !== undefined && bankInterface.CustomerID > 0 && useInterface) {
            externalInterface = bankInterface.BankInterface
            externalInterfaceType = bankInterface.InterfaceType
        }
        getRate(customerDeal.CustomerID,
            0,
            1,
            formatDate(new Date(valueDate)),
            formatDate(new Date(valueDate)),
            customerDeal.SellCurrency,
            customerDeal.BuyCurrency,
            buyAmountPar > 0 ? buyAmountPar : customerDeal.SellAmount,
            'Buy',
            0,
            false,
            null,
            null,
            externalInterface,
            externalInterfaceType).then(
                (response) => {
                    if (Number(response.httpStatusCode) === 200) {
                        let customerRate = response.CustomerRate
                        setRateQuoteId(response.RateQuoteID)
                        setCustomerRate(response.CustomerRate)
                        setMarketRate(response.MarketRate)

                        let newSellAmount = 0
                        if (response.RateTerms === 'D') {
                            newSellAmount = (buyAmountPar > 0 ? buyAmountPar : customerDeal.SellAmount) / customerRate
                        }
                        else {
                            newSellAmount = (buyAmountPar > 0 ? buyAmountPar : customerDeal.SellAmount) * customerRate
                        }
                        newSellAmount = Number(roundAmountByCurrency(newSellAmount, customerDeal.BuyCurrency))
                        setSellAmount(newSellAmount)
                    }
                }
            )
    }

    useEffect(() => {

        console.log('customer deal', customerDeal)
        setLoadingDeal(true)

        let marketRate = customerDeal.MarketRate
        let customerRate = customerDeal.CustomerRate
        setMarketRate(marketRate)
        setMarketRateLabel(customerDeal.DealType === 6 ? 'Forward Market Rate' : 'Market Rate')
        setCustomerRate(customerRate)

        if (customerDeal.UpdateBuyAmountField) {
            setBuyLocked('*')
        } else {
            setSellLocked('*')
        }

        setRateTerms(customerDeal.RateTerms)

        if (Number(customerDeal.AdjustedBy) > 0) {
            setDisableBookNow(true)
        }

        loadBuybackInfo()

        setLoadingDeal(false)

    }, [customerDeal.DealHeaderID]);

    useEffect(() => {
        if (validationError !== undefined && validationError !== null && validationError !== '') {
            setDisableBookNow(true)
            setAlertMessage({
                ...alertMessage,
                title: 'Error', showMessage: true, message: validationError
            })
        }
    }, [validationError]);

    useEffect(() => {
        if (netAmount > 0) {            
            let buySideAmount = Number(roundAmountByCurrency(customerPaysUs * (buyAmount / sellAmount), customerDeal.SellCurrency))            
            setCustomerPaysUsBuySide(buySideAmount)
        } else {
            setCustomerPaysUsBuySide(0)
        }
    }, [customerPaysUs, netAmount])

    useEffect(() => {
        if (balance <= 0) {
            return;
        }

        let netAmount = Number(roundAmountByCurrency(sellBalance - sellAmount, customerDeal.BuyCurrency))
        let backgroundColor = netAmount >= 0 ? '#CCFFCD' : '#FFD6D5'
        let newAmountText = `Net Amount: ${Math.abs(netAmount)} ${customerDeal.BuyCurrency} ${netAmount === 0 ? '' : netAmount >= 0 ? '(Paid by the customer)' : '(Returned to the customer)'}`

        setNetAmount(netAmount)
        setNetAmountStyle({
            backgroundColor: backgroundColor,
            text: newAmountText
        })

        if (netAmount >= 0) {
            setCustomerPaysUs(roundAmountByCurrency(netAmount, customerDeal.SellCurrency))
        }
        else {
            setCustomerPaysUs(0)
        }

    }, [sellAmount, sellBalance]);

    const validateBalance = (amount, calculated) => {
        if (balance === -1) {
            return true;
        }
        if (validateBalances.current && (amount > balance)) {
            setAlertMessage({
                ...alertMessage,
                showMessage: true,
                title: calculated ? 'Error - Invalid Rate' : 'Error',
                message: `The ${calculated ? 'Calculated buy' : 'Buy'} amount ${roundAmountByCurrency(amount, customerDeal.SellCurrency)} ${customerDeal.SellCurrency} can not be higher than the amount balance to buy back (${roundAmountByCurrency(balance, customerDeal.SellCurrency)} ${customerDeal.SellCurrency})`
            })
            return false;
        }
        return true;
    }

    const validateSellBalance = (amount, calculated) => {
        if (sellBalance === -1) {
            return true;
        }
        if (validateBalances.current && (amount > sellBalance)) {
            setAlertMessage({
                ...alertMessage,
                showMessage: true,
                title: calculated ? 'Error - Invalid Rate' : 'Error',
                message: `The ${calculated ? 'Calculated sell' : 'Sell'} amount ${roundAmountByCurrency(amount, customerDeal.BuyCurrency)} ${customerDeal.BuyCurrency} can not be higher than the amount balance to buy back (${roundAmountByCurrency(sellBalance, customerDeal.BuyCurrency)} ${customerDeal.BuyCurrency})`
            })
            return false;
        }
        return true;
    }

    const onChangeSellAmount = async (control) => {

        if (control === undefined || control.target === undefined || typeof control.target.value !== 'number') {
            return;
        }

        let newSellAmount = Number(control.target.value);
        if (newSellAmount === sellAmount) {
            return;
        }

        if (loadingDeal || updatingBuyAmount.current || updatingCustomerRate.current) {
            setSellAmount(newSellAmount)
            return;
        }

        let newCustomerRate = 1
        if (rateTerms === 'I') {
            newCustomerRate = buyAmount / newSellAmount
        }
        else {
            newCustomerRate = newSellAmount / buyAmount
        }

        newCustomerRate = Number(roundAmountByCurrencyPair(newCustomerRate, customerDeal.SellCurrency, customerDeal.BuyCurrency))

        setSellAmount(newSellAmount)

        await updateControlState('updatingSellAmount', true)
        setCustomerRate(newCustomerRate)
        await updateControlState('updatingSellAmount', false)
    }

    const onChangeBuyAmount = async (control) => {

        if (control === undefined || control.target === undefined || typeof control.target.value !== 'number') {
            return;
        }

        let newBuyAmount = Number(control.target.value);
        if (newBuyAmount === buyAmount) {
            return;
        }

        if (loadingDeal || updatingSellAmount.current || updatingCustomerRate.current) {
            setBuyAmount(newBuyAmount)
            return;
        }

        if (!validateBalance(newBuyAmount, false)) {
            setBuyAmount(buyAmount)
            return;
        }

        setBuyAmount(newBuyAmount)

        let newSellAmount = 0
        if (rateTerms === 'I') {
            newSellAmount = newBuyAmount / customerRate
        }
        else {
            newSellAmount = newBuyAmount * customerRate
        }
        newSellAmount = Number(roundAmountByCurrency(newSellAmount, customerDeal.BuyCurrency))

        await updateControlState('updatingBuyAmount', true)
        setSellAmount(newSellAmount)
        await updateControlState('updatingBuyAmount', false)
    }

    const onChangeCustomerRate = async (control) => {

        if (control === undefined || control.target === undefined || typeof control.target.value !== 'number') {
            return;
        }

        let newCustomerRate = Number(control.target.value);
        if (newCustomerRate === customerRate) {
            return;
        }

        let newSellAmount = 0
        if (rateTerms === 'I') {
            newSellAmount = buyAmount / newCustomerRate
        }
        else {
            newSellAmount = buyAmount * newCustomerRate
        }
        newSellAmount = Number(roundAmountByCurrency(newSellAmount, customerDeal.BuyCurrency))

        if (!validateSellBalance(newSellAmount, true)) {
            newCustomerRate = customerRate
            newSellAmount = sellAmount
        }

        setCustomerRate(newCustomerRate)

        if (loadingDeal || updatingSellAmount.current || updatingBuyAmount.current) {
            return;
        }

        await updateControlState('updatingCustomerRate', true)
        setSellAmount(newSellAmount)
        await updateControlState('updatingCustomerRate', false)
    }

    const updateControlState = async (control, state) => {
        switch (control) {
            case 'updatingSellAmount':
                updatingSellAmount.current = state
                break;
            case 'updatingBuyAmount':
                updatingBuyAmount.current = state
                break;
            case 'updatingCustomerRate':
                updatingCustomerRate.current = state
                break;
            default:
                break;
        }
    }

    const onChangeBankInterface = (control) => {
        if (control === undefined || control.value === undefined) {
            setBankInterface({})
            return;
        }
        let coverCustomerId = 0;
        if (control.InterfaceType === "AutoCover") {
            coverCustomerId = Number(control.value);
        }
        let bankInterface = bankCustomers.find(function (x) {
            return Number(x.CustomerID) === Number(control.value);
        })
        setBankInterface(bankInterface)
    }

    const onRefreshRate = () => {
        getRateForBuybackPair(buyAmount)
    }

    const onUseInterface = () => {
        setUseInterface(!useInterface)
    }

    const onSaveBuyback = () => {
        let coverCustomerId = customerDeal.CoverCustomerID
        let externalInterface = ''
        let externalInterfaceType = ''
        if (bankInterface !== undefined && bankInterface.CustomerID > 0) {
            coverCustomerId = bankInterface.CustomerID
            externalInterface = bankInterface.BankInterface
            externalInterfaceType = bankInterface.InterfaceType
        }

        let objDealHeader = {
            BuyAmount: buyAmount,
            BuyCurrency: customerDeal.SellCurrency,
            CoverCustomerID: coverCustomerId,
            CoverDealID: 0,
            CoveredDeals: '',
            CreatedBy: localStorage.getItem('UserID'),
            CustomerContactID: 0,
            CustomerID: customerDeal.CustomerID,
            CustomerRate: customerRate,
            CustomerRefNo: '',
            DealHeaderID: 0,
            DealOrigin: 'Retail',
            DealPaidStatus: '',
            DealsToCover: '',
            DealType: 1,
            DepositAmount: 0,
            DepositCurrency: '',
            DepositID: 0,
            DepositPercent: 0,
            ExternalComments: '',
            ExternalInterface: useInterface ? externalInterface : '',
            ExternalInterfaceType: useInterface ? externalInterfaceType : '',
            ForwardBuyAmount: 0,
            ForwardBuyTotalDisbursed: 0,
            ForwardMaturityDate: '',
            ForwardPoints: 0,
            ForwardProfitAmount: 0,
            ForwardProfitAmountDisbursed: 0,
            ForwardSellAmount: 0,
            ForwardSellTotalDisbursed: 0,
            ForwardType: '',
            ForwardValueDate: '',
            IncomingFunds: [],
            InputBy: localStorage.getItem('UserID'),
            InternalComments: `Buy back for customer deal ${customerDeal.DealHeaderID}, created on ${customerDeal.CreatedOn}`,
            IsSameCurrencyDealRefund: '',
            IssueDate: formatDate(new Date()),
            LinkQuoteNo: '',
            LockedSide: sellLocked === '*' ? 'Sell' : 'Buy',
            MarketRate: marketRate,
            MaturityDate: '',
            NostroAccountID: 0,
            OriginalBuyAmount: 0,
            OriginalCustomerRate: customerDeal.OriginalCustomerRate,
            OriginalMarketSpotRate: customerDeal.OriginalMarketSpotRate,
            OriginalProfit: 0,
            OriginalSellAmount: 0,
            ParentDealHeaderID: customerDeal.DealHeaderID,
            ProfitAmount: 0,
            RateQuoteID: rateQuoteId,
            RateTerms: rateTerms,
            RequireDeposits: 0,
            SellAmount: sellAmount,
            SellCurrency: customerDeal.BuyCurrency,
            SpreadPercentage: 0,
            SpreadPoints: 0,
            Status: 'Accepted',
            TotalDisbursed: 0,
            TotalFees: 0,
            TotalPaid: 0,
            UpdateBuyAmountField: sellLocked === '*',
            UpdatedBy: localStorage.getItem('UserID'),
            UpdateToken: 0,
            ValueDate: formatDate(valueDate),
            BuyBackDeal: true,
            BuyBackInfo: {
                NetAmount: netAmount,
                CustomerPaysUs: customerPaysUsBuySide,
                WePayToCustomer: netAmount < 0 ? Math.abs(netAmount) : 0
            }
        }
        setLoading(true)
        saveDealHeader(objDealHeader).then(
            (json) => {
                let alertMessage = '', alertTitle = '', additionalMessage = '';
                switch (json.httpStatusCode) {
                    case 200:
                        {
                            if (objDealHeader.Status === 'Accepted') {
                                alertMessage = 'Deal ' + json.DealHeaderID + ' was Accepted!';
                            }
                            else {
                                alertMessage = 'Deal ' + json.DealHeaderID + ' was Saved!';
                            }
                            setAlertMessage({
                                ...alertMessage,
                                title: 'Success', showMessage: true, message: alertMessage
                            })

                            setNewDeal({ ...objDealHeader, DealHeaderID: json.DealHeaderID, DealNo: json.DealHeaderID })
                            break;
                        }
                    default:
                        alertMessage = 'Deal not saved! Please try again or contact administrator.';
                        alertTitle = 'Error';

                        if (json.ValidationMessage !== undefined) {
                            alertTitle = 'Validation';
                            alertMessage = json.ValidationMessage;
                        }
                        else {
                            if (json.Message !== undefined) {
                                switch (json.Message) {
                                    case 'concurrency':
                                        alertMessage = 'Please reload the information and try again. Otherwise contact your supervisor';
                                        break;
                                    default:
                                        alertMessage = alertMessage + ' (' + json.Message + ')';
                                        break;
                                }
                            } else {
                                if (json.httpErrorMessage !== undefined) {
                                    alertTitle = 'Error';
                                    alertMessage = json.httpErrorMessage;
                                }
                            }
                        }
                        alertMessage += ' ' + additionalMessage;

                        setAlertMessage({
                            ...alertMessage,
                            title: alertTitle, showMessage: true, message: alertMessage
                        })
                        break;
                }

            }
        ).finally(() => { setLoading(false) });
    }

    const closeAlertMessage = () => {
        setAlertMessage({ ...alertMessage, showMessage: false, title: '', message: '' })
        if (newDeal !== undefined && newDeal.DealNo > 0) {
            onBuybackSuccess(newDeal)
        }
    }

    return <>
        <div>
            <label className="uk-form-label" htmlFor="form-sell-amount">We Sell{sellLocked}</label>
            <div uk-grid="true">
                <div className="uk-width-1-2 uk-form-controls">
                    <NumberInput className={regularInputClass} id="form-sell-amount" type="Currency" scale={getScaleByCurrency(customerDeal.BuyCurrency)}
                        value={sellAmount}
                        onChange={onChangeSellAmount}
                    />
                </div>
                <div className="uk-width-1-2 uk-form-controls">
                    <DynamicSelect
                        className={regularSelectClass}
                        placeholder="Select a currency..."
                        data={[{ value: customerDeal.BuyCurrency, name: customerDeal.BuyCurrency }]}
                        objValue={{ value: customerDeal.BuyCurrency, name: customerDeal.BuyCurrency }}
                        getValue={f => f}
                        disabled={true} />
                </div>
            </div>
        </div>

        <div>
            <label className="uk-form-label" htmlFor="form-buy-amount">We Buy{buyLocked}</label>
            <div uk-grid="true">
                <div className="uk-width-1-2 uk-form-controls">
                    <NumberInput className={regularInputClass} id="form-buy-amount" type="Currency" scale={getScaleByCurrency(customerDeal.SellCurrency)}
                        value={buyAmount}
                        onChange={onChangeBuyAmount} disabled={true}
                    />
                </div>
                <div className="uk-width-1-2 uk-form-controls">
                    <DynamicSelect
                        className={regularSelectClass}
                        placeholder="Select a currency..."
                        data={[{ value: customerDeal.SellCurrency, name: customerDeal.SellCurrency }]}
                        objValue={{ value: customerDeal.SellCurrency, name: customerDeal.SellCurrency }}
                        getValue={f => f}
                        disabled={true} />
                </div>
            </div>
        </div>

        <div uk-grid="true">
            <div className={'uk-width-1-2 uk-form-controls'}>
                <label className="uk-form-label" htmlFor="form-value-date">Value Date</label>
                <DatePicker disabledDays={[0, 6]}
                    className={regularInputClass}
                    value={valueDate}
                    readOnly={true}
                    disabled={true}
                    disableDropdown={true} />
            </div>
        </div>

        <div>
            <div uk-grid="true">
                <div className="uk-width-1-2 uk-form-controls">
                    <label className="uk-form-label" htmlFor="form-market-rate">{marketRateLabel}</label>
                    <NumberInput className="uk-input" id="form-market-rate" type="Rate" scale={getScaleForCurrencyPair(customerDeal.SellCurrency, customerDeal.BuyCurrency)}
                        value={marketRate}
                        disabled={true} />
                </div>
                <div className="uk-width-1-2 uk-form-controls" style={{ display: 'flex', flexDirection: 'row', alignContent: 'space-between' }}>
                    <div>
                        <label className="uk-form-label" htmlFor="form-one-click-cover">One Click Cover</label>
                        <DynamicSelect
                            id="form-one-click-cover"
                            className="uk-select"
                            placeholder="Select a Bank..."
                            placeholderValue={0}
                            data={bankCustomers || []}
                            objValue={bankInterface}
                            getValue={onChangeBankInterface}
                            disabled={false}
                        />
                    </div>
                    <div style={{ marginLeft: '1rem' }}>
                        <label className="uk-form-label" htmlFor="form-use-interface">Use interface?</label>
                        <input className="uk-checkbox" type="checkbox" checked={useInterface} onChange={onUseInterface} />
                    </div>
                </div>

            </div>
        </div>

        <div>
            <label className="uk-form-label" htmlFor="form-customer-rate">Customer Rate</label>
            <div uk-grid="true">
                <div className="uk-width-1-2 uk-form-controls">
                    <NumberInput className="uk-input" id="form-customer-rate" type="Rate" scale={getScaleForCurrencyPair(customerDeal.BuyCurrency, customerDeal.SellCurrency)}
                        value={customerRate} onChange={onChangeCustomerRate} />
                </div>
                <div>
                    <i className="fa fa-fw fa-refresh pointer" title="Get rate" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} onClick={onRefreshRate} />
                </div>
            </div>
        </div>

        {netAmount > 0
            && <div>
                <label className="uk-form-label" htmlFor="form-pay-us">Customer Pays Us</label>
                <div uk-grid="true">
                    <div className="uk-width-1-2 uk-form-controls">
                        <NumberInput className="uk-input" id="form-pay-us" type="Currency" scale={getScaleByCurrency(customerDeal.SellCurrency)}
                            value={customerPaysUs} onChange={x => setCustomerPaysUs(x.target.value)}
                        />

                    </div>
                    <div className="uk-width-1-2 uk-form-controls">
                        <input className="uk-input" value={customerDeal.BuyCurrency} disabled />
                    </div>
                    <div className="uk-width-1-2 uk-form-controls">
                        <input className="uk-input" style={{ textAlign: 'right' }} value={customerPaysUsBuySide} disabled />
                    </div>
                    <div className="uk-width-1-2 uk-form-controls">
                        <input className="uk-input" value={customerDeal.SellCurrency} disabled />
                    </div>
                </div>
            </div>}

        <span style={{ display: 'block', fontWeight: 'bold', padding: '1rem', marginBottom: '1rem', marginTop: '1rem', borderRadius: '0.5rem', backgroundColor: netAmountStyle.backgroundColor }} >
            {netAmountStyle.text} </span >

        <button className="uk-button uk-button-green" onClick={onSaveBuyback} disabled={disableBookNow}>Book Now</button>
        <button className="uk-button uk-button-green" onClick={cancelBuyback}>Cancel</button>

        <AlertBox id="buyBackAlert"
            open={alertMessage.showMessage}
            title={alertMessage.title}
            message={alertMessage.message}
            type="Ok"
            onClose={closeAlertMessage}
            okClick={closeAlertMessage} />

        <LoadingBox loading={loading} />

    </>
}

export default BuybackForm
