
import React, { useEffect, useState } from 'react';
import CustomQuill from '../../../shared/CustomQuill';
import { Modal } from 'antd';

const DialogEmailBeneUserInvitation = (props) => {
    const [subject, setSubject] = useState('Registration to our online portal');
    const [emailBody, setEmailBody] = useState('');
    const [parameters, setParameters] = useState([]);

    useEffect(()=>{
        if(props.emailBody)
        {
            setEmailBody(props.emailBody);
        }
    },[props.emailBody])

    const handleGetBody = (value) => {
        if (value !== null && value !== undefined && value !== 'select' && value !== '') {
          setEmailBody(value);
        }
      }

    const sendInvitation = () =>{
        if(props.sendInvitation)
        {
            props.sendInvitation(subject, emailBody);
        }
    }

return (
    <Modal
    title    = {<h3 className="component-title" style={{marginBottom:0, paddingBottom: 0}}>Send invitation</h3>}
    centered
    visible={props.open}
    footer={null}
    //onCancel={props.changeState()}
    maskClosable={false}
    zIndex={1010}
    closable={false}
    width    = {'60%'}
  >
    <div className='uk-form-stacked uk-grid'>
 
          <div className="uk-width-1-1">
                <div className="uk-margin">
                    <label className="uk-form-label" >Beneficiary Email</label>
                    <div className="uk-form-controls">
                        <input className="uk-input" id="bene-email" type="text" value={props.sendUserEmail} disabled={true}/>
                    </div>
                 </div>
          </div> 

          <div className="uk-width-1-1">
            <div className="uk-margin">
                <label className="uk-form-label" >CC</label>
                    <div className="uk-form-controls">
                        <input  className="uk-input" type="text" value={props.contactEmail} disabled={true}></input>                            
                    </div>
                    </div>
          </div> 
          <div className="uk-width-1-1">
            <div className="uk-margin">
                <label className="uk-form-label" >Subject</label>
                    <div className="uk-form-controls">
                        <input type="text" className="uk-input" value={subject} onChange={(event) => {
                                setSubject(event.target.value);
                            }}></input>                            
                    </div>
            </div>
          </div>

          <div className="uk-width-1-1" style={{marginTop:'10px', marginBottom:'10px'}}>
            <CustomQuill placeholder={"Write text for the template."} theme="snow" value={emailBody} onChange={handleGetBody} parameters={parameters} />
            
          </div>
      
      <div className="uk-width-1-1">
        <button className="uk-button uk-button-green" key={"Cancel"} type="button" onClick={props.cancelInvitation}>Cancel</button>
        <button className="uk-button uk-button-green" key={"Send"} type="button" onClick={sendInvitation}>Send</button>
        <button className="uk-button uk-button-green" key={"Copy"} type="button" onClick={props.copyInvitation}>Copy Invitation</button>
        </div>
    </div>
  </Modal> 
);

}

export default DialogEmailBeneUserInvitation;