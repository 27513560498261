import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class SMSValidationBox extends React.Component {
    state = {
        smsCode: ''
    }

    getSmsCode = (event) => {
        this.setState({smsCode: event.target.value});
    }

    render(){
        let buttons = [];
        let title = [];
        let message = [];
        let inputs = [];
        let fullWidth = false;
        let maxWidth = 'lg';

        switch (this.props.type) {
            case "Validate": {
                buttons.push(
                    <button className="uk-button uk-button-green" key={"Ok"} type="button" onClick={this.props.sendSMSCode}>Send Code</button>
                );
                buttons.push(
                    <button className="uk-button uk-button-green" key={"Ok"} type="button" onClick={()=>this.props.validateSMSCode(this.state.smsCode)}>Validate Code</button>
                );
                buttons.push(
                    <button className="uk-button uk-button-green" key={"No"} type="button" onClick={this.props.noClick}>Cancel</button>
                );
                inputs.push(
                    <input className="uk-input" id="input-sms-code" value={this.state.smsCode} type="text" placeholder="Code" onChange={this.getSmsCode}/>
                );
            }
                break;
            default:
                break;
        }
        
        const titleID = 'alert-dialog-title-' + this.props.id;
        const descriptionID = 'alert-dialog-description-' + this.props.id;

        if (this.props.title !== '' && this.props.title !== undefined) {
            title.push(<DialogTitle key="title" id={titleID}>{this.props.title}</DialogTitle>);
        }
        
        if (this.props.message !== '' && this.props.message !== undefined) {
            message.push(
                <DialogContent key="content">
                    <DialogContentText id={descriptionID}>
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>);
        }

        return(
            <Dialog 
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            disableBackdropClick
            disableEscapeKeyDown
            open={this.props.open} onClose={this.props.onClose} aria-labelledby={titleID} aria-describedby={descriptionID}>
                {title}
                <hr style={{ margin: '0px' }} />
                {message}
                <hr style={{ margin: '0px' }} />
                <DialogActions>
                    {inputs}
                </DialogActions>
                <DialogActions>
                    {buttons}
                </DialogActions>
            </Dialog>
        )
    }
}