import React from 'react';
import DynamicScreen from '../../shared/DynamicScreen/DynamicScreen';

const DynamicScreenTest = () =>{
    const screenName =  'Test'


        return(
            <DynamicScreen screenName={screenName} />
        );
    
}


export default DynamicScreenTest;