import React from 'react';
import { getStyle, notFilledInputClass, regularInputClass } from '../../styles/styles';
import { Dialog, DialogContent } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { changePassword } from '../../networking/Networking';
import AlertBox from '../shared/AlertBox';
import { validate } from '../../helpers/ValidateHelper';
import { red } from '@material-ui/core/colors';

var style = getStyle();

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default class ChangePassword extends React.Component {

    state = {
        user: {
            newPassword: '',
            confirmPassword: '',
            username: localStorage.getItem('UserName'),
        },
        //Alert
        showAlert: false,
        alertMessage: '',
        alertTitle: '',
        errors: [],
        validations: {
            newPassword: true,
            confirmPassword: true,
        },
        validPasswordFormat: true,
        validPasswordMatch: true
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            this.ClearFields()
        }
    }
    ClearFields = () => {
        this.setState({
            user: {
                newPassword: '',
                confirmPassword: '',
                username: localStorage.getItem('UserName'),
            },
            //Alert
            showAlert: false,
            alertMessage: '',
            alertTitle: '',
            errors: [],
            validations: {
                newPassword: true,
                confirmPassword: true,
            }
        })
    }
    SavePassword = (event) => {
        let objFilter = {
            applicationName: 'ForeignExchange',
            userName: this.state.user.username,
            newPassword: this.state.user.confirmPassword
        }
        if (this.validateRequiredFields() && this.state.validPasswordFormat && this.state.validPasswordMatch) {
            if (this.isEqualPassword(this.state.user.newPassword, this.state.user.confirmPassword)) {
                changePassword(objFilter).then(
                    (json) => {
                        switch (Number(json.httpStatusCode)) {
                            case 200:
                                this.setState({
                                    showAlert: true,
                                    alertTitle: 'Success',
                                    alertMessage: 'Password successfully changed.'
                                });
                                break;
                            default:
                                var alertMessage = 'An error occurred while trying to change password. Please try again.'
                                if (json.httpErrorMessage !== undefined) {
                                    alertMessage = json.httpErrorMessage;
                                }
                                if (json.Message !== undefined) {
                                    alertMessage = json.Message;
                                }
                                this.setState({
                                    showAlert: true,
                                    alertMessage: alertMessage,
                                    alertTitle: 'Error'
                                });
                                break;
                        }
                    }
                )
            }
        }
    }
    isEqualPassword = (newPassword, confirmPassword) => {
        if ((newPassword.length < 5 && confirmPassword.length < 5)
            || (newPassword.length < 5) || (confirmPassword.length < 5)) {
            this.setState({
                showAlert: true,
                alertTitle: 'Error',
                alertMessage: 'The length of parameters needs to be greater or equal to 5.',
                errors: []
            });
            return false
        } else {
            if (newPassword === confirmPassword) {
                return true
            } else {
                this.setState({
                    showAlert: true,
                    alertTitle: 'Error',
                    alertMessage: 'Passwords do not match. Please re-enter them.',
                    errors: []
                });
                return false
            }
        }
    }

    validateRequiredFields() {
        const fields = [
            { displayName: 'New Password', validateName: 'newPassword', value: this.state.user.newPassword, type: 'string' },
            { displayName: 'Confirm Password', validateName: 'confirmPassword', value: this.state.user.confirmPassword, type: 'string' },
       ]

        const [valid, validations, errors] = validate(fields);

        this.setState({ validations: { ...this.state.validations, ...validations } });

        if (!valid) {
            this.setState({
                showAlert: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }
    handleUpdateNewPassword = (event) => {
        this.setState({ user: { ...this.state.user, newPassword: event.target.value } })
    }
    handleUpdateConfirmPassword = (event) => {
        this.setState({ user: { ...this.state.user, confirmPassword: event.target.value } })
    }
    closeAlert = () => {
        this.setState({ showAlert: false });
        this.props.close();
    }
    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    validPassword = (event) => 
    {
        var regStrength = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&])[0-9a-zA-Z@#$%&]{6,50}$/;
        var isValid = regStrength.test(event.target.value);
        this.setState({validPasswordFormat: isValid,
            user: { ...this.state.user, newPassword: event.target.value}
        });
    }

    validPasswordMatch = (event) => 
    {
        var isValid = true;
        if(event.target.value !== this.state.user.newPassword)
        {
            isValid = false;
        }
        this.setState({validPasswordMatch: isValid,
            user: { ...this.state.user, confirmPassword: event.target.value }
            });
    }
    render() {

        var labelNewPassword = "";
        var labelPasswordMatch = "";

        if(!this.state.validPasswordFormat)
        {
            labelNewPassword = <label id="newPassword-error" style={{color:'#FF0000',fontSize:'small'}}>{"Your password must contain at least one number, one special character, one lower case letter, and one upper case letter. The minimum password length is 6 characters. (NOTE - special characters accepted only include @ # $ % &)"}</label>
        }

        if(!this.state.validPasswordMatch)
        {
            labelPasswordMatch = <label id="confirmPassword-error" style={{color:'#FF0000',fontSize:'small'}}>{"The new password and confirm password do not match."}</label>
        }

        return (
            <Dialog open={this.props.open} onClose={this.props.close} maxWidth={'xs'} fullWidth={true}>
                <DialogTitle id="attachments-dialog-title">
                    <h3 className="uk-heading-divider" style={style.titleStyle}>
                        Change Password
                    </h3>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="">
                        <div className="uk-form-stacked">
                            <h5 style={style.boldTitle}>User ID: {this.state.user.username}</h5>
                        </div>
                        <div className="uk-form-stacked">
                            <label className="uk-form-label">New Password</label>
                            <div className="" style={style.inputStyle}>
                                <input className={this.formatValidInput(this.state.validations.newPassword)} id="newPassword" type="password" value={this.state.user.newPassword}  onBlur={this.validPassword} onChange={this.handleUpdateNewPassword} />
                                {labelNewPassword}
                            </div>
                        </div>
                        <div className="uk-form-stacked">
                            <label className="uk-form-label">Confirm Password</label>
                            <div className="" style={style.inputStyle}>
                                <input className={this.formatValidInput(this.state.validations.confirmPassword)} id="confirmPassword" type="password" value={this.state.user.confirmPassword}  onBlur={this.validPasswordMatch} onChange={this.handleUpdateConfirmPassword} />
                                {labelPasswordMatch}
                            </div>
                        </div>
                    </div>
                    <div className="uk-margin">
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.SavePassword} type="button">Save</button>
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.props.close} type="button">Cancel</button>
                    </div>
                </DialogContent>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle}  messageBoldList={this.state.errors} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
            </Dialog>
        );
    }
}