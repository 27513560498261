import Badge from '@material-ui/core/Badge';
import InfoIcon from '@material-ui/icons/Info';
import { Breadcrumb } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { v4 as uuidv4 } from 'uuid';
import AppContext from '../../../../AppContext';
import { GlobalSystemParametersNames } from '../../../../constants/GlobalSystemParametersNames';
import '../../../../css/ant.css';
import { formatDate, formatValidDatePicker, formatValidInput, formatValidSelect, getScaleByCurrency } from '../../../../helpers/FormatHelper';
import { validate } from '../../../../helpers/ValidateHelper';
import { getLookUpTable } from '../../../../networking/Networking';
import { getAddressByPersonID } from '../../../../networking/NetworkingAddresses';
import { deleteWorkAssignments, updateProcessStatus } from '../../../../networking/NetworkingCompliance';
import { getCurrencyList, searchCurrencies } from '../../../../networking/NetworkingCurrencies';
import { AFEXSaveCustomer, getCustomerRiskScore, getRiskScoreDetails, getRiskScoreRanges, getScreenInformation, GetSubAccountID, onboardCustomerToCurrencyCloud, searchCustomerByIdNameMin, searchUserReferredByPerson, sendAuthorizedCustomerEmail, updateCustomer, searchCustomerByIdNameMinForAffiliate } from '../../../../networking/NetworkingCustomers';
import { getEntityProperties } from '../../../../networking/NetworkingEntityProperties';
import { getParametersByName } from '../../../../networking/NetworkingGlobalSystemParameters';
import { Monoova_Register, Zepto_Register } from '../../../../networking/NetworkingMonoova';
import { searchInGlobalWatchLists } from '../../../../networking/NetworkingGlobalWatchListSearch';
import { getProcessStatusesByRole, getStatusesForEntity, getUsersByRole } from '../../../../networking/NetworkingLookUpTables';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import DatePicker from '../../../shared/DatePicker';
import AttachmentsDialog from '../../../shared/Dialogs/AttachmentsDialog';
import GlobalWatchListsResultDialog from '../../../shared/Dialogs/GlobalWatchListsResultDialog';
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
import DynamicSelect from '../../../shared/DynamicSelect';
import NumberInput from '../../../shared/NumberInput';
import AuditCustomerScreen from '../auditEntity/AuditCustomerScreen';
import DynamicCoverScreen from '../dynamicCover/DynamicCoverScreen';
import RiskCalculationDialog from '../riskCalculation/RiskCalculationDialog';
import SendMessageScreen from '../sendMessage/SendMessageScreen';
const useEmailTemplate = (window.REACT_APP_USE_EMAIL_TEMPLATE) ? window.REACT_APP_USE_EMAIL_TEMPLATE : false;
let globalFunctions = {};
const style = getStyle();

export default class CustomerInformationScreen extends React.Component {
    static contextType = AppContext;

    state = {
        customer: {
            AffiliateCommission: '',
            AffiliateReferredBy: '',
            AffiliateReferredByName: '',
            AssignTo: '',
            BranchDescription: '',
            BypassValidation: '',
            CommissionBase: '',
            CommissionCurrency: '',
            CommissionCurrencyDescription: '',
            CommissionBusiness: '',
            CommissionPercentage: '',
            CustomerCode: '',
            CustomerGroupID: '',
            CustomerID: '',
            CustomerType: '',
            DefaultBranchDescription: '',
            DefaultBranchID: '',
            DefaultPricingDescription: '',
            DefaultPricingID: '',
            EmailTemplate: '',
            ExpiryByPassDate: '',
            FeeDescription: '',
            FeeID: '',
            FirstName: '',
            IndustrySector: '',
            LastName: '',
            Name: '',
            OfficeFax: '',
            OnlineFeeCategoryDescription: '',
            OnlineFeeCategoryID: '',
            ParentCustomerID: '',
            ParentCustomerName: '',
            PersonID: '',
            Phone: '',
            PhoneExt: '',
            PreferredLanguage: '',
            PreferredName: '',
            ProcessStatus: '',
            ReferredBy: '',
            ReferredByDescription: '',
            Region: '',
            ReportTemplate: 'Default',
            Salutation: '',
            ServiceLevel: '',
            ShortName: '',
            SpreadPoints: '',
            Status: '',
            TraderID: '',
            UpdatedBy: '',
            UpdateToken: ''
        },
        currentStatus: '',
        showConfirm : false,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        errors: [],
        enableCompliance: false,
        viewHistory: false,
        showRiskCalculation: false,
        viewSendMessage: false,
        viewDynamicCover: false,
        showWatchListsResult: false,
        showAlertWatchList: false,
        showAlertSaveSuspicious: false,
        watchListResults: [],
        nameToSearch: '',
        riskScoreDetails: [],
        referredBySelect: [],
        branches: [],
        feeStructures: [],
        feeCategories: [],
        ratePricingCategories: [],
        listParents: [],
        listAffiliates: [],
        currencies: [],
        "BankInterfaces": [],
        "Commission Base": [],
        "Confirmation Type": [],
        "Customer Groups": [],
        "Customer Regions": [],
        "Customer Status": [],
        "Customer Type": [],
        "Deal Status": [],
        "Emails Templates": [],
        "Fee Types": [],
        "Incoming Instruments": [],
        "Industry Sectors": [],
        "Languages": [],
        "Reports Templates": [],
        "Salutation": [],
        "Service Level": [],
        "Spread Points": [],
        ProcessStatuses: [],
        Users: [],
        lookUpTables: [
            "BankInterfaces",
            "Commission Base",
            "Confirmation Type",
            "Customer Groups",
            "Customer Regions",
            "Customer Status",
            "Customer Type",
            "Deal Status",
            "Emails Templates",
            "Fee Types",
            "Incoming Instruments",
            "Industry Sectors",
            "Languages",
            "Reports Templates",
            "Salutation",
            "Service Level",
            "Spread Points"
        ],
        riskScore: {
            Color: "inherit",
            Description: '',
            "Entity Type": '',
            "Range From": '',
            "Range To": '',
            "Score Range ID": '',
            "Status": '',
            "User Editable": false
        },
        validations: {
            customerType: true,
            status: true,
            companyName: true,
            firstName: true,
            lastName: true,
            preferredLanguage: true,
            expiryBypassDate: true,
            commissionCurrency: true,
            reportTemplate: true,
            emailTemplate: true
        },
        hideGeneralInformation: false,
        hideContactInformation: true,
        hidePricingReferrals: true,
        hideOtherInformation: true,
        enableButtonSaveInAfex: false,

        //AttachmentsDialog
        showAttachmentsDialog: false,
        isReadOnly: false,
        dropdownButtonSelectionKey: '',
        validateDropdownButton: true,

        //new options
        prevStatus: '',
        bankFixInterfacesToSend: [],
        entityProperties: [],
        showGetSubAccountIdButton: false,
        disableAssignToButton: true,
        
        fontWeight                          : 'bolder', //riskscore
        backgroundColorRiskScore            : '#1d2b1d'
    }

    componentDidMount() {
        globalFunctions = this.context;
        // var propsCustomer = this.props.data;
        var enableProcessStatus = false;
        //var processStatuses = [];
        // this.setState({customer: {...this.state.customer, ...propsCustomer}});
        var backgroundColorRiskScore =  localStorage.getItem('BackgroundColorRiskScore');
        if(backgroundColorRiskScore != null && backgroundColorRiskScore != '')
        {
            this.setState({backgroundColorRiskScore: backgroundColorRiskScore});
        }

        if (this.props.readOnly !== undefined) {
            this.setState({
                isReadOnly: this.props.readOnly
            })
        }
        
        if(this.props.canUpdateCustomerProcessStatus)
        {
        getProcessStatusesByRole().then(
            (json) => {
                if (json !== undefined) {
                    if (json.processStatuses !== undefined) {
                        if (json.processStatuses.length > 0) {
                            enableProcessStatus = true;
                        }
                    }
                }
                if (enableProcessStatus === true) {
                    for (var i = 0; i < json.processStatuses.length; i++) {
                        json.processStatuses[i].value = json.processStatuses[i].LookUpTableDetail;
                        json.processStatuses[i].name = json.processStatuses[i].LookUpTableDetail;
                    }
                    this.setState({ ProcessStatuses: json.processStatuses, enableCompliance: enableProcessStatus });

                    /*getStatusesForEntity('Customer', this.state.customer.CustomerType, this.state.customer.CustomerID, 0, '', this.state.customer.Status).then(
                        (json) => {
                            if(json !== undefined && json.complianceFlow !== undefined && json.complianceFlow.length > 0){
                                for(var i = 0; i< json.complianceFlow.length; i++){
                                    json.complianceFlow[i].value = json.complianceFlow[i].LookUpTableDetail;
                                    json.complianceFlow[i].name = json.complianceFlow[i].LookUpTableDetail;
                                }
                                processStatuses = json.complianceFlow;
                            }
                        }
                    )*/
                }
            });
        }
        
        getLookUpTable(this.state.lookUpTables.toString()).then((json) => {
            for (let i = 0; i < this.state.lookUpTables.length; i++) {
                let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                if (index > -1) {
                    let table = json.lookUpTables[index].lookUpTableDetails;
                    for (let j = 0; j < table.length; j++) {
                        table[j].value = table[j].LookUpTableDetail;
                        table[j].name = table[j].LookUpTableDetail;
                    }
                    if (enableProcessStatus && json.lookUpTables[index].LookUpTableDescription === 'Customer Status') {
                        let tempTable = [];
                        let tempProcessStatuses = table;
                        let processStatuses = [];
                        getStatusesForEntity('Customer', this.state.customer.CustomerType, this.state.customer.CustomerID, 0, '', this.state.customer.Status).then(
                            (json) => {
                                if (json !== undefined && json.complianceFlow !== undefined && json.complianceFlow.length > 0) {
                                    for (let i = 0; i < json.complianceFlow.length; i++) {
                                        json.complianceFlow[i].value = json.complianceFlow[i].LookUpTableDetail;
                                        json.complianceFlow[i].name = json.complianceFlow[i].LookUpTableDetail;
                                    }
                                    processStatuses = json.complianceFlow;
                                    if (processStatuses !== undefined && processStatuses.length > 0) {
                                        processStatuses.forEach((LookUpTableDetail) => {
                                            for (let k = 0; k < tempProcessStatuses.length; k++) {
                                                if (tempProcessStatuses[k].LookUpTableDetail === LookUpTableDetail.LookUpTableDetail) {
                                                    tempTable.push(tempProcessStatuses[k]);
                                                }
                                            }
                                        });
                                        this.setState({ [this.state.lookUpTables[5]]: tempTable });
                                    }
                                } else {
                                    this.setState({ [this.state.lookUpTables[5]]: tempProcessStatuses });
                                }
                            }
                        );
                    } else {
                        this.setState({ [this.state.lookUpTables[i]]: table }, () => {
                            if (json.lookUpTables[index].LookUpTableDescription == "BankInterfaces") {
                                var bankFixInterfacesToSend = this.state.BankInterfaces.filter(b => b.Field6.toString() == 'true' || b.Field5.toString() == 'True');

                                this.setState({ bankFixInterfacesToSend: bankFixInterfacesToSend });
                            }
                        });
                    }
                }
            }
        });
        searchUserReferredByPerson("_").then(
            (json) => {
                if(json && json.users)
                {
                    var select = json.users;
                    
                    for (var i = 0; i < select.length; i++) {
                        select[i].value = select[i].PersonID;
                        select[i].name = select[i].Name;
                    }
                    this.setState({ referredBySelect: select });
                }
            }
        );
        getScreenInformation().then(
            (json) => {
                let format = (list, property1, property2) => {
                    var helper = [];
                    list.forEach(row => helper.push({ value: row[property1], name: row[property2] }));
                    return helper;
                }
                this.setState({
                    branches: format(json.branches, "BranchID", "Description"),
                    feeStructures: format(json.feeStructures, "FeeStructureID", "Description"),
                    feeCategories: format(json.feeCategories, "FeeCategoryID", "CategoryName"),
                    ratePricingCategories: format(json.ratePricingCategories, "CategoryID", "CategoryName")
                });
            }
        );
        getCurrencyList().then(
            (json) => {
                if (json != null && json.currencies != null) {
                    const currencies = json.currencies.map(c => ({ ...c, name: c.CurrencyIDName, value: c.CurrencyID }));
                    this.setState({ currencies });
                }
            }
        );
        getRiskScoreRanges().then(
            (ranges) => {
                getRiskScoreDetails(this.props.data.CustomerID).then(
                    (details) => {
                        if (details !== undefined) {
                            getCustomerRiskScore(this.props.data.CustomerID).then(
                                (json) => {
                                    if (json !== undefined && json.RiskScore !== undefined && json.RiskScore !== null) {
                                        ranges.riskScores = ranges.riskScores.filter((range) => range["Entity Type"] === "Customer");
                                        var riskScore = ranges.riskScores.find((range) => range["Range From"] <= json.RiskScore && json.RiskScore <= range["Range To"]);
                                        if (riskScore !== undefined) {
                                            this.setState({
                                                riskScore: riskScore,
                                                riskScoreDetails: details.riskScoreDetails
                                            });
                                        }
                                    }
                                }
                            )
                        }
                    }
                )
            }
        );
        getParametersByName(GlobalSystemParametersNames.EnableButtonBeneficiarySaveInAfex).then(
            (response) => {
                if (response != null && response.parameters != null && response.parameters.length > 0) {
                    this.setState({ enableButtonSaveInAfex: response.parameters[0].Value === 'true' });
                }
            }
        )
        getEntityProperties(this.props.id, "Customer").then(
            (value) => {
                if (value.entityProperties != undefined && value.entityProperties != null && Array.isArray(value.entityProperties)) {
                    let properties = value.entityProperties;
                    var interfaceLoginIdProperty = properties.find(x => x.Description === 'Interface login ID');
                    var subAccountIdProperty = properties.find(x => x.Description === 'Interface subaccount ID');
                    if (interfaceLoginIdProperty !== undefined && interfaceLoginIdProperty !== null
                        && subAccountIdProperty !== undefined && subAccountIdProperty !== null) {
                        let accountId = subAccountIdProperty.TextValue
                        let loginId = interfaceLoginIdProperty.TextValue
                        if (loginId !== undefined && loginId !== null && loginId !== ''
                            && (accountId === '' || accountId === null || accountId === undefined)) {
                            this.setState({ showGetSubAccountIdButton: true });
                        }
                    }
                }
            }
        );
    }

    componentDidUpdate(prevProps) {

        if (prevProps.data.Status !== this.props.data.Status) {            
            this.setState({ currentStatus: this.props.data.Status});
        }
        if (prevProps.hideDetail !== this.props.hideDetail && !this.props.hideDetail) {            
            this.setState({ viewHistory: false});
        }

        if (prevProps.data !== this.props.data) {
            const propsCustomer = { ...this.props.data, ReportTemplate: this.props.data.ReportTemplate != null ? this.props.data.ReportTemplate : 'Default' };            
            this.setState({ customer: { ...this.state.customer, ...propsCustomer } });            
            this.setState({ prevStatus: propsCustomer.Status });
            getRiskScoreRanges().then(
                (ranges) => {
                    getRiskScoreDetails(this.props.data.CustomerID).then(
                        (details) => {
                            if (details !== undefined) {
                                getCustomerRiskScore(this.props.data.CustomerID).then(
                                    (json) => {
                                        if (json !== undefined && json.RiskScore !== undefined && json.RiskScore !== null) {
                                            ranges.riskScores = ranges.riskScores.filter((range) => range["Entity Type"] === "Customer");
                                            var riskScore = ranges.riskScores.find((range) => range["Range From"] <= json.RiskScore && json.RiskScore <= range["Range To"]);
                                            if (riskScore !== undefined) {
                                                this.setState({
                                                    riskScore: riskScore,
                                                    riskScoreDetails: details.riskScoreDetails
                                                });
                                            }
                                        }
                                    }
                                )
                            }
                        }
                    )
                }
            );
            getEntityProperties(this.props.id, "Customer").then(
                (value) => {
                    if (value.entityProperties != undefined && value.entityProperties != null && Array.isArray(value.entityProperties)) {
                        let properties = value.entityProperties;
                        var interfaceLoginIdProperty = properties.find(x => x.Description === 'Interface login ID');
                        var subAccountIdProperty = properties.find(x => x.Description === 'Interface subaccount ID');
                        if (interfaceLoginIdProperty !== undefined && interfaceLoginIdProperty !== null
                            && subAccountIdProperty !== undefined && subAccountIdProperty !== null) {
                            let accountId = subAccountIdProperty.TextValue
                            let loginId = interfaceLoginIdProperty.TextValue
                            if (loginId !== undefined && loginId !== null && loginId !== ''
                                && (accountId === '' || accountId === null || accountId === undefined)) {
                                this.setState({ showGetSubAccountIdButton: true });
                            }
                        }
                    }
                }
            );
        } else if (prevProps.data != null && this.props.data != null && prevProps.data.UpdateToken !== this.props.data.UpdateToken) {
            const propsCustomer = this.props.data;
            this.setState({ customer: { ...this.state.customer, ...propsCustomer } }, () => {
                if (propsCustomer.ReportTemplate === null) {
                    this.setState({ customer: { ...this.state.customer, ReportTemplate: 'Default' } });
                }
            });
        }
    }

    searchCurrency = (query) => {
        searchCurrencies(query, 'active').then(
            (json) => {
                var helper = [];
                if (query !== '') {
                    if (json !== undefined) {
                        for (var i = 0; i < json.length; i++) {
                            helper.push({ value: json[i].CurrencyID, name: json[i].CurrencyIDName })
                        }
                        if (json.length > 1) {
                            this.setState({ currencies: helper });
                        } else {
                            this.setState({ currencies: helper, customer: { ...this.state.customer, CommissionCurrencyDescription: helper[0].name } });
                        }
                    } else {
                        this.setState({ currencies: helper });
                    }
                }
            }
        );
    }

    searchParent = (query) => {
        console.group("searchParent");
        console.log(query);
        console.groupEnd();
        searchCustomerByIdNameMin(query).then(
            (response) => {
                if (response.customers !== undefined) {
                    for (var i = 0; i < response.customers.length; i++) {
                        response.customers[i].value = response.customers[i].CustomerID;
                        response.customers[i].name = response.customers[i].CustomerIDName;
                    }
                    this.setState({ listParents: response.customers });
                }
            }
        )
    }

    searchAffiliate = (query) => {
        console.group("searchAffiliate");
        console.log(query);
        console.groupEnd();
        //searchCustomerByIdNameMin(query).then(
        searchCustomerByIdNameMinForAffiliate(query).then(
            (response) => {
                if (response.customers !== undefined) {
                    for (var i = 0; i < response.customers.length; i++) {
                        response.customers[i].value = response.customers[i].CustomerID;
                        response.customers[i].name = response.customers[i].CustomerIDName;
                    }
                    this.setState({ listAffiliates: response.customers });
                }
            }
        )
    }

    validateRequiredFields = () => {
        let fields = [
            { displayName: 'Customer Type', validateName: 'customerType', value: this.state.customer.CustomerType, type: 'string' },
            { displayName: 'Status', validateName: 'status', value: this.state.customer.Status, type: 'string' },
            { displayName: 'Preferred Language', validateName: 'preferredLanguage', value: this.state.customer.PreferredLanguage, type: 'string' },
            { displayName: 'Confirmation Template', validateName: 'reportTemplate', value: this.state.customer.ReportTemplate, type: 'string' },
            { displayName: 'Commission Currency', validateName: 'commissionCurrency', value: this.state.customer.CommissionCurrency, type: 'string' },
        ]

        if (useEmailTemplate !== undefined && useEmailTemplate === "true") {
            fields.push({ displayName: 'Email Template', validateName: 'emailTemplate', value: this.state.customer.EmailTemplate, type: 'string' })
        }
        if (this.state.customer.BypassValidation && this.state.customer.BypassValidation !== '') {
            fields.push({ displayName: 'Expiry Bypass Date', validateName: 'expiryBypassDate', value: this.state.customer.ExpiryByPassDate, type: 'string' });
        }
        if (this.state.customer.CustomerType.includes("Individual")) {
            fields.push({ displayName: 'First Name', validateName: 'firstName', value: this.state.customer.FirstName, type: 'string' });
            fields.push({ displayName: 'Last Name', validateName: 'lastName', value: this.state.customer.LastName, type: 'string' });
        } else {
            fields.push({ displayName: 'Company Name', validateName: 'companyName', value: this.state.customer.Name, type: 'string' })
        }

        const [valid, validations, errors] = validate(fields);

        this.setState({ validations: { ...this.state.validations, ...validations } });

        if (!valid) {
            this.setState({
                showAlertError: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors: errors
            });
        }

        return valid;
    }

    handleSaveClick = async (event) => {
        event.preventDefault();
        globalFunctions.startLoading();
        
        const validateStatus = await this.validateStatusChange(this.state.currentStatus, this.state.customer.Status)        
        if (validateStatus !== undefined && validateStatus !== '') 
        {
            globalFunctions.finishLoading();
            this.setState({ showAlertError: true, alertTitle: 'Warning', alertMessage: validateStatus });
            return
        }

        this.setState({ errors: [] });
        
        if (this.validateRequiredFields()) {
            
            if(this.state.enableCompliance && (this.state.customer.ProcessStatus === 'Closed - Issue' ||
               this.state.customer.ProcessStatus === 'Closed – Non Issue' ||
               this.state.customer.ProcessStatus === 'Approved') &&
               (this.state.customer.Status === 'Registered Online' ||
               this.state.customer.Status === 'Incomplete Online Registration')){
                   this.setState({ showAlertError: true, alertTitle: 'Warning', alertMessage: 'You cannot save the Customer on "Registered Online" or "Incomplete Online Registration" status.' });
            }
            else {
                const textToSearch = this.state.customer.CustomerType === "Individual" ?
                `${this.state.customer.FirstName} ${this.state.customer.LastName}` : this.state.customer.Name;
                const globalListsResponse = await searchInGlobalWatchLists(textToSearch, 'Customer', this.state.customer.CustomerID);
                if (this.state.customer.BypassValidation || (globalListsResponse != null && globalListsResponse.MatchedWords.length === 0 && globalListsResponse.SourceNames.length === 0)) {
                    await this.saveCustomer(false)
                } else {
                    this.setState({ showAlertSaveSuspicious: true, alertTitle: 'Warning', alertMessage: 'Found Customer in Watch Lists. The Customer will be saved with status "Suspicious"' });
                }

            }
            globalFunctions.finishLoading();
        }
        else
        {
            globalFunctions.finishLoading();
        }
    }

    saveCustomer = async (isSuspicious) => {
        let model = {
            AffiliateReferredBy: this.state.customer.AffiliateReferredBy,
            BypassValidation: this.state.customer.BypassValidation,
            CommissionBase: this.state.customer.CommissionBase,
            CommissionCurrency: this.state.customer.CommissionCurrency,
            CommissionBusiness: this.state.customer.CommissionBusiness,
            CommissionPercentage: this.state.customer.CommissionPercentage,
            CustomerCode: this.state.customer.CustomerCode,
            CustomerGroupID: this.state.customer.CustomerGroupID,
            CustomerID: this.state.customer.CustomerID,
            CustomerType: this.state.customer.CustomerType,
            DefaultBranchID: this.state.customer.DefaultBranchID,
            DefaultPricingID: this.state.customer.DefaultPricingID,
            EmailTemplate: this.state.customer.EmailTemplate === null ? '' : this.state.customer.EmailTemplate,
            ExpiryByPassDate: this.state.customer.ExpiryByPassDate,
            FeeID: this.state.customer.FeeID,
            FirstName: this.state.customer.FirstName,
            IndustrySector: this.state.customer.IndustrySector,
            LastName: this.state.customer.LastName,
            Name: this.state.customer.CustomerType === 'Individual' ? `${this.state.customer.LastName}, ${this.state.customer.FirstName}` : this.state.customer.Name,
            OfficeFax: this.state.customer.OfficeFax === null ? '' : this.state.customer.OfficeFax,
            OnlineFeeCategoryID: this.state.customer.OnlineFeeCategoryID,
            ParentCustomerId: this.state.customer.ParentCustomerID,
            PersonID: this.state.customer.PersonID,
            Phone: this.state.customer.Phone === null ? '' : this.state.customer.Phone,
            PhoneExt: this.state.customer.PhoneExt === null ? '' : this.state.customer.PhoneExt,
            PreferredLanguage: this.state.customer.PreferredLanguage,
            PreferredName: this.state.customer.PreferredName === null ? '' : this.state.customer.PreferredName,
            ProcessStatus: this.state.customer.ProcessStatus,
            ReferredBy: this.state.customer.ReferredBy,
            Region: this.state.customer.Region,
            ReportTemplate: this.state.customer.ReportTemplate === null ? '' : this.state.customer.ReportTemplate,
            Salutation: this.state.customer.Salutation,
            ServiceLevel: this.state.customer.ServiceLevel,
            ShortName: this.state.customer.ShortName,
            SpreadPoints: this.state.customer.SpreadPoints,
            Status: isSuspicious ? "Suspicious" : this.state.customer.Status,
            UpdatedBy: localStorage.getItem('UserID'),
            UpdateToken: this.state.customer.UpdateToken,
            PrevStatus: this.state.prevStatus,
        };
        if (useEmailTemplate !== undefined && useEmailTemplate === "true") {
            model.EmailTemplate = this.state.customer.EmailTemplate
        }
        globalFunctions.startLoading();
        const json = await updateCustomer(model);
        switch (Number(json.httpStatusCode)) {
            case 200: {
                this.setState({
                    customer: {
                        ...this.state.customer, UpdateToken: json.updateToken
                    },
                    showAlert: true, alertTitle: 'Success', alertMessage: 'Customer successfully saved.'
                });
                this.props.refreshCustomer();
                if (this.state.enableCompliance && this.state.customer.ProcessStatus !== null && this.state.customer.ProcessStatus !== '') {
                    this.updateProcessStatus();
                }
                break;
            }
            default: {
                this.setState({
                    showAlert: true, alertMessage: json.httpErrorMessage, alertTitle: "Error"
                });
                if (this.state.customer.Status === 'Registered Online') {
                    this.deleteWorkAssignments();
                }
                break;
            }
        }
        globalFunctions.finishLoading();
    }

    searchCustomerInGlobalWatchLists = async () => {
        const textToSearch = this.state.customer.CustomerType === "Individual" ?
            `${this.state.customer.FirstName} ${this.state.customer.LastName}` : this.state.customer.Name;
        const json = await searchInGlobalWatchLists(textToSearch, 'Customer', this.state.customer.CustomerID);
        if (json != null && json.MatchedWords.length === 0 && json.SourceNames.length === 0) {
            this.setState({
                showAlertWatchList: true,
                alertTitle: 'Info',
                alertMessage: 'No results found.',
                nameToSearch: this.state.customer.Name,
                watchListResults: []
            });
        } else {
            this.setState({
                showAlertWatchList: true,
                alertTitle: 'Warning',
                alertMessage: 'Found Customer in Watch Lists',
                nameToSearch: this.state.customer.Name,
                watchListResults: json.SourceNames
            });
        }
    }

    handleAttachmentsClick = () => {
        this.setState({
            showAttachmentsDialog: true
        });
    }

    handlePrintBookletClick = () => {

    }

    handleSendMessageClick = () => {
        this.setState({ viewSendMessage: true });
    }

    handleDynamicCoverClick = () => {
        this.setState({ viewDynamicCover: true });
    }

    handleGetSubAccountIDClick = async () => {
        globalFunctions.startLoading();
        GetSubAccountID(this.state.customer.CustomerID).then(
            (json) => {
                if (json.httpStatusCode === 200) {
                    this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: 'The subaccount ID was saved as an User property!', showGetSubAccountIdButton: false });
                } else {
                    this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: json.Message });
                }
            }
        );
        globalFunctions.finishLoading();
    }

    handleSendToCurrencyCloud = async () => {
        globalFunctions.startLoading();
        const json = await onboardCustomerToCurrencyCloud(this.state.customer.CustomerID, localStorage.getItem('UserID'));
        console.log(json)
        switch (Number(json.httpStatusCode)) {
            case 200: {
                let message = 'Operation successfully completed.'
                if (json.currencyCloudMessage !== undefined && json.currencyCloudMessage !== null
                    && json.currencyCloudMessage !== '') {
                  //  message = <span> {message} <br /> {json.currencyCloudMessage}</span>
                    message = json.currencyCloudMessage.split("\n").map(function (item, idx) {
                        return (
                            <span key={idx}>
                                {item}
                                <br />
                            </span>
                        )
                    })
                }
                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: message });
                this.props.refreshCustomer();
                break;
            }
            default: {
                let message = json.httpErrorMessage
                if (json.errorMessage !== undefined && json.errorMessage !== null
                    && json.errorMessage !== '') {
                    message = <span> {message} <br /> {json.errorMessage}</span>
                }
                this.setState({
                    showAlert: true, alertMessage: message, alertTitle: "Error"
                });
                break;
            }
        }
        globalFunctions.finishLoading();
    }
    
    handleSendMonoova = async () => {
        globalFunctions.startLoading();        
        const json = await Monoova_Register(this.state.customer.CustomerID, localStorage.getItem('UserID'));
        console.log(json)
        switch (Number(json.httpStatusCode)) {
            case 200: {
                let message = 'Operation successfully completed.'
                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: message });
                this.props.refreshCustomer();
                break;
            }
            default: {
                let message = json.httpErrorMessage
                if (json.Message !== undefined && json.Message !== null
                    && json.Message !== '') {
                    message = <span> {message} <br /> {json.Message}</span>
                }
                this.setState({
                    showAlert: true, alertMessage: message, alertTitle: "Error"
                });
                break;
            }
        }
        globalFunctions.finishLoading();
    }
    
    handleSendZepto = async () => {
        globalFunctions.startLoading();        
        const json = await Zepto_Register(this.state.customer.CustomerID, localStorage.getItem('UserID'), this.props.subEntityType === 'Beneficiary Bank Account' ? 'beneficiary' : 'customer');
        console.log(json)
        switch (Number(json.httpStatusCode)) {
            case 200: {
                let message = 'Operation successfully completed.'
                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: message });
                this.props.refreshCustomer();
                break;
            }
            default: {
                let message = json.httpErrorMessage
                if (json.Message !== undefined && json.Message !== null
                    && json.Message !== '') {
                    message = <span> {message} <br /> {json.Message}</span>
                }
                this.setState({
                    showAlert: true, alertMessage: message, alertTitle: "Error"
                });
                break;
            }
        }
        globalFunctions.finishLoading();
    }

    handleSendWelcomeMessageClick = async () => {
        globalFunctions.startLoading();
        const json = await sendAuthorizedCustomerEmail(this.state.customer.CustomerID);
        console.log(json)
        switch (Number(json.httpStatusCode)) {
            case 200: {
                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: 'Welcome email sent successfully.' });
                this.props.refreshCustomer();
                break;
            }
            default: {
                this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: json.Message });
                break;
            }
        }
        globalFunctions.finishLoading();
    };

    handleSaveInAfex = async () => {
        /*let valid = true;
        const jsonContacts = await getContactsByCustomer(this.props.id, 1, 100);
        console.log(jsonContacts)
        if (jsonContacts.contacts !== undefined) {
            const mainContact = jsonContacts.contacts.find( c => c['Main Contact$']);
            if (mainContact != null) {
                const contactInfo = await getContactByID(mainContact['Contact ID']);
                const Address1 = contactInfo['Address Value'];
                if (Address1 === '') {
                    this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'The beneficiary must have a bank account set as default' });
                } else {
                    valid = true;
                }
            }
        }*/

        //if (valid) {
        globalFunctions.startLoading();
        const json = await AFEXSaveCustomer(this.state.customer.CustomerID, localStorage.getItem('UserID'));
        console.log(json)
        switch (Number(json.httpStatusCode)) {
            case 200: {
                let message = 'Operation successfully completed.'
                if (json.currencyCloudMessage !== undefined && json.currencyCloudMessage !== null
                    && json.currencyCloudMessage !== '') {
                    message = <span> {message} <br /> {json.currencyCloudMessage}</span>
                }
                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: message });
                this.props.refreshCustomer();
                break;
            }
            default: {
                let message = json.httpErrorMessage
                let title = 'Error'
                let errors = []

                let validationMessage = json.validationMessage
                let validationMessageList = []
                if (validationMessage !== undefined && validationMessage !== null && validationMessage !== '') {
                    validationMessageList = validationMessage.split("\n") || []
                }

                if (json.errorMessage !== undefined && json.errorMessage !== null
                    && json.errorMessage !== '') {
                    message = <span> {message} <br /> {json.errorMessage}</span>
                }

                if (validationMessageList.length > 0) {
                    errors = validationMessageList
                    message = ''
                }
                this.setState({
                    showAlert: true, alertMessage: message, alertTitle: title, errors: errors
                });
                break;
            }
        }
        globalFunctions.finishLoading();
        //}
    }

    formatVisibilityIndividual = (customerType) => {
        return customerType.includes("Individual") ? "visible" : "hidden";
    }

    formatVisibilityCorporate = (customerType) => {
        return !customerType.includes("Individual") ? "visible" : "hidden";
    }

    closeAlert = () => {
        this.setState({ showAlert: false, errors: [] });
    }

    closeAlertError = () => {
        this.setState({ showAlertError: false });
    }

    closeAlertSaveSuspicious = async () => {
        this.setState({ showAlertSaveSuspicious: false, customer: { ...this.state.customer, Status: 'Suspicious' } });
        await this.saveCustomer(true);
    }

    closeAlertWatchlist = () => {
        this.setState({ showAlertWatchList: false, showWatchListsResult: true });
    }

    closeWatchListResult = () => {
        this.setState({ showWatchListsResult: false });
        this.props.refreshCustomer();
    }

    handleReturnToCustomerInfo = () => {
        this.setState({
            viewHistory: false,
            viewSendMessage: false,
            viewDynamicCover: false
        });
        if(this.props.removeBreadcrumb)
        {
            this.props.removeBreadcrumb();
        }
    }

    handleViewHistoryClick = () => {
        this.setState({ viewHistory: true });
        if(this.props.addBreadcrumb)
        {
            this.props.addBreadcrumb(<Breadcrumb.Item className="breadcrumb-item-bold">Audit Customer</Breadcrumb.Item>, 'hideCustomerDetail')
        }
    }

    handleHideGeneralInformationClick = () => {
        this.setState({
            hideGeneralInformation: !this.state.hideGeneralInformation
        })
    }

    handleHideContactInformationClick = () => {
        this.setState({
            hideContactInformation: !this.state.hideContactInformation
        })
    }

    handleHidePricingReferralsClick = () => {
        this.setState({
            hidePricingReferrals: !this.state.hidePricingReferrals
        })
    }

    handleHideOtherInformationClick = () => {
        this.setState({
            hideOtherInformation: !this.state.hideOtherInformation
        })
    }

    toggleShowRiskCalculation = () => {
        this.setState({ showRiskCalculation: !this.state.showRiskCalculation });
    }

    getAllAddresses = async () => {
        const json = await getAddressByPersonID(this.props.data.PersonID);
        return json.addresses;
    }

    validateStatusChange = async (currentStatus, newStatus) => {
        if (this.state.customer !== undefined && newStatus === 'Authorized to deal') {
            const addresses = await this.getAllAddresses();     
            let defaultAddresss = (addresses || []).filter(x => x.DefaultAddress$)            
            if (defaultAddresss === undefined || defaultAddresss.length === 0) {                                
                return "There is no default address set for this customer, please set a default address before setting the customer as 'Authorizing to deal'."
            }
        }
    }

    handleGetValueCustomerStatus = async (value) => {        
        let currentStatus = this.state.currentStatus        
        if (value != null && value !== 'select' && value.value !== undefined) {
            const validateStatus = await this.validateStatusChange(currentStatus, value.value)            
            if (validateStatus !== undefined && validateStatus !== '') 
            {
                this.setState({ showAlertError: true, alertTitle: 'Warning', alertMessage: validateStatus });
            }
            this.setState({
                customer: {
                    ...this.state.customer,
                    Status: value.value
                },
                validations: {
                    ...this.state.validations,
                    status: true
                }
            });
        }
    }

    handleGetValueSpreadPoints = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ customer: { ...this.state.customer, SpreadPoints: value.value } });
        }
    }

    handleGetValueServiceLevel = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ customer: { ...this.state.customer, ServiceLevel: value.value } });
        }
    }

    handleGetValueEmailTemplate = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({
                customer: { ...this.state.customer, EmailTemplate: value.value },
                validations: { ...this.state.validations, emailTemplate: true }
            });
        }
    }

    handleGetValueCustomerType = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({
                customer: { ...this.state.customer, CustomerType: value.value },
                validations: { ...this.state.validations, customerType: true }
            });
        }
    }
    handleGetValueSalutation = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({
                customer: { ...this.state.customer, Salutation: value.value }
            });
        }
    }

    handleGetValueRegion = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ customer: { ...this.state.customer, Region: value.value } });
        }
    }

    handleGetValueGroups = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ customer: { ...this.state.customer, CustomerGroupID: value.value } });
        }
    }

    handleGetValueIndustrySector = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ customer: { ...this.state.customer, IndustrySector: value.value } });
        }
    }

    handleGetValueLanguages = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({
                customer: { ...this.state.customer, PreferredLanguage: value.value },
                validations: { ...this.state.validations, preferredLanguage: true }
            });
        }
    }

    handleGetValueReportTemplate = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({
                customer: { ...this.state.customer, ReportTemplate: value.value },
                validations: { ...this.state.validations, reportTemplate: true }
            });
        }
    }

    handleGetValueCommissionBase = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ customer: { ...this.state.customer, CommissionBase: value.value } });
        }
    }

    handleGetValueReferredBy = (value) => {
        let referredBy = value;
        let commission = this.state.customer.CommissionBusiness;

        if (referredBy != null && referredBy !== 'select' && referredBy.value !== undefined) {
            if(referredBy.value === '')
            {
                commission = 0;
            }
            this.setState({ customer: { ...this.state.customer, ReferredBy: referredBy.value, CommissionBusiness: commission } });
        }
        else
        {
            this.setState({ customer: { ...this.state.customer, ReferredBy: '', CommissionBusiness: 0 } });

        }
    }

    handleGetValueFeeCategories = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ customer: { ...this.state.customer, OnlineFeeCategoryID: value.value } });
        }
    }

    handleGetValueFeeID = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ customer: { ...this.state.customer, FeeID: value.value } });
        }
    }

    handleGetValueBranch = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ customer: { ...this.state.customer, DefaultBranchID: value.value } });
        }
    }

    handleGetValuePricing = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ customer: { ...this.state.customer, DefaultPricingID: value.value } });
        }
    }

    handleGetValueParent = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined && value.value !== 'select') {
            this.setState({ customer: { ...this.state.customer, ParentCustomerID: value.value, ParentCustomerName: value.name } });
        }
    }

    handleGetValueAffiliateReferredBy = (value) => {
        let referredBy = value;
        let commission = this.state.customer.CommissionPercentage;

        if (referredBy != null && referredBy !== 'select' && referredBy.value !== undefined) {
            if(referredBy.value === '')
            {
                commission = 0;
            }
            this.setState({ customer: { ...this.state.customer, AffiliateReferredBy: referredBy.value, AffiliateReferredByName: referredBy.name, CommissionPercentage: commission } });
        }
        else
        {
            this.setState({ customer: { ...this.state.customer, AffiliateReferredBy: '', AffiliateReferredByName: '',CommissionPercentage: 0} });
        }
    }

    handleGetValueCurrency = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({
                customer: { ...this.state.customer, CommissionCurrency: value.value, CommissionCurrencyDescription: value.name != null ? value.name : '' },
                validations: { ...this.state.validations, commissionCurrency: true }
            });
        }
    }

    handleUpdateName = (event) => {
        this.setState({
            customer: { ...this.state.customer, Name: event.target.value },
            validations: { ...this.state.validations, companyName: true }
        });
    }

    handleUpdateFirstName = (event) => {
        this.setState({
            customer: { ...this.state.customer, FirstName: event.target.value },
            validations: { ...this.state.validations, firstName: true }
        });
    }

    handleUpdateLastName = (event) => {
        this.setState({
            customer: { ...this.state.customer, LastName: event.target.value },
            validations: { ...this.state.validations, lastName: true }
        });
    }

    handleUpdateShortName = (event) => {
        this.setState({ customer: { ...this.state.customer, ShortName: event.target.value } });
    }

    handleUpdatePhone = (phone) => {
        this.setState({ customer: { ...this.state.customer, Phone: phone } });
    }

    handleUpdateParentCustomerName = (event) => {
        this.setState({ customer: { ...this.state.customer, ParentCustomerName: event.target.value } });
    }

    handleUpdatePreferredName = (event) => {
        this.setState({ customer: { ...this.state.customer, PreferredName: event.target.value } });
    }

    handleUpdatePhoneExt = (event) => {
        this.setState({ customer: { ...this.state.customer, PhoneExt: event.target.value } });
    }

    handleUpdateOfficeFax = (event) => {
        this.setState({ customer: { ...this.state.customer, OfficeFax: event.target.value } });
    }

    handleUpdateExpiryByPassDate = (date) => {
        this.setState({
            customer: { ...this.state.customer, ExpiryByPassDate: formatDate(date) },
            validations: { ...this.state.validations, expiryBypassDate: true }
        });
    }

    handleUpdateByPassValidation = (event) => {
        this.setState({ customer: { ...this.state.customer, BypassValidation: event.target.checked, ExpiryByPassDate: '' } })
    }

    handleUpdateAffiliateCommission = (event) => {
        let commission = event.target.value;
        if(commission > 100)
        {
            commission = 100;
        }
        this.setState({ customer: { ...this.state.customer, CommissionPercentage: commission } });
    }

    handleUpdateReferredCommission = (event) => {
        let commission = event.target.value;
        if(commission > 100)
        {
            commission = 100;
        }
        this.setState({ customer: { ...this.state.customer, CommissionBusiness: commission } });
    }

    handleProcessStatus = (value) => {
        if (value != null && value !== "select") {
            let roleName = ''
            if (value.value === 'Sent for First Review') {
                roleName = 'FirstReview';
            }
            else if (value.value === 'Sent for Second Review') {
                roleName = 'SecondReview'
            }
            else if (value.value === 'Approved') {
                roleName = 'AMLCountryCompliance'
            }
            if (roleName !== '') {
                let disableAssignTo = true;
                getUsersByRole(roleName).then(
                    (json) => {
                        if (json !== undefined) {
                            if (json.users !== undefined) {
                                if (json.users.length > 0) {
                                    disableAssignTo = false;
                                }
                            }
                        }
                        if (disableAssignTo === false) {
                            for (var i = 0; i < json.users.length; i++) {
                                json.users[i].value = json.users[i].UserId;
                                json.users[i].name = json.users[i].UserName;
                            }
                            this.setState({
                                customer: { ...this.state.customer, ProcessStatus: value.value },
                                Users: json.users, disableAssignToButton: disableAssignTo
                            });
                        }
                    });
            }
            else {
                this.setState({ customer: { ...this.state.customer, ProcessStatus: value.value } });
            }
        }
    }

    handleAssignTo = (value) => {
        if (value != null && value !== "select") {
            this.setState({ customer: { ...this.state.customer, AssignTo: value.value } });
        }
    }

    handleSectionIcon = (hidden) => {
        return hidden ? "fa-sort-desc" : "fa-sort-asc";
    }

    handleSectionIconStyle = (hidden) => {
        return hidden ? { verticalAlign: 'middle', position: "absolute" } : { paddingTop: "10px", verticalAlign: 'middle', position: "absolute" };
    }

    formatComplianceVisibility() {
        if (this.state.enableCompliance !== undefined) {
            if (this.state.enableCompliance) {
                return "flex";
            }
            else {
                return "none";
            }
        } else { return "none"; }
    }

    handleUpdateProcessStatus() {
        /*if (this.state.customer.AssignTo === undefined || this.state.customer.AssignTo === null || this.state.customer.AssignTo === '') {
            this.setState({ showAlertError: true, alertTitle: 'Error', alertMessage: 'Please select a user.' });
        }
        else if (this.state.customer.ProcessStatus === undefined || this.state.customer.ProcessStatus === null || this.state.customer.ProcessStatus === '') {
            this.setState({ showAlertError: true, alertTitle: 'Error', alertMessage: 'Please select a process status.' });
        }*/
        
        /*if((this.state.customer.ProcessStatus === 'Sent for First Review' 
            || this.state.customer.ProcessStatus === 'Sent for Second Review'
            || this.state.customer.ProcessStatus === 'Approved') 
            && (this.state.customer.ProcessStatus === undefined 
            || this.state.customer.ProcessStatus === null 
            || this.state.customer.ProcessStatus === '')){
            this.setState({ showAlertError: true, alertTitle: 'Error', alertMessage: 'Please select a process status.' });
        }
        else {
            this.setState({ customer: { ...this.state.customer, AssignTo: localStorage.getItem('UserID') } });
            this.updateProcessStatus();
            if (this.state.customer.Status === 'Registered Online') {
                this.deleteWorkAssignments();
            }
        }*/
        if(this.state.customer.ProcessStatus !== 'Sent for First Review' 
            && this.state.customer.ProcessStatus !== 'Sent for Second Review'
            && this.state.customer.ProcessStatus !== 'Approved'){
            this.setState({ customer: { ...this.state.customer, AssignTo: localStorage.getItem('UserID') } });
        }

        this.updateProcessStatus();
        if (this.state.customer.Status === 'Registered Online') {
            this.deleteWorkAssignments();
        }
    }

    updateProcessStatus() {
        updateProcessStatus(this.state.customer.CustomerID, 'Customer', this.state.customer.ProcessStatus, this.state.customer.AssignTo).then(
            (json) => {
                console.log(json)
                if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200) {
                    this.setState({ showAlert: true, alertMessage: json.Message, alertTitle: 'Error' });
                } else {
                    this.setState({
                        showAlert: true, alertTitle: 'Process Status updated.'
                    });
                }
            }
        )
    }

    deleteWorkAssignments() {
        deleteWorkAssignments(this.state.customer.CustomerID, 'Customer').then(
            (json) => {
                //console.log(json)
                /*if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200){
                    this.setState({showAlert: true, alertMessage: json.Message, alertTitle: 'Error'});
                } else {}*/
            }
        )
    }

    onCloseAttachmentsDialog = () => {
        this.setState({
            showAttachmentsDialog: false,
        })
    }

    handleDropdownButtonClick = async () => {
        const fields = [
            { displayName: 'Dropdown', validateName: 'dropdownSelection', value: this.state.dropdownButtonSelectionKey, type: 'string' }
        ];
        const [valid] = validate(fields);
        if (valid) {
            switch (this.state.dropdownButtonSelectionKey) {
                default: break;
                case 'Currency Cloud - Auto Cover':
                case 'Currency Cloud - Pass Thru':
                case 'Currency Cloud - Pass Thru with cover':
                case 'Currency Cloud - Payments': {
                    await this.handleSendToCurrencyCloud();
                    break;
                }
                case 'AFEX':
                case 'AFEX - Auto Cover':
                case 'AFEX - Pass Thru':
                case 'AFEX - Pass Thru With Cover':
                case 'AFEX - Payments': {
                    await this.handleSaveInAfex();
                    break;
                }
                case 'Monoova':{                    
                    this.openConfirm('Monoova', 'Are you sure to register this customer with Monoova?');
                    break;
                }
                case 'Zepto':{
                    this.openConfirm('Zepto', 'Are you sure to register this customer with Zepto?');
                    break;
                }
            }
        } else {
            this.setState({ validateDropdownButton: false });
        }
    }

    //#region Message Confirm
    openConfirm(title, message){
        this.setState({showConfirm: true, alertTitle: title, alertMessage: message});
    }

    yesConfirm(){
        let eventModelMessage = this.state.alertTitle;
        if(eventModelMessage == 'Monoova'){
            this.handleSendMonoova();
        }
        else if(eventModelMessage == 'Zepto'){
            this.handleSendZepto();
        }
        this.setState({showConfirm: false});
    }
    
    closeConfirm(){
        this.setState({showConfirm: false});
    }
    //#endregion

    handleUpdateDropdownButton = (value) => {
        if (value !== undefined) {
            this.setState({ dropdownButtonSelectionKey: value.value, validateDropdownButton: true });
        }
    }

    render() {
        let accordionID = 'accordion-customer-information-' + uuidv4();

        var customerinfoScreen = '';
        if (this.state.viewHistory) {
            customerinfoScreen = (
                <div>
                    <AuditCustomerScreen isTitle={true} customerID={this.props.id} />
                </div>
            );
        } else if (this.state.viewDynamicCover) {
            customerinfoScreen = (
                <div>
                    <div className="breadcrumb-div">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleReturnToCustomerInfo}>General Information</Breadcrumb.Item>
                            <Breadcrumb.Item className="breadcrumb-item-bold">Dynamic Cover</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <DynamicCoverScreen isTitle={true} customerID={this.props.id} />
                </div>);
        } else {
            customerinfoScreen = (
                <div>
                    <div className="">
                        <div className="uk-grid uk-margin" style={{ display: this.formatComplianceVisibility() }}>
                            <div className="uk-width-1-6" style={{ visibility: "hidden" }}>
                                <label className="uk-form-label" ></label>
                            </div>
                            <div className="uk-width-1-6">
                                <label className="uk-form-label" >Process Status</label>
                            </div>
                            <div className="uk-width-1-6">
                                <DynamicSelect objValue={{ value: this.state.customer.ProcessStatus }} getValue={this.handleProcessStatus} listHasPlaceholder={true} data={this.state.ProcessStatuses} id="form-process-status" />
                            </div>
                            {/*<div className="uk-width-1-6">
                                <label className="uk-form-label" >Assign to</label>
                            </div>
                            <div className="uk-width-1-6">
                                <DynamicSelect objValue={{ value: this.state.customer.AssignTo }} disabled={this.state.disableAssignToButton} getValue={this.handleAssignTo} listHasPlaceholder={false} placeholder="Select a user..." data={this.state.Users} id="form-process-status" />
            </div>*/}
                            <div className="uk-width-1-6">
                                <button className="uk-button uk-button-green" onClick={this.handleUpdateProcessStatus.bind(this)} type="button">Update process Status</button>
                            </div>
                        </div>
                        <ul id={accordionID} uk-accordion="multiple: true" >
                            {/*General Information*/}
                            <li className="uk-open">
                                <h5 className="uk-accordion-title">General Information</h5>
                                <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                    <div id="general-information">
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.boldLabel} >Type (*)</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect className={formatValidSelect(this.state.validations.customerType)} objValue={{ value: this.state.customer.CustomerType }} getValue={this.handleGetValueCustomerType} listHasPlaceholder={true} data={this.state["Customer Type"]} id="form-customer-type" />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.boldLabel}>Status (*)</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect className={formatValidSelect(this.state.validations.status)} objValue={{ value: this.state.customer.Status }} getValue={this.handleGetValueCustomerStatus} listHasPlaceholder={true} data={this.state["Customer Status"]} id="form-customer-status" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Parent</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSearchSelect id="parent-list" placeholder="Parent" searchFunction={this.searchParent} getValue={this.handleGetValueParent} data={this.state.listParents} objValue={{ value: this.state.customer.ParentCustomerID, name: this.state.customer.ParentCustomerName }} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked" style={{ visibility: this.formatVisibilityIndividual(this.state.customer.CustomerType) }}>
                                                <label className="uk-form-label" >Salutation</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.Salutation === null ? [] : this.state.customer.Salutation }} getValue={this.handleGetValueSalutation} listHasPlaceholder={true} data={this.state["Salutation"]} id="form-salutation" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked" style={{ visibility: this.formatVisibilityIndividual(this.state.customer.CustomerType) }}>
                                                <label className="uk-form-label" style={style.boldLabel}>First Name (*)</label>
                                                <div className="" style={style.inputStyle}>
                                                    <input className={formatValidInput(this.state.validations.firstName)} type="text" placeholder="" value={this.state.customer.FirstName} onChange={this.handleUpdateFirstName} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked" style={{ visibility: this.formatVisibilityIndividual(this.state.customer.CustomerType) }}>
                                                <label className="uk-form-label" style={style.boldLabel}>Last Name (*)</label>
                                                <div className="" style={style.inputStyle}>
                                                    <input className={formatValidInput(this.state.validations.lastName)} type="text" placeholder="" value={this.state.customer.LastName} onChange={this.handleUpdateLastName} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked" style={{ visibility: this.formatVisibilityCorporate(this.state.customer.CustomerType) }}>
                                                <label className="uk-form-label" style={style.boldLabel}>Company Name (*)</label>
                                                <div className="" style={style.inputStyle}>
                                                    <input className={formatValidInput(this.state.validations.companyName)} type="text" placeholder="" value={this.state.customer.Name} onChange={this.handleUpdateName} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-4 uk-form-stacked">
                                                <label className="uk-form-label">Preferred Name</label>
                                                <div className="" style={style.inputStyle}>
                                                    <input className="uk-input" type="text" placeholder="" value={this.state.customer.PreferredName} onChange={this.handleUpdatePreferredName} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-4 uk-form-stacked" style={{ paddingTop: '22px' }}>
                                                <button className="uk-button-green" onClick={this.searchCustomerInGlobalWatchLists} style={{ borderRadius: '10px', cursor: 'pointer' }}>✓</button>
                                            </div>
                                        </div>
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Short Name</label>
                                                <div className="" style={style.inputStyle}>
                                                    <input className="uk-input" type="text" placeholder="" value={this.state.customer.ShortName} onChange={this.handleUpdateShortName} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Branch</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.DefaultBranchID }} getValue={this.handleGetValueBranch} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.branches} id="form-branch" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Region</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.Region }} getValue={this.handleGetValueRegion} listHasPlaceholder={true} data={this.state["Customer Regions"]} id="form-regions" />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Group</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.CustomerGroupID }} getValue={this.handleGetValueGroups} listHasPlaceholder={true} data={this.state["Customer Groups"]} id="form-groups" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            {/*Contact Information*/}
                            <li>
                                <h5 className="uk-accordion-title">Contact Information</h5>
                                <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                    <div id="contact-information">
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-3 uk-form-stacked">
                                                <label className="uk-form-label">Phone Number</label>
                                                <div className="" style={style.inputStyle}>
                                                    <PhoneInput
                                                        inputProps  = {{
                                                            name        : 'phone',
                                                            required    : true,
                                                            autoFocus   : true
                                                        }}
                                                        enableSearch        = {true}
                                                        disableSearchIcon   = {true}
                                                        containerStyle      = {{
                                                            height: 30
                                                        }}
                                                        inputClass  = {'uk-input'}
                                                        inputStyle  = {{
                                                            height: 30,
                                                            borderRadius: 0,
                                                            width: '100%'
                                                        }}
                                                        buttonStyle = {{
                                                            borderColor: '#CACACA'
                                                        }}
                                                        placeholder = 'Enter a Mobile Number'
                                                        autoFormat
                                                        copyNumbersOnly     = {true}
                                                        countryCodeEditable = {true}
                                                        value               = {this.state.customer.Phone}
                                                        onChange            = {this.handleUpdatePhone}
                                                        preferredCountries  = {['gb']}
                                                        searchPlaceholder   = 'Search a country'
                                                        autocompleteSearch/>
                                                </div>
                                            </div>
                                            <div className="uk-width-1-3 uk-form-stacked">
                                                <label className="uk-form-label">Extension</label>
                                                <div className="" style={style.inputStyle}>
                                                    <input className="uk-input" type="text" placeholder="" value={this.state.customer.PhoneExt} onChange={this.handleUpdatePhoneExt} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-3 uk-form-stacked">
                                                <label className="uk-form-label">Office Fax</label>
                                                <div className="" style={style.inputStyle}>
                                                    <input className="uk-input" type="text" placeholder="" value={this.state.customer.OfficeFax} onChange={this.handleUpdateOfficeFax} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            {/*Pricing and Referrals*/}
                            <li>
                                <h5 className="uk-accordion-title">Pricing and Referrals</h5>
                                <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                    <div id="pricing-referrals">
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Spread / Points</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.SpreadPoints }} getValue={this.handleGetValueSpreadPoints} listHasPlaceholder={true} data={this.state["Spread Points"]} id="form-spread-points" />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Service Level</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.ServiceLevel }} getValue={this.handleGetValueServiceLevel} listHasPlaceholder={true} data={this.state["Service Level"]} id="form-service-level" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Pricing</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.DefaultPricingID }} getValue={this.handleGetValuePricing} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.ratePricingCategories} id="form-pricing" />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Online Fee Category</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.OnlineFeeCategoryID }} getValue={this.handleGetValueFeeCategories} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.feeCategories} id="form-fee-categories" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Fee Id</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.FeeID }} getValue={this.handleGetValueFeeID} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.feeStructures} id="form-fee-id" />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Commission Base</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.CommissionBase }} getValue={this.handleGetValueCommissionBase} listHasPlaceholder={true} data={this.state["Commission Base"]} id="form-commission-base" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Referred By</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.ReferredBy }} getValue={this.handleGetValueReferredBy} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.referredBySelect} id="form-referred-by" />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Referred Commission</label>
                                                <div className="" style={style.inputStyle}>
                                                    <NumberInput value={this.state.customer.CommissionBusiness} disabled={this.state.customer.ReferredBy === '' || this.state.customer.ReferredBy === null} onChange={this.handleUpdateReferredCommission} className="uk-input" type="Currency" scale={getScaleByCurrency(this.state.customer.CommissionCurrency)} placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Referred by Affiliate / Introducer</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSearchSelect id="referred-by-affiliate" placeholder="Referred By Affiliate / Introducer" searchFunction={this.searchAffiliate} getValue={this.handleGetValueAffiliateReferredBy} data={this.state.listAffiliates} objValue={{ value: this.state.customer.AffiliateReferredBy, name: this.state.customer.AffiliateReferredByName }} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Affilliate Commission</label>
                                                <div className="" style={style.inputStyle}>
                                                    <NumberInput value={this.state.customer.CommissionPercentage}  disabled={this.state.customer.AffiliateReferredBy === '' || this.state.customer.AffiliateReferredBy === null} onChange={this.handleUpdateAffiliateCommission} className="uk-input" type="Currency" scale={getScaleByCurrency(this.state.customer.CommissionCurrency)} placeholder="" />
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.boldLabel}>Commissions Currency (*)</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect className={formatValidSelect(this.state.validations.commissionCurrency)} objValue={{ value: this.state.customer.CommissionCurrency }} getValue={this.handleGetValueCurrency} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.currencies} id="form-commission-currencies" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            {/*Other Information*/}
                            <li>
                                <h5 className="uk-accordion-title">Other Information</h5>
                                <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                    <div id="other-information">
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.boldLabel}>Preferred Language (*)</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect className={formatValidSelect(this.state.validations.preferredLanguage)} objValue={{ value: this.state.customer.PreferredLanguage }} getValue={this.handleGetValueLanguages} listHasPlaceholder={true} data={this.state["Languages"]} id="form-languages" />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Industry Sector</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect objValue={{ value: this.state.customer.IndustrySector }} getValue={this.handleGetValueIndustrySector} listHasPlaceholder={true} data={this.state["Industry Sectors"]} id="form-industry-sectors" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.boldLabel}>Bypass Validation (*)</label>
                                                <div className="" style={style.inputStyle}>
                                                    <div className="uk-grid">
                                                        <div className="uk-width-1-2 uk-form-stacked">
                                                            <label><input className="uk-checkbox" value={this.state.customer.BypassValidation} checked={this.state.customer.BypassValidation} onChange={this.handleUpdateByPassValidation} type="checkbox" /> Expiry bypass date</label>
                                                        </div>
                                                        <div className="uk-width-1-2 uk-form-stacked">
                                                            <DatePicker useNew readOnly={true} disabled={!this.state.customer.BypassValidation} className={formatValidDatePicker(this.state.validations.expiryBypassDate)} value={this.state.customer.ExpiryByPassDate} onDayChange={this.handleUpdateExpiryByPassDate} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.boldLabel}>Risk Score (*)</label>
                                                <div className="" style={style.inputStyle}>
                                                <Badge
                                anchorOrigin={{
                                    vertical    : 'top',
                                    horizontal  : 'right',
                                }}
                                badgeContent={
                                    <InfoIcon 
                                        uk-tooltip  = {'Risk Calculation Customer'}
                                        style       = {{ color: '#6E9C3A' }}
                                    />
                                }
                                disabled={true}
                            >
                                <div 
                                    className   = "" 
                                    type        = "button" 
                                    style       = {{
                                        borderRadius    : 10,
                                        border          : '1px solid #e5e5e5',
                                        height          : 30,
                                        justifyContent  : 'center',
                                        display         : 'flex',
                                        alignItems      : 'center',
                                        paddingLeft      : 10,
                                        paddingRight     : 10,
                                        backgroundColor : this.state.backgroundColorRiskScore
                                    }}
                                >
                                    <label 
                                        onClick     = { this.toggleShowRiskCalculation}
                                        className   =""
                                        style={{ color: (this.state.riskScore !== undefined ? this.state.riskScore['Color Hex'] : 'inherit'), fontWeight: this.state.fontWeight, cursor: 'pointer' }}
                                        id          = "CustomerRiskScore"
                                    >{this.state.riskScore.Description}
                                    </label>
                                </div>
                            </Badge>

                                                   </div>
                                            </div>
                                        </div>
                                        <div className="uk-grid" style={{ marginTop: "0px" }}>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.boldLabel}>Confirmation Template (*)</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect className={formatValidSelect(this.state.validations.reportTemplate)} objValue={{ value: this.state.customer.ReportTemplate }} getValue={this.handleGetValueReportTemplate} listHasPlaceholder={true} data={this.state["Reports Templates"]} id="form-reports-templates" />
                                                </div>
                                            </div>
                                            {useEmailTemplate !== undefined && useEmailTemplate === true && <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.boldLabel}>Email template (*)</label>
                                                <div className="" style={style.inputStyle}>
                                                    <DynamicSelect
                                                        className={formatValidSelect(this.state.validations.emailTemplate)}
                                                        objValue={{ value: this.state.customer.EmailTemplate }}
                                                        getValue={this.handleGetValueEmailTemplate}
                                                        listHasPlaceholder={true}
                                                        data={this.state["Emails Templates"]}
                                                        id="form-emails-templates"
                                                    />
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="uk-grid uk-margin">
                            <div className="uk-width-3-4">
                                {this.state.isReadOnly && <button className="uk-button uk-button-green uk-margin-right">Save</button>}
                                {!this.state.isReadOnly && <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSaveClick} >Save</button>}
                                <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleViewHistoryClick}>View History</button>
                                <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleAttachmentsClick}>Attachments</button>
                                <button className="uk-button uk-button-green uk-margin-right" hidden={true} onClick={this.handlePrintBookletClick}>Print booklet</button>
                                <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSendMessageClick}>Send Message</button>
                                <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleDynamicCoverClick}>Dynamic Cover</button>
                                {this.state.showGetSubAccountIdButton && <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleGetSubAccountIDClick}>Get subaccount ID</button>}
                                {this.props.data != null && this.props.data.Status === 'Authorized to deal' && <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSendWelcomeMessageClick}>Send Welcome Email</button>}
                            </div>
                            <div className="uk-width-1-4 uk-grid" >
                                {/*<select onChange={this.handleUpdateDropdownButton}
                                        className={'uk-form-width-medium ' + formatValidSelect(this.state.validateDropdownButton)}
                                        style={{ marginRight: '5px' }}>
                                    <option value=''>Select an option...</option>
                                    <option value='save-currency-cloud'>Send to CurrencyCloud</option>
                                    {this.state.enableButtonSaveInAfex &&<option value='save-afex'>Send to AFEX</option>}
            </select> */}
                                <div className="uk-width-1-2  uk-text-right">
                                    <DynamicSelect
                                        className={'uk-form-width-medium ' + formatValidSelect(this.state.validateDropdownButton)}
                                        objValue={{ value: this.state.dropdownButtonSelectionKey }}
                                        getValue={this.handleUpdateDropdownButton}
                                        placeholder='Select an option...'
                                        data={this.state.bankFixInterfacesToSend}
                                        id="form-send-to-interface-side" />
                                </div>
                                <div className="uk-width-1-2  uk-text-left">
                                    <button className="uk-button uk-button-green" onClick={this.handleDropdownButtonClick}>Send</button>
                                </div>
                            </div>
                            {/* <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSendToCurrencyCloud}>Send To Currency Cloud</button> */}
                            {/* {this.state.enableButtonSaveInAfex && (<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSaveInAfex}>Save in AFEX</button>)} */}
                        </div>
                        <AlertBox
                            open={this.state.showAlert}
                            onClose={this.closeAlert}
                            title={this.state.alertTitle}
                            message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} messageBoldList={this.state.errors} />
                        <AlertBox
                            open={this.state.showAlertError}
                            onClose={this.closeAlertError}
                            title={this.state.alertTitle}
                            messageBoldList={this.state.errors}
                            message={this.state.alertMessage} type="Ok" okClick={this.closeAlertError} />
                        <AlertBox
                            open={this.state.showAlertWatchList}
                            onClose={this.closeAlertWatchlist}
                            title={this.state.alertTitle}
                            message={this.state.alertMessage} type="Ok" okClick={this.closeAlertWatchlist} />
                        <AlertBox
                            open={this.state.showAlertSaveSuspicious}
                            onClose={this.closeAlertSaveSuspicious}
                            title={this.state.alertTitle}
                            message={this.state.alertMessage} type="Ok" okClick={this.closeAlertSaveSuspicious} />
                        <AlertBox 
                            open={this.state.showConfirm} 
                            onClose={this.closeConfirm.bind(this)} 
                            title={this.state.alertTitle}
                            message={this.state.alertMessage}
                            type="Yes/No" 
                            yesClick={this.yesConfirm.bind(this)} 
                            noClick={this.closeConfirm.bind(this)}/>
                        <RiskCalculationDialog
                            riskScoreDetails={this.state.riskScoreDetails}
                            open={this.state.showRiskCalculation}
                            close={this.toggleShowRiskCalculation}
                            entityType={"Customer"}
                        />
                        <SendMessageScreen
                            open={this.state.viewSendMessage}
                            backLink={this.handleReturnToCustomerInfo}
                            customerID={this.props.id}
                        />
                        {this.state.showWatchListsResult && <GlobalWatchListsResultDialog
                            open={this.state.showWatchListsResult}
                            onClose={this.closeWatchListResult}
                            nameToSearch={this.state.nameToSearch}
                            resultList={this.state.watchListResults}
                            entityID={this.props.id}
                            entityType='Customer'
                        />}
                        {this.props.data != null && <AttachmentsDialog
                            open={this.state.showAttachmentsDialog}
                            onClose={this.onCloseAttachmentsDialog}
                            entityID={this.props.data.CustomerID}
                            entityType='Customer'
                        />}
                    </div>
                </div>
            )
        }
        return (
            <div>
                {customerinfoScreen}
            </div>
        );
    }
}