import React from 'react';
import { validateDateString } from '../../helpers/ValidateHelper';
import { getStyle } from '../../styles/styles';
import Paginator from './Paginator';
import TableFilter from './TableFilter';
import TheCSVButton from './TheCSVButton';

var style = getStyle();

class Row extends React.Component {

    state={
        isSelectedOneCheck : false
    }
   
    handleRowClick(){        
        var objClicked = this.props.objData;
        this.props.onClick(objClicked);
    }

    handleDeleteClick(event){
        event.stopPropagation();
        this.props.deleteClick(this.props.objData);
    }

    handleDynamicFunction(event){
        event.stopPropagation();
        var action = this.props.dynamicActions[event.target.id];
        
        action.function(this.props.objData);
    }

    handleCheckBoxFunction(event){
        event.stopPropagation();
        this.props.checkboxFunction(this.props.objData, event.target.checked);
    /*    this.setState({
            isSelectedOneCheck: event.target.checked
        })*/
    }

    handleRadioButtonFunction(event){
        event.stopPropagation();
        this.props.radioButtonFunction(this.props.objData, event.target.checked);
     /*   this.setState({
            isSelectedOneCheck: event.target.checked
        })*/
    }

    handleCheckBoxDynamicFunction(event){
        event.stopPropagation();
        var action = this.props.dynamicCheckboxFunction[event.target.id];
        var checked = event.target.checked;
        action.function(this.props.objData, checked);
    }

    generateActions = (cells) => {
        var buttons = [];

            //Children action
            if(this.props.hasChildren && 
                this.props.objData['ChildrenRecords$'] !== undefined &&
                this.props.objData['ChildrenRecords$'] !== null){
                buttons.push(
                <div key={'div-' + this.props.tableID + '-child-records-' + this.props.id}
                    id={'div-' + this.props.tableID + '-child-records-' + this.props.id} uk-toggle={'target : #' + this.props.tableID + '-child-records-' + this.props.id}
                    style={{cursor: 'pointer'}}
                >
                    <i id={this.props.tableID + '-child-records-' + this.props.id} className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}}/>
                    <i id={this.props.tableID + '-child-records-' + this.props.id} className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} hidden/>
                </div>);
            }

            if (this.props.dynamicActions !== undefined){
                for(var i=0; i<this.props.dynamicActions.length; i++){
                    var action = this.props.dynamicActions[i];
                    var isIcon = false;
                    if(this.props.dynamicActions[i].isIcon !== undefined){
                        isIcon = this.props.dynamicActions[i].isIcon;
                    }
                    if(isIcon){
                        buttons.push(<i key={i} id={i} className={action.name} onClick={this.handleDynamicFunction.bind(this)} style={{ fontSize: '1.25em', verticalAlign: 'middle', cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px'  }}/>);
                    } else {
                        buttons.push(<button key={i} id={i} className="uk-button uk-button-default" type="button" onClick={this.handleDynamicFunction.bind(this)}>{action.name}</button>);
                    }
                }
                
            }

            if (this.props.useDeleteButton){
                buttons.push(<i key={"delete"} className="fa fa-trash" onClick={this.handleDeleteClick.bind(this)} style={{ fontSize: '1.25em', verticalAlign: 'middle', cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px'  }}/>);
                //cells.push(<td key={cells.length} style={style.alignCenter}><button className="uk-button uk-button-default" type="button" onClick={this.handleDeleteClick.bind(this)}>Delete</button></td>);
            } 

            if(this.props.dynamicActions || this.props.useDeleteButton || this.props.hasChildren){
                cells.push(<td key="actions" style={style.alignCenter}><div className="uk-button-group">{buttons}</div></td>);
            }
    }
    
    render(){ 
        var startWithActions = false;
        var hiddenHeaders = [];
        var useCheckBox = false;
        var formats =[];
        var useMultiCheckBox = false;
        var useRadioButton = false;
        var selectedRows = [];

        if(this.props.hiddenHeaders !== undefined){
            hiddenHeaders = this.props.hiddenHeaders;
        }
        if(this.props.formats !== undefined){
            formats = this.props.formats;
        }
        if(this.props.startWithActions !== undefined){
            startWithActions = this.props.startWithActions;
        }
        if(this.props.useCheckBox !== undefined){
            useCheckBox = this.props.useCheckBox;
        }
        if(this.props.useMultiCheckBox !== undefined){
            useMultiCheckBox = this.props.useMultiCheckBox;
        }

        if(this.props.useRadioButton !== undefined){
            useRadioButton = this.props.useRadioButton;
        }

        if(this.props.selectedRows !== undefined)
        {
            selectedRows = this.props.selectedRows;
        }

        var obj = this.props.objData;
        var enableClick = this.props.enableClick;
        //var keys = Object.keys(obj);
        var cells = [];

        
        if(startWithActions){
            this.generateActions(cells);
        }

        if(this.props.objData !== undefined && this.props.objData !== null)
        {

            var isSelected = false;
            //console.log(selectedRows.length);
            if(selectedRows.length > 0)
            {
                selectedRows.forEach(element =>
                    {
                        if(element === this.props.objData)
                        {
                            isSelected = true;
                        }
                    });                
            }

            //this.setState({isSelectedOneCheck: isSelected});

            var headers = Object.keys(this.props.objData);
            let i = 0;
            if (useRadioButton && this.props.radioButtonFunction !== undefined) {
                cells.push(<td key={i} style={{...style.alignCenter, verticalAlign: 'middle'}}><input className="uk-radio" name="Radio" style={{marginRight:0}} checked={isSelected} onClick={(event) => this.handleRadioButtonFunction(event)} type="radio" /></td>);
            }
            if (useCheckBox && this.props.checkboxFunction !== undefined) {
                cells.push(<td key={i} style={{...style.alignCenter, verticalAlign: 'middle'}}><input className="uk-checkbox" style={{marginRight:0}}  checked={isSelected} onClick={(event) => this.handleCheckBoxFunction(event)} type="checkbox" /></td>);
            }
            if (useMultiCheckBox && this.props.dynamicCheckboxFunction !== undefined && this.props.dynamicCheckboxHeaders !== undefined) {
                let c=0;
                for(c=0; c< this.props.dynamicCheckboxHeaders.length; c++)
                {
                    cells.push(<td key={'checkbox_'+c} style={{...style.alignCenter, verticalAlign: 'middle'}}>
                        <input className="uk-checkbox" id={c} onClick={this.handleCheckBoxDynamicFunction.bind(this)} type="checkbox" />
                        </td>);
                }
            }
            for (i = 1; i <= headers.length; i++) 
            {
                var includesHiddenDollar = headers[i - 1].includes('$');
                if (headers[i] !== 'TotalCount')
                {
                    if(!includesHiddenDollar)
                    {
                        var isHidden = false;
                        var originalData = obj[headers[i - 1]];
                        var dataToPrint = obj[headers[i - 1]];
                        for(var j=0; j<formats.length; j++)
                        {
                            if(formats[j].header === headers[i - 1]){
                                dataToPrint = formats[j].formatFunction(dataToPrint);
                            }
                        }
                        for(var k=0; k<hiddenHeaders.length; k++){
                            if(headers[i - 1] === hiddenHeaders[k]){
                                //cells.push(<td key={i} style={{display: 'none'}}>{dataToPrint}</td>);
                                isHidden = true;
                                break;
                            }
                        }

                        let textAlign = 'left';

                        // This also aligns nonvalid phones to the right
                        // TODO: Add an else condition to validate phone number so we can align it to the left                        
                        if (typeof originalData === 'number' || (originalData !== null && typeof originalData === 'string' && isFinite(originalData.replace(',','')) && !validateDateString(originalData))) {
                            textAlign = 'right'
                        }

                        if(!isHidden){
                            if(Array.isArray(dataToPrint)){
                                let newArray = [];
                                dataToPrint.forEach((value) => {
                                    newArray.push(value.Description);
                                })
                                dataToPrint = newArray.join(', ')
                            }
                            cells.push(<td key={i} style={{textAlign: textAlign, verticalAlign:'middle'}}>{dataToPrint}</td>);
                        }
                    }
                }
            }
        }

        if(!startWithActions){
            this.generateActions(cells);
        }

        /*if (this.props.useDeleteButton){
            //cells.push(<td key={cells.length} style={style.alignCenter}><button className="uk-button uk-button-default" type="button" onClick={this.handleDeleteClick.bind(this)}>Delete</button></td>);
            cells.push(<td key={"delete"} style={style.alignCenter}><i className="fa fa-fw fa-trash uk-align-center" style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer', marginBottom: 0  }} onClick={this.handleDeleteClick.bind(this)}/></td>);
        }*/ 


        if(enableClick) {
            return (
                <tr 
                    onClick={this.handleRowClick.bind(this)} 
                    style={{
                        cursor:'pointer', 
                        backgroundColor: this.state.isSelectedOneCheck || isSelected === true ? '#aed38c': null
                    }}
                >
                    {cells}
                </tr>
            );
        } else if (useCheckBox) {
            return (
                <tr 
                    style={{
                        backgroundColor: isSelected === true ? '#aed38c' : null
                    }}
                >
                    {cells}
                </tr>
            );
        } else {
            return (
                <tr>
                    {cells}
                </tr>
            );
        }
    }
}

//HOW TO USE:
//Need one array. 
//data Array must an array of objects.
//If enableClick is false, you dont need to add a clickFunction.
//The function to use will be -> functionName(obj){};
//The obj will be the object of data array.
//<DynamicTable data={[{header1: test1, header2: test2},{header1: test3, header2: test4}]} enableClick={true} clickFunction={this.handleRowClick.bind(this)} />
//entityID=
//dynamicActions={[{name: 'Button 1', function: this.action1.bind(this)},{name: 'Action 2', function: this.action2.bind(this)}]}
//dynamicActions={[{name: 'fa fa-fw fa-briefcase', function: this.action1.bind(this), isIcon: true}]}
//startWithActions={true} dynamicActions={[{name: 'fa fa-fw fa-superpowers', function: this.action1.bind(this), isIcon: true}]}
export default class NewDynamicTable extends React.Component {
    
    constructor(props) {
        super(props);
        var listSortA = []
        if(this.props.data && this.props.data !== undefined && this.props.data !== [])
        {
            if(this.props.data.length >0)
            {
                for(var i=0; i<Object.keys(this.props.data[0]).length; i++)
                {
                    listSortA.push(1)
                }
            }
        }
        var total = 1 
        if(this.props.numberOfPages !== undefined)
        {
            total = this.props.numberOfPages
        }
        
        this.state = { 
            data            : this.props.data,
            listToShow      : [],
            listSort        : listSortA,
            filterScreen    : false,
            loading         : false,
            filtering       : false,
            filters         : {},
            numberOfPages   : total,
            pageNumber      : this.props.pageNumber,
            isAllChecked    : false,
        };
    }

    componentDidUpdate(prevProps, prevState) 
    {
        // Uso tipico (no olvides de comparar los props):
        if ((this.props.data !== prevProps.data && this.props.data !== undefined))
        {
            if(this.props.data.length>0)
            {
                var listAux = []
                for(var i=0; i<Object.keys(this.props.data[0]).length; i++)
                {
                    listAux.push(1)
                }
                this.setState({
                    data: this.props.data,
                    listSort: listAux,
                    numberOfPages: this.props.numberOfPages,
                    pageNumber: this.props.pageNumber,
                    filtering: false,
                    isAllChecked: false,
                })
            } else {
                this.setState({
                    data: [],
                    listSort: [],
                    isAllChecked: false,
                })
            }
        }

        if(this.props.pageNumber !== prevProps.pageNumber)
        {
            this.setState({
                pageNumber: this.props.pageNumber
            })
        }

        if(this.props.numberOfPages !== prevProps.numberOfPages)
        {
            this.setState({
                numberOfPages: this.props.numberOfPages
            })
        }
      }

    handleRowClick(obj) {
        this.props.clickFunction(obj);
    }

    handleDeleteClick(obj) {
        if (!JSON.parse(localStorage.getItem('HasComplianceAccess'))) {
            this.props.deleteFunction(obj);
        }
    }

    handleCheckBoxFunction(obj, checked) {
        //console.log("obj cuando se da check a un row");
        console.log(obj)
        console.log(checked)
        this.props.checkboxFunction(obj, checked, false);
    }

    handleRadioButtonFunction(obj, checked) {
        this.props.radioButtonFunction(obj, checked);
    }

    sorterByHeader = (header, index) => e => 
    {
        //console.log("header por el q será sorteado: " + header)
        //console.log("value  por el q será sorteado: " + this.state.listSort[index])
        //console.log("this.state.data: " + JSON.stringify(this.state.data))
        var newDataSort = this.state.data
        newDataSort.sort(this.dynamicSort(header, this.state.listSort[index]));
        /*newDataSort.forEach(element => {
            console.log("new Data Sort: " + JSON.stringify(element[header]))
        });*/
        

        var newListAux = this.state.listSort
        newListAux.forEach((element,i) => {
            if(i === index)
            {
                if(newListAux[index] === -1)
                {
                    newListAux[index] = 1
                }else
                {
                    newListAux[index] = -1
                }
            }else
            {
                newListAux[i] = 1
            }
        });
        
        this.setState({
            data        : newDataSort,
            listSort    :  newListAux,
            pageNumber  : 1 
        });

        if(this.props.changePage !== undefined){
            this.props.changePage(1);
        }
    };
    
    //Order una Lista por alguna propiedad [Usado en las Listas de Currencies]
    dynamicSort(property,index) 
    {
        var sortOrder = -1*index;
        if(property[0] === "-") 
        {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a,b) 
        {
            /* next line works with strings and numbers, 
                * and you may want to customize it to your needs
            */
            //////debugger
            var x = a[property] == null ? "":a[property];
            var y = b[property] == null ? "":b[property];

            if(typeof(x) == 'string' && typeof(y) == 'string')
            {
                x = x.toLowerCase();
                y = y.toLowerCase();
            }

            var result = (x < y) ? -1 : (x > y) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onFilterButtonClick = () =>{
        this.setState({filterScreen: true});
    }

    clearFilterButtonClick = () => {
        if(this.props.changePage !== undefined){
            this.props.changePage(1);
        }

        this.setState({filtering: false, filters: {}, data: this.props.data, numberOfPages: this.props.numberOfPages});
    }

    onCloseFilterScreen = () => {
        this.setState({filterScreen: false});
    }

    runFilter = (filters) => {
        var newData = [];
        this.setState({filterScreen: false, loading: true, filtering: true, filters: filters});
        this.props.filterFunction().then((rows) => {
            rows.forEach(element => {
                var isOkay = true;
                for(var i = 0; i < filters.length; i++){
                    if(filters[i].value !== '' && element[filters[i].header] !== undefined){
                        if(String(element[filters[i].header]).toLowerCase().includes(String(filters[i].value).toLowerCase()) && isOkay){
                            isOkay = true;
                        } else {
                            isOkay = false;
                        }
                    }
                }
                if(isOkay){
                    newData.push(element);
                }
            });
            this.setState({data: newData, loading: false, numberOfPages: Math.ceil(newData.length/this.props.numberPerPage), });
        });
    }

    handleAllCheckBoxFunction = (e) => {
        if (e.target.checked) {
            this.setState({ isAllChecked: e.target.checked }, () => {
                this.state.data.forEach((element) => {
                    this.props.checkboxFunction(element, true, false);
                });
            });
        } else {
            this.setState({ isAllChecked: e.target.checked }, () => {
                this.props.checkboxFunction({ Key: 0 }, false, true);
            });
        }
    }
    
    render() 
    {
        var startWithActions = false;
        var headers = [];
        var filterHeaders = [];
        var hiddenHeaders = [];
        var filterButton = [];
        var paginator = [];
        var showFilter = false;
        var showExport = false;
        var useCheckBox = false;
        var useRadioButton = false;
        var useMultiCheckBox = false;
        var id = "";
        var selectedRows = [];
        var activeSelectAll = false;

        if(this.props.id !== undefined){
            id = "-" + this.props.id;
        }

        if(this.props.useFilter !== undefined){
            showFilter = this.props.useFilter;
        }

        if(this.props.useExportButton !== undefined){
            showExport = this.props.useExportButton;
        }

        if(this.props.hiddenHeaders !== undefined){
            hiddenHeaders = this.props.hiddenHeaders;
        }
        if(this.props.startWithActions !== undefined){
            startWithActions = this.props.startWithActions;
        }

        if(this.props.useCheckBox  && this.props.checkboxFunction !== undefined){
            useCheckBox = this.props.useCheckBox;
        }

        if(this.props.useRadioButton  && this.props.radioButtonFunction !== undefined){
            useRadioButton = this.props.useRadioButton;
        }

        if(this.props.useMultiCheckBox  && this.props.dynamicCheckboxFunction !== undefined && this.props.dynamicCheckboxHeaders !== undefined){
            useMultiCheckBox = this.props.useMultiCheckBox;
        }

        if((this.props.useRadioButton || this.props.useCheckBox) &&  this.props.selectedRows !== undefined){
            selectedRows = this.props.selectedRows;
        }

        if(this.props.activeSelectAll !== undefined)
        {
            activeSelectAll = this.props.activeSelectAll;
        }

        if(this.props.usePaginator){
            paginator.push(
                <Paginator 
                    key={"paginator"+ id} 
                    changePage={this.props.changePage} 
                    numberOfPages={this.state.numberOfPages} 
                    newSearch={this.props.newSearch}
                    pageNumber={this.state.pageNumber}
                />
            );
        }

        /*
        console.group('Hidden Headers');
        console.log(hiddenHeaders);
        console.groupEnd();
        console.group("NewDynamicTable Data")
        console.table(this.state.data);
        */

        if (this.state.data !== null && this.state.data !== undefined) 
        {
            if(this.state.data.length > 0)
            {
                var testObj = Object.keys(this.state.data[0]);
                /*
                console.group("NewDynamicTable Headers")
                console.log(testObj);
                */

                if(useCheckBox && activeSelectAll)
                {
                    headers.push(
                    <th
                        style={{...style.alignCenter, verticalAlign: 'middle'}}
                        key="actions-column"
                    >
                        <input 
                            className="uk-checkbox" 
                            checked={this.state.isAllChecked} 
                            onChange={this.handleAllCheckBoxFunction}
                            type="checkbox"
                            style={{marginRight:0}}
                        />
                    </th>);                 
                } else if(useCheckBox && !activeSelectAll) {
                    headers.push(
                        <th style={style.alignCenter} key="actions-column"></th>);    
                }

                if(useRadioButton){
                    headers.push(<th style={style.alignCenter} key="actions-column"></th>);                 
                }

                if(useMultiCheckBox){
                    for(var c = 0; c < this.props.dynamicCheckboxHeaders.length; c++)
                    {
                        headers.push(<th style={style.alignCenter} key="actions-column">{this.props.dynamicCheckboxHeaders[c]}</th>);        
                    }         
                }

                if(startWithActions){
                    if(this.props.dynamicActions !== undefined || this.props.useDeleteButton ){
                        headers.push(<th style={style.alignCenter} key="actions-column">Actions</th>);
                    }
                }
                
                for (let i=0; i<testObj.length; i++) 
                {
                    var includesHiddenDollar = testObj[i].includes('$');
                    if (testObj[i] !== 'TotalCount')
                    {
                        if (!includesHiddenDollar)
                        {
                            var isHidden = false;
                            for(var k=0; k<hiddenHeaders.length; k++)
                            {
                                if(testObj[i] === hiddenHeaders[k])
                                {
                                    if(this.props.enableSortByHeader)
                                    {
                                        //headers.push(<th key={testObj[i]} className="hidden-th" style={{display: 'none', cursor: 'pointer', textAlign: 'center', borderLeft: '0px'}} onClick={this.sorterByHeader(testObj[i], i)}>{testObj[i]}</th>);
                                    }else{
                                        //headers.push(<th key={testObj[i]} className="hidden-th" style={{display: 'none',cursor: 'pointer', textAlign: 'center', borderLeft: '0px'}}>{testObj[i]}</th>);
                                    }
                                    isHidden = true;
                                    break;
                                }
                            }

                            var iconClass = this.state.listSort[i] === 1 ? "fa-long-arrow-up" : "fa-long-arrow-down";

                            if(!isHidden)
                            {
                                if(this.props.enableSortByHeader)
                                {
                                    headers.push(
                                        <th 
                                            style={{verticalAlign: 'middle',}} 
                                            key={testObj[i]} 
                                        >
                                            <div 
                                                style={{
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    flex: 1,
                                                    flexDirection: 'row',
                                                    display: 'flex'
                                                }}
                                            >
                                                <text
                                                    style={{
                                                        flex: 9,
                                                        fontSize: 16,
                                                        verticalAlign: 'middle',
                                                    }} 
                                                >
                                                    {testObj[i]}</text>
                                                <i 
                                                    className={"fa fa-fw " + iconClass}
                                                    key={"filter-icon" + id} 
                                                    style={{ 
                                                        fontSize: 18,
                                                        flex: 1,
                                                        cursor: 'pointer', 
                                                    }}
                                                    onClick={this.sorterByHeader(testObj[i], i)}
                                                />
                                            </div>
                                        </th>
                                    );
                                }else
                                {
                                    headers.push(<th style={{cursor: 'pointer', textAlign: 'center'}} key={testObj[i]}>{testObj[i]}</th>);
                                }
                                filterHeaders.push(testObj[i]);
                            }
                        }
                    }
                }
                if(!startWithActions){
                    if(this.props.dynamicActions !== undefined || this.props.useDeleteButton){
                        headers.push(<th style={style.alignCenter} key={"actions-column" + id}>Actions</th>);
                    }
                }
                //VALIDATION FOR DELETE BUTTON
                /*if(this.props.useDeleteButton){
                    headers.push(<th style={style.alignCenter} key={"delete-column" + id}>Actions</th>);
                }*/

                console.groupEnd();
            }
        }
        console.groupEnd();

        var rows = [];
        var listToShow = []
        
        if(this.props.pageNumber && this.props.numberPerPage)
        {
            for(var a= ((this.props.pageNumber-1)*this.props.numberPerPage); a<this.props.pageNumber*this.props.numberPerPage;a++)
            {
                if(this.state.data[a] !== undefined)
                {
                    listToShow.push(this.state.data[a])
                }
            }
        }else
        {
            listToShow = this.state.data
        }

        if (listToShow !== null && listToShow !== undefined)
        {
            var enableClick = this.props.enableClick;

            for (let i=0; i<listToShow.length; i++) 
            {
                if (enableClick) 
                {
                    if(this.props.useDeleteButton)
                    {
                        rows.push(<Row key={i*10} id={i*10} tableID={this.props.id} formats={this.props.formats} useRadioButton={this.props.useRadioButton} radioButtonFunction={this.handleRadioButtonFunction.bind(this)} useCheckBox={this.props.useCheckBox} checkboxFunction={this.handleCheckBoxFunction.bind(this)} startWithActions={this.props.startWithActions} dynamicActions={this.props.dynamicActions} hiddenHeaders={this.props.hiddenHeaders} objData={listToShow[i]} onClick={this.handleRowClick.bind(this)} enableClick={true} useDeleteButton={this.props.useDeleteButton} deleteClick={this.handleDeleteClick.bind(this)} hasChildren={this.props.hasChildren} useMultiCheckBox={useMultiCheckBox} dynamicCheckboxFunction={this.props.dynamicCheckboxFunction} dynamicCheckboxHeaders={this.props.dynamicCheckboxHeaders} selectedRows={selectedRows}></Row>);
                    }else{
                        rows.push(
                            <Row 
                                key                     = {i*10} 
                                id                      = {i*10} 
                                tableID                 = {this.props.id} 
                                formats                 = {this.props.formats} 
                                useRadioButton          = {this.props.useRadioButton} 
                                radioButtonFunction     = {this.handleRadioButtonFunction.bind(this)} 
                                useCheckBox             = {this.props.useCheckBox} 
                                checkboxFunction        = {this.handleCheckBoxFunction.bind(this)} 
                                startWithActions        = {this.props.startWithActions} 
                                dynamicActions          = {this.props.dynamicActions} 
                                hiddenHeaders           = {this.props.hiddenHeaders} 
                                objData                 = {listToShow[i]} 
                                onClick                 = {this.handleRowClick.bind(this)} 
                                enableClick             = {true} 
                                useDeleteButton         = {this.props.useDeleteButton} 
                                hasChildren             = {this.props.hasChildren}  
                                useMultiCheckBox        = {useMultiCheckBox} 
                                dynamicCheckboxFunction = {this.props.dynamicCheckboxFunction} 
                                dynamicCheckboxHeaders  = {this.props.dynamicCheckboxHeaders} 
                                selectedRows            = {selectedRows}>
                            </Row>
                        );
                    }
                }else{
                    if(this.props.useDeleteButton)
                    {
                        rows.push(<Row key={i*10} id={i*10} tableID={this.props.id} formats={this.props.formats} useRadioButton={this.props.useRadioButton} radioButtonFunction={this.handleRadioButtonFunction.bind(this)} useCheckBox={this.props.useCheckBox} checkboxFunction={this.handleCheckBoxFunction.bind(this)} startWithActions={this.props.startWithActions} dynamicActions={this.props.dynamicActions} hiddenHeaders={this.props.hiddenHeaders} objData={listToShow[i]} enableClick={false} useDeleteButton={this.props.useDeleteButton} deleteClick={this.handleDeleteClick.bind(this)} hasChildren={this.props.hasChildren} useMultiCheckBox={useMultiCheckBox} dynamicCheckboxFunction={this.props.dynamicCheckboxFunction} dynamicCheckboxHeaders={this.props.dynamicCheckboxHeaders} selectedRows={selectedRows}></Row>)
                    }else
                    {
                        rows.push(<Row key={i*10} id={i*10} tableID={this.props.id} formats={this.props.formats} useRadioButton={this.props.useRadioButton} radioButtonFunction={this.handleRadioButtonFunction.bind(this)} useCheckBox={this.props.useCheckBox} checkboxFunction={this.handleCheckBoxFunction.bind(this)} startWithActions={this.props.startWithActions} dynamicActions={this.props.dynamicActions} hiddenHeaders={this.props.hiddenHeaders} objData={listToShow[i]} enableClick={false} useDeleteButton={this.props.useDeleteButton} hasChildren={this.props.hasChildren}  useMultiCheckBox={useMultiCheckBox} dynamicCheckboxFunction={this.props.dynamicCheckboxFunction} dynamicCheckboxHeaders={this.props.dynamicCheckboxHeaders} selectedRows={selectedRows}></Row>)
                    }
                }

                //Child records
                if(listToShow[i] !== null)
                {
                    if( listToShow[i]['ChildrenRecords$'] !== null &&
                        listToShow[i]['ChildrenRecords$'] !== undefined){
                        rows.push(
                            <tr key={this.props.id + '-child-records-' + (i*10)}
                                id={this.props.id + '-child-records-' + (i*10)} hidden>
                                <td></td>
                                <td colSpan="20">
                                    <NewDynamicTable data={listToShow[i]['ChildrenRecords$']}/>
                                </td>
                            </tr>);
                    }
                }
            }
        }

        var noRecords;
        if (rows.length === 0 && this.props.newSearch)
        {
            showExport = false;
            showFilter = false;
            noRecords = <h5 style={style.alignCenter}>No records found.</h5>
        }

        if(showFilter)
        {
            if(!this.state.filtering)
            {
                filterButton.push(<i className="fa fa-fw fa-filter uk-align-right" key={"filter-icon" + id} onClick={this.onFilterButtonClick} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer', marginBottom: '2px', marginLeft: '10px'  }} />);
            }else
            {
                filterButton.push(<i className="fa fa-fw fa-ban uk-align-right" key={"filter-icon" + id} onClick={this.clearFilterButtonClick} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer', marginBottom: '2px', marginLeft: '10px'  }} />);
            }
        }

        if(showExport){
            filterButton.push(<TheCSVButton board={this.props.tableName} data={this.state.data} useIcon={true}/>);
        }

        return(
            <div className="table-container">
                <div className="uk-overflow-auto">
                    {filterButton}
                </div>
                <div className="uk-overflow-auto border-table">
                    <table className="uk-table uk-table-striped uk-table-hover uk-table-divider">
                        <thead>
                            <tr>{headers}</tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
                {noRecords}
                {paginator}
                <TableFilter 
                    open={this.state.filterScreen}
                    onClose={this.onCloseFilterScreen}
                    headers={filterHeaders}
                    runFilter={this.runFilter}
                />
                {/*<LoadingBox loading={this.state.loading}/>*/}
            </div>
        );
    }
}