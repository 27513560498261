import React, { useContext, useEffect, useRef, useState } from 'react';
import NewEditableGrid from '../../shared/NewEditableGrid';
import { GetGlobalSystemParameters, saveRFQ } from '../../../networking/NetworkingGlobalSystemParameters';
import { getStyle } from '../../../styles/styles';
import AppContext from '../../../AppContext';
import AlertBox from '../../shared/AlertBox';

const style = getStyle();

const parameterTypes = [
    {name: 'Date', value: 'D'},
    {name: 'Text', value: 'T'},
    {name: 'Numeric', value: 'N'},
    {name: 'Boolean', value: 'B'},
    {name: 'Integer', value: 'I'},
    {name: 'Guid', value: 'G'},
    {name: 'Character', value: 'C'},
  ]

const NewDynamicGlobalParametersScreen = () => {
    const context = useRef(useContext(AppContext));
    const [data, setData] = useState([]);
    const [columnDetail, setColumnDetail] = useState(null);

    const [reloadEmails, setReloadEmails] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
      async function loadGrid() {
        const json = await GetGlobalSystemParameters(0, 0);
        if (json != null && json.globalParameters != null) {
            setData(json.globalParameters);
            if (json.columnDetail != null) {
                const _columnDetail = JSON.parse(json.columnDetail);
                setColumnDetail(_columnDetail);
            }
        }
      }
      loadGrid();
    }, [reloadEmails]);

    const save = async (record, row) => {
        try {
            //console.log("save - validateFields -> ", row);
      
            /*{
              Data Type: "T"
              Description: "Used by Trade screen drop down and Disbursement screen. If the user selects a bene from the dropdown from the trade screen it will put that value as the instrument in the outgoing or if the user selects a bene and press the disburse button , it will show that instrument in the Instrumnet Dropdown in the Disbursement screen."
              Display Order: 1
              LookUpTableID: "3"
              Mandatory: false
              Parameter Value: "Electronic Funds Transfer"
              Tab: 1
            }*/
      
            let model = {
              ParameterName         : (record['Parameter Name'] === '' || record['Parameter Name'] === null) ? row['Parameter Name'] : record['Parameter Name'],
              ParameterValue        : row['Parameter Value'],
              ParameterValueDataType: row['Data Type'],
              ParameterDescription  : row['Description'],
              Tab                   : row['Tab'],
              DisplayOrder          : row['Display Order'],
              Mandatory             : row['Mandatory'],
              LookUpTableID         : row['LookUpTableID'],
            }
            //console.log("save - model -> ", model);
      
            context.current.startLoading();
            const saveRFQResponse = await saveRFQ(model);
            //console.log('saveRFQ ->', saveRFQResponse);
            /*
              Result: true
              httpErrorMessage: undefined
              httpStatusCode: 200
            */
            context.current.finishLoading();
      
            let title = 'Error';
            let message = 'Something went wrong saving Global Parameter. Please try again later.';
      
            if(saveRFQResponse !== undefined)
            {
              if(saveRFQResponse.httpStatusCode === 200 && saveRFQResponse.Result)
              {
                title = 'Success';
                message = 'Global Parameter successfully saved.';
              }else
              {
                if(saveRFQResponse.Message !== undefined && saveRFQResponse.Message !== null && saveRFQResponse.Message !== '')
                {
                  message = saveRFQResponse.Message;
                }
              }
      
              setAlertTitle(title);
              setAlertMessage(message);
              setShowAlert(true);
      
              if(title === 'Success') {
                setReloadEmails(!reloadEmails);
              }
            } else {
              setAlertTitle('Error');
              setAlertMessage('Something went wrong. Please try again later.');
              setShowAlert(true);
            }
          } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
          }
    }

    const closeAlert = () => {
        setShowAlert(false);
        setAlertTitle('');
        setAlertMessage('');
    };

    return (
        <div>
            <h3 className="uk-heading-divider" style={style.titleStyle} >Global System Parameters</h3>
            <NewEditableGrid
                data={data}
                columnDetails={columnDetail}
                dynamicOptions={{
                  'Data Type': parameterTypes
                }}
                isEditable={true}
                editSaveFunction={save}
                canAdd={true}
                useIcons={true}
                addSaveFunction={save}
            />
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
}

export default NewDynamicGlobalParametersScreen;
