import React from 'react';

import LoadingBox from '../shared/LoadingBox';
import NumberInput from '../shared/NumberInput';
import NewDynamicTable from '../shared/NewDynamicTable';
import Paginator from '../shared/Paginator';
import AlertBox from '../shared/AlertBox'

import RefundForm from '../back_office/RefundForm'

import { getOtherSettlementFunds } from '../../networking/NetworkingCustomers';
import { getDealsForPayments } from '../../networking/NetworkingDeals';
import { createIncomingFund, voidIncomingFund } from '../../networking/NetworkingFunds'

import {formatToCurrency,formatToCurrencyWithScale,getScaleByCurrency} from '../../helpers/FormatHelper';
import {getDefaultPagingNumberOfItems} from '../../helpers/PackageJsonHelper';
import {getStyle} from '../../styles/styles';
import UIkit from 'uikit';
import {Breadcrumb} from 'antd';

var style = getStyle();

export default class OtherSettlementFunds extends React.Component {

    state = {
        alertMessage:'',
        alertTitle: '',
        amountReceived : 0,
        availableBalance: 0,
        currencyID : '',
        currentPage : 1,
        customerID: 0, 
        dealHeaderID: 0,
        depositUpdateToken: 0,
        heldFundsUpdateToken: 0,
        incomingFundsElementToVoid: {},
        isOpen: false,
        listToApplyView : [],
        listToApply : [],
        listFunds : [],
        loading : false,
        newSearch: false,
        readOnly : false,
        settlementID : 0,
        showAlert: false,
        showVoidIncomingAlert: false,
        sourceID : 0,
        sourceTable: '',
        totalPages: 0,
        typeRecordsApply : 'Deal',
        disable_amountToUse_column: false,

        //Selecting a held funds balance
        ukOpen: false,
    }

    componentDidMount()
    {
        if(this.props.customerID !== undefined)
        {
            if(this.props.dealHeaderID !== undefined)
            {
                this.getOtherSettlementFunds(this.props.customerID, this.props.dealHeaderID);
                this.setState({dealHeaderID: this.props.dealHeaderID})
            }
            this.setState({customerID: this.props.customerID});
        }
        if (this.props.readOnly !== undefined) {
            this.setState({ readOnly: this.props.readOnly })
        }
        //Selecting a held funds balance
        UIkit.util.on('#accordion-compliance', 'show', () => {
            this.setState({ ukOpen: true });
        });
        UIkit.util.on('#accordion-compliance', 'hide', () => {
            this.setState({ ukOpen: false });
        });
    }

    changePage = (value) =>
    {
        this.setState({currentPage: value},() =>
            this.getOtherSettlementFunds(this.state.customerID, this.state.dealHeaderID)
        );
    }

    handleRowClick = (obj)=>
    {
        //alert(JSON.stringify(obj));
        /*
            {
                "Record No"         : "Held Funds",
                "Currency"          : "USD",
                "Amount"            : 6894,
                "Amount Received"   : 6894,
                "Pending Amount"    : 6894,
                "RecordID"          : -1,
                "UpdateToken$"      : "637472750346366667"
            }
        */
        let disable_amountToUse_column=false;
        let pendingAmount= Number(obj['Pending Amount'].replace(',',''));
        if(pendingAmount<=0){
            disable_amountToUse_column=true;
        }

        let amountReceived = obj['Amount Received'];
        let currencyID = obj['Currency'];
        let sourceID = obj['RecordID'];
        var sourceTable = '';
        var depositUpdateToken = 0, heldFundsUpdateToken = 0;
        if(obj['Record No'] !== 'Held Funds'){
            sourceTable = 'D';
            depositUpdateToken = obj['UpdateToken$'];
        }
        else{
            sourceTable = 'H';
            heldFundsUpdateToken = obj['UpdateToken$'];
        }
        this.setState({
            amountReceived       : amountReceived,
            currencyID           : currencyID,
            depositUpdateToken   : depositUpdateToken,
            heldFundsUpdateToken : heldFundsUpdateToken,
            isOpen               : true,
            sourceID             : sourceID,
            sourceTable          : sourceTable,
            disable_amountToUse_column: disable_amountToUse_column,
        });
        //Selecting a held funds balance
        if (!this.state.ukOpen) {
            this.setState({ ukOpen: true }, () => {
                UIkit.accordion('#accordion-compliance').toggle()
            });
        }
        this.getDealsForPayments(this.state.customerID, currencyID, sourceTable, sourceID);
    }

    getOtherSettlementFunds(customerID, dealHeaderID)
    {
        if(customerID !== undefined && customerID > 0 && dealHeaderID !== undefined && dealHeaderID > 0)
        {
            getOtherSettlementFunds(customerID, dealHeaderID, this.state.currentPage,getDefaultPagingNumberOfItems()).then(
                (value) => {
                    //alert(JSON.stringify(value));
                    if(value !== undefined && value.totalCount > 0)
                    {
                        var funds = value.result;
                        //filter to show just held funds if it comes from the heldfund board
                        if (this.props.openFromHeldfundBoard) 
                        {
                            funds = funds.filter(x => x['Record No'] == 'Held Funds');
                        }

                        if (this.props.buyCCY !== undefined && this.props.buyCCY) 
                        {
                            funds = funds.filter(x => x['Currency'] === this.props.buyCCY);
                        }

                        var totalCount = funds.length;
                        
                        var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());

                        ////debugger
                        /*
                            Amount: 6894
                            Amount Received: 6894
                            Currency: "USD"
                            Pending Amount: 6894
                            Record No: "Held Funds"
                            RecordID: -1
                            UpdateToken$: "637472750346366667"
                        */

                        this.setState({
                            listFunds   : funds,
                            totalPages  : pages, 
                            newSearch   : true
                        }, () =>{
                            this.state.listFunds.forEach(element => {
                                if(element.RecordID === this.state.sourceID)
                                {
                                    this.handleRowClick(element);
                                }
                            });
                        });
                    }
                    else{
                        this.setState({
                            listFunds   : [], 
                            newSearch   : true
                        });
                    }
                }
            );
        }
        else{
            this.setState({
                listFunds   : [], 
                newSearch   : true
            });
        }
    }

    getDealsForPayments(customerID, currencyID, sourceTable, sourceID){
        if(Number(sourceID) > 0 || sourceTable === 'H')
        {
            getDealsForPayments(customerID, currencyID, sourceTable, sourceID, this.state.typeRecordsApply, 1, 100).then(
                (value) => {
                    //alert(JSON.stringify(value));
                    if(value !== undefined && value.totalCount > 0)
                    {
                        console.log(value.result);
                        var deals = value.result;
                        this.setState({listToApplyView: deals, listToApply: []});
                    }
                    else{
                        this.setState({listToApplyView: [], listToApply: []});
                    }
                    this.calculateBalance();
                }
            );
        }
    }

    handleAmountToUse(data, event) { 
        let tempToApply = this.state.listToApply;
        let amountToUse = event.target.value;
        var paymentChanged = this.state.listToApplyView.filter(item => item['RecordID'] === data.recordID)[0]
        var valid = true;
        if (amountToUse === 0) {
            paymentChanged['AmountToUse'] = 0;
        }
        if(amountToUse < 0)
        {            
            this.setState({showAlert:true, alertMessage: 'Amount to Use should be a positive decimal', alertTitle: 'Error'});
            paymentChanged['AmountToUse'] = 0;
            valid = false;
        }

        if(amountToUse > data.pendingAmount)
        {
            this.setState({showAlert:true, alertMessage: 'Amount to Use should be less than Pending Amount', alertTitle: 'Error'});
            paymentChanged['AmountToUse'] = 0;
            valid = false;
        }
       //traveler.map(item => item.Amount).reduce((prev, next) => prev + next);    
          if (tempToApply.length > 0) {
                var amountTotalToApply = tempToApply.map(item => item.AmountToUse).reduce((prev, next) => prev + next);
               
                if (amountTotalToApply > this.state.amountReceived) {
                    this.setState({showAlert:true, alertMessage: 'Amount to Use should be less than Balance', alertTitle: 'Error'});
                    paymentChanged['AmountToUse'] = 0;
                    valid = false;
                }
            }
        //remove if exist
        if (tempToApply.length > 0) {
            tempToApply = tempToApply.filter(item => item.RecordID !== data.recordID)
        }

        if(valid && amountToUse > 0)
        {
            //add if amount > 0
                if (amountToUse > 0) {
                    var dealUpdateToken = '';
                    if(data.typeRecord === 'Deal'){
                        dealUpdateToken = data.dealUpdateToken;
                    }
                    tempToApply.push({
                        EntityType: data.typeRecord,
                        RecordID: data.recordID,
                        AmountToUse: amountToUse,
                        DealUpdateToken: dealUpdateToken,
                    })
                }

                paymentChanged['AmountToUse'] = amountToUse;          
            
        }
        this.calculateBalance();
        this.setState({
            listToApply: tempToApply
        });
    }

    //alerts
    handleApply = () => {
        this.setState({showApplyPaymentsAlert: true});
    }

    yesApplyPaymentsAlert()
    {
        this.setState({
            showApplyPaymentsAlert: false, 
            loading: true
        }, () =>{
            let userID = localStorage.getItem('UserID');
            var sourceUpdateToken = this.state.heldFundsUpdateToken;
            var source = 'Held Fund';
            if(this.state.sourceTable === 'D')
            {
                source = 'Deposit'
                sourceUpdateToken = this.state.depositUpdateToken;
            }
            var model = {
                SourceID            : this.state.sourceID,
                SourceTable         : this.state.sourceTable,
                UserID              : userID,
                SourceUpdateToken   : sourceUpdateToken,
                Records             : this.state.listToApply 
            }

            createIncomingFund(model).then((json) => {            
                if (json.httpStatusCode !== 200) 
                {
                    var alertMessage = 'An error occurred while trying to apply '+ source +'. Please try again.'; 
                    if(json.httpErrorMessage !== undefined){
                        alertMessage = json.httpErrorMessage;
                    }
                    this.setState({
                        alertMessage : alertMessage,
                        alertTitle   : 'Error',
                        loading      : false,
                        showAlert    : true,
                    });
                } else {
                    //this.getDealsForPayments(this.state.customerID, this.state.currencyID, this.state.sourceTable, this.state.sourceID);
                    if(this.props.reloadDealHeaderInfo !== undefined)
                    {
                        this.props.reloadDealHeaderInfo();
                    }

                    let disable_amountToUse_column=false;
                    if(this.state.availableBalance <= 0){
                        disable_amountToUse_column=true;
                    }
                    
                    this.setState({
                        listPaymentsToApply : [],
                        listToApplyView     : [],
                        showAlert           : true,
                        alertTitle          : 'Success',
                        alertMessage        : source + ' applied successfully.',
                        loading             : false,
                        disable_amountToUse_column: disable_amountToUse_column,
                    }, () =>{
                        this.getOtherSettlementFunds(this.state.customerID, this.state.dealHeaderID);
                        this.getDealsForPayments(this.state.customerID, this.state.currencyID, this.state.sourceTable, this.state.sourceID);
                    });
                }
            });
        });
    }

    noApplyPaymentsAlert(){
        this.setState({showApplyPaymentsAlert: false});
    }

    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    calculateBalance()
    {
        var availableBalance = 0;
        if(this.state.sourceTable === 'H'){//Held funds balance
            availableBalance = Number(this.state.amountReceived.replace(",", ""));
        }
        else{//Deposits
            var userAmount = 0;
            var listToApplyView = this.state.listToApplyView;

            for(var d = 0; d< listToApplyView.length; d++)
            {
                if (listToApplyView[d].AmountToUse !== undefined )
                {
                    userAmount += listToApplyView[d].AmountToUse;
                }
            }
            availableBalance = Number(this.state.amountReceived.replace(",", "")) - userAmount;
        }
        this.setState({availableBalance: availableBalance});
    }

    handleUnlinkPayment = (rowElement) => {
        //alert(JSON.stringify(rowElement));
        if (!this.state.readOnly) 
        {
            this.setState({
                showVoidIncomingAlert       : true,
                incomingFundsElementToVoid  : rowElement,
            });
        }
    }

    yesVoidIncomingFund = () => {
        this.setState({
            loading: true
        }, () =>{
            voidIncomingFund(this.state.incomingFundsElementToVoid.IncomingFundsID, localStorage.getItem('UserID'), 0, 0, this.state.incomingFundsElementToVoid.DealUpdateToken, this.state.depositUpdateToken, this.state.heldFundsUpdateToken).then(
                (response) => {
                    if(Number(response.httpStatusCode) === 200)
                    {
                        this.getOtherSettlementFunds(this.state.customerID, this.state.dealHeaderID);
                        //this.getDealsForPayments(this.state.customerID, this.state.currencyID, this.state.sourceTable, this.state.sourceID);
                        if(this.props.reloadDealHeaderInfo !== undefined)
                        {
                            this.props.reloadDealHeaderInfo();
                        }
                        this.setState({
                            showVoidIncomingAlert       : false,
                            incomingFundsElementToVoid  : {},
                            listToApplyView             : [],
                            alertTitle                  : 'Success',
                            alertMessage                : 'Payment application voided!',
                            showAlert                   : true,
                            loading                     : false,
                        }, () =>{
                            this.getDealsForPayments(this.state.customerID, this.state.currencyID, this.state.sourceTable, this.state.sourceID);
                        });                    
                    }
                    else{
                        //var listToApplyView = this.state.listToApplyView;
                        if(Number(response.httpStatusCode) === 409)
                        {
                            //listToApplyView = [];
                            this.getOtherSettlementFunds(this.props.customerID, this.props.dealHeaderID);
                        }
                        var alertMessage = 'Payment application not voided! Please try again or contact administrator.';
                        if(response.httpErrorMessage !== undefined){
                            alertMessage = response.httpErrorMessage;
                        }
                        this.setState({
                            alertMessage            : alertMessage,
                            alertTitle              : 'Error',
                            incomingFundsID         : 0,
                            //listToApplyView         : listToApplyView,
                            loading                 : false,
                            showAlert               : true,
                            showVoidIncomingAlert   : false,
                        });
                    }
                }
            );
        });
    }

    closeVoidIncomingAlert = () => {
        this.setState({showVoidIncomingAlert: false});
    }

    onRefundClick = () => {
        this.setState({ refundMode: true });
    }

    onRefundBackClick = () => {
        this.getOtherSettlementFunds(this.props.customerID, this.props.dealHeaderID);
        this.setState({
            availableBalance: 0,
            listToApply: [],
            listToApplyView: [],
            listPaymentsToApply: [],
            refundMode: false,
            sourceTable: '',
        });
    }

    render()
    {
        //new dynamic table with editable cell
        let formTitle = "Deposit and held funds"
        if (this.props.backLink !== undefined && this.props.openFromHeldfundBoard) {
            formTitle = "Held funds"
        }

        var rows = [];

        let headers = (
            <tr>
                <th>Record Number</th>
                <th>Type</th>
                <th>Value Date</th>
                <th>Buy Amount</th>
                <th>Complete Amount</th>
                <th>Pending Amount</th>
                <th>Amount To Use</th>
                <th>Actions</th>
            </tr>
        );

        var index=0;
        console.log(this.state.listToApplyView)
        if(this.state.listToApplyView !== undefined)
        {
        this.state.listToApplyView.forEach(element => {
            console.log(element)
            index++;

            var voidAction;
            var amountControl;
            if(Number(element.IncomingFundsID) > 0){
                voidAction = (
                    <i 
                        className   = "fa fa-chain-broken" 
                        onClick     = {() => this.handleUnlinkPayment(element)} 
                        style       = {{ 
                            fontSize        : '1.75em', 
                            verticalAlign   : 'middle' 
                        }}
                    />
                );
                amountControl = (formatToCurrencyWithScale(element['AmountToUse'], this.props.buyCCY));
            }
            else{
                amountControl = (
                    <NumberInput id={'amounttouse-' + index} 
                        type="Currency" 
                        value={element['AmountToUse']} 
                        className="uk-input"
                        disabled={this.state.disable_amountToUse_column}
                        scale={getScaleByCurrency(this.state.currencyID)}
                        onChange={this.handleAmountToUse.bind(this, 
                            {
                                recordID: element['RecordID'],
                                typeRecord: element['TypeRecord'],
                                pendingAmount: element['PendingAmount'],
                                incomingFundsID: element['IncomingFundsID'],
                                amount: element['BuyAmount'],
                                dealUpdateToken: element.DealUpdateToken,
                            }
                        )}
                    />
                );
            }

            rows.push(<tr key={index}>
                <td>{element['RecordNo']}</td>
                <td>{element['Description']}</td>
                <td>{element['ValueDate']}</td>
                <td>{formatToCurrencyWithScale(element['BuyAmount'], this.props.buyCCY)}</td>
                <td>{formatToCurrencyWithScale(element['CompleteAmount'], this.props.buyCCY)}</td>
                <td>{formatToCurrencyWithScale(element['PendingAmount'], this.props.buyCCY)}</td>
                <td>
                    {amountControl}
                </td>
                <td align="center" disabled={this.state.readOnly}>
                    {voidAction}
                </td>                   
            </tr>);
        });
        }
        //list of payments to be applied
        var amountTotalToApply = 0;
        var numberOfRecordsToApply = 0;
        this.state.listToApply.forEach(element => {
            amountTotalToApply += element.AmountToUse;   
            numberOfRecordsToApply++;         
        });

        var validToApply = false;
                 
        if(numberOfRecordsToApply > 0)
        {
            validToApply = true;
        }
        

        let heldFundHeader = [];
        if (this.props.backLink !== undefined && this.props.openFromHeldfundBoard) {
            heldFundHeader.push(
                <h3 className="uk-heading-divider" style={style.titleStyle}>
                    <button className="uk-button uk-button-text uk-margin-small-right min-width-0" onClick={this.props.backLink}>
                        <i className="fa fa-arrow-left back-arrow-white"></i>
                    </button>
                    Held Funds for Customer ID : {this.props.customerID} - {this.props.customerName} 
                </h3>)
        }

        //Refund button
        let refundButton = []
        if (this.props.openFromHeldfundBoard && this.state.sourceTable === 'H') {
            refundButton.push(
                <div className="uk-width-1-1 uk-margin-bottom uk-margin-top uk-align-right">
                    <button className="uk-button uk-button-green" type="button" onClick={this.onRefundClick}>Refund</button>
                </div>
            )
        }

        console.log(this.state)
        let refundForm = (
            <RefundForm
                sourceTable={"H"}
                sourceID={0}
                sourceUpdateToken={this.state.heldFundsUpdateToken}
                customerID={this.props.customerID}
                balance={this.state.availableBalance}
                currencyID={this.state.currencyID}
                listNostroAccounts={this.state.listNostroAccounts}
                onBackClick={this.onRefundBackClick} />
        )

        if (this.state.refundMode) {
            return refundForm
        }
        if (this.props.mainTitle != null) {
            heldFundHeader = ("");
        }
        var otherScreen = (
            <div>
                {/*}
                <div className="uk-width-1-1 uk-margin-right">                      
                    <div className="uk-grid uk-margin" >
                        <div className="uk-width-1-5">
                            <h4>Deposit and held funds</h4>
                        </div>
                    </div>                                   
                </div>  
                <div>
                    <NewDynamicTable id="osf-funds" 
                        hiddenHeaders={["RecordID"]} 
                        data={this.state.listFunds} 
                        enableClick={true} 
                        clickFunction={this.handleRowClick}
                        formats={[{header:'Amount', formatFunction: formatToCurrency},
                                    {header:'Amount Received', formatFunction:formatToCurrency},
                                    {header:'Pending Amount', formatFunction:formatToCurrency}]}
                    />
                    <Paginator id="ndt-deposits-paginator" 
                                    changePage={this.changePage} 
                                    numberOfPages={this.state.totalPages} 
                                    newSearch={this.state.newSearch}/> 
                </div>
                <hr></hr>
                <div className="uk-width-1-1 uk-margin-right">                      
                    <div className="uk-grid uk-margin" >
                        <div className="uk-width-1-5">
                            <h4>Deals</h4>
                        </div>
                    </div>  
                    <div className="uk-width-1-1">
                    <label className="uk-form-label uk-align-right">Balance: {formatToCurrency(this.state.availableBalance)} </label>
                    </div>
                    <div className="uk-width-1-1">
                        <table className="uk-table uk-table-striped uk-table-hover uk-table-divider" hidden={rows.length === 0}>
                            <thead>
                                {headers}
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
                <h5 style={style.alignCenter} hidden={rows.length > 0}>No records found.</h5>

                <div className="uk-width-1-1"> 
                    <button className={!validToApply ? "uk-button uk-button-default" : "uk-button uk-button-green"} type = "button"
                    onClick = {this.handleApply} disabled={!validToApply}>Apply</button>
                </div>*/}



                    {heldFundHeader}
                <ul id="accordion-compliance" uk-accordion="multiple: true" >                    
                    {/*Deposit and held funds*/}
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">{formTitle}</h5>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <div id="deposit-held-funds">
                                <div className="uk-width-1-1" id="deposit-held-funds">  
                                    <div>
                                        <NewDynamicTable id="osf-funds" 
                                            hiddenHeaders={["RecordID"]} 
                                            data={this.state.listFunds} 
                                            enableClick={true} 
                                            clickFunction={this.handleRowClick}
                                            formats={[{header:'Amount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Currency'},
                                                        {header:'Amount Received', formatFunction:formatToCurrencyWithScale, formatCurrency:'Currency'},
                                                        {header:'Pending Amount', formatFunction:formatToCurrencyWithScale, formatCurrency:'Currency'}]}
                                        />
                                        <Paginator id="ndt-deposits-paginator" 
                                                        changePage={this.changePage} 
                                                        numberOfPages={this.state.totalPages} 
                                                        newSearch={this.state.newSearch}/> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    {/*Deals*/}
                    <li className="">
                        <h5 className="uk-accordion-title">Deals <label className="uk-form-label uk-align-right" style={{marginTop:2, marginLeft: 0, marginBottom: 0, marginRight: 20}}>Balance: {formatToCurrencyWithScale(this.state.availableBalance,this.props.buyCCY)} </label></h5>
                        <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                            <div id="deals">
                                <div className="uk-width-1-1" id="deals">
                                    <div className="uk-width-1-1 uk-margin-right">
                                        {/*<div className="uk-width-1-1">
                                            <label className="uk-form-label uk-align-right">Balance: {formatToCurrency(this.state.availableBalance)} </label>
                                        </div>*/}
                                        <div className="uk-width-1-1">
                                            <table className="uk-table uk-table-striped uk-table-hover uk-table-divider" hidden={rows.length === 0}>
                                                <thead>
                                                    {headers}
                                                </thead>
                                                <tbody>
                                                    {rows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <h5 style={style.alignCenter} hidden={rows.length > 0}>No records found.</h5>

                                    <div className="uk-width-1-1"> 
                                        <button className={!validToApply ? "uk-button uk-button-default" : "uk-button uk-button-green"} type = "button"
                                        onClick = {this.handleApply} disabled={!validToApply}>Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                {refundButton}
                <div>
                
                </div>
                <LoadingBox loading={this.state.loading}/>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
            
                <AlertBox id="apply-alert"
                    open={this.state.showApplyPaymentsAlert} 
                    title="Please confirm" 
                    message = {"You will apply: " + formatToCurrencyWithScale(amountTotalToApply,this.state.currencyID) + ' ' + this.state.currencyID}
                    type = "Yes/No"
                    yesClick = {this.yesApplyPaymentsAlert.bind(this)}
                    noClick = {this.noApplyPaymentsAlert.bind(this)}
                    />

                <AlertBox id="alert-void-incoming"
                    type="Yes/No"
                    open={this.state.showVoidIncomingAlert}
                    title="Confirm"
                    message="Are you sure you want to unapply this payment?"
                    yesClick={this.yesVoidIncomingFund} 
                    noClick={this.closeVoidIncomingAlert} 
                    onClose={this.closeVoidIncomingAlert}
                />
            </div>
        )
        return (
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={this.props.backLink}>{this.props.mainTitle}</Breadcrumb.Item>
                        <Breadcrumb.Item className="breadcrumb-item-bold">Held Funds for Customer ID : {this.props.customerID} - {this.props.customerName} </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {/*<h3 className="uk-heading-divider" style={style.titleStyle}>Customers</h3>*/}
                {otherScreen}
            </div>
        );
    }

}