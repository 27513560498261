import { PlusOutlined, SearchOutlined,EditOutlined, DeleteOutlined} from '@ant-design/icons';
import { Breadcrumb, Button, Checkbox, Form, Input, InputNumber, Popconfirm, Select, Space, Table, Typography, Tooltip } from 'antd';
import 'antd/dist/antd.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import AppContext from '../../../AppContext';
import '../../../css/ant.css';
import { getCurrencies } from '../../../networking/NetworkingCurrencies';
import { deleteRate, getAllRates, saveRate } from '../../../networking/NetworkingRates';
import AlertBox from '../../shared/AlertBox';
import { getParametersByName, saveRFQ } from '../../../networking/NetworkingGlobalSystemParameters';
import {getScaleForCurrencyPair, roundAmountByCurrencyPair} from '../../../helpers/FormatHelper';
import NumberInput from '../../shared/NumberInput';
import { getStyle } from '../../../styles/styles';

const style = getStyle();

const { Option } = Select;

export default function NewRatesMaintenanceScreen() {
    const context = useRef(useContext(AppContext));
    const [formRFQ]                                = Form.useForm();
    const [form]                                = Form.useForm();
    const [rates, setRates]           = useState([]);
    const [allCurrencies, setAllCurrencies]           = useState([]);
    const [updatingRFQ, setUpdatingRFQ]               = useState(false);
    const [updating, setUpdating]               = useState(false);          // Para saber cuando volver a cargar el useEffect
    const [editingKey, setEditingKey]           = useState(0);
    const [currentPage, setCurrentPage]         = useState();
    const [isCheckedEnable, setCheckedEnable]   = useState(false);
    const [rfqTimeout, setRfqTimeout]           = useState(0);

    //Filters --------------------------------------------------------------------
    const [searchText, setSearchText]                   = useState('');
    const [searchedColumn, setSearchedColumn]           = useState('');
    const [searchArray, setSearchArray]                 = useState({});
    const searchInput                                   = useRef();
    //----------------------------------------------------------------------------

    const [showDialog, setShowDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        const loadRatesMaintenance = async () => {
            context.current.startLoading();
            const json = await getAllRates(0,0);
            context.current.finishLoading();
            if (json != null && json.result != null) {
                const rates = json.result.map((c, index) => ({ ...c, key: index + 1 }));
                setRates(rates);
            } else {
                setRates([]);
            }
        };
        loadRatesMaintenance();
    }, [updating]);

    useEffect(() => {
        const loadRFQTimeout = async () => {
            context.current.startLoading();
            const json = await getParametersByName('RFQTimeout');
            context.current.finishLoading();
            if (json != null && json.parameters != null) {
                const RFQTimeout = json.parameters[0].Value ;
                setRfqTimeout(RFQTimeout);
            } else {
                setRates([]);
            }
        };
        loadRFQTimeout();
    }, [updatingRFQ]);

    useEffect(() => {
        const loadCurrencies = async () => {
            context.current.startLoading();
            const json = await getCurrencies(0, 0);
            context.current.finishLoading();
            if (json != null && json.currencies != null) {
                const currencies = json.currencies.map((c) => ({...c, key: `Rate-${c['Currency Id']}`}));
                setAllCurrencies(currencies);
            }
        };
        loadCurrencies();
    }, []);
    
    const isEditing = (record) => record.key === editingKey;

    const edit = async (record) => {
        if (rates[0].key === 0) {
            let auxRates = [...rates];
            auxRates.shift();
            setRates(auxRates);
        }
        form.setFieldsValue({
            'Base Currency': record['Base Currency'],
            'Quoted Currency': record['Quoted Currency'],
            'BID': record['BID'],
            'ASK': record['ASK'],
            ...record
        });
        setEditingKey(record.key);
    };


    const deleteRecord = async (record) => {
        context.current.startLoading();
        const json = await deleteRate(record['Base Currency'],record['Quoted Currency']);
        context.current.finishLoading();
        if (json.httpStatusCode !== 200) {
            setShowAlert(true);
            setAlertTitle('Error');
            setAlertMessage(json.Message);
        } else {
            setShowAlert(true);
            setAlertTitle('Success');
            setAlertMessage('Rate Maintenance successfully removed.');
            setUpdating(!updating);
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const settingSearchStateValues = (value, col)=>{
        setSearchText(value);
        setSearchedColumn(col);
        setSearchArray(...searchArray[col] = value);
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
                ref           = {node => {searchInput.current = node;}}
                placeholder   = {`Search ${dataIndex}`}
                value         = {selectedKeys[0]}
                onChange      = {(e) => {
                    setSelectedKeys(e.target.value ? [e.target.value] : [])}
                }
                onPressEnter  = {() => handleSearch(selectedKeys, confirm, dataIndex)}
                style         = {{
                    width       : 188, 
                    marginBottom: 8, 
                    display     : 'block' 
                }}
            />
            <Space>
                <Button
                    type    = "primary"
                    onClick = {() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon    = {<SearchOutlined />}
                    size    = "small"
                    style   = {{ width: 90 }}
                >Search
                </Button>
                <Button 
                    onClick = {() => {
                        handleReset(clearFilters);
                    }}
                    size    = "small" 
                    style   = {{ width: 90 }}
                >Reset
                </Button>
                <Button
                    type    = "link"
                    size    = "small"
                    onClick = {() => {
                        confirm({ closeDropdown: false });
                        settingSearchStateValues(selectedKeys[0], dataIndex);
                    }}
                >Filter
                </Button>
            </Space>
          </div>
        ),
        filterIcon                      : filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter                        : (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange   : visible => {
          if (visible) {
            setTimeout(() => searchInput.current.select(), 100);
          }
        },
        render: (text, obj) => {
            switch (dataIndex) {
                case [searchedColumn]:
                    return (<Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />);
                case 'BID':
                case 'ASK':
                    return roundAmountByCurrencyPair(text, obj['Base Currency'], obj['Quoted Currency']);
                default: return text;
            }
        },
    });

    const columns = [
        {
            title: 'Base ccy',
            dataIndex: 'Base Currency',
            width: '17.5%',
            editable: true,
            align: 'center',
            sorter          : (a, b) => { return a['Base Currency'].localeCompare(b['Base Currency'])},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Base Currency'),
        },
        {
            title: 'Foreign ccy',
            dataIndex: 'Quoted Currency',
            width: '17.5%',
            editable: true,
            align: 'center',
            sorter          : (a, b) => { return a['Quoted Currency'].localeCompare(b['Quoted Currency'])},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('Quoted Currency'),
        },
        {
            title: 'BID',
            dataIndex: 'BID',
            width: '17.5%',
            editable: true,
            align: 'center',
            sorter          : (a, b) => { return a['BID'].localeCompare(b['BID'])},
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('BID'),
        },
        {
            title           : 'ASK',
            dataIndex       : 'ASK',
            width           : '17.5%',
            editable        : true,
            align           : 'center',
            sorter          : (a, b) => a['ASK'] - b['ASK'],
            sortDirections  : ['descend', 'ascend'],
            ...getColumnSearchProps('ASK'),
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '30%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={() => save(record)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Tooltip title="Edit">
                                <Typography.Link disabled={editingKey !== 0 || rates[0].key === 0} onClick={() => edit(record)}>
                                    <EditOutlined />
                                </Typography.Link>
                            </Tooltip>
                            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record)}>
                                <Tooltip title="Delete">
                                    <a disabled={editingKey !== 0 || rates[0].key === 0}><DeleteOutlined /></a>
                                </Tooltip>
                            </Popconfirm>
                        </Space>
                    </div>
                );
            }
        }
    ];
    const addNewRate = () => {
        let actual = [...rates];
        if (actual[0] != null && actual[0].key === 0) {
            return;
        } else {
            actual.unshift({
                'key': 0,
                'Base Currency': '',
                'Quoted Currency': '',
                'BID': '',
                'ASK': '',
                isNew: true
            });
            setRates(actual);
            setCurrentPage(1);
            form.setFieldsValue({
                'Base Currency': '',
                'Quoted Currency': '',
                'BID': '',
                'ASK': '',
                ...actual
            });
            //setCurrentUpdateToken('');
            //setCurrentCategoryID(0);
        }
    };
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        let type = '';

        switch (col.dataIndex) {
            case 'Base Currency':
            case 'Quoted Currency': {
                type = 'select';
                break;
            }
            case 'BID':
            case 'ASK': {
                type = 'number';
                break;
            }
            default: {
                type = 'text';
                break;
            }
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let inputNode = null;
        switch (inputType) {
            case 'number': {
                inputNode = <NumberInput 
                                className='ant-input'
                                style={{ width: '100%', textAlignLast: 'center' }} 
                                type='Currency' 
                                scale={getScaleForCurrencyPair(record['Base Currency'],record['Quoted Currency'])}
                                />;
                break;
            }
            case 'select': {
                inputNode = (
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a Type"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        disabled={record.key > 0}
                    >
                        {allCurrencies.map((currency) => (
                            <Option
                                key={`Rate-options-${currency['Currency Id']}`}
                                value={currency['Currency Id']}>{currency['Currency Id']}
                            </Option>
                        ))}
                    </Select>
                );
                break;
            }
            default: {
                inputNode = <Input style={{ textAlign: 'center' }} />;
                break;
            }
        }

        let styleEdit = { margin: 0 };

        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        const getCustomRules = (columnDataIndex) => {
            switch (columnDataIndex) {
                case 'BID':
                case 'ASK': {
                    return [{
                        min: 0,
                        required: true,
                        type: 'number',
                        mesage: 'Value can not be 0 or empty'
                    }]
                }
                default: return [
                    {
                        required: true,
                        message: `Please Input a ${title}!`
                    }
                ];
            }
        }
        const rules = getCustomRules(dataIndex);

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item name={dataIndex} style={styleEdit}
                        rules={rules}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const cancel = (page) => {
        setEditingKey(0);
        if(typeof page === 'number')
        {
            setCurrentPage(page);
        }
        if (rates[0].key === 0) {
            let auxRates = [...rates];
            auxRates.shift();
            setRates(auxRates);
        }
    };

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
          return <a>Previous</a>;
        }
        if (type === 'next') {
          return <a>Next</a>;
        }
        return originalElement;
    }

    const closeAlert = () => {
        setShowAlert(false);
    };

    const save = async(record)=> {
        try{
            const row = await form.validateFields();
            console.log(record);
            console.log(row);
            const newData = [...rates];
            const index = newData.findIndex((item) => record.key === item.key);
            if (index > -1) {
                const model = {
                    CCY1: row['Base Currency'],
                    CCY2: row['Quoted Currency'],
                    BID: row.BID,
                    ASK: row.ASK
                };
                context.current.startLoading();
                const json = await saveRate(model);
                context.current.finishLoading();
                if (json != null) {
                    if (json.httpStatusCode !== 200) {
                        setShowAlert(true);
                        setAlertTitle('Error');
                        setAlertMessage(json.httpErrorMessage);
                        setUpdating(!updating);
                        setEditingKey(0);
                    } else {
                        setShowAlert(true);
                        setAlertTitle('Success');
                        setAlertMessage('Rate Maintenance successfully saved.');
                        setUpdating(!updating);
                        setEditingKey(0);
                    }
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }
    const onChange = (e) => {
        if (e.target.checked) {
            setCheckedEnable(true);
        } else {
            setCheckedEnable(false);
        }

        form.setFieldsValue({
            Status: e.target.checked
        });
    };
    const onChangeRfqTimeout = (value) => {
        setRfqTimeout(value)
    }
    const SaveRFQClick = async(record)=>{
        try {
            let EnableRFQ = {
                ParameterName: "EnableRFQ",
                ParameterValue: isCheckedEnable,
                ParameterValueDataType: "B",
                ParameterDescription: ''
            }

            let RFQTimeout = {
                ParameterName: "RFQTimeout",
                ParameterValue: rfqTimeout,
                ParameterValueDataType: "I",
                ParameterDescription: ''
            }
            context.current.startLoading();
            const value = await saveRFQ(EnableRFQ);
            const json = await saveRFQ(RFQTimeout);
            context.current.finishLoading();
            if (json != null) {
                if (json.httpStatusCode !== 200) {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.httpErrorMessage);
                    setUpdatingRFQ(!updatingRFQ);
                } else {
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('RFQ values Successfully saved.');
                    setUpdatingRFQ(!updatingRFQ);
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }
    return (
        <div>
            <div className="breadcrumb-div">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className="breadcrumb-item-bold">Rates Maintenance</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Form form={formRFQ} component={false}>
                <div className="">
                    <h5 className="uk-heading-divider" style={{ ...style.subTitleStyle, width: '100%' }}>
                        RFQ
                    </h5>
                </div>
                <div className="uk-grid">
                    <div className="uk-width-1-5 uk-margin"></div>
                    <div className="uk-width-1-5 uk-margin" style={{ marginTop: '30px!important' }}>
                        <Checkbox onChange={onChange} checked={isCheckedEnable}>Enable RFQ</Checkbox>
                    </div>
                    <div className="uk-width-1-5">
                        <label className="uk-form-label" htmlFor="form-rfqTimeout">RFQ Timeout (seconds)</label>
                        <div className="uk-form-controls">
                            <InputNumber id="rfqTimeout" min={0} value={rfqTimeout} onChange={onChangeRfqTimeout} />
                        </div>
                    </div>
                    <div className="uk-width-1-5 uk-margin" >
                        <button className="uk-button uk-button-green" onClick={SaveRFQClick}>Save</button>
                    </div>
                </div>
            </Form>
            <Form form={form} component={false}>
                <div className="">
                    <h5 className="uk-heading-divider" style={{ ...style.subTitleStyle, width: '100%' }}>
                        Rates
                    </h5>
                </div>
                <Table
                    components={{ body: { cell: EditableCell }}}
                    //bordered={true}
                    dataSource={rates}
                    columns={mergedColumns}
                    //rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                        showQuickJumper: true,
                        itemRender: itemRender,
                        current: currentPage,
                        defaultCurrent: 1,
                        size: 'default'
                    }}
                    size="small"
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={addNewRate}
                            block
                            icon={<PlusOutlined />}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            Add a Rate
                        </Button>
                    )}
                />
            </Form>
            <AlertBox id="alert-rates" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
}