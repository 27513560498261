import React from 'react';
import NewEditableGrid from '../../shared/NewEditableGrid';
import { getDrawdownsByForward } from '../../../networking/NetworkingDeals';
export default class WizardDrawdownsView extends React.Component {
    state = {
        drawdowns: [],
        drawdownsDetails: {},
    }

    componentDidMount() {
        getDrawdownsByForward(this.props.forwardID, 0, 0).then(
            (json) => {
                var helper = [];
                let drawdownsDetails = []
                if (json.deals !== undefined) {
                    helper = json.deals;
                    let listDrawdownsDetails = JSON.parse(json.columnDetail)
                    drawdownsDetails = listDrawdownsDetails
                }
                this.setState({ drawdowns: helper, drawdownsDetails: drawdownsDetails });
            }
        )
    }

    render() {
        return (
            <div className="uk-form-stacked">
                <h4>
                    <i onClick={this.props.handleCloseDrawdownsClick} className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>
                    &nbsp;Drawdowns
                </h4>
                <NewEditableGrid
                    key="key_deal_drawdowns"
                    data={this.state.drawdowns}
                    columnDetails={this.state.drawdownsDetails}
                    isEditable={false}
                    tableName="deal_drawdowns"
                    enableClick={true}
                    clickFunction={this.props.handleDealClick}
                />
            </div>
        );
    }
}