import React from 'react';
import AppContext from '../../../AppContext';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getLookUpTable } from '../../../networking/Networking';
import { deleteAttachmentsBySubEntityType, getAttachmentBySubEntityTypeByID, getAttachmentsBySubEntityType, saveAttachmentsBySubEntityType } from '../../../networking/NetworkingAttachments';
import { getCountries } from '../../../networking/NetworkingCountries';
import { getTasksDefBySubEntityType } from '../../../networking/NetworkingTasksDefinition';
import { getUserDefinedProperties } from '../../../networking/NetworkingUserProperties';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import TranslationMaintenanceDialog from '../../shared/Dialogs/TranslationMaintenanceDialog';
import DynamicSelect from '../../shared/DynamicSelect';
import LoadingBox from '../../shared/LoadingBox';
import NewDynamicTable from '../../shared/NewDynamicTable';
import NumberInput from '../../shared/NumberInput';
import {Colors} from '../../../constants/Colors'
import { TranslationOutlined } from '@ant-design/icons';

var style = getStyle();
let globalFunctions = {};
export default class AttachmentsDefinitionMaintenance extends React.Component {
    static contextType = AppContext;
    
    state = {
        attachmentBySubEntityTypeID: 0,
        properties: [],
        allProperties: [],
        description: '',
        order: 0,
        rangeValidate: false,
        mandatory: false,
        subEntityType:'',
        userPropertyID: 0,
        taskID:'',
        groupBy: '',
        userProperties: [],
        taskList: [],
        typeProperty: true,
        typeTask: false,
        "Data Type": [],
        "Sub Entity Types": [],
        lookUpTables: [
            "Data Type",
            "Sub Entity Types"
        ],
        updateToken: 0,
        //
        hideButton: false,
        hasAttachmentsDisabled: '',
        disableSubEntityType: '',

        //Translation
        showTranslationDialog:false,
        objTranslation: null,

        //For tables
        selectIDLeft: '',
        selectIDRight: '',
        listInitialHave: [],
        listHave: [],//originLists
        listWant: [],//originLists
        listMissing: [],

        validations: {
            description: true,
            order: true,
            mandatory: true,
            subEntityType: true
        },

        alertMessage: '',
        showAlert: false,
        showAlertGlobal: false,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        readyToDeleteProperty: {},
        alertTitle: '',
        totalPages: 0,
        currentPage: 1,
        newSearch: false,
        loading: false
    }

    closeDeleteAlert = () => {
        this.setState({ showDeleteAlert: false, readyToDeleteProperty: {} });
    }

    openDeleteAlert = () => {
        this.setState({ showDeleteAlert: true });
    }

    yesDeleteAlert = () => {
        deleteAttachmentsBySubEntityType(this.state.attachmentBySubEntityTypeID).then(
            (value) => {
                ////debugger
                if(value !== undefined)
                {
                    if(value.hasAttachments > 0)
                    {
                        this.setState({showDeleteAlert: false, showAlertGlobal: true, alertTitle: 'Error', alertMessage: 'This Attachment cannot be deleted as it is linked to one or more entities in the system.'});
                        
                    }
                    else
                    {
                        this.setState({ showDeleteAlert: false, showSuccessDeleteAlert: true });                    
                        this.handleUpdateGrid();
                    }
                }
            });
    }

    okSuccessAlert = () => {
        this.setState({ showSuccessDeleteAlert: false });
    }

    componentDidMount() {

        globalFunctions = this.context;
        globalFunctions.startLoading();

        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1) {
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                            table[j].field1 = table[j].Field1;
                        }
                        if(this.state.lookUpTables[i] === "Sub Entity Types")
                        {
                            table = table.filter(item => item.field1 == '1');
                        }
                        this.setState({ [this.state.lookUpTables[i]]: table });
                    }
                }
            })

        getCountries(0,0).then(
            (json) =>
            {
                if(json !== undefined && json.countries !== undefined)
                {
                    var countries = [];
                    for (var c = 0; c < json.countries.length; c++) {
                        var country = {};
                        country.value = json.countries[c].Country;
                        country.name = json.countries[c]["Country Name"];
                        countries.push(country);
                    }

                    this.setState({
                        listInitialHave: countries,
                        listHave: countries,
                        listWant: []
                    });

                }
            }
        );

        this.handleUpdateGrid();
    }

    getAttachmentsBySubEntityType = async () => {
        const json = await getAttachmentsBySubEntityType(this.state.subEntityType);
        return json.attachments;
    };

    handleUpdateGrid = async () => {        
        ////debugger
        const properties = await this.getAttachmentsBySubEntityType();
        if (properties !== undefined) {
            const totalCount = properties.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({allProperties: properties, totalPages, newSearch: true });
        }
        else
        {
            this.setState({ allProperties:[], 
                totalPages:0, newSearch: true });
        }
        globalFunctions.finishLoading();
    }

    handleRowClick = (obj) => {
        var hasAttachments = '';
        if(obj.AttachmentLinks !== undefined)
        {
            if(obj.AttachmentLinks > 0)
            {
                hasAttachments = 'disabled';
            }
        }
                getAttachmentBySubEntityTypeByID(obj["AttachmentBySubEntityTypeID"]).then(
                    (value) => {
                        
                        var typeProperty = true;
                        var typeTask = false;
                        if(value.UserPropertyID == undefined || value.UserPropertyID == 0)
                        {
                            typeProperty = false;
                            typeTask = true;
                        }
                        this.setState({
                            typeProperty : typeProperty,
                            typeTask : typeTask,
                        }, () => {
                            this.updateAttachmentType(value.SubEntityType);

                            this.setState({
                                attachmentBySubEntityTypeID: obj["AttachmentBySubEntityTypeID"],
                                description: value.Description,
                                subEntityType: value.SubEntityType,
                                order: value.Order,
                                mandatory: value.Mandatory,
                                userPropertyID: value.UserPropertyID,
                                taskID: value.TaskID,
                                groupBy: value.GroupBy,
                                listWant: value.Countries,
                                updateToken: value.UpdateToken,
                                hasAttachmentsDisabled: hasAttachments,
                                disableSubEntityType: 'disabled'
                            },
                                () => {
                                    let Countries1 = value.Countries
                                    var havelist = [...this.state.listInitialHave]
                                    if (Countries1 !== null) {
                                        for (var i = havelist.length - 1; i >= 0; i--) {
                                            for (var j = 0; j < Countries1.length; j++) {
                                                if (Countries1[j] && (Countries1[j].CountryID === havelist[i].value)) {
                                                    havelist.splice(i, 1);
                                                    i = --i
                                                }
                                            }
                                        }
                                    }
                                    this.setState({ listHave: havelist });                                
                                });
                        });
                        
                    }
                );
        
    }

    handleDeleteClick = (obj) => {
        ////debugger
        if(obj.AttachmentLinks === 0)
        {
            this.setState({ showDeleteAlert: true, attachmentBySubEntityTypeID: obj.AttachmentBySubEntityTypeID });
        }
        else
        {
            this.setState({ showAlertGlobal: true, alertTitle: 'Error', alertMessage: 'This Attachment cannot be deleted as it is linked to one or more entities in the system.'});
        }
    }

    changePage = (value) => {
        this.setState({ currentPage: value });
    }

    handleSaveClick = (event) => {
        let userID = localStorage.getItem('UserID');
        var propertyID = this.state.userPropertyID;
        var taskID = this.state.taskID;
        if(this.state.typeProperty)
        {
            taskID = '';
        }
        else
        {
            propertyID = '';
        }
        
        var model = {
            AttachmentBySubEntityTypeID: this.state.attachmentBySubEntityTypeID,
            Description: this.state.description,
            Mandatory: this.state.mandatory,            
            Order: this.state.order,
            SubEntityType: this.state.subEntityType,
            UserPropertyID: propertyID,
            TaskID: taskID,
            AttachmentsByCountry:  JSON.stringify(this.transformWantList(this.state.listWant)),
            GroupBy: this.state.groupBy,
            UpdatedBy: userID,
            UpdateToken: this.state.updateToken
        }
        if (this.validateRequiredFields()) {
            this.setState({loading: true});
            saveAttachmentsBySubEntityType(model).then(
                (json) => {
                    if (json.httpStatusCode !== 200){
                        var alertMsg = 'An error occurred while trying to save the bank. Please try again.'
                        if(json.httpErrorMessage !== undefined && json.httpErrorMessage !== ''){
                            alertMsg = json.httpErrorMessage;
                        }
                        this.setState({showAlert: true, alertMessage: alertMsg, alertTitle: 'Error'});
                    } else {
                        this.setState({updateToken: json.updateToken, addProperattachmentBySubEntityTypeIDtyId: json.attachmentBySubEntityTypeID
                            , showAlert: true, alertTitle: 'Success', alertMessage: 'Attachment definition successfully saved.'
                        });
                        this.handleUpdateGrid();
                        this.handleClearClick();
                    }
                    this.setState({loading: false});
            });
            
        }
    }

    transformWantList = list => {
        var helper = [];
        var i = 0;
        for (i; i < list.length; i++) {
            helper.push({
                'CountryID': list[i].value});
        }
        return helper;
    }

    validateRequiredFields() {
        var valid = true;
        var description = true;
        var order = true;
        var subEntityType = true;
        var userPropertyID = true;
        var taskID = true;
        var listItems = []

        if (this.state.subEntityType === undefined ||
            this.state.subEntityType === "") {
            valid = false;
            subEntityType = false;
            listItems.push("Sub Entity Type");
        }

        if (this.state.description === undefined ||
            this.state.description === "") {
            valid = false;
            description = false;
            listItems.push("Description")
        }

        if (this.state.order === undefined ||
            this.state.order === "") {
            valid = false;
            order = false;
            listItems.push("Display Order")
        }

        if(this.state.typeProperty)
        {
            if(this.state.userPropertyID === undefined ||
            this.state.userPropertyID === "")
            {
                valid = false;
                userPropertyID = false;
                listItems.push("Property");
            }
        }
        else
        {
            if(this.state.taskID === undefined ||
            this.state.taskID === "")
            {
                valid = false;
                taskID = false;
                listItems.push("Task");
            }
        }

        this.setState({
            validations: {
                ...this.state.validations,
                description: description,
                order: order,
                subEntityType: subEntityType,
                userPropertyID: userPropertyID,
                taskID: taskID
            },
            listMissing: listItems
        });

        if (!valid) {
            this.setState({
                showAlert: true,
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    handleClearClick = () => {
        this.setState({
            attachmentBySubEntityTypeID: 0,
            description: '',
            order: 0,
            mandatory: false,
            userPropertyID: '',
            taskID: '',
            updateToken: 0,
            selectIDLeft: '',
            selectIDRight: '',
            listHave: this.state.listInitialHave,
            listWant: [],
            disableSubEntityType:'',
            hasAttachmentsDisabled: '',
            groupBy: ''
        }
        );
    }

    //For color left & right
    roleChangeColorLeft = selectIDLeft => e => {
        if (selectIDLeft !== undefined) {
            this.setState({ selectIDLeft });
        }
    };
    roleChangeColorRight = selectIDRight => e => {
        if (selectIDRight !== undefined) {
            this.setState({ selectIDRight });
        }
    };

    handleAddClick(event) {
        event.preventDefault();
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;
        var idSelected = this.state.selectIDLeft;
        var add = true
        if (idSelected != '') {
            if (listRight.length == 0) {
                listLeft.forEach((country, index) => {
                    if (country.value == idSelected) {
                        listRight.push({ value: country.value, name: country.name })
                        listLeft.splice(index, 1)
                        this.setState({
                            listWant: listRight,
                            listHave: listLeft,
                            selectIDLeft: ''
                        }
                        );
                    }
                });

            } else {
                listRight.forEach(rightRole => {
                    if (rightRole.value == idSelected) {
                        add = false
                    }
                });
                if (add) {
                    listLeft.forEach((leftRole, index) => {
                        if (leftRole.value == idSelected) {
                            listRight.push({ value: leftRole.value, name: leftRole.name })
                            listLeft.splice(index, 1)
                            this.setState({
                                listWant: listRight,
                                listHave: listLeft,
                                selectIDLeft: ''
                            }
                            );
                        }
                    });
                }
            }
        }
    }

    handleRemoveClick(event) {
        event.preventDefault();
        var listLeft = this.state.listHave;
        var listRight = this.state.listWant;
        var idSelected = this.state.selectIDRight;
        var add = true

        if (idSelected != '') {
            listRight.forEach((rightRole, index) => {
                if (rightRole.value == idSelected) {
                    listRight.splice(index, 1)
                    listLeft.push({ value: rightRole.value, name: rightRole.name })
                }
                this.setState({
                    listWant: listRight,
                    listHave: listLeft,
                    selectIDRight: ''
                }
                );
            });
        }
    }

    handleUpdateDescription = (value) => {
        this.setState({ description: value.target.value, validations: { ...this.state.validations, description: true } });
    }

    handleUpdateDisplayOrder = (value) =>  {
        this.setState({ order: value.target.value, validations: { ...this.state.validations, order: true } });
    }
    
    handleUpdateSubEntityType = (value) =>  {
        if (value != undefined && value !== "select") {
            this.setState({ subEntityType: value.value, validations: { ...this.state.validations, subEntityType: true } }
            , () => {this.handleUpdateGrid();
                    this.updateAttachmentType(value.value);});
        }
    }

    updateAttachmentType = (subEntityType) => {
        if(subEntityType !== undefined && subEntityType !== '')
        {
            if(this.state.typeProperty)
            {
                getUserDefinedProperties(subEntityType, 0,0).then(
                    (json) =>
                    {
                        if(json != undefined && json.properties !== undefined)
                        {
                            var table = json.properties;
                            for (var j = 0; j < table.length; j++) {
                                table[j].value = table[j].ID;
                                table[j].name = table[j].Description;
                            }
                            this.setState({ userProperties: table });
                        }
                    }
                );
            }
            else
            {
                getTasksDefBySubEntityType(subEntityType).then(
                    (json) =>
                    {
                        if(json != undefined && json.tasks !== undefined)
                        {
                            var table = json.tasks;
                            for (var j = 0; j < table.length; j++) {
                                table[j].value = table[j].TaskDefID;
                                table[j].name = table[j].Title;
                            }
                            this.setState({ taskList: table });
                        }
                    }
                );
            }
        }
        else{
            this.setState({ typeProperty: true, typeTask: false,
                userProperties: [],
                taskList: [] });
        }
    }

    handleOnCheckMandatory = (event) =>  {
        this.setState({ mandatory: event.target.checked, validations: { ...this.state.validations, mandatory: true } });
    }

    handleUpdateProperty = (value) => {
        if(value != undefined)
        {
            this.setState({ userPropertyID: value.value });
        }
    }

    handleUpdateTask = (value) => {
        if(value != undefined)
        {
            this.setState({ taskID: value.value });
        }
    }

    handleHideButtonClick = () => {
        if (this.state.hideButton) {
            this.setState({ hideButton: false })
        } else {
            this.setState({ hideButton: true })
        }
    }

    handleCheckProperty = (event) => {
        if(event != undefined)
        {
            var check = event.target.checked;
            this.setState({
                typeProperty: check,
                typeTask: !check
            }, () => this.updateAttachmentType(this.state.subEntityType));
        }
    }

    handleCheckTask = (event) => {
        if(event != undefined)
        {
            var check = event.target.checked;
            this.setState({
                typeTask: check,
                typeProperty: !check
            }, () => this.updateAttachmentType(this.state.subEntityType));
        }
    }

    handleUpdateGroupBy = (event) => {
        this.setState({ groupBy: event.target.value });
    };

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    closeAlert() {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }

    closeAlertGlobal = () => {
        this.setState({ showAlertGlobal: false, alertMessage: '', alertTitle: '' });
    }

    handleOpenTranslation = (obj) => {
        //console.log("handleOpenTranslation: " + JSON.stringify(obj))
        this.setState({
            showTranslationDialog   : true,
            objTranslation          : obj
        });
   }

    closeTranslationAlert = () =>
    {
        this.setState({
             showTranslationDialog   : false,
             objTranslation          : null
        })
    }

    render() {
        var classNameSelect = regularSelectClass;
        var listLeft = this.state.listHave;
        var listRight = [];
        if (this.state.listWant === null || this.state.listWant === undefined) {
            this.setState({ listWant: [] }, () => listRight = this.state.listWant)
        } else {
            listRight = this.state.listWant
        }

        var controlTypeAttachment = '';

        if(this.state.typeProperty)
        {
            controlTypeAttachment = <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Properties</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect id="properties-list" className={classNameSelect} getValue={this.handleUpdateProperty} listHasPlaceholder={false} placeholder='Select an item...' data={this.state.userProperties} objValue={{ value: this.state.userPropertyID}} disabled={this.state.hasAttachmentsDisabled}/>
                            </div>
                        </div>
        }
        else
        {
            controlTypeAttachment = <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label">Task</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect id="task-list" className={classNameSelect} getValue={this.handleUpdateTask} listHasPlaceholder={false} placeholder='Select an item...' data={this.state.taskList} objValue={{ value: this.state.taskID}} disabled={this.state.hasAttachmentsDisabled}/>
                            </div>
                        </div>
        }

        return (
            <div>
                <div className="uk-width-1">
                    <div>
                        <h3 className="uk-heading-divider" style={style.titleStyle}>Attachment Definition</h3>
                    </div>
                </div>
                <div className="uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Sub Entity Type (*)</label>
                        <div className=""style={style.inputStyle}>
                            <DynamicSelect id="data-type" placeholder={"Select an Item..."} className={this.formatValidSelect(this.state.validations.subEntityType)} getValue={this.handleUpdateSubEntityType} listHasPlaceholder={true} data={this.state["Sub Entity Types"]} objValue={{ value: this.state.subEntityType }} disabled={this.state.disableSubEntityType} />
                        </div>
                    </div> 
                    <div className="uk-width-1-2 uk-form-stacked">
                    </div>

                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Description (*)</label>
                        <div className=""style={style.inputStyle}>
                            <input className={this.formatValidInput(this.state.validations.description)} id="description" type="text" placeholder="" value={this.state.description} onChange={this.handleUpdateDescription} />
                        </div>
                    </div>       
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Group By</label>
                        <div className=""style={style.inputStyle}>
                            <input className="uk-input" id="group-by" type="text" placeholder="" value={this.state.groupBy} onChange={this.handleUpdateGroupBy} />
                        </div>
                    </div>

                    
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" style={style.boldLabel}>Display Order (*)</label>
                        <div className=""style={style.inputStyle}>
                            <NumberInput className={this.formatValidInput(this.state.validations.order)} type="Integer"  id="displayOrder"  placeholder="" value={this.state.order} onChange={this.handleUpdateDisplayOrder} />
                        </div>
                    </div>   
                    <div className="uk-width-1-2 uk-form-stacked uk-margin">
                        <label className="uk-form-label"><input className="uk-checkbox" type="checkbox" checked={this.state.mandatory} value={this.state.mandatory} onChange={this.handleOnCheckMandatory} /> Mandatory</label>
                    </div> 

                    <div className="uk-width-1-4 uk-form-stacked">                        
                        <label className="uk-form-label">Property
                            <input className="uk-radio" type="radio" onChange={this.handleCheckProperty} checked={this.state.typeProperty} disabled={this.state.hasAttachmentsDisabled} />
                        </label>
                    </div>
                    <div className="uk-width-1-4 uk-form-stacked">
                        <label className="uk-form-label" >Task
                            <input className="uk-radio" type="radio" onChange={this.handleCheckTask} checked={this.state.typeTask} disabled={this.state.hasAttachmentsDisabled} />
                        </label>
                    </div>

                    {controlTypeAttachment}
                     
                </div>
                {/* //------Countries----------- */}
                <ul id="accordion-online-access" uk-accordion="multiple: true">
                    <li className="">
                        <h5 className="uk-accordion-title">Available Countries</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div id="general-information">
                                <div className="uk-grid" style={{ marginTop: "0px" }}>
                                    <div className="uk-width-1-1" id="subtype-component">
                                        <div
                                            className="uk-margin"
                                            style={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                border: '1px solid #9DA99E'
                                            }}
                                        >
                                            <div
                                                className="uk-width-1-3"
                                                style={{
                                                    overflowY: 'scroll',
                                                    height: 500,
                                                }}
                                            >
                                                <table
                                                    className="uk-table uk-table-hover uk-table-divider"
                                                    style={{
                                                        marginBottom: 0
                                                    }}
                                                >
                                                    <tbody>
                                                        {listLeft.map((item, i) => {
                                                            return (
                                                                <tr
                                                                    key={item.value} onClick={this.roleChangeColorLeft(item.value)}
                                                                    style={
                                                                        this.state.selectIDLeft === item.value ?
                                                                            { backgroundColor: Colors.primaryColor, cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                                    }
                                                                >
                                                                    <td
                                                                        style={
                                                                            this.state.selectIDLeft === item.value ?
                                                                                { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                                        }
                                                                    >{item.name}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div
                                                className="uk-card-body uk-width-1-3"
                                                style={{
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    borderRight: '1px solid #9DA99E',
                                                    borderLeft: '1px solid #9DA99E',
                                                    height: 500
                                                }}
                                            >
                                                
                                                <div
                                                    style={{
                                                        justifyContent: 'center',
                                                        marginTop: '50%'
                                                    }}>
                                                    <button
                                                        className="uk-button uk-button-green uk-margin-right" 
                                                        onClick={this.handleAddClick.bind(this)}>
                                                        Add
                                                    </button>
                                                    <br></br>
                                                    <button
                                                    className="uk-button uk-button-green uk-margin-right uk-margin" 
                                                    onClick={this.handleRemoveClick.bind(this)}>
                                                    Remove
                                                </button>
                                              
                                               </div>
                                            </div>
                                            <div
                                                className="uk-width-1-3"
                                                style={{
                                                    overflowY: 'scroll',
                                                    height: 500,
                                                    paddingLeft: 0,
                                                }}
                                            >
                                                <table
                                                    className="uk-table uk-table-hover uk-table-divider"
                                                    style={{
                                                        marginBottom: 0
                                                    }}
                                                >
                                                    <tbody>
                                                        {listRight.map((item, i) => {
                                                            return (
                                                                <tr
                                                                    key={item.value} onClick={this.roleChangeColorRight(item.value)}
                                                                    style={
                                                                        this.state.selectIDRight === item.value ?
                                                                            { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                                    }
                                                                >
                                                                    <td
                                                                        style={
                                                                            this.state.selectIDRight === item.value ?
                                                                                { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }
                                                                        }
                                                                    >{item.name}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                {/* //------Available Subtypes----------- */}
                <div>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSaveClick} type="button">Save</button>
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearClick} type="button">Clear Fields</button>
                    <hr/>
                </div>
                <div className="uk-width-1 uk-margin">
                    {/* <h3 className="uk-heading-divider" style={style.titleStyle}>Existing Properties</h3> */}
                    <NewDynamicTable
                        useIcons={true}
                        id="properties-table"
                        data={this.state.allProperties}
                        hiddenHeaders={['AttachmentBySubEntityTypeID','AttachmentLinks']} 
                        enableClick={true}
                        clickFunction={this.handleRowClick}
                        newSearch={this.state.newSearch}
                        useDeleteButton={true}
                        deleteFunction={this.handleDeleteClick}
                        useFilter={true}
                        filterFunction={this.getAttachmentsBySubEntityType}
                        usePaginator={true}
                        changePage={this.changePage}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.currentPage}
                        numberPerPage={getDefaultPagingNumberOfItems()}
                        enableSortByHeader={true}
                        dynamicActions={[{name: 'Open Translation', function: this.handleOpenTranslation, isIcon: true, icon: <TranslationOutlined />}]}
                    />
                    {/*<Paginator changePage={this.changePage} numberOfPages={this.state.totalPages} newSearch={this.state.newSearch}/>*/}
                </div>
                <AlertBox id="delete-property" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Warning" message="Are you sure you want to delete this Attachment? This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                <AlertBox id="confirmation-delete-property" open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Success" message="Attachment successfully removed." type="Ok" okClick={this.okSuccessAlert} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} messageBoldList={this.state.listMissing} />
                <AlertBox open={this.state.showAlertGlobal} onClose={this.closeAlertGlobal} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertGlobal}  />
                <LoadingBox loading={this.state.loading} />
                <TranslationMaintenanceDialog
                    open    = {this.state.showTranslationDialog}
                    data    = {this.state.objTranslation}
                    type    = {'Attachment'}
                    onclose = {this.closeTranslationAlert}
                />
            </div>
        );
    }

}