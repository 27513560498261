import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DynamicSelect from '../../../shared/DynamicSelect';
import AlertBox from '../../../shared/AlertBox';

const DialogConfirmBeneUserInvitation = (props) => {
    const [contact, setContact] = useState({});
    const [contactID, setContactID] = useState(0);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(()=>{
        if(props.contacts)
        {
            let mainContact = props.contacts.find(c => c["Main Contact$"]);
            if(mainContact)
            {
                setContactID(mainContact["Contact ID"]);
                setContact(mainContact);
            }
        }
    },[props.contacts])

    const getContact = (objContact) =>{
        if(objContact && objContact["Contact ID"])
        {
            console.log(objContact);
            setContact(objContact);
            setContactID(objContact["Contact ID"]);
        }
    }

    const yesClick = () =>{
        
        if(contactID > 0)
        {
            if(props.yesClick)
            {
                props.yesClick(contact);
            }
        }
        else
        {
            setShowAlert(true);
        }

    }

return (
    <>
        <Dialog 
            disableBackdropClick
            disableEscapeKeyDown
            open={props.open} onClose={props.onClose} aria-labelledby={'messageConfirmInvitationTitle'} aria-describedby={'messageConfirmInvitationBy'}>
                <DialogTitle key="title-bene-invitation" id={"title-bene-invitation"}>{"Warning"}</DialogTitle>
                <hr style={{ margin: '0px' }} />
                <DialogContent key="content">
                    <DialogContentText id={'messageConfirmInvitation'}>
                        {"Do you want to send a registration invitation to this beneficiary?"}
                    </DialogContentText>
                    <div className="uk-width-1-1">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >Contact (*)</label>
                        <div className="uk-form-controls" >
                        <DynamicSelect className="uk-select" getValue={getContact} listHasPlaceholder={false} placeholder="Select a Contact"
                                        data={props.contacts} id="contact-select"
                                        objValue={{ value: contactID }} />
                        </div>
                    </div>
                </div>
                </DialogContent>
                <hr style={{ margin: '0px' }} />
                <DialogActions>
               
                    <button className="uk-button uk-button-green" key={"Yes"} type="button" onClick={yesClick}>Yes</button>
                
                    <button className="uk-button uk-button-green" key={"No"} type="button" onClick={props.noClick}>No</button>
            
                </DialogActions>
            </Dialog>
            <AlertBox open={showAlert} onClose={()=> setShowAlert(false)} title={'Error'} message={'Select a contact'} type="Ok" okClick={()=> setShowAlert(false)} />
            </>
);

}

export default DialogConfirmBeneUserInvitation;