import React, { useContext, useEffect, useRef, useState } from 'react';

import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSelect from '../../shared/DynamicSelect';
import { Divider } from 'antd';
import DatePicker from '../../shared/DatePicker';
import { formatDate } from '../../../helpers/FormatHelper';
import { timeList } from './TimeList';
import { saveEvent } from '../../../networking/NetworkingScheduler';
import AppContext from '../../../AppContext';
import { Dialog, DialogContent } from '@material-ui/core';

const FollowUpScreen =(props)=> {
    
    const FORMAT_NEW_DAYPICKER = 'YYYY/MM/DD';
    const style = getStyle();
    const context = useRef(useContext(AppContext));
        const [customerID, setCustomerID] = useState (0);
        const [customerIDName, setCustomerIDName] = useState ('');
        const [contactID, setContactID] = useState (0);
        const [contactIDName, setContactIDName] = useState ('');
        const [eventType, setEventType] = useState ('');
        const [subject, setSubject] = useState ('');
        const [description, setDescription] = useState ('');
        const [dateStart, setDateStart] = useState (formatDate(new Date()));
        const [timeStart, setTimeStart] = useState ('00:00');
        const [dateEnd, setDateEnd] = useState (formatDate(new Date()));
        const [timeEnd, setTimeEnd] = useState ('00:00');
        const [allDay, setAllDay] = useState (false);
        const [reminder, setReminder] = useState ('');
        const [parentEventID, setParentEventID] = useState (0);
        const [dateEndParent, setDateEndParent] = useState (formatDate(new Date()));
        const [timeEndParent, setTimeEndParent] = useState ('00:00');

        const [showAlert, setShowAlert] =useState(false);
        const [showAlertSuccess, setShowAlertSuccess] =useState(false);
        const [alertTitle, setAlertTitle] = useState ('');
        const [alertMessage, setAlertMessage] = useState('');

        const [windowHeight, setWindowHeight] = useState(0);
        const [calendarTop, setCalendarTop] = useState(0);

    useEffect(()=>{
        if(props.openFollowUpEvent && props.event && props.event.EventID > 0)
        {
            let event = props.event;
            setCustomerID(event.CustomerID);
            setCustomerIDName(event.CustomerName);
            setContactID(event.ContactID);
            setContactIDName(event.ContactName);
            setEventType(event.EventType);
            setSubject(event.Subject);
            setDescription(event.Description);
            setParentEventID(event.EventID);
            setDateEndParent(event.EventDateEnd);
            setTimeEndParent(event.EventTimeEnd);
        }
        
    },[props.event, props.openFollowUpEvent])

    const OnCancel = () =>{
        if(props.onCancel)
        {
            props.onCancel();
        }
    }

    const isValid = () =>{

        let valid = true;
        if(customerID === 0 || contactID === 0 || subject === '')
        {
            valid = false;
            setAlertMessage('Please complete the required fields (*)');
        }
        let startDate =new Date(`${dateStart},${timeStart}:00`);
        let endDate = new Date(`${dateEnd},${timeEnd}:00`)
        let endDateParent = new Date(`${dateEndParent},${timeEndParent}:00`)
        if(startDate < endDateParent)
        {
            valid = false;
            setAlertMessage('Start Date cannot be lower than Parent Event');
        }
        if(startDate > endDate)
        {
            valid = false;
            setAlertMessage('Start Date cannot be greater than End Date');
        }

        return valid;
    }
    const OnSave = async() =>{

        if(isValid())
        {
            context.current.startLoading();

            let model = {
                EventID:0,
                EventPersonID: localStorage.getItem('UserID'),
                CustomerID: customerID,
                ContactID: contactID,
                EventType: eventType,
                Subject: subject,
                Description: description,
                EventDateStart: dateStart,
                EventTimeStart: `${timeStart}:00`,
                EventDateEnd: allDay?'':dateEnd,
                EventTimeEnd: allDay?'':`${timeEnd}:00`,
                Reminder: reminder,
                Status: 'Pending',
                UpdatedBy: localStorage.getItem('UserID'),
                UpdateToken: 0,
                ParentEventID: parentEventID
            }
            
            saveEvent(model).then((json) => {

                if (json.httpStatusCode !== 200){
                    setAlertTitle('Error');
                    setAlertMessage('An error occurred while trying to save the Event. Please try again.');
                    setShowAlert(true);                 
                } else {
                    setShowAlertSuccess(true);                 
                    if(props.onReload)
                    {
                        props.onReload();
                    }              
                }
            })

            context.current.finishLoading();

        }
        else
        {
            setAlertTitle('Error');
            setShowAlert(true);
        }
    }

    const closeAlertSuccess = () => {
        setShowAlertSuccess(false);
        if(props.onCancel)
        {
            props.onCancel();
        }
    }

    const closeAlert = () => {
        setShowAlert(false);
    }

    const handleAllDay = () =>{
        if(!allDay)
        {
            setTimeStart('00:00');
            setTimeEnd('00:00');
        }
        setAllDay(!allDay)
    }

    const handleUpdateDateStart = (date) => {
        setDateStart(formatDate(date));
    }

    const handleUpdateDateEnd = (date) => {
        setDateEnd(formatDate(date));
    }

    const getTimeStart = (value) =>{
        if (value != null && value !== 'select' && value.value !== undefined) {
            setTimeStart(value.value !== "select" ? value.value : "");
            let index = timeList.findIndex(t => t.value === value.value);
            let count = timeList.length-1;
            if(index === count)
            {
                setTimeEnd('00:00');
            }
            else
            {
                let time = timeList[index+1];
                setTimeEnd(time.value);
            }

        }
    }

    const getTimeEnd = (value) =>{
        if (value != null && value !== 'select' && value.value !== undefined) {
            setTimeEnd(value.value !== "select" ? value.value : "");
        }
    }

    const getReminder = (value) =>{
        if (value != null && value !== 'select' && value.value !== undefined) {
            setReminder(value.value !== "select" ? value.value : "");
        }
    }

        return(
            <Dialog
          fullWidth={true}
          maxWidth='md'
          disableBackdropClick
          disableEscapeKeyDown
          open={props.openFollowUpEvent} 
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          ref={el => {
            if (!el) return;                    
            setWindowHeight(el.getBoundingClientRect().height);
            }}
            >
          <DialogContent>
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>{`Follow Up Event`}</h3>
                <div className="uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >Customer</label>
                        <div className="" >
                            <input className="uk-input" id="customer" value={customerIDName} type="text" placeholder="Customer" readOnly={true}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >Contact</label>
                        <div className="">
                            <input className="uk-input" id="contact" value={contactIDName} type="text" placeholder="Contact" readOnly={true}/>
                        </div>
                    </div>
                    
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label" >Subject (*)</label>
                        <div className="" style={style.inputStyle}>
                            <input className="uk-input" id="subject" value={subject} type="text" placeholder="Subject" readOnly={true}/>
                        </div>
                    </div>       
                    <>
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                        <div className="uk-width-1-1 uk-form-stacked">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={allDay}
                                    onChange={handleAllDay}
                                ></input>
                                {' All day event'}
                            </label>
                        </div>
                        <div className="uk-width-1-4" style={style.inputStyle}
                        ref={el => {
                            if (!el) return;                    
                            let top = el.getBoundingClientRect().top;
                            let bottom = el.getBoundingClientRect().bottom;
                            let calendarInitTop = windowHeight-top;
                            let calendarInitBotton = windowHeight-bottom;
                            if(calendarInitTop > calendarInitBotton)
                            {
                                if(top > 350)
                                {
                                calendarInitTop= top - 350;
                                }
                                else
                                {
                                    calendarInitTop = 1;
                                }
                                setCalendarTop(calendarInitTop);
                            }
                            else
                            {
                                setCalendarTop(0);
                            }
                            }}

                            >
                        <label className="uk-form-label" >Start</label>
                                <DatePicker
                                    value={dateStart}
                                    onDayChange={handleUpdateDateStart}
                                    className='uk-input'
                                    id="from-date-startFollowUp"
                                    calendarTop={calendarTop}
                                />
                        </div>
                        <div className="uk-width-1-8" style={{paddingBottom:"5px", paddingTop:'21px'}}>
                            <DynamicSelect getValue={getTimeStart} disabled={allDay} listHasPlaceholder={true} data={timeList} id="ttart-timeFollowUp" objValue={{value: timeStart, LookUpTableDetail: timeStart}}/>
                        </div>           

                        <div className="uk-width-1-8 uk-form-stacked" ></div>      
                        
                        <div className="uk-width-1-4 uk-form-stacked" style={style.inputStyle}>
                        <label className="uk-form-label" >End</label>
                                <DatePicker
                                    value={dateEnd}
                                    onDayChange={handleUpdateDateEnd}
                                    className='uk-input'
                                    id="from-date-endFollowUp"
                                    calendarTop={calendarTop}
                                />
                        </div>
                        <div className="uk-width-1-8 uk-form-stacked" style={{paddingBottom:"5px", paddingTop:'21px'}}>
                            <DynamicSelect getValue={getTimeEnd} disabled={allDay} listHasPlaceholder={true} data={timeList} id="end-timeFollowUp" objValue={{value: timeEnd, LookUpTableDetail: timeEnd}}/>
                        </div>
                    

                    <div className="uk-width-1-4 uk-form-stacked">
                        <label className="uk-form-label" >Reminder</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect getValue={getReminder} listHasPlaceholder={true} data={props.eventReminderList} id="event-reminderFollowUp" objValue={{value: reminder, LookUpTableDetail: reminder}}/>
                        </div>
                    </div>             
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    </>
                     
                    <div className="uk-width-1-1" style={{marginTop:'20px', textAlign:'end'}}>
                        <button className="uk-button uk-button-green" onClick={OnCancel} >Cancel</button>
                        <button className="uk-button uk-button-green" onClick={OnSave} >Save</button>  
                    </div>
                </div>
                <AlertBox open={showAlertSuccess} onClose={closeAlertSuccess} title={'Success'} message={'Event successfully saved.'} type="Ok" okClick={closeAlertSuccess}/>
                <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert}/>
            </div>
            </DialogContent>
      </Dialog>
        );
    
}


export default FollowUpScreen;