import React, { useContext, useEffect, useRef, useState } from 'react';

import { getStyle } from '../../../styles/styles';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import AlertBox from '../../shared/AlertBox';
import { getContactInfoMin, getContactsByCustomer } from '../../../networking/NetworkingContacts';
import DynamicSelect from '../../shared/DynamicSelect';
import { Divider } from 'antd';
import DatePicker from '../../shared/DatePicker';
import { formatDate } from '../../../helpers/FormatHelper';
import { timeList } from './TimeList';
import moment from 'moment';
import { getEventByID, saveEvent } from '../../../networking/NetworkingScheduler';
import AppContext from '../../../AppContext';
import { Dialog, DialogContent, Tooltip } from '@material-ui/core';
import FollowUpScreen from './FollowUpScreen';
import ShowDynamicInfo from '../../shared/ShowDynamicInfo';
import { Colors } from '../../../constants/Colors';
import InfoIcon from '@material-ui/icons/Info';
import { getCustomerInfoMin } from '../../../networking/NetworkingCustomers';

const NewEventScreen =(props)=> {
    
    const FORMAT_NEW_DAYPICKER = 'YYYY/MM/DD';
    const style = getStyle();
    const context = useRef(useContext(AppContext));
        const [customers, setCustomers] = useState([]);
        const [customersFiltered, setCustomersFiltered] = useState([]);
        const [contacts, setContacts] = useState([]);
        const [contactsFiltered, setContactsFiltered] = useState([]);
        const [customerID, setCustomerID] = useState (0);
        const [customerIDName, setCustomerIDName] = useState ('');
        const [contactID, setContactID] = useState (0);
        const [contactIDName, setContactIDName] = useState ('');
        const [eventType, setEventType] = useState ('');
        const [subject, setSubject] = useState ('');
        const [description, setDescription] = useState ('');
        const [dateStart, setDateStart] = useState (formatDate(new Date()));
        const [timeStart, setTimeStart] = useState ('00:00');
        const [dateEnd, setDateEnd] = useState (formatDate(new Date()));
        const [timeEnd, setTimeEnd] = useState ('00:00');
        const [allDay, setAllDay] = useState (false);
        const [status, setStatus] = useState ('Pending');
        const [reminder, setReminder] = useState ('');
        const [eventID, setEventID] = useState (0);
        const [parentEventID, setParentEventID] = useState (0);
        const [isParentEvent, setIsParentEvent] = useState (false);
        const [reload, setReload] = useState (false);
        const [updateToken, setUpdateToken] = useState (0);

        const [showAlert, setShowAlert] =useState(false);
        const [showAlertSuccess, setShowAlertSuccess] =useState(false);
        const [showAlertFollowUp, setShowAlertFollowUp] =useState(false);
        const [alertTitle, setAlertTitle] = useState ('');
        const [alertMessage, setAlertMessage] = useState('');
        const [event, setEvent]= useState({});
        const [openFollowUpEvent, setOpenFollowUpEvent] =useState(false);
        const [openInfoScreen, setOpenInfoScreen] =useState(false);
        const [jsonDetail, setJsonDetail] = useState({});
        const [screenTitle, setScreenTitle] = useState ('');

        const [windowHeight, setWindowHeight] = useState(0);
        const [calendarTop, setCalendarTop] = useState(0);

        const numberItems = 7

    useEffect(()=> {
        if(props.customers)
         {   
             setCustomers(props.customers);      
             let firstItems = props.customers.slice(0, numberItems);
             setCustomersFiltered(firstItems);  
         }
        if(props.dateStartEvent)
        {
            let start = moment(props.dateStartEvent);
            setDateStart(start.format(FORMAT_NEW_DAYPICKER));
            setTimeStart(start.format("HH:mm"));
        }
        if(props.dateEndEvent)
        {
            let end = moment(props.dateEndEvent);
            setDateEnd(end.format(FORMAT_NEW_DAYPICKER));
            setTimeEnd(end.format("HH:mm"));
        }

        if(props.allDay)
        {
            setAllDay(true);
            setTimeStart('00:00');
            setTimeEnd('00:00');
        }
        else
        {
            setAllDay(false);
        }

    },[props.customers,props.dateStartEvent, props.dateEndEvent, props.allDay]);

    useEffect(()=>{
        if(props.openNewEvent && props.eventID && props.eventID >0)
        {
            context.current.startLoading();
            getEventByID(props.eventID).then(
                (response) => {
                    if(response && response.event && response.event.length > 0)
                    {
                        let event = response.event[0];
                        setEventID(props.eventID);
                        setCustomerID(event.CustomerID);
                        setCustomerIDName(event.CustomerName);
                        setContactID(event.ContactID);
                        setContactIDName(event.ContactName);
                        setEventType(event.EventType);
                        setStatus(event.Status);
                        setSubject(event.Subject);
                        setDescription(event.Description);
                        setAllDay(event.AllDay);
                        setDateStart(event.EventDateStart);
                        setTimeStart(event.EventTimeStart);
                        setDateEnd(event.EventDateEnd);
                        setTimeEnd(event.EventTimeEnd);
                        setReminder(event.Reminder);
                        setUpdateToken(event.UpdateToken);
                        setParentEventID(event.ParentEventID);
                        setIsParentEvent(event.IsParentEvent);
                        handleUpdateCustomerIDName({name:event.CustomerName, value:event.CustomerID});
                        context.current.finishLoading();
                    }
                    else
                    {
                        context.current.finishLoading();
                    }
                });
        }
        else
        {
            setEventID(0);
            setCustomerID(0);
            setCustomerIDName('');
            setContactID(0);
            setContactIDName('');
            setEventType('');
            setStatus('Pending');
            setSubject('');
            setDescription('');
            setReminder('');
            setUpdateToken(0);
            setParentEventID(0);
            setIsParentEvent(false);
            setContacts([]);
        }

    },[props.eventID, props.openNewEvent])

    const OnCancel = () =>{
        if(props.onCancel)
        {
            props.onCancel(false);
        }
    }

    const isValid = () =>{

        let valid = true;
        if(customerID === 0 || contactID === 0 || subject === '')
        {
            valid = false;
            setAlertMessage('Please complete the required fields (*)');
        }
        let startDate =new Date(`${dateStart},${timeStart}:00`);
        let endDate = new Date(`${dateEnd},${timeEnd}:00`)
        if(startDate > endDate)
        {
            valid = false;
            setAlertMessage('Start Date cannot be greater than End Date');
        }

        return valid;
    }
    const OnSave = async() =>{

        if(isValid())
        {
            context.current.startLoading();
            let model = {
                EventID:eventID,
                EventPersonID: localStorage.getItem('UserID'),
                CustomerID: customerID,
                ContactID: contactID,
                EventType: eventType,
                Subject: subject,
                Description: description,
                EventDateStart: dateStart,
                EventTimeStart: `${timeStart}:00`,
                EventDateEnd: allDay?'':dateEnd,
                EventTimeEnd: allDay?'':`${timeEnd}:00`,
                Reminder: reminder,
                Status: status,
                UpdatedBy: localStorage.getItem('UserID'),
                UpdateToken: updateToken,
                ParentEventID: parentEventID
            }
            
            saveEvent(model).then((json) => {
            context.current.finishLoading();

                if (json.httpStatusCode !== 200){
                    setAlertTitle('Error');
                    setAlertMessage('An error occurred while trying to save the Event. Please try again.');
                    setShowAlert(true);                
                    setReload(false);                
                } else {
                    if(json.EventID > 0)
                    {
                        setEventID(json.EventID);
                        let event = {
                            EventID: json.EventID,
                            CustomerID: customerID,
                            CustomerName: customerIDName,
                            ContactID: contactID,
                            ContactName: contactIDName,
                            EventType: eventType,
                            Subject: subject,
                            Description: description,
                            EventDateEnd: allDay?dateStart:dateEnd,
                            EventTimeEnd: allDay?'00:00':timeEnd
                        }
                        setEvent(event);
                    }
                    setShowAlertSuccess(true);                 
                    setReload(true);                 
                }
            })
        }
        else
        {
            setAlertTitle('Error');
            setShowAlert(true);
        }
    }

    const closeAlertSuccess = () => {
        setShowAlertSuccess(false);
        if(!isParentEvent && status === 'Completed')
        {
            setShowAlertFollowUp(true);
        }
        else
        {
            if(props.onCancel)
            {
                props.onCancel(reload);
            }
        }
    }

    const closeAlert = () => {
        setShowAlert(false);
    }

    const searchCustomer = (query) => {
        if(customers)
        {
            let searchText = query.toLowerCase();
            let customersFilteredAux = customers.filter(c => c.name.toLowerCase().includes(searchText));
            let firstItems = customersFilteredAux.slice(0, numberItems);
            setCustomersFiltered(firstItems);
        }
    }

    const handleUpdateCustomerIDName = async(value) => {
        if (value.value !== undefined) {
            setCustomerIDName(value.name !== 'select' ? value.name : '');
            setCustomerID(value.value !== 'select' ? value.value : '');
            if(value.value !== 'select' && value.value !== '')
            {
            let contactsResult = await  getContactsByCustomer(value.value, 1, 100);
                if (contactsResult.contacts !== undefined) {
                    console.log(contactsResult);
                    for (var i = 0; i < contactsResult.contacts.length; i++) {
                        contactsResult.contacts[i].value = contactsResult.contacts[i]['Contact ID'];
                        contactsResult.contacts[i].name = `${contactsResult.contacts[i].Name} - ${contactsResult.contacts[i]['Contact ID']}`;
                    }
                    setContacts(contactsResult.contacts);
                    let firstItems = contactsResult.contacts.slice(0, numberItems);
                    setContactsFiltered(firstItems);
                } else {
                    setContacts([]);
                }
            }
            else
            {
                setContacts([]);
            } 
        }
    }

    const searchContact = (query) => {
        if(contacts)
        {
            let searchText = query.toLowerCase();
            let contactsFilteredAux = contacts.filter(c => c.name.toLowerCase().includes(searchText));
            let firstItems = contactsFilteredAux.slice(0, numberItems);
            setContactsFiltered(firstItems);
        }
    }

    const handleUpdateContactIDName = (value) => {
        if (value.value !== undefined) {
            setContactIDName(value.name !== 'select' ? value.name : '');
            setContactID(value.value !== 'select' ? value.value : 0);
        }
    }

    const handleChangeSubject = (event) =>{
       setSubject(event.target.value);            
    }

    const handleChangeDescription = (event) =>{
        setDescription(event.target.value);              
     }

    const getEventType = (value) =>{
        if (value != null && value !== 'select' && value.value !== undefined) {
            setEventType(value.value !== "select" ? value.value : "");
        }
    }

    const getStatus = (value) =>{
        if (value != null && value !== 'select' && value.value !== undefined) {
            setStatus(value.value !== "select" ? value.value : "");
        }
    }

    const handleAllDay = () =>{
        if(!allDay)
        {
            setTimeStart('00:00');
            setTimeEnd('00:00');
        }
        setAllDay(!allDay)
    }

    const handleUpdateDateStart = (date) => {
        setDateStart(formatDate(date));
    }

    const handleUpdateDateEnd = (date) => {
        setDateEnd(formatDate(date));
    }

    const getTimeStart = (value) =>{
        if (value != null && value !== 'select' && value.value !== undefined) {
            setTimeStart(value.value !== "select" ? value.value : "");

            let index = timeList.findIndex(t => t.value === value.value);
            let count = timeList.length-1;
            if(index === count)
            {
                setTimeEnd('00:00');
            }
            else
            {
                let time = timeList[index+1];
                setTimeEnd(time.value);
            }

        }
    }

    const getTimeEnd = (value) =>{
        if (value != null && value !== 'select' && value.value !== undefined) {
            setTimeEnd(value.value !== "select" ? value.value : "");
        }
    }

    const getReminder = (value) =>{
        if (value != null && value !== 'select' && value.value !== undefined) {
            setReminder(value.value !== "select" ? value.value : "");
        }
    }

    const onReload = (reload) =>{
        setReload(reload);
    }

    const yesFollowUp = () =>{
        setShowAlertFollowUp(false);
        setOpenFollowUpEvent(true);
     }

     const noFollowUp = () =>{
        setShowAlertFollowUp(false);
        setOpenFollowUpEvent(false);
        if(props.onCancel)
            {
                props.onCancel(reload);
            }
     }

     const cancelFollowUp = () =>{
        setShowAlertFollowUp(false);
        setOpenFollowUpEvent(false);
        if(props.onCancel)
        {
            props.onCancel(reload);
        }
     }

     const showCustomerInfo = async()=>{
        if(customerID > 0)
        {
            let customer = await getCustomerInfoMin(customerID);

            setJsonDetail(customer);
            setScreenTitle("Customer Information");
            setOpenInfoScreen(true);
        }
     }

     const showContactInfo = async ()=>{
        if(contactID > 0)
        {
            let contact = await getContactInfoMin(contactID);
            setJsonDetail(contact);
            setScreenTitle("Contact Information");
            setOpenInfoScreen(true);
        }
     }

        return(
            <>
            <Dialog
          fullWidth={true}
          maxWidth='md'
          disableBackdropClick
          disableEscapeKeyDown
          open={props.openNewEvent} 
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          ref={el => {
            if (!el) return;                    
            setWindowHeight(el.getBoundingClientRect().height);
            }}
          >
          <DialogContent>
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>{`${eventID > 0 ? 'Editing': 'New'} Event`}</h3>
                <div className="uk-grid">
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >{`Customer (*)`} 
                            {customerID > 0 && <Tooltip title="Customer Information">
                                <InfoIcon id={`customerInfo`} onClick={showCustomerInfo} style={{color:Colors.primaryColor, cursor:'pointer'}} />
                  
                            </Tooltip>}</label>
                        <div className="" >
                            <DynamicSearchSelect id="CustomerIDName" placeholder="Customer" searchFunction={searchCustomer} getValue={handleUpdateCustomerIDName} data={customersFiltered} objValue={{ value: customerID, name: customerIDName }} />
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >{`Contact (*)`} 
                            {contactID > 0 && <Tooltip title="Contact Information">
                                <InfoIcon id={`contactInfo`} onClick={showContactInfo} style={{color:Colors.primaryColor, cursor:'pointer'}} />
                  
                            </Tooltip>}</label>
                        <div className="">
                            <DynamicSearchSelect id="ContactIDName" placeholder="Contact" searchFunction={searchContact} getValue={handleUpdateContactIDName} data={contactsFiltered} objValue={{ value: contactID, name: contactIDName }} />
                        </div>
                    </div>
                    
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >Subject (*)</label>
                        <div className="" style={style.inputStyle}>
                            <input className="uk-input" id="subject" value={subject} type="text" placeholder="Subject" onChange={handleChangeSubject}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">                        
                        <label className="uk-form-label" >Event Type (*)</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect getValue={getEventType} listHasPlaceholder={true} data={props.eventTypeList} id="event-types" objValue={{value: eventType, LookUpTableDetail: eventType}}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >Reminder</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect getValue={getReminder} listHasPlaceholder={true} data={props.eventReminderList} id="event-reminder" objValue={{value: reminder, LookUpTableDetail: reminder}}/>
                        </div>
                        <label className="uk-form-label" >Status (*)</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect getValue={getStatus} listHasPlaceholder={true} data={props.eventStatusList} id="event-statuses" objValue={{value: status, LookUpTableDetail: status}}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label" >Notes</label>
                        <div className="" style={style.inputStyle}>
                        <textarea className="uk-textarea" style={{height:'85px'}} id="Notes" type="text" placeholder="" value={description} onChange={handleChangeDescription}></textarea>
                        </div>
                    </div>
                    
                    
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label>
                            <input
                                type="checkbox"
                                checked={allDay}
                                onChange={handleAllDay}
                            ></input>
                            {' All day event'}
                        </label>
                    </div>

                  
                        
                        <div className="uk-form-stacked" style={style.inputStyle}
                        ref={el => {
                            if (!el) return;                    
                            let top = el.getBoundingClientRect().top;
                            let bottom = el.getBoundingClientRect().bottom;
                            let calendarInitTop = windowHeight-top;
                            let calendarInitBotton = windowHeight-bottom;
                            if(calendarInitTop > calendarInitBotton)
                            {
                                if(top > 350)
                                {
                                calendarInitTop= top - 350;
                                }
                                else
                                {
                                    calendarInitTop = 1;
                                }
                                setCalendarTop(calendarInitTop);
                            }
                            else
                            {
                                setCalendarTop(0);
                            }
                            }}>
                        <label className="uk-form-label" >Start</label>
                                <DatePicker
                                    value={dateStart}
                                    onDayChange={handleUpdateDateStart}
                                    className='uk-input'
                                    id="from-date-start"
                                    calendarTop={calendarTop}
                                />
                        </div>
                        <div className="uk-width-1-8" style={{paddingBottom:"5px", paddingTop:'21px'}}>
                            <DynamicSelect getValue={getTimeStart} disabled={allDay} listHasPlaceholder={true} data={timeList} id="ttart-time" objValue={{value: timeStart, LookUpTableDetail: timeStart}}/>
                        </div>             
                        
                        <div className="uk-form-stacked" style={style.inputStyle}>
                        <label className="uk-form-label" >End</label>
                                <DatePicker
                                    value={dateEnd}
                                    onDayChange={handleUpdateDateEnd}
                                    className='uk-input'
                                    id="from-date-end"
                                    calendarTop={calendarTop}
                                />
                        </div>
                        <div className="uk-width-1-8 uk-form-stacked" style={{paddingBottom:"5px", paddingTop:'21px'}}>
                            <DynamicSelect getValue={getTimeEnd} disabled={allDay} listHasPlaceholder={true} data={timeList} id="end-time" objValue={{value: timeEnd, LookUpTableDetail: timeEnd}}/>
                        </div>
                                        
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    <div className="uk-width-1-1" style={{marginTop:'20px', textAlign:'end'}}>
                        <button className="uk-button uk-button-green" onClick={OnCancel} >Cancel</button>
                        <button className="uk-button uk-button-green" onClick={OnSave} >Save</button>  
                    </div>
                </div>
                <AlertBox open={showAlertSuccess} onClose={closeAlertSuccess} title={'Success'} message={'Event successfully saved.'} type="Ok" okClick={closeAlertSuccess}/>
                <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert}/>
                <AlertBox id="followup-alert"
                    type="Yes/No"
                    open={showAlertFollowUp}
                    title="Confirmation"
                    message="Do you want to do a follow up for this event?"
                    yesClick={yesFollowUp}
                    noClick={noFollowUp} />
            </div>
            </DialogContent>
      </Dialog>
      <FollowUpScreen event={event} openFollowUpEvent={openFollowUpEvent}  
        onCancel={cancelFollowUp} 
              eventReminderList={props.eventReminderList}
              onReload={() =>onReload(true)} />
        <ShowDynamicInfo open={openInfoScreen} jsonDetail={jsonDetail} title={screenTitle} onClose={() => setOpenInfoScreen(false)}/>
    </>                
        );
    
}


export default NewEventScreen;