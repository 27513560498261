/* eslint-disable default-case */
import React from 'react';
import DynamicSelect from '../../../shared/DynamicSelect';
import {getCurrencyList, getCurrenciesByCustomer, getCurrenciesByCustomerType} from '../../../../networking/NetworkingCurrencies';
import {regularSelectClass} from '../../../../styles/styles';
import { getInstrumentsByCustomer } from '../../../../networking/NetworkingInstruments';
import { getLookUpTable } from '../../../../networking/Networking';
import { GetListRestrictedCountries } from '../../../../networking/NetworkingCountries';
import { saveOnlineAccess, AFEXCreateInstantBeneficiary, GetFundingBalances, AFEXCreateFundingBalance } from '../../../../networking/NetworkingCustomers';
import AlertBox from '../../../shared/AlertBox';
import ApprovalTiersScreen from './ApprovalTiersScreen';
import LoadingBox from '../../../shared/LoadingBox';
import {Breadcrumb} from 'antd';
import {validateCustomerPairs} from '../../../../networking/NetworkingCurrencyPairs';
import AppContext from '../../../../AppContext';
import {Colors} from '../../../../constants/Colors'

const textCanPayUs = 'Currencies the customer can pay us';
const textCanPurchaseFromUs = 'Currencies the customer can purchase from us';
let globalFunctions = {};
export default class OnlineAccessInformationScreen extends React.Component {
    static contextType = AppContext;
    state = {
        currentID                   : 0,
        currentCustomerObj          : null,
        beneCheckbox                : false,
        payCheckbox                 : false,
        available_countries         : [],
        assigned_countries         : [],

        listOfCurrencyType          : [
                                        {value:'Settlement' , name:'Settlement'},
                                        {value:'Payment'    , name: 'Payment'}
                                        ],
        listOfInstrumentType        : [
                                        {value:'Incoming' , name:'Incoming'},
                                        {value:'Outgoing' , name: 'Outgoing'}
                                        ],

        textToShowNextToSelect      : '',
        currencyTypeSelected        : '',   //Select Currencies
        instrumentTypeSelected      : '',   //Select Instruments

        currencyListByCustomer      : [],   //AllCurrenciesByCustomer
        instrumentListByCustomer    : [],   //AllInstrumentsByCustomer

        //Currencies
        selectedIDRowLeftCurrency   : '',   //Seleccionado de lista Izquierda
        selectedIDRowRightCurrency  : '',   //Seleccionado de lista Derecha
        currencyListHaveShow        : [],   
        currencyListHaveSettlement  : [],
        currencyListHavePayment     : [],
        currencyListWantShow        : [],
        currencyListWantSettlement  : [],
        currencyListWantPayment     : [],

        //Instrumens
        selectedIDRowLeftInstrument : '',   //Seleccionado de lista Izquierd2
        selectedIDRowRightInstrument: '',   //Seleccionado de lista Derecha2
        instrumentListHaveShow      : [],   
        instrumentListHaveIncoming  : [],
        instrumentListHaveOutgoing  : [],
        instrumentListWantShow      : [],
        instrumentListWantIncoming  : [],
        instrumentListWantOutgoing  : [],

        //restrictedCountries
        selectedIDRowLeftRestrictedCountries : '',
        selectedIDRowRightRestrictedCountries: '',

        //Alert Box
        showAlert                   : false,
        alertTitle                  : '',
        alertMessage                : '',
        isSamePage                  : true,
        disable                     : false,
        loading                     : false
    };

    handleBackLink() {
        this.setState({
            isSamePage: true
        });
    }

    componentDidMount()
    {
        this.loadListRestrictedCountriesByCustomer();
        globalFunctions = this.context;
        var customer = this.props.customer;
        var idCustomer = this.props.id;
        //Poner [Textos, Info, etc] por defecto al Inicio
        this.setState({
            textToShowNextToSelect : textCanPayUs,
            currencyTypeSelected : 'Settlement',
            instrumentTypeSelected: 'Incoming',
            currentID: idCustomer,
            currentCustomerObj: customer,
            beneCheckbox: customer.BeneDualApproval,
            payCheckbox: customer.PaymentDualApproval,
            loading: true
        })

        this.loadCurrencies(idCustomer, customer);

        /*
        //Para obtener los Available [Currencies - Settlement]
        getCurrencyList().then(
            (jsonResponse) => {
                let currencies = jsonResponse.currencies

                currencies.forEach((currency, index) => {
                    currency["Type"] = "Settlement"
                })

                //{CurrencyID: "CAD" CurrencyIDName: "CAD - CANADIAN DOLLAR" Type: "Settlement"}
                var wantlistSettlement = this.state.currencyListWantSettlement

                for( var i = currencies.length - 1; i >= 0; i--)
                {
                    for( var j = 0; j<wantlistSettlement.length; j++)
                    {
                        if(currencies[i] && (currencies[i].CurrencyID === wantlistSettlement[j].CurrencyID))
                        {
                            currencies.splice(i, 1);
                        }
                    }
                }

                this.setState({
                    currencyListHaveSettlement: currencies,
                });

            }
        );

        //Para obtener los Available [Currencies - Payment]
        getCurrencyList().then(
            (jsonResponse) => {
                let currencies = jsonResponse.currencies

                currencies.forEach((currency, index) => {
                    currency["Type"] = "Payment"
                })

                //{CurrencyID: "CAD" CurrencyIDName: "CAD - CANADIAN DOLLAR" Type: "Payment"}
                var wantlistPayment = this.state.currencyListWantPayment

                for( var i = currencies.length - 1; i >= 0; i--)
                {
                    for( var j = 0; j<wantlistPayment.length; j++)
                    {
                        if(currencies[i] && (currencies[i].CurrencyID === wantlistPayment[j].CurrencyID))
                        {
                            currencies.splice(i, 1);
                        }
                    }
                }

                this.setState({
                    currencyListHavePayment: currencies,
                });

            }
        );*/
        //Para obtener los [Instruments] del tipo [Incoming] y [Outgoing]
        getInstrumentsByCustomer(idCustomer).then(
            (jsonResponseGetInstrumentByCustomer) => {
                if (jsonResponseGetInstrumentByCustomer.instruments !== undefined) {
                    const listInstrumentsByCustomer = jsonResponseGetInstrumentByCustomer.instruments;
                    const listOfIncoming = listInstrumentsByCustomer.filter(instrument => instrument.InstrumentType === "Incoming");
                    const listOfOutcoming = listInstrumentsByCustomer.filter(instrument => instrument.InstrumentType === "Outgoing");
                    // hasInstruments true
                    this.getInOutINstruments('Incoming Instruments', listOfIncoming);
                    this.getInOutINstruments('Outgoing Instruments', listOfOutcoming);
                    this.setState({
                        instrumentListByCustomer: listInstrumentsByCustomer,
                        instrumentListWantIncoming: listOfIncoming,
                        instrumentListWantOutgoing: listOfOutcoming,
                    });

                } else { // hasInstruments false
                    this.getInOutINstruments('Incoming Instruments', []);
                    this.getInOutINstruments('Outgoing Instruments', []);
                }
            }
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.customer.UpdateToken !== this.props.customer.UpdateToken) {
            const customer = this.props.customer;
            this.setState({
                currentCustomerObj: customer
            });
        }
        if (prevProps.refreshOnlineCurrencies !== this.props.refreshOnlineCurrencies && this.props.refreshOnlineCurrencies) {
            this.loadCurrencies(this.props.id, this.props.customer);
        }
    }

    loadListRestrictedCountriesByCustomer(){
        GetListRestrictedCountries(this.props.id).then((json)=>{
            let available_countries=[]
            let assigned_countries=[]
            if(json.Assigned!=null){assigned_countries=json.Assigned}
            if(json.AllList!=null){
                available_countries=json.AllList
                for (let i = available_countries.length - 1; i >= 0; i--) {
                    for (let j = 0; j < assigned_countries.length; j++) {
                        if (available_countries[i] && (available_countries[i].value == assigned_countries[j].value)) {
                            available_countries.splice(i, 1);
                        }
                    }
                }
            }
            this.setState({ available_countries: available_countries, assigned_countries: assigned_countries });
        })
    }

    loadCurrencies = async (idCustomer, customer) => {
        //Para obtener los [Currencies] del tipo [Settlement] y [Payment]
        const jsonCurrenciesSettlement = await getCurrenciesByCustomerType(customer.CustomerID, 'Settlement');
        const jsonCurrenciesPayment = await getCurrenciesByCustomerType(customer.CustomerID, 'Payment');
        let currenciesPayment = [];
        let currenciesSettlement = [];
        if (jsonCurrenciesSettlement.currencies !== undefined) {
            currenciesSettlement = jsonCurrenciesSettlement.currencies;
        }
        if (jsonCurrenciesPayment.currencies !== undefined) {
            currenciesPayment = jsonCurrenciesPayment.currencies
        }
        let listCurrenciesByCustomer = [];
        let listOfSettlement = [];
        let listOfPayment = [];
        const jsonResponseGetCurrenciesByCustomer = await getCurrenciesByCustomer(idCustomer);
        if (jsonResponseGetCurrenciesByCustomer.currencies !== undefined) {
            listCurrenciesByCustomer = jsonResponseGetCurrenciesByCustomer.currencies;
            listOfSettlement = listCurrenciesByCustomer.filter(currency => currency.Type === "Settlement");
            listOfPayment = listCurrenciesByCustomer.filter(currency => currency.Type === "Payment");
            for (let i = currenciesSettlement.length - 1; i >= 0; i--) {
                for (let j = 0; j < listOfSettlement.length; j++) {
                    if (currenciesSettlement[i] && (currenciesSettlement[i].CurrencyID === listOfSettlement[j].CurrencyID)) {
                        currenciesSettlement.splice(i, 1);
                    }
                }
            }
            for (let i = currenciesPayment.length - 1; i >= 0; i--) {
                for (let j = 0; j < listOfPayment.length; j++) {
                    if (currenciesPayment[i] && (currenciesPayment[i].CurrencyID === listOfPayment[j].CurrencyID)) {
                        currenciesPayment.splice(i, 1);
                    }
                }
            }
        }
        this.setState({
            currencyListByCustomer: listCurrenciesByCustomer,
            currencyListWantSettlement: listOfSettlement,
            currencyListWantShow: listOfSettlement,
            currencyListWantPayment: listOfPayment,
            currencyListHaveSettlement: currenciesSettlement,
            currencyListHavePayment: currenciesPayment,
            loading: false
        });
    }

    async getInOutINstruments (description, wantlist){
       getLookUpTable(description).then(
        (json) => {
            var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
            var helper = [];
            for(var i=1; i<lookUpTableDetails.length; i++){
                lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                helper.push(lookUpTableDetails[i]);
            }
            // eslint-disable-next-line default-case
            switch(description){
                case 'Incoming Instruments':
                {
                    //var wantlistIncoming = this.state.instrumentListWantIncoming
                    for( var i = helper.length - 1; i >= 0; i--)
                    {
                        for( var j = 0; j<wantlist.length; j++)
                        {
                            if(helper[i] && (helper[i].name === wantlist[j].Instrument))
                            {
                                helper.splice(i, 1);
                            }
                        }
                    }
                    this.setState({
                        instrumentListHaveIncoming: helper,
                        loading: false
                    });
                    break;
                }
                case 'Outgoing Instruments':
                {
                    //var wantlistOutgoing = this.state.instrumentListWantOutgoing
                    for( var i = helper.length - 1; i >= 0; i--)
                    {
                        for( var j = 0; j<wantlist.length; j++)
                        {
                            if(helper[i] && (helper[i].name === wantlist[j].Instrument))
                            {
                                helper.splice(i, 1);
                            }
                        }
                    }
                    this.setState({
                        instrumentListHaveOutgoing: helper,
                        loading: false
                    });
                    break;
                }
            }
        }
        );
    }

    onchangeValueOfCurrencyType(typeSelected)
    {
        if(typeSelected != undefined)
        {
            switch(typeSelected.value)
            {
                case 'Settlement':
                    this.setState(
                    {
                        textToShowNextToSelect: textCanPayUs,
                    });
                    break;
                case 'Payment':
                    this.setState(
                    {
                        textToShowNextToSelect: textCanPurchaseFromUs,
                    });
                    break;
                default:
                    break;
            }
            this.setState({
                currencyTypeSelected: typeSelected.value
            })
        }
    }
    
    onchangeValueOfInstrumentType(typeSelected)
    {
        if(typeSelected != undefined)
        {
            this.setState({
                instrumentTypeSelected: typeSelected.value
            });            
        }
    }

    // Currency
    currencyChangeColorLeft = selectedIDRowLeftCurrency => e => {
        if (selectedIDRowLeftCurrency !== undefined) {
          this.setState({ selectedIDRowLeftCurrency  });
        }
    };
    
    currencyChangeColorRight = selectedIDRowRightCurrency => e => {
        if (selectedIDRowRightCurrency !== undefined) {
          this.setState({ selectedIDRowRightCurrency  });
        }
    };

    // Instruments
    instrumentChangeColorLeft = selectedIDRowLeftInstrument => e => {
        if (selectedIDRowLeftInstrument !== undefined) {
          this.setState({ selectedIDRowLeftInstrument  });
        }
    };

    instrumentChangeColorRight = selectedIDRowRightInstrument => e => {
        if (selectedIDRowRightInstrument !== undefined) {
          this.setState({ selectedIDRowRightInstrument  });
        }
    };

    // RestrictedCountries
    restrictedCountriesChangeColorLeft = selectedIDRowLeftRestrictedCountries => e => {
        if (selectedIDRowLeftRestrictedCountries !== undefined) {
          this.setState({ selectedIDRowLeftRestrictedCountries  });
        }
    };

    restrictedCountriesChangeColorRight = selectedIDRowRightRestrictedCountries => e => {
        if (selectedIDRowRightRestrictedCountries !== undefined) {
          this.setState({ selectedIDRowRightRestrictedCountries  });
        }
    };

    sort_restrictedCountries_list(list){
        list.sort(function (a, b) {
            if (a.description > b.description) {
              return 1;
            }
            if (a.description < b.description) {
              return -1;
            }
            return 0;
          });
        return list;
    }

    // RestrictedCountries - BUTTONS
    restrictedCountriesAddItemSelected()
    {
        var listLeft = [];
        var listRight = [];
        listLeft = this.state.available_countries;
        listRight = this.state.assigned_countries;
        
        var idSelected = this.state.selectedIDRowLeftRestrictedCountries;
        var add = true
        
        if(idSelected != '')
        {
            if(listRight.length == 0)
            {
                listLeft.forEach((obj, index) => {
                    if(obj.value == idSelected)
                    {
                        listRight.push(obj)
                        listLeft.splice(index,1)
                    }
                    listRight = this.sort_restrictedCountries_list(listRight);
                    listLeft = this.sort_restrictedCountries_list(listLeft);
                    this.setState({
                        assigned_countries:  listRight,
                        available_countries: listLeft,
                        selectedIDRowLeftRestrictedCountries: ''
                    });
                });                
            }
            else
            {
                listRight.forEach(right_obj => {
                    if(right_obj.value == idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((left_obj, index) => {
                            if(left_obj.value == idSelected)
                            {
                                listRight.push(left_obj)
                                listLeft.splice(index,1)
                                listRight = this.sort_restrictedCountries_list(listRight);
                                listLeft = this.sort_restrictedCountries_list(listLeft);
                                this.setState({
                                    assigned_countries:  listRight,
                                    available_countries: listLeft,
                                    selectedIDRowLeftRestrictedCountries: ''
                                });
                            }
                        });
                    }
            }
        }
    }

    restrictedCountriesRemoveItemSelected()
    {
        var listLeft = [];
        var listRight = [];
        listLeft = this.state.available_countries;
        listRight = this.state.assigned_countries;

        var idSelected = this.state.selectedIDRowRightRestrictedCountries;
        var add = true
        if(idSelected != '')
        {
            listRight.forEach((right_obj, index) => {
                if(right_obj.value == idSelected)
                {
                    listRight.splice(index,1)
                    listLeft.push(right_obj)
                }
            });
            listRight = this.sort_restrictedCountries_list(listRight);
            listLeft = this.sort_restrictedCountries_list(listLeft);
            this.setState({
                assigned_countries:  listRight,
                available_countries: listLeft,
                selectedIDRowRightRestrictedCountries: ''
            });
        }
    }

    restrictedCountriesAddAllItems()
    {
        var listLeft = [];
        var listRight = [];

        listLeft = this.state.available_countries;
        listRight = this.state.assigned_countries;

        Array.prototype.push.apply(listRight,listLeft)
        listRight = this.sort_restrictedCountries_list(listRight);        
        this.setState({
            assigned_countries:  listRight,
            available_countries: [],
            selectedIDRowLeftRestrictedCountries: '',
            selectedIDRowRightRestrictedCountries: ''
        });
    }

    restrictedCountriesRemoveAll()
    {
        var listLeft = [];
        var listRight = [];

        listLeft = this.state.available_countries;
        listRight = this.state.assigned_countries;
        
        Array.prototype.push.apply(listLeft,listRight)
        listLeft = this.sort_restrictedCountries_list(listLeft);
        this.setState({
            assigned_countries:  [],
            available_countries: listLeft,
            selectedIDRowLeftRestrictedCountries: '',
            selectedIDRowRightRestrictedCountries: '',
        });
    }

    //Currency - BUTTONS -------------------------------------------------------------
    currencyAddItemSelected()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                {listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
                break;}
            case 'Payment': 
                {listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
                break;}
        }
        
        var idSelected = this.state.selectedIDRowLeftCurrency;
        var add = true
        
        if(idSelected != '')
        {
            if(listRight.length == 0)
            {
                listLeft.forEach((currency, index) => {
                    if(currency.CurrencyID == idSelected)
                    {
                        listRight.push(currency)
                        listLeft.splice(index,1)
                    }

                    switch(this.state.currencyTypeSelected)
                    {
                        case 'Settlement':
                            {this.setState({
                                currencyListWantSettlement:  listRight,
                                currencyListHaveSettlement: listLeft,
                                selectedIDRowLeftCurrency: ''
                            });
                            break;}
                        case 'Payment': 
                            {this.setState({
                                currencyListWantPayment:  listRight,
                                currencyListHavePayment: listLeft,
                                selectedIDRowLeftCurrency: ''
                            });
                            break;}
                    }
                });
                
            }else
            {
                listRight.forEach(rightCurrency => {
                    if(rightCurrency.CurrencyID == idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((leftCurrency, index) => {
                            if(leftCurrency.CurrencyID == idSelected)
                            {
                                listRight.push(leftCurrency)
                                listLeft.splice(index,1)

                                switch(this.state.currencyTypeSelected)
                                {
                                    case 'Settlement':
                                    {
                                        this.setState({
                                            currencyListWantSettlement:  listRight,
                                            currencyListHaveSettlement: listLeft,
                                            selectedIDRowLeftCurrency: ''
                                        });
                                        break;
                                    }
                                    case 'Payment': 
                                    {
                                        this.setState({
                                            currencyListWantPayment:  listRight,
                                            currencyListHavePayment: listLeft,
                                            selectedIDRowLeftCurrency: ''
                                        });
                                        break;
                                    }
                                }
                            }
                        });
                    }
            }
        }
    }

    currencyRemoveItemSelected()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
            break
            case 'Payment': 
                listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
            break
        }

        var idSelected = this.state.selectedIDRowRightCurrency
        var add = true
        if(idSelected != '')
        {
            listRight.forEach((rightCurrency, index) => {
                if(rightCurrency.CurrencyID == idSelected)
                {
                    listRight.splice(index,1)
                    listLeft.push(rightCurrency)
                }
            });

            switch(this.state.currencyTypeSelected)
            {
                case 'Settlement':
                    {this.setState({
                        currencyListWantSettlement:  listRight,
                        currencyListHaveSettlement: listLeft,
                        selectedIDRowRightCurrency: ''
                    });
                    break;}
                case 'Payment': 
                    {this.setState({
                        currencyListWantPayment:  listRight,
                        currencyListHavePayment: listLeft,
                        selectedIDRowRightCurrency: ''
                    });
                    break;}
            }
        }
    }

    currencyAddAllItems()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
            break
            case 'Payment': 
                listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
            break
        }
        
        Array.prototype.push.apply(listRight,listLeft)

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
            {
                this.setState({
                    currencyListWantSettlement:  listRight,
                    currencyListHaveSettlement: [],
                });
                break;
            }
            case 'Payment': 
            {
                this.setState({
                currencyListWantPayment:  listRight,
                currencyListHavePayment: [],
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftCurrency: '',
            selectedIDRowRightCurrency: '',
        })
    }
    
    currencyRemoveAll()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
                listLeft = this.state.currencyListHaveSettlement
                listRight = this.state.currencyListWantSettlement
            break
            case 'Payment': 
                listLeft = this.state.currencyListHavePayment
                listRight = this.state.currencyListWantPayment
            break
        }
        
        Array.prototype.push.apply(listLeft,listRight)

        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
            {
                this.setState({
                    currencyListWantSettlement:  [],
                    currencyListHaveSettlement: listLeft,
                });
                break;
            }
            case 'Payment': 
            {
                this.setState({
                currencyListWantPayment:  [],
                currencyListHavePayment: listLeft,
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftCurrency: '',
            selectedIDRowRightCurrency: '',
        })
    }

    //Instruments - BUTTONS -------------------------------------------------------------

    addInstrumentItem(instrumentType, instrumentID, listRight, listLeft)
    {
        var idSelected = instrumentID;
        var add = true

        var newIncomingToAdd = {
            CustomerInstrumentId: 0, 
            InstrumentType      : instrumentType, 
            Instrument          : ""
        }
        
        if(idSelected != '')
        {
            if(listRight.length == 0)
            {
                listLeft.forEach((instrument, index) => {
                    if(instrument.name == idSelected)
                    {
                        newIncomingToAdd['Instrument'] = instrument.name
                        listRight.push(newIncomingToAdd)
                        listLeft.splice(index,1)
                    }

                    switch(instrumentType)
                    {
                        case 'Incoming':
                        {
                            this.setState({
                                instrumentListWantIncoming: listRight,
                                instrumentListHaveIncoming: listLeft,
                                selectedIDRowLeftCurrency: ''
                            });
                            break;
                        }
                        case 'Outgoing': 
                        {
                            this.setState({
                                instrumentListWantOutgoing:  listRight,
                                instrumentListHaveOutgoing: listLeft,
                                selectedIDRowLeftInstrument: ''
                            });
                            break;
                        }
                    }
                });
                
            }else
            {
                listRight.forEach(rightInstrument => {
                    if(rightInstrument.Instrument == idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                    {
                        listLeft.forEach((lefInstrument, index) => {
                            if(lefInstrument.name == idSelected)
                            {
                                newIncomingToAdd['Instrument'] = lefInstrument.name
                                listRight.push(newIncomingToAdd)
                                listLeft.splice(index,1)

                                switch(instrumentType)
                                {
                                    case 'Incoming':
                                    {
                                        this.setState({
                                            instrumentListWantIncoming: listRight,
                                            instrumentListHaveIncoming: listLeft,
                                            selectedIDRowLeftInstrument: ''
                                        });
                                        break;
                                    }
                                    case 'Outgoing': 
                                    {
                                        this.setState({
                                            instrumentListWantOutgoing:  listRight,
                                            instrumentListHaveOutgoing: listLeft,
                                            selectedIDRowLeftInstrument: ''
                                        });
                                        break;
                                    }
                                }
                            }
                        });
                    }
            }
        }
    }
    
    instrumentAddItemSelected()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
                break;
            }
            case 'Outgoing': 
            {
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
                break;
            }
        }
        
        this.addInstrumentItem(this.state.instrumentTypeSelected,this.state.selectedIDRowLeftInstrument, listRight, listLeft);
        if(this.state.instrumentTypeSelected === 'Incoming' && this.state.selectedIDRowLeftInstrument === "Client's Vostro Account")
        {
            this.addInstrumentItem('Outgoing',"Hold fund in Client's account", this.state.instrumentListWantOutgoing, this.state.instrumentListHaveOutgoing);
        }
        if(this.state.instrumentTypeSelected === 'Outgoing' && this.state.selectedIDRowLeftInstrument === "Hold fund in Client's account")
        {
            this.addInstrumentItem('Incoming',"Client's Vostro Account", this.state.instrumentListWantIncoming, this.state.instrumentListHaveIncoming);
        }
    }

    removeInstrumentItem(instrumentType, instrumentID, listRight, listLeft)
    {
        var idSelected = instrumentID;

        var oldIncomingToAdd = {
            LookUpTableDetail   : "", 
            Field4              : "Incoming", 
            value               : "",
            name                : ""
        }

        if(idSelected != '')
        {
            listRight.forEach((rightInstrument, index) => {
                if(rightInstrument.Instrument == idSelected)
                {
                    oldIncomingToAdd.LookUpTableDetail = rightInstrument.Instrument
                    oldIncomingToAdd.value = rightInstrument.Instrument
                    oldIncomingToAdd.name = rightInstrument.Instrument
                    listRight.splice(index,1)
                    listLeft.push(oldIncomingToAdd)
                }
            });

            switch(instrumentType)
            {
                case 'Incoming':
                    {this.setState({
                        instrumentListWantIncoming: listRight,
                        instrumentListHaveIncoming: listLeft,
                        selectedIDRowRightInstrument: ''
                    });
                    break;}
                case 'Outgoing': 
                    {this.setState({
                        instrumentListWantOutgoing:  listRight,
                        instrumentListHaveOutgoing: listLeft,
                        selectedIDRowRightInstrument: ''
                    });
                    break;}
            }
        }
    }

    instrumentRemoveItemSelected()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
            break
            case 'Outgoing': 
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
            break
        }

        this.removeInstrumentItem(this.state.instrumentTypeSelected,this.state.selectedIDRowRightInstrument, listRight, listLeft);
        if(this.state.instrumentTypeSelected === 'Incoming' && this.state.selectedIDRowRightInstrument === "Client's Vostro Account")
        {
            this.removeInstrumentItem('Outgoing',"Hold fund in Client's account", this.state.instrumentListWantOutgoing, this.state.instrumentListHaveOutgoing);
        }
        if(this.state.instrumentTypeSelected === 'Outgoing' && this.state.selectedIDRowRightInstrument === "Hold fund in Client's account")
        {
            this.removeInstrumentItem('Incoming',"Client's Vostro Account", this.state.instrumentListWantIncoming, this.state.instrumentListHaveIncoming);
        }
    }

    instrumentAddAllItems()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
            break
            case 'Outgoing': 
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
            break
        }

        var newListLeft = []
        listLeft.forEach((lefInstrument, index) => {
            var newIncomingToAdd = {
                CustomerInstrumentId: 0, 
                InstrumentType      : this.state.instrumentTypeSelected, 
                Instrument          : lefInstrument.name
            }
            newListLeft.push(newIncomingToAdd)
        })
        
        Array.prototype.push.apply(listRight,newListLeft)

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                this.setState({
                    instrumentListWantIncoming  : listRight,
                    instrumentListHaveIncoming  : []
                });
                break;
            }
            case 'Outgoing': 
            {
                this.setState({
                    instrumentListWantOutgoing:  listRight,
                    instrumentListHaveOutgoing: []
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftInstrument: '',
            selectedIDRowRightInstrument: ''
        })
    }
    
    instrumentRemoveAll()
    {
        var listLeft = [];
        var listRight = [];

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                listLeft = this.state.instrumentListHaveIncoming
                listRight = this.state.instrumentListWantIncoming
                break;
            }
            case 'Outgoing': 
            {
                listLeft = this.state.instrumentListHaveOutgoing
                listRight = this.state.instrumentListWantOutgoing
                break;
            }
        }

        var newListRight = []
        listRight.forEach((rightInstrument, index) => {
            var oldInstrumentToAdd = {
                LookUpTableDetail   : rightInstrument.Instrument, 
                Field4              : this.state.instrumentTypeSelected, 
                value               : rightInstrument.Instrument,
                name                : rightInstrument.Instrument
            }
            newListRight.push(oldInstrumentToAdd)
        })

        Array.prototype.push.apply(listLeft,newListRight)

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                this.setState({
                    instrumentListWantIncoming:  [],
                    instrumentListHaveIncoming: listLeft,
                });
                break;
            }
            case 'Outgoing': 
            {
                this.setState({
                    instrumentListWantOutgoing:  [],
                    instrumentListHaveOutgoing: listLeft,
                });
                break;
            }
        }

        this.setState({
            selectedIDRowLeftInstrument: '',
            selectedIDRowRightInstrument: '',
        })
    }
    // ----------------------------------------------------------------------------------

    handleOnCheckBene(event){
        this.setState({beneCheckbox: event.target.checked});    
    }

    handleOnCheckPay(event){
        this.setState({payCheckbox: event.target.checked});
    }

    validatePairs = async() =>
    {
        var result = false;
        var payCurrenciesJson      = [];
        var setCurrenciesJson      = [];
        var currencySettList    = this.state.currencyListWantSettlement;
        var currencyPayList     = this.state.currencyListWantPayment;

        for (var i = 0; i < currencyPayList.length; i++) {
            payCurrenciesJson.push({
                'Currency': currencyPayList[i].CurrencyID});
        }
        for (var i = 0; i < currencySettList.length; i++) {
            setCurrenciesJson.push({
                'Currency': currencySettList[i].CurrencyID});
        }

        var model ={
            'paymentCurrenciesJSON': JSON.stringify(payCurrenciesJson),
            'settlementCurrenciesJSON': JSON.stringify(setCurrenciesJson)
        }
        
        debugger;
        var validate = await validateCustomerPairs(model);
        if (validate != null && validate.httpStatusCode != null) {
            if (validate.httpStatusCode !== 200) {
                this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: validate.httpErrorMessage, loading: false });
            } else {
                if(validate.data != null && validate.data.length > 0)
                {
                    var pairs = '';

                    validate.data.forEach(c => 
                        {
                            pairs += c.Pair + ' ';
                        });

                    var message = <span> {'Currency pairs are not defined'} <br /> {pairs} </span>
                this.setState({
                    showAlert: true, alertTitle: 'Error'
                    , alertMessage: message,
                    loading: false
                });
                }
                else
                {
                    result = true;
                }
            }
        }
        return result;
    }

    handleSaveClick = async(event) =>{
        event.preventDefault();
        globalFunctions.startLoading();

        //if(validate)
        //{

        var currencySettList    = this.state.currencyListWantSettlement;
        var currencyPayList     = this.state.currencyListWantPayment;
        var insList             = this.state.instrumentListWantIncoming;
        var outList             = this.state.instrumentListWantOutgoing;
        var Customer            = this.state.currentCustomerObj;
        var CurrenciesJson      = [];
        var InstrumentsJson     = [];
        
        if(this.state.beneCheckbox===''){Customer.BeneDualApproval=false}else{Customer.BeneDualApproval= this.state.beneCheckbox;}
        if(this.state.payCheckbox===''){Customer.PaymentDualApproval=false}else{Customer.PaymentDualApproval = this.state.payCheckbox;}
        Customer.OrderDualApproval = true;

        for (var i = 0; i < currencySettList.length; i++) {
            CurrenciesJson.push({
                'CurrencyID': currencySettList[i].CurrencyID,
                'Type': 'Settlement'});
        }
        for (var i = 0; i < currencyPayList.length; i++) {
            CurrenciesJson.push({
                'CurrencyID': currencyPayList[i].CurrencyID,
                'Type': 'Payment'});
        }
        for (var i = 0; i < insList.length; i++) {
            InstrumentsJson.push({
                'Instrument': insList[i].Instrument,
                'Type': 'Incoming'});
        }
        for (var i = 0; i < outList.length; i++) {
            InstrumentsJson.push({
                'Instrument': outList[i].Instrument,
                'Type': 'Outgoing'});
        }
        let restrictedCountriesListJson=this.state.assigned_countries;
        var objFilter={
            Customer,
            CurrenciesJson,
            InstrumentsJson,
            restrictedCountriesListJson
        }
        this.setState({ loading: true });
        saveOnlineAccess(objFilter).then(
            (json)=>{
                console.log(json);                
                //var validate = this.validatePairs();
                globalFunctions.finishLoading();
                //if(validate){
                    if (json != null && json.httpStatusCode != null) {
                        if (json.httpStatusCode !== 200) {
                            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: json.httpErrorMessage, loading: false });
                        } else {
                            this.setState({
                                showAlert: true, alertTitle: 'Success', alertMessage: 'Online Access successfully saved.',
                                currentCustomerObj: { ...this.state.currentCustomerObj, UpdateToken: json.updateToken },
                                loading: false
                            });
                            this.props.refreshCustomer();
                        }
                    }
                    var validate = this.validatePairs();
                //}
            }                
        );
        /*}
        else
        {
            globalFunctions.finishLoading();
        }*/

    }

    handleAFEXCreateFundingBalance(event){
        this.setState({  loading: true });
        let list_currency = [];
        
        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':                
                {
                    list_currency=this.state.currencyListWantSettlement;
                break;}
            case 'Payment':                 
                {
                    list_currency=this.state.currencyListWantPayment;
                break;}
        }

        let currencies = [];
        list_currency.forEach(c => {
            currencies.push(c.CurrencyID);
        });

        let customerId = this.props.customer.CustomerID;
        let userId = localStorage.getItem('UserID');
        
        AFEXCreateFundingBalance(customerId, userId, currencies).then(
            (json)=>{
                console.log(json);
                if(json.httpStatusCode===200){                    
                    if(json.SuccessMessage!=='' && json.FailedMessage!==''){
                        this.setState({showAlert: true, alertTitle: 'Information', alertMessage: 'Success: '+json.SuccessMessage+'.\n Failed: '+json.FailedMessage+'.'});
                    }
                    else if(json.SuccessMessage!=='' && json.FailedMessage===''){
                        this.setState({showAlert: true, alertTitle: 'Success', alertMessage: 'Success: '+json.SuccessMessage+'.'});
                    }
                    else if(json.SuccessMessage==='' && json.FailedMessage!==''){
                        this.setState({showAlert: true, alertTitle: 'Failed', alertMessage: 'Failed: '+json.FailedMessage+'.'});
                    }                    
                }else{
                    this.setState({showAlert: true, alertTitle: 'Error', alertMessage: json.httpErrorMessage});
                }
                this.setState({  loading: false });
            })
        ;
    }

    handleGetFundingBalanceId(event){        
        this.setState({  loading: true });
        let list_currency = [];
        
        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':                
                {
                    list_currency=this.state.currencyListWantSettlement;
                break;}
            case 'Payment':                 
                {
                    list_currency=this.state.currencyListWantPayment;
                break;}
        }

        let currencies = [];
        list_currency.forEach(c => {
            currencies.push(c.CurrencyID);
        });

        let customerId = this.props.customer.CustomerID;
        let userId = localStorage.getItem('UserID');
        
        GetFundingBalances(customerId, userId, currencies).then(
            (json)=>{
                if(json.httpStatusCode===200){                    
                    if(json.SuccessMessage!=='' && json.FailedMessage!==''){
                        this.setState({showAlert: true, alertTitle: 'Information', alertMessage: 'Success: '+json.SuccessMessage+'.\n Failed: '+json.FailedMessage+'.'});
                    }
                    else if(json.SuccessMessage!=='' && json.FailedMessage===''){
                        this.setState({showAlert: true, alertTitle: 'Success', alertMessage: 'Success: '+json.SuccessMessage+'.'});
                    }
                    else if(json.SuccessMessage==='' && json.FailedMessage!==''){
                        this.setState({showAlert: true, alertTitle: 'Failed', alertMessage: 'Failed: '+json.FailedMessage+'.'});
                    }                    
                }else{
                    this.setState({showAlert: true, alertTitle: 'Error', alertMessage: json.httpErrorMessage});
                }
                this.setState({  loading: false });
            })
        ;
    }

    handleAFEXCreateInstantBeneficiary(event){
        this.setState({  loading: true });
        let list_currency = [];
        
        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':                
                {
                    list_currency=this.state.currencyListWantSettlement;
                break;}
            case 'Payment':                 
                {
                    list_currency=this.state.currencyListWantPayment;
                break;}
        }

        let currencies = [];
        list_currency.forEach(c => {
            currencies.push(c.CurrencyID);
        });

        let customerId = this.props.customer.CustomerID;
        let userId = localStorage.getItem('UserID');
        
        AFEXCreateInstantBeneficiary(customerId, userId, currencies).then(
            (json)=>{
                if(json.httpStatusCode===200){                    
                    if(json.SuccessMessage!=='' && json.FailedMessage!==''){
                        this.setState({showAlert: true, alertTitle: 'Information', alertMessage: 'Success: '+json.SuccessMessage+'.\n Failed: '+json.FailedMessage+'.'});
                    }
                    else if(json.SuccessMessage!=='' && json.FailedMessage===''){
                        this.setState({showAlert: true, alertTitle: 'Success', alertMessage: 'Success: '+json.SuccessMessage+'.'});
                    }
                    else if(json.SuccessMessage==='' && json.FailedMessage!==''){
                        this.setState({showAlert: true, alertTitle: 'Failed', alertMessage: 'Failed: '+json.FailedMessage+'.'});
                    }                    
                }else{
                    this.setState({showAlert: true, alertTitle: 'Error', alertMessage: json.httpErrorMessage});
                }
                this.setState({  loading: false });
            })
        ;
    }

    handleApprovalTiersClick(event){
        this.setState({isSamePage: false});
    }
    
    closeAlert(){
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }

    //Order una Lista por alguna propiedad [Usado en las Listas de Currencies]
    dynamicSort(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    setApprovalTierList = (appList) =>
    {
        var listTypes = [];

        appList.forEach(element => {
            listTypes.push(element['Entity Type']);
        });

        listTypes = Array.from(new Set(listTypes));

        var dealHas = false;
        var beneHas = false;
        listTypes.forEach(type => {
            switch (type) {
                case 'Deal':
                {   
                    dealHas = true;
                    break;
                }
                case 'Beneficiary':
                {   
                    beneHas = true;
                    break;
                }
            }
        });

        this.setState({
            beneCheckbox: beneHas,
            payCheckbox : dealHas, 
        });

    }

    render(){
        var losQueHayAhora  = []
        var losQueQuiero    = [];
        switch(this.state.currencyTypeSelected)
        {
            case 'Settlement':
            {
                losQueHayAhora = this.state.currencyListHaveSettlement
                losQueQuiero = this.state.currencyListWantSettlement
                break;
            }
            case 'Payment': 
            {
                losQueHayAhora = this.state.currencyListHavePayment
                losQueQuiero = this.state.currencyListWantPayment
                break;
            }
        }
        
        var losQueHayAhoraIns  = []
        var losQueQuieroIns    = []

        switch(this.state.instrumentTypeSelected)
        {
            case 'Incoming':
            {
                losQueHayAhoraIns = this.state.instrumentListHaveIncoming
                losQueQuieroIns = this.state.instrumentListWantIncoming
                break;
            }
            case 'Outgoing': 
            {
                losQueHayAhoraIns = this.state.instrumentListHaveOutgoing
                losQueQuieroIns = this.state.instrumentListWantOutgoing
                break;
            }
        }
        if (!this.state.isSamePage) {
            return (
                <div>
                    <div className="breadcrumb-div">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink.bind(this)}>Dual Approval Information</Breadcrumb.Item>
                            <Breadcrumb.Item className="breadcrumb-item-bold">Approval Tiers</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <ApprovalTiersScreen
                        //backLink={this.handleBackLink.bind(this)}
                        id={this.state.currentID}
                        payCheckbox={this.state.payCheckbox}
                        beneCheckbox={this.state.beneCheckbox}
                        backApprovalTiers={this.setApprovalTierList}
                    />
                </div>
            );
        }
        else {
            return (
                <div>
                    <ul id="accordion-online-access" uk-accordion="multiple: true">
                        <li className="uk-open">
                            <h5 className="uk-accordion-title">Dual Approval Information</h5>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div id="dual-approval">
                                    <div className="uk-margin">
                                        <label className="uk-margin-right">
                                            <input className="uk-checkbox" type="checkbox" checked={this.state.beneCheckbox} onChange={this.handleOnCheckBene.bind(this)} /> Beneficiaries
                                        </label>
                                        <label className="uk-margin-right">
                                            <input className="uk-checkbox" type="checkbox" checked={this.state.payCheckbox} onChange={this.handleOnCheckPay.bind(this)} />
                                            Payment
                                        </label>
                                        <button className="uk-button uk-button-green uk-margin-right" type="button" onClick={this.handleApprovalTiersClick.bind(this)} disabled={!(this.state.beneCheckbox || this.state.payCheckbox)}>Approval Tiers</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="">
                            <h5 className="uk-accordion-title">Payment and Settlement Currencies</h5>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div id="settlement-currencies">
                                    <div style={{ display: 'flex' }}>
                                        <div className="uk-width-1-3 uk-form-stacked">
                                            <div className="uk-margin">
                                                <label className="uk-form-label" htmlFor="form-bank-status">Currency Type</label>
                                                <div className="uk-form-controls">
                                                    <DynamicSelect className={regularSelectClass} objValue={'value'} getValue={this.onchangeValueOfCurrencyType.bind(this)} listHasPlaceholder={false} data={this.state.listOfCurrencyType} id='select-currency-type' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-3 uk-form-stacked">
                                            <div className="uk-form-controls" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', height: '100%' }}    >
                                                &nbsp;<label style={{ fontSize: 16 }}>{this.state.textToShowNextToSelect}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h3 class="uk-width-2-3" style={{ marginLeft: 20 }}>Available Currencies</h3>
                                        <h3 class="uk-width-1-3 uk-form-stacked">Assigned Currencies</h3>
                                    </div>
                                    <div className="" style={{ alignItems: 'center', display: 'flex', border: '1px solid #9DA99E' }}>
                                        <div className="uk-width-1-3 uk-form-stacked" style={{ overflowY: 'scroll', height: 300 }}>
                                            <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                <tbody>
                                                    {losQueHayAhora.map((item, i) => {
                                                        return (
                                                            <tr key={item.CurrencyID} onClick={this.currencyChangeColorLeft(item.CurrencyID)} onDoubleClick={this.currencyAddItemSelected.bind(this)} style={this.state.selectedIDRowLeftCurrency === item.CurrencyID ? { backgroundColor: Colors.primaryColor, cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                <td style={this.state.selectedIDRowLeftCurrency === item.CurrencyID ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                    {item.CurrencyIDName}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="uk-card-body uk-width-1-3 uk-form-stacked" style={{ textAlign: 'center', borderRight: '1px solid #9DA99E', borderLeft: '1px solid #9DA99E' }}>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.currencyAddItemSelected.bind(this)}>Add</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.currencyAddAllItems.bind(this)}>Add all</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.currencyRemoveItemSelected.bind(this)}>Remove</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" onClick={this.currencyRemoveAll.bind(this)}>Remove all</button>
                                        </div>
                                        <div className="uk-width-1-3 uk-form-stacked" style={{ overflowY: 'scroll', height: 300, paddingLeft: 0 }}>
                                            <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                <tbody>
                                                    {losQueQuiero.map((item, i) => {
                                                        return (
                                                            <tr key={item.CurrencyID} onClick={this.currencyChangeColorRight(item.CurrencyID)} onDoubleClick={this.currencyRemoveItemSelected.bind(this)} style={this.state.selectedIDRowRightCurrency === item.CurrencyID ? { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                <td style={this.state.selectedIDRowRightCurrency === item.CurrencyID ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                    {item.CurrencyIDName}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="">
                            <h5 className="uk-accordion-title">Payment and Settlement Instruments</h5>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div id="instruments">
                                    <div style={{ display: 'flex' }}>
                                        <div className="uk-width-1-3 uk-form-stacked">
                                            <div className="uk-margin">
                                                <label className="uk-form-label" htmlFor="form-bank-status">Instrument Type</label>
                                                <div className="uk-form-controls">
                                                    <DynamicSelect className={regularSelectClass} objValue={'value'} getValue={this.onchangeValueOfInstrumentType.bind(this)} listHasPlaceholder={false} data={this.state.listOfInstrumentType} id='select-instrument-type' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h3 class="uk-width-3-3" style={{ marginLeft: 20 }}>Available Instruments</h3>
                                        <h3 class="uk-width-1-3 uk-form-stacked">Assigned Instruments</h3>
                                    </div>
                                    <div className="" style={{ alignItems: 'center', display: 'flex', border: '1px solid #9DA99E' }}>
                                        <div className="uk-width-1-3 uk-form-stacked" style={{ overflowY: 'scroll', height: 300 }}>
                                            <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                <tbody>
                                                    {losQueHayAhoraIns.map((item, i) => {
                                                        return (
                                                            <tr key={item.value} onClick={this.instrumentChangeColorLeft(item.value)} onDoubleClick={this.instrumentAddItemSelected.bind(this)} style={this.state.selectedIDRowLeftInstrument === item.value ? { backgroundColor: Colors.primaryColor, cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                <td style={this.state.selectedIDRowLeftInstrument === item.value ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                    {item.LookUpTableDetail}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="uk-card-body uk-width-1-3 uk-form-stacked" style={{ textAlign: 'center', borderRight: '1px solid #9DA99E', borderLeft: '1px solid #9DA99E' }}>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.instrumentAddItemSelected.bind(this)} >Add</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.instrumentAddAllItems.bind(this)}>Add all</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.instrumentRemoveItemSelected.bind(this)}>Remove</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" onClick={this.instrumentRemoveAll.bind(this)}>Remove all</button>
                                        </div>
                                        <div className="uk-width-1-3 uk-form-stacked" style={{ overflowY: 'scroll', height: 300, paddingLeft: 0 }}>
                                            <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                <tbody>
                                                    {losQueQuieroIns.map((item) => {
                                                        return (
                                                            <tr key={item.Instrument} onClick={this.instrumentChangeColorRight(item.Instrument)} onDoubleClick={this.instrumentRemoveItemSelected.bind(this)} style={this.state.selectedIDRowRightInstrument === item.Instrument ? { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                <td style={this.state.selectedIDRowRightInstrument === item.Instrument ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                    {item.Instrument}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="">
                            <h5 className="uk-accordion-title">Blocked destination countries</h5>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div id="instruments">
                                    <div style={{ display: 'flex' }}>
                                        <h3 class="uk-width-3-3" style={{ marginLeft: 20 }}>Available Countries</h3>
                                        <h3 class="uk-width-1-3 uk-form-stacked">Restricted Countries</h3>
                                    </div>

                                    <div className="" style={{ alignItems: 'center', display: 'flex', border: '1px solid #9DA99E' }}>
                                        <div className="uk-width-1-3 uk-form-stacked" style={{ overflowY: 'scroll', height: 300 }}>
                                            <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                <tbody>
                                                    {this.state.available_countries.map((item, i) => {
                                                        return (
                                                            <tr key={item.value} onClick={this.restrictedCountriesChangeColorLeft(item.value)} onDoubleClick={this.restrictedCountriesAddItemSelected.bind(this)} style={this.state.selectedIDRowLeftRestrictedCountries === item.value ? { backgroundColor: Colors.primaryColor, cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                <td style={this.state.selectedIDRowLeftRestrictedCountries === item.value ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                    {item.description}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="uk-card-body uk-width-1-3 uk-form-stacked" style={{ textAlign: 'center', borderRight: '1px solid #9DA99E', borderLeft: '1px solid #9DA99E' }}>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.restrictedCountriesAddItemSelected.bind(this)} >Add</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.restrictedCountriesAddAllItems.bind(this)}>Add all</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.restrictedCountriesRemoveItemSelected.bind(this)}>Remove</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" onClick={this.restrictedCountriesRemoveAll.bind(this)}>Remove all</button>
                                        </div>
                                        <div className="uk-width-1-3 uk-form-stacked" style={{ overflowY: 'scroll', height: 300, paddingLeft: 0 }}>
                                            <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                <tbody>
                                                    {this.state.assigned_countries.map((item) => {
                                                        return (
                                                            <tr key={item.value} onClick={this.restrictedCountriesChangeColorRight(item.value)} onDoubleClick={this.restrictedCountriesRemoveItemSelected.bind(this)} style={this.state.selectedIDRowRightRestrictedCountries === item.value ? { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}>
                                                                <td style={this.state.selectedIDRowRightRestrictedCountries === item.value ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                    {item.description}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>
                    <button className="uk-button uk-button-green" style={{ marginTop: 20 }} onClick={this.handleSaveClick.bind(this)} >Save</button>
                    <button className="uk-button uk-button-green" style={{ marginTop: 20 }} onClick={this.handleAFEXCreateFundingBalance.bind(this)} >Create Funding Balance</button>
                    <button className="uk-button uk-button-green" style={{ marginTop: 20 }} onClick={this.handleGetFundingBalanceId.bind(this)} >Get Funding Balance ID</button>
                    <button className="uk-button uk-button-green" style={{ marginTop: 20 }} onClick={this.handleAFEXCreateInstantBeneficiary.bind(this)} >Create Instant Beneficiary</button>                    
                    <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                    <LoadingBox loading={this.state.loading} />
                </div>
            );
        }
    }
}