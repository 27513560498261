/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import LocalAccountForm from './LocalAccountForm';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import GenericBankAccountForm from './GenericBankAccountForm';
import { getBankAccountsByAccountID, saveBankAccount, sendBankAcountToInterface, validateAccount } from '../../../../networking/NetworkingBankAccounts';
import BankAccountPropertiesForm from './BankAccountPropertiesForm';
import AuditBankAccountScreen from '../auditEntity/AuditBankAccountScreen';
import LoadingBox from '../../../shared/LoadingBox';
import { Monoova_Register, Zepto_Register, OpenPayd_CreateBeneficiaryBankAccount } from '../../../../networking/NetworkingMonoova';
import { AFEXSaveCustomer, onboardCustomerToCurrencyCloud } from '../../../../networking/NetworkingCustomers';
import AppContext from '../../../../AppContext';
import { validate } from '../../../../helpers/ValidateHelper';
import { getLookUpTable } from '../../../../networking/Networking';
import DynamicSelect from '../../../shared/DynamicSelect';
import { formatValidSelect } from '../../../../helpers/FormatHelper';
import { Breadcrumb } from 'antd';

let globalFunctions = {};
var style = getStyle();

export default class BankAccountScreen extends React.Component {
    static contextType = AppContext;

    //BankAccountID

    constructor(props)
    {
        super(props);
        var id = 0

        /*this.state.selectedBankAccount ------------
                AccountNumber: "12312123123"
                BankAccountID: 1001218
                CountryIdName: "United Kingdom"
                Currency: "GBP"
        ----------------------------------------------*/

        if(this.props.data)
        {
            if(this.props.data.BankAccountID !== undefined && this.props.data.BankAccountID !== null)
            {
                id = this.props.data.BankAccountID
            }
        }
        this.state = {
            customer        : {},
            activateLocal   : true,
            showAlert       : false,
            showAlertError  : false,
            alertTitle      : '',
            alertMessage    : '',
            newSearch       : false,
            bankAccounts    : [],
            localAccount    : null,
            intermediary    : null,
            correspondent   : null,
            loading         : true,
            isValidatedLocalAccount          : false,
            isValidatedIntermediaryAccount   : true,
            validateLocalAccountFields       : true,
            validateIntermediaryAccountFields: true,
            saveProperties                   : false,
            bankAccountID                    : id,
            localAccountErrors               : [],
            intermediaryAccountErrors        : [],
            clearClicked                     : false,
            viewHistory                      : false,
            isValidCurrency                  : true,            
            dropdownButtonSelectionKey: '',
            validateDropdownButton: true,
            bankFixInterfacesToSend: [],
            lookUpTables: [
                "BankInterfaces"
                /*"Commission Base",
                "Confirmation Type",
                "Customer Groups",
                "Customer Regions",
                "Customer Status",
                "Customer Type",
                "Deal Status",
                "Emails Templates",
                "Fee Types",
                "Incoming Instruments",
                "Industry Sectors",
                "Languages",
                "Reports Templates",
                "Salutation",
                "Service Level",
                "Spread Points"*/
            ],
            showConfirmDuplicateAccount:false
        }
    }

    componentDidMount() 
    {
        globalFunctions = this.context;
        if (this.props.data !== null && this.props.data.BankAccountID !== undefined) 
        {
            getBankAccountsByAccountID(this.props.data.BankAccountID).then(
                (json) => {
                    console.log("getBankAccountsByAccountID: "+ JSON.stringify(json))
                    if (json !== undefined && json["root"] !== undefined) 
                    {
                        var localAccount = {
                            CountryID   : json["root"][0].CountryID,
                            ABANo           : json["root"][0]["ABA No"],
                            AccountNo       : json["root"][0]["Account No"],
                            BankID          : json["root"][0]["Bank ID"],
                            BankName        : json["root"][0]["Bank Name"],
                            BranchID        : json["root"][0]["Branch ID"],
                            BranchName      : json["root"][0]["Branch Name"],
                            CreatedBy       : json["root"][0].CreatedBy,
                            CreatedOn       : json["root"][0].CreatedOn,
                            Currency        : json["root"][0].Currency,
                            DefaultAccount  : json["root"][0].DefaultAccount,
                            Description     : json["root"][0].Description,
                            Active          : json["root"][0].Active ? "Active" : "Inactive",
                            Swift           : json["root"][0].SWIFT,
                            TransitNo       : json["root"][0]["Transit No"],
                            UpdateToken     : json["root"][0].UpdateToken,
                            UpdatedBy       : json["root"][0].UpdatedBy,
                            UpdatedOn       : json["root"][0].UpdatedOn,
                            UserID          : localStorage.getItem('UserID')
                        };
                        var intermediary  = JSON.parse(json["root"][0].Intermediate);
                        var correspondent = JSON.parse(json["root"][0].Correspondent);
                        correspondent = correspondent === null ? null : correspondent[0];
                        intermediary = intermediary === null ? null : intermediary[0];

                        if (intermediary !== null)
                        {
                            intermediary = {
                                ABANo           : intermediary["ABA No"],
                                CountryID   : intermediary["Country ID"],
                                AccountNo       : intermediary["Account No"],
                                BankAccountID   : intermediary["BankAccount ID"],
                                BankID          : intermediary["Bank ID"],
                                BankName        : intermediary["Bank Name"],
                                BranchID        : intermediary["Branch ID"],
                                BranchName      : intermediary["Branch Name"],
                                Swift           : intermediary["SWIFT"],
                                TransitNo       : intermediary["Transit No"],
                                UpdateToken     : intermediary.UpdateToken
                            };
                        }

                        if (correspondent !== null) 
                        {
                            correspondent = {
                                ABANo: correspondent["ABA No"],
                                CountryID   : correspondent["Country ID"],
                                AccountNo: correspondent["Account No"],
                                BankAccountID: correspondent["BankAccount ID"],
                                BankID: correspondent["Bank ID"],
                                BankName: correspondent["Bank Name"],
                                BranchID: correspondent["Branch ID"],
                                BranchName: correspondent["Branch Name"],
                                Swift: correspondent["SWIFT"],
                                TransitNo: correspondent["Transit No"],
                                UpdateToken: correspondent.UpdateToken
                            };
                        }

                        this.setState({
                            localAccount    : localAccount,
                            intermediary    : intermediary,
                            correspondent   : correspondent,
                            bankAccountID   : this.props.data.BankAccountID,
                            loading         : false
                        });
                    } else 
                    {
                        this.setState({
                            bankAccountID   : 0,
                            loading         : false
                        });
                    }
                }
            )
        } else {
            this.setState({
                bankAccountID: 0,
                loading: false
            });
        }

        
    getLookUpTable(this.state.lookUpTables.toString()).then((json) => {        
        for (let i = 0; i < this.state.lookUpTables.length; i++) {
            let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
            if (index > -1) {
                let table = json.lookUpTables[index].lookUpTableDetails;
                for (let j = 0; j < table.length; j++) {
                    table[j].value = table[j].LookUpTableDetail;
                    table[j].name = table[j].LookUpTableDetail;
                }
                
                this.setState({ [this.state.lookUpTables[i]]: table }, () => {
                    if (json.lookUpTables[index].LookUpTableDescription === "BankInterfaces") {
                        let beneBankAccountScreen = this.props.subEntityType === 'Beneficiary Bank Account'                        
                        var bankFixInterfacesToSend = this.state.BankInterfaces.filter(b =>
                            (b.Field6.toString() === 'true' || b.Field5.toString() === 'True' || b.Field1 === 'Payments') &&
                            (!beneBankAccountScreen || (!b.LookUpTableDetail.includes('Currency Cloud') && beneBankAccountScreen)));
                        this.setState({ bankFixInterfacesToSend: bankFixInterfacesToSend });
                    }
                });
            }
        }
    });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.hideDetail !== this.props.hideDetail && !this.props.hideDetail) {          
            this.setState({ viewHistory: false});
            if(this.props.removeBreadcrumb)
            {
                this.props.removeBreadcrumb();
            }
        }
    }

    
    handleBackLink = () => {
        this.setState({
            localAccount: null,
            intermediary: null,
            correspondent: null,
            loading: true
        }, () => this.props.backLink());        
    }

    validateAccountBeforeSave = async() =>{

        var local = {
            BankAccountID:0,
            BankAccount: this.state.localAccount.AccountNo,
            PersonID: this.props.personID
        }

        if(this.state.bankAccountID !== 0)
        {
            local.BankAccountID = this.state.bankAccountID
        }

        var model = {
            localAccount        : local,
            intermediateAccount : null,
                    correspondantAccount: null
        }

        let validAccountResponse = await validateAccount(model);

        if(validAccountResponse.Valid)
        {
            this.saveAccount();
        }
        else{
            this.setState({showConfirmDuplicateAccount: true});
        }

    }

    yesConfirmDuplicate = () =>{
        this.setState({showConfirmDuplicateAccount: false});
        this.saveAccount();
    }

    noConfirmDuplicate = () =>{
        this.setState({showConfirmDuplicateAccount: false});
    }


    saveAccount = () =>{
        var intermediary = null;
                if ( this.state.intermediary !== null && (this.state.intermediary.BankID !== "select" && this.state.intermediary.BankID !== '' && this.state.intermediary.BankID !== undefined)) 
                {
                    intermediary = {
                        ABANo                   : this.state.intermediary.ABANo,
                        AccountCurrencyID       : null,
                        AccountType             : "Intermediate",
                        Active                  : true,
                        BankAccount             : this.state.intermediary.AccountNo,
                        //BankAccountID: this.props.data === null ? null: this.state.intermediary.BankAccountID,
                        BankAccountParentID     : null,
                        BankBranchID            : this.state.intermediary.BranchID,
                        BankID                  : this.state.intermediary.BankID,
                        CreatedOn               : null,
                        DefaultAccount          : false,
                        DefaultDeliveryMethod   : null,
                        Description             : "",
                        GLAccountID             : 0,
                        IsSettlementAccount     : false,
                        PersonID                : this.props.personID,
                        PublishOnline           : false,
                        Swift                   : this.state.intermediary.Swift,
                        TransitNo               : this.state.intermediary.TransitNo,
                        UpdateToken             : this.state.intermediary.UpdateToken,
                        UserID                  : localStorage.getItem('UserID')
                    }

                    if(this.state.bankAccountID !== 0)
                    {
                        intermediary.BankAccountID = this.state.intermediary.BankAccountID
                    }
                }

                var local = {
                    ABANo: this.state.localAccount.ABANo,
                    AccountCurrencyID: this.state.localAccount.Currency,
                    AccountType: "Actual / Local",
                    Active: this.state.localAccount.Active === "Active",
                    BankAccount: this.state.localAccount.AccountNo,
                    BankAccountParentID: null,
                    BankBranchID: this.state.localAccount.BranchID,
                    BankID: this.state.localAccount.BankID,
                    CreatedOn: this.state.localAccount.CreatedOn,
                    DefaultAccount: this.state.localAccount.DefaultAccount,
                    DefaultDeliveryMethod: null,
                    Description: this.state.localAccount.Description,
                    GLAccountID: 0,
                    IsSettlementAccount: false,
                    PersonID: this.props.personID,
                    PublishOnline: false,
                    Swift: this.state.localAccount.Swift,
                    TransitNo: this.state.localAccount.TransitNo,
                    UpdateToken: this.state.localAccount.UpdateToken,
                    UserID: localStorage.getItem('UserID')
                }

                if(this.state.bankAccountID !== 0)
                {
                    local.BankAccountID = this.state.bankAccountID
                }

                var model = {
                    localAccount        : local,
                    intermediateAccount : intermediary,
                    correspondantAccount: null
                }

                console.log("model BEFORE saveBankAccount:  ---------------------------");
                console.log(model);
                console.log("----------------------------------------------------------");

                this.setState({ loading: true });
                saveBankAccount(model).then(
                    (json) => {
                        console.log(json)
                        if (json.httpStatusCode !== 200 && json.httpStatusCode !== 409) {
                            this.setState({
                                showAlertError: true,
                                alertMessage: 'An error occurred while trying to save the bank account. Please try again.',
                                alertTitle: 'Error',
                                loading: false
                            });
                        } else if (json.httpStatusCode === 409) {
                            this.setState({
                                showAlertError: true,
                                alertMessage: json.httpErrorMessage,
                                alertTitle: 'Error',
                                loading: false
                            });
                        } else {
                            this.setState({
                                saveProperties: (this.state.localAccount.UpdateToken !== "")
                            }, () => {
                                    let alertMessage = 'Bank account successfully saved.'
                                    if (json.externalInterfaceErrorMessage !== undefined && json.externalInterfaceErrorMessage !== null
                                        && json.externalInterfaceErrorMessage !== '') {
                                        alertMessage = <span> {alertMessage} <br /> {json.externalInterfaceErrorMessage}</span>
                                    }
                                this.setState({
                                    showAlert: true,
                                    alertTitle: 'Success',
                                    alertMessage: alertMessage,
                                    bankAccountID: json.localBankAccountID,
                                    localAccount: { ...this.state.localAccount, UpdateToken: json.updateToken, BankAccountID: json.localBankAccountID },
                                    intermediary: { ...this.state.intermediary, UpdateToken: json.iUpdateToken },
                                    clearClicked: false,
                                    loading: false
                                });
                                this.props.updatePage();
                            });
                        }
                    }
                )
    }

    handleSaveClick = (event) => 
    {
        //currency will be taken from the screen not from de iban api
       // if(this.state.isValidCurrency)
      //  {
            if (this.state.isValidatedLocalAccount && this.state.isValidatedIntermediaryAccount) 
            {
                this.validateAccountBeforeSave();
            } else {
                if (!this.state.isValidatedLocalAccount && !this.state.isValidatedIntermediaryAccount) {
                    this.setState({
                        showAlertError: true,
                        alertMessage: 'Please fill all mandatory fields',
                        alertTitle: 'Error',
                        validateLocalAccountFields: false,
                        validateIntermediaryAccountFields: false
                    });
                } else if (!this.state.isValidatedLocalAccount) {
                    this.setState({
                        showAlertError: true,
                        alertMessage: 'Please fill all mandatory fields',
                        alertTitle: 'Error',
                        validateLocalAccountFields: false
                    });
                } else if (!this.state.isValidatedIntermediaryAccount) {
                    this.setState({
                        showAlertError: true,
                        alertMessage: 'Please fill all mandatory fields',
                        alertTitle: 'Error',
                        validateIntermediaryAccountFields: false
                    });
                }
            }
       /* } else {
            this.setState({
                showAlertError: true,
                alertMessage: "This beneficiary account's currency is not configured for this customer. Go to the Online Access Information/Settlement currencies section and add the appropriate Payment currency",
                alertTitle: 'Error',
                validateIntermediaryAccountFields: false
            });
        }*/
        
    }

    isValidatedLocalAccount = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedLocalAccount: true,
                validateLocalAccountFields: true,
                localAccountErrors: []
            });
        } else{
            this.setState({
                isValidatedLocalAccount: false,
                localAccountErrors: {tab: "Main Account", errors: validator.errors}
            });
        }
    }

    isValidatedIntermediaryAccount = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedIntermediaryAccount: true,
                validateIntermediaryAccountFields: true,
                intermediaryAccountErrors: []
            });
        } else{
            this.setState({
                isValidatedIntermediaryAccount: false,
                intermediaryAccountErrors: {tab: "Intermediary Account", errors: validator.errors}
            });
        }
    }

    getLocalAccount = (localAccount) => {
        this.setState({localAccount: localAccount});
    }
    
    getValidCurrency = (isValidCurrency) => {
        this.setState({isValidCurrency: isValidCurrency});
    }

    getIntermediaryAccount = (intermediaryAccount) => {
        this.setState({intermediary: intermediaryAccount});
    }

    getSaveProperties = (value) => {
        this.setState({saveProperties: value, loading: false });
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
        //, () => this.props.backLink());
    }

    closeAlertError = () => {
        this.setState({showAlertError: false, alertMessage: '', alertTitle: '', validateLocalAccountFields: true, validateIntermediaryAccountFields: true});
    }

    handleClearClick = (event) =>
    {
        this.setState({
            localAccount    : null,
            intermediary    : null,
            saveProperties  : false,
            clearClicked    : true,
            isValidatedLocalAccount         : false,
            isValidatedIntermediaryAccount  : true,
            validateLocalAccountFields      : true,
            bankAccountID                   : 0,
        });
    }

    clearClickResponse = () =>{
        this.setState({clearClicked    : false});
    }

    handleViewHistoryClick = () => {
        this.setState({ viewHistory: true});
        if(this.props.addBreadcrumb)
        {
            this.props.addBreadcrumb(<Breadcrumb.Item className="breadcrumb-item-bold">Audit Bank Account</Breadcrumb.Item>, 'hideBankAccountDetail')
        }
    }
    

    handleSendMonoova = async () => {
        globalFunctions.startLoading();        
        const json = await Monoova_Register(this.props.customerID, localStorage.getItem('UserID'));
        console.log(json)
        switch (Number(json.httpStatusCode)) {
            case 200: {
                let message = 'Operation successfully completed.'
                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: message });
                this.props.refreshCustomer();
                break;
            }
            default: {
                let message = json.httpErrorMessage
                if (json.Message !== undefined && json.Message !== null
                    && json.Message !== '') {
                    message = <span> {message} <br /> {json.Message}</span>
                }
                this.setState({
                    showAlert: true, alertMessage: message, alertTitle: "Error"
                });
                break;
            }
        }
        globalFunctions.finishLoading();
    }
    
    handleSaveInAfex = async () => {
        globalFunctions.startLoading();
        const json = await AFEXSaveCustomer(this.props.customerID, localStorage.getItem('UserID'));
        console.log(json)
        switch (Number(json.httpStatusCode)) {
            case 200: {
                let message = 'Operation successfully completed.'
                if (json.currencyCloudMessage !== undefined && json.currencyCloudMessage !== null
                    && json.currencyCloudMessage !== '') {
                    message = <span> {message} <br /> {json.currencyCloudMessage}</span>
                }
                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: message });
                break;
            }
            default: {
                let message = json.httpErrorMessage
                let title = 'Error'
                let errors = []

                let validationMessage = json.validationMessage
                let validationMessageList = []
                if (validationMessage !== undefined && validationMessage !== null && validationMessage !== '') {
                    validationMessageList = validationMessage.split("\n") || []
                }

                if (json.errorMessage !== undefined && json.errorMessage !== null
                    && json.errorMessage !== '') {
                    message = <span> {message} <br /> {json.errorMessage}</span>
                }

                if (validationMessageList.length > 0) {
                    errors = validationMessageList
                    message = ''
                }
                this.setState({
                    showAlert: true, alertMessage: message, alertTitle: title, errors: errors
                });
                break;
            }
        }
        globalFunctions.finishLoading();
    }
    
    handleSendToCurrencyCloud = async () => {
        globalFunctions.startLoading();
        const json = await onboardCustomerToCurrencyCloud(this.props.customerID, localStorage.getItem('UserID'));
        console.log(json)
        switch (Number(json.httpStatusCode)) {
            case 200: {
                let message = 'Operation successfully completed.'
                if (json.currencyCloudMessage !== undefined && json.currencyCloudMessage !== null
                    && json.currencyCloudMessage !== '') {
                    message = json.currencyCloudMessage.split("\n").map(function (item, idx) {
                        return (
                            <span key={idx}>
                                {item}
                                <br />
                            </span>
                        )
                    })
                }
                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: message });
                break;
            }
            default: {
                let message = json.httpErrorMessage
                if (json.errorMessage !== undefined && json.errorMessage !== null
                    && json.errorMessage !== '') {
                    message = <span> {message} <br /> {json.errorMessage}</span>
                }
                this.setState({
                    showAlert: true, alertMessage: message, alertTitle: "Error"
                });
                break;
            }
        }
        globalFunctions.finishLoading();
    }

    handleSendToZeptoClick = async () => {
        globalFunctions.startLoading();        
        const json = await Zepto_Register(this.props.customerID, localStorage.getItem('UserID'), this.state.bankAccountID, this.state.localAccount.UpdateToken,
            this.props.subEntityType === 'Beneficiary Bank Account' ? 'beneficiary' : 'customer');
        console.log(json)
        switch (Number(json.httpStatusCode)) {
            case 200: {
                let message = 'Operation successfully completed.'
                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: message });
                break;
            }
            default: {
                let message = json.httpErrorMessage
                if (json.Message !== undefined && json.Message !== null
                    && json.Message !== '') {
                    message = <span> {message} <br /> {json.Message}</span>
                }
                this.setState({
                    showAlert: true, alertMessage: message, alertTitle: "Error"
                });
                break;
            }
        }
        globalFunctions.finishLoading();
    }
    
    handleSendToOpenPayd = async () => {
        globalFunctions.startLoading();        
        const json = await OpenPayd_CreateBeneficiaryBankAccount(this.state.bankAccountID);
        console.log(json)
        switch (Number(json.httpStatusCode)) {
            case 200: {
                let message = 'Operation successfully completed.'
                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: message });
                this.props.refreshCustomer();
                break;
            }
            default: {
                let message = json.httpErrorMessage
                if (json.Message !== undefined && json.Message !== null
                    && json.Message !== '') {
                    message = <span> {message} <br /> {json.Message}</span>
                }
                this.setState({
                    showAlert: true, alertMessage: message, alertTitle: "Error"
                });
                break;
            }
        }
        globalFunctions.finishLoading();
    }

    handleSendToIbanq= async () => {
        globalFunctions.startLoading();        
        const json = await sendBankAcountToInterface('Ibanq - Payments', this.state.bankAccountID);
        console.log(json)
        switch (Number(json.httpStatusCode)) {
            case 200: {
                let errorMessage = json.ErrorMessage
                let interfaceMessage = json.InterfaceMessage
                let success = json.Response !== undefined && json.Response.success
                if (errorMessage !== undefined && errorMessage !== null && errorMessage !== '') {
                    this.setState({
                        showAlert: true,
                        alertTitle: 'Error',
                        alertMessage: errorMessage
                    });
                } else if (interfaceMessage !== undefined && interfaceMessage !== null && interfaceMessage !== '') {
                    this.setState({
                        showAlert: true,
                        alertTitle: 'Error',
                        alertMessage: interfaceMessage
                    });
                } else if(success) {
                    let message = 'Operation successfully completed.'
                    this.setState({
                        showAlert: true,
                        alertTitle: 'Success',
                        alertMessage: message
                    });                    
                }
                else{
                    let message = 'Something went wrong, please contact us.'
                    this.setState({
                        showAlert: true,
                        alertTitle: 'Error',
                        alertMessage: message
                    });
                }                
                break;
            }
            default: {
                let message = json.httpErrorMessage
                if (json.Message !== undefined && json.Message !== null
                    && json.Message !== '') {
                    message = <span> {message} <br /> {json.Message}</span>
                }
                this.setState({
                    showAlert: true, alertMessage: message, alertTitle: "Error"
                });
                break;
            }
        }
        globalFunctions.finishLoading();
    }

    handleReturnToCustomerInfo = () => {
        this.setState({
            viewHistory: false,
        });
    }
    
    handleDropdownButtonClick = async () => {
        const fields = [
            { displayName: 'Dropdown', validateName: 'dropdownSelection', value: this.state.dropdownButtonSelectionKey, type: 'string' }
        ];
        const [valid] = validate(fields);
        if (valid) {
            switch (this.state.dropdownButtonSelectionKey) {
                default: break;
                case 'Currency Cloud - Auto Cover':
                case 'Currency Cloud - Pass Thru':
                case 'Currency Cloud - Pass Thru with cover':
                case 'Currency Cloud - Payments': {
                    this.openConfirm('Currency Cloud', 'Are you sure to register this customer with Currency Cloud?');  
                    break;
                }
                case 'AFEX':
                case 'AFEX - Auto Cover':
                case 'AFEX - Pass Thru':
                case 'AFEX - Pass Thru With Cover':
                case 'AFEX - Payments': {
                    this.openConfirm('AFEX', 'Are you sure to register this customer with AFEX?');                    
                    break;
                }
                case 'Monoova':{
                    this.openConfirm('Monoova', 'Are you sure to register this customer with Monoova?');
                    break;
                }
                case 'Zepto':{
                    this.openConfirm('Zepto', 'Are you sure to register this customer with Zepto?');
                    break;
                }
                case 'OpenPayd':{
                    this.openConfirm('OpenPayd', 'Are you sure to register this customer with OpenPayd?');
                    break;
                }
                case 'Ibanq':
                case 'IFXPayments':{
                    this.openConfirm('IFXPayments', 'Are you sure to register this customer with IFXPayments?');
                    break;
                }                    
                case 'Ibanq - Payments': {
                    this.openConfirm('Ibanq (IFX)', 'Are you sure to register this customer with Ibanq?');
                    break;
                }
            }
        } else {
            this.setState({ validateDropdownButton: false });
        }
    }
    
    
    //#region Message Confirm
    openConfirm(title, message){
        this.setState({showConfirm: true, alertTitle: title, alertMessage: message});
    }

    yesConfirm(){
        let eventModelMessage = this.state.alertTitle;
        if(eventModelMessage === 'Monoova'){
            this.handleSendMonoova();
        }
        else if(eventModelMessage === 'Zepto'){
            this.handleSendToZeptoClick();
        }
        else if(eventModelMessage === 'AFEX'){
            this.handleSaveInAfex();
        }
        else if(eventModelMessage === 'Currency Cloud'){
            this.handleSendToCurrencyCloud();
        }
        else if(eventModelMessage === 'OpenPayd'){
            this.handleSendToOpenPayd();
        }
        else if(eventModelMessage === 'Ibanq (IFX)'){
            this.handleSendToIbanq();
        }
        else if(eventModelMessage === 'IFXPayments'){
            this.handleSendToIbanq();
        }
        this.setState({showConfirm: false});
    }
    
    closeConfirm(){
        this.setState({showConfirm: false});
    }
    //#endregion

    handleUpdateDropdownButton = (value) => {
        if (value !== undefined) {
            this.setState({ dropdownButtonSelectionKey: value.value, validateDropdownButton: true });
        }
    }

    activateTab = (tab) => this.setState({ [tab]: true });

    render() {
        if (this.state.viewHistory) {
            return (
            <AuditBankAccountScreen
                backLink        = {this.handleReturnToCustomerInfo} 
                bankAccountID   = {this.state.bankAccountID}
            />);
        }
						
        let showId;
        if (this.state.bankAccountID !== 0 && this.state.bankAccountID !== undefined) {
            showId = '-' + this.state.bankAccountID;//this.props.data.BankAccountID;
        } else {
            showId = '';
        }
        var bankTitle = ''
        if (this.props.backLink != null && this.props.isBreadcrum != null) {
            bankTitle = (
                <h3 className="uk-heading-divider component-title" >
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink}>
                        <i className="fa fa-arrow-left back-arrow" ></i>
                    </button>
                    Add / Update Bank Account {showId}
                </h3>);
        }
        return (
            <div>
                {bankTitle}
                <ul uk-tab="">
                    <li>
                        <a onClick={() => this.activateTab('activateLocal')}>Main Account</a>
                    </li>
                    <li>
                        <a onClick={() => this.activateTab('activateIntermediary')}>Intermediary</a>
                    </li>
                    <li>
                        <a>Properties</a>
                    </li>
                </ul>
                <ul className="uk-switcher uk-margin">
                    <li>{this.state.activateLocal &&
                        <LocalAccountForm 
                            customerID={this.props.customerID}
                            data={this.state.localAccount} 
                            getLocalAccount={this.getLocalAccount} 
                            isValid={this.isValidatedLocalAccount} 
                            validated={this.state.validateLocalAccountFields}
                            isClearClicked={this.state.clearClicked}
                            isClearClicked_out={this.clearClickResponse}
                            isBeneficiary={this.props.isBeneficiary}
                            getValidCurrency={this.getValidCurrency}
                            customerInfoUsedForBene={this.props.customerInfoUsedForBene}
                        />}
                    </li>
                    <li>{this.state.activateIntermediary &&
                        <GenericBankAccountForm
                            customerID={this.props.customerID}
                            data={this.state.intermediary} 
                            getBankAccount={this.getIntermediaryAccount} 
                            isValid={this.isValidatedIntermediaryAccount} 
                            validated={this.state.validateIntermediaryAccountFields}
                            isClearClicked={this.state.clearClicked}
                            name={"Intermediary Account"}
                            customerInfoUsedForBene={this.props.customerInfoUsedForBene}
                        />}
                    </li>
                    <li>
                        <BankAccountPropertiesForm 
                            customerID={this.props.customerID}
                            getSaveProperties={this.getSaveProperties} 
                            bankAccountID={this.state.bankAccountID} 
                            saveProperties={this.state.saveProperties}
                            isClearClicked={this.state.clearClicked}
                            subEntityType={this.props.subEntityType}
                            customerInfoUsedForBene={this.props.customerInfoUsedForBene}
                        />
                    </li>
                </ul>

                <div className="uk-grid uk-margin">                    
                    <div className="uk-width-3-4">
                        <button 
                            className="uk-button uk-button-green" 
                            onClick={this.handleSaveClick}
                            disabled={this.props.disableEditCompliance || this.props.customerInfoUsedForBene}
                        >Save</button>
                        <button 
                            className="uk-button uk-button-green" 
                            onClick={this.handleClearClick}
                            disabled={this.props.customerInfoUsedForBene}
                        >Clear fields</button>
                        <button 
                            className="uk-button uk-button-green" 
                            onClick={this.handleViewHistoryClick}
                        >View History</button>
                    </div>
                    {!this.props.customerInfoUsedForBene && 
                    <div className="uk-width-1-4 uk-grid" >
                        <div className="uk-width-1-2  uk-text-right">
                            <DynamicSelect
                                className={'uk-form-width-medium ' + formatValidSelect(this.state.validateDropdownButton)}
                                objValue={{ value: this.state.dropdownButtonSelectionKey }}
                                getValue={this.handleUpdateDropdownButton}
                                placeholder='Select an option...'
                                data={this.state.bankFixInterfacesToSend}
                                id="form-send-to-interface-side" />
                        </div>
                        <div className="uk-width-1-2  uk-text-left">
                            <button className="uk-button uk-button-green" disabled={this.props.disableEditCompliance} onClick={this.handleDropdownButtonClick}>Send</button>
                        </div>
                    </div>
                    }
                </div>

                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={[this.state.localAccountErrors, this.state.intermediaryAccountErrors]} type="Ok" okClick={this.closeAlertError}/>
                <AlertBox 
                            open={this.state.showConfirm} 
                            onClose={this.closeConfirm.bind(this)} 
                            title={this.state.alertTitle}
                            message={this.state.alertMessage}
                            type="Yes/No" 
                            yesClick={this.yesConfirm.bind(this)} 
                            noClick={this.closeConfirm.bind(this)}/>
                <LoadingBox loading={this.state.loading} />
                <AlertBox
                        open={this.state.showConfirmDuplicateAccount}
                        onClose={this.noConfirmDuplicate}
                        title={'Warning'}
                        message={'Account number already exists. Do you want to save?'}
                        type="Yes/No"
                        yesClick={this.yesConfirmDuplicate}
                        noClick={this.noConfirmDuplicate}
                    />
            </div>
        )
    }
}