import React from 'react';
import { getStyle, regularInputClass, regularSelectClass, notFilledInputClass, notFilledSelectClass } from '../../../../styles/styles';
import DynamicSelect from '../../../shared/DynamicSelect';
import { getCurrenciesByCustomer, getCurrencyList, searchCurrencies } from '../../../../networking/NetworkingCurrencies';
import { searchCountries, getCountries, getBankingStandardsByCountry } from '../../../../networking/NetworkingCountries';
import { getListOfBanks, validateIban } from '../../../../networking/NetworkingBanks';
import { getAllBranches } from '../../../../networking/NetworkingBankBranches';
import { validateIBAN } from '../../../../networking/NetworkingBankAccounts';
import { needsIban } from '../../../../networking/NetworkingCountries';
import DynamicSearchSelect from '../../../shared/DynamicSearchSelect';
import { validate } from '../../../../helpers/ValidateHelper';
import LoadingBox from '../../../shared/LoadingBox';
import AlertBox from '../../../shared/AlertBox';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import BanksForm from '../../../maintenance/banks/BanksForm';
import BankBranchForm from '../../../maintenance/bankBranches/BankBranchForm';

var style = getStyle();

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });  
export default class LocalAccountForm extends React.Component {

    state = {
        localAccount: {
            ABANo: '',
            AccountNo: '',
            BankAccountID: 0,
            BankID: '',
            BankName: '',
            BranchID: 0,
            CreatedBy: '',
            CreatedOn: '',
            Currency: '',
            CurrencyName: '',
            CountryID: '',
            CountryIDName: '',
            DefaultAccount: false,
            Description: '',
            Active: "Active",
            Swift: '',
            TransitNo: '',
            UpdatedBy: '',
            UpdatedOn: '',
            UpdateToken: ''
        },
        dropdowns: {
            countryList: [],
            currencies: [],
            banks: [],
            banksSwift: [],
            branches: []
        },
        validations: {
            bank: true,
            accountNo: true,
            currency: true,
            iban: true,
            labelRoutingCode: true,
            labelInstitution: true,
            labelAccountNumber: true
        },
        allBanks: [],
        allBankSwifts: [],
        allBranches: [],
        loading: false,
        validtionsRegex: [],
        labelRoutingCode: { name: 'Routing Code', value: 'Routing Code/BSB/ABA/IFSC', regex: '', validationMessage: ''},
        labelInstitution: { name: 'Institution', value: 'Institution', regex: '', validationMessage: ''},
        labelAccountNumber: { name: 'Account number', value: 'Account number', regex: '', validationMessage: ''},
        //Alert
        showAlert       : false,
        alertTitle      : '',
        alertMessage    : '',
        //Iban validation
        IBAN            : '',
        IbanLoaded      : false,
        NeedsIban       : false,
        DisableControls : false,
        showModal       : false,
        modalTitle      : '',
        fullWidth       : true,
        maxWidth        : 'md',
        selectedBank: {}
    }

    componentDidMount() {
        this.setState({loading: true});
        getBankingStandardsByCountry('', '').then(
            (value) => {
                this.setState({ validtionsRegex: value.data })
            }
        )
        if (this.props.isBeneficiary != null) {
            if (this.props.isBeneficiary) {
                this.getCurrencies(this.props.customerID, "Payment");
            } else {
                this.getCurrencies(this.props.customerID, "Settlement");
            }
        } else {
            getCurrencyList().then(
                (json) => {
                    for (var i = 0; i < json.currencies.length; i++) {
                        json.currencies[i].value = json.currencies[i].CurrencyID;
                        json.currencies[i].name = json.currencies[i].CurrencyIDName;
                    }
                    this.setState({ dropdowns: { ...this.state.dropdowns, currencies: json.currencies } }, () => this.getAllDdwn())
                }
            );
        }
    }
    getCurrencies = async (idCustomer, type) => {
        const jsonResponseGetCurrenciesByCustomer = await getCurrenciesByCustomer(idCustomer);
        var listCurrenciesByCustomer = jsonResponseGetCurrenciesByCustomer != null ? jsonResponseGetCurrenciesByCustomer.currencies : null;
        var listCurrencyOfType = listCurrenciesByCustomer != null ? listCurrenciesByCustomer.filter(currency => currency.Type === type) : [];
        var helper = [];
        listCurrencyOfType.forEach(element => {
            helper.push({
                //CurrencyID: element.CurrencyID,
                //CurrencyIDName: element.CurrencyIDName,
                value: element.CurrencyID,
                name: element.CurrencyIDName
            })
        });
        this.setState({ dropdowns: { ...this.state.dropdowns, currencies: helper } }, () => this.getAllDdwn());
    }
    getAllDdwn = () => {
        getCountries(1, 250).then(
            (json) => {
                if (json != null && json.countries != null) {
                    const countries = json.countries.map(c => ({ ...c, name: c["Country Name"], value: c.Country, needsIban: c.NeedsIban }));
                    this.setState({ dropdowns: { ...this.state.dropdowns, countryList: countries } },
                        () => {
                            getListOfBanks(false).then( // SWIFT
                                (json) => {
                                    for (var i = 0; i < json.banks.length; i++) {
                                        json.banks[i].value = json.banks[i].BankID;
                                        json.banks[i].name = json.banks[i].Name;
                                    }
                                    //this.setState({ dropdowns: { ...this.state.dropdowns, banksSwift: json.banks }, allBankSwifts: json.banks });
                                    this.setState({ allBankSwifts: json.banks },
                                        () => {
                                            getListOfBanks(true).then( // BANKS
                                                (json) => {
                                                    for (var i = 0; i < json.banks.length; i++) {
                                                        json.banks[i].value = json.banks[i].BankID;
                                                        json.banks[i].name = json.banks[i].Name;
                                                    }
                                                    //this.setState({ dropdowns: { ...this.state.dropdowns, banks: json.banks }, allBanks: json.banks });
                                                    this.setState({ allBanks: json.banks },
                                                        () => {
                                                            getAllBranches().then(
                                                                (json) => {
                                                                    if (json !== undefined) {
                                                                        if (json.branches !== undefined) {
                                                                            for (var i = 0; i < json.branches.length; i++) {
                                                                                json.branches[i].value = json.branches[i]["Branch ID"];
                                                                                json.branches[i].name = json.branches[i].Branch;
                                                                            }
                                                                            /* this.setState({ dropdowns: { ...this.state.dropdowns, branches: json.branches }, allBranches: json.branches },
                                                                                () => {
                                                                                    this.props.isValid(this.validateRequiredInputs())
                                                                                    this.updateInputs()
                                                                                }); */
                                                                            this.setState({ allBranches: json.branches },
                                                                                () => {
                                                                                    this.props.isValid(this.validateRequiredInputs())
                                                                                    this.updateInputs()
                                                                                });
                                                                        }
                                                                    }
                                                                }
                                                            )
                                                        });
                                                }
                                            )
                                        });
                                }
                            )
                        });
                }
            }
        )
    }
    updateInputs = () => {
        if (this.props.data !== undefined) {
            if (this.props.data !== null) {
                var propsData = this.props.data;
                this.setState({ localAccount: { ...this.state.localAccount, ...propsData } },
                    () => {
                        // this.searchCountry(propsData.CountryIdName)
                        // this.searchCurrency(propsData.Currency)                        
                        this.props.isValid(this.validateRequiredInputs());
                        this.getBranches(propsData.BankID, undefined);
                    }
                );
                this.NeedsIban(this.props.data.CountryID);
                this.filterBanksByCountry(propsData.CountryID);
            } else {
                this.props.isValid(this.validateRequiredInputs());
                this.setState({loading:false});
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.data != null && this.props.data != null && prevProps.data.UpdateToken !== this.props.data.UpdateToken) {
            const propsData = this.props.data;
            this.setState({ localAccount: { ...this.state.localAccount, UpdateToken: propsData.UpdateToken } });
        } 
        if (prevProps.validated !== this.props.validated && !this.props.validated) {
            this.validateRequiredFields();
        }
        if (this.props.isClearClicked !== prevProps.isClearClicked && this.props.data === null) {
            this.clearLocalAccountForm();
        }
    }

    clearLocalAccountForm() {
        var localAccount = {
            ABANo: '',
            AccountNo: '',
            BankAccountID: 0,
            BankID: '',
            BankName: '',
            BranchID: 0,
            CreatedBy: '',
            CreatedOn: '',
            Currency: '',
            CurrencyName: '',
            CountryID: '',
            CountryIDName: '',
            DefaultAccount: false,
            Description: '',
            Active: "Active",
            Swift: '',
            TransitNo: '',
            UpdatedBy: '',
            UpdatedOn: '',
            UpdateToken: ''
        }
        /*var dropdowns = {
            currencies: [],
            banks: [],
            banksSwift: [],
            branches: []
        }*/

        var validations = {
            bank: true,
            accountNo: true,
            currency: true
        }

        this.setState({
            localAccount: localAccount,
            //dropdowns   : dropdowns,
            validations: validations
        })
        this.props.isClearClicked_out();
    }

    getBranches(bankID, countryID) {
        var objBranches = this.state.allBranches
        var newListB = []
        if (bankID > 0 && countryID !== undefined) {
            objBranches.forEach(value => {
                if (value['Bank ID'] === parseInt(bankID) && value['Country ID'] === countryID) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        } else if (bankID > 0 && countryID === undefined) {
            objBranches.forEach(value => {
                if (value['Bank ID'] === parseInt(bankID)) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        } else {
            objBranches.forEach(value => {
                if (value['Country ID'] === countryID) {
                    newListB.push(value)
                }
            })
            this.setState({ dropdowns: { ...this.state.dropdowns, branches: newListB } });
        }
    }
    //----FILTER BY COUNTRY
    searchCountry(query) {
        var queryHelper = query
        if (queryHelper !== undefined && queryHelper.includes(' - ')) {
            var x = queryHelper.substr(0, 3)
            queryHelper = x
        }
        searchCountries(queryHelper).then(
            (json) => {
                var helper = [];
                if (queryHelper !== '') {
                    console.group('Search Countries');
                    console.log('Query');
                    console.table(queryHelper);
                    console.log('Response');
                    console.table(json);
                    console.groupEnd();
                    for (var i = 0; i < json.length; i++) {
                        json[i].value = json[i].CountryId;
                        json[i].name = json[i].CountryIdName;
                        helper.push(json[i]);
                    }
                    console.log(helper);
                    this.setState({
                        dropdowns: {
                            ...this.state.dropdowns,
                            countryList: helper
                        }
                    });
                    if (helper.length !== 0) {
                        if (helper.length === 1 || helper[0].CountryId === queryHelper) {
                            var value = helper[0];
                            this.setState({
                                localAccount: {
                                    ...this.state.localAccount,
                                    CountryID: value.CountryId, CountryIDName: value.CountryIdName
                                }
                            }, () => {
                                this.filterBanksByCountry(value.CountryId)
                            });
                        }
                    }
                }
            }
        );
        console.table(this.state.countries)
    }

    setLabels=(countryID, currencyID)=>{        
        this.updateLabels(countryID, currencyID, this.state.labelAccountNumber);
        this.updateLabels(countryID, currencyID, this.state.labelInstitution);
        this.updateLabels(countryID, currencyID, this.state.labelRoutingCode);
    }

    updateLabels = (countryID, currencyID, obj) =>{
        var list=this.state.validtionsRegex;        
        var v_exists=false;
        let v_currency = 'ANY';
        v_exists=false;
        list.forEach(value=>{
            if(v_exists){return;}
            if (value['Label'] === obj.name && value['Country'] === countryID && value['Currency'] === currencyID) {
                obj.value = value['Label for country'];
                obj.regex = value['Regex']; 
                obj.validationMessage = value['ValidationMessage'];
                v_exists=true;                
            }
        })
        if(!v_exists){
            list.forEach(value=>{
                if(v_exists){return;}
                if (value['Label'] === obj.name && value['Country'] === countryID && value['Currency'] === v_currency) {
                    obj.value = value['Label for country'];
                    obj.regex = value['Regex'];
                    obj.validationMessage = value['ValidationMessage'];
                    v_exists=true;                
                }
            })
        }
        if(!v_exists){                
            obj.value = obj.name;
            obj.regex = '';
            obj.validationMessage = '';
        }
    }

    handleGetValueCurrency = (value) => {
        if (value.value !== undefined) {
            this.setLabels( this.state.localAccount.CountryID, value.value);
            this.setState({
                localAccount: { ...this.state.localAccount, Currency: value.value, CurrencyName: value.name != null ? value.name : '' },
                validations: { ...this.state.validations, currency: true }
            },
                () => {
                    if (this.validateCurrency()) {
                        this.props.getLocalAccount(this.state.localAccount);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                }
            );
        }
    }

    getCountryValue = (value) => {
        if (value !== '' && value !== 'select' && this.state.IbanLoaded === false) {
            if (value.value != null) {                
                this.setLabels(value.value, this.state.localAccount.Currency)
                //this.props.isValid(this.validateRequiredInputs());
                const newLocalAccount = { ...this.state.localAccount, CountryID: value.value, CountryIDName: value.name != null ? value.name : '' };
                this.props.getLocalAccount(newLocalAccount);
                if (value.value != null) {
                    this.filterBanksByCountry(value.value)
                    //this.getBranches('', value.CountryId)
                }
                let disableControls = false;
                if(value.needsIban === true){
                    disableControls = true;
                }
                this.setState({ localAccount: newLocalAccount, NeedsIban: value.needsIban, DisableControls: disableControls },
                () => {                    
                    this.props.isValid(this.validateRequiredInputs());
                });
                this.setState({
                    localAccount: { ...this.state.localAccount, BankID: "", BankName: "" },
                    validations: { ...this.state.validations, bank: true }
                });
            } else if (value.value == null) {
                /* this.setState({
                    localAccount: { ...this.state.localAccount, CountryID: '', CountryIDName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                    dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                ); */
                this.setState({
                    localAccount: { ...this.state.localAccount, CountryID: '', CountryIDName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                    dropdowns: { ...this.state.dropdowns, banks: [], banksSwift: [], branches: [] },
                    validations: { ...this.state.validations, bank: true }
                },
                    () => {
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                );
            }
        }
    }
    filterBanksByCountry = (countryID) => {
        var objBanks = this.state.allBanks
        var objSwift = this.state.allBankSwifts
        var newList = [], newListS = []
        objBanks.forEach(value => {
            if (value.CountryID === countryID) {
                newList.push(value)
            }
        })
        objSwift.forEach(value => {
            if (value.CountryID === countryID) {
                newListS.push(value)
            }
        })
        this.setState({ dropdowns: { ...this.state.dropdowns, banks: newList, banksSwift: newListS }, loading: false });
    }
    filterBanksForIban = (countryID, bankID) => {
        var objBanks = this.state.allBanks
        var objSwift = this.state.allBankSwifts
        var objBranches = this.state.allBranches
        var newList = [], newListS = [], newListB = []
        objBanks.forEach(value => {
            if (value.CountryID === countryID) {
                newList.push(value)
            }
        })
        objSwift.forEach(value => {
            if (value.CountryID === countryID) {
                newListS.push(value)
            }
        })
        objBranches.forEach(value => {
            if (value['Bank ID'] === parseInt(bankID) && value['Country ID'] === countryID) {
                newListB.push(value)
            }
        })
        this.setState({ dropdowns: { ...this.state.dropdowns, banks: newList, banksSwift: newListS, branches: newListB }, loading: false });
    }
    //----END FILTER BY COUNTRY
    searchCurrency(query) {
        searchCurrencies(query, 'active').then(
            (json) => {
                var helper = [];
                if (query !== '') {
                    if (json !== undefined) {
                        for (var i = 0; i < json.length; i++) {
                            helper.push({ value: json[i].CurrencyID, name: json[i].CurrencyIDName })
                        }
                        if (json.length > 1) {
                            this.setState({ dropdowns: { ...this.state.dropdowns, currencies: helper } });
                        } else {
                            this.setState({ dropdowns: { ...this.state.dropdowns, currencies: helper }, localAccount: { ...this.state.localAccount, CurrencyName: helper[0].name } });
                        }
                    } else {
                        this.setState({ dropdowns: { ...this.state.dropdowns, currencies: helper } });
                    }
                }
            }
        );
    }

    validateRequiredFields() {
        var currency = true, bankID = true, accountNo = true;
 
        if (this.state.localAccount.Currency === "select" || this.state.localAccount.Currency === '' || this.state.localAccount.Currency === undefined) {
            currency = false;
        }
        if (this.state.localAccount.BankID === "select" || this.state.localAccount.BankID === '' || this.state.localAccount.BankID === 0 || this.state.localAccount.BankID === undefined) {
            bankID = false;
        }
        /*let bank = this.state.dropdowns.banks.find(b => b.Name === this.state.localAccount.BankName && b.BankID === this.state.localAccount.BankID );
        if(bank === undefined){
            bankID = false;
        }*/
        if (this.state.localAccount.AccountNo === '') {
            accountNo = false;
        }

        this.validateFormat(this.state.localAccount.TransitNo, this.state.labelRoutingCode, this.state.validations.labelRoutingCode);
        this.validateFormat(this.state.localAccount.AccountNo, this.state.labelAccountNumber, this.state.validations.labelAccountNumber);
        this.validateFormat(this.state.localAccount.ABANo, this.state.labelInstitution, this.state.validations.labelInstitution);
        
        this.setState({
            validations: {
                ...this.state.validations,
                bank: bankID,
                accountNo: accountNo,
                currency: currency
            }
        })
    }

    validateFormat = (textIn, regex, objValidation) => {
        if(regex!==''){
            if (textIn.match(regex)) {
                objValidation = true;
            }
        }
        objValidation =  false;
    }

    validateRequiredInputs() {
   
        const fields = [
            { displayName: 'Bank', validateName: 'bank', value: this.state.localAccount.BankID, type: 'string' },
            { displayName: 'Currency', validateName: 'currency', value: this.state.localAccount.Currency, type: 'string' },
            { displayName: 'Account Number', validateName: 'accountNo', value: this.state.localAccount.AccountNo, type: 'string' },
        ];
        
        let [valid, , errors] = validate(fields);
        this.addLabelValidation(valid, errors, this.state.localAccount.TransitNo, this.state.labelRoutingCode);
        this.addLabelValidation(valid, errors, this.state.localAccount.AccountNo, this.state.labelAccountNumber);
        this.addLabelValidation(valid, errors, this.state.localAccount.ABANo, this.state.labelInstitution);
        /*let bank = this.state.dropdowns.banks.find(b => b.Name === this.state.localAccount.BankName && b.BankID === this.state.localAccount.BankID );
        if(bank === undefined){
            valid = false;
        }*/
        return { valid, errors };
    }

    validateBank() {
        if (this.state.localAccount.BankID === "select" || this.state.localAccount.BankID === '' || this.state.localAccount.BankID === 0 || this.state.localAccount.BankID === undefined) {
            return false;
        }
        return true;
    }

    addLabelValidation = (valid, errors, texto, obj) => {
        if(texto!=='' && obj.regex!=='' && !texto.match(obj.regex)){
            errors.push( obj.value + '(' + obj.validationMessage + ').' );
            valid = false;
        }
    }

    validateCurrency() {
        if (this.state.localAccount.Currency === "select" || this.state.localAccount.Currency === '' || this.state.localAccount.Currency === undefined) {
            return false;
        }
        return true;
    }

    validateAccountNo() {
        if (this.state.localAccount.AccountNo === '') {
            return false;
        }
        return true;
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    handleGetValueCurrency = (value) => {
        if (value.value !== undefined) {
            this.setLabels( this.state.localAccount.CountryID, value.value);
            this.setState({
                localAccount: { ...this.state.localAccount, Currency: value.value, CurrencyName: value.name != null ? value.name : '' },
                validations: { ...this.state.validations, currency: true }
            },
                () => {
                    if (this.validateCurrency()) {
                        this.props.getLocalAccount(this.state.localAccount);
                        this.props.isValid(this.validateRequiredInputs());
                    } else {
                        this.props.isValid({ valid: false, errors: [] });
                    }
                }
            );
        }
    }

    handleGetValueBankSwift = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            let bank = this.state.dropdowns.banks.find(b => b.BankID === value.BankID);
            if (bank !== undefined) {
                let selectedBank={
                    BankID: value.value,
                    BankName: value.name,
                }
                this.setState({
                    localAccount: { ...this.state.localAccount, Swift: value.name, BankID: value.BankID, BankName: bank.name },
                    validations: { ...this.state.validations, bank: true },
                    selectedBank: selectedBank
                },
                    () => {
                        this.getBranches(value.BankID, bank.CountryID)
                        // this.searchCountry(bank.CountryID)
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                );
            } else {
                this.setState({
                    localAccount: { ...this.state.localAccount, Swift: value.name, BankID: value.BankID },
                    validations: { ...this.state.validations, bank: true },
                    selectedBank: {}
                },
                    () => {
                        // this.searchCountry(bank.CountryID)
                        this.getBranches(value.BankID, bank.CountryID)
                        this.props.getLocalAccount(this.state.localAccount);
                    }
                );
            }
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                localAccount: { ...this.state.localAccount, CountryID: '', CountryIDName: '', BranchID: '', Swift: "", BankID: "", BankName: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true }
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleGetValueBank = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            let selectedBank={
                BankID: value.value,
                BankName: value.name,
            }
            this.setState({
                localAccount: { ...this.state.localAccount, BankName: value.name, BankID: value.value, Swift: value.SWIFT === null ? "" : value.SWIFT },
                dropdowns: { ...this.state.dropdowns, branches: [] },
                validations: { ...this.state.validations, bank: true },
                selectedBank: selectedBank
            }, () => {
                if (this.validateBank()) {
                    this.props.getLocalAccount(this.state.localAccount);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: [] });
                }
                if (value.value !== '' && value.value !== "select") {
                    // this.searchCountry(value.CountryID)
                    this.getBranches(this.state.localAccount.BankID, value.CountryID);
                }
            });
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                localAccount: { ...this.state.localAccount, CountryID: '', CountryIDName: '', BranchID: '', BankID: "", BankName: "", Swift: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true, selectedBank:{} }
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleGetValueBranch = (value) => {
        if (value.value !== undefined && value.value !== "select" && value.value !== "") {
            let selectedBank={
                BankID: value.value,
                BankName: value.name,
            }
            this.setState({
                localAccount: { ...this.state.localAccount, BranchID: value.value, BankName: value.Bank, BankID: value['Bank ID'], Swift: value.Swift === null ? "" : value.Swift },
                validations: { ...this.state.validations, bank: true },
                selectedBank: selectedBank
            },
                () => {
                    // this.searchCountry(value['Country ID'])
                    this.getBranches(value['Bank ID'], value['Country ID'])
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        } else if (value.value === "select" || value.value === "") {
            this.setState({
                localAccount: { ...this.state.localAccount, CountryID: '', CountryIDName: '', BranchID: '', BankID: "", BankName: "", Swift: "" },
                dropdowns: { ...this.state.dropdowns, banks: this.state.allBanks, banksSwift: this.state.allBankSwifts, branches: this.state.allBranches },
                validations: { ...this.state.validations, bank: true },
                selectedBank: {}
            },
                () => {
                    this.props.getLocalAccount(this.state.localAccount);
                }
            );
        }
    }

    handleUpdateAccountNumber = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, AccountNo: event.target.value },
            validations: { ...this.state.validations, accountNo: true, labelAccountNumber: true }
        },
            () => {
                if (this.validateAccountNo()) {
                    this.props.getLocalAccount(this.state.localAccount);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    this.props.isValid({ valid: false, errors: [] });
                }
            }
        );
    }

    handleUpdateSwift = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, Swift: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateStatus = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, Active: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateDefaultAccount = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, DefaultAccount: event.target.checked }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateABANo = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, ABANo: event.target.value },            
            validations: { ...this.state.validations, labelInstitution: true }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }

    handleUpdateTransitNo = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, TransitNo: event.target.value },
            validations: { ...this.state.validations, labelRoutingCode: true }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
                this.props.isValid(this.validateRequiredInputs());
            }
        );
    }

    handleUpdateDescription = (event) => {
        this.setState({
            localAccount: { ...this.state.localAccount, Description: event.target.value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
            }
        );
    }
    
    search(value){     
        this.setState({
            localAccount: { ...this.state.localAccount, BankName: value }
        },
            () => {
                this.props.getLocalAccount(this.state.localAccount);
                this.props.isValid(this.validateRequiredInputs());
            }
        );
    }

    ValidateIban = (event) => {     
        let accountNo = this.state.localAccount.AccountNo;
        validateIban(accountNo).then(
            (json) => {
                if(json.httpStatusCode===200){
                    let message = json.Message;
                    this.setState({showAlert: true, alertMessage: message, alertTitle: 'Validate IBAN'});
                }
            });
    }
    
    ValidateIBAN = (event) => {     
        let accountNo = this.state.localAccount.AccountNo;
        validateIBAN(accountNo).then(
            (json) => {
                if(json.httpStatusCode===200){
                    var bankAccount = json.bankAccounts;
                   
                    const newLocalAccount = { ...this.state.localAccount, NeedsIban: false, AccountNo: accountNo, 
                        BankID: bankAccount.BankID, BankName: bankAccount.BankName, BranchID: bankAccount.BankBranchID,
                        CountryID: bankAccount.CountryID, CountryIDName: bankAccount.CountryName, Swift: bankAccount.Swift,
                        Currency: bankAccount.AccountCurrencyID, CurrencyName: bankAccount.AccountCurrencyID };
                    this.filterBanksForIban(newLocalAccount.CountryID, newLocalAccount.BankID);
                    this.setState({
                        IbanLoaded: true, showAlert: true, alertMessage: "This IBAN is valid.", alertTitle: 'Validate IBAN', 
                        localAccount: newLocalAccount, NeedsIban: false, DisableControls: true}
                    );
                    this.props.getLocalAccount(newLocalAccount);
                    this.validateIbanCurrency(newLocalAccount.Currency);
                    this.props.isValid(this.validateRequiredInputs());
                } else {
                    let message = json.Message;
                    this.setState({showAlert: true, alertMessage: message, alertTitle: 'Validate IBAN'});
                }
        });
            /*const newLocalAccount = { ...this.state.localAccount, NeedsIban: false, AccountNo: '111222333', 
                        CountryID: 'ALB', ABANo: '3311', Swift: '551166', BankName: 'Raiffeisen Bank',
                        BankID: 1005217, BranchID: 1006343, Currency: 'ALL' };
            //this.filterBanksByCountry(newLocalAccount.CountryID);
            //this.getBranches(newLocalAccount.BankID, newLocalAccount.CountryID);
            this.filterBanksForIban(newLocalAccount.CountryID, newLocalAccount.BankID);
            this.setState({
                IbanLoaded: true, showAlert: true, alertMessage: "This IBAN is valid.", alertTitle: 'Validate IBAN', 
                localAccount: newLocalAccount, NeedsIban: false, DisableControls: false}
            );
            this.props.getLocalAccount(newLocalAccount);
            this.validateIbanCurrency(newLocalAccount.Currency);*/
    }

    validateIbanCurrency = async (currency) => {
        let type = "Payment";
        if (this.props.isBeneficiary != null) {
            if (this.props.isBeneficiary) {
                type = "Payment";
            } else {
                type = "Settlement";
            }
        }
        const jsonResponseGetCurrenciesByCustomer = await getCurrenciesByCustomer(this.props.customerID);
        var listCurrenciesByCustomer = jsonResponseGetCurrenciesByCustomer != null ? jsonResponseGetCurrenciesByCustomer.currencies : null;
        var listCurrencyOfType = listCurrenciesByCustomer != null ? listCurrenciesByCustomer.filter(currency => currency.Type === type) : [];
        var isValidCcy = false;
        listCurrencyOfType.forEach(element => {
            if (element.CurrencyID === currency) {
                isValidCcy = true;
            }
        });
        this.props.getValidCurrency(isValidCcy);
        this.props.isValid(this.validateRequiredInputs());
    }

    NeedsIban(country){           
        needsIban(country).then(
            (value) => {
                if(value !== undefined && value.needsIban !== undefined){
                    let disableControls = false;
                    if(value.needsIban === true){
                        disableControls = true;
                    }
                    this.setState({ NeedsIban: value.needsIban, DisableControls: disableControls });
                }
            }
        );
    }

    closeAlert = () => {
        this.setState({showAlert: false, alertMessage: '', alertTitle: ''});
    }
    
    closeModal = () =>
    {
        this.setState({ showModal: false });
    }

    addBank = () => {
        this.setState({
            showModal: true,
            modalTitle: 'Add Bank'
        });
    }

    addBranch = () =>{
        if(this.state.localAccount.BankID > 0){
            this.setState({
                showModal: true,
                modalTitle: 'Add Branch'
            });
        }        
    }

    updatePage = () => {        
        this.setState({ loading: true},
            () => {
                getListOfBanks(false).then( // SWIFT
                    (json) => {
                        for (var i = 0; i < json.banks.length; i++) {
                            json.banks[i].value = json.banks[i].BankID;
                            json.banks[i].name = json.banks[i].Name;
                        }
                        this.setState({ allBankSwifts: json.banks },
                            () => {
                                getListOfBanks(true).then( // BANKS
                                    (json) => {
                                        for (var i = 0; i < json.banks.length; i++) {
                                            json.banks[i].value = json.banks[i].BankID;
                                            json.banks[i].name = json.banks[i].Name;
                                        }
                                        this.setState({ allBanks: json.banks },
                                            () => {
                                                getAllBranches().then(
                                                    (json) => {
                                                        if (json !== undefined) {
                                                            if (json.branches !== undefined) {
                                                                for (var i = 0; i < json.branches.length; i++) {
                                                                    json.branches[i].value = json.branches[i]["Branch ID"];
                                                                    json.branches[i].name = json.branches[i].Branch;
                                                                }
                                                                this.setState({ allBranches: json.branches },
                                                                    () => {
                                                                        this.props.isValid(this.validateRequiredInputs())
                                                                        this.updateInputs()
                                                                    });
                                                            }
                                                        }
                                                    }
                                                )
                                            });
                                    }
                                )
                            });
                    }
                )

            });
        this.setState({loading:false});
    }

    updateModelBankBranch = () =>{
        this.setState({ loading: true},
            () => {
                getAllBranches().then(
                    (json) => {
                        if (json !== undefined) {
                            if (json.branches !== undefined) {
                                for (var i = 0; i < json.branches.length; i++) {
                                    json.branches[i].value = json.branches[i]["Branch ID"];
                                    json.branches[i].name = json.branches[i].Branch;
                                }
                                this.setState({ allBranches: json.branches },
                                    () => {
                                        this.props.isValid(this.validateRequiredInputs())
                                        this.updateInputs()
                                    });
                            }
                        }
                    }
                )
            });
        this.setState({loading:false});
    }

    render() {
        return (
            <div>
                <ul id="accordion-" uk-accordion="multiple: true">
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">Main Account</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="local-acount">
                                <form className="uk-form-horizontal">
                                    <div className="uk-grid">
                                        <div className="uk-grid">
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.formLabel}>Country</label>
                                                <div className="uk-margin" style={style.inputStyle}>
                                                    <DynamicSelect className="uk-select" objValue={{ value: this.state.localAccount.CountryID, name: this.state.localAccount.CountryIDName }} getValue={this.getCountryValue} placeholder={"Select an Item..."} data={this.state.dropdowns.countryList} id="local-account-country" 
                                                    disabled={this.props.customerInfoUsedForBene}/>
                                                    {/* <DynamicSearchSelect
                                                        className='uk-select'
                                                        id="form-country"
                                                        placeholder="Select a country"
                                                        searchFunction={this.searchCountry.bind(this)}
                                                        getValue={this.getCountryValue}
                                                        data={this.state.dropdowns.countryList}
                                                        objValue={{ value: this.state.localAccount.CountryID, name: this.state.localAccount.CountryIDName }}
                                                    /> */}
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.boldLabel}>Bank (*) <a onClick={this.addBank}>Add Bank</a></label>
                                                <div className="uk-margin" style={style.inputStyle}>
                                                    {/*<DynamicSelect className={this.formatValidSelect(this.state.validations.bank)} objValue={{ value: this.state.localAccount.BankID }} getValue={this.handleGetValueBank} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banks} id="form-banks2" />*/}
                                                    <DynamicSearchSelect className={this.formatValidSelect(this.state.validations.bank)} disabled={this.state.DisableControls || this.props.customerInfoUsedForBene} objValue={{ value: this.state.localAccount.BankID, name: this.state.localAccount.BankName, type: 'Bank' }} getValue={this.handleGetValueBank} placeholder={"Select an Item..."} searchFunction={this.search.bind(this)} data={this.state.dropdowns.banks} id="form-banks" />
                                                    {/*<button className="uk-button uk-button-green uk-margin-right" type="button" onClick={this.addBank}>Add Bank</button>*/}
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">SWIFT</label>
                                                <div className="uk-margin" style={style.inputStyle}>
                                                    <input className="uk-input" type="text" disabled={this.state.DisableControls || this.props.customerInfoUsedForBene} placeholder="" value={this.state.localAccount.Swift} onChange={this.handleUpdateSwift} id="form-banks-swift" />
                                                    {/* <DynamicSelect className="uk-select" objValue={{ value: this.state.localAccount.BankID }} disabled={this.state.DisableControls} getValue={this.handleGetValueBankSwift} placeholder={"Select an Item..."} listHasPlaceholder={false} data={this.state.dropdowns.banksSwift} id="form-banks-swift" /> */}
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Bank Branch <a style={{ visibility: "show" }} onClick={this.addBranch}>Add Branch</a></label>
                                                <div className="uk-margin" style={style.inputStyle}>
                                                    <DynamicSelect className="uk-select" objValue={{ value: this.state.localAccount.BranchID }} disabled={this.state.DisableControls || this.props.customerInfoUsedForBene} getValue={this.handleGetValueBranch} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.branches} id="form-branches" />
                                                </div>
                                            </div>
                                            {/* <div className="uk-width-1-2 uk-form-stacked" style={{visibility: "hidden"}}>
                                                <label className="uk-form-label"></label>
                                                <div className="uk-margin" style={style.inputStyle}>
                                                </div>
                                            </div> */}
                                            <div className="uk-width-1-1 uk-form-stacked uk-margin-small-top uk-margin-small-bottom">
                                                <hr></hr>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.boldLabel}>{this.state.labelAccountNumber.value} (*)</label>
                                                <div className="uk-margin" style={style.inputStyle}>
                                                <table width="100%">
                                                        <tr>
                                                            <td width="100%">
                                                                <input className={this.formatValidInput(this.state.validations.accountNo)} title={this.state.labelAccountNumber.validationMessage}  pattern={this.state.labelAccountNumber.regex} type="text" placeholder="" value={this.state.localAccount.AccountNo} onChange={this.handleUpdateAccountNumber} 
                                                                disabled={this.props.customerInfoUsedForBene}/>
                                                            </td>
                                                <td>
                                                                {this.state.NeedsIban && !this.props.customerInfoUsedForBene && <button className="uk-button uk-button-green uk-float-right" type="button" onClick={this.ValidateIBAN}>Translate</button>}
                                                            </td>
                                                        </tr>
                                                            </table>
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label" style={style.boldLabel}>Currency (*)</label>
                                                <div className="uk-margin" style={style.inputStyle}>
                                                    <DynamicSelect className={this.formatValidSelect(this.state.validations.currency)} objValue={{value: this.state.localAccount.Currency}} getValue={this.handleGetValueCurrency} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.currencies} id="form-currencies" 
                                                    disabled={this.props.customerInfoUsedForBene}/>
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">SWIFT / BIC</label>
                                                <div className="uk-margin" style={style.inputStyle}>
                                                    <input disabled={true} className="uk-input" type="text" /*disabled={this.state.DisableControls}*/ placeholder="" value={this.state.localAccount.Swift} onChange={this.handleUpdateSwift} 
                                                />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked" />
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">{this.state.labelInstitution.value}</label>
                                                <div className="uk-margin" style={style.inputStyle}>
                                                    <input className="uk-input" type="text" disabled={this.state.DisableControls || this.props.customerInfoUsedForBene} pattern={this.state.labelInstitution.regex} title={this.state.labelInstitution.validationMessage} placeholder="" value={this.state.localAccount.ABANo} onChange={this.handleUpdateABANo} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">{this.state.labelRoutingCode.value}</label>
                                                <div className="uk-margin" style={style.inputStyle}>
                                                    <input className="uk-input" type="text" disabled={this.state.DisableControls || this.props.customerInfoUsedForBene} pattern={this.state.labelRoutingCode.regex} title={this.state.labelRoutingCode.validationMessage} placeholder="" value={this.state.localAccount.TransitNo} onChange={this.handleUpdateTransitNo} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked">
                                                <label className="uk-form-label">Description</label>
                                                <div className="uk-margin" style={style.inputStyle}>
                                                    <input className="uk-input" type="text" disabled={this.state.DisableControls || this.props.customerInfoUsedForBene} placeholder="" value={this.state.localAccount.Description} onChange={this.handleUpdateDescription} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked" />
                                            <div className="uk-width-1-2 uk-form-stacked uk-margin">
                                                <label className="uk-form-label"><input className="uk-checkbox" disabled={this.state.DisableControls || this.props.customerInfoUsedForBene} value={this.state.localAccount.DefaultAccount} checked={this.state.localAccount.DefaultAccount} onChange={this.handleUpdateDefaultAccount} type="checkbox" /> Default Account</label>
                                            </div>
                                            <div className="uk-width-1-2 uk-form-stacked uk-margin">
                                                <label className="uk-form-label" style={{width:70}}>Status</label>
                                                <label className="uk-margin-right"><input className="uk-radio" type="radio" id="status-active" name="radio1" value={"Active"} disabled={this.state.DisableControls || this.props.customerInfoUsedForBene} checked={this.state.localAccount.Active === "Active"} onChange={this.handleUpdateStatus} /> Active</label>
                                                <label className=""><input className="uk-radio" type="radio" disabled={this.state.DisableControls || this.props.customerInfoUsedForBene} id="status-inactive" name="radio1" value={"Inactive"} checked={this.state.localAccount.Active === "Inactive"} onChange={this.handleUpdateStatus} /> Inactive</label>
                                            </div>

                                            {/*
                                                this.state.localAccount.CreatedBy !== "" && this.state.localAccount.CreatedBy !== undefined ?
                                                    <div className="uk-width-1-2 uk-form-stacked">
                                                        <p>Created by {this.state.localAccount.CreatedBy} on {this.state.localAccount.CreatedOn}</p>
                                                    </div>
                                                    : ""
                                            }
                                            <div className="uk-width-1-2 uk-form-stacked">
                                            </div>
                                            {
                                                this.state.localAccount.UpdatedBy !== "" && this.state.localAccount.UpdatedBy !== undefined ?
                                                    <div className="uk-width-1-2 uk-form-stacked">
                                                        <p>Updated by {this.state.localAccount.UpdatedBy} on {this.state.localAccount.UpdatedOn}</p>
                                                    </div>
                                                    : ""
                                            */}
                                        </div>                                 
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                </ul>

                <Dialog
                    open={this.state.showModal} 
                    onClose={this.closeModal}
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                >
                    <DialogTitle id="customized-dialog-gwlatr-title" onClose={this.closeModal}>
                        {this.state.modalTitle}
                    </DialogTitle>
                    <DialogContent dividers>
                        {this.state.modalTitle=='Add Bank' && <BanksForm updateFunction={this.updatePage} selectedBank={this.state.selectedBank}/>}
                        {this.state.modalTitle=='Add Branch' && <BankBranchForm updatePage={this.updateModelBankBranch} selectedBank={{ BankID: this.state.localAccount.BankID, BankName: this.state.localAccount.BankName }}/>}
                    </DialogContent>
                </Dialog>

                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert}/>
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}