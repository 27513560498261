import React from 'react';

import NumberInput from '../../shared/NumberInput';

import { getNostroBankAccountsForFunds } from '../../../networking/NetworkingBankAccounts';
import { getTradingInformationByCustomerID } from '../../../networking/NetworkingCustomers';

import { formatToCurrencyWithScale, getScaleByCurrency, roundAmountByCurrency } from '../../../helpers/FormatHelper';

import { getStyle, notFilledInputClass, regularInputClass } from '../../../styles/styles';

import { Dialog, DialogContent } from '@material-ui/core';
import DynamicSelectWithSearch from '../../shared/DynamicSelectWithSearch';

var style = getStyle();

export default class WizardIncomingPaymentPopup extends React.Component {

    state = {
        payments: [],
        nostroAccounts: [],
        totalInput: 0,
        validTotalInput: true,
        customerDefaultIncomingNostroBankId: 0,
        btnAddDisabled: false, 
        message:''
    }

    componentDidMount() {        
        this.loadNostroBankAccounts(this.props.buyCurrency)
        this.loadDefaultIncomingNostroBank(this.props.customerId)
        this.setState({ payments: (this.props.incomingFunds || []).filter(x => x.Status !== 'Void') }, () => this.setDefaultRow())
    }

    componentDidUpdate(previousProps) {

        if (previousProps.buyCurrency !== this.props.buyCurrency) {
            this.loadNostroBankAccounts(this.props.buyCurrency)
        }
        if (previousProps.incomingFunds !== this.props.incomingFunds) {
            this.setState({ payments: this.props.incomingFunds || [] }, () => {
                this.loadNostroBankAccounts(this.props.buyCurrency)
            })
        }
        if (previousProps.customerId !== this.props.customerId) {
            this.loadDefaultIncomingNostroBank(this.props.customerId)
        }

        if (previousProps.buyAmount !== this.props.buyAmount && this.state.payments.length === 0) 
        {
            this.setDefaultRow();
        }
    }

    loadNostroBankAccounts(currencyId) {
        if (currencyId !== '') {
            getNostroBankAccountsForFunds(currencyId, this.props.customerId, false).then(
                (response) => {                    
                    if (response !== undefined && response.nostroBankAccounts !== undefined) {
                        let nostroAcounts = response.nostroBankAccounts;
                        nostroAcounts.forEach(element => {
                            element.value = element.BankAccountID;
                            element.name = element.BankName;
                        });
                        this.setState({ nostroAccounts: nostroAcounts }, () => this.setDefaultRow())
                    } else {
                        this.setState({ nostroAccounts: [] }, () => this.setDefaultRow());
                    }
                }
            )
        }
    }

    loadDefaultIncomingNostroBank(customerID) {
        getTradingInformationByCustomerID(customerID).then(
            (value) => {
                if (value != null) {
                    if (value.DefaultIncomingNostroBankID !== undefined && value.DefaultIncomingNostroBankID !== '') {
                        this.setState({ customerDefaultIncomingNostroBankId: value.DefaultIncomingNostroBankID },
                            () => this.setDefaultRow())
                    }
                }
            }
        )
    }

    addPayment = () => {
        let existingPayments = this.state.payments
        let newPaymentAmount = 0        
        let paymentAmounts = existingPayments.map(x => Number(x.Amount) || 0)

        let paymentAmountTotal = Number(paymentAmounts.reduce((acc, currentValue) => { return acc + currentValue }, 0))

        if (Number(this.props.buyAmount) > paymentAmountTotal) {
            newPaymentAmount = Number(this.props.buyAmount) - paymentAmountTotal
        }        
        newPaymentAmount = Number(roundAmountByCurrency(newPaymentAmount, this.props.buyCurrency))        
        existingPayments.push({ Amount: newPaymentAmount, NostroAccount: 0 })
        this.setState({ payments: existingPayments }, () => this.updateRunningTotals())
    }

    setDefaultRow() {

        let reloadDefault = false
        let payments = this.state.payments || []
        
        if (payments.length === 0 && this.props.buyAmount > 0) {
            payments.push({ Amount: this.props.buyAmount, NostroAccount: 0 })
            reloadDefault = true;
        } else if (payments.length === 1 && (payments[0].IncomingFundsID || 0) === 0 && this.state.customerDefaultIncomingNostroBankId !== 0) {
            let defaultAccount = this.state.nostroAccounts.filter(x => x.BankID === this.state.customerDefaultIncomingNostroBankId)
            if (defaultAccount !== undefined && defaultAccount.length > 0 && defaultAccount[0].BankAccountID > 0) {
                payments = []
                payments.push({ Amount: this.props.buyAmount, NostroAccount: defaultAccount[0].BankAccountID })
                reloadDefault = true;
            }
        }
        if (reloadDefault) {
            this.setState({ payments: payments }, () => this.updateRunningTotals())
        } else {
            this.updateRunningTotals()
        }
    }

    savePayments = () => {

        let inputNoValid = false;
        let payments = this.state.payments
        payments.forEach(x => {
            if (x.Amount <= 0) {
                x.amountValid = false
                inputNoValid = true;
            } else {
                x.amountValid = true
            }

            if (x.NostroAccount === undefined || x.NostroAccount <= 0) {
                x.nostroValid = false
                inputNoValid = true;
            } else {
                x.nostroValid = true
            }
        })

        if (inputNoValid) {
            this.setState({ payments: payments})
            return
        }

        let amount = Number(this.state.payments.reduce((x, y) => x + y.Amount, 0))        
        
        let message = ''

        let paymentsAux = payments.filter(
            (obj, index) =>
            payments.findIndex((item) => item.NostroAccount === obj.NostroAccount) === index
          );
        
          if(payments.length !== paymentsAux.length)
          {
            message = 'You have selected the same account more than once';
            this.setState({ validTotalInput: false, totalInput: amount, message: message });
            return;
          }
        if (roundAmountByCurrency(amount, this.props.buyCurrency) !== roundAmountByCurrency(this.props.buyAmount, this.props.buyCurrency)) {
            message = `The total input ${formatToCurrencyWithScale(amount, this.props.buyCurrency)} does not match the buy amount ${formatToCurrencyWithScale(this.props.buyAmount, this.props.buyCurrency)}`;
            this.setState({ validTotalInput: false, totalInput: amount, message: message })
        }
        else {            
            this.setState({ validTotalInput: true, totalInput: amount });            
            this.props.updateIncomingFunds(this.state.payments);
            this.props.close();
        }
    }

    formatValidSelect(valid) {
        return valid ? '' : 'error';
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    removePaymentFromTable = (index) => {
        let existingPayments = this.state.payments
        existingPayments.splice(index, 1)        
        this.setState({ payments: existingPayments }, () => this.updateRunningTotals())
    }

    updateRunningTotals = () => {
        let existingPayments = this.state.payments
        let runningTotal = 0
        let btnAddDisabled = false
        existingPayments.forEach(x => {
            runningTotal = Number(x.Amount || 0) + Number(runningTotal || 0)            
            x.runningTotal = Number(roundAmountByCurrency(runningTotal, this.props.buyCurrency))  
            if(Number(x.Amount || 0) === 0 || runningTotal >= this.props.buyAmount)
            {
                btnAddDisabled = true
            }          
        });        

        this.setState({ payments: existingPayments, btnAddDisabled: btnAddDisabled })
    }
 search = (query) =>{
    let a = this.state.nostroAccounts;
    this.setState({nostroAccounts:a}) 
 }
    render() {

        let paymentsHeader = []
        paymentsHeader.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={'rt-h1'}>Incoming ID</th>)
        paymentsHeader.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={'rt-h2'}>Amount</th>)
        paymentsHeader.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={'rt-h3'}>Nostro Account</th>)
        paymentsHeader.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={'rt-h4'}>Running Total</th>)
        paymentsHeader.push(<th style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'center', fontSize: '0.75em' }} className="uk-table-shrink" key={'rt-h5'}>Action</th>)

        let paymentsRows = this.state.payments.map((x, i) => <tr key={"tr" + i}>
            <td key={"td1" + i} width={'10%'}>
                {x.IncomingFundsID}
            </td>
            <td> <NumberInput key={'ni' + i}
                type="Currency"
                scale={getScaleByCurrency(this.props.buyCurrency)}
                className={this.formatValidInput(x.amountValid !== undefined ? x.amountValid : true/* || true*/)}
                value={x.Amount}
                onChange={(event) => {
                    x.Amount = Number(event.target.value)
                    this.updateRunningTotals()
                }}
            /></td>
            <td key={"td2" + i} width={'50%'}>
                <DynamicSelectWithSearch key={'ds' + i}
                    id={'ds' + i}
                    className={this.formatValidSelect(x.nostroValid !== undefined ? x.nostroValid : true)}
                    data={this.state.nostroAccounts}
                    placeholder='Select an Account...'
                    getValue={(obj) => {
                        if (obj && obj.value !== undefined) {
                            x.NostroAccount = obj.value
                        }
                    }}
                    objValue={{ value: x.NostroAccount }}
                /></td>
            <td key={"td3" + i}>
                {formatToCurrencyWithScale(x.runningTotal, this.props.buyCurrency)}
            </td>
            <td key={"td4-actions" + i} style={style.alignCenter} width={'10%'}>
                <div className="uk-button-group">
                    <i key={"delete" + i} className="fa fa-trash" onClick={() => this.removePaymentFromTable(i)}
                        style={{
                            fontSize: '1.25em', verticalAlign: 'middle', cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px',
                            visibility: Number(x.IncomingFundsID) > 0 ? 'hidden' : 'visible'
                        }} />
                </div>
            </td>

            </tr>)

        let paymentTable =
            <div className="">
                <table key={'paymentTable'} className="uk-table uk-table-responsive uk-table-hover uk-table-divider border-table uk-table-striped"
                >
                    <thead>
                        <tr>{paymentsHeader}</tr>
                    </thead>
                    <tbody>{paymentsRows}</tbody>
                </table>
            </div>

        let dialog = <Dialog open={this.props.open} disableBackdropClick={true} disableEscapeKeyDown={true} onClose={this.props.close} maxWidth={'lg'} fullWidth={true}>
            <DialogContent dividers>
                <div className="">
                    <h5 className="uk-heading-divider">Total to be paid by customer: {formatToCurrencyWithScale(this.props.buyAmount, this.props.buyCurrency)}  {this.props.buyCurrency}</h5>
                    <div className="uk-grid">
                        <div className="uk-width-1-1">
                            <button className="uk-button uk-button-green" onClick={this.addPayment} disabled={this.state.btnAddDisabled}>Add Payment</button>
                        </div>
                        <div className="uk-width-1-1" style={{marginTop:'5px'}}>
                            {paymentTable}
                        </div>
                        <div className="uk-width-1-1" hidden={this.state.validTotalInput}>
                            <div className="uk-alert-danger" uk-alert>{this.state.message}</div>
                        </div>
                        <div className="uk-margin uk-width-1-1">
                            <button className="uk-button uk-button-green" onClick={this.savePayments}>Save Payments</button>
                            <button className="uk-button uk-button-green" onClick={this.props.close}>Close</button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>

        return dialog
    }
}