import React from 'react';

import {myHistoricalPerformance, traderPerformance} from '../../../networking/NetworkingWidget';

import { Column } from '@ant-design/charts';
import { getActiveTradersMin } from '../../../networking/NetworkingTrader';
import DynamicSelect from '../../shared/DynamicSelect';
import { getStyle, regularSelectClass } from '../../../styles/styles';
var style = getStyle();
export default class TraderPerformance extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.state = { 
            traderPerformanceListAll   : [],
            traderPerformanceList   : [],
            //Checks
            traders                : [],
            loadingData         : false,
            traderID : 0
        };
    }

    componentDidMount()
    {
        this.load();
    }

    load = async() =>{
        let jsonTraders = await getActiveTradersMin();

        let selectedTrader = -1;
        if(jsonTraders)
        {
            
            for (var i = 0; i < jsonTraders.traders.length; i++) {
                jsonTraders.traders[i].name = jsonTraders.traders[i].Trader;
                jsonTraders.traders[i].value = jsonTraders.traders[i].TraderID;
            }
            if(jsonTraders.traders.length > 0)
            {
                selectedTrader = jsonTraders.traders[0].TraderID;
            }
            this.setState({ traders: jsonTraders.traders, traderID: selectedTrader });
        }

        this.setState({
            loadingData: true
        }, async () =>{
            let response = await traderPerformance(-1);
                if(response)  {
                    if(response !== undefined)
                    {
                        console.log("traderPerformance: " + JSON.stringify(response));
                        console.log(response);
                        let allTraders = response.traders
                        let filteredTraders = [];

                        if(selectedTrader > 0 && allTraders.length > 0)
                        {
                            filteredTraders = allTraders.filter(t => t.TraderID == this.state.traderID);
                        }

                        this.setState({
                            loadingData         : false,
                            traderPerformanceListAll   : allTraders,
                            traderPerformanceList: filteredTraders
                        });
                    }
                }
            
        }
    );
    }


    getColorByMonth = (month) => 
    {
        var color = '#8ac43f';
        return color;
    }

    handleUpdateTrader = (value) => {
        this.setState({loading:true, traderID: value.value }, () =>{
            let filteredTraders = [];
            if(value.value > 0 && this.state.traderPerformanceListAll.length > 0)
            {
                filteredTraders = this.state.traderPerformanceListAll.filter(t => t.TraderID == value.value );
            }

            this.setState({
                loadingData         : false,
                traderPerformanceList: filteredTraders
            });
        }
        );
    }

    render = () => {

        var config = {
            data        : this.state.traderPerformanceList,
            xField      : 'Month',
            yField      : 'Profit',
            label: 'Profit',
            color: '#8ac43f',
            legend: false,
        };

        var classNameSelect = regularSelectClass;

        return(
            <div
                style={{
                    width: this.props.width,
                    height: this.props.height,
                    //overflow: 'auto',
                    //backgroundColor: 'yellow',
                    flexDirection: 'column',
                    padding: 10,
                    //
                    display: 'flex',
                    alignItems: 'stretch',
                    Margin:'0 auto',
                    //borderRadius: '10px',
                    //border: '2px solid #E7E7E7',
                }}
            >
                <div
                    className ='title-widget'
                    style={{
                        display         : 'block',
                        textAlign       : 'center'
                    }}
                >
                    <span>Trader Performance</span>
                </div>
                <div className="uk-width-1-5 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-deal-dealType">Trader</label>
                        <div className="" style={style.inputStyle}>
                            <DynamicSelect className={classNameSelect} objValue={{ value: this.state.traderID }} getValue={this.handleUpdateTrader} placeholder={"Select a trader..."} listHasPlaceholder={false} data={this.state.traders} id="trader-list" />
                        </div>
                    </div>
            <div 
                style={{
                    width           : '100%', 
                    height          : '100%',
                    margin          : 0,
                    overflow        : 'hidden',
                    paddingTop      : 10,
                    paddingBottom   : 5
                }}
            >
                <Column 
                    {...config} 
                    style={{
                        height: '100%', 
                        width:'100%'
                    }}
                    loading={this.state.loadingData}
                />
            </div>
            
          </div>
        );

        
    }
}