import { genericCallWithBody, genericGetWithParameters, genericGetWithParametersNoEncrypted, genericDeleteWithParameters } from "./Networking";
import jwt from 'jsonwebtoken';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function sendForgotPasswordEmail(applicationName, userName){
    try {
        let params = {
            'applicationName': applicationName,
            'userName': userName
        };
        let response = await genericGetWithParametersNoEncrypted('User/SendForgotPasswordEmail', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function resetPassword(model) {
    try {
        let response = await genericCallWithBody('POST', "User/ResetPassword", model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getListOfUserActions() {
    try {
        let userID = await localStorage.getItem('UserID');
        let params = {
            'UserID': userID,
        };
        let response = await genericGetWithParameters('UserActions/Get',params);
        console.log('ACTIONS');
        console.log(response);
        return response;
    } catch (error) {
        console.error(error);
    }
}export async function getListOfBranch() {
    try {
        let response = await genericGetWithParameters('User/GetUserBranches_min', null);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getBusinessUnits(countryID) {
    try {
        let params = {
            'CountryID': countryID,
        };
        let response = await genericGetWithParameters('User/GetUserBusinessUnits_min',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getReportTo(appName) {
    try {
        let params = {"ApplicationName":appName,};
        let response = await genericGetWithParametersNoEncrypted('User/GetUserReportsTo',params)
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getAllUsers(appName) {
    try {
        let params = {"ApplicationName":appName,};
        let response = await genericGetWithParametersNoEncrypted('User/GetAllUsers',params)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveUser(model){
    try {
        console.log(model);
        let response = await genericCallWithBody('POST','User/CreateUser',model)
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getUsersRol(model){
    try {
        console.log(model);
        let response = await genericCallWithBody('POST','User/GetUsersByCustomTextAndRole',model)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getUsersByName(appName, username) {
    try {
        let params = {
            'ApplicationName': appName,
            'UserName': username
        };
        let response = await genericGetWithParametersNoEncrypted('User/GetUserByUserName',params)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function resetAnswers(userName){
    var method = 'PUT';
    var apiName = 'User/ResetAnswers';
    var request = {
        'ApplicationName': 'ForeignExchange',
        'UserName': userName
    }
    try {
        let response = await genericCallWithBody(method,apiName,request);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteUser(userId, userName){    
    try{
        let params = {
            'ApplicationName': 'ForeignExchange',
            'UserId': userId,
            'UserName': userName
        };
        let response = await genericDeleteWithParameters('User/Delete',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAdditionalInfoByUserID(appName, userID) {
    try {
        let params = {
            'ApplicationName': appName,
            'UserID': userID
        };
        let response = await genericGetWithParameters('User/GetAdditionalInfoByUserID',params)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getUsersByEntityType(entityType)
{
    try {
        let params = {
            EntityType: entityType
        };
        let response = await genericGetWithParameters('User/GetUsersByEntityType', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function generateTokenCodeSecurity(model) {
    try {
        let response = await genericCallWithBody('POST', 'Customer/GenerateTokenCode', model)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function validateTokenCodeSecurity(model) {
    try {
        console.log(model);
        let response = await genericCallWithBody('POST', 'Customer/ValidateTokenCode', model)
        return response;
    } catch (error) {
        console.error(error);
    }
}