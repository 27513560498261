import React from 'react';
import OnlineUserProfilesForm from './OnlineUserProfilesForm';
import OnlineUserProfilesDetailForm from './OnlineUserProfilesDetailForm';
import NewDynamicTable from '../../shared/NewDynamicTable';
import AlertBox from '../../shared/AlertBox';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getListOfOnlineUserProfile, deleteOnlineUserProfile } from '../../../networking/NetworkingOnlineUserProfile';
import { SearchOutlined } from '@ant-design/icons';
import AppContext from '../../../AppContext';
import { getStyle } from '../../../styles/styles';

import {Breadcrumb} from 'antd';

let globalFunctions = {}


var style = getStyle();

const itemsPerPage = getDefaultPagingNumberOfItems();
export default class OnlineUserProfilesScreen extends React.Component{
    static contextType = AppContext;
    state = {
        clearProfile: false,
        newSearch: false,
        onlineUserProfileId: 0,
        onlineUserProfileList: [],
        pages: 1,
        readyToDelete: {},
        selectedOnlineUserProfile: {},
        step:1,
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        totalPages: 0
    }

    componentDidMount(){
        globalFunctions = this.context;
        globalFunctions.startLoading();
        getListOfOnlineUserProfile('').then(
            (json) => {
                var helper = this.transformHeaders(json.onlineUserProfiles);
                var totalCount = json.onlineUserProfiles.length;
                var pages = Math.ceil(totalCount/itemsPerPage);
                this.setState({onlineUserProfileList: helper, totalPagesStructure: pages, newSearch: false});
                globalFunctions.finishLoading();
            }
        );
    }

    transformHeaders(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({
                'Profile Name': list[i]['OnlineUserProfileName'],
                'OnlineUserProfileID': list[i]['OnlineUserProfileID']
            });
        }
        return helper;
    }
  
    changePage = (value) =>{
        this.setState({pages: value, newSearch: false});
    }

    onClickStepTab(value){
        this.setState({step: value});
        globalFunctions.startLoading();
        getListOfOnlineUserProfile('').then(
            (json) => {
                var helper = this.transformHeaders(json.onlineUserProfiles);
                var totalCount = json.onlineUserProfiles.length;
                var pages = Math.ceil(totalCount/itemsPerPage);
                this.setState({onlineUserProfileList: helper, totalPagesStructure: pages, newSearch: false});
                globalFunctions.finishLoading();
            }
        );
    }

    handleRowClick(obj){
        console.group("FeeStructure handleRowClick")
        console.log(obj);
        console.groupEnd();
        /*getFeeStructureByID(obj['FeeStructureID']).then(
            (value)=>{
                console.log(value.feeStructure[0]);
                this.setState({selectedFeeStructure: value.feeStructure[0],selectedFeeStructureDetail: value.feeStructure[0]});
            }
        );*/
    }
    
    isClearForm = () =>{
        this.setState({selectedOnlineUserProfile:{}});
    }
    outClearFee(value){
        this.setState({clearProfile: value});
    }

    updateFeeStructureDetailsTable(value){
        this.updateFeeStructureDetailList(value);
    }

    updateOnlineUserProfilesDetailList(value){
        if(value===undefined || value===''){
            this.setState({onlineUserProfileList: []});            
        }else{
            getListOfOnlineUserProfile('').then(
                (json) => {
                    if(json.onlineUserProfiles !== undefined){
                        var helper = this.transformHeaders(json.onlineUserProfiles);
                        var totalCount = json.onlineUserProfiles.length;
                        var pages = Math.ceil(totalCount/itemsPerPage);
                        this.setState({onlineUserProfileList: helper, totalPagesStructure: pages, newSearch: false});
                    } else{
                        this.setState({onlineUserProfileList: []});
                    }
                }
            );            
        }
    }
    
    handleDeleteClick(obj){
        console.group("OnlineUserProfiles handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDelete: obj});
    }
    
    closeDeleteAlert(){
        this.setState({showDeleteAlert: false, readyToDelete: {}});
    }

    openDeleteAlert(){
        this.setState({showDeleteAlert: true});
    }

    okSuccessAlert(){
        this.setState({showSuccessDeleteAlert: false});
    }
    
    yesDeleteAlert(){
        deleteOnlineUserProfile(this.state.readyToDelete['OnlineUserProfileID']).then(
            (value)=>{
                if(value.httpStatusCode!==200){
                    this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true,alertTitle:"Error", alertMessage: value.Message});
                }else{
                    this.setState({showDeleteAlert: false, showSuccessDeleteAlert: true,alertTitle:"Success", alertMessage:"Online user profile successfully removed.",clearProfile: true},()=>this.updateTable());
                }
            }
        );
    }
    
    updateTable(){
        getListOfOnlineUserProfile('').then(
            (json) => {
                var helper = this.transformHeaders(json.onlineUserProfiles);
                this.setState({onlineUserProfileList: helper});
            }
        );
    }

    handleDetailClick = (obj) => {
        this.setState({selectedOnlineUserProfile: obj, step: 2});
    }

    render(){
        var stepScreen = [];
        var breadcrumb = [];
        var classNameStep1, classNameStep2 = "";
        
        switch(this.state.step){
            case 1:
                classNameStep1 = "uk-active";
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Online User Profile Maintenance</Breadcrumb.Item>
                );
                stepScreen.push(
                    <OnlineUserProfilesForm key="online-user-profile-form" isClearForm = {this.isClearForm} updateTable={this.updateTable.bind(this)} clearForm={this.state.clearProfile} outClearForm={this.outClearFee.bind(this)}/>,
                    <NewDynamicTable key="online-user-profile-screen"
                                    useIcons={true}
                                    data={this.state.onlineUserProfileList}
                                    enableClick={true}
                                    hiddenHeaders={['OnlineUserProfileID', 'onlineUserProfilesDetails']} 
                                    clickFunction={this.handleRowClick.bind(this)} 
                                    dynamicActions  = {[{name: 'Details', function: this.handleDetailClick, isIcon: true, icon: <SearchOutlined/>}]}
                                    newSearch={this.state.newSearch} 
                                    useDeleteButton={true} 
                                    deleteFunction={this.handleDeleteClick.bind(this)}
                                    usePaginator={true}
                                    changePage={this.changePage}
                                    numberOfPages={this.state.totalPages}
                                    pageNumber = {this.state.pages}
                                    numberPerPage = {getDefaultPagingNumberOfItems()}
                                    enableSortByHeader={true}/>,
                    <AlertBox id="delete-online-user-profile" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this record?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>,
                    <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.okSuccessAlert.bind(this)}/>
                );
                break;
            case 2:
                classNameStep2 = "uk-active";
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => {
                        this.onClickStepTab(1);
                    }}>Online User Profile Maintenance</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Online User Profile Detail - {this.state.selectedOnlineUserProfile['Profile Name']}</Breadcrumb.Item>
                );
                stepScreen.push(
                    <OnlineUserProfilesDetailForm key="online-user-profile-detail-form" selectedOnlineUserProfile={this.state.selectedOnlineUserProfile} profileSelectValue={this.updateOnlineUserProfilesDetailList.bind(this)}/>
                );
                break;
            default:
                classNameStep1 = "uk-active";
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Online User Profile Maintenance</Breadcrumb.Item>
                );
                stepScreen.push(     
                    <OnlineUserProfilesForm key="online-user-profile-form" isClearForm = {this.isClearForm} updateTable={this.updateTable.bind(this)} clearForm={this.state.clearProfile} outClearForm={this.outClearFee.bind(this)}/>,
                    <NewDynamicTable key="online-user-profile-screen"
                                    data={this.state.onlineUserProfileList}
                                    enableClick={true}
                                    hiddenHeaders={['OnlineUserProfileID', 'onlineUserProfilesDetails']} 
                                    clickFunction={this.handleRowClick.bind(this)} 
                                    dynamicActions  = {[{name: 'Details', function: this.handleDetailClick, isIcon: true}]}
                                    newSearch={this.state.newSearch} 
                                    useDeleteButton={true} 
                                    deleteFunction={this.handleDeleteClick.bind(this)}
                                    usePaginator={true}
                                    changePage={this.changePage}
                                    numberOfPages={this.state.totalPages}
                                    pageNumber = {this.state.pages}
                                    numberPerPage = {getDefaultPagingNumberOfItems()}
                                    enableSortByHeader={true}/>,
                    <AlertBox id="delete-online-user-profile" open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this record?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>,
                    <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.okSuccessAlert.bind(this)}/>
                );
                break;
        }
        return(
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        {breadcrumb}
                    </Breadcrumb>
                </div>
                {/*<h3 className="uk-heading-divider" style={style.titleStyle}>Online User Profiles Maintenance</h3>
                <ul className="uk-tab">
                    <li className={classNameStep1}><a onClick={this.onClickStepTab.bind(this, 1)}>Online User Profile Maintenance</a></li>
                    <li className={classNameStep2}><a onClick={this.onClickStepTab.bind(this, 2)}>Online User Profile Details</a></li>
                </ul>*/}
                {stepScreen}
            </div>
        );
    }
}