import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Space, Form, Input, InputNumber, Popconfirm, Select, Tag, Table, Typography, Tooltip } from 'antd';
import { PlusOutlined, SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AppContext from '../../../AppContext';
import Highlighter from 'react-highlight-words';
import AlertBox from '../../shared/AlertBox';
import { getStyle } from '../../../styles/styles';
import { deleteRateVolatilityPadding, getRateVolatilityPadding, saveVolatilityPadding } from '../../../networking/NetworkingRates';
import { getCurrencyList } from '../../../networking/NetworkingCurrencies';

const { Option } = Select;

const VolatilityPaddingTable = () => {
    const context = useRef(useContext(AppContext));
    const [updating, setUpdating] = useState(false);
    const [idNameColumn, setIDnameColumn] = useState("ID")
    const [form] = Form.useForm();
    //table
    const [currentID, setCurrentID] = useState(0);
    const [id, setID] = useState(0);
    const [dataTable, setDataTable] = useState([]);
    const isEditing = (record) => record[idNameColumn] === editingKey;
    const [editingKey, setEditingKey] = useState(0);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const [searchText, setSearchText] = useState('');
    const searchInput = useRef();
    const [currentPage, setCurrentPage] = useState();
    const [filteringSomething, setFilteringSomething] = useState(false);
    //data currencies
    const [currencyList, setCurrencyList] = useState([]);
    //Alert
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    //#region load data
    useEffect(()=>{
        const loadDataTable = async() =>{
            context.current.startLoading();
            const json = await getRateVolatilityPadding(0,0);
            if (json != null && json.result != null){
                let datalist = json.result;
                setDataTable(datalist);
            }
            context.current.finishLoading();
        }
        
        const loadCurrencyList = async() =>{
            const json = await getCurrencyList();
              if (json != null && json.currencies != null) 
              {
                let auxGP = json.currencies;
                setCurrencyList(auxGP);
              }
          }

        loadDataTable();
        loadCurrencyList();
    },[updating]);
    //#endregion load data

    //#region table
    const saveRecord = async (key) => {
        try {
            const row = await form.validateFields();          
            let UserID = localStorage.getItem('UserID');
            
            const newData = [...dataTable];
            const index = newData.findIndex((item) => key === item.ID);
            
            if (index > -1) 
            {
                const item = newData[index];
                const token = item.UpdateToken;
                let model = {
                PaddingID:  editingKey === '' ? row[idNameColumn] : editingKey,
                HomeCurrencyCode: row['Our Buy'],
                ForeignCurrencyCode: row['Our Sell'],
                MarkupFactor: row['Markup Factor'],
                PercentOrPoints: row['Percent or Points'],
                UserId: UserID,
                updateToken: token
            }  
            context.current.startLoading();
            const json = await saveVolatilityPadding(model);
            context.current.finishLoading();
            if (json != null) {
                if (json.httpStatusCode !== 200) {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.httpErrorMessage);
                    setUpdating(!updating);
                } else {
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Successfully saved.');
                    setUpdating(!updating);
                }
            }
            setEditingKey(0);
        }
        else
        {
            newData.push(row);
            setDataTable(newData);
            setEditingKey(0);
        }

        } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
        }
    };
    
    const editRecord = async (record) => {
        if (dataTable[0][idNameColumn] === 0) {
            let auxDataTable = [...dataTable];
            auxDataTable.shift();
            setDataTable(auxDataTable);
        }
        form.setFieldsValue({
          ...record
        });
        setEditingKey(record[idNameColumn]);
        setCurrentID(record[idNameColumn]);
    };
    
    const deleteRecord = async (record) => {
        context.current.startLoading();
        let UserID = localStorage.getItem('UserID');        
        let model = {
          "PaddingID"           : record['ID'],
          "UserId"              : UserID,
          "UpdateToken"         : record['UpdateToken']
        }      
        const deleteResponse = await deleteRateVolatilityPadding(model);
        context.current.finishLoading();
        if (deleteResponse != null) {
            if (deleteResponse.httpStatusCode !== 200) {
                setShowAlert(true);
                setAlertTitle('Error');
                setAlertMessage(deleteResponse.httpErrorMessage);
                setUpdating(!updating);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Successfully removed.');
                setUpdating(!updating);
            }
        }
    };
    
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref         = {(node) => {
                searchInput.current = node;
              }}
              placeholder = {`Search ${dataIndex}`}
              value       = {selectedKeys[0]}
              onChange    = {(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                width       : 188,
                marginBottom: 8,
                display     : 'block'
              }}
            />
            <Space>
              <Button 
                type    = "primary" 
                onClick = {() => handleSearch(selectedKeys, confirm, dataIndex)} 
                icon    = {<SearchOutlined />} 
                size    = "small" 
                style   = {{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                    handleReset(clearFilters);
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type    = "link"
                size    = "small"
                onClick = {() => {
                  confirm({ closeDropdown: false });
                  settingSearchStateValues(selectedKeys[0], dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current.select(), 100);
          }
        },
        render: (text) => (
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : ( text )
        )
    });
    
    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
        return <a>Previous</a>;
        }
        if (type === 'next') {
        return <a>Next</a>;
        }
        return originalElement;
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setFilteringSomething(true);
    };
    
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };
    
    const settingSearchStateValues = (value, col) => {
        setSearchText(value);
        setSearchedColumn(col);
        if(searchArray!==undefined && searchArray[col]!==undefined && value!==undefined){
            setSearchArray(...(searchArray[col] = value));
        }        
        setFilteringSomething(true);
    };
    
    const cancelEdit = () => {
        setEditingKey(0);
        if(dataTable[0][idNameColumn] === 0)
        {
        let auxDataTable = [...dataTable];
        auxDataTable.shift();
        setDataTable(auxDataTable);
        }
        setID(0);
    };
    
    const onChangePagination = (page, pageSize) => {
        setEditingKey(0);
        if(typeof page === 'number')
        {
        setCurrentPage(page);
        }
    };
    //#endregion table

    //#region mapping data
    const columns = [
        {
            title     : 'Our Buy',
            dataIndex : 'Our Buy',
            width     : '20%',
            editable  : true,
            sorter    : (a, b) => {
              return a['Our Buy'].localeCompare(b['Our Buy']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Our Buy')
        },
        {
            title     : 'Our Sell',
            dataIndex : 'Our Sell',
            width     : '20%',
            editable  : true,
            sorter    : (a, b) => {
                return a['Our Sell'].localeCompare(b['Our Sell']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Our Sell')
        },        
        {
            title     : 'Markup Factor',
            dataIndex : 'Markup Factor',
            width     : '20%',
            editable  : true,
            sorter    : (a, b) => {
                return a['Markup Factor'].localeCompare(b['Markup Factor']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Markup Factor')
        },
        {
            title     : 'Percent or Points',
            dataIndex : 'Percent or Points',
            width     : '30%',
            editable  : true,
            sorter    : (a, b) => {
                return a['Percent or Points'].localeCompare(b['Percent or Points']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Percent or Points')
        },
        {
            title     : 'Actions',
            dataIndex : 'Actions',
            align     : 'center',
            width     : '10%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                <span>
                    <a
                    onClick = {() => {saveRecord(record[idNameColumn])}}
                    style   = {{
                        marginRight: 8,
                    }}
                    >Save
                    </a>
                    <Popconfirm title="Sure to cancel?" onConfirm={cancelEdit}>
                    <a>Cancel</a>
                    </Popconfirm>
                </span>
                ) : (
                <div>
                    <Space size="middle">
                    <Tooltip title="Edit">
                    <Typography.Link disabled = {editingKey !== 0 ? true: (dataTable[0][idNameColumn] === 0) ? true: false}
                        onClick  = {() => editRecord(record)} >
                        <EditOutlined />
                    </Typography.Link>
                    </Tooltip>
                    <Popconfirm title = "Sure to delete?" onConfirm = {() => {deleteRecord(record)}}>
                    <Tooltip title="Delete">
                        <a disabled = {editingKey !== 0 ? true: (dataTable[0][idNameColumn] === 0) ? true: false} ><DeleteOutlined /></a>
                        </Tooltip>
                    </Popconfirm>
                    </Space>
                </div>            
                );
            }
        }
    ]
    
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        const getType = (record) => {
          let type = '';
          switch (col.dataIndex) 
          {
            case 'Markup Factor':
            {
              type = 'number';
              break;
            }
            case 'Our Buy':
            case 'Our Sell':
            {
              type = 'select-currencies';
              break;
            }
            case 'Percent or Points':
            {
              type = 'select-percent-points';
              break;
            }
            default:
            {
                type = 'text'
                break;
            }
          }
          return type;
        }
    
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: getType(record),
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
    });

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {  
        let inputNode = null;  
        if(editing)
        {
        switch (inputType) 
        {
            case 'number':
            {
                inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center'}}/>;
                break;
            }
            case 'select-currencies':
            {
                let allOptions = [];
        
                currencyList.forEach((valueList,index)=>{
                  allOptions.push(
                    <Option key = {valueList['CurrencyID']} value = {valueList['CurrencyID']}>{valueList['CurrencyID']}</Option>
                  );
                });

                inputNode = (
                <Select
                    showSearch
                    style               = {{ width: '100%' }}
                    placeholder         = "Select..."
                >
                    {allOptions}
                </Select>
                );
                break;
            }
            case 'select-percent-points':
            {
                let allOptions = [];
                allOptions.push(
                  <Option 
                        key   = 'PERCENT'
                        value = 'PERCENT'
                    >PERCENT</Option>
                )
                allOptions.push(
                  <Option 
                        key   = 'POINTS'
                        value = 'POINTS'
                    >POINTS</Option>
                )        
                inputNode = (
                <Select
                    showSearch
                    style               = {{ width: '100%' }}
                    placeholder         = "Select..."
                >
                    {allOptions}
                </Select>
                );
                break;
            }
            default:
            {
                inputNode = <Input style={{textAlign: 'center'}}/>;
                break;
            }
        }
        }

        let auxRules = [];

        if(dataIndex !== 'LookUpTableID')
        {
        auxRules = [
            {
            required: true,
            message : `Please Input ${title}!`,
            },
        ]
        }
  
        return (
        <td 
            key = {inputType + dataIndex} 
            id  = {inputType + dataIndex}
            {...restProps}
        >
            {editing ? (
            <Form.Item
                name  = {dataIndex}
                rules = {auxRules}
                style = {{
                margin: 0,
                }}
            >{inputNode}
            </Form.Item>
            ) : children
            }
        </td>
        );
    };
        
    const addNewRow = () => {
        let actual = [...dataTable];
        if (actual[0] != null && actual[0][idNameColumn] === 0) {
            return;
        } else {
            actual.unshift({
                'ID': 0,
                'Our Buy': '',
                'Our Sell': '',
                'Markup Factor': '',
                'Percent or Points': '',
                isNew: true
            });
            setDataTable(actual);
            setCurrentPage(1);
            form.setFieldsValue({
              'Our Buy': '',
              'Our Sell': '',
              'Markup Factor': '',
              'Percent or Points': '',
                ...actual
            });
            setCurrentID(0);
        }
    }
    //#endregion mapping data

    //#region alert box
    const closeAlert = () => {
        setShowAlert(false);
    };
    //#endregion alert box

    return(
        <div>
            <Form form={form} component={false}>
                <Table
                    components   = {{
                        body: {
                        cell: EditableCell,
                        },
                    }}
                    bordered
                    rowClassName = "editable-row"
                    size         = 'small'
                    dataSource   = {dataTable}
                    columns      = {mergedColumns}
                    pagination   = {{
                        onChange        : onChangePagination,
                        showQuickJumper : true,
                        itemRender      : itemRender,
                        current         : currentPage,
                        defaultCurrent  : 1,
                        defaultPageSize : 15,
                        pageSizeOptions : [10, 15, 20, 30, 40, 50],
                        size            : 'default'
                    }}
                    footer          = {() => {
                    return(
                        <Button 
                        type        = "dashed" 
                        onClick     = {addNewRow}
                        block
                        icon        = {<PlusOutlined />}
                        style       = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        disabled    = {
                            editingKey !== 0 || filteringSomething || (dataTable[0] !== undefined && dataTable[0][idNameColumn] === 0)                              
                        }
                        >Add a Treasury Padding
                        </Button>
                    );
                    }}  
                />
            </Form>
            <AlertBox id="alert-new-bankingStandard" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
}

export default VolatilityPaddingTable;