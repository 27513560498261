import {genericGetWithParameters, genericCallWithBody, genericDeleteWithParameters, genericCallWithParametersNoEncrypted, genericDeleteWithBody} from './Networking';

export async function getRate(customerID, contactID, dealType, valueDate, forwardNearDate, buyCurrency, sellCurrency, dealAmount, lockedSide, totalFeesAmount, isOnlineRequest, farLegDealAmount, farLegDate, externalInterface, externalInterfaceType){
    try {
        let params = {
            buyCurrency:buyCurrency,
            customerID: customerID,
            contactID: contactID,
            dealAmount:dealAmount,
            dealType:dealType,
            externalInterface: externalInterface,
            externalInterfaceType: externalInterfaceType,
            farLegDate: farLegDate,
            farLegDealAmount: farLegDealAmount,
            forwardNearDate:forwardNearDate,
            isOnlineRequest: isOnlineRequest,
            lockedSide:lockedSide,
            sellCurrency:sellCurrency,
            totalFeesAmount: totalFeesAmount,
            valueDate:valueDate,
        };

        let response = await genericGetWithParameters('Rate/GetRate',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getProfit(rateQuoteID, dealType, sellCurrency, buyCurrency, sellAmount, buyAmount, valueDate, feesAmount, customerRate, marketRate){
    try {
        let params = {"rateQuoteID": rateQuoteID,
            "dealType":dealType,
            "sellCurrency":sellCurrency,
            "buyCurrency":buyCurrency,
            "sellAmount":sellAmount,
            "buyAmount":buyAmount,
            "valueDate":valueDate,
            "feesAmount":feesAmount,
            "customerRate":customerRate,
            "marketRate":marketRate,
        };

        let response = await genericGetWithParameters('Rate/GetProfit',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getSupportingRates(rateQuoteID, dealType, sellCurrency, buyCurrency, valueDate, maturityDate){
    try {
        let params = {"rateQuoteID": rateQuoteID,
            "dealType":dealType,
            "sellCurrency":sellCurrency,
            "buyCurrency":buyCurrency,
            "maturityDate":maturityDate,
            "valueDate":valueDate,
        };

        let response = await genericGetWithParameters('Rate/GetSupportingRates',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAllRatesMonitor(createDate, traderUserID, status){
    try {
        let params = {
            "createDate": createDate,
            "traderUserID": traderUserID,
            "status": status
        };

        let response = await genericGetWithParameters('Rate/GetAllNonPendingApproval',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getPopUpRateMonitor(userName){
    try {
        let params = {
            "userName": userName
        };

        let response = await genericGetWithParameters('Rate/GetNextPendingApproval',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function saveSupportingRates(objReactSupportingRates){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Rate/SaveSupportingRates';
    let response = await genericCallWithBody(method, apiName, objReactSupportingRates);
    return response;
}
export async function rejectPendingRateActivity(objRejectRate) {
    let response = await genericCallWithParametersNoEncrypted('Rate/RejectPendingRateActivity', objRejectRate);
    return response;
}
export async function approvePendingRateActivity(objApproveRate) {
    var method = '';
    var apiName = '';
    method = 'PUT';
    apiName = 'Rate/ApproveRateActivity';
    let response = await genericCallWithBody(method, apiName, objApproveRate);
    return response;
}

export async function getAllRates(page, items){
    try {
        let params = {
            'Page': page,
            'Items': items
        };
        let response = await genericGetWithParameters('Rate/GetAllRates',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getRateTreasuryPadding(page, items){
    try {
        let params = {
            'Page': page,
            'Items': items
        };
        let response = await genericGetWithParameters('Rate/GetRateTreasuryPadding',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getRateVolatilityPadding(page, items){
    try {
        let params = {
            'Page': page,
            'Items': items
        };
        let response = await genericGetWithParameters('Rate/GetRateVolatilityPadding',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function saveRate(objRate){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Rate/SaveRates';
    let response = await genericCallWithBody(method, apiName, objRate);
    return response;
}

export async function saveTreasuryPadding(objRate){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Rate/UpdateRateTreasuryPadding';
    let response = await genericCallWithBody(method, apiName, objRate);
    return response;
}

export async function saveVolatilityPadding(objRate){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Rate/UpdateRateVolatilityPadding';
    let response = await genericCallWithBody(method, apiName, objRate);
    return response;
}
export async function deleteRateTreasuryPadding(params)
{
  let response = await genericDeleteWithParameters('Rate/DeleteRateTreasuryPadding', params);
  return response;
}
export async function deleteRateVolatilityPadding(params)
{
  let response = await genericDeleteWithParameters('Rate/DeleteRateVolatilityPadding', params);
  return response;
}

export async function deleteRate(ccy1,ccy2){
    let params = {
        "CCY1": ccy1,
        "CCY2": ccy2
    }
    let response = await genericDeleteWithParameters('Rate/DeleteRates',params);
    return response;
}

export async function getRatePricingManager(custID, custPricingCategoryID, affilPricingCategoryID, parentAffilPricingCategoryID, homeCurrencyCode, foreignCurrencyCode, amount, isReverse, isOnlineRequest, currentDate, valueDate, fixInterfaceRate, interfaceSource){
    try {
        let params = {
            custID: custID, 
            custPricingCategoryID: custPricingCategoryID, 
            affilPricingCategoryID: affilPricingCategoryID, 
            parentAffilPricingCategoryID: parentAffilPricingCategoryID, 
            homeCurrencyCode: homeCurrencyCode, 
            foreignCurrencyCode: foreignCurrencyCode, 
            amount: amount, 
            isReverse: isReverse, 
            isOnlineRequest: isOnlineRequest, 
            currentDate: currentDate, 
            valueDate: valueDate, 
            fixInterfaceRate: fixInterfaceRate, 
            interfaceSource: interfaceSource
        };

        let response = await genericGetWithParameters('Rate/GetRatePricingManager',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getBroadcastRates(customerID, contactID, dealType, valueDate, forwardNearDate, buyCurrency, sellCurrency, dealAmount, lockedSide, totalFeesAmount, isOnlineRequest, farLegDealAmount, farLegDate){
    try {
        let params = {
            buyCurrency:buyCurrency,
            customerID: customerID,
            contactID: contactID,
            dealAmount:dealAmount,
            dealType:dealType,
            farLegDate: farLegDate,
            farLegDealAmount: farLegDealAmount,
            forwardNearDate:forwardNearDate,
            isOnlineRequest: isOnlineRequest,
            lockedSide:lockedSide,
            sellCurrency:sellCurrency,
            totalFeesAmount: totalFeesAmount,
            valueDate:valueDate,
        };

        let response = await genericGetWithParameters('Rate/GetBroadcastRates',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getRateQuotesByCustomer(customerID, isOnlineDeal, page, items){
    try {
        let params = {
            customerID: customerID,
            isOnlineDeal: isOnlineDeal,
            page: page,
            items: items,
        }

        let response = await genericGetWithParameters('Rate/GetRateQuotesByCustomer',params);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getRate_forImportDeals(customerRate, marketRate, customerID, contactID, dealType, valueDate, forwardNearDate, buyCurrency, sellCurrency, dealAmount, lockedSide, totalFeesAmount, isOnlineRequest, farLegDealAmount, farLegDate){
    try {
        let params = {
            customerRate: customerRate,
            marketRate: marketRate,
            buyCurrency: buyCurrency,
            customerID: customerID,
            contactID: contactID,
            dealAmount: dealAmount,
            dealType: dealType,
            farLegDate: farLegDate,
            farLegDealAmount: farLegDealAmount,
            forwardNearDate: forwardNearDate,
            isOnlineRequest: isOnlineRequest,
            lockedSide: lockedSide,
            sellCurrency: sellCurrency,
            totalFeesAmount: totalFeesAmount,
            valueDate: valueDate,
        };

        let response = await genericGetWithParameters('Rate/GetRate_forImportDeals',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function GetRateForMultiplePaymentsOnPartner(externalInterface, externalInterfaceType, dealHeader, dealAmount) {
    try {
        let method = 'POST';
        let apiName = 'Rate/GetRateForMultiplePaymentsOnPartner';
        let model = {
            ExternalInterface: externalInterface,
            ExternalInterfaceType: externalInterfaceType,
            DealHeader: dealHeader,
            DealAmount: dealAmount
        }
        let response = await genericCallWithBody(method, apiName, model);
        return response;

    } catch (error) {
        console.error(error);
    }
}