import React, { useState } from 'react';
import { getStyle } from '../../../styles/styles';
import { savePlan, deletePlan, getAllAfterHoursPlan } from '../../../networking/NetworkingAfterHours';
import AlertBox from '../../shared/AlertBox';
import { formatValidInput } from '../../../helpers/FormatHelper';
import NewDynamicTable from '../../shared/NewDynamicTable';
import { validate } from '../../../helpers/ValidateHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { InfoCircleOutlined } from '@ant-design/icons';

var style = getStyle();

const PlansForm = (props) => {
    const [planID, setPlanID] = useState(0);
    const [planName, setPlanName] = useState('');
    const [validations, setValidations] = useState({planName: true});
    const plans = props.plans;

    const [pages, setPages] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [showSuccessDeleteAlert, setShowSuccessDeleteAlert] = useState(false)
    const [readyToDeletePlanID, setReadyToDeletePlanID] = useState(0);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [errors, setErrors] = useState([]);

    const handleAddPlan = async (event) => {
        event.preventDefault();
        if (validatePlanName()) {
            const model = {
                'AfterHoursPlanID': planID,
                'PlanName': planName
            };
            const json = await savePlan(model);
            if (json.httpStatusCode !== 200) {
                setShowAlertError(true);
                setAlertTitle('Error')
                setAlertMessage('An error occurred while trying to save the plan. Please try again.')
            } else {
                setShowAlert(true);
                setAlertTitle('Success')
                setAlertMessage('Plan saved successfully.');
                setPlanName('');
                setPlanID(0);
                props.plansNeedReloading();
            }
        }
    }

    const handleClear = (event) => {
        event.preventDefault();
        setValidations({planName: true});
        setPlanName('');
        setPlanID(0);
    }

    const handleRowClick = (obj) => {
        setValidations({ planName: true });
        setPlanID(obj.AfterHoursPlan_ID);
        setPlanName(obj.PlanName);
    }

    const viewPlanDetails = (obj) => {
        //props.getSelectedPlanID(obj.AfterHoursPlan_ID);
        props.getSelectedPlanID(obj);
    }

    const handleDeletePlan = async (obj) => {
        setShowDeleteAlert(true);
        setReadyToDeletePlanID(obj.AfterHoursPlan_ID);
    }

    const validatePlanName = () => {
        let fields = [
            {displayName: 'Plan Name', validateName: 'planName', value: planName, type: 'string'}
        ];

        const [valid, _validations, _errors] = validate(fields);

        setValidations(_validations);
        if (!valid) {
            setAlertTitle('Error');
            setAlertMessage('Please fill all mandatory fields:')
            setErrors(_errors);
            setShowAlertError(true);
        }

        return valid;
    }

    const changePage = (value) => {
        setPages(value);
    }

    const getAllPlans = async () => {
        const json = await getAllAfterHoursPlan();
        return json.afterHoursPlans;
    }

    const handleUpdatePlanName = (event) => {
        setValidations({planName: true});
        setPlanName(event.target.value);
    }

    const yesDeleteAlert = async () => {
        const json = await deletePlan(readyToDeletePlanID);
        switch (json.httpStatusCode) {
            case 200:
                setShowDeleteAlert(false);
                setShowSuccessDeleteAlert(true);
                props.plansNeedReloading();
                break;
            case 503:
                setShowDeleteAlert(false);
                setShowAlertError(true);
                setAlertTitle('Error');
                setAlertMessage(json.Message);
                break;
            default:
                setShowDeleteAlert(false);
                setShowAlertError(true);
                setAlertTitle('Error');
                setAlertMessage('An error occurred while trying to delete the plan. Please try again.');
                break;
        }
    }

    const okSuccessAlert = () => {
        setShowSuccessDeleteAlert(false);
    }

    const closeDeleteAlert = () => {
        setShowDeleteAlert(false);
        setReadyToDeletePlanID(0);
    }

    const closeAlert = () => {
        setShowAlert(false);
        setAlertTitle('');
        setAlertMessage('');
    }

    const closeAlertError = () => {
        setShowAlertError(false)
        setAlertTitle('');
        setAlertMessage('');
    }
    
    return (
        <div>
            <form className="uk-form-stacked uk-grid uk-margin">
                <div className="uk-width-1-2 uk-form-stacked">
                    <label className="uk-form-label" style={style.boldLabel}>Plan Name (*)</label>
                    <div className="uk-form-controls" style={style.inputStyle}>
                        <input className={formatValidInput(validations.planName)} type="text" placeholder="" value={planName} onChange={handleUpdatePlanName} />
                    </div>
                </div>
                <div className="uk-width-1-2 uk-form-stacked uk-margin" style = {{marginBottom:'0px'}}></div>
            </form>
            <div>
                <button className="uk-button uk-button-green" onClick={handleClear}>Clear Fields</button>
                <button className="uk-button uk-button-green" onClick={handleAddPlan}>Save</button>
                <hr/>
            </div>
            <NewDynamicTable
                data={plans}
                useIcons={true}
                hiddenHeaders={['AfterHoursPlan_ID', 'name', 'value', 'controlID']}
                dynamicActions={[{name: 'Info', function: viewPlanDetails, isIcon: true, icon: <InfoCircleOutlined />}]}
                enableClick={true}
                clickFunction={handleRowClick}
                newSearch={true}
                useDeleteButton={true}
                deleteFunction={handleDeletePlan}
                usePaginator={true}
                changePage={changePage}
                numberOfPages={props.totalPages}
                pageNumber={pages}
                numberPerPage={getDefaultPagingNumberOfItems()}
                enableSortByHeader={true}
                useFilter={true}
                filterFunction={getAllPlans}/>
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert}/>
            <AlertBox open={showAlertError} onClose={closeAlertError} title={alertTitle} message={alertMessage} messageBoldList={errors} type="Ok" okClick={closeAlertError}/>
            <AlertBox open={showDeleteAlert} onClose={closeDeleteAlert} title="Are you sure you want to delete this plan?" message="This action can not be undone." type="Yes/No" yesClick={yesDeleteAlert} noClick={closeDeleteAlert}/>
            <AlertBox open={showSuccessDeleteAlert} onClose={closeDeleteAlert} title="Plan successfully removed." message="" type="Ok" okClick={okSuccessAlert}/>
        </div>
    );
}

export default PlansForm;