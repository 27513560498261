import React from 'react';
import { getStyle } from '../../../styles/styles';
import DynamicSelect from '../../shared/DynamicSelect';
import NewDynamicTable from '../../shared/NewDynamicTable';
import NumberInput from '../../shared/NumberInput';
import { savePlanDetails, getPlanDetailsById, deletePlanDetails, getPlanDetailsByPlanID } from '../../../networking/NetworkingAfterHours';
import AlertBox from '../../shared/AlertBox';
import { formatValidSelect, formatValidInput, getScaleForCurrencyPair } from '../../../helpers/FormatHelper';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import { searchCurrencies } from '../../../networking/NetworkingCurrencies';
import { validate } from '../../../helpers/ValidateHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
const style = getStyle();

export default class PlanDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homeCurrency: [],
            foreignCurrency: [],
            planDetails: {
                planID: 0,
                homeCurrency: '',
                homeCurrencyName: '',
                foreignCurrency: '',
                foreignCurrencyName: '',
                markupFactor: 0,
                percentOrPoints: '',
                updateToken: ''
            },
            validations: {
                homeCurrency: true,
                foreignCurrency: true,
                markupFactor: true,
                percentOrPoints: true
            },
            percentOrPoints: [
                { name: 'Percent', value: 'Percent' },
                { name: 'Points', value: 'Points' }
            ],

            disableDetails: false,
            pages: 1,
            showAlert: false,
            showAlertError: false,
            showDeleteAlert: false,
            showSuccessDeleteAlert: false,
            readyToDeletePlanDetail: null,
            alertTitle: '',
            alertMessage: '',
            errors: [],
            planDetailsGrid : [], 
            totalPages : 0
        }
    }
    componentDidMount() {
        this.getPlanDetails(this.props.selectedPlanID);
    }
    getPlanDetails = async (selectedPlanID) => {
        const json = await getPlanDetailsByPlanID(selectedPlanID, 0, 0);
        if (json.afterHours !== undefined) {
            const totalCount = json.afterHours.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({ planDetailsGrid: json.afterHours, totalPages });
        } else {
            this.setState({ planDetailsGrid: [] });
        }
    }
    changePage = (value) => {
        this.setState({ pages: value });
    }
    searchHomeCurrency = async (query) => {
        const json = await searchCurrencies(query, 'active');
        var helper = [];
        if (query !== '') {
            if (json !== undefined) {
                for (var i = 0; i < json.length; i++) {
                    helper.push({ value: json[i].CurrencyID, name: json[i].CurrencyIDName })
                }
                if (json.length > 1) {
                    this.setState({ homeCurrency: helper });
                } else {
                    this.setState({
                        homeCurrency: helper,
                        planDetails: { ...this.state.planDetails, homeCurrency: helper[0].name }
                    });
                }
            } else {
                this.setState({ homeCurrency: helper });
            }
        }
    }
    searchForeignCurrency = async (query) => {
        const json = await searchCurrencies(query, 'active');
        var helper = [];
        if (query !== '') {
            if (json !== undefined) {
                for (var i = 0; i < json.length; i++) {
                    helper.push({ value: json[i].CurrencyID, name: json[i].CurrencyIDName })
                }
                if (json.length > 1) {
                    this.setState({ foreignCurrency: helper });
                } else {
                    this.setState({
                        foreignCurrency: helper,
                        planDetails: { ...this.state.planDetails, foreignCurrency: helper[0].name }
                    });
                }
            } else {
                this.setState({ foreignCurrency: helper });
            }
        }
    }
    handleSaveClick = async (e) => {
        e.preventDefault();
        if (this.validateFields()) {
            const model = {
                'AfterHoursPlanID': this.props.selectedPlanID,
                'HomeCurrency': this.state.planDetails.homeCurrency,
                'ForeignCurrency': this.state.planDetails.foreignCurrency,
                'MarkupFactor': this.state.planDetails.markupFactor,
                'PercentOrPoints': this.state.planDetails.percentOrPoints,
                'UserId': localStorage.getItem('UserID'),
                'UpdateToken': this.state.planDetails.updateToken
            }
            const json = await savePlanDetails(model);
            if (json.httpStatusCode !== 200) {
                var alertMsg = 'Error saving Plan details'
                if (json.httpErrorMessage !== undefined && json.httpErrorMessage !== '') {
                    alertMsg = json.httpErrorMessage;
                }
                this.setState({ showAlertError: true, alertTitle: 'Error', alertMessage: alertMsg });
            } else {
                this.setState({ showAlert: true, alertTitle: 'Success', alertMessage: 'Plan details saved successfully' }, () => {
                    this.getPlanDetails(this.props.selectedPlanID);
                });
            }
            this.clearFields();
        }
    }
    validateFields = () => {
        const fields = [
            { displayName: 'Home Currency', validateName: 'homeCurrency', value: this.state.planDetails.homeCurrency, type: 'string' },
            { displayName: 'Foreign Currency', validateName: 'foreignCurrency', value: this.state.planDetails.foreignCurrency, type: 'string' },
            { displayName: 'Markup Factor', validateName: 'markupFactor', value: this.state.planDetails.markupFactor, type: 'number' },
            { displayName: 'Percent or Points', validateName: 'percentOrPoints', value: this.state.planDetails.percentOrPoints, type: 'string' },
        ]

        const [valid, _validations, _errors] = validate(fields);
        this.setState({ validations: _validations })
        if (!valid) {
            this.setState({
                showAlertError: true,
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:',
                errors: _errors
            });
        }

        return valid;
    }
    handleRowClick = async (obj) => {
        const json = await getPlanDetailsById(obj['AfterHoursPlanID'], obj['Home Currency'], obj['Foreign Currency']);
        this.setState({
            planDetails: {
                ...this.state.planDetails,
                planID: json.AfterHoursPlanID,
                homeCurrency: json.HomeCurrency,
                homeCurrencyName: json.HomeCurrency,
                foreignCurrency: json.ForeignCurrency,
                foreignCurrencyName: json.ForeignCurrency,
                markupFactor: json.MarkupFactor,
                percentOrPoints: json.PercentOrPoints,
                updateToken: json.UpdateToken
            },
            disableDetails: true,
            validations: {
                ...this.state.validations,
                homeCurrency: true,
                foreignCurrency: true,
                markupFactor: true,
                percentOrPoints: true
            }
        });
    }
    handleDeleteClick = (obj) => {
        this.setState({ readyToDeletePlanDetail: obj, showDeleteAlert: true });
    }
    handleClearClick = (e) => {
        e.preventDefault();
        this.clearFields();
    }
    clearFields = () => {
        this.setState({
            planDetails: {
                ...this.state.planDetails,
                planID: 0,
                homeCurrency: '',
                foreignCurrency: '',
                homeCurrencyName: '',
                foreignCurrencyName: '',
                markupFactor: 0,
                percentOrPoints: '',
                updateToken: ''
            },
            //disableDetails: true,
            validations: {
                ...this.state.validations,
                omeCurrency: true,
                foreignCurrency: true,
                markupFactor: true,
                percentOrPoints: true
            }
        });
    }
    getAllPlanDetails = async () => {
        const json = await getPlanDetailsByPlanID(this.props.selectedPlanID, 0, 0);
        if (json.afterHours !== undefined) {
            return json.afterHours;
        } else {
            return [];
        }
    }
    handleGetValueHomeCurrency = (value) => {
        if (value.value !== undefined && value.value !== 'select') {
            this.setState({
                planDetails: {
                    ...this.state.planDetails,
                    homeCurrency: value.value,
                    homeCurrencyName: value.name
                },
                validations: {
                    ...this.state.validations, homeCurrency: true
                }
            });
        }
    }

    handleGetValueForeignCurrency = (value) => {
        if (value.value !== undefined && value.value !== 'select') {
            this.setState({
                planDetails: {
                    ...this.state.planDetails,
                    foreignCurrency: value.value,
                    foreignCurrencyName: value.name
                },
                validations: {
                    ...this.state.validations, foreignCurrency: true
                }
            });
        }
    }

    handleUpdateMarkupFactor = (event) => {
        this.setState({
            planDetails: {
                ...this.state.planDetails,
                markupFactor: event.target.value
            },
            validations: {
                ...this.state.validations, markupFactor: true
            }
        });
    }

    handleGetValuePercentOrPoints = (value) => {
        if (value !== undefined && value !== 'select') {
            this.setState({
                planDetails: {
                    ...this.state.planDetails,
                    percentOrPoints: value.value
                },
                validations: {
                    ...this.state.validations, percentOrPoints: true
                }
            });
        }
    }
    yesDeleteAlert = async () => {
        const json = await deletePlanDetails(this.state.readyToDeletePlanDetail['AfterHoursPlanID'], this.state.readyToDeletePlanDetail['Home Currency'], this.state.readyToDeletePlanDetail['Foreign Currency']);
        if (json.httpStatusCode !== 200) {
            this.setState({
                showDeleteAlert: false,
                showAlertError: true,
                alertTitle: 'Error',
                alertMessage: 'An error occurred while trying to delete the plan details. Please try again.',
            });
        } else {
            this.setState({
                showDeleteAlert: false,
                showSuccessDeleteAlert: true
            }, () => {
                this.getPlanDetails(this.props.selectedPlanID);
                this.clearFields();
            });
        }
    }
    okSuccessAlert = () => {
        this.setState({ showSuccessDeleteAlert: false });
    }
    closeDeleteAlert = () => {
        this.setState({ showDeleteAlert: false, readyToDeletePlanDetail: null });
    }
    closeAlert = () => {
        this.setState({ showAlert: false, alertTitle: '', alertMessage: '' });
    }
    closeAlertError = () => {
        this.setState({ showAlertError: false, alertTitle: '', alertMessage: '' })
    }
    render() {
        return (
            <div>
                <form className="uk-form-horizontal uk-margin">
                    <div className="uk-grid uk-margin">
                        {this.props.selectedPlanName != null &&
                            <div className="uk-width-1-1 uk-form-stacked">
                                <label className="uk-form-label" style={style.boldLabel}>Plan Name</label>
                                <div className="uk-margin" style={style.inputStyle}>
                                    <input disabled={true} className="uk-input" data={this.state.homeCurrency} value={this.props.selectedPlanName} />
                                </div>
                            </div>
                        }
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Home Currency (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSearchSelect disabled={this.state.disableDetails} className={formatValidSelect(this.state.validations.homeCurrency)} data={this.state.homeCurrency} objValue={{ value: this.state.planDetails.homeCurrency, name: this.state.planDetails.homeCurrency }} getValue={this.handleGetValueHomeCurrency} searchFunction={this.searchHomeCurrency} placeholder="Select a Currency..." id="form-home-currency" />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Foreign Currency (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSearchSelect disabled={this.state.disableDetails} className={formatValidSelect(this.state.validations.foreignCurrency)} data={this.state.foreignCurrency} objValue={{ value: this.state.planDetails.foreignCurrency, name: this.state.planDetails.foreignCurrency }} getValue={this.handleGetValueForeignCurrency} searchFunction={this.searchForeignCurrency} placeholder="Select a Currency..." id="form-foreign-currency" />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Markup Factor (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <NumberInput id="markup-factor-id" className={formatValidInput(this.state.validations.markupFactor)} type="Rate" 
                                scale={getScaleForCurrencyPair(this.state.homeCurrency, this.state.foreignCurrency)}
                                placeholder="" value={this.state.planDetails.markupFactor} onChange={this.handleUpdateMarkupFactor} />
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Percent or Points (*)</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DynamicSelect className={formatValidSelect(this.state.validations.percentOrPoints)} data={this.state.percentOrPoints} objValue={{ value: this.state.planDetails.percentOrPoints }} getValue={this.handleGetValuePercentOrPoints} listHasPlaceholder={false} placeholder="Select an Item..." id="form-percent-points" />
                            </div>
                        </div>
                    </div>
                </form>
                <div>
                    <button className="uk-button uk-button-green" onClick={this.handleClearClick}>Clear Fields</button>
                    <button className="uk-button uk-button-green" onClick={this.handleSaveClick}>Save</button>
                    <hr />
                </div>
                <NewDynamicTable
                    useIcons={true}
                    data={this.state.planDetailsGrid}
                    hiddenHeaders={["AfterHoursPlanID"]}
                    enableClick={true}
                    clickFunction={this.handleRowClick}
                    newSearch={true}
                    useDeleteButton={true}
                    deleteFunction={this.handleDeleteClick}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={getDefaultPagingNumberOfItems()}
                    enableSortByHeader={true}
                    useFilter={true}
                    filterFunction={this.getAllPlanDetails} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                <AlertBox open={this.state.showAlertError} onClose={this.closeAlertError} title={this.state.alertTitle} message={this.state.alertMessage} messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlertError} />
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete these plan details?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="Plan details successfully removed." message="" type="Ok" okClick={this.okSuccessAlert} />
            </div>
        )
    }
}